import { request, addEnvironmentToReqParams } from './request';
import { APIJobRef } from './supplierInvoiceJobRef';
import { APIDisassociateDocumentId, APIDocumentContainer } from './documentContainer';
import { APIEmailBasic } from './email';
import { APIUser } from './comment';
import { APIClusterTotal } from './jobRefCluster';
import { APICwPostingLog } from './cargowisePostingLog';

export type Filter = { [k: string]: string | string[] | number | number[] | boolean | null };

export interface APIJobOwner {
  jobRef: string;
  consolRef: string;
  operatorName: string;
  operatorCode: string;
  userId: number | null;
  companyId: number;
  supplierInvoiceId: number;

  userData?: APIUser;
}

export interface APIJobOwnersMap {
  [jobRef: string]: APIJobOwner[];
}

export interface APISupplierInvoice {
  id: number;
  documentId: number;
  addressee: string;
  addresseeCgwCode: string;
  issuer: string;
  issuerCgwCode: string;
  invoiceNumber: string;
  invoiceDate: string;
  grossTotal: number;
  netTotal: number
  vatTotal: number
  currency: string;
  currencyId: number;
  issuerCgwRecordId: string | null;

  taxCode: string | null;
  departmentCode: string;
  branchCountry: string;
  branch: string;

  taxDate: string | null;

  document: APIDocumentContainer;
  lineItems: APIJobRef[];
  clusters: APIClusterTotal[];
  lastCargowisePostingLog?: APICwPostingLog;
  jobOwnersData?: APIJobOwnersMap;
}

export interface ApiFetchInvoices {
  invoices: APIDocumentContainer[];
  numberOfOpenInvoices: number;
  numberOfQueriedInvoices: number;
  openGroupsIds: number[];
}

export interface APIEmail extends APIEmailBasic {
  id: number;
  customId: string
  emailAccountId: number;
  created: string;
  attachmentCount: number;
  companyId: number;
  subject: string;
  sender: string;
  receiver: string;
  date: string;
}

export interface ExchangeRateType {
  id: number;
  sourceCurrency: string;
  targetCurrency: string;
  rate: number;
  isFlipped?: boolean;
  extractedRate?: number;
  originalExtractedRate?: number;
}

export interface APIExchangeRate extends ExchangeRateType {
  invoiceId: number;
  originalRate: number;
}

export enum ClearOperationType {
  Header = 0,
  LineItems = 1,
}

export interface APIStatisticsCorrection {
  supplierInvoiceId: number;
  timestamp: string;
  field: string;
  model: string;
  oldValue: any;
  newValue: any;
  userId: number;
  operation: string;
}

export const SupplierInvoiceAPI = {
  async fetchInvoices(filter: Filter): Promise<ApiFetchInvoices> {
    const params = [`companyId=${localStorage.getItem('companyId')}`];
    addEnvironmentToReqParams(params);
    return request(`/SupplierInvoices/search?filter=${encodeURIComponent(JSON.stringify(filter))}&${params.join('&')}`);
  },

  async update(id: number, supplierInvoice: Partial<APISupplierInvoice> | APIDisassociateDocumentId): Promise<APISupplierInvoice> {
    return request(`/SupplierInvoices/${id}`, 'PATCH', supplierInvoice);
  },

  async validate(id: number) {
    return request(`/SupplierInvoices/${id}/validate`, `POST`);
  },

  async revalidate(id: number) {
    return request(`/SupplierInvoices/${id}/revalidate`, `POST`);
  },

  async fetchExchangeRates(invoiceId: number): Promise<APIExchangeRate[]> {
    const filter = JSON.stringify({
      where: {
        invoiceId
      }
    });

    return request(`/SupplierInvoiceExchangeRates?filter=${encodeURIComponent(filter)}`)
  },

  async updateExchangeRate(id: number, change: Partial<APIExchangeRate>): Promise<APIExchangeRate> {
    return request(`/SupplierInvoiceExchangeRates/${id}`, `PATCH`, change);
  },

  async hasCorrections(supplierInvoiceId: number): Promise<unknown[]> {
    const filter = JSON.stringify({
      where: {
        supplierInvoiceId,
      },
      limit: 1
    });

    return request(`/StatisticsCorrections?filter=${encodeURIComponent(filter)}`);
  },

  async getCorrections(supplierInvoiceId: number): Promise<APIStatisticsCorrection[]> {
    const filter = JSON.stringify({
      where: {
        supplierInvoiceId,
        and: [
          { field: { neq: 'issuerCgwRecordId' } },
          { field: { neq: 'orderIndex' } },
          { field: { neq: 'currencyId' } },
          { field: { neq: 'exchangeRate' } },
          { field: { neq: 'isHeader' } },
          { field: { neq: 'type' } },
          { hideFromActivityLog: false },
        ]
      },
    });

    return request(`/StatisticsCorrections?filter=${encodeURIComponent(filter)}`);
  },

  async clearData(supplierInvoiceId: number, clearOperationTypes: ClearOperationType[]): Promise<unknown[]> {
    return request(`/SupplierInvoices/${supplierInvoiceId}/clearData`, 'POST', {
      clearOperationTypes
    });
  },

  async bulkRevalidate(groupIds: number[]) {
    return request(`/SupplierInvoices/bulkRevalidate`, 'POST', { groupIds });
  },

  async markAsPosted(invoiceId: number, groupId: number) {
    return request('/SupplierInvoices/markAsPosted', 'POST', 
      {
        invoiceId,
        groupId
      }
    );
  },
}
