import * as React from 'react';
import {
  APIError,
  DocumentDiscarded,
  DocumentReassigned,
  DocumentReclassifiedAsInvoice,
  Notification
} from '../../common/Notification';
import { APIDocumentContainer, DocumentContainerAPI, DocumentType } from '../../../api/documentContainer';
import { StatusBarContainer } from '../../common/StatusBarContainer';
import { APITeam } from '../../../api/teams';
import { APIUser } from '../../../api/comment';
import { AssignmentRule } from '../../../api/userSettings';
import { DocumentGroupAPI } from '../../../api/documentGroup';
import { checkDocumentMatchesFilter } from '../helpers';
import { ActivityLogAPI, ActivityType } from '../../../api/activityLog';
import { SearchFilter } from '../../common/document-list';

import './reclassify-document.scss';
import { APIPermissionProfile } from '../../../api/permissionProfiles';

interface Props {
  document?: APIDocumentContainer;
  currentSearchFilter: SearchFilter;
  selectNextDocument: () => void;
  setNotification: (notification: Notification | null) => void;
  teams: APITeam[];
  users: APIUser[];
  checkPermissionProfileAccess: (value: keyof APIPermissionProfile) => boolean;
}

export const ReclassifyDocument: React.FC<Props> = (props) => {
  const [assignmentRule, setAssignmentRule] = React.useState<AssignmentRule>({ teamId: null, userIds: [] });
  const userId = Number(localStorage.getItem('userId'));

  React.useEffect(() => {
    if (props.document) {
      setAssignmentRule({
        teamId: props.document.documentGroup.teamId,
        userIds: props.document.documentGroup.assignedUsers.map((value) => value.userId)
      });
    } else {
      setAssignmentRule({
        teamId: null,
        userIds: []
      });
    }
  }, [props.document]);

  const onDiscard = async () => {
    DocumentContainerAPI.discardDocumentArrivedInAPInvoiceMailbox(props.document!.id);
    props.selectNextDocument();
    props.setNotification(DocumentDiscarded);
  }

  const onReclassify = async () => {
    ActivityLogAPI.createLog({
      documentGroupId: props.document?.groupId,
      type: ActivityType.Reclassification,
      userId,
    });
    DocumentContainerAPI.reclassify([{ id: props.document!.id, newDocumentType: DocumentType.SupplierInvoice }]);
    props.setNotification(DocumentReclassifiedAsInvoice);
    props.selectNextDocument();
  }

  const onReassign = async (value: AssignmentRule) => {
    if (!props.document) {
      return;
    }

    try {
      await DocumentGroupAPI.bulkReassign([props.document!.groupId], [], value.teamId || null, value.userIds);

      setAssignmentRule({ teamId: value.teamId || null, userIds: value.userIds });
      props.setNotification(DocumentReassigned);

      if (!checkDocumentMatchesFilter(props.currentSearchFilter, value)) {
        props.selectNextDocument();
      }

    } catch (e) {
      props.setNotification({ ...APIError, details: { groupId: props.document!.groupId }, reason: `Error reassign operation: ${e}` });
    }
  }

  return (
    <section className="document-action reclassify-document__section">
      <StatusBarContainer
        isTeamDropdownDisabled={!props.document}
        buttons={(
          <>
            <button
              className="light-button light-button--with-border"
              onClick={onReclassify}
              disabled={!props.document}
            >This is an AP Invoice</button>
            { props.checkPermissionProfileAccess('discardAP') && 
              <button
                className="full-button"
                onClick={onDiscard}
                disabled={!props.document}
              >Discard</button>
            }
          </>
        )}
        exceptions={[{ description: 'We didn\'t recognise this document', code: -1, isWarning: false }]}
        teams={props.teams}
        users={props.users}
        assignmentRule={assignmentRule}
        setAssignmentRule={onReassign}
        status="Unclassified"
      />
    </section>
  )
}
