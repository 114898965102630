import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import DataGrid, { Column } from 'react-data-grid';
import { useHistory } from 'react-router';
import { ShipamaxCheckIcon, ShipamaxTimesCircleIcon } from '../../images/Icons';

const PAGE_SIZE = 60;

declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export enum AdminGridTagType {
  Default = 'default',
  Green = 'green',
  Red = 'red',
  Yellow = 'yellow',
  Purple = 'purple',
}

export const AdminGridTagsList: React.FC<{ type: AdminGridTagType, items: string[] }> = (props) => (
  <div className="admin__grid__tags-list">
    {props.items.map((item, index) => (
      <span key={index} className={`admin__grid__tag admin__grid__tag--${props.type}`}>{item}</span>
    ))}
  </div>
)

export const booleanFormatter = (value: boolean | undefined) => {
  if (value) return <ShipamaxCheckIcon />;
  if (value !== undefined) return <ShipamaxTimesCircleIcon />;
  return <></>;
}

export interface AdminGridSearchParams {
  searchTerm: string;
  limit?: number;
}

export interface AdminGridHandle {
  refetchData: () => void,
}

interface Props<T> {
  searchPlaceholder?: string;
  link?: {
    label: string,
    url?: string,
    onClick?: () => void;
  }
  columns: Column<T>[];
  fetchData: (params: AdminGridSearchParams) => Promise<T[]>;
  hideSearch?: boolean;
}

function AdminGridComponent<T extends unknown>(props: Props<T>, ref: React.ForwardedRef<AdminGridHandle>) {
  const [rows, setRows] = React.useState<T[]>([]);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [searchTerm, setSearchTerm] = React.useState<string>('');

  const history = useHistory();

  const HEADER_ROW_HEIGHT = 40;
  const ROW_HEIGHT = 30;
  const TABLE_HEIGHT = (rows.length * ROW_HEIGHT) + HEADER_ROW_HEIGHT + 17;

  React.useImperativeHandle(ref, () => ({
    refetchData() {
      fetchData(PAGE_SIZE);
    }
  }));

  React.useEffect(() => {
    let didCancel = false;

    props.fetchData({ searchTerm, limit: PAGE_SIZE }).then((data) => {
      if (didCancel) {
        return;
      }
      setRows(data);
      setHasMore(data.length >= PAGE_SIZE);
    });

    return () => { didCancel = true; };
  }, [searchTerm]);

  const fetchData = (limit: number) => {
    props.fetchData({ searchTerm, limit }).then((data) => {
      setRows(data);
      setHasMore(data.length >= limit);
    });
  }

  return (
    <div className="admin__grid">
      <div className="admin__grid__top">
        {!props.hideSearch && (
          <input
            className="admin__grid__search"
            placeholder={props.searchPlaceholder || 'Search...'}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
        {props.link && (
          <button
            className="full-button ml-auto"
            onClick={() => {
              if (props.link?.url) {
                history.push(props.link.url);
              } else {
                props.link?.onClick && props.link.onClick();
              }
            }}
          >{props.link.label}</button>
        )}
      </div>
      <div id="scrollable-wrapper" className="admin__grid__wrapper with-custom-scrollbar overflow-auto">
        <InfiniteScroll
          dataLength={rows.length}
          next={() => fetchData(rows.length + PAGE_SIZE)}
          hasMore={hasMore}
          scrollThreshold={0.90}
          scrollableTarget="scrollable-wrapper"
          loader={<></>}
        >
          <DataGrid
            columns={props.columns}
            rows={rows}
            className="rdg-light admin__grid__table with-custom-scrollbar"
            defaultColumnOptions={{ resizable: true }}
            headerRowHeight={HEADER_ROW_HEIGHT}
            rowHeight={ROW_HEIGHT}
            style={{ height: `${TABLE_HEIGHT}px` }}
          />
        </InfiniteScroll>
      </div>
    </div>
  )
}

export const AdminGrid = React.forwardRef(AdminGridComponent);
