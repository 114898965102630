import * as React from 'react';
import { FAIcon } from '../FAIcon';
import { Copied, Notification } from '../Notification';
import { Exception } from '../../../api/validationResults';
import XMLViewer from 'react-xml-viewer';

import './posting-log-tile.scss';

export interface AuditLogTileProps {
  message: string | undefined;
  hideMessageBar?: boolean;
  exception?: Exception;
  setNotification: (notification: Notification) => void;
}

export const PostingLogTile: React.FC<AuditLogTileProps> = (props) => {
  const downloadLog = () => {
    if (props.message) {
      const element = document.createElement("a");
      const file = new Blob([props.message], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = "response.txt";
      document.body.appendChild(element);
      element.click();
    }
  }

  const copyText = () => {
    if (props.message) {
      navigator.clipboard.writeText(props.message);
      props.setNotification(Copied);
    }
  }

  return (
    <div className="posting-log-tile">
      {!props.hideMessageBar && (
        <>
          <div className="posting-log-tile__actions">
            <button className="light-button active-on-hover" onClick={copyText}>Copy text</button>
            <button className="light-button active-on-hover" onClick={downloadLog}>Download</button>
          </div>
          <div className="posting-log-tile__response with-custom-scrollbar">
            {props.message && props.message.trim() !== '' ?
              <XMLViewer
                xml={props.message}
                indentSize={4}
                overflowBreak={true}
                invalidXml={(
                  <div>
                    {props.message}
                  </div>
                )}
              /> : <div>
                Request to TMS failed without details. If the problem persists, please contact support.
              </div>}
          </div>
        </>
      )}
      {props.exception &&
        <div className="posting-log-tile__exception">
          <FAIcon name="exclamation-triangle" solid className="icon-error" />
          <span>{props.exception.description}</span>
        </div>
      }
    </div>
  );
}
