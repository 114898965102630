import * as React from 'react';
import { WrapperLabel } from '../../common/WrapperLabel';
import { OptionValue } from 'react-selectize';
import { TextInput } from '../../common/TextInput';
import { SkeletonInput } from '../../common/SkeletonInput';
import { APIDocumentGroupContainer } from '../../../api/documentGroup';
import { BillOfLadingContainerAPI } from '../../../api/billOfLadingContainer';
import { FocusDirection } from '../../helpers';
import { BillOfLadingSeal } from '../../../api/billOfLadingSeal';
import { CustomSelect } from '../../common/CustomSelect';

import './container-row.scss';

interface Props {
  data: APIDocumentGroupContainer;
  containerTypeOptions: OptionValue[];
  deleteContainerRow: (id?: number) => void;
  onChange: (change: Partial<APIDocumentGroupContainer>) => void;
  hideLabels?: boolean;
  onContainerUpdated: () => void;
  containersCount: number;
  onShiftFocus: (direction: FocusDirection) => void;
  shouldFocusInput: boolean;
  onFocusCompleted: () => void;
  disabled: boolean;
  onFocus?: () => void;
}

export const ContainerRow: React.FC<Props> = (props) => {
  const firstInputRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (props.shouldFocusInput) {
      firstInputRef.current?.focus();
      props.onFocusCompleted();
    }
  }, [props.shouldFocusInput]);

  const update = async (name: string, value: string | number | null, withoutChange?: boolean) => {
    if (props.data?.id && !withoutChange) {
      await BillOfLadingContainerAPI.update(props.data?.id, { [name]: value });
      props.onContainerUpdated();
    }
  }

  const onFilterOptions = (items: OptionValue[], search: string): OptionValue[] => {
    return items.filter((option) => option.label.toUpperCase().includes(search.toUpperCase()));
  }

  const updateSealNumber = async (sealNumber: string, index: number) => {
    const sealNums = [...props.data.seals];
    if (index >= props.data.seals.length) {
      const newSeal = {
        sealNo: sealNumber,
        containerNo: props.data.containerNo,
        containerId: props.data?.id,
        billOfLadingId: props.data?.billOfLadingId
      };
      sealNums.push(newSeal);
    } else {
      const sealToUpdate = sealNums[index];
      if (sealToUpdate) sealToUpdate.sealNo = sealNumber;
    }

    props.onChange({ seals: sealNums });
  }

  const onBlurSealNumber = async (index: number, value: string | number | null, withoutChange?: boolean) => {
    if (withoutChange) {
      return;
    }

    const sealToUpdate = props.data.seals[index];
    if (sealToUpdate.sealNo) {
      if (sealToUpdate.id) {
        await BillOfLadingSeal.update(sealToUpdate.id!, { sealNo: String(value) });
      } else {
        const created = await BillOfLadingSeal.create(sealToUpdate);
        props.onChange({ seals: props.data.seals.map((item, i) => i === index ? created : item) });
      }
    } else {
      if (sealToUpdate.id) {
        await BillOfLadingSeal.remove(sealToUpdate.id);
      }
      props.onChange({ seals: props.data.seals.filter((seal, i) => i !== index) });
      checkIfRemovable();
    }
  }

  const onBlurUpdateAndCheckRemoveable = (name: string, value: string | number | null, withoutChange?: boolean) => {
    update(name, value, withoutChange);
    checkIfRemovable();
  }

  const checkIfRemovable = () => {
    // if all fields are empty, delete this container row
    if (!props.data.containerType && !props.data.containerNo && !props.data.seals.length) {
      props.deleteContainerRow(props.data.id);
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): boolean => {
    if (event.key === 'ArrowDown') {
      props.onShiftFocus(FocusDirection.DOWN);
      event.preventDefault();
      return false;
    } else if (event.key === 'ArrowUp') {
      props.onShiftFocus(FocusDirection.UP);
      event.preventDefault();
      return false;
    }

    return true;
  }

  const sealNums = props.data.seals.length < 3 ? [...props.data.seals, { sealNo: '' }] : props.data.seals;
  const containerTypeOption = props.containerTypeOptions.find((option) => option.value === props.data.containerType) || null;

  const sealNumsComponents = sealNums.map((seal, i) => {
    const isAddButton = i === sealNums.length - 1 && seal.sealNo === '';

    return (
      <SkeletonInput
        key={`seal-number-${i}`}
        displayAsSkeleton={isAddButton}
        className={isAddButton ? 'seal-number-add-button' : 'seal-number'}
        disabled={props.disabled}
      >
        <WrapperLabel text="Seal Number">
          <TextInput
            dataTestId={`seal-number-input-${i}`}
            value={seal.sealNo}
            name={`seal-number-${i}`}
            onBlur={(name, value, withoutChange) => onBlurSealNumber(i, value, withoutChange)}
            setter={(sealNumber: string) => updateSealNumber(sealNumber, i)}
            placeholder={isAddButton ? 'Add a Seal No.' : ''}
            onKeyDown={handleKeyDown}
            maxLength={20}
            disabled={props.disabled}
            onFocus={props.onFocus}
            fieldData={{
              objectIds: [seal.id || -1],
              tableName: 'bol_container_seals',
              attributeNames: ['seal_number'],
            }}
          />
        </WrapperLabel>
      </SkeletonInput>
    );
  });

  return (
    <div className={`grid__row container-row ${props.hideLabels ? 'hide-labels' : ''}`}>
      <div className="grid__col-2">
        <WrapperLabel text={'Container No.' + (props.containersCount > 1 ? ` (${props.containersCount})` : '')}>
          <TextInput
            dataTestId="container-number-input"
            value={props.data.containerNo || ''}
            name="containerNo"
            onBlur={onBlurUpdateAndCheckRemoveable}
            onChange={(value, name) => props.onChange({ [name]: value })}
            onKeyDown={handleKeyDown}
            ref={firstInputRef}
            maxLength={20}
            disabled={props.disabled}
            onFocus={props.onFocus}
            fieldData={{
              objectIds: [props.data?.id || -1],
              tableName: 'bol_container',
              attributeNames: ['container_number'],
            }}
          />
        </WrapperLabel>
      </div>
      <div className="grid__col-2">
        <WrapperLabel text="Container Type" title={containerTypeOption?.value}>
          <CustomSelect
            dataTestId="container-type-input"
            options={props.containerTypeOptions}
            value={containerTypeOption || undefined}
            filterOptions={onFilterOptions}
            onValueChange={(value) => {
              props.onChange({ containerType: value?.value || null });
              update('containerType', value?.value || null);
            }}
            onBlur={checkIfRemovable}
            disabled={props.disabled}
            onFocus={props.onFocus}
            fieldData={{
              objectIds: [props.data?.id || -1],
              tableName: 'bol_container',
              attributeNames: ['container_type'],
            }}
          />
        </WrapperLabel>
      </div>
      <div className="grid__col-2">
        {sealNumsComponents.slice(0, 1)}
      </div>
      <div className="grid__col-2">
        {sealNumsComponents.slice(1, 2)}
      </div>
      <div className="grid__col-2">
        {sealNumsComponents.slice(2, 3)}
      </div>
      <div className="grid__col-2">
        {sealNumsComponents.slice(3, 4)}
      </div>
    </div>
  );
}
