import * as React from 'react';
import { WrapperLabel } from '../../../common/WrapperLabel';
import { TextInput } from '../../../common/TextInput';
import { FieldValidator } from '../../../common/Form';

import './OrganisationText.scss';

interface Props {
  label: string;
  fieldName?: string;
  highlightName?: string;
  tableName: string;

  name: string;
  setName: (name: string) => void;
  address: string;
  setAddress: (address: string) => void;

  update: (name: string, value: string | number | null, withoutChange?: boolean) => Promise<void>;
  disabled: boolean;
  id: number[];
  onFocus: () => void;
  validators?: FieldValidator[];
}

export const OrganisationText: React.FC<Props> = (props: Props) => {

  return (
    <div className="grid__col-4 flex-column">
      <div className="grid__sub-row organisation-name-box">
        <WrapperLabel text={`${props.label.toLocaleUpperCase()} NAME`}>
          <TextInput
            value={props.name}
            name={`${props.fieldName || props.label}Name`}
            onBlur={props.update}
            setter={props.setName}
            disabled={props.disabled}
            fieldData={{
              objectIds: props.id,
              tableName: props.tableName,
              attributeNames: [`${props.highlightName || props.fieldName || props.label}_name`],
            }}
            onFocus={props.onFocus}
            dataTestId={props.fieldName ? props.fieldName + '_input_name' : undefined}
            formEntered={true}
            validators={props.validators}
          />
        </WrapperLabel>
      </div>
      <WrapperLabel text={`${props.label.toLocaleUpperCase()} ADDRESS`}>
        <TextInput
          value={props.address}
          name={`${props.fieldName || props.label}Address`}
          onBlur={props.update}
          setter={props.setAddress}
          disabled={props.disabled}
          fieldData={{
            objectIds: props.id,
            tableName: props.tableName,
            attributeNames: [`${props.highlightName || props.fieldName || props.label}_address`],
          }}
          onFocus={props.onFocus}
          enableMultiline
          className='textarea-address'
          dataTestId={props.fieldName ? props.fieldName + '_input_address' : undefined}
        />
      </WrapperLabel>
    </div>);
};
