import * as React from 'react';
import { ClusterStatus } from '../../api/jobRefCluster';

import './message-bar.scss';

interface Props {
  text: string;
  btnText?: string;
  onClick?: () => void;
  disabled?: boolean;
  type: ClusterStatus;
  testId?: string;
  hideButton?: boolean;
}

export const MessageBar: React.FC<Props> = (props) => (
  <div className={`exception-bar exception-bar--${props.type}`} data-test-id={props.testId}>
    <span>{props.text}</span>
    {!props.hideButton && (
      <button onClick={props.onClick} disabled={props.disabled} className="light-button">{props.btnText}</button>
    )}
  </div>
);