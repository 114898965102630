import * as React from 'react';
import { CompanyAPI } from '../../../api/company';
import { ChartColors, ChartType, SerieSettings } from '../common';
import { ChartCommonProps, ChartFilterProps } from '../ChartWrapper';
import { GeneralChart, ChartSettings } from '../GeneralChart';
import { Map2 } from '../../helpers';
import * as moment from 'moment';

const settings: { [key: string]: { leftAxisLabel: string, tooltip: string } } = {
  [ChartType.AgeOfOldest]: {
    leftAxisLabel: 'Age (days)',
    tooltip: 'Age of oldest (days)'
  },
  [ChartType.InvoiceCount]: {
    leftAxisLabel: 'Invoice count',
    tooltip: 'Invoices'
  },
}

interface Props extends ChartCommonProps {
  apiFilter: ChartFilterProps
  hideExportButton?: boolean;
}

export const CountChart: React.FC<Props> = (props) => {
  const seriesData = new Map2<string, SerieSettings>([
    [
      'count',
      {
        color: ChartColors.Color1,
        label: settings[props.apiFilter.chartType].tooltip
      }
    ]
  ]);

  const fetchChartData = async () => {
    const result: any[] = await CompanyAPI.getApInsightsChartData(props.apiFilter);
    return result.map((data) => ({
      name: data.aggregation,
      count: props.apiFilter.chartType === ChartType.InvoiceCount ? data.total : data.values[0]?.value
    })) || [];
  }

  return (
    <GeneralChart
      apiFilter={props.apiFilter}
      seriesData={seriesData}
      fetchChartData={fetchChartData}
      referenceData={props.referenceData}
      isFetching={props.isFetching}
      setIsFetching={props.setIsFetching}
      counterType={props.counterType}
      groupMode="stacked"
      leftAxisSettings={{ axisLegend: settings[props.apiFilter.chartType].leftAxisLabel }}
      setNotification={props.setNotification}
      renderForCustomDashboard={props.renderForCustomDashboard}
      renderAddToButton={props.renderAddToButton}
      permissionLevel={props.permissionLevel}
      customDashboards={props.customDashboards}
      insightsSettings={props.insightsSettings}
      chartUserSettings={props.chartUserSettings}
      renderExportButton={props.apiFilter.chartType === ChartType.InvoiceCount && !props.hideExportButton}
      exportFilename={`Shipamax_Count_Report_${moment().format('DDMMYYYY')}.csv`}
      exportType='ap'
      customPageSize={props.customPageSize}
      productSection={props.productSection}
    />
  )
}
