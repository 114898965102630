import * as React from 'react';
import { AdminHeader } from '../../AdminHeader';
import { TextInput } from '../../../common/TextInput';
import { WrapperLabel } from '../../../common/WrapperLabel';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { APIKpiTarget, KPITargetAPI } from '../../../../api/kpiTargets';
import { NumberInput } from '../../../common/NumberInput';
import { isFormInvalid, RecordValidator } from '../../../common/Form';
import { DateSelectEditor } from '../../../common/DateSelectEditor';

interface UrlParams {
  id?: string;
}

interface Props {
  data?: APIKpiTarget;
}

export const KpiTargetForm: React.FC<Props> = (props) => {
  const [record, setRecord] = React.useState<Partial<APIKpiTarget>>({
    companyId: parseInt(localStorage.getItem('companyId') || ''),
  });
  const [isNameTaken, setIsNameTaken] = React.useState<boolean>(false);
  const [isStartDateTaken, setIsStartDateTaken] = React.useState<boolean>(false);
  const [formEntered, setFormEntered] = React.useState<boolean>(false);

  const recordValidator: RecordValidator = {
    name: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: (name) => !!name
        },
        {
          errorMessage: 'Name should be unique',
          isValid: () => !isNameTaken
        }
      ]
    },
    straightThroughRate: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: (straightThroughRate) => !!straightThroughRate
        },
        {
          isValid: (straightThroughRate) => straightThroughRate !== undefined && straightThroughRate > 0 && straightThroughRate <= 100,
          errorMessage: 'Value needs to be between 1 and 100'
        },
      ]
    },
    timeToProcess: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: (timeToProcess) => !!timeToProcess
        },
        {
          isValid: (straightThroughRate) => straightThroughRate !== undefined && straightThroughRate > 0 && straightThroughRate <= 90,
          errorMessage: 'Value needs to be between 1 and 90'
        },
      ]
    },
    withinTime: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: (withinTime) => !!withinTime
        },
        {
          isValid: (withinTime) => withinTime !== undefined && withinTime > 0,
          errorMessage: 'Value needs to be greater than 0'
        },
      ]
    },
    processExceptionFree: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: (processExceptionFree) => !!processExceptionFree
        },
        {
          isValid: (straightThroughRate) => straightThroughRate !== undefined && straightThroughRate > 0 && straightThroughRate <= 100,
          errorMessage: 'Value needs to be between 1 and 100'
        },
      ]
    },
    startDate: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: (startDate) => !!startDate
        },
        {
          isValid: (startDate) => !isStartDateTaken,
          errorMessage: 'There is already a AP KPI target starting in this date'
        },
      ]
    },
  }

  const submitDisabled = isFormInvalid(record, recordValidator);

  const params = useParams<UrlParams>();
  const history = useHistory();

  React.useEffect(() => {
    if (params.id) {
      KPITargetAPI.fetch(parseInt(params.id)).then((data) => {
        setRecord(data);
      });
    }
  }, [params.id]);

  React.useEffect(() => {
    if (!record.name) {
      setIsNameTaken(false);
      return;
    }

    KPITargetAPI.isFieldValueTaken('name', record.name, record.id).then((result) => {
      setIsNameTaken(result);
    });
  }, [record.name]);

  React.useEffect(() => {
    if (!record.startDate) {
      setIsStartDateTaken(false);
      return;
    }

    KPITargetAPI.isFieldValueTaken('startDate', record.startDate, record.id).then((result) => {
      setIsStartDateTaken(result);
    });
  }, [record.startDate]);

  const onSave = async () => {
    setFormEntered(true);
    if (submitDisabled) return;

    if (record.id) {
      await KPITargetAPI.update(record.id, record);
    } else {
      await KPITargetAPI.create(record);
    }

    history.push(AdminLinks.KpiTargets);
  }

  return (
    <div className="admin__form__wrapper with-custom-scrollbar">
      <AdminHeader
        headline={params.id ? 'Edit Target' : 'Add a Target'}
        tagText="Production workspace"
        links={[{
          label: 'What are AP KPI targets?',
          url: '/knowledge-base/What-are-AP-KPI-targets---3fc296f90bf541ebb9b96c3fa1ab5c4e'
        }]}
      />
      <div className="admin__form__content">
        <div>
          <WrapperLabel text="Target name*">
            <TextInput
              name="name"
              value={record.name || null}
              setter={(value) => setRecord({ ...record, name: value })}
              formEntered={formEntered}
              validators={recordValidator.name.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="Straight through rate*">
            <NumberInput
              value={record.straightThroughRate || null}
              name="straightThroughRate"
              onChange={(value) => value !== undefined && setRecord({ ...record, straightThroughRate: value })}
              precision={0}
              formEntered={formEntered}
              validators={recordValidator.straightThroughRate.validators}
              suffix="%"
            />
          </WrapperLabel>
          <WrapperLabel text="Time to process*">
            <NumberInput
              value={record.timeToProcess || null}
              name="timeToProcess"
              onChange={(value) => value !== undefined && setRecord({ ...record, timeToProcess: value })}
              precision={0}
              formEntered={formEntered}
              validators={recordValidator.timeToProcess.validators}
              suffix="days"
            />
          </WrapperLabel>
          <WrapperLabel text="Within time*">
            <NumberInput
              value={record.withinTime || null}
              name="withinTime"
              onChange={(value) => value !== undefined && setRecord({ ...record, withinTime: value })}
              precision={0}
              formEntered={formEntered}
              validators={recordValidator.withinTime.validators}
              suffix="%"
            />
          </WrapperLabel>
          <WrapperLabel text="Straight through potential*">
            <NumberInput
              value={record.processExceptionFree || null}
              name="processExceptionFree"
              onChange={(value) => value !== undefined && setRecord({ ...record, processExceptionFree: value })}
              precision={0}
              showWarningIcon
              formEntered={formEntered}
              validators={recordValidator.processExceptionFree.validators}
              suffix="%"
            />
          </WrapperLabel>
          <WrapperLabel text="Start date*">
            <DateSelectEditor
              initDate={record.startDate}
              onDateChange={(value) => setRecord({ ...record, startDate: value?.format('YYYY-MM-DD') || null })}
              formEntered={formEntered}
              validators={recordValidator.startDate.validators}
            />
          </WrapperLabel>
        </div>
        <div>
          <Link to={AdminLinks.KpiTargets}><button className="light-button">Cancel</button></Link>
          <button className="full-button" onClick={onSave}>Save</button>
        </div>
      </div>
    </div>
  )
}