import * as React from 'react';

interface Props {
  name: string;
  id?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  title?: string;
  solid?: boolean;
  tooltip?: string;
  style?: React.CSSProperties;
}

/**
 * A helper component for FontAwesome icons
 *
 * You need to ensure that the Font Awesome CSS is loaded elsewhere
 */
export const FAIcon: React.FC<Props> = (props) => {
  const { name, className, onClick, onMouseEnter, onMouseLeave, title, id, solid } = props;
  const classNames = [`fa${solid ? 's' : 'r'}`, `fa-${name}`];
  if (className) classNames.push(className);

  return <span
    style={props.style}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    title={title}
    id={id}
    className={classNames.join(' ')}
    data-tip="tooltip"
    data-for={props.tooltip}
  >
    {props.children}
  </span>;
}
