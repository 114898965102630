import * as React from 'react';
import { APIUser, UserAPI, UserStatus } from '../../../../api/comment';
import { TextInput } from '../../../common/TextInput';
import { WrapperLabel } from '../../../common/WrapperLabel';
import { OptionValue } from 'react-selectize';
import { AuthAPI } from '../../../../api/authentication';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { permissionOptions } from './index';
import { AdminHeader } from '../../AdminHeader';
import { Toggle } from '../../../common/Toggle';
import { AdminLinks } from '../../Admin';
import { CustomSelect } from '../../../common/CustomSelect';
import { ConfirmModal, ConfirmModalType } from '../../../common/ConfirmModal';
import { isValidEmail } from '../../../common/MultiEmailInput';
import { isFormInvalid, RecordValidator } from '../../../common/Form';

interface UrlParams {
  id?: string;
}

interface Props {
  checkboxValue?: boolean;
  checkboxLabel?: string;
  teamOptions: OptionValue[];
  permissionProfileOptions: OptionValue[];
  refetchUserDetails: () => void;
}

export const UserForm: React.FC<Props> = (props) => {
  const [record, setRecord] = React.useState<Partial<APIUser>>({
    companyId: parseInt(localStorage.getItem('companyId') || ''),
    status: UserStatus.Enabled,
  });

  const [isOperatorCodeTaken, setIsOperatorCodeTaken] = React.useState<boolean>(false);
  const [isEmailTaken, setIsEmailTaken] = React.useState<boolean>(false);
  const [formEntered, setFormEntered] = React.useState<boolean>(false);

  const [isConfirmingNotify, setIsConfirmingNotify] = React.useState<boolean>(false);
  const [shouldRepeat, setShouldRepeat] = React.useState<boolean>(false);

  const recordValidator: RecordValidator = {
    firstName: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => !!record.firstName
      }]
    },
    lastName: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => !!record.lastName
      }]
    },
    email: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: () => !!record.email
        },
        {
          errorMessage: 'Not a valid email',
          isValid: () => isValidEmail(record.email || '')
        },
        {
          errorMessage: 'A User already exists with this email',
          isValid: () => !isEmailTaken
        },
      ]
    },
    operatorCode: {
      validators: [{
        errorMessage: 'Code must be unique for each user in the system',
        isValid: () => !isOperatorCodeTaken
      }]
    },
    permissionLevel: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => !!record.permissionLevel
      }]
    },
    teamId: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => !!record.teamId
      }]
    },
    permissionProfileId: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => record.status !== UserStatus.Enabled || !!record.permissionProfileId
      }]
    }
  }

  const submitDisabled = isFormInvalid(record, recordValidator);

  const params = useParams<UrlParams>();
  const recordId = params.id ? parseInt(params.id) : undefined;
  const history = useHistory();

  React.useEffect(() => {
    if (recordId) {
      UserAPI.fetch(recordId).then((data) => {
        setRecord(data);
      });
    }
  }, [params.id]);

  React.useEffect(() => {
    if (!record.operatorCode) {
      setIsOperatorCodeTaken(false);
      return;
    }

    UserAPI.isFieldValueTaken('operatorCode', record.operatorCode, recordId, true, true).then((result) => {
      setIsOperatorCodeTaken(result);
    });
  }, [record.operatorCode]);

  React.useEffect(() => {
    if (!record.email) {
      setIsEmailTaken(false);
      return;
    }

    UserAPI.isFieldValueTaken('email', record.email, recordId, false, false).then((result) => {
      setIsEmailTaken(result);
    });
  }, [record.email]);

  const onUpdate = (change: Partial<APIUser>) => {
    setRecord((record) => ({ ...record, ...change }));
  }

  const onSave = async () => {
    setFormEntered(true);
    if (submitDisabled) {
      setShouldRepeat(false);
      return;
    }

    if (recordId) {
      await UserAPI.update(recordId, record);
      if (recordId === parseInt(localStorage.getItem('userId') || '')) {
        props.refetchUserDetails();
      }
      history.push(AdminLinks.Users);
    } else {
      await UserAPI.create(record);
      setIsConfirmingNotify(true);
    }
  };

  const onNotifyConfirmed = async (confirmed?: boolean) => {
    setIsConfirmingNotify(false);
    if (confirmed) {
      if (record.email) {
        await AuthAPI.requestPasswordReset(record.email, false);
      }
    }
    afterSave();
  }

  const afterSave = () => {
    if (shouldRepeat) {
      setShouldRepeat(false);
      setFormEntered(false);
      setRecord({
        teamId: record.teamId,
        companyId: record.companyId,
        status: UserStatus.Enabled,
      });
    } else {
      history.push(AdminLinks.Users);
    }
  }

  return (
    <div className="admin__form__wrapper with-custom-scrollbar">
      <ConfirmModal
        onHide={() => onNotifyConfirmed(false)}
        show={isConfirmingNotify}
        onConfirm={() => onNotifyConfirmed(true)}
        title="Notify the user"
        text={`
          The user you have added will be emailed to invite them to set their password.
          <br />
          <br />
          <b>Would you like the user to be emailed now?</b>
        `}
        confirmText="Email now"
        cancelText="Email later"
        modalType={ConfirmModalType.Message}
      />
      <AdminHeader
        headline={params.id ? 'Edit User' : 'Add a new User'}
        tagText="Global"
        links={[{
          url: '/knowledge-base/User-Management--9048c99b55ff400da4c1ebce5ec969ef',
          label: 'How to configure users'
        }]}
      />
      <div className="admin__form__content">
        <div>
          <WrapperLabel text="First name*">
            <TextInput
              value={record.firstName || ''}
              name="first-name"
              setter={(value) => onUpdate({ firstName: value })}
              formEntered={formEntered}
              validators={recordValidator.firstName.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="Last name*">
            <TextInput
              value={record.lastName || ''}
              name="last-name"
              setter={(value) => onUpdate({ lastName: value })}
              formEntered={formEntered}
              validators={recordValidator.lastName.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="Email address*">
            <TextInput
              value={record.email || ''}
              name="email"
              setter={(value) => onUpdate({ email: value })}
              formEntered={formEntered}
              validators={recordValidator.email.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="TMS User code">
            <TextInput
              value={record.operatorCode || ''}
              name="operatorCode"
              setter={(value) => onUpdate({ operatorCode: value.toUpperCase() })}
              maxLength={3}
              formEntered={formEntered}
              validators={recordValidator.operatorCode.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="Role*">
            <CustomSelect
              options={permissionOptions}
              value={permissionOptions.find((option) => option.value === record.permissionLevel)}
              onValueChange={(option) => onUpdate({ permissionLevel: option?.value })}
              formEntered={formEntered}
              validators={recordValidator.permissionLevel.validators}
            />
          </WrapperLabel>
          <WrapperLabel text={`Permission profile${record.status === UserStatus.Enabled ? '*' : ''}`}>
            <CustomSelect
              options={props.permissionProfileOptions}
              value={props.permissionProfileOptions.find((option) => option.value === record.permissionProfileId)}
              onValueChange={(value) => onUpdate({ permissionProfileId: value?.value || null })}
              formEntered={formEntered}
              validators={recordValidator.permissionProfileId.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="Team*">
            <CustomSelect
              options={props.teamOptions}
              value={props.teamOptions.find((option) => option.value === record.teamId)}
              onValueChange={(value) => onUpdate({ teamId: value?.value })}
              formEntered={formEntered}
              validators={recordValidator.teamId.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="Site access">
            <Toggle
              id="siteAccess"
              checked={record.status === UserStatus.Enabled}
              onChecked={(name, value) => onUpdate({ status: value ? UserStatus.Enabled : UserStatus.Disabled })}
            />
          </WrapperLabel>
        </div>
        <div>
          <Link to={AdminLinks.Users}><button className="light-button">Cancel</button></Link>
          {recordId ? (
            <button className="full-button" onClick={onSave}>Save</button>
          ) : (
            <>
              <button className="full-button mr-[10px]" onClick={onSave}>Add</button>
              <button
                className="full-button"
                onClick={() => {
                  setShouldRepeat(true);
                  onSave();
                }}
              >Add & Repeat</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
