import * as React from 'react';
import { Column } from 'react-data-grid';
import { AdminHeader } from '../../AdminHeader';
import { Link } from 'react-router-dom';
import { AdminGrid, AdminGridSearchParams } from '../../AdminGrid';
import { APITeam, TeamAPI } from '../../../../api/teams';
import { AdminLinks } from '../../Admin';

interface Props {

}

export const TeamsTable: React.FC<Props> = (props) => {
  const fetchTeams = (params?: AdminGridSearchParams) => {
    return TeamAPI.fetchAll(params);
  };

  const columns: Column<APITeam>[] = React.useMemo(() => [
    {
      key: 'name', name: 'Team name',
    },
    {
      key: 'actions', name: 'Actions', formatter: (tableProps) => {
        return <Link to={`${AdminLinks.Teams}/form/${tableProps.row.id}`}><button className="light-button active-on-hover">Edit</button></Link>;
      },
    },
  ], []);

  return (
    <div>
      <AdminHeader
        headline="Teams"
        tagText="Global"
        links={[{
          label: 'What are Teams?',
          url: '/knowledge-base/What-are-Teams--ed0776779aad4658923c380a7767e62c'
        }]}
      />
      <AdminGrid
        searchPlaceholder="Search teams..."
        columns={columns}
        fetchData={fetchTeams}
        link={{
          url: AdminLinks.Teams + '/form',
          label: 'Add a new Team'
        }}
      />
    </div>
  )
};
