import * as React from 'react';
import { FAIcon } from '../common/FAIcon';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { ShipamaxQueueIcon, ShipamaxUsersIcon } from '../../images/Icons';

interface Props {
  id: number;
  title: string;
  date: string;
  name: string;
  description: string;
  selected: boolean;
  isResubmitted: boolean;
  isReassigned: string;

  onClick: (id: number) => void;
}

export const Card: React.FC<Props> = (props) => {
  const onCardClicked = () => {
    props.onClick(props.id);
  }

  return (
    <div className={`card ${props.selected ? 'selected' : ''}`} onClick={onCardClicked}>
      <div>
        <div className="title" title={props.title}>{props.title}</div>
        <div className="date" title={props.date}>{props.date}</div>
      </div>
      <div className="name" title={props.name}>{props.name}</div>
      <div>
        <div className="description" title={props.description}>{props.description}</div>
        <div className="icons">
          {props.isReassigned &&
            <OverlayTrigger overlay={<Tooltip><span>{props.isReassigned}</span></Tooltip>} delayShow={500} placement="top">
              <span data-tip="tooltip" data-for={`tooltip-${props.isReassigned}`}><ShipamaxUsersIcon /></span>
            </OverlayTrigger>
          }
          {props.isResubmitted && <span><ShipamaxQueueIcon /></span>}
        </div>
      </div>
    </div>
  );
}
