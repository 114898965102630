import { request } from './request';
import { ExchangeRateType } from './supplierInvoice';

export interface APICurrency {
  id: number;
  created: string;
  currency: string;
}

export interface APICgwCurrencyExchangeRates extends ExchangeRateType {

}

export const CurrencyAPI = {
  async fetchAll(): Promise<APICurrency[] | null> {
    const filter = JSON.stringify({ order: 'currency ASC' });

    return request(`/Currencies?filter=${filter}`);
  },

  async fetchCargowiseCurrencyExchangeRates(): Promise<APICgwCurrencyExchangeRates[]> {
    const filter = JSON.stringify({ where: { companyId: parseInt(localStorage.getItem('companyId') + '') } });
    return request(`/CargowiseExchangeRates?filter=${filter}`);
  }
}
