import { request } from './request';
import { APIDocumentGroupPackingList } from './documentGroup';
import { APIDisassociateDocumentId } from './documentContainer';

export type Filter = { [k: string]: string | string[] | number | number[] | boolean | null };

export const PackingListAPI = {
  async update(id: number, packingList: Partial<APIDocumentGroupPackingList> | APIDisassociateDocumentId): Promise<unknown> {
    return request(`/PackingLists/${id}`, 'PATCH', packingList);
  },

  async fetch(id: number): Promise<APIDocumentGroupPackingList> {
    return request(`/PackingLists/${id}`, 'GET');
  },

  async bulkUpdate(ids: number[], change: Partial<APIDocumentGroupPackingList>): Promise<unknown> {
    return request(`/PackingLists/bulkUpdate`, 'PATCH', {
      ids,
      change
    });
  },
}
