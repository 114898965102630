import { request } from './request';
import { createRecordId, getCreditorAddressId, getCreditorAliasId, getCreditorCWMapId } from '../components/helpers';
import { OptionValue } from 'react-selectize';
import { IssuerDetailMap } from '../components/bill-of-lading/common';

export const trimRecordId = (recordId: string): string => {
  return recordId.split('-').slice(0, 3).join('-');
}

export interface APIIssuerAddress {
  id: number;
  addressLine1: string;
  addressLine2: string;
  postCode: string;
  city: string;
  stateProvince: string;
  countryCode: string;
  unloco: string;
  landline: string;
  mobile: string;
  fax: string;
  email: string;
  creditorId: number;
  organizationId: number;
  main: boolean;
}

export interface APIIssuerAlias {
  id: number;
  name: string;
  organizationId: number;
  main: boolean;
}

export interface APIIssuerCgwCodes {
  id: number;
  cgwCode: string;
  companyId: number;
  creditorId: number;
  addresses: APIIssuerAddress[];
  aliases: APIIssuerAlias[];
}

export interface CreditorFlagsFilter {
  creditor?: boolean;
  shipper?: boolean;
  consignee?: boolean;
  carrier?: boolean;
}

export const IssuerAPI = {
  async search(searchTerm: string, flags: CreditorFlagsFilter = {}, companyCode: string | null = null): Promise<APIIssuerCgwCodes[]> {
    return request(`/CreditorCwmaps/search`, 'POST', {
      searchTerm,
      companyId: parseInt(localStorage.getItem('companyId') || ''),
      companyCode: companyCode || undefined,
      flags
    });
  },

  async fetchIssuerDetailsMap(recordIds: string[]): Promise<IssuerDetailMap> {
    const creditorCWMapIds: number[] = [];
    const creditorAddressIds: number[] = [];
    const creditorAliasIds: number[] = [];

    recordIds.forEach((recordId) => {
      const cwMapId = getCreditorCWMapId(recordId);
      const addressId = getCreditorAddressId(recordId);
      const aliasId = getCreditorAliasId(recordId);

      if (cwMapId) {
        creditorCWMapIds.push(cwMapId);
      }

      if (addressId) {
        creditorAddressIds.push(addressId);
      }

      if (aliasId) {
        creditorAliasIds.push(aliasId);
      }
    });

    const filter = JSON.stringify({
      where: {
        id: { inq: creditorCWMapIds },
        companyId: localStorage.getItem('companyId'),
      },
      include: [
        {
          relation: 'addresses',
          scope: {
            where: {
              id: { inq: creditorAddressIds },
            }
          }
        },
        {
          relation: 'aliases',
          scope: {
            where: {
              id: { inq: creditorAliasIds },
            }
          }
        }
      ]
    });

    const issuersData = await request(`/CreditorCwmaps?filter=${filter}`);
    return transformIssuerDataToMap(issuersData);
  },

  async fetchAllCreditorsAddressesAsMap(recordId: string, offset: number, limit: number): Promise<IssuerDetailMap> {
    const creditorCWMapId = getCreditorCWMapId(recordId);
    const creditorAliasId = getCreditorAliasId(recordId);

    const filter = JSON.stringify({
      where: {
        id: creditorCWMapId,
        companyId: localStorage.getItem('companyId'),
      },
      include: [
        {
          relation: 'addresses',
          scope: {
            limit,
            skip: offset,
            where: {
              active: true,
            }
          }
        },
        {
          relation: 'aliases',
          scope: {
            where: {
              ...(creditorAliasId ? { id: creditorAliasId } : { main: true })
            },
            limit: 1
          }
        },
      ]
    });

    const issuersData = await request(`/CreditorCwmaps?filter=${filter}`);
    return transformIssuerDataToMap(issuersData);
  },

  async createNewCreditor(cgwCode: string, flags: CreditorFlagsFilter = {}): Promise<number> {
    return request(`/CreditorCwmaps/createCreditor?cgwCode=${cgwCode}&companyId=${parseInt(localStorage.getItem('companyId') || '')}&flags=${JSON.stringify(flags)}`);
  }
}

export const transformIssuerDataToMap = (issuersData: APIIssuerCgwCodes[]): IssuerDetailMap => {
  return issuersData.reduce((map: IssuerDetailMap, issuer) => {
    if (issuer) {
      map[issuer.id] = { // add to the map data for issuer without the address
        id: issuer.id,
        name: issuer?.aliases.find(alias => { return alias.main })?.name || issuer?.aliases[0]?.name || '',
        cgwCode: issuer.cgwCode
      };

      issuer.addresses.forEach((address) => { // we might have issuer with two addresses, where both of them were parsed
        issuer?.aliases.forEach((alias) => {
          map[createRecordId(issuer.id, alias.id, address.id)] = {
            id: issuer.id,
            address,
            name: alias.name || '',
            cgwCode: issuer.cgwCode
          };
        })
      })
    }

    return map;
  }, {});
}

export const mapCreditorsToOptionValues = (items: APIIssuerCgwCodes[]): OptionValue[] => {
  const result: OptionValue[] = [];
  items.forEach((item) => {
    if (item.aliases.length > 0) {
      item.aliases.forEach((alias) => {
        result.push({
          label: `${item.cgwCode} - ${alias.name}`,
          value: createRecordId(item.id, alias.id, item?.addresses[0]?.id)
        });
      })
    } else {
      result.push({
        label: item.cgwCode,
        value: `${item.id}`
      });
    }
  });
  return result;
}
