import * as React from 'react';
import { AdminHeader } from '../../AdminHeader';
import { Column } from 'react-data-grid';
import { AdminGrid, AdminGridHandle, AdminGridTagsList, AdminGridTagType } from '../../AdminGrid';
import { APIApiKey, ApiKeyAPI } from '../../../../api/apiKey';
import { APIUser } from '../../../../api/comment';
import * as moment from 'moment/moment';
import { ConfirmModal } from '../../../common/ConfirmModal';
import { ShowFullKeyConfirm } from './ShowFullKeyConfirm';
interface Props {
  users: APIUser[];
}

export const APIKeyManagementTable: React.FC<Props> = (props) => {
  const [apiKeyToBeRevoked, setApiKeyToBeRevoked] = React.useState<number | undefined>();
  const [showTooManyApiKeysMessage, setShowTooManyApiKeysMessage] = React.useState<boolean>(false);
  const [apiKeyToBeShown, setApiKeyToBeShown] = React.useState<number | undefined>();
  const [visibleApiKeys, setVisibleApiKeys] = React.useState<{ [key: number]: string }>({});

  const gridRef = React.useRef<AdminGridHandle>(null);

  const fetchAllAPIKeys = () => {
    return ApiKeyAPI.fetchAll();
  };

  const onRevokeConfirmed = async () => {
    if (!apiKeyToBeRevoked) return;

    await ApiKeyAPI.revokeApiKey(apiKeyToBeRevoked);
    setApiKeyToBeRevoked(undefined);
    gridRef.current?.refetchData();
  }

  const onGenerateNewKey = async () => {
    const existingApiKeys = await ApiKeyAPI.fetchAll();

    if (existingApiKeys.filter((apiKey) => apiKey.active).length >= 3) {
      setShowTooManyApiKeysMessage(true);
    } else {
      await ApiKeyAPI.generateApiKey();
      gridRef.current?.refetchData();
    }
  }

  const showFullApiKey = async () => {
    if (!apiKeyToBeShown) return;

    const apiKey = await ApiKeyAPI.fetch(apiKeyToBeShown);
    if (apiKey) {
      setVisibleApiKeys((currentMap) => ({ ...currentMap, [apiKeyToBeShown]: apiKey.token }));
    }
    setApiKeyToBeShown(undefined);
  }

  const columns: Column<APIApiKey>[] = React.useMemo(() => [
    {
      key: 'token', name: 'API Key', width: 250, formatter: (tableProps) => {
        return <>{visibleApiKeys[tableProps.row.id] || tableProps.row.token}</>
      }
    },
    {
      key: 'created', name: 'Date created', width: 130, formatter: (tableProps) => {
        return <>{tableProps.row.created ? moment(tableProps.row.created)?.format('DD MMM YYYY') : ''}</>
      }
    },
    {
      key: 'revokedDate', name: 'Date revoked', width: 130, formatter: (tableProps) => {
        return <>{tableProps.row.revokedDate ? moment(tableProps.row.revokedDate)?.format('DD MMM YYYY') : <span style={{ textAlign: 'center', paddingLeft: '30px' }}>-</span>}</>
      }
    },
    {
      key: 'createdByUser', name: 'Creating User', formatter: (tableProps) => {
        const user = tableProps.row.createdByUser && props.users.find((user) => user.id === tableProps.row.createdByUser);
        return <>{user ? `${user.firstName } ${user.lastName}` : ''}</>
      },
    },
    {
      key: 'revokedByUser', name: 'Revoking User', formatter: (tableProps) => {
        const user = tableProps.row.revokedByUser && props.users.find((user) => user.id === tableProps.row.revokedByUser);
        return <>{user ? `${user.firstName } ${user.lastName}` : <span style={{ textAlign: 'center', paddingLeft: '40px' }}>-</span>}</>
      },
    },
    {
      key: 'active', name: 'Status', formatter: (tableProps) => {
        return tableProps.row.active ? (
          <AdminGridTagsList items={['Active']} type={AdminGridTagType.Green} />
        ) : (
          <AdminGridTagsList items={['Revoked']} type={AdminGridTagType.Default} />
        );
      }
    },
    {
      key: 'actions', name: 'Actions', minWidth: 170, formatter: (tableProps) => {
        return (
          <>
            {!visibleApiKeys[tableProps.row.id] && (
              <button className="light-button active-on-hover" onClick={() => setApiKeyToBeShown(tableProps.row.id)}>Show full key</button>
            )}
            {tableProps.row.active && (
              <button
                className="light-button active-on-hover"
                onClick={() => {
                  setApiKeyToBeRevoked(tableProps.row.id);
                }}
              >Revoke</button>
            )}
          </>
        );
      },
    }
  ], [visibleApiKeys, props.users]);

  return (
    <div className="api-key-management-section">
      <AdminHeader
        headline="API Key Management"
        tagText="Global"
      />
      <ConfirmModal
        show={!!apiKeyToBeRevoked}
        title="Revoke Token Access"
        text={`
          Revoking token access will result in the failure of all your API requests using this token. <br />
          <br />
          Please ensure you have a replacement token in use. <br />
          <br />
          <b>Would you like to continue?</b>
`       }
        onHide={() => setApiKeyToBeRevoked(undefined)}
        onConfirm={onRevokeConfirmed}
      />
      <ConfirmModal
        show={showTooManyApiKeysMessage}
        title="Active API Key Limit Reached"
        text={`
          The maximum number of active API keys has been reached.<br />
          <br />
          Please revoke a key before generating a new one. 
`       }
        onHide={() => setShowTooManyApiKeysMessage(false)}
      />
      {apiKeyToBeShown && (
        <ShowFullKeyConfirm
          onConfirmed={() => {
            showFullApiKey();
          }}
          onCancelled={() => {
            setApiKeyToBeShown(undefined)
          }}
        />
      )}
      <AdminGrid
        ref={gridRef}
        hideSearch
        columns={columns}
        fetchData={fetchAllAPIKeys}
        link={{
          label: 'Generate New Token',
          onClick: onGenerateNewKey
        }}
      />
    </div>
  );
};
