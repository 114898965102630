import { request } from './request';

export interface APICountry {
  id: number;
  code: string;
  name: string;
}

export const CountryAPI = {
  async fetchAll(): Promise<APICountry[]> {
    return request(`/Countries`);
  }
}
