import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AdminLinks } from '../../../Admin';
import { MailboxForm } from './MailboxForm';
import { OptionValue } from 'react-selectize';
import { SiteAPI } from '../../../../../api/sites';
import { MailboxesTable } from './MailboxesTable';
import { TeamAPI } from '../../../../../api/teams';

import './index.scss';

interface Props {
  countryOptions: OptionValue[];
  currencyOptions: OptionValue[];
}

export const APWorkflowSection: React.FC<Props> = (props) => {
  const [siteOptions, setSiteOptions] = React.useState<OptionValue[]>([]);
  const [teamOptions, setTeamOptions] = React.useState<OptionValue[]>([]);

  React.useEffect(() => {
    fetchReferenceDataFromAPI();
  }, []);

  const fetchReferenceDataFromAPI = async () => {
    const [sites, teams] = await Promise.all([SiteAPI.fetchAll(), TeamAPI.fetchAll()]);
    setSiteOptions(sites.map((site) => ({ label: site.name, value: site.id })));
    setTeamOptions(teams.filter((team) => !team.disabled).map((team) => ({ label: team.name, value: team.id })));
  }

  return (
    <Switch>
      <Route path={AdminLinks.ApReconciliationWorkflows} exact component={() => (
        <MailboxesTable teamOptions={teamOptions} siteOptions={siteOptions} />
      )} />
      <Route path={[AdminLinks.ApReconciliationWorkflows + '/form', AdminLinks.ApReconciliationWorkflows + '/form?env=:env', AdminLinks.ApReconciliationWorkflows + '/form/:id']} exact component={() => (
        <MailboxForm
          teamOptions={teamOptions}
          currencyOptions={props.currencyOptions}
          countryCodeOptions={props.countryOptions}
          siteOptions={siteOptions}
        />
      )} />
    </Switch>
  )
};
