import * as React from 'react';
import { CompanyAPI } from '../../../api/company';
import { formatAggregationValue, SerieSettings, SerieToggleState, tolerancesModifier } from '../common';
import { ChartCommonProps, ChartFilterProps, CounterType } from '../ChartWrapper';
import { GeneralChart } from '../GeneralChart';
import { Map2 } from '../../helpers';
import { formatNumber } from '../../common/NumberInput';
import { numFormatter, removeDataForSaveToggles } from '../utils';
import * as moment from 'moment';

export enum TolerancesSerieId {
  TotalValueBeforePosting = 1,
  AccrualsIncreases = 2,
  AccrualsDecreases = 3,
  TotalPosted = 3,
}

export type SeriesDataType = {
  [key: number]: {
    label: string,
    color: string
  }
}

const seriesData = new Map2<number, SerieSettings>([
  [
    TolerancesSerieId.AccrualsIncreases,
    {
      label: 'Sum of accrual increases',
      color: '#E15B63',
    }
  ],
  [
    TolerancesSerieId.AccrualsDecreases,
    {
      label: 'Sum of accrual decreases',
      color: '#84A59D',
    }
  ],
]);

interface Props extends ChartCommonProps {
  apiFilter: ChartFilterProps;
}

export const TolerancesChart: React.FC<Props> = (props) => {

  const fetchChartData = async () => {
    if (!props.apiFilter.dateRange) return [];

    const result = await CompanyAPI.getApInsightsChartData(props.apiFilter);

    let processedChartData: any[] = result.map((dataPoint) => {
      const [increase, decrease, total] = dataPoint.values.length ? [
        dataPoint.values[0].increase,
        dataPoint.values[0].decrease,
        dataPoint.values[0].accrualAmount
      ] : [0, 0, 0];

      let keys: any = dataPoint.values.length ? {
        [TolerancesSerieId.AccrualsIncreases]: increase,
        [TolerancesSerieId.AccrualsDecreases]: decrease,
        ['value-' + TolerancesSerieId.AccrualsIncreases]: increase,
        ['value-' + TolerancesSerieId.AccrualsDecreases]: decrease,
        ['percentage-' + TolerancesSerieId.AccrualsIncreases]: 100.0 * increase / total,
        ['percentage-' + TolerancesSerieId.AccrualsDecreases]: 100.0 * decrease / total,
        total,
        maxValue: increase || decrease,
      } : {};

      if (props.apiFilter.userOptions && props.removeFilteredData) removeDataForSaveToggles(keys, props.apiFilter.userOptions);

      return {
        name: dataPoint.aggregation,
        ...keys,
      };
    });

    return processedChartData || [];
  }

  return (
    <GeneralChart
      referenceData={props.referenceData}
      apiFilter={props.apiFilter}
      seriesData={seriesData}
      fetchChartData={fetchChartData}
      isFetching={props.isFetching}
      setIsFetching={props.setIsFetching}
      counterType={props.counterType}
      groupMode="grouped"
      innerPadding={1}
      showToggles={!props.hideToggles}
      compactToggles
      leftAxisSettings={{
        axisLegend: props.counterType === CounterType.InvoicePercentage ? 'Percentage of accrual changes to value accrued before posting' : `Value of accrual changes (${props.apiFilter.localCurrency})`,
        axisFormat: (value) => numFormatter(value as number)
      }}
      tooltip={(target) => (
        <div className="chart-tooltip chart-tooltip--tolerances">
          <b>{formatAggregationValue(target.indexValue, props.apiFilter.aggregation, false, props.referenceData)}</b><br />
          {!tolerancesModifier.includes(props.apiFilter.aggregation) && (
            <div>
              <span>Percentage</span>
              <span>{formatNumber(target.data['percentage-' + target.id] as number, {})}%</span>
            </div>
          )}
          <div>
            <span>{target.id === TolerancesSerieId.AccrualsIncreases ? 'Increase' : 'Decrease'}</span>
            <span>{formatNumber(target.data['value-' + target.id] as number, {})} {props.apiFilter.localCurrency}</span>
          </div>
          {!tolerancesModifier.includes(props.apiFilter.aggregation) && (
            <div>
              <span>Total invoice value</span>
              <span>{formatNumber(target.data.total as number, {})} {props.apiFilter.localCurrency}</span>
            </div>
          )}
        </div>
      )}
      chartSettings={{ reversedKeys: false, useMaxValue: true }}
      setNotification={props.setNotification}
      renderForCustomDashboard={props.renderForCustomDashboard}
      renderAddToButton={props.renderAddToButton}
      permissionLevel={props.permissionLevel}
      customDashboards={props.customDashboards}
      insightsSettings={props.insightsSettings}
      chartUserSettings={props.chartUserSettings}
      renderExportButton={true}
      exportFilename={`Shipamax_Tolerances_Report_${moment().format('DDMMYYYY')}.csv`}
      exportType='ap'
      customPageSize={props.customPageSize}
      getChartToggles={props.getChartToggles}
      productSection={props.productSection}
    />
  )
}
