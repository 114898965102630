import * as React from 'react';
import { FileSplitterColumn } from './FileSplitter';
import { OptionValue } from 'react-selectize';
import { TooltipTrigger } from 'pivotal-ui/react/tooltip';
import { ShipamaxMoveIcon, ShipamaxMoveNewPageIcon, ShipamaxMovePageIcon } from '../../../images/Icons';

import './move-menu.scss';

interface Props {
  columns: FileSplitterColumn[];
  column?: FileSplitterColumn;
  documentTypesOptions: OptionValue[];
  activePageIndex: number;
  setColumns?: (value: FileSplitterColumn[]) => void;
  moveMultiple?: boolean | false;
  selectedPages?: { pageIndex: number, columnIndex: number }[];
  disabled?: boolean;
  clearSelectedPages?: () => void;
}

export const MoveMenu: React.FC<Props> = (props) => {
  const activePageNumber = props.activePageIndex + 1;
  const [showMenu, setShowMenu] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const listRef = React.useRef<HTMLLIElement>(null);

  const updateAllColumns = (targetColumn: FileSplitterColumn | null) => {
    setShowMenu(false);
    const sourceColumnIndex = props.column ? props.columns.indexOf(props.column) : props.selectedPages ? props.selectedPages[0].columnIndex : 0;
    const allPagesToMove = props.column ? [{ pageIndex: props.columns[sourceColumnIndex].pages.indexOf(props.activePageIndex), columnIndex: sourceColumnIndex }] : props.selectedPages;
    const pagesToMove: number[] = []
    const sourcePages = Array.from(props.columns[sourceColumnIndex].pages);
    let destinationPages: number[] = [];
    let targetColumnIndex = 0;

    if (targetColumn) {
      targetColumnIndex = props.columns.indexOf(targetColumn);
      destinationPages = Array.from(props.columns[targetColumnIndex].pages);

      if (targetColumnIndex === sourceColumnIndex) return;
    }

    allPagesToMove && allPagesToMove.map((page, index) => {
      pagesToMove.push(props.columns[sourceColumnIndex].pages[page.pageIndex]);

      if (targetColumn) {
        destinationPages.push(pagesToMove[index]);
      }
    })

    allPagesToMove && allPagesToMove.slice(0).reverse().map((page) => {
      if (targetColumn) {
        sourcePages.splice(page.pageIndex, 1);
      } else {
        props.columns[sourceColumnIndex].pages.splice(page.pageIndex, 1);
      }
    })

    if (targetColumn) {
      if (!sourcePages.length) props.columns.splice(sourceColumnIndex, 1);

      props.setColumns && props.setColumns(props.columns.map((column, index) => {
        return index === sourceColumnIndex && sourcePages.length ? { ...column, pages: [...sourcePages] } : index === targetColumnIndex ? { ...column, pages: [...destinationPages] } : column;
      }));
    } else {
      // add new column
      props.columns.push({ pages: pagesToMove });
      props.setColumns && props.setColumns([...props.columns]);
    }

    props.clearSelectedPages && props.clearSelectedPages();
  }

  const handleoutSideClick = (e: MouseEvent) => {
    if ((buttonRef.current && !buttonRef.current.contains(e.target as HTMLElement)) && (listRef.current && !listRef.current.contains(e.target as HTMLElement))) {
      setShowMenu(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener("click", handleoutSideClick, true)
  }, [])

  return (
    <>
      <TooltipTrigger tooltip={props.moveMultiple ? props.selectedPages && props.selectedPages.length < 2 ? "Select Multiple Pages" : "Move Selected Pages To..." : props.selectedPages && props.selectedPages.length > 1 ? "Use Move Selected Pages" : "Move Page To..."}>
      <button
        ref={buttonRef}
          className="light-button move-page-button"
        onClick={(e) => { setShowMenu(!showMenu); }}
          disabled={(props.moveMultiple && props.selectedPages && props.selectedPages.length < 2) || (!props.moveMultiple && props.selectedPages && props.selectedPages.length > 1) || props.disabled}
        >
          <ShipamaxMoveIcon />
          {`${props.moveMultiple ? "Move Selected" : "Move"}`}

      </button>
      </TooltipTrigger >
      <ul className={`move-page-menu ${props.moveMultiple ? "multiple" : ""}`} style={{ display: showMenu ? "inline-block" : "none" }}>
        <li>Move <span className='current-page-number'>
          {props.moveMultiple ? (
            "Selected Pages"
          ) : (
            `Page ${activePageNumber}`
          )}</span> to...</li>

        {props.columns.map((column, index) => {
          const documentType = props.documentTypesOptions.filter((item) => {
            return item.value === column.documentType;
          });

          return (
            <li ref={listRef} key={`doc-${index}`}
              onClick={() => { updateAllColumns(column); }}>
              <ShipamaxMovePageIcon />
              {documentType[0] && documentType[0].label}
              {column.document && column.document.documentAlias ? ` (${column.document.documentAlias})` : ` (Document ${index + 1})`}
            </li>
          );
        })}
        <li onClick={() => updateAllColumns(null)}>
          <ShipamaxMoveNewPageIcon />
          New Document
        </li>
      </ul>
    </>
  )
}
