import * as React from 'react';
import * as moment from 'moment';
import { Chrono, options } from 'chrono-node';
import { DateParser, refineYear, refineMonth, hasOverlap } from '../helpers';
import { APIDocCoordinate, FieldData, findMatchedCoordinates } from '../../api/documentContainer';
import { DocumentViewerContext, OldContext } from '../DocumentViewerContext';
import { FieldValidator } from './Form';

import './date-select-editor.scss';

type DateOption = {
  value: moment.Moment,
  label: string,
  valid: boolean,
  message: string
};

interface OwnState {
  options: DateOption[];
  currentOption: DateOption | null;
  inputValue: string;
  clearOnBlur: boolean;
  renderFromInit: boolean;
  selectedOption: number | null;
}

interface OwnProps {
  id?: string;
  onDateChange: (date: moment.Moment | null, updateInvoice?: boolean, fieldData?: FieldData) => void;
  placeholderText?: string;
  disabled?: boolean;
  initDate?: moment.MomentInput | null;
  minDate?: moment.MomentInput;
  maxDate?: moment.MomentInput;
  fieldData?: FieldData;
  required?: boolean;
  className?: string;
  onFocus?: () => void;
  validators?: FieldValidator[];
  formEntered?: boolean;
  dataTestId?: string;
}

type Props = OwnProps;
type State = OwnState;

export class DateSelectEditor extends React.Component<Props, State> {
  static contextType = DocumentViewerContext;
  // @ts-ignore
  context: React.ContextType<typeof DocumentViewerContext>;
  // @ts-ignore
  private oldContext: OldContext<React.ContextType<typeof DocumentViewerContext>>;

  inputRef = React.createRef<HTMLInputElement>();

  private chrono: DateParser.Chrono;
  private matchedCoordinates: APIDocCoordinate[] = [];

  constructor(props: Props) {
    super(props);

    this.state = {
      options: [],
      currentOption: null,
      inputValue: '',
      renderFromInit: false,
      clearOnBlur: false,
      selectedOption: null,
    };

    this.chrono = new Chrono(
      options.mergeOptions(
        [
          options.en_GB.casual,
          options.commonPostProcessing,
        ]
      )
    );

    this.chrono.refiners.push(refineYear);
    this.chrono.refiners.push(refineMonth);

    this.initialDate = this.initialDate.bind(this);
    this.onSelectorBlur = this.onSelectorBlur.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOptions = this.handleOptions.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
  }

  componentDidMount() {
    this.oldContext = new OldContext(this.context);

    window.addEventListener('keydown', this.handleKeyPress, false);
    if (this.props.initDate) this.initialDate();
  }

  componentDidUpdate(prevProps: Props) {
    this.matchedCoordinates = findMatchedCoordinates(this.props.fieldData || null, this.context.coordinates) || null;

    if (this.matchedCoordinates && JSON.stringify(this.context.focusedCoordinates) !== JSON.stringify(this.oldContext.value.focusedCoordinates)) {
      if (hasOverlap<APIDocCoordinate>(this.context.focusedCoordinates, this.matchedCoordinates)) { // we should focus element
        this.inputRef.current?.focus();
      }
    }

    if (JSON.stringify(prevProps.initDate) !== JSON.stringify(this.props.initDate)) {
      this.initialDate();
    } else if (prevProps.initDate && !this.props.initDate) {
      this.handleClear();
    }

    if (this.oldContext.isOutdated()) {
      this.oldContext.update()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress, false);
  }

  handleSubmit(option: DateOption | null) {
    if (option && option.valid) {
      this.removeCoordinates();
      this.props.onDateChange(option.value, true, this.props.fieldData);
      this.handleInputChange(option.label, true);
      this.setState({
        options: [],
        currentOption: option,
        clearOnBlur: false,
        selectedOption: null,
      });
    }
  }

  handleClear() {
    this.removeCoordinates();
    this.props.onDateChange(null, false, this.props.fieldData);
    this.handleInputChange('', true);
  }

  handleKeyPress() {
    const hasOptions = Boolean(this.state.options.length);
    if (document.activeElement && hasOptions) {
      if (document.activeElement.classList && document.activeElement.classList.contains('date-selector__option')) {
        this.setState({ selectedOption: null });
      }
    }
  }

  private getOptionFromMoment(dt: moment.Moment) {
    dt.startOf('day');

    return {
      value: dt,
      label: dt.format('DD MMM YYYY'),
      valid: true,
      message: null,
    };
  }

  handleInputChange(inputValue: string, calledFromOnClick: boolean) {
    this.setState({ inputValue }, () => {
      /**
       * If this is called from the onClick in the render method, we don't need to check for options again,
       * because the option is where the value came from.
      */
      if (!calledFromOnClick) {
        this.handleOptions();
      }
    });
  }

  onSelectorBlur(e: React.FormEvent<any>) {
    const currentTarget = e.currentTarget;
    const inputIsValidDate = Boolean(
      moment(this.state.inputValue, 'DD MMM YYYY', true).isValid() &&
      this.state.currentOption &&
      this.state.inputValue === this.state.currentOption.label
    );

    setTimeout(() => {
      // New Element not focused before onBlur fires, so timeOut required
      if (!currentTarget.contains(document.activeElement)) {
        if (this.state.clearOnBlur) {
          this.setState({ options: [], selectedOption: 0 });
          if (!inputIsValidDate) {
            this.handleClear();
          }
        }
      }
    }, 10);
  }

  initialDate() {
    if (this.props.initDate) {
      const option = this.getOptionFromMoment(
        moment(
          this.props.initDate
        )
      );
      const options = Array(option);

      // @ts-ignore
      this.setState({ options, inputValue: option.label, renderFromInit: true, currentOption: option });
    } else {
      this.handleInputChange('', true);
    }
  }

  handleOptions() {
    let options: any[] = [];
    if (moment(this.state.inputValue, ['DDMM', 'DDMMYY', 'DDMMYYYY'], true).isValid()) {
      options = [this.getOptionFromMoment(moment(this.state.inputValue, 'DDMMYYYY'))];
    } else {
      const parsed = this.chrono.parse(this.state.inputValue, null, { forwardDate: false }) as DateParser.ResultWrapper[];
      options = parsed.map((result) => this.getOptionFromMoment(moment(result.start.date())));
    }

    if (this.props.minDate && options.length) {
      options = options.map((option) => {
        if (moment(this.props.minDate).isBefore(option.value) || moment(this.props.minDate).isSame(option.value)) {
          return option;
        } else {
          option.valid = false;
          option.message = 'Date must be after or equal to "From Date"';
          return option;
        }
      });
    }

    if (this.props.maxDate && options.length) {
      options = options.map((option) => {
        if (moment(option.value).isBefore(this.props.maxDate) || moment(this.props.maxDate).isSame(option.value)) {
          return option;
        } else {
          option.valid = false;
          option.message = 'Date must be before or equal to "To Date"';
          return option;
        }
      });
    }

    if (this.state.inputValue === '') {
      this.removeCoordinates();
      this.props.onDateChange(null, true, this.props.fieldData);
    }

    this.state.options.forEach((old: any) => {
      if (!options.find((option) => moment(option.value).isSame(old.value))) {
        options.push(old);
      }
    });

    this.setState({ options, renderFromInit: false });
  }

  handleMouseEnter(index: number) {
    this.setState({ selectedOption: index });
  }

  removeCoordinates() {
    this.props.fieldData && this.context.removeCoordinates({
      parentTableName: this.props.fieldData.tableName,
      parentTableIds: this.props.fieldData.objectIds,
      attributeNames: this.props.fieldData.attributeNames
    });
  }

  render() {
    const options = this.state.options;
    const renderFromInit = this.state.renderFromInit;

    const triggeredValidator = this.props.formEntered ? this.props.validators?.find((validator) => {
      if (!validator.isValid(this.props.initDate)) {
        return true;
      }
    }) : null;

    const cssClasses = [
      'date-selector-editor',
      (this.props.required && this.props.fieldData?.objectIds.find((id) => id !== -1) && !this.state.inputValue) ? 'date-selector-editor--highlight' : '',
      this.props.disabled ? 'date-selector-editor--disabled' : '',
      (this.props.formEntered && triggeredValidator) ? 'date-selector-editor--highlight' : '',
    ];

    return (
      <div className={`date-selector ${this.props.className}`} onBlur={this.onSelectorBlur}>
        <input
          data-test-id={this.props.dataTestId}
          ref={this.inputRef}
          type="text"
          className={cssClasses.join(' ')}
          autoComplete="off"
          id={this.props.id || ''}
          placeholder={this.props.placeholderText}
          onFocus={() => {
            this.context.setFocusedCoordinates(this.matchedCoordinates);
            this.setState({ clearOnBlur: true })
            if (this.props.onFocus) this.props.onFocus()
          }}
          onBlur={() => this.context.setFocusedCoordinates([])}
          onChange={(e) => this.handleInputChange(e.target.value, false)}
          value={this.state.inputValue}
          onKeyDown={(e) => {
            if (this.props.disabled) {
              e.preventDefault();
              e.stopPropagation();
              return;
            }

            if (this.state.options.length) {
              let selectedOption = this.state.selectedOption;
              if (!selectedOption && selectedOption !== 0) {
                selectedOption = 0;
                if (e.keyCode === 38) selectedOption++;
                else if (e.keyCode === 40) selectedOption--;
              }

              if (e.keyCode === 13) { // enter
                e.preventDefault();
                e.stopPropagation();
                this.handleSubmit(this.state.options[selectedOption]);
              } else if (e.keyCode === 38 && selectedOption > 0) { // arrow up
                e.preventDefault();
                e.stopPropagation();
                this.setState({ selectedOption: selectedOption - 1 });
              } else if (e.keyCode === 40 && (options.length - 1) > selectedOption) { // arrow down
                e.preventDefault();
                e.stopPropagation();
                this.setState({ selectedOption: selectedOption + 1 });
              }
            }
          }}
        />
        {!renderFromInit && options.length > 0 && <ul className="date-selector__options-wrapper">
          {options.length > 0 && options.map((option: any, index) => {
            return (
              <li key={index}
                tabIndex={option.valid ? 0 : -1}
                id={`date-option-${index}`}
                className={
                  `date-selector__option 
                  ${option.valid ? '' : 'invalid'}
                  ${this.state.selectedOption === index && option.valid ? 'selected' : ''}`
                }
                onMouseEnter={(e) => this.handleMouseEnter(index)}
                title={option.message}
                onMouseDown={() => this.handleSubmit(option)}
              >
                {option.label}
              </li>
            );
          })}
        </ul>}
        {triggeredValidator?.errorMessage && (
          <div className="form-field__error">
            {triggeredValidator.errorMessage}
          </div>
        )}
      </div>
    );
  }
}
