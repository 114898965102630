import { request } from './request';
import { ChartType } from '../components/insights/common';
import { AdminGridSearchParams } from '../components/admin/AdminGrid';
import { ShipamaxEnvironment } from './cwCredential';

export enum EnvironmentTab {
  ProductionWorkspace = 'Production workspace',
  TestWorkspace = 'Test workspace',
}

export interface MailboxAdminGridSearchParams extends AdminGridSearchParams {
  validationType?: ValidationType[];
  environmentTab: string;
}

export enum ValidationType {
  None = 0,
  AP = 1,
  Forwarding = 2,
  Export = 3,
  Clearance = 4,
  ClearanceAndForwarding = 5,
  CinvCluster = 6,
  GatewayForwarding = 7,
  GatewayClearance = 8,
  WtgIntegration = 9,
  GenericTMS = 10,
}

export enum TMSType {
  Unknown = 0,
  Cargowise = 1
}

export enum CNValidationType {
  None = 0,
  Standard = 1,
  WithServiceDate = 2
}

export interface APIEmailAccount {
  id: number;
  companyId: number;
  emailAddr: string;
  confidentialEmailAddr: string; // this email address isn't hashed for the Sales account - use it only for AP insights queries
  accountName: string;
  defaultTeamId: number;
  cwCompanyCode: string | null;
  validationType: ValidationType;
  triggerParser: boolean;
  defaultBranch: string;
  defaultDepartment: string;
  autoPostBills: boolean;
  oneFilePerGroup: boolean;
  localCurrency: string | null;
  tmsType: TMSType;
  branchCountryCode: string | null;
  cnValidation: CNValidationType;
  taxTolerance: number | null;
  exchangeTolerance: number | null;
  taxCodeGroupId: number;
  multiDoc: boolean;
  siteId: number | null;
  workflowTemplateId: number | null;
  environment: number | null;
  xrUpperAbsoluteMax: number;
  xrLowerAbsoluteMax: number;
  xrUpperPercentageMax: number;
  xrLowerPercentageMax: number;
  accrualUpperAbsoluteMax: number;
  accrualLowerAbsoluteMax: number;
  accrualUpperPercentageMax: number;
  accrualLowerPercentageMax: number;
  accrualSetSeparateLimit: boolean;
  xrSetSeparateLimit: boolean;
  useTaxDate: boolean;
  useDocumentReceivedDate: boolean;
  defaultToOverheads: boolean;
  orgMatchingEnabled: boolean;
  defaultTransportMode: string | null;
}

export const EmailAccountAPI = {
  async fetchAllForInsights(chartType?: ChartType): Promise<APIEmailAccount[]> {
    return request(`/Companies/${localStorage.getItem('companyId')}/emailAccounts`, 'POST', { chartType });
  },

  async fetchAll(params?: MailboxAdminGridSearchParams): Promise<APIEmailAccount[]> {
    let environmentClause = {};
    if (params?.environmentTab === EnvironmentTab.ProductionWorkspace) {
      environmentClause = {
        or: [
          { environment: null },
          { environment: ShipamaxEnvironment.Production },
          { environment: ShipamaxEnvironment.Staging }
        ]
      }
    } else if (params?.environmentTab === EnvironmentTab.TestWorkspace) {
      environmentClause = {
        or: [
          { environment: ShipamaxEnvironment.TestProduction },
        ]
      }
    }

    let validationTypeParam;

    if (params && Array.isArray(params.validationType)) {
      validationTypeParam = { inq: params.validationType };
    } else {
      validationTypeParam = params?.validationType;
    }

    const filter = JSON.stringify({
      where: {
        companyId: parseInt(localStorage.getItem('companyId') || ''),
        ...(params?.searchTerm ? { emailAddr: { ilike: `%${params.searchTerm.toLowerCase()}%` } } : {}),
        ...(validationTypeParam ? { validationType: validationTypeParam } : {}),
        ...(environmentClause)
      },
      order: 'emailAddr',
      ...(params?.limit ? { limit: params.limit } : {}),
    });
    return request(`/EmailAccounts?filter=${encodeURIComponent(filter)}`);
  },

  async fetch(id: number): Promise<APIEmailAccount> {
    return request(`/EmailAccounts/${id}`, 'GET');
  },

  async create(data: Partial<APIEmailAccount>): Promise<APIEmailAccount> {
    return request(`/EmailAccounts`, 'POST', data);
  },

  async update(id: number, data: Partial<APIEmailAccount>): Promise<APIEmailAccount> {
    return request(`/EmailAccounts/${id}`, 'PATCH', data);
  },

  async isFieldValueTaken(field: keyof APIEmailAccount, value: string, siteId: number | undefined): Promise<boolean> {
    const companyId = parseInt(localStorage.getItem('companyId') || '');
    const filter = JSON.stringify({
      where: {
        companyId,
        [field]: value,
        ...(siteId ? { id: { neq: siteId } } : {}),
      },
    });

    const result = await request(`/EmailAccounts?filter=${filter}`);
    return !!result.length;
  }
}
