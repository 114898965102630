import * as React from 'react';
import { Avatar } from '../common/Avatar';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { UserSettingsContext } from '../main/Main';
import { FAIcon } from '../common/FAIcon';
import { AuthAPI, PermissionLevel } from '../../api/authentication';
import { ShipamaxMoneyIcon, ShipamaxForwardingIcon, ShipamaxInsightsIcon, ShipamaxQuestionIcon, ShipamaxCogsIcon, ShipamaxSignOutIcon, ShipamaxWtgIcon } from '../../images/Icons';
import { APIPermissionProfile } from '../../api/permissionProfiles';
import { getApBaseUrl, getEnvironmentNameFromEnvironment } from '../../api/request';
import { navigateToUrl } from '../helpers';

import './nav-bar.scss';
import { APICompany } from '../../api/company';
import { APIRelatedUser } from '../../api/comment';
import { login } from '../helpers';
import { APIUserSettings, UserSettingsAPI } from '../../api/userSettings';

const logoSVG = require('../../images/logo-icon-1.svg');
const logoDHLSVG = require('../../images/logo-dhl.svg');

export enum AppSection {
  APInvoice = 'apinvoice',
  BillOfLading = 'billoflading',
  WtgIntegration = 'wtg-fnc',
  ApInsights = 'apInsights',
  Admin = 'admin',
  KnowledgeBase = 'knowledge-base',
}

export const AppSectionIds = {
  [AppSection.APInvoice]: 0,
  [AppSection.BillOfLading]: 1,
  [AppSection.WtgIntegration]: 2,
  [AppSection.ApInsights]: 3,
  [AppSection.Admin]: 4,
  [AppSection.KnowledgeBase]: 5,
}

// find better way for reverse lookup
export const AppSectionNames = [
  AppSection.APInvoice,
  AppSection.BillOfLading,
  AppSection.WtgIntegration,
  AppSection.ApInsights,
  AppSection.Admin,
  AppSection.KnowledgeBase,
]

interface Props {
  logout: () => void;
  logoutCardVisible: boolean;
  activeSectionId: number;
  showLogoutCard: (value: boolean) => void;
  permissionLevel: PermissionLevel;
  permissionProfile: APIPermissionProfile | null;
  selectEnvCardVisible: boolean;
  showSelectEnvCard: (value: boolean) => void;
  selectCompanyCardVisible: boolean;
  showSelectCompanyCard: (value: boolean) => void;
  checkPermissionProfileAccess: (value: keyof APIPermissionProfile) => boolean;
  isChildUser: boolean;
  company: APICompany | null;
  relatedUsers: APIRelatedUser[];
  userSettings: APIUserSettings;
}
export const NavBar: React.FC<Props> = (props) => {
  const history = useHistory();
  const userSettings = React.useContext(UserSettingsContext);
  const [testEnv, setTestEnv] = React.useState<boolean>(false);
  const isDHLTheme = parseInt(localStorage.getItem('colorTheme') || '') === 1;

  React.useEffect(() => {
    const selectedEnv = localStorage.getItem('selectedEnv');

    if (selectedEnv === '2') {
      if (!props.permissionProfile || props.permissionProfile.workspaceTest) {
        return setTestEnv(true);
      }
      localStorage.removeItem('selectedEnv');
      return setTestEnv(false);
    }

    if (!props.permissionProfile || props.permissionProfile.workspaceProduction) {
      localStorage.removeItem('selectedEnv');
      return setTestEnv(false);
    }

    localStorage.setItem('selectedEnv', '2');
    setTestEnv(true);
  }, [props.permissionProfile]);

  React.useEffect(() => {
    if (userSettings.appSection !== props.activeSectionId) {
      const settings = { ...userSettings };
      settings.appSection = props.activeSectionId;
      UserSettingsAPI.updateParentSettings(settings);
    }
  }, [props.activeSectionId]);

  const showLogoutCard = () => {
    window.setTimeout(() => props.showLogoutCard(true), 0);
  }

  const showSelectEnvCard = () => {
    window.setTimeout(() => {
      props.showSelectCompanyCard(false);
      props.showSelectEnvCard(true);
    }, 0);
  }

  const showSelectCompanyCard = () => {
    window.setTimeout(() => {
      props.showSelectEnvCard(false);
      props.showSelectCompanyCard(true);
    }, 0);
  }

  const stopPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }

  const onAdminClicked = () => {
    props.showLogoutCard(false);
    navigateToUrl(`/${AppSection.Admin}/users`, history);
  }

  const handleRelatedUserSelect = async (relatedUser: APIRelatedUser) => {
    // Do nothing if relatedUser matches logged in user
    if (relatedUser.id === userId) return;

    UserSettingsAPI.updateParentLastLoggedInSetting(relatedUser.company.id);

    const relatedUserTokenData = await AuthAPI.fetchRelatedUserToken(relatedUser.company.id);
    // This is to clear the lastPath item
    // Else it will update the child users settings with the current previous path
    localStorage.clear();
    localStorage.setItem('token', relatedUserTokenData.token);

    await login(true, window.location.pathname);
  }

  const enableTestEnv = () => {
    setTestEnv(true);
    localStorage.setItem('selectedEnv', '2');
    props.showSelectEnvCard(false);

    if ([AppSectionIds[AppSection.ApInsights], AppSectionIds[AppSection.APInvoice]].includes(props.activeSectionId)) {
      const selectedEnvironmentName = getEnvironmentNameFromEnvironment('2');
      const baseUrl = getApBaseUrl(selectedEnvironmentName);
      props.showLogoutCard(false);
      window.location.href = baseUrl;
    } else {
      history.go(0);
    }
  }

  const disableTestEnv = () => {
    setTestEnv(false);
    localStorage.removeItem('selectedEnv');
    props.showSelectEnvCard(false);

    if ([AppSectionIds[AppSection.APInvoice]].includes(props.activeSectionId)) {
      const selectedEnvironmentName = getEnvironmentNameFromEnvironment(null);
      const baseUrl = getApBaseUrl(selectedEnvironmentName);
      window.location.href = baseUrl;
    } else {
      history.go(0);
    }
  }

  const activeMainSectionsMap = new Map<number, React.ReactNode>();

  if (props.checkPermissionProfileAccess('accessAP')) {
    activeMainSectionsMap.set(AppSectionIds[AppSection.APInvoice], (
      <NavIcon id={AppSectionIds[AppSection.APInvoice]} tooltip="AP Invoices" icon={<ShipamaxMoneyIcon />} activeSectionId={props.activeSectionId} />
    ));
  }
  if (props.checkPermissionProfileAccess('accessForwarding')) {
    activeMainSectionsMap.set(AppSectionIds[AppSection.BillOfLading], (
      <NavIcon id={AppSectionIds[AppSection.BillOfLading]} tooltip="Forwarding and Clearance" icon={<ShipamaxForwardingIcon />} activeSectionId={props.activeSectionId} />
    ));
  }
  if (!testEnv && props.checkPermissionProfileAccess('accessWtgIntegration')) {
    activeMainSectionsMap.set(AppSectionIds[AppSection.WtgIntegration], (
      <NavIcon id={AppSectionIds[AppSection.WtgIntegration]} tooltip="WTG Integration" icon={<ShipamaxWtgIcon />} activeSectionId={props.activeSectionId} />
    ));
  }
  if (!testEnv && props.checkPermissionProfileAccess('accessInsights')) {
    activeMainSectionsMap.set(AppSectionIds[AppSection.ApInsights], (
      <NavIcon id={AppSectionIds[AppSection.ApInsights]} tooltip="Insights" icon={<ShipamaxInsightsIcon />} activeSectionId={props.activeSectionId} />
    ));
  }

  const activeMainSectionIndex = [...activeMainSectionsMap.keys()].findIndex((sectionId) => sectionId === props.activeSectionId);

  const userId = Number(localStorage.getItem('userId'));

  const shortenString = (maxLength: number, str?: string) => {
    if (!str) return '';
    return str.length > maxLength ? `${str.substring(0, maxLength - 1)}...` : str;
  }

  return (
    <div className={`nav-bar ${testEnv ? 'test' : ''}`}>
      <img className="logo-nav" src={isDHLTheme ? logoDHLSVG : logoSVG} alt="Shipamax" />
      <div className="sections">
        {(activeMainSectionIndex !== -1) && (
          <div className="sections__highlighter" style={{ transform: `translateY(${45 * activeMainSectionIndex}px)` }} />
        )}
        {[...activeMainSectionsMap.values()].map((section) => section)}
        <NavIcon id={AppSectionIds[AppSection.KnowledgeBase]} tooltip="Knowledge Base" icon={<ShipamaxQuestionIcon />} activeSectionId={props.activeSectionId} />
      </div>

      {props.company && (props.relatedUsers.length > 1) ? (
        <div className={`child_user_awareness ${testEnv ? 'child_user_awareness--test' : ''}`}>
          {`${shortenString(24, props.company?.name)}${!props.isChildUser ? " *" : ""}`}
        </div>
      ) : <></>}

      <div className="avatar-flex-wrapper">
        <div>
          <div className="selected-env">{testEnv ? 'TST' : 'PRD'}</div>
          <Avatar
            onClick={showLogoutCard}
            color="#111D26"
            initials={'' + localStorage.getItem('firstName')?.charAt(0) + localStorage.getItem('lastName')?.charAt(0)}
          />
        </div>
      </div>
      <div className={`nav-bar__logout-card ${props.logoutCardVisible ? 'show' : ''}`} onClick={stopPropagation}>
        <div className="nav-bar__logout-card__logged-user">
          <Avatar
            initials={'' + localStorage.getItem('firstName')?.charAt(0) + localStorage.getItem('lastName')?.charAt(0)}
          />
          <div>{localStorage.getItem('firstName')} {localStorage.getItem('lastName')}</div>
        </div>
        {props.relatedUsers.length > 1 && (
          <div className={`nav-bar__logout-card__env-selector company-selector ${testEnv ? 'company-selector--test' : ''}`} onClick={showSelectCompanyCard}>
            <div className="nav-bar__logout-card__env-selector__current-env">
              <div className="env">
                {shortenString(20, props.company?.name)}
              </div>
              <div className="message">Switch Companies</div>
            </div>
            <FAIcon name="angle-right" solid />
          </div>
        )}
        {props.checkPermissionProfileAccess('workspaceProduction') && props.checkPermissionProfileAccess('workspaceTest') && (
          <div className="nav-bar__logout-card__env-selector" onClick={showSelectEnvCard}>
            <div className="nav-bar__logout-card__env-selector__current-env">
              <div className="env">{testEnv ? 'TST - Test' : 'PRD - Production'}</div>
              <div className="message">Switch Workspaces</div>
            </div>
            <FAIcon name="angle-right" solid />
          </div>
        )}
        {props.permissionLevel === PermissionLevel.Admin && (
          <div className="nav-bar__logout-card__item" onClick={onAdminClicked} style={{ marginTop: '10px' }}>
            <ShipamaxCogsIcon />
            <span className="nav-bar__logout-card__item__label" id="organisation-admin-button">
              Admin
            </span>
          </div>
        )}
        <div className="nav-bar__logout-card__item" onClick={props.logout} id="logout-button" style={{ marginTop: '10px', marginBottom: '10px' }}>
          <ShipamaxSignOutIcon />
          <span className="nav-bar__logout-card__item__label">
            Logout
          </span>
        </div>
      </div>
      <div className={`nav-bar__select-env-card ${props.selectEnvCardVisible ? 'show' : ''}`} onClick={stopPropagation}>
        <div className={`nav-bar__select-env-card__item ${testEnv ? '' : 'selected'}`} onClick={disableTestEnv}>
          <span className="nav-bar__select-env-card__item__label">
            PRD - Production
          </span>
          {!testEnv && <FAIcon name="check" solid />}
        </div>
        <div className={`nav-bar__select-env-card__item ${testEnv ? 'selected' : ''}`} onClick={enableTestEnv}>
          <span className="nav-bar__select-env-card__item__label">
            TST - Test
          </span>
          {testEnv && <FAIcon name="check" solid />}
        </div>
      </div>
      <div className={`nav-bar__select-env-card select-company-card ${props.selectCompanyCardVisible ? 'show' : ''}`} onClick={stopPropagation}>
        {props.relatedUsers.map((relatedUser: APIRelatedUser) =>
          <div
            key={relatedUser.id}
            className={`nav-bar__select-env-card__item select-company-card_item ${relatedUser.id === userId ? 'selected' : ''}`}
            onClick={() => handleRelatedUserSelect(relatedUser)}
          >
            <span className="nav-bar__select-env-card__item__label">
              {relatedUser.company.name}
            </span>
            {relatedUser.id === userId && <FAIcon name="check" solid />}
          </div>
        )}
      </div>
    </div>
  );
}

interface NavIconProps {
  id: number;
  icon: any;
  tooltip: string;
  activeSectionId: number;
}

const NavIcon: React.FC<NavIconProps> = (props) => {
  const history = useHistory();
  const userSettings = React.useContext(UserSettingsContext);
  const isActive = props.activeSectionId === props.id;
  const isKnowledgeBase = props.id === AppSectionIds[AppSection.KnowledgeBase];

  return (
    <a>
      <div
        data-tip="tooltip"
        data-for={`tooltip-${AppSectionNames[props.id]}`}
        className={`sections__icon ${isActive ? (isKnowledgeBase ? 'active__knowledge-base' : 'active') : ''}`}
        onClick={() => {
          const url = isKnowledgeBase && userSettings.knowledgeBasePage ? userSettings.knowledgeBasePage : `/${AppSectionNames[props.id]}`;
          navigateToUrl(url, history);
        }}
      >
        {props.icon}
      </div>
      <ReactTooltip id={`tooltip-${AppSectionNames[props.id]}`} place="right" effect='solid' className="tooltip">
        <span>{props.tooltip}</span>
      </ReactTooltip>
    </a>
  );
}
