import { request, upload } from './request';
import { ChartType } from '../components/insights/common';
import { ChartFilterProps } from '../components/insights/ChartWrapper';
import { APITeam } from './teams';
import { APIUser } from './comment';
import { APIIssuerCgwCodes } from './issuer';
import { AdminGridSearchParams } from '../components/admin/AdminGrid';

export interface APICwReferenceDataItem {
  code: string;
  description: string;
}

export interface APICwReferenceData {
  paymentTerms: APICwReferenceDataItem[];
  releaseType: APICwReferenceDataItem[];
  containerType: APICwReferenceDataItem[];
  consolType: APICwReferenceDataItem[];
  shipmentType: APICwReferenceDataItem[];
  bJobShipmentType: APICwReferenceDataItem[];
  transportMode: APICwReferenceDataItem[];
  packageType: APICwReferenceDataItem[];
  containerMode: APICwReferenceDataItem[];
  incoTerm: APICwReferenceDataItem[];
  unitType: APICwReferenceDataItem[];
}

export enum ImportStatus {
  Received = 0,
  Queued = 1,
  Importing = 2,
  Completed = 3,
  Failed = 4
}

export interface APICwReferenceImportOrgLog {
  message: string;
  details: any;
}

export interface APICwReferenceImportOrg {
  id: number;
  date: string;
  filename: string;
  status: ImportStatus;
  clearExisting: boolean;
  logs?: APICwReferenceImportOrgLog[];
}

export interface FileReference {
  name: string,
  ref: string,
}

export interface APIProductReferenceImport {
  id: number;
  date: string;
  fileReferences: FileReference[];
  status: ImportStatus;
  clearExisting: boolean;
  logs?: APICwReferenceImportOrgLog[];
}

export interface APIContainerDataReferenceImport {
  id: number;
  date: string;
  fileName: string;

  fileRef: string;
  status: ImportStatus;
  clearExisting: boolean;
  logs?: APICwReferenceImportOrgLog[];
}

interface TaxCode {
  countryCode: string;
  code: string;
  description: string;
}

export enum ColorTheme {
  DHL = 1
}

export interface APICompany {
  colorTheme: ColorTheme;
  allowedSuperUsers: 'true' | 'false'
  name: string,
  id: number,
}

export interface APIRollingAverageResult {
  date: string;
  straightThroughRate: number;
  postedWithinDaysRate: number;
  postedExceptionFreeRate: number;
}

export interface APILeaderBoardResult {
  value: number;
  siteName: string;
}

export interface APIEnterpriseDashboardData {
  leaderBoardResults?: {
    topStraightThroughSites: APILeaderBoardResult[];
    topExceptionFreeSites: APILeaderBoardResult[];
    topPostedWithinDaysSites: APILeaderBoardResult[];
  };
  postedExceptionFree: {
    postedExceptionFreeRate: number;
    againstLast: number;
    againstTarget: number;
  }
  postedWithInData: {
    postedWithInRate: number,
    againstLast: number;
    againstTarget: number;
  }
  straightThroughData: {
    straightThroughRate: number,
    againstLast: number;
    againstTarget: number;
  }
  rollingAverage: APIRollingAverageResult[];
}

export const CompanyAPI = {
  fetchCwReferenceData(): Promise<APICwReferenceData> {
    return request(`/Companies/${localStorage.getItem('companyId')}/cargowiseReferenceData`);
  },

  importOrganisationData(file: any, clearExisting: boolean) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);

    return upload(`/CargowiseReferenceImportOrgs/import?clearExisting=${clearExisting}`, 'POST', formData);
  },

  getOrganisationDataImports(params?: AdminGridSearchParams): Promise<APICwReferenceImportOrg[]> {
    const companyId = parseInt(localStorage.getItem('companyId') || '');
    const filter = JSON.stringify({
      where: {
        companyId,
        ...(params?.searchTerm ? { filename: { ilike: `%${params.searchTerm.toLowerCase()}%` } } : {}),
      },
      order: 'date DESC',
      fields: { request: false },
      ...(params?.limit ? { limit: params.limit } : {}),
    });

    return request(`/CargowiseReferenceImportOrgs?filter=${filter}`);
  },

  importProductData(files: File[], clearExisting: boolean) {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('file', file);
      formData.append('fileName', file.name);
    });

    return upload(`/ProductReferenceImports/import?clearExisting=${clearExisting}`, 'POST', formData);
  },

  getProductDataImports(params?: AdminGridSearchParams): Promise<APIProductReferenceImport[]> {
    const companyId = parseInt(localStorage.getItem('companyId') || '');
    const filter = JSON.stringify({
      where: {
        companyId,
        ...(params?.searchTerm ? { filename: { ilike: `%${params.searchTerm.toLowerCase()}%` } } : {}),
      },
      order: 'date DESC',
      ...(params?.limit ? { limit: params.limit } : {}),
    });

    return request(`/ProductReferenceImports?filter=${encodeURIComponent(filter)}`);
  },

  importContainerData(file: File, clearExisting: boolean) {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('fileName', file.name);

    return upload(`/ContainerDataReferenceImports/import?clearExisting=${clearExisting}`, 'POST', formData);
  },

  getContainerDataImports(params?: AdminGridSearchParams): Promise<APIContainerDataReferenceImport[]> {
    const companyId = parseInt(localStorage.getItem('companyId') || '');
    const filter = JSON.stringify({
      where: {
        companyId,
        ...(params?.searchTerm ? { filename: { ilike: `%${params.searchTerm.toLowerCase()}%` } } : {}),
      },
      order: 'date DESC',
      ...(params?.limit ? { limit: params.limit } : {}),
    });

    return request(`/ContainerDataReferenceImports?filter=${encodeURIComponent(filter)}`);
  },

  getDocumentationPage(pageId: string): Promise<any> {
    return request(`/Companies/${localStorage.getItem('companyId')}/documentationPage?pageId=${pageId}`);
  },

  getTaxCodes(countryCode: string, taxCodeGroupId: number): Promise<TaxCode[]> {
    const filter = JSON.stringify({
      where: {
        countryCode: { ilike: countryCode },
        taxCodeGroupId
      },
      order: 'code ASC'
    });

    return request(`/TaxCodes?filter=${filter}`);
  },

  getParentCompany(): Promise<APICompany> {
    return request(`/Companies/${localStorage.getItem('parentCompanyId')}`);
  },

  fetch(): Promise<APICompany> {
    return request(`/Companies/${localStorage.getItem('companyId')}`);
  },

  async getApInsightsChartData(
    filter: ChartFilterProps
  ): Promise<any[]> {
    if (!filter.dateRange) {
      return [];
    }

    return request(`/SupplierInvoices/insights`, 'POST', {
      filter: {
        ...filter,
        startDate: filter.dateRange[0],
        endDate: filter.dateRange[1],
        type: 'Chart'
      }
    })
  },

  async getApInsightsChartRawData(
    filter: ChartFilterProps
  ): Promise<any[]> {
    if (!filter.dateRange) {
      return [];
    }

    return request(`/SupplierInvoices/insights`, 'POST', {
      filter: {
        ...filter,
        startDate: filter.dateRange[0],
        endDate: filter.dateRange[1],
        type: 'Export'
      }
    })
  },

  async getGroupsInsightsChartData(
    filter: ChartFilterProps,
    isExport: boolean = false,
  ): Promise<any[]> {
    if (!filter.dateRange) {
      return [];
    }

    return request(`/CommercialInvoices/insights`, 'POST', {
      filter: {
        ...filter,
        startDate: filter.dateRange[0],
        endDate: filter.dateRange[1],
        type: isExport ? 'Export' : 'Chart'
      }
    });
  },

  async getApEnterpriseDashboardData(): Promise<APIEnterpriseDashboardData> {
    return request(`/SupplierInvoices/health`, 'POST', {
      companyId: parseInt(localStorage.getItem('companyId') || '')
    });
  },

  async fetchTeamsForInsights(chartType?: ChartType): Promise<APITeam[]> {
    return request(`/Companies/${localStorage.getItem('companyId')}/getTeams`, 'POST', { chartType });
  },

  async fetchUsersForInsights(chartType?: ChartType): Promise<APIUser[]> {
    return request(`/Companies/${localStorage.getItem('companyId')}/getUsers`, 'POST', { chartType });
  },

  async fetchCreditorsForInsights(searchTerm: string, chartType?: ChartType): Promise<APIIssuerCgwCodes[]> {
    return request(`/Companies/${localStorage.getItem('companyId')}/getCreditors?search=${searchTerm}`, 'POST', { chartType });
  },
}
