import * as React from 'react';
import { AdminHeader } from '../../AdminHeader';
import { APIAutomatedTolerance, DiscrepancyType, } from '../../../../api/automatedTolerances';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { isFormInvalid, RecordValidator } from '../../../common/Form';
import { WrapperLabel } from '../../../common/WrapperLabel';
import { NumberInput } from '../../../common/NumberInput';
import { TextInput } from '../../../common/TextInput';
import { EmailAccountAPI } from '../../../../api/emailAccount';
import { FormSubHeadline } from '../../admin-components';
import { Toggle } from '../../../common/Toggle';

interface UrlParams {
  id: string;
}

interface Props {
  data?: APIAutomatedTolerance;
}

export const AutomatedAccrualToleranceForm: React.FC<Props> = (props) => {
  const urlParams = new URLSearchParams(location.search);
  const environment = urlParams.get('env');
  const params = useParams<UrlParams>();
  const id = parseInt(params.id);

  const history = useHistory();
  const [record, setRecord] = React.useState<Partial<APIAutomatedTolerance>>({
    id,
    accrualUpperAbsoluteMax: 0,
    accrualLowerAbsoluteMax: 0,
    accrualUpperPercentageMax: 0,
    accrualLowerPercentageMax: 0,
    accrualSetSeparateLimit: false,
    exchangeTolerance: 0,
    taxTolerance: 0,
    discrepancyType: DiscrepancyType.AccrualDiscrepancy,
  });

  const [formEntered, setFormEntered] = React.useState<boolean>(false);

  const recordValidator: RecordValidator = {
    accrualUpperAbsoluteMax: {
      validators: [{
        isValid: (value) => value >= 0 && value <= 1000000,
        errorMessage: 'Value needs to be between 0 and 1,000,000'
      }]
    },
    accrualLowerAbsoluteMax: {
      validators: [{
        isValid: (value) => value >= 0 && value <= 1000000,
        errorMessage: 'Value needs to be between 0 and 1,000,000'
      }]
    },
    accrualUpperPercentageMax: {
      validators: [{
        isValid: (value) => value >= 0 && value <= 1000000,
        errorMessage: 'Value needs to be between 0 and 1,000,000'
      }]
    },
    accrualLowerPercentageMax: {
      validators: [{
        isValid: (value) => value >= 0 && value <= 1000000,
        errorMessage: 'Value needs to be between 0 and 1,000,000'
      }]
    },
    exchangeTolerance: {
      validators: [{
        isValid: (value) => value >= 0 && value <= 1000000,
        errorMessage: 'Value needs to be between 0 and 1,000,000'
      }]
    },
    taxTolerance: {
      validators: [{
        isValid: (value) => value >= 0 && value <= 1000000,
        errorMessage: 'Value needs to be between 0 and 1,000,000'
      }]
    },
  }

  const submitDisabled = isFormInvalid<APIAutomatedTolerance>(record, recordValidator);

  React.useEffect(() => {
    if (params.id) {
      EmailAccountAPI.fetch(parseInt(params.id)).then((mailboxData) => {
        const recordData = {
          id: mailboxData.id,
          emailAddr: mailboxData.emailAddr,
          localCurrency: mailboxData.localCurrency,
          discrepancyType: DiscrepancyType.AccrualDiscrepancy,
          accrualUpperAbsoluteMax: mailboxData.accrualUpperAbsoluteMax,
          accrualLowerAbsoluteMax: mailboxData.accrualLowerAbsoluteMax,
          accrualUpperPercentageMax: mailboxData.accrualUpperPercentageMax,
          accrualLowerPercentageMax: mailboxData.accrualLowerPercentageMax,
          accrualSetSeparateLimit: mailboxData.accrualSetSeparateLimit,
          exchangeTolerance: mailboxData.exchangeTolerance,
          taxTolerance: mailboxData.taxTolerance,
        }
        setRecord(recordData);
      });
    }
  }, [params.id]);

  const onSave = async () => {
    setFormEntered(true);
    if (submitDisabled) return;

    if (record.id) {
      await EmailAccountAPI.update(
        record.id,
        {
          accrualUpperAbsoluteMax: record.accrualUpperAbsoluteMax,
          accrualLowerAbsoluteMax: record.accrualSetSeparateLimit ? record.accrualLowerAbsoluteMax : record.accrualUpperAbsoluteMax,
          accrualUpperPercentageMax: record.accrualUpperPercentageMax,
          accrualLowerPercentageMax: record.accrualSetSeparateLimit ? record.accrualLowerPercentageMax : record.accrualUpperPercentageMax,
          accrualSetSeparateLimit: record.accrualSetSeparateLimit,
          exchangeTolerance: record.exchangeTolerance,
          taxTolerance: record.taxTolerance,
        });
    }

    history.push(AdminLinks.AutomatedTolerances);
  }

  return (
    <div className="admin__form__wrapper with-custom-scrollbar permission-profile-form">
      <AdminHeader
        headline='Edit AP automated tolerance'
        tagText={environment ? 'Test workspace' : 'Production workspace'}
        links={[{
          label: 'What are automated tolerances?',
          url: '/knowledge-base/What-are-tolerances-b-b--a04664929e0045d48e1e5f6cef5f627a#8b6013cae88c42e49e4c31d92c4a36a2',
        }]}
      />
      <div className="admin__form__content">
        <div>
          <WrapperLabel text="Threshold type">
            <TextInput
              disabled={true}
              value={"Accrual discrepancy"}
              name="thresholdType"
            />
          </WrapperLabel>
          <WrapperLabel text="Mailbox address">
            <TextInput
              disabled={true}
              value={record.emailAddr || ''}
              name="thresholdType"
            />
          </WrapperLabel>
          <WrapperLabel text="Local currency">
            <TextInput
              disabled={true}
              value={record.localCurrency || ''}
              name="thresholdType"
            />
          </WrapperLabel>
          <WrapperLabel text="Tax rounding tolerance (automatic)">
            <NumberInput
              value={record.taxTolerance || 0}
              name="taxToleracnce"
              onChange={(value) => setRecord({ ...record, taxTolerance: value || 0 })}
              precision={2}
              minPrecision={0}
              formEntered={formEntered}
              validators={recordValidator.taxTolerance.validators}
              suffix={record.localCurrency + " or equivalent"}
            />
          </WrapperLabel>
          <WrapperLabel text="FX rounding tolerance (manual)">
            <NumberInput
              value={record.exchangeTolerance || 0}
              name="exchangeTolerance"
              onChange={(value) => setRecord({ ...record, exchangeTolerance: value || 0 })}
              precision={2}
              minPrecision={0}
              formEntered={formEntered}
              validators={recordValidator.exchangeTolerance.validators}
              suffix={record.localCurrency + " or equivalent"}
            />
          </WrapperLabel>
          <FormSubHeadline headline="Thresholds for updating accrual amounts" />
          <Toggle
            id="setSeparateLimit"
            label="Set separate upper and lower thresholds"
            checked={record.accrualSetSeparateLimit || false}
            onChecked={(name, value) => setRecord({ ...record, accrualSetSeparateLimit: value })}
          />
          <table>
            <thead>
              <tr>
                <th>Threshold</th>
                <th>Absolute maximum</th>
                <th>Percentage maximum</th>
              </tr>
            </thead>
            {!record.accrualSetSeparateLimit ? (
              <tbody>
                <tr>
                  <td>Universal Limit</td>
                  <td>
                    <NumberInput
                      value={record.accrualUpperAbsoluteMax || 0}
                      onChange={(value) => setRecord({
                        ...record,
                        accrualUpperAbsoluteMax: value || 0, accrualLowerAbsoluteMax: value || 0
                      })}
                      name={'accrualUpperAbsoluteMax'}
                      ignorePrecision
                    />
                  </td>
                  <td>
                    <NumberInput
                      value={record.accrualUpperPercentageMax || 0}
                      onChange={(value) => setRecord({
                        ...record,
                        accrualUpperPercentageMax: value || 0, accrualLowerPercentageMax: value || 0
                      })}
                      name={'accrualUpperPercentageMax'}
                      ignorePrecision
                    />
                  </td>
                </tr>
              </tbody>
            ) :
              (
                <tbody>
                  <tr>
                    <td>Upper Limit</td>
                    <td>
                      <NumberInput
                        value={record.accrualUpperAbsoluteMax || 0}
                        onChange={(value) => setRecord({
                          ...record,
                          accrualUpperAbsoluteMax: value || 0
                        })}
                        name={'accrualUpperAbsoluteMax'}
                        ignorePrecision
                      />
                    </td>
                    <td>
                      <NumberInput
                        value={record.accrualUpperPercentageMax || 0}
                        onChange={(value) => setRecord({
                          ...record,
                          accrualUpperPercentageMax: value || 0
                        })}
                        name={'accrualUpperPercentageMax'}
                        ignorePrecision
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Lower Limit</td>
                    <td>
                      <NumberInput
                        value={record.accrualLowerAbsoluteMax || 0}
                        onChange={(value) => setRecord({
                          ...record,
                          accrualLowerAbsoluteMax: value || 0
                        })}
                        name={'accrualLowerAbsoluteMax'}
                        ignorePrecision
                      />
                    </td>
                    <td>
                      <NumberInput
                        value={record.accrualLowerPercentageMax || 0}
                        onChange={(value) => setRecord({
                          ...record,
                          accrualLowerPercentageMax: value || 0
                        })}
                        name={'accrualLowerPercentageMax'}
                        ignorePrecision
                      />
                    </td>
                  </tr>
                </tbody>
              )}
          </table>
        </div>
        <div>
          <Link to={AdminLinks.AutomatedTolerances}><button className="light-button">Cancel</button></Link>
          <button className="full-button" onClick={onSave}>Save</button>
        </div>
      </div>
    </div>
  )
}
