import { Packline } from './Packline';
import * as React from 'react';
import { FocusDirection } from '../../helpers';
import { BillOfLadinPackLineAPI } from '../../../api/billOfLadingPackLine';
import { APIDocumentGroupPackline } from '../../../api/documentGroup';
import { OptionValue } from 'react-selectize';
import { ShipamaxPlusIcon } from '../../../images/Icons';

interface Props {
  packLines?: APIDocumentGroupPackline[];
  reloadGroupData: () => void;
  disabled: boolean;
  packageTypeOptions: OptionValue[];
  billOfLadingId?: number;
  onFocus?: () => void;
}

export const PacklinesList: React.FC<Props> = (props) => {
  const [packlines, setPacklines] = React.useState<APIDocumentGroupPackline[]>([]);
  // first number represents the index of a packline, which should be focused. The second number represents the index of an input in that packline
  const [shouldFocusIndex, setShouldFocusIndex] = React.useState<[number, number]>([-1, -1]);

  React.useEffect(() => {
    setPacklines(props.packLines || []);
  }, [props.packLines]);

  const addPackline = async () => {
    const packLine = await BillOfLadinPackLineAPI.create({ billOfLadingId: props.billOfLadingId });

    setPacklines([...packlines, packLine]);
    props.reloadGroupData();
    document.getElementById('add-new-packline')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  const deletePackline = async (id?: number) => {
    if (id && packlines.length > 1) {
      await BillOfLadinPackLineAPI.remove(id);

      const rows = packlines.filter((row) => row.id !== id);
      setPacklines(rows);
      props.reloadGroupData();
    }
  }

  const onChange = (id: number, change: Partial<APIDocumentGroupPackline>) => {
    setPacklines((items) => items.map((packline) => {
      return packline.id === id ? { ...packline, ...change } : packline;
    }));
  }

  const handleShiftFocus = (direction: FocusDirection, currentPacklineIndex: number, inputIndex: number) => {
    if (direction === FocusDirection.UP && currentPacklineIndex - 1 >= 0) {
      setShouldFocusIndex([currentPacklineIndex - 1, inputIndex]);
    }

    if (direction === FocusDirection.DOWN && currentPacklineIndex + 1 <= packlines.length) {
      setShouldFocusIndex([currentPacklineIndex + 1, inputIndex]);
    }
  }

  const reloadGroupData = (line: APIDocumentGroupPackline, unitChange: boolean) => {
    if (unitChange) {
      packlines.forEach(l => {
        l.volumeUnit = line.volumeUnit;
        l.weightUnit = line.weightUnit;
      });
      setPacklines([]);
      setPacklines(packlines);
    }
    props.reloadGroupData();
  }

  return (
    <div className={`packlines-list ${packlines.length ? '' : 'container--empty'}`} style={{ display: 'block' }}>
      <div>
        {packlines.map((line, i) => (
          <Packline
            key={`packline-${i}`}
            data={line}
            packageTypeOptions={props.packageTypeOptions}
            onChange={(change) => onChange(line.id, change)}
            deletePackline={deletePackline}
            hideLabels={i !== 0}
            onPacklineUpdated={(line, unitChange) => reloadGroupData(line, unitChange)}
            onShiftFocus={(direction, inputIndex) => handleShiftFocus(direction, i, inputIndex)}
            shouldFocusInputWithIndex={shouldFocusIndex[0] === i ? shouldFocusIndex[1] : -1}
            onFocusCompleted={() => setShouldFocusIndex([-1, -1])}
            disabled={props.disabled}
            onFocus={props.onFocus}
          />
        ))}
      </div>
      <div className="grid__row">
        <button
          id="add-new-packline"
          className="manage-row-button active-on-hover"
          onClick={addPackline}
          data-test-id="add-packline-btn"
          disabled={props.disabled}
        ><ShipamaxPlusIcon /> Add a Packline</button>
      </div>
    </div>
  )
}
