import * as React from 'react';
import { DocumentViewer } from './DocumentViewer';
import { Notification } from '../Notification';
import { APIDocumentContainer, APIDocumentType } from '../../../api/documentContainer';
import { ConfirmModal } from '../ConfirmModal';
import './table-definitions-editor.scss';
import { ShipamaxDownIcon, ShipamaxFileIcon } from '../../../images/Icons';

interface Props {
  setShowEditDefinitions: (value: boolean) => void;
  document: APIDocumentContainer;
  pdfDocument: any;
  filename: string | undefined;
  setNotification: (notification: Notification | null) => void;
  showSpinner: boolean;
  filePages?: number[] | null;
  documentTypes?: APIDocumentType[];
  reloadGroupData?: () => Promise<void>;
  groupId?: number;
  activePage: number;
}

enum ChangeType {
  currentPage,
  currentPageAndSubsequent,
  allPages
}

export const TableDefinitionsEditor: React.FC<Props> = (props) => {
  const [cancelEditTrigger, setCancelEditTrigger] = React.useState<number>(0);
  const [previewChangesTrigger, setPreviewChangesTrigger] = React.useState<number>(0);
  const [applyChangesCurrentTrigger, setApplyChangesCurrentTrigger] = React.useState<number>(0);
  const [applyChangesSubsequentTrigger, setApplyChangesSubsequentTrigger] = React.useState<number>(0);
  const [applyChangesAllTrigger, setApplyChangesAllTrigger] = React.useState<number>(0);
  const [activePage, setActivePage] = React.useState<number>(props.activePage);
  const [lastClickedPage, setLastClickedPage] = React.useState<number>(props.activePage);
  const [hoveredPage, setHoveredPage] = React.useState<number>(0);
  const [numPages, setNumPages] = React.useState<number>(0);
  const [showPageNavigator, setShowPageNavigator] = React.useState<boolean>(true);
  const [showPagePreview, setShowPagePreview] = React.useState<boolean>(false);
  const [definitionsEdited, setDefinitionsEdited] = React.useState<boolean>(false);
  const [showApplyChangesModal, setShowApplyChangesModal] = React.useState<boolean>(false);
  const [applyChangesMode, setApplyChangesMode] = React.useState<ChangeType>(ChangeType.currentPage);
  const [changesComplete, setChangesComplete] = React.useState<boolean>(false);

  const fileType = props.documentTypes && props.documentTypes.find((type) => { return type.id === props.document.documentType });
  const fileTypeLabel = fileType ? fileType.cwCode : "MSC";

  const displayNumPages = props.filePages ? props.filePages.length : numPages;

  React.useEffect(() => {
    if (changesComplete) {
      if (lastClickedPage !== activePage) {
        setActivePage(lastClickedPage);
        setApplyChangesAllTrigger(0);
        setApplyChangesSubsequentTrigger(0);
        setApplyChangesCurrentTrigger(0);
        return;
      }

      setPreviewChangesTrigger(previewChangesTrigger + 1);
      setChangesComplete(false)
    }
  }, [changesComplete]);

  const onPageClicked = (pageNumber: number) => {
    if (definitionsEdited && displayNumPages > 1) {
      setShowApplyChangesModal(true);
      setLastClickedPage(pageNumber);
      return
    }

    setLastClickedPage(pageNumber);
    setActivePage(pageNumber)
  }

  const onPageHover = (pageNumber: number, show: boolean) => {
    if (show) {
      setHoveredPage(pageNumber);
      setShowPagePreview(true);
      return;
    }

    setShowPagePreview(false);
  }

  const handleConfirmApplyChanges = () => {
    setShowApplyChangesModal(false);
    setDefinitionsEdited(false);

    if (applyChangesMode === ChangeType.currentPageAndSubsequent) {
      setApplyChangesSubsequentTrigger(applyChangesSubsequentTrigger + 1);
      return
    }

    if (applyChangesMode === ChangeType.allPages) {
      setApplyChangesAllTrigger(applyChangesAllTrigger + 1);
      return
    }

    if (applyChangesMode === ChangeType.currentPage) {
      if (lastClickedPage !== activePage) {
        setApplyChangesCurrentTrigger(applyChangesCurrentTrigger + 1);
        return
      }
      setApplyChangesCurrentTrigger(applyChangesCurrentTrigger + 1);
      setPreviewChangesTrigger(previewChangesTrigger + 1);
    }
  }

  return (
    <div className='table-definitions-editor'>
      <div className={`table-definitions-editor-wrapper ${showPageNavigator ? "show-page-navigator" : ""}`}>
        <div
          className='table-definitions-editor-header'
          onMouseEnter={() => setShowPagePreview(false)}
        >
          <div className='table-definitions-editor-document'>
            <div className={`table-definitions-document-type ${fileTypeLabel}`}>
              <ShipamaxFileIcon />
              {fileTypeLabel}
            </div>
            <div className='table-definitions-document-filename'>
              {props.filename}
            </div>
          </div>
          <div className='table-definitions-editor-pagination'>
            <button
              className='light-button first'
              onClick={() => setActivePage(0)}
            >
              <ShipamaxDownIcon />
              <ShipamaxDownIcon />
            </button>
            <button
              className='light-button prev'
              onClick={() => { if (activePage - 1 >= 0) setActivePage(activePage - 1) }}
            >
              <ShipamaxDownIcon />
            </button>
            <span
              className="input"
              role="textbox"
              contentEditable
              suppressContentEditableWarning
              onInput={(e) => {
                const newActivePage = parseInt(e.currentTarget.innerText);
                if (isNaN(newActivePage) || newActivePage > displayNumPages) return;

                setActivePage(newActivePage - 1);
              }}>
              {activePage + 1}
            </span>
            of {displayNumPages}
            <button
              className='light-button next'
              onClick={() => { if (activePage + 1 < displayNumPages) setActivePage(activePage + 1) }}
            >
              <ShipamaxDownIcon />
            </button>
            <button
              className='light-button last'
              onClick={() => setActivePage(displayNumPages - 1)}
            >
              <ShipamaxDownIcon />
              <ShipamaxDownIcon />
            </button>
          </div>
          <div className='table-definitions-editor-actions'>
            <button
              className='light-button cancel'
              onClick={() => {
                setCancelEditTrigger(cancelEditTrigger + 1);
              }}
            >
              Cancel
            </button>
            <button
              className='full-button'
              onClick={() => {
                if (definitionsEdited && displayNumPages > 1) {
                  setShowApplyChangesModal(true);
                  return
                }
                setPreviewChangesTrigger(previewChangesTrigger + 1);
              }}
            >
              Preview Results
            </button>
          </div>
        </div>
        {showPageNavigator && (
          <div className='table-definitions-editor-page-navigator'>
            <DocumentViewer
              pdfDocument={props.pdfDocument}
              parentDocumentId={null}
              filename={props.filename}
              filePages={props.filePages}
              setNotification={props.setNotification}
              showSpinner={props.showSpinner}
              documentId={props.document.id}
              specificId={props.document.commercialInvoice?.id || props.document.packingList?.id || props.document.billOfLading?.id || props.document.supplierInvoice?.id || 0}
              editTableDefinitions={false}
              hideOverlay={true}
              hideControls={true}
              onPageClicked={onPageClicked}
              onPageHover={onPageHover}
              setNumPages={setNumPages}
              displayPageNumber={true}
              activePage={activePage}
              loadDefaultByWidth={true}
              useContainerRef={true}
              initialPdfScale={0.8}
            />
          </div>
        )}
        {showPagePreview && (
          <div
            className='table-definitions-editor-page-preview'
            style={{ top: hoveredPage === 0 ? hoveredPage * 192 + 70 : hoveredPage * 192 + 100 }}
          >
            <DocumentViewer
              pdfDocument={props.pdfDocument}
              parentDocumentId={null}
              filename={props.filename}
              setNotification={props.setNotification}
              showSpinner={props.showSpinner}
              documentId={props.document.id}
              specificId={props.document.commercialInvoice?.id || props.document.packingList?.id || props.document.billOfLading?.id || props.document.supplierInvoice?.id || 0}
              editTableDefinitions={false}
              hideControls={true}
              filePages={props.filePages}
              loadOnlyPageIndex={props.filePages ? props.filePages[hoveredPage] : hoveredPage}
              previewTableDefinitions={true}
              loadDefaultByWidth={true}
              useContainerRef={true}
              initialPdfScale={0.8}
            />
          </div>
        )}
        <div
          className='table-definitions-editor-body'
          onMouseEnter={() => setShowPagePreview(false)}
        >
          <DocumentViewer
            pdfDocument={props.pdfDocument}
            parentDocumentId={null}
            filename={props.filename}
            setNotification={props.setNotification}
            showSpinner={props.showSpinner}
            documentId={props.document.id}
            hideControls={true}
            useContainerRef={true}
            filePages={props.filePages}
            loadOnlyPageIndex={props.filePages ? props.filePages[activePage] : activePage}
            loadDefaultByWidth={true}
            editTableDefinitions={true}
            showTableHighlightOnly={true}
            cancelEditTrigger={cancelEditTrigger}
            previewChangesTrigger={previewChangesTrigger}
            applyChangesSubsequentTrigger={applyChangesSubsequentTrigger}
            applyChangesAllTrigger={applyChangesAllTrigger}
            applyChangesCurrentTrigger={applyChangesCurrentTrigger}
            setApplyChangesSubsequentTrigger={setApplyChangesSubsequentTrigger}
            setApplyChangesAllTrigger={setApplyChangesAllTrigger}
            setApplyChangesCurrentTrigger={setApplyChangesCurrentTrigger}
            setShowEditDefinitions={props.setShowEditDefinitions}
            initialPdfScale={0.8}
            documentType={props.document.documentType}
            reloadGroupData={props.reloadGroupData}
            groupId={props.groupId}
            specificId={props.document.commercialInvoice?.id || props.document.packingList?.id || props.document.billOfLading?.id || props.document.supplierInvoice?.id || 0}
            pageNavigatorShowing={showPageNavigator}
            setShowPageNavigator={setShowPageNavigator}
            setDefinitionsEdited={setDefinitionsEdited}
            setPreviewChangesTrigger={setPreviewChangesTrigger}
            setChangesComplete={setChangesComplete}
          />
        </div>
      </div>
      <ConfirmModal
        show={showApplyChangesModal}
        title={`Apply changes to...`}
        onHide={() => setShowApplyChangesModal(false)}
        onConfirm={() => {
          handleConfirmApplyChanges()
        }}
        radioOptions={[
          {
            label: `Page ${activePage + 1} only`,
            value: ChangeType.currentPage,
            initialSelected: true
          },
          {
            label: `Page ${activePage + 1} and subsequent pages`,
            value: ChangeType.currentPageAndSubsequent
          },
          {
            label: "All pages",
            value: ChangeType.allPages
          }
        ]}
        onRadioChange={(selectedOption: number) => {
          setApplyChangesMode(selectedOption)
        }}
      />
    </div>
  )
}
