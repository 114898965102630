import * as React from 'react';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { ShipamaxPlusIcon } from '../../images/Icons';

import './add-item-btn.scss';

interface Props {
  onClick: () => void;
  tooltipText: any;
  alwaysVisible?: boolean;
  disabled?: boolean;
  disabledButVisible?: boolean;
}

export const AddItemBtn: React.FC<Props> = (props) => {
  const [isHoveredOn, setIsHoveredOn] = React.useState<boolean>(false);
  const timeoutRef = React.useRef<any>(null);

  const onMouseEnter = () => {
    timeoutRef.current = setTimeout(() => {
      setIsHoveredOn(true);
    }, 200);
  }

  const onMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    setIsHoveredOn(false);
  }

  const onClick = () => {
    if (props.disabled || props.disabledButVisible) return;
    props.onClick();
  }

  const cssClasses = [
    'add-item-btn',
    props.alwaysVisible ? 'add-item-btn--visible' : '',
    props.disabled ? 'add-item-btn--disabled' : '',
    isHoveredOn ? 'add-item-btn--hovered' : ''
  ];

  return (
    <OverlayTrigger overlay={props.disabled ? <></> : <Tooltip>{props.tooltipText}</Tooltip>} placement="top" delayShow={500}>
      <div className={cssClasses.join(' ')} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <div className="add-item-btn__wrapper">
            <ShipamaxPlusIcon />
          </div>
      </div>
    </OverlayTrigger>
  )
}
