import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { ShipamaxArrowIcon, ShipamaxTimesIcon } from '../../../images/Icons';
import { FAIcon } from '../../common/FAIcon';
import { FileElement } from './FileSorter';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { DocumentType } from '../../../api/documentContainer';
import { ActionModalType } from '../../common/DocumentActionModal';

import './job-tile.scss'

export enum JobTileType {
  Undefined = 0,
  Consol = 1,
  Shipment = 2,
  Brokerage = 3,
}

interface Props {
  type: JobTileType;
  files: FileElement[];
  topDocumentId: number;
  jobRef?: string;
  info1?: string;
  info2?: string;
  draggableIndex: number;
  isDragging: boolean;
  actionType?: ActionModalType;
  showWarning?: string;
  onClickRemoveShipment: (documentId: number) => void;
}

export const JobTile: React.FC<Props> = (props) => {
  const isUndefinedType = props.type === JobTileType.Undefined;

  const showTooltip = () => {
    return (props.files.length === 0);
  }

  return (
    <Droppable droppableId={props.draggableIndex.toString()}>
      {(provided, snapshot) => (
        <>
          <div className={`job-tile ${snapshot.isDraggingOver ? 'dragged-over' : props.isDragging ? 'dragged-hidden' : ''} `}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {!isUndefinedType && <div className={`job-tile__header ${props.files.length < 1 ? 'no-child' : ''} ${props.showWarning ? 'warning-here opacity50' : ''}`}>
              <div className={`job-tile-warning ${showTooltip() ? '' : 'hidden'}`} title={`${JobTileType[props.type]} does not contain any documents and will be removed`}>
                <FAIcon name="fa-circle-exclamation" className={`f-xs`} solid />
              </div>
              <div className={`job-tile__header--type`}>{`${JobTileType[props.type]} ${props.jobRef || ''}`}</div>
              <div className={`job-tile__header--section`}>
                <div>{!isUndefinedType ? props.info1 || '' : ''} </div>
                <span className={`fas fa-lg ${(props.info1 || props.info2) ? 'job-tile__header--arrow' : 'job-tile__header--arrow-hidden'}`} >
                  <ShipamaxArrowIcon />
                </span>
              </div>
              <div className={`job-tile__header--section`}>
                {!isUndefinedType ? props.info2 || '' : ''}
              </div>
              {(props.type === JobTileType.Shipment && props.files?.some((f) => f.type === DocumentType.HouseBillOfLading)) && (
                <OverlayTrigger overlay={<Tooltip>To <b>delete</b> a Shipment with an HBL,<br /> go to the ‘Edit Doc Type’ and <br /><b>change</b> the HBL type</Tooltip>} placement="left" delayShow={500}>
                  <button className="remove-button light-button disabled"><ShipamaxTimesIcon /></button>
                </OverlayTrigger>
              )}
              {(props.actionType === ActionModalType.DragMode && props.type === JobTileType.Shipment && !props.files?.some((f) => f.type === DocumentType.HouseBillOfLading)) && (
                <OverlayTrigger overlay={<Tooltip><b>Delete</b> Shipment</Tooltip>} placement="left" delayShow={500}>
                  <button className="remove-button light-button active-on-hover remove-tile show" onClick={() => props.onClickRemoveShipment(props.topDocumentId)}><ShipamaxTimesIcon /></button>
                </OverlayTrigger>
              )}
            </div>}
            {props.showWarning !== undefined && props.showWarning !== '' && <div className='remove-warning'>
              <div>
                <FAIcon name="exclamation-triangle" solid />
                <div dangerouslySetInnerHTML={{ __html: props.showWarning }} />
              </div>
            </div>}
            <div
              className={`job-tile__body ${isUndefinedType ? 'job-tile__body--no-header' : ''}`}
            >
              <span
                style={{
                  display: "none"
                }}
              >
                {provided.placeholder}
              </span>
              {props.files.map((file) => file.element)}
            </div>
          </div >
        </>
      )}
    </Droppable >
  );
}
