import * as React from 'react';
import { AdminHeader } from '../../AdminHeader';
import { AdminGrid, AdminGridSearchParams, AdminGridTagsList, AdminGridTagType } from '../../AdminGrid';
import { AdminLinks } from '../../Admin';
import {
  APIContainerDataReferenceImport,
  CompanyAPI,
  FileReference,
  ImportStatus
} from '../../../../api/company';
import * as moment from 'moment';
import { Column } from 'react-data-grid';

import './index.scss';

const StatusLabel = {
  [ImportStatus.Received]: <AdminGridTagsList items={['Received']} type={AdminGridTagType.Default} />,
  [ImportStatus.Queued]: <AdminGridTagsList items={['Pending']} type={AdminGridTagType.Yellow} />,
  [ImportStatus.Importing]: <AdminGridTagsList items={['In Progress']} type={AdminGridTagType.Green} />,
  [ImportStatus.Completed]: <AdminGridTagsList items={['Completed']} type={AdminGridTagType.Green} />,
  [ImportStatus.Failed]: <AdminGridTagsList items={['Failed']} type={AdminGridTagType.Red} />
}

interface Props {

}

export const ContainerDataImportTable: React.FC<Props> = () => {
  const fetchImports = (params: AdminGridSearchParams) => {
    return CompanyAPI.getContainerDataImports(params);
  }

  const columns: Column<APIContainerDataReferenceImport>[] = [
    {
      key: 'filename', name: 'File'
    },
    {
      key: 'date', name: 'Import date', formatter: (tableProps) => {
        return <>{moment(tableProps.row.date).format('HH:mm:ss DD/MM/YYYY')}</>;
      }
    },
    {
      key: 'importMode', name: 'Import mode', formatter: (tableProps) => {
        return <>{tableProps.row.clearExisting ? 'Replacement' : 'Addition'}</>;
      }
    },
    {
      key: 'status', name: 'Status', formatter: (tableProps) => {
        return (
          <>
            {StatusLabel[tableProps.row.status] || 'Unknown'}
          </>
        );
      }
    },
  ];

  return (
    <div>
      <AdminHeader
        headline="Container data imports"
        tagText="Global"
        links={[
          {
            url: 'https://s3.eu-west-1.amazonaws.com/storage.shipamax-api.com/ContainerDataImportExample.xlsx',
            label: 'Download template'
          },
          // {
          //   url: '/knowledge-base/What-are-reference-data-uploads---dddb80127fd04e38bd2796e1b80b1727',
          //   label: 'What are reference data uploads?'
          // },
        ]}
      />
      <AdminGrid
        columns={columns}
        fetchData={fetchImports}
        link={{
          url: AdminLinks.ContainerDataImports + '/form',
          label: 'Import data'
        }}
      />
    </div>
  )
};
