import * as React from 'react';
import { Notification } from '../../../common/Notification';
import { ProductDataImportTable } from './ProductDataImportTable';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ProductDataImportForm } from './ProductDataImportForm';
import { AdminLinks } from '../../Admin';

import './index.scss';

interface Props {
  setNotification: (notification: Notification | null) => void;
}

export interface FileError {
  name: string,
  errors: string[]
}

export const ProductDataSection: React.FC<Props> = (props) => {
  // TODO: Improve if we ever figure this out.
  // setFileErrors required due to set state not working in ProductDataImportForm
  const [fileErrors, setFileErrors] = React.useState<FileError[]>([]);
  const history = useHistory();
  history.listen(() => {
    setFileErrors([]);
  });

  return (
    <Switch>
      <Route path={AdminLinks.ProductDataImports} exact component={() => (
        <ProductDataImportTable />
      )} />
      <Route path={[AdminLinks.ProductDataImports + '/form']} exact component={() => (
        <ProductDataImportForm setNotification={props.setNotification} fileErrors={fileErrors} setFileErrors={setFileErrors} />
      )} />
    </Switch>
  );
};
