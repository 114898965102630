import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './components/app/App';
import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader';

import './index.scss';

let IS_METICULOUS_ENABLED = !!process.env.METICULOUS;

async function startApp() {
  if (IS_METICULOUS_ENABLED) {
    console.log('Recording tests with Meticulous');

    await tryLoadAndStartRecorder({
      projectId: '4JQbOmg2OYCRpwKWBaJ55DFnunPviQhC2XWd25lG',
      forceRecording: true,
    });
  }

  ReactDOM.render(<App />, document.getElementById('root'));
}

startApp();
