export interface FieldValidator {
  errorMessage: string;
  isValid: (value: any) => boolean;
}

export interface RecordValidator {
  [name: string]: { validators: FieldValidator[] }
}

export function isFormInvalid<T>(record: Partial<T>, recordValidator: RecordValidator) {
  // @ts-ignore
  return Object.keys(recordValidator).some((recordKey) => recordValidator[recordKey].validators.some((validator) => !validator.isValid(record[recordKey])))
}