import * as React from 'react';
import { CustomMultiSelect, labelSeparator } from '../common/CustomMultiSelect';
import { OptionValue } from 'react-selectize';
import { ChartType, SectionModule, shouldAnonymizeData } from './common';
import { SelectedCreditor } from '../../api/userSettings';
import { CompanyAPI } from '../../api/company';

interface Props {
  selectedValues: SelectedCreditor[];
  onBlur: (values: SelectedCreditor[]) => void;
  chartType: ChartType;
  section: SectionModule;
}

export const CreditorsMultiselect: React.FC<Props> = (props) => {
  const [options, setOptions] = React.useState<OptionValue[]>([]);

  const [freeze, setFreeze] = React.useState<boolean>(false);
  const [timeoutRef, setTimeoutRef] = React.useState<any | undefined>(undefined);

  const salesCreditorPrefix = props.section === SectionModule.APInvoice ? 'Creditor ' : 'Organisation ';

  const creditors = React.useMemo(() => {
    return props.selectedValues.map((selectedCode) => ({
      value: selectedCode.code,
      label: shouldAnonymizeData ? salesCreditorPrefix + selectedCode.id : selectedCode.code
    }));
  }, [props.selectedValues]);

  return (
    <CustomMultiSelect
      className="creditors-multiselect"
      renderNoResultsFound={(item: OptionValue, search: string) => <div className="dropdown-placeholder">{freeze ? 'Searching...' : 'Please enter the first 3 characters of the code...'}</div>}
      values={creditors}
      options={options}
      onBlur={(items) => {
        setOptions([]);
        props.onBlur(items.map((item) => {
          return { code: item.value, id: shouldAnonymizeData ? parseInt(item.label.replace(salesCreditorPrefix, '')) : 0 };
        }));
      }}
      onSearchChange={async (search) => {
        if (search.length <= 2) {
          return [];
        }

        if (freeze) {
          clearTimeout(timeoutRef);
          setTimeoutRef(undefined);
        }

        setFreeze(true);

        setTimeoutRef(setTimeout(async () => {
          const results = await CompanyAPI.fetchCreditorsForInsights(search, props.chartType);
          setOptions(results.map((item) => ({
            value: item.cgwCode,
            label: shouldAnonymizeData ? salesCreditorPrefix + item.id : item.cgwCode + labelSeparator + (item.aliases[0]?.name || '')
          })));
          setFreeze(false);
        }, 400));
      }}
    />
  )
}
