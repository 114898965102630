import * as React from 'react';
import { APIEmailAlert, TeamAPI } from '../../../../api/teams';
import { Notification } from '../../../common/Notification';
import { AdminHeader } from '../../AdminHeader';
import { Link, useHistory } from 'react-router-dom';
import { Column } from 'react-data-grid';
import { AdminLinks } from '../../Admin';
import { UTCOffset, UTCOffsetLabel, getMinutesFromOffset } from './index';
import { AdminGrid, AdminGridSearchParams, AdminGridTagsList, AdminGridTagType, booleanFormatter } from '../../AdminGrid';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { ShipamaxCopyIcon, ShipamaxPasteIcon } from '../../../../images/Icons';
import { OptionValue } from 'react-selectize';

import './index.scss';

export const timezoneName = new Date()
  .toLocaleDateString(navigator.language, {
    day: '2-digit',
    timeZoneName: 'short',
  })
  .slice(4)

interface Props {
  teamOptions: OptionValue[];
  setNotification: (notification: Notification | null) => void;
}

export const EmailAlertsTable: React.FC<Props> = (props) => {
  const [clipboardAlert, setClipboardAlert] = React.useState<APIEmailAlert | null>(null);

  const history = useHistory();

  const fetchData = async (params: AdminGridSearchParams | undefined) => {
    const [teams, emailAlerts] = await Promise.all([TeamAPI.fetchAll(params), TeamAPI.fetchAllAlerts(params)]);
    const result: APIEmailAlert[] = teams.map((team) => {
      const existingAlert = emailAlerts.find((alert) => alert.teamId === team.id);
      return existingAlert || {
        teamId: team.id,
        companyId: team.companyId,
        managerEmails: [],
        alertUser: undefined,
        alertManager: undefined,
        userLimit: null,
        teamLimit: null,
        alertTime: null,
      };
    });
    return result;
  }

  const columns: Column<APIEmailAlert>[] = [
    {
      key: 'team', name: 'Team', formatter: (tableProps) => {
        return <>{tableProps.row.teamId ? props.teamOptions.find((option) => option.value === tableProps.row.teamId)?.label : 'No team'}</>
      }
    },
    {
      key: 'alertUser', name: 'User alerts', formatter: (tableProps => booleanFormatter(tableProps.row.alertUser)), minWidth: 80
    },
    {
      key: 'alertManager', name: 'Manager alerts', formatter: (tableProps => booleanFormatter(tableProps.row.alertManager)), minWidth: 100
    },
    {
      key: 'userLimit', name: 'User threshold', minWidth: 100,
    },
    {
      key: 'teamLimit', name: 'Team threshold', minWidth: 100,
    },
    {
      key: 'managerEmails', name: 'Manager email addresses', formatter: (tableProps) => (
        <OverlayTrigger placement='bottom' overlay={<Tooltip  size='md'>{tableProps.row.managerEmails?.join(', ')}</Tooltip>} delayShow={500}>
          <AdminGridTagsList type={AdminGridTagType.Default} items={tableProps.row.managerEmails || []} />
        </OverlayTrigger>
      )
    },
    {
      key: 'alertTime', name: `Alert time UTC${UTCOffsetLabel} (${timezoneName})`, minWidth: 150, formatter(tableProps) {
        const alertTimeSplit = tableProps.row.alertTime?.split(':');
        const hours = alertTimeSplit?.length === 3 ? alertTimeSplit[0] : undefined;
        const minutes = getMinutesFromOffset(UTCOffset)
        if (hours === undefined || minutes === undefined) return <></>;
        const normalizedHours = Math.floor((parseInt(hours) + UTCOffset + 24) % 24);
        return <>{normalizedHours}:{minutes}</>;
      }
    },
    {
      key: 'actions', name: 'Actions', minWidth: 110, formatter(tableProps) {
        const link = tableProps.row.id ? `${AdminLinks.EmailAlerts}/form/${tableProps.row.id}` : `${AdminLinks.EmailAlerts}/form/add/${tableProps.row.teamId}`;

        return (
          <>
            <OverlayTrigger overlay={<Tooltip>Copy configuration</Tooltip>} placement="bottom" delayShow={500}>
              <button className="light-button active-on-hover" onClick={() => setClipboardAlert(tableProps.row)}><ShipamaxCopyIcon /></button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Paste configuration</Tooltip>} placement="bottom" delayShow={500}>
              <button className="light-button active-on-hover" disabled={!clipboardAlert} onClick={() => {
                history.push(link, { emailAlertData: { ...clipboardAlert, teamId: tableProps.row.teamId, id: tableProps.row.id } });
              }}><ShipamaxPasteIcon /></button>
            </OverlayTrigger>
            <Link to={link}><button className="light-button active-on-hover">Edit</button></Link>
          </>
        )
      },
    },
  ];

  return (
    <div className="email-alerts-section">
      <AdminHeader
        headline="AP Invoice Alerts"
        tagText="Production workspace"
        links={[{
          url: '/knowledge-base/What-are-email-alerts---5eb6e04ae58942daa80d240fb3f3520a',
          label: 'What are email alerts?',
        }]}
      />
      <AdminGrid
        searchPlaceholder="Search teams..."
        columns={columns}
        fetchData={fetchData}
      />
    </div>
  )
}
