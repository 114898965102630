import { ActivityDetailsFieldCorrection } from "../../../api/activityLog";
import { DocumentType } from "../../../api/documentContainer";
import { APIDocumentGroupDetails } from "../../../api/documentGroup";
import { PostingBehaviour } from "../../bill-of-lading/common";

const FieldCorrectionLabel: { [key: string]: { [key2: string]: string } } = {
  'BillOfLading': {
    'billOfLadingNo': 'the BL Number',
    'jobRef': 'the Job Ref',
    'consignee': 'the Consignee',
    'consigneeCgwRecordId': 'the Consignee',
    'shipper': 'the Shipper',
    'origin': 'the Origin',
    'destination': 'the Destination',
    'dischargePort': 'the Discharge Port',
    'firstArrivalPort': 'the Port Of First Arrival',
    'shippingLineCgwRecordId': 'the Carrier',
    'shipperCgwRecordId': 'the Shipper',
    'containerMode': 'the Container Mode',
    'releaseType': 'the Release Type',
    'vessel': 'the Vessel',
    'shippedOnBoardDate': 'the Shipped On Board Date',
  },
  'BillOfLadingImporterReference': {
    'jobRef': 'the Job Ref',
  },
  'CommercialInvoice': {
    'invoiceNumber': 'the Invoice Number',
    'invoiceDate': 'the Invoice Date',
    'importer': 'the Importer',
    'supplier': 'the Supplier',
    'grossTotal': 'the Gross Total',
    'supplierRecordId': 'the Supplier',
    'importerRecordId': 'the Importer',
  },
  'SupplierInvoice': {
    'invoiceNumber': 'the Invoice Number',
    'issuerCgwCode': 'the Issuer Code',
    'invoiceDate': 'the Invoice Date',
    'addresseeCgwCode': 'the Addressee',
    'currency': 'the Currency',
    'grossTotal': 'the Invoice Total',
    'vatTotal': 'the Tax Total',
    'branch': 'the Branch',
    'departmentCode': 'the Department Code',
    'taxCode': 'the Tax Code',
  },
  'SupplierInvoiceJobRefCluster': {
    'total': 'a subtotal',
    'glCode': 'a GL Code',
    'description': 'a Description',
    'branch': 'a Branch',
    'departmentCode': 'a Department Code',
    'taxCode': 'a Tax Code',
    'vatTotal': 'a Tax Amount'
  },
  'SupplierInvoiceJobRef': {
    'jobRef': 'a reference',
    'bolNum': 'a reference',
    'containerNum': 'a reference',
    'purchaseOrder': 'a reference',
    'serviceStartDate': 'a Service Start Date',
    'serviceEndDate': 'a Service End Date',
  }
}

const fieldsWithDifferingNames = [
  'billOfLadingNo',
  'consigneeCgwRecordId',
  'shipperCgwRecordId',
  'origin',
  'destination',
  'dischargePort',
  'shippingLineCgwRecordId',
  'containerMode',
];

export const retrieveFieldCorrectionLabel = (
  fieldCorrection: ActivityDetailsFieldCorrection,
  isNonCargowise?: boolean,
  documentGroup?: APIDocumentGroupDetails | null,
  documentId?: number,
): string => {
  // If the name cannot differ use map above
  if (!fieldsWithDifferingNames.includes(fieldCorrection.field)) {
    if (FieldCorrectionLabel[fieldCorrection.model] && FieldCorrectionLabel[fieldCorrection.model][fieldCorrection.field]) {
      return FieldCorrectionLabel[fieldCorrection.model][fieldCorrection.field];
    }
  }

  const docType = (documentGroup?.documents?.find((d) => d.id === documentId))?.documentType;
  const isBrokerage = documentGroup?.postingBehaviour === PostingBehaviour.NewBJob;
  const isConsol = documentGroup?.postingBehaviour === PostingBehaviour.Forwarding || documentGroup?.postingBehaviour === PostingBehaviour.ForwardingAndClearance;
  const isShipment = docType === DocumentType.HouseBillOfLading || docType === DocumentType.PlaceholderShipment;
  const isMaster = docType === DocumentType.MasterBillOfLading;

  switch (fieldCorrection.field) {
    case 'billOfLadingNo':
      if (isBrokerage || isConsol || isShipment) {
        if (isShipment) return 'the HBL Number';
        if (isMaster) return 'the MBL Number';
      }
      break;
    case 'consigneeCgwRecordId':
      if (isConsol || isMaster) return 'the Receiving Agent';
      break;
    case 'shipperCgwRecordId':
      if (isConsol || isMaster) return 'the Sending Agent';
      break;
    case 'origin':
      if (isNonCargowise) return 'the Load Port';
      break;
    case 'destination':
      if (isNonCargowise) return 'the Discharge Port';
      break;
    case 'containerMode':
      if (isShipment) return 'the Packing Mode';
      break;
  }
  return '';
}
