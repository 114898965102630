import { request } from './request';
import { AdminGridSearchParams } from '../components/admin/AdminGrid';

export interface APIKpiTarget {
  id: number;
  name: string;
  companyId: number;
  straightThroughRate: number;
  timeToProcess: number;
  withinTime: number;
  processExceptionFree: number;
  startDate: string | null;
}

export const KPITargetAPI = {
  async fetchAll(params?: AdminGridSearchParams): Promise<APIKpiTarget[]> {
    const filter = JSON.stringify({
      where: {
        companyId: parseInt(localStorage.getItem('companyId') || ''),
        ...(params?.searchTerm ? { name: { ilike: `%${params.searchTerm.toLowerCase()}%` } } : {}),
      },
      order: 'name',
      ...(params?.limit ? { limit: params.limit } : {}),
    });
    return request(`/KpiTargets?filter=${encodeURIComponent(filter)}`);
  },

  async fetchForDashboard(): Promise<APIKpiTarget[]> {
    const filter = JSON.stringify({
      where: {
        companyId: parseInt(localStorage.getItem('companyId') || ''),

      },
      order: 'name',
    });
    return request(`/KpiTargets?filter=${encodeURIComponent(filter)}`);
  },

  async fetch(id: number): Promise<APIKpiTarget> {
    return request(`/KpiTargets/${id}`);
  },

  async update(id: number, change: Partial<APIKpiTarget>): Promise<unknown> {
    return request(`/KpiTargets/${id}`, 'PATCH', change);
  },

  async create(values: Partial<APIKpiTarget>): Promise<APIKpiTarget> {
    return request(`/KpiTargets`, 'POST', values);
  },

  async isFieldValueTaken(field: keyof APIKpiTarget, value: string, siteId: number | undefined): Promise<boolean> {
    const companyId = parseInt(localStorage.getItem('companyId') || '');
    const filter = JSON.stringify({
      where: {
        companyId,
        [field]: value,
        ...(siteId ? { id: { neq: siteId } } : {}),
      },
    });

    const result = await request(`/KpiTargets?filter=${filter}`);
    return !!result.length;
  }
}
