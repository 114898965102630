import * as React from 'react';
import { DocumentViewerContext } from '../../DocumentViewerContext';
import { APIDocCoordinate, haveCoordinatesFullOverlap } from '../../../api/documentContainer';
import { TableHighlight } from './TableHighlight';
import { Notification } from '../Notification';

interface GroupedCoordinate extends APIDocCoordinate {
  coordinates: APIDocCoordinate[];
}

interface GroupedCoordinateBoxProps {
  groupedCoordinate: GroupedCoordinate;
  documentScale: number;
  documentWidth: number;
  documentHeight: number;
  canvasWidth: number;
  canvasHeight: number;
  rotation: number;
  elementRef: React.RefObject<SVGRectElement> | null;
  firstCoordinateRef: React.RefObject<SVGRectElement> | null;
}

const GroupedCoordinateBox: React.FC<GroupedCoordinateBoxProps> = (props) => {
  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>();

  React.useEffect(() => {
    setSelectedIndex(0);
  }, [props.groupedCoordinate]);

  const documentViewerContext = React.useContext(DocumentViewerContext);
  const highlightedCoordinates = documentViewerContext.focusedCoordinates;

  const translateX = (props.canvasWidth - props.documentWidth) / 2;
  const translateY = (props.canvasHeight - props.documentHeight) / 2;

  const borderRadius = 5 * props.documentScale; // border-radius 5px for 100% zoom

  const isHighlighted = props.groupedCoordinate.coordinates.find((coordinate) => highlightedCoordinates.find((item) => item.id === coordinate.id));
  const strokeWidth = 3;
  const paddingOffset = (props.groupedCoordinate.attributeName === 'full_text' ? 3 : 5) * props.documentScale; // padding 5px for 100% zoom

  const offset = paddingOffset + (isHighlighted ? 0 : strokeWidth / 2.0);

  const x = props.documentWidth * props.groupedCoordinate.xLeft - offset;
  const y = props.documentHeight * (1 - props.groupedCoordinate.yTop) - offset;
  const width = props.documentWidth * (props.groupedCoordinate.xRight - props.groupedCoordinate.xLeft) + offset * 2;
  const height = props.documentHeight * (props.groupedCoordinate.yTop - props.groupedCoordinate.yBottom) + offset * 2;

  const onGroupCoordinateClicked = (groupCoordinate: GroupedCoordinate) => {
    const targetIndex = (selectedIndex !== undefined && selectedIndex < groupCoordinate.coordinates.length - 1) ? selectedIndex + 1 : 0;
    setSelectedIndex(targetIndex);

    documentViewerContext.setFocusedCoordinates([groupCoordinate.coordinates[targetIndex]]);
  }

  return (
    <g transform={`rotate(${props.rotation} ${props.canvasWidth / 2} ${props.canvasHeight / 2}) translate(${translateX} ${translateY})`} key={props.groupedCoordinate.id}>
      <rect
        className={isHighlighted ? `highlight-${props.groupedCoordinate.parentTableName}-selected` : `highlight-${props.groupedCoordinate.parentTableName}`}
        x={x}
        y={y}
        rx={borderRadius + 'px'}
        ry={borderRadius + 'px'}
        width={width}
        height={height}
        style={{
          stroke: isHighlighted ? '#0085ff' : 'none',
          fill: '#0085ff',
          fillOpacity: 0.2,
          strokeWidth: strokeWidth + 'px',
          cursor: 'pointer',
          pointerEvents: 'all'
        }}
        ref={isHighlighted ? props.elementRef : props.firstCoordinateRef}
        onClick={() => onGroupCoordinateClicked(props.groupedCoordinate)}
      />
    </g>
  );
}

interface Props {
  pageIndex: number;
  pageRelativeIndex: number;
  canvasWidth: number;
  canvasHeight: number;
  canvasLeftX: number;
  canvasTopY: number;
  documentScale: number;
  rotation: number;
  resetZoom: () => void;
  scrollableContainer: HTMLDivElement | null;
  filterCoordinates?: (coordinate: APIDocCoordinate) => boolean;
  isLoaded: boolean;
  editTableDefinitions: boolean;
  showTableHighlightOnly: boolean;
  cancelEditTrigger?: number;
  previewChangesTrigger?: number;
  setShowEditDefinitions?: (value: boolean) => void;
  documentId?: number;
  pdfDocument?: any;
  filename?: string | undefined;
  setNotification: (notification: Notification | null) => void;
  showSpinner?: boolean;
  filePages?: number[] | null;
  setActivePage?: (value: number) => void;
  previewTableDefinitions?: boolean;
  documentType?: number;
  reloadGroupData?: () => Promise<void>;
  scrollPosition?: number;
  groupId?: number;
  specificId: number;
  pageContainerRef?: any;
  openEditView?: (pageIndex: number) => void;
  pageNavigatorShowing?: boolean;
  setShowPageNavigator?: (value: boolean) => void;
  setDefinitionsEdited?: (value: boolean) => void;
  applyChangesSubsequentTrigger?: number;
  applyChangesAllTrigger?: number;
  applyChangesCurrentTrigger?: number;
  setApplyChangesSubsequentTrigger?: (value: number) => void;
  setApplyChangesAllTrigger?: (value: number) => void;
  setApplyChangesCurrentTrigger?: (value: number) => void;
  setPreviewChangesTrigger?: (value: number) => void;
  setChangesComplete?: (value: boolean) => void;
}

export const HighlightOverlay: React.FC<Props> = (props) => {
  const elementRef = React.useRef<SVGRectElement>(null);
  const firstCoordinateRef = React.useRef<SVGRectElement>(null);
  const documentViewerContext = React.useContext(DocumentViewerContext);

  const visibleCoordinates = React.useMemo(() => {
    return props.filterCoordinates ? documentViewerContext.coordinates.filter(props.filterCoordinates) : documentViewerContext.coordinates;
  }, [props.filterCoordinates, documentViewerContext.coordinates]);

  const isLandscape = props.rotation % 180 !== 0;

  const [documentWidth, documentHeight] = isLandscape ? [props.canvasHeight, props.canvasWidth] : [props.canvasWidth, props.canvasHeight];

  React.useEffect(() => {
    focusSelectedCoordinate();
  }, [JSON.stringify(documentViewerContext.focusedCoordinates)]);

  React.useEffect(() => {
    if (props.isLoaded && props.filterCoordinates && firstCoordinateRef.current && !isInViewport(firstCoordinateRef.current, props.scrollableContainer)) {
      firstCoordinateRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [props.isLoaded]);

  const focusSelectedCoordinate = () => {
    if (documentViewerContext.focusedCoordinates && elementRef.current && !isInViewport(elementRef.current, props.scrollableContainer)) {
      setTimeout(() => { // we need to wait until it's re-rendered
        if (elementRef.current && !isInViewport(elementRef.current, props.scrollableContainer)) { // scroll only when the element is still not visible after resetting the zoom
          elementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);
    }
  }

  const pageWithFirstCoordinate = visibleCoordinates.length ? visibleCoordinates[0].pageIndex === props.pageIndex : false;

  const groupedCoordinates: GroupedCoordinate[] = React.useMemo(() => {
    const result: GroupedCoordinate[] = [];

    if (visibleCoordinates) {
      visibleCoordinates
        .filter((coordinate) => coordinate.pageIndex === props.pageIndex && !coordinate.isHidden)
        .forEach((coordinate) => {
          const index = result.findIndex((groupedCoordinate) => haveCoordinatesFullOverlap(groupedCoordinate, coordinate));

          if (index !== -1) {
            result[index].coordinates.push(coordinate);
          } else {
            result.push({
              ...coordinate,
              coordinates: [coordinate]
            });
          }
        });
    }

    return result;
  }, [visibleCoordinates]);

  const pageTableCoordinates = React.useMemo(() => {
    return documentViewerContext.tableCoordinates && documentViewerContext.tableCoordinates[props.pageRelativeIndex]
  }, [documentViewerContext.tableCoordinates]);

  const localTableCoordinates = JSON.parse(localStorage.getItem("TempCoordinates") || "{}");

  return (
    <>
      {pageTableCoordinates ? pageTableCoordinates.map((currentTableCoordinates) => (
        <TableHighlight
          documentScale={props.documentScale}
          documentWidth={documentWidth}
          documentHeight={documentHeight}
          canvasWidth={props.canvasWidth}
          canvasHeight={props.canvasHeight}
          rotation={props.rotation}
          tableCoordinates={currentTableCoordinates}
          editTableDefinitions={props.editTableDefinitions}
          pageIndex={props.pageIndex}
          pageRelativeIndex={props.pageRelativeIndex}
          cancelEditTrigger={props.cancelEditTrigger}
          previewChangesTrigger={props.previewChangesTrigger}
          setShowEditDefinitions={props.setShowEditDefinitions}
          documentId={props.documentId}
          pdfDocument={props.pdfDocument}
          setNotification={props.setNotification}
          showSpinner={props.showSpinner}
          filePages={props.filePages}
          previewTableDefinitions={props.previewTableDefinitions}
          allTableCoordinates={documentViewerContext.tableCoordinates}
          setActivePage={props.setActivePage}
          documentType={props.documentType}
          reloadGroupData={props.reloadGroupData}
          scrollPosition={props.scrollPosition}
          groupId={props.groupId}
          specificId={props.specificId}
          pageContainerRef={props.pageContainerRef}
          openEditView={props.openEditView}
          pageNavigatorShowing={props.pageNavigatorShowing}
          setShowPageNavigator={props.setShowPageNavigator}
          setDefinitionsEdited={props.setDefinitionsEdited}
          applyChangesSubsequentTrigger={props.applyChangesSubsequentTrigger}
          applyChangesAllTrigger={props.applyChangesAllTrigger}
          applyChangesCurrentTrigger={props.applyChangesCurrentTrigger}
          setApplyChangesSubsequentTrigger={props.setApplyChangesSubsequentTrigger}
          setApplyChangesAllTrigger={props.setApplyChangesAllTrigger}
          setApplyChangesCurrentTrigger={props.setApplyChangesCurrentTrigger}
          setPreviewChangesTrigger={props.setPreviewChangesTrigger}
          setChangesComplete={props.setChangesComplete}
        />
      )) : (
        props.editTableDefinitions && (
          <TableHighlight
            documentScale={props.documentScale}
            documentWidth={documentWidth}
            documentHeight={documentHeight}
            canvasWidth={props.canvasWidth}
            canvasHeight={props.canvasHeight}
            rotation={props.rotation}
            tableCoordinates={localTableCoordinates[0] && localTableCoordinates[0][props.pageIndex] ? localTableCoordinates[0][props.pageIndex] : { "x": [], "y": [] }}
            editTableDefinitions={props.editTableDefinitions}
            pageIndex={props.pageIndex}
              pageRelativeIndex={props.pageRelativeIndex}
            cancelEditTrigger={props.cancelEditTrigger}
            previewChangesTrigger={props.previewChangesTrigger}
            setShowEditDefinitions={props.setShowEditDefinitions}
            documentId={props.documentId}
            pdfDocument={props.pdfDocument}
            setNotification={props.setNotification}
            showSpinner={props.showSpinner}
            filePages={props.filePages}
            previewTableDefinitions={props.previewTableDefinitions}
            allTableCoordinates={localTableCoordinates}
            setActivePage={props.setActivePage}
            documentType={props.documentType}
            reloadGroupData={props.reloadGroupData}
            scrollPosition={props.scrollPosition}
            groupId={props.groupId}
            specificId={props.specificId}
            pageContainerRef={props.pageContainerRef}
            openEditView={props.openEditView}
            pageNavigatorShowing={props.pageNavigatorShowing}
            setShowPageNavigator={props.setShowPageNavigator}
            setDefinitionsEdited={props.setDefinitionsEdited}
            applyChangesCurrentTrigger={props.applyChangesCurrentTrigger}
            applyChangesSubsequentTrigger={props.applyChangesSubsequentTrigger}
            applyChangesAllTrigger={props.applyChangesAllTrigger}
            setApplyChangesSubsequentTrigger={props.setApplyChangesSubsequentTrigger}
            setApplyChangesAllTrigger={props.setApplyChangesAllTrigger}
            setApplyChangesCurrentTrigger={props.setApplyChangesCurrentTrigger}
            setPreviewChangesTrigger={props.setPreviewChangesTrigger}
            setChangesComplete={props.setChangesComplete}
          />
        )
      )}
      <svg style={{
        position: 'absolute',
        zIndex: 10,
        width: props.canvasWidth,
        height: props.canvasHeight,
        left: props.canvasLeftX,
        top: props.canvasTopY,
        pointerEvents: 'none'
      }}
      >
        {groupedCoordinates
          .map((groupedCoordinate, index) => {
            if (props.showTableHighlightOnly && groupedCoordinate.parentTableName !== "commercial_invoice_line_item") {
              return;
            } else {
              return (
                <GroupedCoordinateBox
                  key={groupedCoordinate.id}
                  groupedCoordinate={groupedCoordinate}
                  documentScale={props.documentScale}
                  documentWidth={documentWidth}
                  documentHeight={documentHeight}
                  canvasWidth={props.canvasWidth}
                  canvasHeight={props.canvasHeight}
                  rotation={props.rotation}
                  elementRef={elementRef}
                  firstCoordinateRef={(pageWithFirstCoordinate && index === 0) ? firstCoordinateRef : null}
                />
              )
            }
          })}
      </svg>
    </>
  )
}

export const isInViewport = (element: SVGRectElement | HTMLDivElement, container: HTMLDivElement | null): boolean => {
  if (!container) {
    return true;
  }

  const rect = element.getBoundingClientRect();

  return (
    rect.top - container.offsetTop + (rect.height / 2) >= 0 &&
    rect.left - container.offsetLeft + (rect.width / 2) >= 0 &&
    rect.bottom - container.offsetTop - (rect.height / 2) <= (container.offsetHeight) &&
    rect.right - container.offsetLeft - (rect.width / 2) <= (container.offsetWidth)
  );
}
