import * as React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Main } from '../main/Main';
import { Login } from '../login/Login';
import { SetPassword } from '../update-password/SetPassword';
import { SetPasswordAction } from '../update-password/SetPasswordAction';
import { getLoginPage } from '../../api/request';
import { AbsoluteRedirect } from '../common/AbsoluteRedirect';

export const App: React.FC = () => {
  const [loggedIn, setLoggedIn] = React.useState<boolean>(Boolean(localStorage.getItem('token')));
  const history = createBrowserHistory();

  const renderMain = () => {
    const loginPage = `${getLoginPage()}?redirectTo=${encodeURIComponent(window.location.pathname + window.location.search)}`;

    if (!loggedIn) return <AbsoluteRedirect to={loginPage} />
    return <Main />
  }

  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" render={() => <Login setLoggedIn={setLoggedIn} onLoginRedirect={true} />} />
        <Route path="/setpassword-request" component={SetPassword} />
        <Route path="/setpassword" component={SetPasswordAction} />
        <Route path="/resetpassword-request" render={() => <SetPassword isReset={true} />} />
        <Route path="/resetpassword" render={() => <SetPasswordAction isReset={true} />} />
        <Route path="/:section/:idView?" render={renderMain} />
        <Route path="/" render={renderMain} />
      </Switch>
    </Router>
  );
}
