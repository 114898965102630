import { request } from './request';

export enum IssueCodeType {
  Discard = "Discard",
  Query = "Query",
  Other = "Other",
};

export interface APIIssueCode {
  id: number;
  code: string;
  actionType: IssueCodeType;
  description: string;
  apInvoice: boolean;
  overheadInvoice: boolean;
  validationTypes: number[];
}

export enum IssueCode {
  Other = 9,
  BulkDiscard = 38,
  BulkReassign = 39,
};

export const IssueCodeAPI = {
  async fetchAll(): Promise<APIIssueCode[] | null> {
    const filter = { where: { code: { neq: 'CNT' } }, order: ['order', 'id'] };
    return request(`/IssueCodes?filter=${JSON.stringify(filter)}`);
  }
}
