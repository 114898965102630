import { request } from './request';
import { APIBillOfLadingImporterReference } from './billOfLading';

export const BillOfLadingImporterReference = {
  async update(id: number, change: Partial<APIBillOfLadingImporterReference>): Promise<APIBillOfLadingImporterReference> {
    return request(`/BillOfLadingImporterReferences/${id}`, 'PATCH', change);
  },

  async create(container: Partial<APIBillOfLadingImporterReference>): Promise<APIBillOfLadingImporterReference> {
    return request('/BillOfLadingImporterReferences', 'POST', container)
  },

  async remove(id: number): Promise<unknown> {
    return request(`/BillOfLadingImporterReferences/${id}`, 'DELETE');
  },

  async updateJobRef(value: string | number | null, isConsol: boolean, billOfLadingId: number, importerReference?: APIBillOfLadingImporterReference[]):
    Promise<APIBillOfLadingImporterReference | number | void> {
    let foundRef = importerReference?.find((ref) => ref.isConsol === isConsol);
    if (foundRef && !value) {
      await BillOfLadingImporterReference.remove(foundRef.id);
      return foundRef.id;
    } else if (foundRef && value) {
      return BillOfLadingImporterReference.update(foundRef.id, { jobRef: String(value) });
    } else if (value) {
      return BillOfLadingImporterReference.create({
        billOfLadingId,
        jobRef: String(value),
        isConsol
      });
    }
  }
}
