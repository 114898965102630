import { AdminGridSearchParams } from '../components/admin/AdminGrid';
import { request } from './request';

export interface APIWebhook {
  id: number;
  companyId: number;
  endpoint: string;
  environment: number;
  eventNames: string[] | string;
}

export const WebhookAPI = {
  async getWebhooks(where: Object = {}): Promise<any> {
    return request(`/Webhooks?filter=${encodeURIComponent(
      JSON.stringify({
        where: {
          companyId: localStorage.getItem('companyId'),
          ...where
        }
      }),
    )}`);
  },
  async getWebhookById(id: number): Promise<any> {
    return request(`/Webhooks/${id}`);
  },
  async createWebhook(webhook: Partial<APIWebhook>): Promise<any> {
    return request(`/Webhooks`, 'POST', webhook);
  },
  async updateWebhook(id: number, webhook: Partial<APIWebhook>): Promise<any> {
    return request(`/Webhooks/${id}`, 'PATCH', webhook);
  },
  async deleteWebhook(id: number): Promise<any> {
    return request(`/Webhooks/${id}`, 'DELETE');
  },
}
