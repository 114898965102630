import * as React from 'react';
import { cn, navigateToUrl } from '../helpers';
import { useHistory } from 'react-router-dom';
import { PermissionLevel } from '../../api/authentication';
import { APICustomDashboard } from '../../api/customDashboard';
import { DashboardsDropdown } from '../insights/custom-dashboards/DashboardsDropdown';

import './top-navigation.scss';

export interface NavigationLink {
  path: string;
  label: string;
}

interface Props {
  links: NavigationLink[];
  permissionLevel: PermissionLevel;
  customDashboards: APICustomDashboard[];
}

export const TopNavigation: React.FC<Props> = (props) => {
  const history = useHistory();

  return (
    <div className="top-navigation">
      {props.links.map((link, index) => (
        <button className={`option ${window.location.pathname === link.path ? 'selected' : ''}`} key={index}>
          <a onClick={() => navigateToUrl(link.path, history)}>{link.label}</a>
        </button>
      ))}
      <DashboardsDropdown
        customDashboards={props.customDashboards}
        permissionLevel={props.permissionLevel}
        className={cn(
          `top-navigation__dropdown ${props.permissionLevel === PermissionLevel.Admin ? 'top-navigation__dropdown--admin' : ''}`,
          `option ${window.location.pathname.includes('/apInsights/custom-dashboards') ? 'selected' : ''}`
        )}
      />
    </div>
  )
}
