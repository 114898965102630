import * as React from 'react';
import { APIClusterAccrual, APIClusterAggregatedAccrual } from '../../../api/clusterAccrual';
import { AccrualsFormMode } from './AccrualsForm';
import * as moment from 'moment';
import { FAIcon } from '../../common/FAIcon';
import { AccrualChanges, AccrualsRow, formatCostNumber, isAccrualSelectableBasedOnCurrency } from './AccrualsRow';
import { APIExchangeRate } from '../../../api/supplierInvoice';
import { RadioButton } from '../../common/RadioButton';
import { APICgwCurrencyExchangeRates } from '../../../api/currency';
import { APIPermissionProfileToleranceThreshold } from '../../../api/permissionProfiles';

import './accruals-table.scss';

export const aggregateAccruals = (accruals: APIClusterAccrual[]): (APIClusterAccrual | APIClusterAggregatedAccrual)[] => {
  const map = new Map<string, (APIClusterAccrual | APIClusterAggregatedAccrual)>();

  accruals.forEach((accrual) => {
    if (!accrual.isApportioned) {
      return map.set(accrual.id + '', accrual);
    }
    const key = `${accrual.consolRef}-${accrual.chargeCode}-${accrual.osCurrencyCode}`;

    if (!map.has(key)) {
      map.set(key, { accruals: [], osCurrencyCode: accrual.osCurrencyCode });
    }
    const aggregatedAccrual = map.get(key) as APIClusterAggregatedAccrual;
    aggregatedAccrual.accruals.push(accrual);
  });

  [...map.keys()].forEach((accrualKey) => { // final processing to convert single apportioned accruals to normal accruals
    const data = map.get(accrualKey)!;

    if ('accruals' in data && data.accruals.length === 1) {
      const accrual = data.accruals[0];
      map.delete(accrualKey);
      map.set(accrual.id + '', accrual);
    }
  });

  return [...map.values()];
}

interface Props {
  invoiceCurrencyCode: string;
  mailboxCurrencyCode: string;
  updateAccruals: (changes: AccrualChanges) => void;
  accrualsFormMode: AccrualsFormMode;
  showAggregatedView: boolean;
  exchangeRates: APIExchangeRate[];
  toleranceThreshold: APIPermissionProfileToleranceThreshold | undefined;
  visibleAccruals: APIClusterAccrual[];
  invoiceAccruals: APIClusterAccrual[];
  cargowiseExchangeRates: APICgwCurrencyExchangeRates[];
  hasApportionedAccruals: boolean;
  isConsolReference: boolean;
}

export const AccrualsTable: React.FC<Props> = (props) => {
  const aggregatedAccruals: (APIClusterAccrual | APIClusterAggregatedAccrual)[] = React.useMemo(() => {
    let showAggregatedAccruals = false;

    if (props.showAggregatedView && props.accrualsFormMode !== AccrualsFormMode.ReadOnly) {
      showAggregatedAccruals = true;
    }

    if (props.accrualsFormMode === AccrualsFormMode.ReadOnly && props.isConsolReference) {
      showAggregatedAccruals = true;
    }

    return showAggregatedAccruals ? aggregateAccruals(props.visibleAccruals) : props.visibleAccruals;
  }, [props.visibleAccruals, props.showAggregatedView]);

  const singleApportionedAccrualIds: number[] = React.useMemo(() => {
    const aggregatedAccruals = aggregateAccruals(props.visibleAccruals);
    const result: number[] = [];
    aggregatedAccruals.forEach((accrual) => {
      if (!('accruals' in accrual) && accrual.isApportioned) {
        result.push(accrual.id);
      }
    });

    return result;
  }, [props.visibleAccruals]);

  const accrualsCurrencyMap = React.useMemo(() => {
    const map = new Map<string, APIClusterAccrual[]>();

    if (props.visibleAccruals.length < 2) return map;

    props.visibleAccruals.forEach((accrual) => {
      if (!map.has(accrual.osCurrencyCode)) {
        map.set(accrual.osCurrencyCode, []);
      }

      const currencyAccruals = map.get(accrual.osCurrencyCode);
      currencyAccruals?.push(accrual);
    });

    return map;
  }, [props.visibleAccruals]);

  const toggleAccrualsTotal = (accruals: APIClusterAccrual[], selected: boolean) => {
    const update = accruals.reduce((result, accrual) => {
      if (accrual.isSplit || accrual.isUpdated) return result;
      if (!isAccrualSelectableBasedOnCurrency(accrual.osCurrencyCode, props.invoiceCurrencyCode, props.mailboxCurrencyCode)) return result;

      return {
        ...result,
        [accrual.id]: {
          selected,
        }
      };
    }, {});

    props.updateAccruals(update);
  }

  return (
    <div className={`accruals accruals--${props.accrualsFormMode}`}>
      <div>
        {!aggregatedAccruals || aggregatedAccruals.length === 0 ? (
          <h3 className="accruals__empty-message"><FAIcon name="exclamation-triangle" solid /> No accruals found</h3>
        ) : (
          <>
            <div className="accruals__header">
              <div>Accruals from your TMS</div>
              <div>Last updated {moment(props.visibleAccruals[0].validationDate).format('Do MMM')}. Click Post to refresh</div>
            </div>
            <table className={`accruals__table accruals__table--${props.accrualsFormMode} ${!props.hasApportionedAccruals ? 'accruals__table--no-apportioned-accruals' : ''}`}>
              <thead /*style={{ display: 'block' }}*/>
                <tr>
                  <th>{props.accrualsFormMode === AccrualsFormMode.ReadOnly ? 'Selected' : 'Select'}</th>
                  <th>Charge Code</th>
                  <th className="num-header">OS Cost</th>
                  <th className="num-header">Local Cost</th>
                  <th className="num-header">Tax Amt</th>
                  <th className="num-header">Local Tax Amt</th>
                  <th>Cost Cur</th>
                  <th>Supplier Reference</th>
                  <th>Description</th>
                  <th>Shipment</th>
                  <th>Apportioned from</th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody className="with-custom-scrollbar">
                {aggregatedAccruals?.map((accrual, index) => (
                  <AccrualsRow
                    key={index}
                    accrual={accrual}
                    invoiceAccruals={props.invoiceAccruals}
                    index={index}
                    updateAccruals={props.updateAccruals}
                    invoiceCurrencyCode={props.invoiceCurrencyCode}
                    mailboxCurrencyCode={props.mailboxCurrencyCode}
                    accrualsFormMode={props.accrualsFormMode}
                    showAggregatedView={props.showAggregatedView}
                    exchangeRates={props.exchangeRates}
                    cargowiseExchangeRates={props.cargowiseExchangeRates}
                    toleranceThreshold={props.toleranceThreshold}
                    singleApportionedAccrualIds={singleApportionedAccrualIds}
                  />
                ))}
              </tbody>
              {props.accrualsFormMode !== AccrualsFormMode.ReadOnly && (
                <tbody /*style={{ display: 'block' }}*/>
                  {[...accrualsCurrencyMap.entries()].map(([currency, accruals]) => {
                    const osTotal = accruals.reduce((result, accrual) => result + (accrual.osCostAmount || 0), 0);
                    const localTotal = accruals.reduce((result, accrual) => result + (accrual.localCostAmount || 0), 0);
                    const vatTotal = accruals.reduce((result, accrual) => result + (accrual.vatAmount || 0), 0);
                    const localVatTotal = accruals.reduce((result, accrual) => result + (accrual.localVatAmount || 0), 0);
                    const allSelected = !accruals.some((accrual) => !accrual.selected && !accrual.isSplit);

                    return (
                      <tr className="accruals__table__row--total">
                        <td>
                          <RadioButton
                            checked={allSelected}
                            onChange={() => toggleAccrualsTotal(accruals, !allSelected)}
                            asCheckbox={true}
                          />
                        </td>
                        <td>{accrualsCurrencyMap.size > 1 ? currency : 'All'} accruals</td>
                        <td className="num-cell">{formatCostNumber(osTotal)}</td>
                        <td className="num-cell">{formatCostNumber(localTotal)}</td>
                        <td className="num-cell">{formatCostNumber(vatTotal)}</td>
                        <td className="num-cell">{formatCostNumber(localVatTotal)}</td>
                        <td>{currency}</td>
                        <td colSpan={6} />
                      </tr>
                    )
                  })}
                </tbody>
              )}
            </table>
          </>
        )}
      </div>
    </div>
  )
}
