import * as React from 'react';
import { ConfirmModal } from '../../common/ConfirmModal';
import { APIUser } from '../../../api/comment';
import { APITeam } from '../../../api/teams';
import { DocumentGroupAPI } from '../../../api/documentGroup';
import { IssueCode } from '../../../api/issueCode';

interface Props {
  documentGroupIds: number[];
  onCancel: () => void;
  onFinished: () => void;
  selectedUser?: APIUser;
  selectedTeam?: APITeam;
}
export const ReassignBulkAction: React.FC<Props> = (props) => {

  const onSubmit = async () => {
    await DocumentGroupAPI.bulkReassign(props.documentGroupIds, [{ id: IssueCode.BulkReassign, description: 'Bulk reassign' }], props.selectedTeam?.id || null, props.selectedUser ? [props.selectedUser.id] : []);
    props.onFinished();
  }

  const invoicesLabel = props.documentGroupIds.length === 1 ? 'invoice' : 'invoices';

  return (
    <ConfirmModal
      show={true}
      onConfirm={onSubmit}
      onHide={props.onCancel}
      keepOpenOnConfirm
      title={`Reassign selected ${invoicesLabel}`}
      text={`
        ${props.documentGroupIds.length === 1 ? 'The selected invoice' : 'All selected invoices'} will be reassigned.
        <br />
        <br />
        <b>Would you like to reassign ${props.documentGroupIds.length} selected ${invoicesLabel}?</b>
      `}
    />
  );
}