import * as React from 'react';
import { Column } from 'react-data-grid';
import { AdminHeader } from '../../AdminHeader';
import { Link } from 'react-router-dom';
import { AdminGrid, AdminGridSearchParams, AdminGridTagsList, AdminGridTagType } from '../../AdminGrid';
import { AdminLinks } from '../../Admin';
import { APIKpiTarget, KPITargetAPI } from '../../../../api/kpiTargets';
import * as moment from 'moment';
const sortAsc = (target1: APIKpiTarget, target2: APIKpiTarget) => moment(target1.startDate).isAfter(target2.startDate) ? 1 : -1;
const sortDesc = (target1: APIKpiTarget, target2: APIKpiTarget) => sortAsc(target2, target1);

enum TargetStatus {
  Scheduled,
  Active,
  Historic,
}

const targetStatusTag = {
  [TargetStatus.Scheduled]: <AdminGridTagsList items={['Scheduled']} type={AdminGridTagType.Yellow} />,
  [TargetStatus.Active]: <AdminGridTagsList items={['Active']} type={AdminGridTagType.Green} />,
  [TargetStatus.Historic]: <AdminGridTagsList items={['Historic']} type={AdminGridTagType.Default} />
}

interface Props {

}

export const KpiTargetsTable: React.FC<Props> = () => {
  const [allTargetsAsc, setAllTargetsAsc] = React.useState<APIKpiTarget[]>([]);

  const fetchData = async (params?: AdminGridSearchParams) => {
    const data = await KPITargetAPI.fetchAll();
    const sortedAsc = data.sort(sortAsc);
    setAllTargetsAsc(sortedAsc);

    return [...data].sort(sortDesc).filter((target) => params?.searchTerm ? target.name.toLowerCase().includes(params?.searchTerm) : true);
  };

  const getNextTarget = (currentTarget: APIKpiTarget) => {
    return allTargetsAsc.find((target) => {
      if (target.id === currentTarget.id) return false;
      if (!moment(target.startDate).isAfter(currentTarget.startDate)) return false;

      return true;
    });
  }

  const getTargetStatus = (target: APIKpiTarget): TargetStatus => {
    if (moment(target.startDate).isAfter(moment())) return TargetStatus.Scheduled;

    const nextTarget = getNextTarget(target);
    if (!nextTarget || moment(nextTarget.startDate).isAfter(moment())) return TargetStatus.Active;

    return TargetStatus.Historic;
  }

  const columns: Column<APIKpiTarget>[] = [
    {
      key: 'name', name: 'Target name',
    },
    {
      key: 'straightThroughRate', name: 'Straight through rate', minWidth: 140, formatter: (tableProps) => <>{tableProps.row.straightThroughRate}%</>
    },
    {
      key: 'timeToProcess', name: 'Time to process', formatter: (tableProps) => <>{tableProps.row.timeToProcess} {tableProps.row.timeToProcess ? (tableProps.row.timeToProcess > 1 ? 'days' : 'day') : ''}</>
    },
    {
      key: 'withinTime', name: 'Within time', formatter: (tableProps) => <>{tableProps.row.withinTime}%</>
    },
    {
      key: 'processExceptionFree', name: 'Straight through potential', minWidth: 155, formatter: (tableProps) => <>{tableProps.row.processExceptionFree}%</>
    },
    {
      key: 'startDate', name: 'Start date', formatter: (tableProps) => <>{tableProps.row.startDate ? moment(tableProps.row.startDate)?.format('DD MMM YYYY') : ''}</>
    },
    {
      key: 'endDate', name: 'End date', formatter: (tableProps) => {
        const nextTarget = getNextTarget(tableProps.row);

        return <>{nextTarget ? moment(nextTarget.startDate).add('-1', 'days').format('DD MMM YYYY') : '-'}</>
      }
    },
    {
      key: 'status', name: 'Status', formatter: ((tableProps) => {
        const targetStatus = getTargetStatus(tableProps.row);
        return targetStatusTag[targetStatus];
      })
    },
    {
      key: 'actions', name: 'Actions', formatter: (tableProps) => {
        const targetStatus = getTargetStatus(tableProps.row);
        if (targetStatus === TargetStatus.Historic) return <></>
        return <Link to={`${AdminLinks.KpiTargets}/form/${tableProps.row.id}`}><button className="light-button active-on-hover">Edit</button></Link>;
      },
    },
  ];

  return (
    <div>
      <AdminHeader
        headline="AP KPI targets"
        tagText="Production workspace"
        links={[{
          label: 'What are AP KPI targets?',
          url: '/knowledge-base/What-are-AP-KPI-targets---3fc296f90bf541ebb9b96c3fa1ab5c4e'
        }]}
      />
      <AdminGrid
        searchPlaceholder="Search targets..."
        columns={columns}
        fetchData={fetchData}
        link={{
          url: AdminLinks.KpiTargets + '/form',
          label: 'Add a new Target'
        }}
      />
    </div>
  )
};
