import * as React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { APICommercialInvoiceLineItem, APIPackingListLineItem } from '../../../api/documentGroup';
import { EditorProps } from 'react-data-grid';
import { CommercialInvoiceLineItemAPI, APICargowiseReferenceProductData } from '../../../api/commercialInvoiceLineItem';
import { StylesConfig, OptionTypeBase, GroupTypeBase } from 'react-select';
import Highlighter from "react-highlight-words";

interface Props {
  property: 'description' | 'productCode' | 'hsCode';
  matchedProperty: 'matchedDescription' | 'matchedProductCode' | 'matchedHsCode';
  propertyText: string;
  editorProps: EditorProps<APICommercialInvoiceLineItem | APIPackingListLineItem>;
  isDisabled: boolean;
  supplierCgwCode: string | null;
  importerCgwCode: string | null;
  updateLineItemRows: (ids: number[], update: Partial<APICommercialInvoiceLineItem | APIPackingListLineItem>) => void;
  updateLineItemsDB: (row: APICommercialInvoiceLineItem | APIPackingListLineItem, field: string, value: string | number | null | boolean, withoutChange?: boolean) => Promise<APICommercialInvoiceLineItem | APIPackingListLineItem | undefined>;
  matchRows: (rows: Readonly<APICommercialInvoiceLineItem | APIPackingListLineItem>[], productData: APICargowiseReferenceProductData) => void;
  gridSelectStyle: (rowHeight: number, isProductCodeDropdown?: boolean) => StylesConfig<OptionTypeBase, false, GroupTypeBase<OptionTypeBase>>;
}

export const LineItemsSelector: React.FC<Props> = (props) => {
  const [searchTerm, setSearchTerm] = React.useState<string | undefined>('');
  const [productsDataMap, setProductsDataMap] = React.useState<{ [index: number]: APICargowiseReferenceProductData }>({});
  const [selected, setIsSelected] = React.useState<boolean>(false);

  const [freeze, setFreeze] = React.useState<boolean>(false);
  const [timeoutRef, setTimeoutRef] = React.useState<any | undefined>(undefined);

  const { row, editorPortalTarget } = props.editorProps;

  const [field, matchedField] = [row[props.property], row[props.matchedProperty]];

  const cleanSearch = () => {
    setSearchTerm('');
    setProductsDataMap({});
  }

  const setRef = () => {
    if (!selected && typeof field === 'string') {
      setProductsDataMap({});
      setSearchTerm(field);
      const input: HTMLInputElement | null = document.querySelector("#product-select-input");
      if (input && input.value.length > 0) input.select();
      setIsSelected(true);
    }
  }

  let getUpdateObject = (value: string | undefined): Partial<APICommercialInvoiceLineItem> => {
    return { [props.property]: value };
  }

  const searchForProductCodes = async (search: string, callback: (options: ReadonlyArray<any>) => void) => {
    const searchByDescription = props.property === 'description';
    let labelProperty: keyof APICargowiseReferenceProductData = 'description';
    if (props.property == 'hsCode') {
      labelProperty = 'hsCode';
    } else if (props.property == 'productCode') {
      labelProperty = 'productCode';
    }
    setProductsDataMap({});
    const productsData = await CommercialInvoiceLineItemAPI.searchProducts(props.property, search, props.supplierCgwCode, props.importerCgwCode) || [];

    setProductsDataMap(productsData.reduce((map, product) => ({ ...map, [product.id]: product }), {}));
    const options = productsData.map((productCode) => ({
      value: productCode.id,
      label: searchByDescription ? productCode[labelProperty] : `${productCode[labelProperty]} - ${productCode.description}`,
    }));
    callback(options);
  };

  return (
    <AsyncCreatableSelect
      ref={setRef}
      inputId="product-select-input"
      autoFocus
      defaultMenuIsOpen
      value={row.matched ? { value: matchedField, label: matchedField } : { value: field, label: field }}
      className="grid-selector"
      isDisabled={props.isDisabled}
      onBlur={async (event) => {
        if ((event.target as HTMLInputElement).value) {
          const value = (event.target as HTMLInputElement).value;
          props.updateLineItemRows([row.orderIndex], getUpdateObject(value));
          await props.updateLineItemsDB(row, props.property, value);
        }
        cleanSearch();
      }}
      onChange={async (option, actionType) => {
        if (!option) {
          props.updateLineItemRows([row.orderIndex], getUpdateObject(''));
          await props.updateLineItemsDB(row, props.property, '');
          return;
        }
        const data = productsDataMap[option?.value];
        if (data) {
          let updatedRow;
          if (row.id === -1) updatedRow = await props.updateLineItemsDB(row, props.property, '');
          await props.matchRows([updatedRow || row], data);
        }
        cleanSearch();
      }}
      onCreateOption={async (value: string) => {
        props.updateLineItemRows([row.orderIndex], getUpdateObject(value));
        await props.updateLineItemsDB(row, props.property, value);
        cleanSearch();
      }}
      createOptionPosition='first'
      formatCreateLabel={(value) => <span className="line-item__product-code__create">Set {props.propertyText} to <strong>{value}</strong></span>}
      formatOptionLabel={(option: any) => {
        if (option.__isNew__) {
          return option.label;
        }
        if (option.label && option.value) {
          const label = option.label?.split(/ - (.+)/);
          if (props.property !== 'description') {
            return (
              <div className="line-item__product-code__option">
                <Highlighter
                  highlightClassName="highlight-search"
                  searchWords={searchTerm ? [searchTerm] : []}
                  autoEscape={true}
                  title={label[1]}
                  textToHighlight={`${label[0]} - ${label[1]}`}
                />
              </div>
            );
          } else {
            return (
              <div className="line-item__product-code__option">
                <Highlighter
                  title={label[0]}
                  className="description-select"
                  highlightClassName="highlight-search"
                  searchWords={searchTerm ? [searchTerm] : []}
                  autoEscape={true}
                  textToHighlight={label[0]}
                />
              </div>
            );
          }
        }
      }}
      inputValue={searchTerm}
      onInputChange={(search, actionMeta) => {
        if (!['menu-close', 'input-blur'].includes(actionMeta.action)) {
          setSearchTerm(search);
        }
      }}
      defaultOptions={[]}
      loadOptions={(search, callback) => {
        if (search.length <= 2) {
          return callback([]);
        }

        if (freeze) {
          clearTimeout(timeoutRef);
          setTimeoutRef(undefined);
        }

        setFreeze(true);

        setTimeoutRef(setTimeout(async () => {
          await searchForProductCodes(search, callback);
          setFreeze(false);
        }, 400));
      }}
      menuPortalTarget={editorPortalTarget as HTMLElement}
      styles={props.gridSelectStyle(42, true)}
      noOptionsMessage={!searchTerm || searchTerm.length < 3 ? () => `Please enter the first 3 characters of the ${props.propertyText}...` : undefined}
      placeholder=""
      isClearable={!!field}
      backspaceRemovesValue={false}
      onKeyDown={(event) => {
        if (['Enter', 'Tab'].includes(event.key)) (event.target as HTMLElement).blur();
      }}
    />
  );
}
