import * as React from 'react';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { RadioButton } from '../../common/RadioButton';
import { APIClusterAccrual, APIClusterAggregatedAccrual } from '../../../api/clusterAccrual';
import { AccrualsFormMode, isAccrualSelectedInAnyOtherCluster, UpdatedAccrual } from './AccrualsForm';
import { NumberInput } from '../../common/NumberInput';
import { roundNumber } from '../../helpers';
import { ExchangeRateType } from '../../../api/supplierInvoice';
import { ShipamaxForkIcon, ShipamaxForkSingleIcon } from '../../../images/Icons';
import { CustomSelect } from '../../common/CustomSelect';
import { OptionValue } from 'react-selectize';
import { APIPermissionProfileToleranceThreshold } from '../../../api/permissionProfiles';
import { checkExceedsSplitAllowancesSingleAccrual } from './helpers';

export const formatCostNumber = (value?: number): string => value !== undefined ? value.toLocaleString(navigator.language, { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '';

const SelectedAccrualIcon = () => (
  <OverlayTrigger overlay={<Tooltip>This accrual has already been<br /> selected for this invoice</Tooltip>} placement="left" delayShow={500}>
    <span>
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: '0.5px' }}>
        <path d="M3.80938 5.30938C3.63906 5.47969 3.36094 5.47969 3.19063 5.30938L2.19063 4.30938C2.02031 4.13906 2.02031 3.86094 2.19063 3.69063C2.36094 3.52031 2.63906 3.52031 2.80938 3.69063L3.5 4.38125L5.19063 2.69063C5.36094 2.52031 5.63906 2.52031 5.80938 2.69063C5.97969 2.86094 5.97969 3.13906 5.80938 3.30938L3.80938 5.30938ZM8 4C8 6.20938 6.20938 8 4 8C1.79063 8 0 6.20938 0 4C0 1.79063 1.79063 0 4 0C6.20938 0 8 1.79063 8 4ZM4 0.75C2.20469 0.75 0.75 2.20469 0.75 4C0.75 5.79531 2.20469 7.25 4 7.25C5.79531 7.25 7.25 5.79531 7.25 4C7.25 2.20469 5.79531 0.75 4 0.75Z" fill="#A6A6A6" />
      </svg>
    </span>
  </OverlayTrigger>
)

/*
  if we have the exchange rate, we can't use the originalLocalCost, we need to calculate the value from the originalOsCost and exchange rate
 */
export const getAccrualOriginalLocalCost = (accrual: APIClusterAccrual, exchangeRate: ExchangeRateType | null): number => {
  if (exchangeRate?.rate) {
    return roundNumber(exchangeRate.isFlipped ? accrual.originalOsCostAmount * exchangeRate.rate : accrual.originalOsCostAmount / exchangeRate.rate, 2);
  }

  return accrual.originalLocalCostAmount;
}

export const getCurrencyExchangeRate = (sourceCurrency: string, targetCurrency: string, exchangeRates: ExchangeRateType[]): ExchangeRateType | null => {
  const directExchangeRate = exchangeRates.find((e) => e.targetCurrency === targetCurrency && e.sourceCurrency === sourceCurrency);
  const flippedExchangeRate = exchangeRates.find((e) => e.sourceCurrency === targetCurrency && e.targetCurrency === sourceCurrency);
  const result = directExchangeRate || flippedExchangeRate || null;

  return result ? { ...result, isFlipped: !directExchangeRate } : null;
}

const sumOrUndefined = (accruals: APIClusterAccrual[], getValue: (accrual: APIClusterAccrual) => (number | undefined)): (number | undefined) => {
  return accruals.some((accrual) => getValue(accrual) === undefined) ? undefined : accruals.reduce((result, accrual) => result + getValue(accrual)!, 0);
}

export const isAccrualSelectableBasedOnCurrency = (accrualCurrency: string, invoiceCurrencyCode: string, mailboxCurrencyCode: string): boolean => {
  return (invoiceCurrencyCode === mailboxCurrencyCode || accrualCurrency === invoiceCurrencyCode);
}

const getAccrualValues = (accrual: APIClusterAccrual | APIClusterAggregatedAccrual): APIClusterAccrual => {
  if ('id' in accrual || !accrual.accruals.length) return accrual as APIClusterAccrual;

  return {
    id: accrual.accruals.reduce((result, accrual, index) => result + Math.pow(10, index) * accrual.id, 0),
    shipmentRef: 'Various',
    consolRef: accrual.accruals[0].consolRef,
    chargeCode: accrual.accruals[0].chargeCode,
    invoiceId: accrual.accruals[0].invoiceId,
    clusterId: accrual.accruals[0].clusterId,
    osCurrencyCode: accrual.accruals[0].osCurrencyCode,
    originalOsCurrencyCode: accrual.accruals[0].originalOsCurrencyCode,
    validationDate: accrual.accruals[0].validationDate,
    // @ts-ignore
    osCostAmount: sumOrUndefined(accrual.accruals, (accrual) => accrual.osCostAmount),
    // @ts-ignore
    localCostAmount: sumOrUndefined(accrual.accruals, (accrual) => accrual.localCostAmount),
    // @ts-ignore
    vatAmount: sumOrUndefined(accrual.accruals, (accrual) => accrual.vatAmount),
    // @ts-ignore
    localVatAmount: sumOrUndefined(accrual.accruals, (accrual) => accrual.localVatAmount),
    originalOsCostAmount: accrual.accruals.reduce((sum, accrual) => sum + accrual.originalOsCostAmount, 0),
    originalLocalCostAmount: accrual.accruals.reduce((sum, accrual) => sum + accrual.originalLocalCostAmount, 0),
    originalVatAmount: accrual.accruals.reduce((sum, accrual) => sum + accrual.originalVatAmount, 0),
    originalLocalVatAmount: accrual.accruals.reduce((sum, accrual) => sum + accrual.originalLocalVatAmount, 0),
    selected: !accrual.accruals.some((accrual) => !accrual.selected),
    isSplit: !accrual.accruals.some((accrual) => !accrual.isSplit),
    isApportioned: true,
    isUpdated: accrual.accruals.some((accrual) => accrual.isUpdated),
  }
}

export interface AccrualChanges {
  [id: number]: Partial<UpdatedAccrual> | undefined;
}

interface Props {
  index: number;
  accrual: APIClusterAccrual | APIClusterAggregatedAccrual;
  invoiceAccruals: APIClusterAccrual[];
  updateAccruals: (changes: AccrualChanges) => void;
  invoiceCurrencyCode: string;
  mailboxCurrencyCode: string;
  accrualsFormMode: AccrualsFormMode;
  showAggregatedView: boolean;
  exchangeRates: ExchangeRateType[];
  toleranceThreshold: APIPermissionProfileToleranceThreshold | undefined;
  cargowiseExchangeRates: ExchangeRateType[];
  singleApportionedAccrualIds: number[];
}

export const AccrualsRow: React.FC<Props> = (props) => {
  const accrual = React.useMemo(() => getAccrualValues(props.accrual), [props.accrual]);
  const aggregatedAccrual = 'accruals' in props.accrual ? props.accrual as APIClusterAggregatedAccrual : undefined;

  const inputRef = React.useRef<any>(null);
  const exchangeRate = React.useMemo<ExchangeRateType | null>(() => getCurrencyExchangeRate(props.mailboxCurrencyCode, accrual.osCurrencyCode, props.exchangeRates), [props.exchangeRates, accrual]);

  const sameCurrency = props.invoiceCurrencyCode === accrual.osCurrencyCode
  const hideCheckboxForAccrualsInDifferentCurrency = !isAccrualSelectableBasedOnCurrency(accrual.osCurrencyCode, props.invoiceCurrencyCode, props.mailboxCurrencyCode);
  const highlightOsCost = accrual.selected && sameCurrency;
  const highlightLocalCost = (accrual.selected && !sameCurrency) || (accrual.localCostAmount !== accrual.originalLocalCostAmount);
  const originalLocalCost = getAccrualOriginalLocalCost(accrual, exchangeRate);

  const toggleSelected = () => {
    let update: AccrualChanges;

    if (accrual.selected) {
      if (aggregatedAccrual) {
        update = aggregatedAccrual.accruals.reduce((result, subAccrual) => {
          if (subAccrual.isSplit || subAccrual.isUpdated) return result;

          return {
            ...result,
            [subAccrual.id]: {
              ...subAccrual,
              isSplit: false,
              selected: false,
              isUpdated: false,
              osCostAmount: subAccrual.originalOsCostAmount,
              localCostAmount: getAccrualOriginalLocalCost(subAccrual, exchangeRate),
              vatAmount: subAccrual.originalVatAmount,
              localVatAmount: subAccrual.localVatAmount,
              osCurrencyCode: subAccrual.originalOsCurrencyCode,
            }
          };
        }, {});
      } else {
        update = {
          [accrual.id]: {
            ...accrual,
            isSplit: false,
            selected: false,
            isUpdated: false,
            osCostAmount: accrual.originalOsCostAmount,
            localCostAmount: originalLocalCost,
            vatAmount: accrual.originalVatAmount,
            localVatAmount: accrual.originalLocalVatAmount,
            osCurrencyCode: accrual.originalOsCurrencyCode,
          },
        }
      }
    } else {
      if (aggregatedAccrual) {
        update = aggregatedAccrual.accruals.reduce((result, subAccrual) => ({
          ...result,
          [subAccrual.id]: {
            ...subAccrual,
            isSplit: false,
            selected: true,
          }
        }), {});
      } else {
        update = {
          [accrual.id]: {
            ...accrual,
            isSplit: false,
            selected: true,
          }
        }
      }
    }

    props.updateAccruals(update);
  }

  const revertUpdate = () => {
    const update: AccrualChanges = aggregatedAccrual ? aggregatedAccrual.accruals.reduce((result, subAccrual) => ({
      ...result,
      [subAccrual.id]: {
        isUpdated: false,
        selected: isAccrualSelectableBasedOnCurrency(subAccrual.originalOsCurrencyCode, props.invoiceCurrencyCode, props.mailboxCurrencyCode),
        isSplit: false,
        osCostAmount: subAccrual.originalOsCostAmount,
        localCostAmount: getAccrualOriginalLocalCost(subAccrual, exchangeRate),
        vatAmount: subAccrual.originalVatAmount,
        localVatAmount: subAccrual.originalLocalVatAmount,
        osCurrencyCode: subAccrual.originalOsCurrencyCode,
      }
    }), {}) : {
      [accrual.id]: {
        isUpdated: false,
        selected: isAccrualSelectableBasedOnCurrency(accrual.originalOsCurrencyCode, props.invoiceCurrencyCode, props.mailboxCurrencyCode),
        isSplit: false,
        osCostAmount: accrual.originalOsCostAmount,
        localCostAmount: originalLocalCost,
        vatAmount: accrual.originalVatAmount,
        localVatAmount: accrual.originalLocalVatAmount,
        osCurrencyCode: accrual.originalOsCurrencyCode,
      }
    };

    props.updateAccruals(update);
  }

  const enableUpdate = (split: boolean) => {
    const update: AccrualChanges = aggregatedAccrual ? aggregatedAccrual.accruals.reduce((result, subAccrual) => ({
      ...result,
      [subAccrual.id]: {
        ...subAccrual,
        isUpdated: true,
        selected: !hideCheckboxForAccrualsInDifferentCurrency,
        isSplit: split,
      }
    }), {}) : {
      [accrual.id]: {
        ...props.accrual,
        isUpdated: true,
        selected: !hideCheckboxForAccrualsInDifferentCurrency,
        isSplit: split,
      }
    }

    props.updateAccruals(update);

    setTimeout(() => {
      inputRef.current?.focus();
    }, 400);
  }

  const osCostChange = (value: number | undefined, newSelectedCurrencyRate?: ExchangeRateType) => {
    let selectedExchangeRate = newSelectedCurrencyRate;
    if (!selectedExchangeRate && accrual.osCurrencyCode !== accrual.originalOsCurrencyCode) {
      selectedExchangeRate = getCurrencyExchangeRate(props.mailboxCurrencyCode, accrual.osCurrencyCode, props.cargowiseExchangeRates) || undefined;
    }

    const currencyExchangeRate = newSelectedCurrencyRate || exchangeRate || selectedExchangeRate;
    const taxRate = accrual.originalVatAmount / accrual.originalOsCostAmount;
    let calculatedLocalCost: number | undefined;

    if (currencyExchangeRate) {
      calculatedLocalCost = value ? roundNumber(currencyExchangeRate.isFlipped ? (value * currencyExchangeRate.rate) : (value / currencyExchangeRate.rate), 2) : undefined;
    } else {
      calculatedLocalCost = value ? roundNumber(value / (accrual.originalOsCostAmount / accrual.originalLocalCostAmount), 2) : undefined;
    }

    const calculatedTax = value ? roundNumber(value * taxRate, 2) : undefined;
    const calculatedLocalTax = currencyExchangeRate?.rate && calculatedTax
      ? roundNumber((calculatedTax / currencyExchangeRate.rate), 2)
      : calculatedTax;

    if (aggregatedAccrual) {
      const update: AccrualChanges = {};

      aggregatedAccrual.accruals.forEach((subAccrual, index) => {
        let osCostAmount, localCostAmount, vatAmount, localVatAmount;

        if (index === aggregatedAccrual.accruals.length - 1) { // the last sub accrual will get the remainder
          osCostAmount = value !== undefined ? roundNumber(Object.values(update).reduce((remainder, subAccrual) => remainder - subAccrual.osCostAmount, value), 2) : undefined;
          localCostAmount = calculatedLocalCost !== undefined ? roundNumber(Object.values(update).reduce((remainder, subAccrual) => remainder - subAccrual.localCostAmount, calculatedLocalCost), 2) : undefined;
          vatAmount = calculatedTax !== undefined ? roundNumber(Object.values(update).reduce((remainder, subAccrual) => remainder - subAccrual.vatAmount, calculatedTax), 2) : undefined;
          localVatAmount = currencyExchangeRate?.rate && vatAmount ? roundNumber((vatAmount / currencyExchangeRate.rate), 2) : vatAmount;
        } else {
          osCostAmount = value !== undefined ? roundNumber(value * subAccrual.originalOsCostAmount / accrual.originalOsCostAmount, 2) : undefined;
          localCostAmount = calculatedLocalCost !== undefined ? roundNumber(calculatedLocalCost * subAccrual.originalLocalCostAmount / accrual.originalLocalCostAmount, 2) : undefined;
          vatAmount = calculatedTax !== undefined ? roundNumber(accrual.originalVatAmount === 0 ? 0 : calculatedTax * subAccrual.originalVatAmount / accrual.originalVatAmount, 2) : undefined;
          localVatAmount = currencyExchangeRate?.rate && vatAmount ? roundNumber((vatAmount / currencyExchangeRate.rate), 2) : vatAmount;
        }

        update[subAccrual.id] = {
          osCostAmount,
          localCostAmount,
          vatAmount,
          localVatAmount,
          exchangeRate: currencyExchangeRate
        }
      });

      props.updateAccruals(update);
    } else {
      props.updateAccruals({ [accrual.id]: { osCostAmount: value, localCostAmount: calculatedLocalCost, vatAmount: calculatedTax, localVatAmount: calculatedLocalTax, exchangeRate: currencyExchangeRate } });
    }
  }

  const onCurrencyChange = (value: string | undefined) => {
    if (!value) return;

    const shouldSelect = value === props.invoiceCurrencyCode;

    const userDefinedExchangeRate = getCurrencyExchangeRate(props.mailboxCurrencyCode, value, props.exchangeRates);
    const exchangeRate = userDefinedExchangeRate || getCurrencyExchangeRate(props.mailboxCurrencyCode, value, props.cargowiseExchangeRates);
    if (exchangeRate) {
      if (aggregatedAccrual) {
        const update: AccrualChanges = {};

        aggregatedAccrual.accruals.forEach((subAccrual, index) => {
          update[subAccrual.id] = { osCurrencyCode: value, selected: shouldSelect };
        });

        props.updateAccruals(update);
      } else {
        props.updateAccruals({ [accrual.id]: { osCurrencyCode: value, selected: shouldSelect } });
      }
      const newAmount = exchangeRate.isFlipped ? accrual.localCostAmount / exchangeRate.rate : accrual.localCostAmount * exchangeRate?.rate;

      // update cost
      osCostChange(roundNumber(newAmount, 2), exchangeRate);
    }
  }

  const isTolerancesUpdateActive = accrual?.isUpdated && props.accrualsFormMode === AccrualsFormMode.Tolerances;
  const remainderOsCost = accrual.selected ? roundNumber(accrual.originalOsCostAmount - (accrual.osCostAmount || 0), 2) : 0;
  const remainderVAT = accrual.selected ? roundNumber(accrual.originalVatAmount - (accrual.vatAmount || 0), 2) : 0;
  const remainderLocalVAT = accrual.selected ? roundNumber(accrual.originalLocalVatAmount - (accrual.localVatAmount || 0), 2) : 0;
  const remainderLocalCost = roundNumber(originalLocalCost - (accrual?.localCostAmount || 0), 2);

  const accrualSelectedInAnyOtherCluster = isAccrualSelectedInAnyOtherCluster(props.invoiceAccruals, accrual);

  const rowClassNames = [
    'accruals__table__row',
    accrual.selected ? 'accruals__table__row--selected' : '',
    accrual?.isUpdated ? 'accruals__table__row--old' : '',
    accrualSelectedInAnyOtherCluster ? 'accruals__table__row--unselectable' : '',
  ];

  const showUpdateButton = !accrual.isSplit && props.toleranceThreshold && !accrualSelectedInAnyOtherCluster;
  const showSplitButton = ((!accrual?.isUpdated) || accrual.isSplit) && sameCurrency && !accrualSelectedInAnyOtherCluster;
  const singleApportionedAccrual = props.singleApportionedAccrualIds.includes(accrual.id);
  const lockApportionedSubAccrualChanges = accrual.isApportioned && !singleApportionedAccrual && !props.showAggregatedView;
  const lockApportionedAggAccrualCurrencyChanges = accrual.isApportioned && !singleApportionedAccrual && hideCheckboxForAccrualsInDifferentCurrency;

  const currencyOptions: OptionValue[] = React.useMemo(() => {
    return [...new Set(props.cargowiseExchangeRates.filter((exchangeRate) => [exchangeRate.sourceCurrency, exchangeRate.targetCurrency].includes(props.mailboxCurrencyCode)).map((exchangeRate) => exchangeRate.targetCurrency === accrual.osCurrencyCode ? exchangeRate.sourceCurrency : exchangeRate.targetCurrency))]
      .sort((c1, c2) => c1.localeCompare(c2))
      .map((currency) => ({ label: currency, value: currency }));
  }, [props.cargowiseExchangeRates, accrual.osCurrencyCode]);

  return (
    <>
      <tr
        key={props.index}
        className={rowClassNames.join(' ')}
      >
        <td>
          {props.accrualsFormMode === AccrualsFormMode.ReadOnly ? (
            <>
              {((accrual.selected || accrualSelectedInAnyOtherCluster) && !accrual?.isUpdated) && (
                <SelectedAccrualIcon />
              )}
            </>
          ) : (
            <>
              {isTolerancesUpdateActive ? (
                <span className="accruals__table__row__old-row-indicator" />
              ) : (lockApportionedSubAccrualChanges || hideCheckboxForAccrualsInDifferentCurrency ? '' : (
                <>
                  {accrualSelectedInAnyOtherCluster ? (
                    <SelectedAccrualIcon />
                  ) : (
                    <RadioButton
                      checked={accrual.selected}
                      onChange={toggleSelected}
                      asCheckbox={true}
                      disabled={isTolerancesUpdateActive}
                    />
                  )}
                </>
              ))}
            </>
          )}
        </td>
        <td title={accrual.chargeCode}>{accrual.chargeCode}</td>
        <td className={`num-cell ${highlightOsCost ? 'highlighted' : ''}`}>{formatCostNumber(accrual?.isUpdated ? accrual.originalOsCostAmount : accrual.osCostAmount)}</td>
        <td className={`num-cell ${highlightLocalCost ? 'highlighted' : ''}`}>{formatCostNumber(accrual?.isUpdated ? accrual.originalLocalCostAmount : accrual.localCostAmount)}</td>
        <td className="num-cell">{formatCostNumber(accrual?.isUpdated ? accrual.originalVatAmount : accrual.vatAmount)}</td>
        <td className="num-cell">{formatCostNumber(accrual?.isUpdated ? accrual.originalLocalVatAmount : accrual.localVatAmount)}</td>
        <td>{accrual.isUpdated ? accrual.originalOsCurrencyCode : accrual.osCurrencyCode}</td>
        <td>
          <OverlayTrigger overlay={<Tooltip>{accrual.supplierReference}</Tooltip>} placement="left" delayShow={500}>
            <span>{accrual.supplierReference}</span>
          </OverlayTrigger>
        </td>
        <td>
          <OverlayTrigger overlay={<Tooltip>{accrual.description}</Tooltip>} placement="left" delayShow={500}>
            <span>{accrual.description}</span>
          </OverlayTrigger>
        </td>
        <td title={accrual.shipmentRef}>{accrual.shipmentRef}</td>
        <td>{accrual.isApportioned ? accrual.consolRef : ''}</td>
        <td>
          {showUpdateButton && !lockApportionedSubAccrualChanges && !lockApportionedAggAccrualCurrencyChanges && (
            <>
              {accrual?.isUpdated ? (
                <button onClick={revertUpdate} data-test-id="revert-update-button">Undo Change</button>
              ) : (
                <button onClick={() => enableUpdate(false)} data-test-id="edit-update-button">Update</button>
              )}
            </>
          )}
        </td>
        <td>
          {showSplitButton && !lockApportionedSubAccrualChanges && (
            <>
              {accrual?.isSplit ? (
                <button onClick={revertUpdate} data-test-id="revert-split-button">Undo Split</button>
              ) : (
                <button onClick={() => enableUpdate(true)} data-test-id="edit-split-button">Split</button>
              )}
            </>
          )}
        </td>
      </tr>
      {accrual?.isUpdated && (
        <tr className={`accruals__table__row accruals__table__row--new`}>
          <td>
            {!accrual?.isSplit && (<ShipamaxForkSingleIcon className='fork-icon single' />)}
            {props.accrualsFormMode === AccrualsFormMode.ReadOnly ? (
              <>
                {(accrual.selected || accrualSelectedInAnyOtherCluster) && (
                  <SelectedAccrualIcon />
                )}
              </>
            ) : (
              <>
                {!hideCheckboxForAccrualsInDifferentCurrency && (
                  <RadioButton
                    checked={accrual.selected}
                    onChange={toggleSelected}
                    asCheckbox={true}
                    locked={lockApportionedSubAccrualChanges}
                  />
                )}
              </>
            )}
          </td>
          <td>{accrual.chargeCode}</td>
          <td className={`num-cell ${highlightOsCost ? 'highlighted' : ''}`}>
            {props.accrualsFormMode === AccrualsFormMode.Tolerances && !lockApportionedSubAccrualChanges ? (
              <NumberInput
                value={accrual?.osCostAmount || null}
                setter={osCostChange}
                name=""
                ref={inputRef}
              />
            ) : (
              <>{formatCostNumber(accrual.osCostAmount)}</>
            )}
          </td>
          <td className={`num-cell ${highlightLocalCost ? 'highlighted' : ''}`}>{formatCostNumber(accrual.localCostAmount)}</td>
          <td className={`num-cell`}>{formatCostNumber(accrual.vatAmount)}</td>
          <td className={`num-cell`}>{formatCostNumber(accrual.localVatAmount)}</td>
          <td className={`accruals__table__row__currency`}>
            {props.accrualsFormMode === AccrualsFormMode.Tolerances && !lockApportionedSubAccrualChanges && props.cargowiseExchangeRates.length > 0 && !accrual.isSplit ? (
              <CustomSelect
                options={currencyOptions}
                renderValue={(item: OptionValue) => {
                  return item.value;
                }}
                value={{ label: accrual.osCurrencyCode, value: accrual.osCurrencyCode } || undefined}
                onValueChange={(option) => {
                  onCurrencyChange(option?.value);
                }}
              />
            ) : (
              <>{accrual.osCurrencyCode}</>
            )
            }
          </td>
          <td>
            <OverlayTrigger overlay={<Tooltip>{accrual.supplierReference}</Tooltip>} placement="left" delayShow={500}>
              <span>{accrual.supplierReference}</span>
            </OverlayTrigger>
          </td>
          <td>
            <OverlayTrigger overlay={<Tooltip>{accrual.description}</Tooltip>} placement="left" delayShow={500}>
              <span>{accrual.description}</span>
            </OverlayTrigger>
          </td>
          <td title={accrual.shipmentRef}>{accrual.shipmentRef}</td>
          <td>{accrual.isApportioned ? accrual.consolRef : ''}</td>
          <td />
          <td />
        </tr>
      )}
      {accrual?.isSplit && isTolerancesUpdateActive && (
        <tr className={`accruals__table__row accruals__table__row--new`}>
          <td>
            <ShipamaxForkIcon className='fork-icon' />
            <RadioButton
              checked={false}
              onChange={() => { }}
              asCheckbox={true}
              disabled
            />
          </td>
          <td>{accrual.chargeCode}</td>
          <td className={`num-cell ${checkExceedsSplitAllowancesSingleAccrual(accrual) ? 'num-cell--invalid' : ''}`}>{formatCostNumber(remainderOsCost)}</td>
          <td className={`num-cell ${checkExceedsSplitAllowancesSingleAccrual(accrual) ? 'num-cell--invalid' : ''}`}>{formatCostNumber(remainderLocalCost)}</td>
          <td className={`num-cell ${checkExceedsSplitAllowancesSingleAccrual(accrual) ? 'num-cell--invalid' : ''}`}>{formatCostNumber(remainderVAT)}</td>
          <td className={`num-cell ${checkExceedsSplitAllowancesSingleAccrual(accrual) ? 'num-cell--invalid' : ''}`}>{formatCostNumber(remainderLocalVAT)}</td>
          <td>{accrual.osCurrencyCode}</td>
          <td>
            <OverlayTrigger overlay={<Tooltip>{accrual.supplierReference}</Tooltip>} placement="left" delayShow={500}>
              <span>{accrual.supplierReference}</span>
            </OverlayTrigger>
          </td>
          <td>
            <OverlayTrigger overlay={<Tooltip>{accrual.description}</Tooltip>} placement="left" delayShow={500}>
              <span>{accrual.description}</span>
            </OverlayTrigger>
          </td>
          <td title={accrual.shipmentRef}>{accrual.shipmentRef}</td>
          <td>{accrual.isApportioned ? accrual.consolRef : ''}</td>
          <td />
          <td />
        </tr>
      )}
    </>
  )
}
