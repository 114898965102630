import * as React from 'react';
import { AdminHeader } from '../../../AdminHeader';
import { APIEmailAccount, EmailAccountAPI, TMSType, ValidationType, CNValidationType } from '../../../../../api/emailAccount';
import { TextInput } from '../../../../common/TextInput';
import { WrapperLabel } from '../../../../common/WrapperLabel';
import { CustomSelect } from '../../../../common/CustomSelect';
import { OptionValue } from 'react-selectize';
import { Toggle } from '../../../../common/Toggle';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AdminLinks } from '../../../Admin';
import { isFormInvalid, RecordValidator } from '../../../../common/Form';
import { APISite } from '../../../../../api/sites';
import { isValidEmail } from '../../../../common/MultiEmailInput';
import { ShipamaxEnvironment } from '../../../../../api/cwCredential';

const emailSuffix = '@ff.shipamax.com';

enum WorkflowTemplate {
  NonCWBol = 5,
  CWBol = 6,
  GatewayCWBol = 8,
}

export const tmsTypeOptions = [
  { value: TMSType.Cargowise, label: 'CargoWise' },
  { value: TMSType.Unknown, label: 'API' },
];

export const transportModeOptions = [
  { value: 'AIR', label: 'AIR' },
  { value: 'SEA', label: 'SEA' },
  { value: 'ROA', label: 'ROA' },
  { value: 'RAI', label: 'RAI' },
];

interface UrlParams {
  id?: string;
  env?: string;
}

interface Props {
  data?: APIEmailAccount;
  teamOptions: OptionValue[];
  countryCodeOptions: OptionValue[];
}

export const MailboxForm: React.FC<Props> = (props) => {
  const urlParams = new URLSearchParams(location.search);
  const environment = urlParams.get('env');

  const [record, setRecord] = React.useState<Partial<APIEmailAccount>>({
    companyId: parseInt(localStorage.getItem('companyId') || ''),
    multiDoc: true,
    triggerParser: true,
    validationType: ValidationType.Clearance,
    cnValidation: CNValidationType.Standard,
    environment: environment ? ShipamaxEnvironment.TestProduction : null,
    useTaxDate: false,
    useDocumentReceivedDate: false,
  });

  const isCW = record.tmsType === TMSType.Cargowise;

  const [isEmailTaken, setIsEmailTaken] = React.useState<boolean>(false);
  const [formEntered, setFormEntered] = React.useState<boolean>(false);

  const params = useParams<UrlParams>();
  const history = useHistory();

  let useCaseOptions =
    record.tmsType === TMSType.Cargowise ?
      [
        { value: ValidationType.Clearance, label: 'Clearance' },
        { value: ValidationType.Forwarding, label: 'Forwarding' },
        { value: ValidationType.ClearanceAndForwarding, label: 'Forwarding & Clearance' }
      ] :
      [
        { value: ValidationType.Export, label: 'Export (API)' },
        { value: ValidationType.GenericTMS, label: 'Import (API)' },
      ]
    ;

  const recordValidator: RecordValidator = {
    emailAddr: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: () => !!record.emailAddr?.replace(emailSuffix, '')
        },
        {
          errorMessage: 'This address is already in use',
          isValid: () => !isEmailTaken
        },
        {
          errorMessage: 'Not a valid email',
          isValid: () => isValidEmail(record.emailAddr || '')
        },
      ]
    },
    tmsType: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => record.tmsType != undefined
      }]
    },
    validationType: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => useCaseOptions.map(u => u.value).includes(record.validationType!)
      }]
    },
    defaultTeamId: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => !!record.defaultTeamId
      }]
    },
    defaultTransportMode: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => !!record.defaultTransportMode
      }]
    },
  }

  const cwValidation = {
    cwCompanyCode: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => !!record.cwCompanyCode
      }]
    },
  }

  const submitDisabled = isCW ?
    isFormInvalid<APISite>(
      record,
      {
        ...recordValidator,
        ...cwValidation,
      }
    ) :
    isFormInvalid<APISite>(record, recordValidator);

  let formDisabled = false;
  switch (record.validationType) {
    case ValidationType.CinvCluster:
      formDisabled = true;
      useCaseOptions = [
        { value: record.validationType, label: 'Clustering' },
      ]
      break;
    case ValidationType.WtgIntegration:
      formDisabled = true
      useCaseOptions = [
        { value: record.validationType, label: 'WTG Internal' },
      ]
      break;

    default:
      break;
  }

  React.useEffect(() => {
    if (params.id) {
      EmailAccountAPI.fetch(parseInt(params.id)).then((data) => {
        setRecord(data);
      });
    }
  }, [params.id]);

  React.useEffect(() => {
    if (!record.emailAddr) {
      setIsEmailTaken(false);
      return;
    }

    EmailAccountAPI.isFieldValueTaken('emailAddr', record.emailAddr, record.id).then((result) => {
      setIsEmailTaken(result);
    });
  }, [record.emailAddr]);

  const onUpdate = (change: Partial<APIEmailAccount>) => {
    setRecord((record) => ({ ...record, ...change }));
  }

  const isTest = new URLSearchParams(window.location.search).get('env') === 'test';
  const getNextUrl = () => `${AdminLinks.ForwardingAndClearanceWorkflows}${isTest ? '?env=test' : ''}`

  const onSave = async () => {
    setFormEntered(true);
    if (submitDisabled) return;

    // Set Workflow Template ID
    if (record.tmsType === TMSType.Cargowise) {
      if ([ValidationType.GatewayForwarding, ValidationType.GatewayClearance].includes(record.validationType!)) {
        record.workflowTemplateId = WorkflowTemplate.GatewayCWBol
      } else {
        record.workflowTemplateId = WorkflowTemplate.CWBol
      }
    } else {
      record.workflowTemplateId = WorkflowTemplate.NonCWBol
    }

    if (record.id) {
      await EmailAccountAPI.update(record.id, record);
    } else {
      await EmailAccountAPI.create(record);
    }

    history.push(getNextUrl());
  }

  let email = record.emailAddr?.replace(emailSuffix, '');

  return (
    <div className="admin__form__wrapper with-custom-scrollbar">
      <AdminHeader
        headline={params.id ? 'Edit Workflow' : 'Add a new Workflow'}
        tagText={environment ? 'Test workspace' : 'Production workspace'}
        links={[{
          label: 'What are workflows?',
          url: '/knowledge-base/What-are-workflows---73a06cd51fb047c6a40d8772b4a8ec2d',
        }]}
      />
      <div className="admin__form__content">
        <div>
          <WrapperLabel text="Mailbox address*">
            <TextInput
              name="emailAddr"
              value={email || null}
              setter={(value) => onUpdate({ emailAddr: value ? value + emailSuffix : undefined })}
              suffix={emailSuffix}
              formEntered={formEntered}
              validators={recordValidator.emailAddr.validators}
              disabled={formDisabled}
            />
          </WrapperLabel>
          <WrapperLabel text="Integration*">
            <CustomSelect
              options={tmsTypeOptions}
              onValueChange={(value) => {
                onUpdate({
                  tmsType: value?.value,
                });
              }}
              value={tmsTypeOptions.find((option) => option.value === record.tmsType)}
              formEntered={formEntered}
              validators={recordValidator.tmsType.validators}
              disabled={formDisabled}
            />
          </WrapperLabel>
          {record.tmsType !== undefined && (
            <WrapperLabel text="Use-Case*">
              <CustomSelect
                options={useCaseOptions}
                onValueChange={(value) => {
                  onUpdate({
                    validationType: value?.value,
                  });
                }}
                value={useCaseOptions.find((option) => option.value === record.validationType)}
                formEntered={formEntered}
                validators={recordValidator.validationType.validators}
                disabled={formDisabled}
              />
            </WrapperLabel>
          )}
          <WrapperLabel text="Team*">
            <CustomSelect
              options={props.teamOptions}
              onValueChange={(value) => onUpdate({ defaultTeamId: value?.value })}
              value={props.teamOptions.find((option) => option.value === record.defaultTeamId)}
              formEntered={formEntered}
              disabled={formDisabled}
              validators={recordValidator.defaultTeamId.validators}
            />
          </WrapperLabel>
          {record.tmsType === TMSType.Cargowise && (
            <>
              <WrapperLabel text="CargoWise Company Code*">
                <TextInput
                  name="companyCode"
                  value={record.cwCompanyCode || null}
                  setter={(value) => onUpdate({ cwCompanyCode: value })}
                  formEntered={formEntered}
                  validators={cwValidation.cwCompanyCode.validators}
                  disabled={formDisabled}
                />
              </WrapperLabel>
              <WrapperLabel text="CargoWise Branch Code">
                <TextInput
                  name="defaultBranch"
                  value={record.defaultBranch || null}
                  setter={(value) => onUpdate({ defaultBranch: value })}
                  formEntered={formEntered}
                  disabled={formDisabled}
                />
              </WrapperLabel>
              <WrapperLabel text="Default Transport Mode">
                <CustomSelect
                  options={transportModeOptions}
                  onValueChange={(value) => onUpdate({ defaultTransportMode: value?.value })}
                  value={transportModeOptions.find((option) => option.value === record.defaultTransportMode)}
                  formEntered={formEntered}
                  disabled={formDisabled}
                  validators={recordValidator.defaultTransportMode.validators}
                />
              </WrapperLabel>
            </>
          )}
          <WrapperLabel text="Multi Doc File Splitting">
            <Toggle
              id="multiDoc"
              checked={!!record.multiDoc}
              onChecked={() => onUpdate({ multiDoc: !record.multiDoc })}
              disabled={formDisabled}
            />
          </WrapperLabel>
          <WrapperLabel text="Enable Organisation Matching">
            <Toggle
              id="orgMatchingEnabled"
              checked={!!record.orgMatchingEnabled}
              onChecked={() => onUpdate({ orgMatchingEnabled: !record.orgMatchingEnabled })}
              disabled={formDisabled}
            />
          </WrapperLabel>
        </div>
        <div>
          <Link to={getNextUrl()}><button className="light-button">Cancel</button></Link>
          {!formDisabled && <button className="full-button" onClick={onSave}>Save</button>}
        </div>
      </div>
    </div>
  )
}
