import * as React from 'react';
import { JobReference } from './JobReference';
import { JobRef, JobRefType } from './Clusters';
import { APIEmailAccount } from '../../../api/emailAccount';
import { Exception, FailedJobRef } from '../../../api/validationResults';
import { CoordinatesFilter } from '../../../api/documentContainer';
import { FAIcon } from '../../common/FAIcon';
import { APIJobOwner, APIJobOwnersMap } from '../../../api/supplierInvoice';
import { CustomDropdown } from '../../common/CustomDropdown';

import './aggregated-job-reference.scss';
import { Cluster } from '../../../api/jobRefCluster';
import { ShipamaxExclamationIcon, ShipamaxUserIcon } from '../../../images/Icons';

const getJobOwnerName = (jobOwner: APIJobOwner): string => {
  return (jobOwner.userData ? jobOwner.userData.firstName + ' ' + jobOwner.userData.lastName : jobOwner.operatorName)
}

interface JobOwnersWrapperProps {
  jobOwners: APIJobOwner[];
  assignUser: (userId: number) => void;
  disabled: boolean;
}

const JobOwnersWrapper: React.FC<JobOwnersWrapperProps> = (props) => {
  if (!props.jobOwners.length) return <></>;
  const firstJobOwner = props.jobOwners[0];

  const jobOwnerCodesMap = React.useMemo(() => {
    const map = new Map<string, APIJobOwner[]>();
    props.jobOwners.forEach((jobOwner) => {
      if (!map.has(jobOwner.operatorCode)) {
        map.set(jobOwner.operatorCode, []);
      }
      map.get(jobOwner.operatorCode)!.push(jobOwner);
    });

    return map;
  }, [props.jobOwners]);

  return (
    <>
      <CustomDropdown
        label={<><ShipamaxUserIcon />{getJobOwnerName(firstJobOwner)}</>}
        options={[firstJobOwner.userId ? { label: <button className="aggregated-reference__assign-btn light-button active-on-hover">Assign invoice</button>, value: -1 } : {
          label: <div><ShipamaxExclamationIcon />No Shipamax user for code {firstJobOwner.operatorCode}</div>,
          value: -1
        }]}
        onSelect={() => {
          if (firstJobOwner.userId) props.assignUser(firstJobOwner.userId);
        }}
        labelActiveOnHover
        hideToggleIcon
        disabled={props.disabled}
        openOnHover
      />
      {jobOwnerCodesMap.size > 1 && (
        <CustomDropdown
          label={`+ ${jobOwnerCodesMap.size - 1} more`}
          labelActiveOnHover
          options={props.jobOwners.map((jobOwner) => ({
            label: (
              <div className="job-owner-item">
                <span title={jobOwner.jobRef}>{jobOwner.jobRef}</span>
                <ShipamaxUserIcon />
                <span title={getJobOwnerName(jobOwner)}>{getJobOwnerName(jobOwner)}</span>
                {jobOwner.userId ? (
                  <>
                    {!props.disabled && (
                      <button
                        onClick={() => props.assignUser(jobOwner.userId!)}
                        className="aggregated-reference__assign-btn light-button active-on-hover"
                      >Assign invoice</button>
                    )}
                  </>
                ) : (
                    <span><ShipamaxExclamationIcon />No Shipamax user for code {jobOwner.operatorCode}</span>
                )}
              </div>
            ),
            value: jobOwner.userId || -1
          }))}
          onSelect={() => { }}
          selectedValue={-1}
        />
      )}
    </>
  )
}

export interface AggregatedDocumentRef {
  key: string;
  refs: JobRef[];
}

export interface AggregatedTMSRef {
  jobRef: string | null;
  aggregatedDocumentRefs: AggregatedDocumentRef[];
}

interface Props {
  aggregatedTMSRef: AggregatedTMSRef;
  onChange: (aggregatedTMSRefIndex: number, aggregatedDocumentIndex: number, change: Partial<JobRef>) => void;
  emailAccount?: APIEmailAccount;
  disabled: boolean;
  exceptions: Exception[];
  removeCoordinates: (coordinatesFilter: CoordinatesFilter) => void;
  addJobRef: () => void;
  removeJobRef: (aggregatedTMSRefIndex: number, aggregatedDocumentRefIndex: number, jobRefIds: number[]) => void;
  jobOwnersMap: APIJobOwnersMap;
  assignUser: (userId: number) => void;
  documentGroupId: number;
  index: number;
  clusters?: Cluster[];
  isAggregated?: boolean;
}

export const AggregatedJobReference = React.memo((props: Props) => {

  let jobRefValue = props.aggregatedTMSRef.jobRef;
  const shouldHighlightJobRef = React.useMemo(() => {
    return props.exceptions.some((exception) => exception.failedJobRefs?.find((failedJobRef: FailedJobRef) => failedJobRef.ref.trim().toUpperCase() === jobRefValue?.trim().toUpperCase()));
  }, [props.exceptions, jobRefValue]);

  let className;

  const jobOwnersDetails = props.jobOwnersMap[jobRefValue?.toUpperCase() || 'INVALID'];
  const onlyDirectRefs = props.aggregatedTMSRef.aggregatedDocumentRefs.every((aggregatedDocumentRef) => aggregatedDocumentRef.refs.every((ref) => ref.type === JobRefType.Ref));

  if ((!jobRefValue && !onlyDirectRefs) || shouldHighlightJobRef) {
    className = (jobRefValue === '' || shouldHighlightJobRef) ? 'aggregated-reference__header__ref--not-found' : 'aggregated-reference__header__ref--empty';
    jobRefValue = (jobRefValue === '' || shouldHighlightJobRef) ? 'Matching job not found' : 'Post the Invoice to match to a job in your TMS';
  }

  return (
    <div className="aggregated-reference">
      <div className="aggregated-reference__header">
        <div className={`aggregated-reference__header__ref ${className}`}>{jobRefValue}</div>
        {jobOwnersDetails && (
          <div className="aggregated-reference__header__user">
            <JobOwnersWrapper
              jobOwners={jobOwnersDetails}
              assignUser={props.assignUser}
              disabled={props.disabled}
            />
          </div>
        )}
      </div>
      <div>
        {props.aggregatedTMSRef.aggregatedDocumentRefs.map((ref, index) => {
          return (
            <JobReference
              key={index}
              aggregatedJobRef={ref}
              onChange={(change) => props.onChange(props.index, index, change)}
              emailAccount={props.emailAccount}
              disabled={props.disabled}
              disabledUpdate={props.aggregatedTMSRef.aggregatedDocumentRefs.length > 1}
              removeCoordinates={props.removeCoordinates}
              addJobRef={props.addJobRef}
              removeJobRef={(ids: number[]) => props.removeJobRef(props.index, index, ids)}
              documentGroupId={props.documentGroupId}
              clusters={props.clusters}
              isAggregated={props.isAggregated}
            />
          )
        })}
      </div>
    </div>
  )
});
