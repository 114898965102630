import { OptionValue } from 'react-selectize';
import { Icon } from 'pivotal-ui/react/iconography';
import * as React from 'react';
import { WrapperLabel } from './WrapperLabel';
import { getCreditorAddressId } from '../helpers';
import { CreditorFlagsFilter, IssuerAPI, mapCreditorsToOptionValues, trimRecordId } from '../../api/issuer';
import { CustomSelect } from './CustomSelect';
import { FieldData } from '../../api/documentContainer';
import Highlighter from 'react-highlight-words';
import { ShipamaxDownIcon } from '../../images/Icons';
import { FAIcon } from './FAIcon';
import { IssuerDetailMap, IssuerDetails, PostingBehaviour } from '../bill-of-lading/common';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { FieldValidator } from './Form';

import './creditor-select.scss';

enum FetchingState {
  None,
  Fetching,
  Fetched,
  AllFetched
}

interface Props {
  recordId: string | null;
  onValueChange: (value: OptionValue | null) => void;
  filterOptions?: (items: OptionValue[], search: string) => OptionValue[];
  label: string;
  creditorDetailsMap: IssuerDetailMap;
  showAddress?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  flags?: CreditorFlagsFilter;
  fieldData?: FieldData;
  companyCode?: string | null;
  dropdownMenuRightAlignment?: true;
  required?: boolean;
  onFocus?: () => void;
  allowCloneAgents?: boolean;
  cloneFieldValue?: (senderValue: string | undefined, receiverFields: string[] | undefined, onlyInvoices: boolean, isOrganizationUpdate?: boolean, onlySameShipmentId?: number) => void;
  copyOnlyToInvoices?: boolean;
  cloneToFields?: string[];
  cloneFieldLabel?: string;
  postingBehaviour?: PostingBehaviour;
  shipmentId?: number;
  formEntered?: boolean;
  validators?: FieldValidator[];
}

export const CreditorSelect: React.FC<Props> = (props) => {
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [recordId, setRecordId] = React.useState<string | null>(null);
  const [options, setOptions] = React.useState<OptionValue[]>([]);
  const [showAddressesDropdown, setShowAddressesDropdown] = React.useState<boolean>(false);
  const [addresses, setAddresses] = React.useState<IssuerDetailMap>({});
  const [fetchingState, setFetchingState] = React.useState<FetchingState>(FetchingState.None);
  const [selectedOption, setSelectedOption] = React.useState<OptionValue | null>(null);

  const nothing = () => { };
  const onFocus = props.onFocus || nothing;

  const divRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (divRef && divRef.current) {
      divRef.current.addEventListener('blur', onBlur);
      divRef.current.addEventListener('scroll', onScroll);

      return () => {
        divRef.current?.removeEventListener('blur', onBlur);
        divRef.current?.removeEventListener('scroll', onScroll);
      }
    }
  }, []);

  React.useEffect(() => {
    setRecordId(props.recordId);
    setAddresses({});
    setFetchingState(FetchingState.None);
    setSelectedOption(null);
    setOptions([]);
  }, [props.recordId])

  React.useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
  }, [showAddressesDropdown]);

  React.useEffect(() => {
    if (fetchingState === FetchingState.None && showAddressesDropdown) {
      // fetch all addresses for that creditor
      fetchAddresses();
    }
  }, [showAddressesDropdown]);

  const onCreditorSearch = async (search: string): Promise<OptionValue[]> => {
    if (search.length < 3) return [];

    const creditors = await IssuerAPI.search(search, props.flags, props.companyCode)
    return mapCreditorsToOptionValues(creditors);
  }

  const fetchAddresses = () => {
    if (recordId) {
      setFetchingState(FetchingState.Fetching);
      IssuerAPI.fetchAllCreditorsAddressesAsMap(recordId, Object.keys(addresses).length, 20).then((fetchedAddresses) => {
        setAddresses((prevValue) => ({
          ...prevValue,
          ...fetchedAddresses
        }));
        if (Object.keys(fetchedAddresses).length < 20) {
          setFetchingState(FetchingState.AllFetched);
        } else {
          setFetchingState(FetchingState.Fetched);
        }
      });
    }
  }

  const onScroll = () => {
    if (![FetchingState.Fetching, FetchingState.AllFetched].includes(fetchingState) && divRef.current && divRef.current.scrollTop >= ((divRef.current.scrollHeight * 0.8) - divRef.current.offsetHeight)) {
      fetchAddresses();
    }
  }

  const onBlur = () => {
    setShowAddressesDropdown(false);
  }

  const onSelectAddress = (recordId: string) => {
    setRecordId(recordId);
    props.onValueChange({
      value: recordId,
      label: addresses[recordId].cgwCode
    });
    setShowAddressesDropdown(false);
  }
  const triggeredValidator = props.formEntered ? props.validators?.find((validator) => {
    if (!validator.isValid(recordId)) {
      return true;
    }
  }) : null;
  const creditorDetails = props.creditorDetailsMap[trimRecordId(recordId || '')];
  const value = recordId ? { value: recordId, label: props.creditorDetailsMap[trimRecordId(recordId)]?.cgwCode || selectedOption?.label || '' } : undefined
  const hasDetails = Boolean(creditorDetails?.name && creditorDetails?.address);

  const isFnc = props.postingBehaviour === PostingBehaviour.ForwardingAndClearance;
  const setInAllLabel = props.copyOnlyToInvoices
    ? `invoices ${isFnc ? ' of this Shipment' : ''}`
    : 'shipments';

  return (
    <div className={`creditor-select ${props.showAddress ? 'with-address' : ''} ${showAddressesDropdown ? 'with-address--open' : ''}`} data-test-id={props.dataTestId}>
      <WrapperLabel
        text={props.label}
        copyComponent={
          (props.cloneFieldValue && (
            <div className="clone-agents-on-wrapper">
              <OverlayTrigger overlay={<Tooltip><span>Set as {props.cloneFieldLabel || props.label} in all {setInAllLabel}</span></Tooltip>} delayShow={500} placement="left">
                <button
                  className="light-button clone-agents-button active-on-hover"
                  disabled={props.disabled || !props.allowCloneAgents || !props.recordId}
                  onClick={() => props.cloneFieldValue ? props.cloneFieldValue(recordId || undefined, props.cloneToFields, props.copyOnlyToInvoices || false, true, (isFnc && props.copyOnlyToInvoices) ? props.shipmentId : undefined) : {}}
                ><FAIcon name="clone" /></button>
              </OverlayTrigger>
            </div>)
          )
        }>
        <CustomSelect
          dataTestId={`${props.dataTestId}-custom-select`}
          disabled={props.disabled}
          renderResetButton={() => <FAIcon name="times" solid className={`simple-select--clear ${value ? '' : 'hide'}`} />}
          showResetButton={true}
          dropdownMenuRightAlignment={props.dropdownMenuRightAlignment}
          options={options}
          value={value}
          required={props.required}
          fieldData={props.fieldData}
          fillInputOnFocus={true}
          renderNoResultsFound={(item: OptionValue, search: string) => <div className="dropdown-placeholder">Please enter the first 3 characters of the code...</div>}
          filterOptions={props.filterOptions ? props.filterOptions : () => options}
          formEntered={props.formEntered}
          validators={props.validators}
          hideValidatorMessage={props.showAddress}
          onSearchChange={async (search: string) => {
            setSearchTerm(search);
            const newOptions = await onCreditorSearch(search);
            setOptions(newOptions);
          }}
          onValueChange={async (value) => {
            setSelectedOption(value);
            if (value && (value as any).newOption) {
              const issuerId = await IssuerAPI.createNewCreditor(value.value, props.flags);
              setRecordId(issuerId.toString());
              props.onValueChange({
                label: value.value,
                value: issuerId.toString()
              });
            } else {
              setRecordId(value?.value);
              props.onValueChange(value);
            }
            setFetchingState(FetchingState.None);
            setAddresses({});
            setSearchTerm("");
          }}
          createFromSearch={(options, search) => {
            if (search.length < 3 || options.find((option) => option.label.toUpperCase() === search.toUpperCase())) {
              // returning as any because react-selectizes simple select type definition is wrong :/
              return null as any;
            }
            else {
              return { label: '', value: search.toUpperCase() };
            }
          }}
          defaultValue={value}
          renderValue={(value => {
            return value.label.split(' - ')[0];
          })}
          renderOption={(value) => {
            if (!value.label) {
              return (
                <div className="creditor-select__option" title={value.value}>
                  <span>
                    Use {value.value}
                  </span>
                </div>
              );
            }
            const [cgwCode, name] = value.label.split(' - ');
            return (
              <div className="creditor-select__option" title={name}>
                <span>
                  <Highlighter
                    highlightClassName="highlight-search"
                    searchWords={[searchTerm]}
                    autoEscape={true}
                    textToHighlight={cgwCode}
                  />
                </span>
                <span> - </span>
                <span>
                  <Highlighter
                    highlightClassName="highlight-search"
                    searchWords={[searchTerm]}
                    autoEscape={true}
                    textToHighlight={name}
                  />
                </span>
              </div>
            );
          }}
          onFocus={onFocus} />
      </WrapperLabel>
      {props.showAddress && (
        <div ref={divRef} tabIndex={0}
          onFocus={onFocus}
        >
          <div
            className={`creditor-select__address__wrapper ${props.disabled ? 'creditor-select__address__wrapper--disabled' : ''} ${showAddressesDropdown ? 'open' : ''} ${triggeredValidator ? 'creditor-select__address__wrapper--highlighted' : ''}`}
            onClick={() => !props.disabled && setShowAddressesDropdown(!showAddressesDropdown)}
          >
            {recordId && <ShipamaxDownIcon className={`creditor-select__address__toggle`} />}
            <CreditorDetailsBlock
              creditorDetails={creditorDetails}
              onClick={() => !props.disabled && setShowAddressesDropdown(!showAddressesDropdown)}
            />
          </div>
          {showAddressesDropdown && (
            <div className={`creditor-select__address__list with-custom-scrollbar`}>
              {recordId && hasDetails && (
                <CreditorDetailsBlock
                  creditorDetails={creditorDetails}
                  onClick={() => setShowAddressesDropdown(false)}
                  selected={true}
                />
              )}
              {Object.keys(addresses).map((addressRecordId) => {
                if (!getCreditorAddressId(addressRecordId) || getCreditorAddressId(addressRecordId) === getCreditorAddressId(recordId)) { // filter out the one without address and the selected one
                  return;
                }

                return (
                  <CreditorDetailsBlock
                    creditorDetails={addresses[addressRecordId]}
                    onClick={() => onSelectAddress(addressRecordId)}
                    selected={false}
                  />
                )
              })}
              {hasDetails && fetchingState === FetchingState.Fetching && (
                <Icon style={{ fontSize: '20px', margin: '5px calc(50%)' }} src="spinner-md" />
              )}
            </div>
          )}
        </div>
      )}
      {(triggeredValidator?.errorMessage && props.showAddress) && (
        <div className="form-field__error">
          {triggeredValidator.errorMessage}
        </div>
      )}
    </div>
  )
}

interface AddressProps {
  creditorDetails: IssuerDetails;
  onClick: () => void;
  selected?: boolean;
}

const CreditorDetailsBlock: React.FC<AddressProps> = ({ creditorDetails, onClick, selected }) => (
  <div className={`creditor-select__address with-custom-scrollbar ${selected ? 'selected' : ''}`} onClick={onClick}>
    {creditorDetails && (
      <>
        {creditorDetails.name} < br />
        {creditorDetails.address && (
          <>
            {creditorDetails.address.addressLine1} <br />
            {creditorDetails.address.addressLine2} <br />
            {creditorDetails.address.city} {creditorDetails.address.postCode} <br />
            {creditorDetails.address.countryCode} {creditorDetails.address.stateProvince}
          </>
        )}
      </>
    )}
  </div>
)
