import * as React from 'react';
import { OptionValue } from 'react-selectize';
import { APIUser, UserAPI, UserStatus } from '../../../../api/comment';
import { AuthAPI, PermissionLevel } from '../../../../api/authentication';
import { Link } from 'react-router-dom';
import { AdminHeader } from '../../AdminHeader';
import { Column } from 'react-data-grid';
import { AdminGrid, AdminGridSearchParams, AdminGridTagsList, AdminGridTagType } from '../../AdminGrid';
import { AdminLinks } from '../../Admin';
import { ConfirmModal, ConfirmModalType } from '../../../common/ConfirmModal';

export const permissionOptions: OptionValue[] = [
  { label: 'Standard', value: PermissionLevel.Standard },
  { label: 'Admin', value: PermissionLevel.Admin }
];

interface Props {
  teamOptions: OptionValue[];
  permissionProfileOptions: OptionValue[];
}

export const UsersTable: React.FC<Props> = (props) => {
  const [userPasswordReset, setUserPasswordReset] = React.useState<APIUser | null>(null);
  const [userPasswordSet, setUserPasswordSet] = React.useState<APIUser | null>(null);

  const fetchAllUsers = (params?: AdminGridSearchParams) => {
    return UserAPI.fetchAllVisible(params);
  };

  const columns: Column<APIUser>[] = React.useMemo(() => [
    {
      key: 'name', name: 'Name', formatter: (tableProps) => {
        return <>{tableProps.row.firstName} {tableProps.row.lastName}</>
      }
    },
    {
      key: 'email', name: 'Email address'
    },
    {
      key: 'operatorCode', name: 'TMS user code', minWidth: 100,
    },
    {
      key: 'team', name: 'Team', formatter: (tableProps) => {
        return <>{tableProps.row.teamId ? props.teamOptions.find((option) => option.value === tableProps.row.teamId)?.label : 'No team'}</>
      }
    },
    {
      key: 'permissionLevel', name: 'Role', minWidth: 80, formatter: (tableProps) => {
        return <>{tableProps.row.permissionLevel ? permissionOptions.find((permission) => permission.value === tableProps.row.permissionLevel)?.label : ''}</>
      }
    },
    {
      key: 'permissionProfile', name: 'Permission profile', formatter: (tableProps) => {
        return <>{tableProps.row.permissionProfileId ? props.permissionProfileOptions.find((option) => option.value === tableProps.row.permissionProfileId)?.label : <span className="pl-[15px]">-</span>}</>
      }
    },
    {
      key: 'status', name: 'Access', minWidth: 90, formatter: (tableProps) => {
        return (
          <AdminGridTagsList
            type={tableProps.row.status === UserStatus.Enabled ? AdminGridTagType.Green : AdminGridTagType.Red}
            items={[tableProps.row.status === UserStatus.Enabled ? 'Has access' : 'No access']}
          />
        );
      },
    },
    {
      key: 'actions', name: 'Actions', minWidth: 170, formatter: (tableProps) => {
        return (
          <>
            <Link to={`${AdminLinks.Users}/form/${tableProps.row.id}`}><button className="light-button active-on-hover">Edit</button></Link>
            {tableProps.row.status === UserStatus.Enabled && (
              <>
                {tableProps.row.emailVerified ? (
                  <button
                    className="light-button active-on-hover"
                    onClick={() => {
                      setUserPasswordSet(null);
                      setUserPasswordReset(tableProps.row);
                    }}
                  >Reset password</button>
                ) : (
                  <button
                    className="light-button active-on-hover"
                    onClick={() => {
                      setUserPasswordReset(null);
                      setUserPasswordSet(tableProps.row)
                    }}
                  >Set password</button>
                )}
              </>
            )}
          </>
        );
      },
    },
  ], [props.teamOptions]);

  return (
    <div className="user-management">
      {userPasswordReset && (
        <ConfirmModal
          title={`Reset password for this user?`}
          text={`You are about to send a password reset email to <i>${userPasswordReset.email}</i>.`}
          show={true}
          onConfirm={async () => {
            await AuthAPI.requestPasswordReset(userPasswordReset.email, true);
            setUserPasswordReset(null);
          }}
          onHide={() => setUserPasswordReset(null)}
          confirmText={'Send'}
        />
      )}
      {userPasswordSet && (
        <ConfirmModal
          title={`Notify the user`}
          text={`The user with email <i>${userPasswordSet.email}</i> will be emailed to invite them to set their password.`}
          show={true}
          onConfirm={async () => {
            await AuthAPI.requestPasswordReset(userPasswordSet.email, false);
            setUserPasswordSet(null);
          }}
          onHide={() => setUserPasswordSet(null)}
          confirmText={'Send'}
          modalType={ConfirmModalType.Message}
        />
      )}
      <AdminHeader
        headline="Users"
        tagText="Global"
        links={[{
          url: '/knowledge-base/User-Management--9048c99b55ff400da4c1ebce5ec969ef',
          label: 'How to configure users'
        }]}
      />
      <AdminGrid
        searchPlaceholder="Search Users or Teams..."
        columns={columns}
        fetchData={fetchAllUsers}
        link={{
          url: AdminLinks.Users + '/form',
          label: 'Add a User'
        }}
      />
    </div>
  );
};
