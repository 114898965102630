import * as React from 'react';
import { CWEAdaptorForm } from './CWEadaptorForm';
import { TabSwitcher } from '../../../common/TabSwitcher';
import { AdminHeader } from '../../AdminHeader';
import { Environment } from '../../../../api/cwCredential';

import './index.scss';

export enum CWCredentialTab {
  ProductionWorkspace = 'Production workspace',
  TestWorkspace = 'Test workspace',
}

interface Props {
}

export const CargowiseIntegrationSection: React.FC<Props> = (props) => {
  const [selectedTab, setSelectedTab] = React.useState<CWCredentialTab>(CWCredentialTab.ProductionWorkspace);

  return (
    <div className="cargowise-integration-section">
      <AdminHeader
        headline="CargoWise credentials"
        links={[{
          label: 'What are CargoWise credentials?',
          url: '/knowledge-base/CargoWise-Inbound-eAdaptor--a17eeb086cbf453389e63438ba038515#a3c45aafd81640a5a93f25b567102232',
        }]}
      />
      <TabSwitcher
        selectedTab={selectedTab}
        onTabClicked={setSelectedTab}
        tabs={[CWCredentialTab.ProductionWorkspace, CWCredentialTab.TestWorkspace]}
      />
      <div className="admin__form__content cw-eAdaptor-form">
        <CWEAdaptorForm
          environment={selectedTab === CWCredentialTab.ProductionWorkspace ? Environment.Production : Environment.Test}
        />
      </div>
    </div >
  );
}
