import { request } from './request';
import { AdminGridSearchParams } from '../components/admin/AdminGrid';

export interface APITeam {
  id: number;
  name: string;
  companyId: number;
  disabled: boolean;
}

export interface APIEmailAlert {
  id?: number;
  teamId: number;
  companyId: number;
  alertUser?: boolean;
  alertManager?: boolean;
  userLimit: number | null;
  teamLimit: number | null;
  managerEmails: string[] | null;
  alertTime: string | null;
}

export const TeamAPI = {
  async fetchAll(params?: AdminGridSearchParams): Promise<APITeam[]> {
    const filter = JSON.stringify({
      where: {
        companyId: parseInt(localStorage.getItem('companyId') || ''),
        ...(params?.searchTerm ? { name: { ilike: `%${params.searchTerm.toLowerCase()}%` } } : {}),
        disabled: false,
      },
      order: 'name',
      ...(params?.limit ? { limit: params.limit } : {}),
    });
    return request(`/Teams?filter=${encodeURIComponent(filter)}`);
  },

  async fetch(id: number): Promise<APITeam> {
    return request(`/Teams/${id}`);
  },

  async update(id: number, change: Partial<APITeam>): Promise<unknown> {
    return request(`/Teams/${id}`, 'PATCH', change);
  },

  async create(values: Partial<APITeam>): Promise<APITeam> {
    return request(`/Teams`, 'POST', values);
  },

  async fetchAllAlerts(params: AdminGridSearchParams | undefined): Promise<APIEmailAlert[]> {
    const filter = JSON.stringify({
      where: { companyId: parseInt(localStorage.getItem('companyId') || '') },
      ...(params?.limit ? { limit: params.limit } : {}),
    });
    return request(`/EmailAlerts?filter=${encodeURIComponent(filter)}`);
  },

  async fetchEmailAlert(id: number): Promise<APIEmailAlert> {
    return request(`/EmailAlerts/${id}`);
  },

  async updateEmailAlert(id: number, change: Partial<APIEmailAlert>): Promise<unknown> {
    return request(`/EmailAlerts/${id}`, 'PATCH', change);
  },

  async createEmailAlert(values: Partial<APIEmailAlert>): Promise<APIEmailAlert> {
    return request(`/EmailAlerts`, 'POST', values);
  },

  async isFieldValueTaken(field: keyof APITeam, value: string, teamId: number | undefined): Promise<boolean> {
    const companyId = parseInt(localStorage.getItem('companyId') || '');
    const filter = JSON.stringify({
      where: {
        companyId,
        disabled: false,
        [field]: value,
        ...(teamId ? { id: { neq: teamId } } : {}),
      },
    });

    const result = await request(`/Teams?filter=${filter}`);
    return !!result.length;
  }
}
