import * as React from 'react';
import { cn } from '../../helpers';
import { ChartWrapper } from '../ChartWrapper';
import { APIPermissionProfile } from '../../../api/permissionProfiles';
import { SectionModule, SerieToggleState } from '../common';
import { APICustomDashboardChart } from '../../../api/customDashboard';
import { PermissionLevel } from '../../../api/authentication';
import { APICustomDashboard } from '../../../api/customDashboard';

interface Props {
  chart: APICustomDashboardChart;
  section: SectionModule;
  permissionProfile: APIPermissionProfile | null;
  permissionLevel: PermissionLevel;
  enableChartEditMode: (chart: APICustomDashboardChart) => void;
  onDeleteChart: (chart: APICustomDashboardChart) => void;
  dragHandleProps: any;
  isBeingDragged: boolean;
  customDashboards: APICustomDashboard[];
  customPageSize: number;
  onChartLoaded: () => void;
  getChartToggles?: (data: SerieToggleState) => void;
}

export const ChartView = React.memo((props: Props) => {
  const dashboardViewSettings = React.useMemo(() => ({
    chart: props.chart,
    dragHandleProps: props.dragHandleProps,
    isBeingDragged: props.isBeingDragged,
    enableChartEditMode: props.enableChartEditMode,
    onDeleteChart: props.onDeleteChart,
    customPageSize: props.customPageSize,
    onChartLoaded: props.onChartLoaded,
  }), [props.chart, props.dragHandleProps, props.isBeingDragged, props.enableChartEditMode, props.onDeleteChart]);

  return (
    <div className={cn(
      "custom-dashboard__chart-view",
      "group h-[560px]",
    )}>
      <ChartWrapper
        setNotification={() => { }}
        section={props.section}
        permissionProfile={props.permissionProfile}
        permissionLevel={props.permissionLevel}
        insightsSettings={props.chart.settings.insightsSettings}
        chartSettings={props.chart.settings.chartSettings}
        userOptions={props.chart.settings.userOptions}
        updateFilterSettings={() => { }}
        updateChartSettings={() => { }}
        dashboardViewSettings={dashboardViewSettings}
        customDashboards={props.customDashboards}
        hideToggles={true}
        getChartToggles={props.getChartToggles}
        removeFilteredData={true}
      />
    </div>
  )
});
