import { request } from './request';
import { Exception } from './validationResults';
import { APIReferenceRequestLog } from './referenceRequestLog';
export interface APICwPostingLog {
  id: number;
  date: string;
  request?: string;
  response: string;
  reponseTime: number;
  supplierInvoiceId: number;
  documentGroupId: number;
  success: boolean;
  startTime: string;
  endTime: string;
}

export interface APICwPostingLogWithException {
  log: APICwPostingLog;
  referenceRequestLog: APIReferenceRequestLog;
  exception: Exception;
}

export const CwPostingLogAPI = {
  async fetchLogForInvoiceId(id: number): Promise<APICwPostingLog[]> {
    return request(`/CargowisePostingLogs?filter=${encodeURIComponent(JSON.stringify({ where: { supplierInvoiceId: id, success: true } }))}`);
  },

  async fetchLogForDocumentGroup(id: number): Promise<APICwPostingLog[]> {
    return request(`/CargowisePostingLogs?filter=${encodeURIComponent(JSON.stringify({ where: { documentGroupId: id, success: true } }))}`);
  },
}
