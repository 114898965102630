import { request } from './request';
import { CWEAdaptorCredentialStatus } from '../components/admin/sections/cargowise-integration/CWEadaptorForm';

export interface APICwEAdaptorCredential {
  id?: number;
  customerEnv: Environment;
  enterpriseId?: string;
  serverId?: string;
  username: string;
  password: string;
  endpoint: string;
  companyId: number;
  active: boolean;
  envId?: number;
}

export enum Environment {
  Test = 0,
  Production = 1,
}

export enum ShipamaxEnvironment {
  Production = 1,
  TestProduction = 2,
  Staging = 3,
  Test = 4,
}

export interface APITestResult {
  isError: boolean;
}

export const CwCredentialAPI = {
  fetchCwEAdaptorCredentials(args: Partial<APICwEAdaptorCredential>): Promise<APICwEAdaptorCredential[]> {
    const filter = JSON.stringify({
      where: {
        companyId: parseInt(localStorage.getItem('companyId') + ''),
        active: true,
        ...args
      }
    });
    return request(`/CargowiseCredentials?filter=${encodeURIComponent(filter)}`);
  },

  async testCwEAdaptorCredential(values: APICwEAdaptorCredential): Promise<CWEAdaptorCredentialStatus> {
    const result = await request(`/CargowiseCredentials/test?credentials=${encodeURIComponent(JSON.stringify(values))}`);
    return result.isError ? CWEAdaptorCredentialStatus.Failed : CWEAdaptorCredentialStatus.Success;
  },

  async update(id: number, cwApplicationCredential: Partial<APICwEAdaptorCredential>): Promise<boolean> {
    return request(`/CargowiseCredentials/${id}`, 'PATCH', cwApplicationCredential);
  },

  async create(data: Partial<APICwEAdaptorCredential>): Promise<APICwEAdaptorCredential> {
    return request('/CargowiseCredentials', 'POST', data);
  },
}
