import * as React from 'react';
import { DropdownOptions } from '../../common/DropdownList';
import {
  APIError,
  BLDiscarded,
  BLReopened,
  BLUnposted,
  Done,
  Notification,
  ValidationIssues,
  PostToCWError,
  PostToCWSuccess,
  ValidationSuccess,
  EventNotificationRequest
} from '../../common/Notification';
import { APIDocumentGroupConsol, APIDocumentGroupDocument, DocumentGroupAPI } from '../../../api/documentGroup';
import { ConfirmModalProps } from '../../common/ConfirmModal';
import { UserSettingsContext } from '../../main/Main';
import { Exception, ExceptionCode } from '../../../api/validationResults';
import { PostingBehaviour, StatusCode, statusText } from '../common';
import { APITeam } from '../../../api/teams';
import { APIUser } from '../../../api/comment';
import { AssignmentRule } from '../../../api/userSettings';
import { ValidationType } from '../../../api/emailAccount';
import { PostingBehaviourButton } from '../../common/PostingBehaviourButton';
import { FAIcon } from '../../common/FAIcon';
import { APIIssueCode } from '../../../api/issueCode';
import { ModalMode, CodeSelectionModalProps, CodeSelectionModalSubmitParams } from '../../common/CodeSelectionModal';
import { BillOfLadingImporterReference } from '../../../api/billOfLadingImporterReference';
import { ShipamaxPostIcon, ShipamaxClipboardIcon, ShipamaxTrashIcon, ShipamaxSignOutIcon, ShipamaxBackIcon } from '../../../images/Icons';
import { ActivityLogAPI, ActivityType, APIActivityLog } from '../../../api/activityLog';
import { DocumentAssignment } from '../../common/DocumentAssignment';
import { WrapperLabel } from '../../common/WrapperLabel';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { APIDocumentContainer } from '../../../api/documentContainer';

import './status-bar.scss';
import { OverlayType } from '../GroupSection';

interface Props {
  groupId?: number;
  validationResultId?: number;
  validationType?: ValidationType;
  postingBehaviour: PostingBehaviour;
  disabledNewBjob: boolean;
  placeholderJobRef?: string;
  exceptions?: Exception[];
  status?: number;
  teams: APITeam[];
  users: APIUser[];
  assigneeOptions?: DropdownOptions;
  assignmentRule: AssignmentRule;
  show: boolean;
  setConfirmModal: (props: ConfirmModalProps) => void;
  setDiscardCodesModal: (props: CodeSelectionModalProps) => void;
  setNotification: (notification: Notification) => void;
  reloadDetailsForGroup: (id: number, reloadBoards?: boolean) => Promise<void>;
  reloadDashboard: () => void;
  reloadGroupData: () => Promise<void>;
  setOverlay: (value: OverlayType) => void;
  closeDetailsView: () => void;
  isTeamDropdownDisabled: boolean;
  onPostButtonClick?: () => void;
  placeholder?: APIDocumentGroupDocument<APIDocumentGroupConsol>;
  groupAnyBoL?: APIDocumentContainer;
  onActivityLogAdded: (newActivityLog: Partial<APIActivityLog>) => void;
  addEventsToListen: (event: EventNotificationRequest) => void;
  removeGroupNotifications: (groupId: number) => void;
}

let pollingTimeoutId: number;

export const StatusBar: React.FC<Props> = (props) => {
  const [assignmentRule, setAssignmentRule] = React.useState<AssignmentRule>({ teamId: null, userIds: [] });
  const userSettings = React.useContext(UserSettingsContext);
  const userId = localStorage.getItem('userId') ? Number(localStorage.getItem('userId')) : -1;

  React.useEffect(() => {
    if (props.assignmentRule) setAssignmentRule(props.assignmentRule);
  }, [props.assignmentRule]);

  React.useEffect(() => {
    if (!props.show) cancelPolling();
    if (props.groupId) props.removeGroupNotifications(props.groupId);
  }, [props.show]);

  const logStatusChange = (newStatus: StatusCode, codes?: APIIssueCode[]) => {
    const newActivityLog = {
      userId,
      documentGroupId: props.groupId,
      type: ActivityType.StatusChange,
      details: {
        oldStatus: props.status || StatusCode.Unposted,
        newStatus,
        codes: codes?.map((code) => ({ id: code.id, code: code.code, description: code.description })),
      }
    }

    props.onActivityLogAdded(newActivityLog);
    return ActivityLogAPI.createLog(newActivityLog);
  }

  const onAssignmentRuleChanged = async (value: AssignmentRule, codes?: APIIssueCode[]) => {
    if (props.validationResultId && props.groupId) {
      await DocumentGroupAPI.bulkReassign([props.groupId], [], value.teamId, value.userIds);

      setAssignmentRule(value);

      props.reloadDashboard();
      props.reloadGroupData();
    }
  }

  const onDiscardCodesModal = () => {
    props.setDiscardCodesModal({
      show: true,
      mode: ModalMode.Discard,
      submit: onDiscard,
      validationType: props.validationType
    });
  }

  const onDiscard = ({ actionMode, codes }: CodeSelectionModalSubmitParams) => {
    if (userSettings.blSettings?.hideDiscardModal) {
      onDiscardConfirmed(actionMode, codes);
    } else {
      props.setConfirmModal({
        show: true,
        title: 'Discard this Card',
        text: 'This will hide this pack of documents from the board and mark it as Discarded. You can use the search to find this card and re-open it at a later date if necessary.',
        checkboxLabel: 'Do not show this message again',
        checkboxValue: userSettings.blSettings?.hideDiscardModal || false,
        onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          const settings = { ...userSettings };
          if (settings.blSettings) settings.blSettings.hideDiscardModal = event.target.checked;
          if (userSettings.update) userSettings.update(settings);
        },
        onConfirm: () => onDiscardConfirmed(actionMode, codes),
      });
    }
  }

  const onDiscardConfirmed = async (mode: ModalMode, codes: APIIssueCode[]) => {
    // set discarded
    if (props.groupId) {
      const promises: Promise<unknown>[] = [];
      promises.push(DocumentGroupAPI.updateStatus(props.groupId, StatusCode.Discarded));
      promises.push(logStatusChange(StatusCode.Discarded, codes));

      const resultPromise = await Promise.all(promises);

      codes.forEach((code) => {
        DocumentGroupAPI.createGroupDiscardReason({
          issueCodeId: code.id,
          documentGroupId: props.groupId,
          userId,
          ...(code.description && { description: code.description })
        });
      });

      if (resultPromise.includes(null)) {
        props.setNotification({ ...APIError, details: { groupId: props.groupId }, reason: `Error on discard request, returned null` });
      } else {
        props.setNotification(BLDiscarded);
        props.closeDetailsView();
      }
    }
  }

  const onReopen = () => {
    if (userSettings.blSettings?.hideReopenModal) {
      onReopenConfirmed();
    } else {
      const isDiscarded = props.status === StatusCode.Discarded;
      const newStatusTitle = isDiscarded ? 'Move this Card to Unposted' : 'Reopen this pack';
      props.setConfirmModal({
        show: true,
        title: newStatusTitle,
        text: `This will change the pack's status to '${isDiscarded ? 'Unposted' : 'Reopen'}' and enable you to make updates and post data. Do you want to ${newStatusTitle.toLowerCase()}?`,
        checkboxLabel: 'Do not show this message again',
        confirmText: `${isDiscarded ? 'Move' : 'Reopen'}`,
        checkboxValue: userSettings.blSettings?.hideReopenModal || false,
        onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          const settings = { ...userSettings };
          if (settings.blSettings) settings.blSettings.hideReopenModal = event.target.checked;
          if (userSettings.update) userSettings.update(settings);
        },
        onConfirm: onReopenConfirmed,
      });
    }
  }

  const onReopenConfirmed = async () => {
    if (props.groupId) {
      // Discarded => Unposted; Done/Posted => Reopened;
      const startsAsDiscarded = props.status === StatusCode.Discarded;
      const newStatus = startsAsDiscarded ? StatusCode.Unposted : StatusCode.Reopened;

      const promises: Promise<unknown>[] = [];
      promises.push(DocumentGroupAPI.updateStatus(props.groupId, newStatus));
      promises.push(logStatusChange(newStatus));

      const resultPromise = await Promise.all(promises);
      if (resultPromise.includes(null)) {
        props.setNotification({ ...APIError, details: { groupId: props.groupId }, reason: `Error on reopen request, returned null` });
      } else {
        // reload board and detail view
        props.reloadDetailsForGroup(props.groupId, true);
        props.setNotification(startsAsDiscarded ? BLUnposted : BLReopened);
      }
    }
  }

  const onRevalidateBillOfLading = () => {
    createOnRevalidateActionClickHandler(
      'Revalidate Pack',
      `This will only validate the pack's data, it will not post to your TMS.<br>
      The process may take a few moments. <strong>Do you want to revalidate?</strong>`,
      undefined
    )();
  }

  const onPostBillOfLadingToCW = (isReopen: boolean) => {
    isReopen
      ? createOnPostingActionClickHandler(
        'Repost pack',
        'This will repost the data from this pack to your TMS and may override/update existing data. Do you want to repost? This cannot be undone from within Shipamax.',
        undefined,
        'hidePostModal',
        'Repost'
      )()
      : createOnPostingActionClickHandler(
        'Post pack',
        'This will post the data from this pack to your TMS. Do you want to post? This cannot be undone from within Shipamax.',
        undefined,
        'hidePostModal',
        'Post'
      )();
  }

  const cancelPolling = () => {
    window.clearTimeout(pollingTimeoutId)
    // there might be a chance that the user closes the modal while the timeout function is executed,
    // but has not yet scheduled another call, so we call clearTimeout again after half a second, just to make sure
    window.setTimeout(() => window.clearTimeout(pollingTimeoutId), 500)
  }

  /**
   * Works for validation+posting or just validation.
   *
   * @param revalidate If it's not to post.
   */
  const onPostConfirmed = async (hasSizeErrors: boolean, revalidate: boolean) => {
    // post to cw
    if (props.groupId && props.validationResultId) {
      props.setOverlay(OverlayType.CloseMessage);
      const lastValidationResultId = props.validationResultId;

      try {
        // sets a flag that allows file size errors to be ignored posting
        if (!revalidate) await DocumentGroupAPI.update(props.groupId, { ignoreLargeFiles: hasSizeErrors });

        await ActivityLogAPI.createLog({
          documentGroupId: props.groupId,
          userId,
          type: ActivityType.RequestedValidation,
          details: { withoutPosting: revalidate }
        });

        const response = await DocumentGroupAPI.validate(props.groupId, revalidate ? false : true);
        // wait for response and display correct notification
        if (response === null) {
          props.setNotification({ ...APIError, details: { groupId: props.groupId }, reason: `Error on group post request, returned null` });
        } else {
          const groupId = props.groupId;
          props.addEventsToListen({
            id: groupId,
            request: () => { return checkValidationResult(groupId, lastValidationResultId, revalidate) }
          });
          const result = await new Promise<{ reload: boolean, closeGroup: boolean, notification: Notification }>((resolve) => checkValidationResult(groupId, lastValidationResultId, revalidate, resolve));

          if (result.closeGroup) props.closeDetailsView();
          if (result.reload) props.reloadDetailsForGroup(groupId, true);
        }
      } catch (error) {
        props.setNotification({ ...APIError, details: { groupId: props.groupId }, reason: `Error on group post request: ${error}` });
      }
      props.setOverlay(OverlayType.Hidden);
    }
  }

  const checkValidationResult = async (groupId: number, lastValidationResultId: number, revalidate: boolean, resolve?: Function) => {
    const group = await DocumentGroupAPI.fetchGroup(groupId);
    let result;
    if (!group) result = { notification: { ...APIError, details: { groupId: props.groupId }, reason: 'Error fetching group on checkValidationResult' }, groupId };

    if (group.validationResultId === lastValidationResultId) {
      if (resolve) {
        pollingTimeoutId = window.setTimeout(() => checkValidationResult(groupId, lastValidationResultId, revalidate, resolve), 3000);
      }
      return;
    }

    const exceptions = group.lastValidationResult?.details?.exceptions || [];
    const errors = exceptions.filter((e) => !e.isWarning && e.code !== ExceptionCode.ManualApprovalRequired);
    if (!group.lastValidationResult.valid && errors.length !== 0) {
      // There is a new validation result, but it contains error exceptions.
      result = { reload: true, notification: revalidate ? ValidationIssues : PostToCWError, groupId };
    }

    if ([StatusCode.Processing].includes(group.status)) {
      // New validation result is ok but the groups status has not updated yet.
      if (resolve) {
        pollingTimeoutId = window.setTimeout(() => checkValidationResult(groupId, lastValidationResultId, revalidate, resolve), 3000);
      }
      return;
    }

    if ([StatusCode.Posted, StatusCode.Done].includes(group.status)) {
      // No errors on the document group. Post successful!
      result = { reload: true, notification: PostToCWSuccess, groupId };
    }

    if ([StatusCode.PendingResponse].includes(group.status)) {
      // No errors on the document group. Post successful!
      result = { closeGroup: true };
    }
    if (!result) {
      result = { reload: true, notification: ValidationSuccess, groupId };
    }

    if (resolve) {
      return resolve(result);
    }
    return result;
  }

  const onDone = () => {
    if (userSettings.blSettings?.hideDoneModal) {
      onDoneConfirmed();
    } else {
      props.setConfirmModal({
        show: true,
        title: 'Mark as Done',
        text: 'This will remove this pack of documents from the board. You can use the search to find this card and re-open it at a later date if necessary.',
        checkboxLabel: 'Do not show this message again',
        checkboxValue: userSettings.blSettings?.hideDoneModal || false,
        onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          const settings = { ...userSettings };
          if (settings.blSettings) settings.blSettings.hideDoneModal = event.target.checked;
          if (userSettings.update) userSettings.update(settings);
        },
        onConfirm: onDoneConfirmed,
      });
    }
  }

  const onDoneConfirmed = async () => {
    // set done
    if (props.groupId) {
      const promises: Promise<unknown>[] = [];
      promises.push(DocumentGroupAPI.updateStatus(props.groupId, StatusCode.Done));
      promises.push(logStatusChange(StatusCode.Done));

      const resultPromise = await Promise.all(promises);
      if (resultPromise.includes(null)) {
        props.setNotification({ ...APIError, details: { groupId: props.groupId }, reason: `Error on group Done request, returned null` });
      } else {
        props.setNotification(Done);
        props.closeDetailsView();
      }
    }
  }

  const onPostButtonClick = () => {
    const hasSizeErrors = props.exceptions?.some((exception) => exception.code === ExceptionCode.CargoWiseFileTooBig);
    if (hasSizeErrors) {
      props.setConfirmModal({
        show: true,
        title: 'Post with file size errors?',
        text: createFileSizeModalText(props.exceptions || []),
        confirmText: 'Post',
        onConfirm: () => onPostConfirmed(true, false),
      });
    } else {
      onPostConfirmed(false, false);
    }
  };

  const createFileSizeModalText = (exceptions: Exception[]) => {
    let modalText = `
      The following files cannot be uploaded to eDocs because they are larger than your CargoWise limit for posting to eDocs.
      Continue posting to CargoWise without these files?
      Files: 
    `;
    let first = true;
    exceptions.filter((exception) => exception.code === ExceptionCode.CargoWiseFileTooBig).forEach((exception) => {
      if (exception.fileName) {
        modalText = modalText.concat(`${!first ? ',' : ''} ${exception.fileName}`);
        first = false;
      }
    });
    return modalText;
  }

  const createOnRevalidateActionClickHandler = (title: string, text: string, note: string | undefined,): () =>
    void => () => {
      props.setConfirmModal({
        show: true,
        title,
        text,
        note,
        onConfirm: () => onPostConfirmed(false, true),
        confirmText: "Revalidate",
        cancelText: "Cancel",
      });
    }

  const createOnPostingActionClickHandler = (
    title: string,
    text: string,
    note: string | undefined,
    checkboxField: 'hidePostStandaloneCINV' | 'hidePostCreateBJob' | 'hidePostUpdateBJob' | 'hidePostUpdateSJob' | 'hidePostModal',
    confirmText: string = 'Confirm'
  ): () => void => {
    return () => {
      if (userSettings.blSettings && userSettings.blSettings[checkboxField]) {
        return onPostButtonClick();
      }

      props.setConfirmModal({
        show: true,
        title,
        text,
        note,
        confirmText,
        onConfirm: onPostButtonClick,
        checkboxLabel: 'Do not show this message again',
        checkboxValue: userSettings.blSettings ? (userSettings.blSettings[checkboxField] || false) : false,
        onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          const settings = { ...userSettings };
          if (settings.blSettings) settings.blSettings[checkboxField] = event.target.checked;
          if (userSettings.update) userSettings.update(settings);
        },
      });
    }
  }

  const isClearance = props.validationType && [ValidationType.Clearance, ValidationType.GatewayClearance].includes(props.validationType);

  const postingBehaviourOptions = [];

  if (isClearance) {
    postingBehaviourOptions.push({
      id: PostingBehaviour.UpdateBJob,
      label: <>Update an existing <b>Brokerage</b></>,
      disabled: false,
      onChange: async () => {
        if (props.groupId) {
          props.setOverlay(OverlayType.Default);
          try {
            const postingBehaviour = PostingBehaviour.UpdateBJob;
            await DocumentGroupAPI.removePlaceholder(props.groupId);
            await DocumentGroupAPI.createPlaceholder(props.groupId, postingBehaviour);
            await DocumentGroupAPI.update(props.groupId, { postingBehaviour });
            if (props.groupAnyBoL && props.groupAnyBoL.billOfLading?.importerReference?.length) {
              await BillOfLadingImporterReference.update(
                props.groupAnyBoL.billOfLading?.importerReference[0].id, {
                jobRef: ''
              });
            }
            ActivityLogAPI.createLog({
              documentGroupId: props.groupId,
              type: ActivityType.ChangePostingBehaviour,
              userId,
              details: {
                oldBehaviour: props.postingBehaviour,
                newBehaviour: postingBehaviour,
              }
            });
            props.reloadDetailsForGroup(props.groupId);
          } catch (error) {
            props.setNotification({ ...APIError, details: { groupId: props.groupId }, reason: `Error on Update Bjob group post request: ${error}` });
          }
          props.setOverlay(OverlayType.Hidden);
        }
      },
      onClick: createOnPostingActionClickHandler(
        `POST INVOICES TO EXISTING BROKERAGE JOB`,
        `Posting will add the Commercial Invoice(s) data to job ${props.placeholderJobRef || 'N/A'} in your TMS.<br /> Note that once posted, the action cannot be undone from within Shipamax.`,
        'This will append data to the Job. Any existing commercial invoice data will be left unchanged.',
        'hidePostUpdateBJob'
      )
    });
    postingBehaviourOptions.push({
      id: PostingBehaviour.NewBJob,
      label: <>Create a new <b>Brokerage</b></>,
      disabled: props.disabledNewBjob,
      onChange: async () => {
        if (props.groupId) {
          props.setOverlay(OverlayType.Default);
          try {
            if (props.groupAnyBoL) {
              await DocumentGroupAPI.restoreBill(props.groupId);
            } else {
              await DocumentGroupAPI.removePlaceholder(props.groupId);
              await DocumentGroupAPI.createPlaceholder(props.groupId, PostingBehaviour.UpdateBJob);
            }
            if (props.placeholder?.billOfLading?.importerReference?.length) {
              await BillOfLadingImporterReference.update(
                props.placeholder?.billOfLading?.importerReference[0].id, {
                jobRef: ''
              });
            }
            await DocumentGroupAPI.update(props.groupId, { postingBehaviour: PostingBehaviour.NewBJob });
            ActivityLogAPI.createLog({
              documentGroupId: props.groupId,
              type: ActivityType.ChangePostingBehaviour,
              userId,
              details: {
                oldBehaviour: props.postingBehaviour,
                newBehaviour: PostingBehaviour.NewBJob,
              }
            });
            props.reloadDetailsForGroup(props.groupId);
          } catch (error) {
            props.setNotification({ ...APIError, details: { groupId: props.groupId }, reason: `Error on New Bjob group post request: ${error}` });
          }
          props.setOverlay(OverlayType.Hidden);
        }
      },
      onClick: createOnPostingActionClickHandler(
        `POST A NEW BROKERAGE JOB`,
        `Posting will create a new B-job in your TMS and include the Commercial Invoice data on that Job.<br /> Note that once posted, the action cannot be undone from within Shipamax.`,
        'If there is a Job in your TMS with a matching BL number, this Job will be updated and a new Job will not be created.',
        'hidePostCreateBJob'
      )
    });
  }

  postingBehaviourOptions.push(
    {
      id: PostingBehaviour.UpdateSJob,
      label: <>Update an existing <b>Shipment</b></>,
      disabled: false,
      onChange: async () => {
        if (props.groupId) {
          props.setOverlay(OverlayType.Default);
          try {
            const postingBehaviour = PostingBehaviour.UpdateSJob;
            await DocumentGroupAPI.removePlaceholder(props.groupId);
            await DocumentGroupAPI.createPlaceholder(props.groupId, postingBehaviour);
            await DocumentGroupAPI.update(props.groupId, { postingBehaviour });
            if (props.groupAnyBoL && props.groupAnyBoL.billOfLading?.importerReference?.length) {
              await BillOfLadingImporterReference.update(
                props.groupAnyBoL.billOfLading?.importerReference[0].id, {
                jobRef: ''
              });
            }
            ActivityLogAPI.createLog({
              documentGroupId: props.groupId,
              type: ActivityType.ChangePostingBehaviour,
              userId,
              details: {
                oldBehaviour: props.postingBehaviour,
                newBehaviour: postingBehaviour,
              }
            });
            props.reloadDetailsForGroup(props.groupId);
          } catch (error) {
            props.setNotification({ ...APIError, details: { groupId: props.groupId }, reason: `Error on Update Sjob group post request: ${error}` });
          }
          props.setOverlay(OverlayType.Hidden);
        }
      },
      onClick: createOnPostingActionClickHandler(
        `POST INVOICES TO EXISTING SHIPMENT JOB`,
        `Posting will add the Commercial Invoice(s) data to job ${props.placeholderJobRef || 'N/A'} in your TMS.<br /> Note that once posted, the action cannot be undone from within Shipamax.`,
        'This will append data to the Job. Any existing commercial invoice data will be left unchanged.',
        'hidePostUpdateSJob'
      )
    }
  );
  postingBehaviourOptions.push(
    {
      id: PostingBehaviour.StandaloneCInv,
      label: <>Create standalone Invoices</>,
      disabled: false,
      onChange: async () => {
        if (props.groupId) {
          props.setOverlay(OverlayType.Default);
          try {
            await DocumentGroupAPI.removePlaceholder(props.groupId);
            await DocumentGroupAPI.update(props.groupId, { postingBehaviour: PostingBehaviour.StandaloneCInv });
            ActivityLogAPI.createLog({
              documentGroupId: props.groupId,
              type: ActivityType.ChangePostingBehaviour,
              userId,
              details: {
                oldBehaviour: props.postingBehaviour,
                newBehaviour: PostingBehaviour.StandaloneCInv,
              }
            });
            props.reloadDetailsForGroup(props.groupId);
          } catch (error) {
            props.setNotification({ ...APIError, details: { groupId: props.groupId }, reason: `Error on Standalone CIV group post request: ${error}` });
          }
          props.setOverlay(OverlayType.Hidden);
        }
      },
      onClick: createOnPostingActionClickHandler(
        'Post as standalone Commercial Invoice(s)',
        `Posting will create standalone Commercial Invoices in your TMS. <br /> Note that once posted, the action cannot be undone from within Shipamax.`,
        undefined,
        'hidePostStandaloneCINV'
      ),
    }
  );

  return (
    <div className="status-bar-wrapper">
      <OverlayTrigger overlay={<Tooltip><b>Exit</b> Pack View</Tooltip>} placement="right" delayShow={500}>
        <button className="button-close" onClick={props.closeDetailsView}><ShipamaxBackIcon /></button>
      </OverlayTrigger>
      <div className="status-bar-container__details">
        <div className="status-bar-container__status">
          {props.status &&
            (<>
              <span className="status-bar-container__status--label">Status</span>
              <span className="status-bar-container__status--value">{statusText[props.status]}</span>
            </>)
          }
        </div>
        <div className="status-bar-container__team-dropdown">
          <WrapperLabel text="Assigned to">
            <DocumentAssignment
              teams={props.teams}
              users={props.users}
              disabled={props.isTeamDropdownDisabled}
              assignmentRule={assignmentRule}
              setAssignmentRule={onAssignmentRuleChanged}
            />
          </WrapperLabel>
        </div>
      </div>
      <div className="status-bar-container__buttons">
        <>
          {props.status && props.status === StatusCode.Unposted &&
            <button className="light-button revalidate-button" onClick={onRevalidateBillOfLading}>
              <ShipamaxClipboardIcon />Revalidate
            </button>
          }

          {props.status === StatusCode.Unposted && <button className="light-button discard-button" onClick={onDiscardCodesModal}><ShipamaxTrashIcon />Discard</button>}
          {props.status === StatusCode.Reopened &&
            <OverlayTrigger overlay={<Tooltip>Abort and set as Done</Tooltip>} placement="bottom" delayShow={500}>
              <button className="light-button light-button--with-border" onClick={onDone}>Done</button>
            </OverlayTrigger>
          }
          {(props.status && [StatusCode.Unposted, StatusCode.Reopened].includes(props.status)) && (
            <>
              {props.status === StatusCode.Unposted && props.validationType && [ValidationType.Clearance, ValidationType.GatewayClearance].includes(props.validationType) ? (
                <PostingBehaviourButton
                  options={postingBehaviourOptions}
                  selectedOptionId={props.postingBehaviour}
                  dataTestId="resubmit-button"
                />
              ) : (
                  <button
                    className="full-button post-button"
                    onClick={() => onPostBillOfLadingToCW(props.status === StatusCode.Reopened)}
                  >
                    <ShipamaxPostIcon />
                    {props.status === StatusCode.Reopened ? 'Repost' : 'Post'}
                  </button>
              )}
            </>
          )}
          {props.status === StatusCode.Posted && <button className="full-button" onClick={onDone}>Done</button>}
          {(props.status && [StatusCode.Discarded, StatusCode.Done, StatusCode.Posted].includes(props.status)) && <button className="full-button space-left" onClick={onReopen}>{props.status === StatusCode.Discarded ? 'Move to Unposted' : 'Reopen'}</button>}
        </>
      </div>
    </div>
  );
}
