
export enum DiscrepancyType {
  AccrualDiscrepancy = 1,
  ExchangeRateDiscrepancy = 2
}

export interface APIAutomatedTolerance {
  id: number;
  emailAddr: string;
  localCurrency: string | null;
  site?: string;
  xrUpperAbsoluteMax: number;
  xrLowerAbsoluteMax: number;
  xrUpperPercentageMax: number;
  xrLowerPercentageMax: number;

  accrualUpperAbsoluteMax: number;
  accrualLowerAbsoluteMax: number;
  accrualUpperPercentageMax: number;
  accrualLowerPercentageMax: number;
  discrepancyType: DiscrepancyType;

  accrualSetSeparateLimit: boolean;
  xrSetSeparateLimit: boolean;

  exchangeTolerance: number | null;
  taxTolerance: number | null;
}
