import * as React from 'react';
import { PointTooltip, Line } from '@nivo/line'
import { ColorsMapType } from '../utils';
import { AxisSettings } from '../common';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';

interface Props {
  colorsMap: ColorsMapType,
  data: any[];
  displayInPercentage: boolean;
  bottomAxisSettings: AxisSettings;
  leftAxisLegend: string;
  tooltip?: PointTooltip;
}

export const LineChart: React.FC<Props> = (props) => (
  <AutoSizer style={{ width: '100%' }}>
    {(size: Size) => (
      <Line
        height={size.height}
        width={size.width}
        data={props.data}
        margin={{ top: 10, right: 50, bottom: 70, left: 70 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: props.bottomAxisSettings.tickRotation || 0,
          legend: props.bottomAxisSettings.axisLegend,
          legendOffset: 52,
          legendPosition: 'middle',
          format: props.bottomAxisSettings.axisFormat
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.leftAxisLegend,
          legendOffset: -60,
          legendPosition: 'middle'
        }}
        enableGridX={false}
        colors={(bar) => props.colorsMap[bar.id]}
        pointColor={{ from: 'color', modifiers: [] }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        tooltip={props.tooltip}
      />
    )}
  </AutoSizer>
)
