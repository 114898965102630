import * as React from 'react';

export interface Tag {
  id: number;
  value: string;
  tagRef?: React.Ref<HTMLInputElement>;
}

interface Props {
  search: string;
  showError: boolean;
  nrOfActiveTags: number;
  tagList: Tag[];
  selectedTag: number | null;
  searchTags: Tag[];
  onClick: (tag: Tag) => void;
}

export const TagList: React.FC<Props> = (props) => {
  const onClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, tag: Tag) => {
    event.preventDefault();
    props.onClick(tag);
  }

  const tags = props.tagList.map((tag) => <li key={tag.id} className={tag.id === props.selectedTag ? 'selected' : ''} onClick={(e) => onClick(e, tag)}>{tag.value}</li>)

  let content: any;
  if (tags.length > 0) content = tags;
  else content = props.nrOfActiveTags === props.searchTags.length
    ? <li className="info">All tags are already selected</li>
    : <li className="info">No tags match the search term <strong>'{props.search}'</strong></li>;

  return (
    <ul>
      {props.showError && <><li className="error">Please select a search parameter from the dropdown and type your search term directly after it.</li><br /></>}
      {content}
    </ul>
  );
} 
