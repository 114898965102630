import * as React from 'react';
import { cn } from '../../helpers';
import { ChartWrapper } from '../ChartWrapper';
import { Notification } from '../../common/Notification';
import { APIPermissionProfile } from '../../../api/permissionProfiles';
import { SectionModule, SerieToggleState } from '../common';
import { ChartUserSettings, InsightsFilterSettings } from '../../../api/userSettings';
import { APICustomDashboardChart } from '../../../api/customDashboard';
import { NameForm } from './NameForm';
import { ShipamaxPencilIcon } from '../../../images/Icons';
import { ConfirmModal } from '../../common/ConfirmModal';
import { PermissionLevel } from '../../../api/authentication';
import { APICustomDashboard } from '../../../api/customDashboard';

interface Props {
  sectionModule: SectionModule;
  chartData: APICustomDashboardChart;
  onCancel: () => void;
  onSave: (data: Partial<APICustomDashboardChart>) => void;
  setNotification: (notification: Notification | null) => void;
  permissionProfile: APIPermissionProfile | null;
  permissionLevel: PermissionLevel;
  customDashboards: APICustomDashboard[];
  getChartToggles: (data: SerieToggleState) => void;
  chartToggles?: SerieToggleState;
}

export const ChartForm: React.FC<Props> = (props) => {
  const [chartName, setChartName] = React.useState<string>(props.chartData.name || '');
  const [isNameFormVisible, setIsNameFormVisible] = React.useState<boolean>(false);
  const [showEmptyChartNameWarning, setShowEmptyChartNameWarning] = React.useState<boolean>(false);

  const [insightsSettings, setInsightsSettings] = React.useState<InsightsFilterSettings>(props.chartData.settings?.insightsSettings);
  const [chartSettings, setChartSettings] = React.useState<ChartUserSettings>(props.chartData.settings?.chartSettings);

  const onSave = async () => {
    if (!chartName) {
      setShowEmptyChartNameWarning(true);
      return;
    }

    props.onSave({
      ...props.chartData,
      name: chartName,
      settings: {
        chartSettings,
        insightsSettings,
        userOptions: props.chartToggles
      }
    });
  }

  const onUpdateChartName = async (name: string) => {
    setChartName(name);
    setIsNameFormVisible(false);
  }

  return (
    <div className={cn(
      "absolute inset-0 w-full h-full bg-white z-[1000]",
    )}>
      <div
        className={cn(
          "flex justify-gap-between items-center h-[40px] px-[20px]",
          "border-0 border-b border-solid border-grey4",
        )}
      >
        <div>
          <h1 id="chartName" className="text-[18px] text-grey6 flex items-center">
            {chartName || 'Untitled Chart'}
            <ShipamaxPencilIcon className="ml-[10px] active-on-hover edit-chart-name" onClick={() => setIsNameFormVisible(true)} />
          </h1>
        </div>
        <div className="ml-auto">
          <button className="light-button" onClick={props.onCancel}>Cancel</button>
          <button id="saveChart" className="full-button" onClick={onSave}>Save</button>
        </div>
      </div>
      <div className="with-custom-scrollbar">
        <ChartWrapper
          setNotification={props.setNotification}
          section={props.sectionModule}
          permissionProfile={props.permissionProfile}
          permissionLevel={props.permissionLevel}
          insightsSettings={insightsSettings}
          chartSettings={chartSettings}
          updateFilterSettings={(change) => setInsightsSettings((prev) => ({ ...prev, ...change }))}
          updateChartSettings={() => { }}
          dashboardEditSettings={{
            chart: props.chartData,
            updateChartSettings: (change) => setChartSettings((prev) => ({ ...prev, ...change })),
          }}
          customDashboards={props.customDashboards}
          renderAddToButton={false}
          getChartToggles={props.getChartToggles}
          userOptions={props.chartData.settings.userOptions}
        />
      </div>

      {isNameFormVisible && (
        <NameForm
          type="chart"
          id={props.chartData.id}
          sectionModule={undefined}
          onCancel={() => setIsNameFormVisible(false)}
          onSubmit={onUpdateChartName}
        />
      )}
      {showEmptyChartNameWarning && (
        <ConfirmModal
          show={true}
          title="No Chart name"
          text="Please enter chart name before saving."
          hideCancelBtn={true}
          onConfirm={() => setShowEmptyChartNameWarning(false)}
          confirmText="Continue"
        />
      )}
    </div>
  )
}
