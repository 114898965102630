import * as React from 'react';
import { SearchPanel } from './SearchPanel';
import { Icon } from 'pivotal-ui/react/iconography';
import { APITeam } from '../../../api/teams';
import { Notification, APIError } from '../Notification';
import { Filter } from '../../../api/supplierInvoice';
import { DropdownOptions } from '../DropdownList';
import { Tag } from './TagList';
import { APIUser } from '../../../api/comment';
import { APIDocumentGroupSearchDetails } from '../../../api/documentGroup';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RadioButton } from '../RadioButton';
import { APIDocumentContainer } from '../../../api/documentContainer';
import { StatusCode } from '../../bill-of-lading/common';

import './document-list.scss';

export interface SearchFilter {
  status: number;
  sortOrder: number;
  teamId: number | null;
  userIds: number[];
  activeTags: Tag[];
}

export interface DocumentListHandle {
  searchDocumentsWithLastFilter: () => Promise<APIDocumentGroupSearchDetails[]>,
}

interface Props<T> {
  searchFilter: SearchFilter;
  setSearchFilter: (update: Partial<SearchFilter>) => void;
  sortOrderOptions: DropdownOptions;
  statusOptions: DropdownOptions;
  items: T[];
  renderItem: (item: T) => React.ReactElement;
  teams: APITeam[];
  users: APIUser[];
  listStyles?: React.CSSProperties;
  searchTags: Tag[];
  setNotification: (notification: Notification) => void;
  setResults: (results: any[]) => void;
  clearLoadedElements: () => void;
  selectFirstResult?: (invoices: any[]) => void;
  search: (filter: Filter, hasFilterChanged: boolean) => Promise<any[]>;
  periodicUpdates?: boolean;
  defaultSearchClosed?: boolean;
  allowBulkMode?: boolean;
  setIsBulkModeActive?: (value: boolean) => void;
  getBulkActions: (selectedIds: number[], closeBulkMode: () => void, isDisabled: boolean) => React.ReactElement;

  openGroupsIds: number[];
  selectedDocumentId?: number;
}

const DEFAULT_LIST_LIMIT = 35;
const AUTO_REFRESH_MSTIME = 120000;

export const DocumentList = React.forwardRef((props: Props<APIDocumentContainer>, ref: React.ForwardedRef<DocumentListHandle>) => {
  const [listOffset, setListOffset] = React.useState<number>(86);
  const [fetchingData, setFetchingData] = React.useState<boolean>(false); // used for the spinner
  const [lockedFetcher, setLockedFetcher] = React.useState<boolean>(false); // used to not request twice at same time
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [lastFilter, setLastFilter] = React.useState<Filter | null>(null);

  const [isBulkModeActive, setIsBulkModeActive] = React.useState<boolean>(false);
  const [selectedBulkGroupIdsMap, setSelectedBulkGroupIdsMap] = React.useState<{ [groupId: number]: boolean }>({});

  const isAllSelected = React.useMemo(() => !props.items.some((item) => !selectedBulkGroupIdsMap[item.groupId] && item.documentGroup.status === StatusCode.Unposted), [props.items, selectedBulkGroupIdsMap]);
  const isAtLeastOneSelected = React.useMemo(() => !!Object.keys(selectedBulkGroupIdsMap).find((key: any) => selectedBulkGroupIdsMap[key]), [selectedBulkGroupIdsMap]);

  const documentListRef = React.useRef<HTMLDivElement>(null);

  const uniqueItemIds = new Set<number>();

  React.useImperativeHandle(ref, () => ({
    async searchDocumentsWithLastFilter() {
      if (lastFilter) {
        return searchDocuments(lastFilter, false, true, false);
      } else {
        return [] as APIDocumentGroupSearchDetails[];
      }
    },
  }));

  React.useEffect(() => {
    props.setIsBulkModeActive && props.setIsBulkModeActive(isBulkModeActive);
  }, [isBulkModeActive]);

  React.useEffect(() => {
    closeBulkMode();
    updateListOffset();
  }, []);

  React.useEffect(() => {
    if (!props.allowBulkMode) {
      closeBulkMode();
    }
  }, [props.allowBulkMode]);

  React.useEffect(() => {
    setSelectedBulkGroupIdsMap({});
  }, [props.searchFilter]);

  React.useEffect(() => {
    // update search list once every 2 minutes
    if (props.periodicUpdates) {
      const interval = window.setInterval(() => {
        if (lastFilter) {
          if (lastFilter.offset) {
            lastFilter.limit = Number(lastFilter.limit) + Number(lastFilter.offset);
            delete lastFilter.offset;
          }
          searchDocuments(lastFilter, true, true, false);
        }
      }, AUTO_REFRESH_MSTIME);

      return () => window.clearInterval(interval);
    }
  }, [lastFilter]);

  const fetchData = () => {
    if (!lockedFetcher) {
      const filter: Filter = { ...lastFilter };
      filter.limit = DEFAULT_LIST_LIMIT;
      if (props.items.length) {
        filter.offset = props.items.length;
      }
      searchDocuments(filter, false, false, false);
    }
  };

  const updateListOffset = () => {
    const searchArea = document.getElementsByClassName('search-area')[0];
    if (searchArea && listOffset !== (searchArea as HTMLDivElement).offsetHeight) {
      setListOffset((searchArea as HTMLDivElement).offsetHeight);
    }
  }

  const searchDocuments = async (filter: Filter, isAutoRefresh: boolean, clearData: boolean, hasFilterChanged: boolean): Promise<APIDocumentGroupSearchDetails[]> => {
    setLockedFetcher(true);
    if (!isAutoRefresh) { // we don't want to show the spinner when autoRefreshing
      setFetchingData(true);
    }

    if (!filter.orderBy && !filter.orderDirection) {
      filter.orderBy = 'date';
      filter.orderDirection = 'desc';
    }

    if (clearData && !isAutoRefresh) props.clearLoadedElements();
    filter.limit = (Number(filter.limit) || DEFAULT_LIST_LIMIT);

    const result = await props.search(filter, hasFilterChanged);
    if (result === null) {
      props.setNotification({ ...APIError, details: { filter }, reason: `Error on search document request, returned null` });
    } else {
      if (clearData) {
        props.setResults(result);
      } else {
        props.setResults([...props.items, ...result]);
      }
      if (!isAutoRefresh && result.length > 0 && props.selectFirstResult) props.selectFirstResult(clearData ? result : [...props.items, ...result]);
    }

    setHasMore(result.length > 0);
    setFetchingData(false);
    setLockedFetcher(false);
    setLastFilter(filter);

    return result;
  }

  const onSelectAll = () => {
    setSelectedBulkGroupIdsMap(props.openGroupsIds.reduce((map, groupId) => ({
      ...map,
      [groupId]: !isAllSelected,
    }), {}));
  }

  const closeBulkMode = () => {
    setIsBulkModeActive(false);
    setSelectedBulkGroupIdsMap({});
  }

  return (
    <section className={`document-list`}>
      <SearchPanel
        searchFilter={props.searchFilter}
        setSearchFilter={props.setSearchFilter}
        hasSort={true}
        teams={props.teams}
        users={props.users}
        updateListOffset={updateListOffset}
        searchDocuments={searchDocuments}
        sortOrderOptions={props.sortOrderOptions}
        statusOptions={props.statusOptions}
        searchTags={props.searchTags}
        kind="apInv"
      />
      {props.allowBulkMode && (
        <>
          {isBulkModeActive && (
            <div className="document-list__bulk-mode__actions flex items-center bg-white pr-[22px] pl-[19px] h-[35px] justify-end">
              {props.getBulkActions(
                Object.keys(selectedBulkGroupIdsMap).filter((groupId) => selectedBulkGroupIdsMap[groupId as any]).map((groupId) => parseInt(groupId)),
                closeBulkMode,
                !isAtLeastOneSelected,
              )}
            </div>
          )}
          <div className="document-list__bulk-mode__header">
            {(isBulkModeActive && !!props.items.length) ? (
              <>
                <RadioButton
                  onChange={onSelectAll}
                  asCheckbox
                  label={isAllSelected ? 'Deselect all' : 'Select all'}
                  checked={isAllSelected}
                />
                <button className="light-button active-on-hover !mr-[14px]" onClick={closeBulkMode}>Cancel</button>
              </>
            ) : (
              <div>
                <button className="light-button active-on-hover" onClick={() => setIsBulkModeActive(true)}>Select multiple</button>
              </div>
            )}
          </div>
        </>
      )}
      <div ref={documentListRef} id="scrollable-cards" className="cards with-custom-scrollbar" style={{ height: `calc(100vh - ${listOffset}px)`, ...props.listStyles }}>
        <InfiniteScroll
          dataLength={props.items.length}
          next={fetchData}
          hasMore={hasMore}
          scrollThreshold={0.9}
          scrollableTarget="scrollable-cards"
          loader={<></>}>
          {props.items.map((item) => {
            if (uniqueItemIds.has(item.id)) {
              console.log('document already in the list ' + item.id);
              return;
            }

            uniqueItemIds.add(item.id);

            return (
              <div className={`cards__card-wrapper ${props.selectedDocumentId === item.id ? 'cards__card-wrapper--selected' : ''}`}>
                {isBulkModeActive && (
                  <RadioButton
                    onChange={() => setSelectedBulkGroupIdsMap((currentBulkGroupIdsMap) => ({ ...currentBulkGroupIdsMap, [item.groupId]: !currentBulkGroupIdsMap[item.groupId] }))}
                    asCheckbox
                    checked={selectedBulkGroupIdsMap[item.groupId] && item.documentGroup.status === StatusCode.Unposted}
                    disabled={item.documentGroup.status !== StatusCode.Unposted}
                  />
                )}
                {props.renderItem(item)}
              </div>
            )
          })}
        </InfiniteScroll>
        {fetchingData && <Icon style={{ fontSize: '48px', margin: '20px calc(50% - 24px) 30px' }} src="spinner-md" />}
      </div>
    </section>
  );
});
