import * as React from 'react';
import * as moment from 'moment';
import { TextInput } from '../../common/TextInput';
import { WrapperLabel } from '../../common/WrapperLabel';
import { OptionValue } from 'react-selectize';
import { DateSelectEditor } from '../../common/DateSelectEditor';
import { APIDocumentGroupConsol, APIDocumentGroupDocument } from '../../../api/documentGroup';
import { APIBillOfLadingImporterReference, BillOfLadingAPI } from '../../../api/billOfLading';
import { BillOfLadingImporterReference } from '../../../api/billOfLadingImporterReference';
import { sortSearchOptions } from '../../helpers';
import { FieldChangedParams, IssuerDetailMap, JobType } from '../common';
import { IssuerAPI } from '../../../api/issuer';
import { CreditorSelect } from '../../common/CreditorSelect';
import { PortSelect } from '../../common/PortSelect';
import { CustomSelect } from '../../common/CustomSelect';
import { CollapsibleContainer } from '../../common/CollapsibleContainer';
import { ContainersList } from './ContainersList';
import { PacklinesList } from './PacklinesList';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { APIDocumentContainer } from '../../../api/documentContainer';
import { APIEmailBasic } from '../../../api/email';
import { placeholderTypes } from '../file-sorter/FileSorter';
import { FAIcon } from '../../common/FAIcon';

import './consol.scss';
import { RecordValidator } from '../../common/Form';
import { OrganisationText } from './organisation-text/OrganisationText';
import { checkIfRecordValidatorHasErrors } from './ErrorHelper';

interface Props {
  data: APIDocumentGroupConsol | undefined;
  document: APIDocumentGroupDocument<APIDocumentGroupConsol> | undefined;
  isNonCargowise: boolean;
  consolTypeOptions: OptionValue[];
  transModeOptions: OptionValue[];
  containerTypeOptions: OptionValue[];
  paymentTermsOptions: OptionValue[];
  containerModeOptions: OptionValue[];
  packageTypeOptions: OptionValue[];
  releaseTypeOptions: OptionValue[];
  vesselOptions: OptionValue[];
  groupId?: number;
  reloadGroupData: (forceRefresh?: boolean) => void;
  issuerDetails: IssuerDetailMap;
  fieldsChanged: (changes: FieldChangedParams[]) => void;
  disabled: boolean;
  setActiveDocument: (document: APIDocumentContainer | APIEmailBasic | undefined, scroll?: boolean) => void;
  activeDocument?: APIDocumentContainer | APIEmailBasic | undefined;
  allowCloneAgents: boolean;
  cloneFieldValue: (senderValue: string | undefined, receiverFields: string[] | undefined, onlyInvoices: boolean, isOrganisation?: boolean) => void;
  checkRepeatedBlNumber: (docId: number, value: string | undefined) => boolean;
  addToBlNums: (docId: number, value: string) => void;
  useOrganisations: boolean;
  displayDraftAndFinal?: boolean;
}

const ratedOrUnratedOptions = [{ value: true, label: 'Rated' }, { value: false, label: 'Unrated' }];
const finalOrDraftOptions = [{ value: false, label: 'Final' }, { value: true, label: 'Draft' }];

export const Consol: React.FC<Props> = (props: Props) => {
  const [mblNo, setMblNo] = React.useState<string>('');
  const [carriersRef, setCarriersRef] = React.useState<string>('');
  const [shippingLineText, setShippingLineText] = React.useState<string>('');
  const [exportersRef, setExportersRef] = React.useState<string>('');
  const [consolType, setConsolType] = React.useState<OptionValue | null>(null);
  const [releaseType, setReleaseType] = React.useState<OptionValue | null>(null);

  const [areCoLoaderSettingsVisible, setAreCoLoaderSettingsVisible] = React.useState<boolean>(false);
  const [coLoader, setCoLoader] = React.useState<OptionValue | null>(null);
  const [coLoadMblNo, setCoLoadMblNo] = React.useState<string | null>(null);
  const [coLoaderOptions, setCoLoaderOptions] = React.useState<OptionValue[]>([]);

  const [paymentTerms, setPaymentTerms] = React.useState<OptionValue | null>(null);
  const [transMode, setTransMode] = React.useState<OptionValue | null>(null);
  const [voyage, setVoyage] = React.useState<string>('');
  const [vessel, setVessel] = React.useState<OptionValue | null>(null);
  const [containerMode, setContainerMode] = React.useState<OptionValue | null>(null);
  const [isRated, setIsRated] = React.useState<OptionValue | null>(null);
  const [isDraft, setIsDraft] = React.useState<OptionValue | null>(null);
  const [jobRef, setJobRef] = React.useState<string>('');
  const [etd, setEtd] = React.useState<string | null>();
  const [eta, setEta] = React.useState<string | null>();
  const [sob, setSob] = React.useState<string | null>();
  const [shipperName, setShipperName] = React.useState<string>('');
  const [shipperAddress, setShipperAddress] = React.useState<string>('');
  const [consigneeName, setConsigneeName] = React.useState<string>('');
  const [consigneeAddress, setConsigneeAddress] = React.useState<string>('');

  // load document group data into consol form
  React.useEffect(() => {
    const d = props.data || {} as APIDocumentGroupConsol;
    setMblNo(d.billOfLadingNo || '');
    setJobRef(d.importerReference?.find((ref) => ref.isConsol)?.jobRef || '');

    setCarriersRef(d.bookingNo || '');
    setShippingLineText(d.shippingLine || '');
    setExportersRef(d.exportReference || '');
    setVoyage(d.voyageNumber || '');
    setEtd(d.etd ? d.etd : null);
    setEta(d.eta ? d.eta : null);
    setSob(d.shippedOnBoardDate || null);

    setConsigneeName(d.consigneeName || '');
    setConsigneeAddress(d.consigneeAddress || '');
    setShipperName(d.shipperName || '');
    setShipperAddress(d.shipperAddress || '');

    setContainerMode(props.containerModeOptions.find((option) => option.value === d.containerMode) || null);
    setIsRated(ratedOrUnratedOptions.find((option) => option.value === d.isRated) || null);
    setIsDraft(finalOrDraftOptions.find((option) => option.value === d.isDraft) || null);

    let paymentTermsCode = d.paymentTerms;
    if (d.paymentTerms?.toLowerCase() === 'freight collect') paymentTermsCode = 'CCX';
    else if (d.paymentTerms?.toLowerCase() === 'freight prepaid') paymentTermsCode = 'PPD'
    setPaymentTerms(props.paymentTermsOptions.find((option) => option.value === paymentTermsCode) || null);

    if (!d.transportMode) update('transportMode', 'SEA');
    setTransMode(props.transModeOptions.find((option) => option.value === (d.transportMode || 'SEA')) || null);
    if (!d.consolType) update('consolType', 'AGT');
    setConsolType(props.consolTypeOptions.find((option) => option.value === (d.consolType || 'AGT')) || null);

    setVessel(props.vesselOptions.find((option) => option.label === d.vessel) || null);

    setReleaseType(props.releaseTypeOptions.find((options) => options.value === d.releaseType) || null);

    if (d.coLoaderCWCode) {
      setCoLoader({ label: d.coLoaderCWCode, value: d.coLoaderCWCode });
    } else {
      setCoLoader(null)
    }

    setCoLoadMblNo(d.coLoadMblNo || null);
  }, [props.data]);

  React.useEffect(() => {
    if (consolType?.value === 'CLD') {
      setAreCoLoaderSettingsVisible(true);
    } else {
      setAreCoLoaderSettingsVisible(false);

      // if consol type is changed to something else than CLD, clear the values
      if (coLoader !== null) {
        setCoLoader(null);
        update('coLoaderCWCode', null);
      }

      if (coLoadMblNo !== null) {
        setCoLoadMblNo(null);
        update('coLoadMblNo', null);
      }
    }
  }, [consolType]);

  React.useEffect(() => {
    props.addToBlNums(props.data?.documentId!, mblNo);
  }, [mblNo]);

  const recordValidator: RecordValidator = {
    blNumber: {
      validators: [
        {
          errorMessage: 'Duplicate bill of lading number',
          isValid: () => !props.checkRepeatedBlNumber(props.data?.documentId!, mblNo)
        },
      ]
    },
  };
  const recordValidatorHasErrors = checkIfRecordValidatorHasErrors(recordValidator);

  const onFilterOptionsAtLeast3Characters = (items: OptionValue[], search: string): OptionValue[] => {
    if (search.length < 3) return [];

    const filteredOptions = items.filter((option) => option.label.toUpperCase().includes(search.toUpperCase()));
    return sortSearchOptions(filteredOptions, search);
  }

  const onCoLoaderSearch = async (search: string) => {
    if (search.length < 3) return [];

    const creditors = await IssuerAPI.search(search, {}, props.data?.emailAccount?.cwCompanyCode)
    setCoLoaderOptions(creditors.map((item): OptionValue => {
      return {
        label: item.cgwCode,
        value: item.cgwCode
      };
    }));
  }

  const update = async (name: string, value: string | number | null, withoutChange?: boolean) => {
    if (withoutChange) {
      return;
    }

    if (props.data?.id) {
      if (name === 'jobRef') {
        const updatedImporterReference =
          await BillOfLadingImporterReference.updateJobRef(value, true, props.data.id, props.data?.importerReference) as APIBillOfLadingImporterReference;
        let allReferences;

        if (updatedImporterReference.id &&
          props.data?.importerReference &&
          props.data?.importerReference.length > 0) {
          allReferences = props.data.importerReference.map((ref) => {
            if (ref.id === updatedImporterReference.id) {
              return updatedImporterReference;
            } else {
              return ref;
            }
          });
        } else if (typeof updatedImporterReference == 'number' &&
          props.data.importerReference) {
          allReferences = props.data.importerReference.filter((ref) => ref.id !== updatedImporterReference);
        } else {
          allReferences = [updatedImporterReference];
        }
        props.fieldsChanged([{
          id: props.data.id,
          fieldName: name,
          jobType: JobType.Consol,
          value: allReferences,
        }]);
        setJobRef(updatedImporterReference.jobRef);
      } else {
        await BillOfLadingAPI.update(props.data?.id, { [name]: value });
        props.reloadGroupData();
      }
    }

    if (['consigneeCgwRecordId', 'shipperCgwRecordId', 'shippingLineCgwRecordId'].includes(name)) {
      props.fieldsChanged([{
        id: props.data?.documentId,
        fieldName: name,
        jobType: JobType.Consol,
        recordId: value as string | null
      }]);
    } else if (['billOfLadingNo'].includes(name)) {
      props.fieldsChanged([{
        id: props.data?.documentId,
        fieldName: name,
        jobType: JobType.Consol,
        value: value as string | null
      }]);
    }
  }

  const updateTwo = async (name: string, value: string | number | null, name2: string, value2: string | number | null) => {
    if (props.data?.id) {
      await BillOfLadingAPI.update(props.data?.id, { [name]: value, [name2]: value2 });
      props.reloadGroupData();
    }
  }

  const onFocus = () => {
    if (props.document?.documentType && !placeholderTypes.includes(props.document?.documentType)) {
      props.setActiveDocument(props.document as unknown as APIDocumentContainer)
    }
  }

  const headline = props.isNonCargowise ? 'Master Bill Of Lading' : 'Consol';

  const isActiveDocument = props.activeDocument?.id === props.document?.id;

  return (
    <CollapsibleContainer
      versionTwo
      disableScroll
      disabled={props.disabled}
      isCollapsedByDefault={isActiveDocument ? false : true}
      title={headline}
      className={`consol ${isActiveDocument ? 'selected-card-div' : 'mt-25'}`}
      id={`card-${props.data?.documentId}`}
      preHeaderJsx={
        <div className='invoice_icons'>
          {recordValidatorHasErrors && <span className='fas fa-exclamation-triangle icon-error'></span>}
        </div>
      }
      visibleContent={(
        <div className="grid__row grid__row--with-separator">
          <div className="grid__col-4">
            <WrapperLabel text={props.isNonCargowise ? 'TMS Reference' : 'Job Reference'}>
              <TextInput value={jobRef} name="jobRef" disabled={props.disabled} onBlur={(name: string, value: string | null, withoutChange) => {
                if (withoutChange) {
                  return;
                }
                update(name, value);
              }} setter={setJobRef}
                onFocus={onFocus}
                dataTestId="job-reference-input"
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-4">
            <WrapperLabel text="MBL Number">
              <TextInput value={mblNo} name="billOfLadingNo" disabled={props.disabled} onBlur={(name: string, value: string | null, withoutChange) => {
                if (withoutChange) {
                  return;
                }
                update(name, value);
              }} setter={setMblNo}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['bill_of_lading_number'],
                }}
                formEntered={true}
                validators={recordValidator.blNumber.validators}
                dataTestId="bol-number-input"
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-4">
            <WrapperLabel text="Exporters Reference">
              <TextInput
                value={exportersRef}
                name="exportReference"
                onBlur={update}
                setter={setExportersRef}
                disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['export_reference'],
                }}
                dataTestId='exporter-reference-input'
              />
            </WrapperLabel>
          </div>
        </div >
      )}
    >
      <>
        <div className="grid__row grid__row--with-separator">
          {!props.useOrganisations
            ? (<>
              <OrganisationText
                label='Sending Agent'
                fieldName='shipper'
                tableName='bol_header'
                name={shipperName}
                setName={setShipperName}
                address={shipperAddress}
                setAddress={setShipperAddress}
                update={update}
                disabled={props.disabled}
                id={[props.data?.id || -1]}
                onFocus={onFocus}
              />
              <OrganisationText
                label='Receiving Agent'
                fieldName='consignee'
                tableName='bol_header'
                name={consigneeName}
                setName={setConsigneeName}
                address={consigneeAddress}
                setAddress={setConsigneeAddress}
                update={update}
                disabled={props.disabled}
                id={[props.data?.id || -1]}
                onFocus={onFocus}
              />
            </>)
            :
            (<>
              <div className="grid__col-4">
                <CreditorSelect
                  disabled={props.disabled}
                  label="Sending Agent"
                  recordId={props.data?.shipperCgwRecordId || null}
                  onValueChange={(option) => {
                    update('shipperCgwRecordId', option?.value || null);
                  }}
                  creditorDetailsMap={props.issuerDetails}
                  flags={{ shipper: true }}
                  dataTestId="sender-dropdown"
                  showAddress={true}
                  companyCode={props.data?.emailAccount?.cwCompanyCode}
                  onFocus={onFocus}
                  cloneFieldValue={props.cloneFieldValue}
                  allowCloneAgents={props.allowCloneAgents}
                  copyOnlyToInvoices={false}
                  cloneToFields={['shipperCgwRecordId']}
                  cloneFieldLabel="Shipper"
                  fieldData={{
                    objectIds: [props.data?.id || -1],
                    tableName: 'bol_header',
                    attributeNames: ['shipper'],
                  }}
                />
              </div>
              <div className="grid__col-4">
                <CreditorSelect
                  disabled={props.disabled}
                  label="Receiving Agent"
                  recordId={props.data?.consigneeCgwRecordId || null}
                  onValueChange={(option) => {
                    update('consigneeCgwRecordId', option?.value || null);
                  }}
                  creditorDetailsMap={props.issuerDetails}
                  flags={{ consignee: true }}
                  dataTestId="receiver-dropdown"
                  showAddress={true}
                  companyCode={props.data?.emailAccount?.cwCompanyCode}
                  onFocus={onFocus}
                  cloneFieldValue={props.cloneFieldValue}
                  allowCloneAgents={props.allowCloneAgents}
                  copyOnlyToInvoices={false}
                  cloneToFields={['consigneeCgwRecordId']}
                  cloneFieldLabel="Consignee"
                  fieldData={{
                    objectIds: [props.data?.id || -1],
                    tableName: 'bol_header',
                    attributeNames: ['consignee'],
                  }}
                />
              </div>
            </>)
          }
          <div className="grid__col-4 grid__col--flow-column">
            <div className="grid__sub-row">
              {props.useOrganisations ? (
                <CreditorSelect
                  label="Carrier Code"
                  recordId={props.data?.shippingLineCgwRecordId || null}
                  onValueChange={(option) => {
                    update('shippingLineCgwRecordId', option?.value || null);
                  }}
                  creditorDetailsMap={props.issuerDetails}
                  flags={{ carrier: true }}
                  dataTestId="carrier-dropdown"
                  disabled={props.disabled}
                  companyCode={props.data?.emailAccount?.cwCompanyCode}
                  onFocus={onFocus}
                  fieldData={{
                    objectIds: [props.data?.id || -1],
                    tableName: 'bol_header',
                    attributeNames: ['shipping_line'],
                  }}
                />
              ) : (
                <WrapperLabel text="Carrier">
                  <TextInput
                    value={shippingLineText}
                    name="shippingLine"
                    onBlur={update}
                    setter={setShippingLineText}
                    disabled={props.disabled}
                    onFocus={onFocus}
                    fieldData={{
                      objectIds: [props.data?.id || -1],
                      tableName: 'bol_header',
                      attributeNames: ['shipping_line'],
                    }}
                  />
                </WrapperLabel>
              )}
            </div>
            <div className="grid__sub-row">
              <WrapperLabel text="Booking Number">
                <TextInput
                  value={carriersRef}
                  name="bookingNo"
                  onBlur={update}
                  setter={setCarriersRef}
                  maxLength={20}
                  disabled={props.disabled}
                  onFocus={onFocus}
                  fieldData={{
                    objectIds: [props.data?.id || -1],
                    tableName: 'bol_header',
                    attributeNames: ['booking_number'],
                  }}
                  dataTestId="carrier-ref-input"
                />
              </WrapperLabel>
            </div>
            {props.displayDraftAndFinal && (
              <div className="grid__sub-row grid__col-12">
                <WrapperLabel text="Final / Draft">
                  <CustomSelect
                    options={finalOrDraftOptions}
                    value={isDraft || undefined}
                    onValueChange={(value) => {
                      setIsDraft(value);
                      update('isDraft', value?.value || false);
                    }}
                    disabled={props.disabled}
                    onFocus={onFocus}
                  />
                </WrapperLabel>
                <WrapperLabel text="Rated / Unrated">
                  <CustomSelect
                    dropdownMenuRightAlignment
                    options={ratedOrUnratedOptions}
                    value={isRated || undefined}
                    onValueChange={(value) => {
                      setIsRated(value);
                      update('isRated', value?.value || false);
                    }}
                    disabled={props.disabled}
                    onFocus={onFocus}
                  />
                </WrapperLabel>
              </div>
            )}
          </div>
        </div>
        <div className="grid__row">
          <div className="grid__col-4">
            <WrapperLabel text="Voyage">
              <TextInput value={voyage} name="voyageNumber" onBlur={update} setter={setVoyage} maxLength={10} disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['voyage'],
                }}
                dataTestId='voyage-input'
              />
            </WrapperLabel>
            <WrapperLabel text="Vessel">
              <CustomSelect
                options={props.vesselOptions}
                value={vessel || undefined}
                renderNoResultsFound={(item: OptionValue, search: string) => <div className="dropdown-placeholder">Please enter the first 3 characters of the code...</div>}
                filterOptions={onFilterOptionsAtLeast3Characters}
                onValueChange={(value) => {
                  setVessel(value);
                  updateTwo('vessel', value?.label || null, 'vesselIMO', value?.value || null);
                }}
                disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['vessel'],
                }}
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-4">
            <WrapperLabel text="Release Type">
              <CustomSelect
                options={props.releaseTypeOptions}
                value={releaseType || undefined}
                onValueChange={(value) => {
                  setReleaseType(value);
                  update('releaseType', value?.value || null);
                }}
                disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['release_type'],
                }}
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-4">

            <WrapperLabel text="SOB Date"
              copyComponent={
                (props.cloneFieldValue && (
                  <div className="clone-agents-on-wrapper">
                    <OverlayTrigger overlay={<Tooltip><span>Set as SOB in all shipments</span></Tooltip>} delayShow={500} placement="left">
                      <button
                        className="light-button clone-agents-button active-on-hover"
                        disabled={props.disabled || !sob}
                        onClick={() => props.cloneFieldValue ? props.cloneFieldValue(sob || undefined, ['shippedOnBoardDate'], false, false) : {}}
                      ><FAIcon name="clone" /></button>
                    </OverlayTrigger>
                  </div>)
                )
              }>
              <DateSelectEditor
                initDate={props.data?.shippedOnBoardDate && moment(props.data.shippedOnBoardDate)}
                onDateChange={(value: moment.Moment | null) => {
                  update('shippedOnBoardDate', value ? value.format('YYYY-MM-DD') : null);
                  setSob(value ? value.format('YYYY-MM-DD') : null);
                }}
                disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['shipped_on_board_date'],
                }}
                dataTestId="sob-date-input"
              />
            </WrapperLabel>
            <WrapperLabel text="Trans Mode">
              <CustomSelect
                disabled={props.disabled}
                options={props.transModeOptions}
                value={transMode || undefined}
                onValueChange={(value) => {
                  setTransMode(value);
                  update('transportMode', value?.value || null);
                }}
                onFocus={onFocus}
                dataTestId="transport-mode-input"
              />
            </WrapperLabel>
          </div>
        </div>
        <div className="grid__row">
          <div className="grid__col-4">
            <PortSelect
              label="Load Port"
              value={props.data?.loadPort}
              valueUnlocode={props.data?.loadPortUnlocode}
              onValueChange={(value, valueUnlocode) => {
                updateTwo('loadPort', value, 'loadPortUnlocode', valueUnlocode);
              }}
              onValueUnlocodeChange={(valueUnlocode) => {
                update('loadPortUnlocode', valueUnlocode);
              }}
              onlySeaPort={true}
              dataTestId="load-port"
              disabled={props.disabled}
              onFocus={onFocus}
              cloneFieldValue={props.cloneFieldValue}
              cloneToField1={'origin'}
              cloneToField2={'originUnlocode'}
              cloneFieldLabel="Origin"
              fieldData={{
                objectIds: [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['load_port'],
              }}
            />
          </div>
          <div className="grid__col-4">
            <PortSelect
              label={'Discharge Port'}
              value={props.data?.dischargePort}
              valueUnlocode={props.data?.dischargePortUnlocode}
              onValueChange={(value, valueUnlocode) => {
                updateTwo('dischargePort', value, 'dischargePortUnlocode', valueUnlocode);
              }}
              onValueUnlocodeChange={(valueUnlocode) => {
                update('dischargePortUnlocode', valueUnlocode);
              }}
              onlySeaPort={true}
              dataTestId="disch-port"
              disabled={props.disabled}
              onFocus={onFocus}
              cloneFieldValue={props.cloneFieldValue}
              cloneToField1={'destination'}
              cloneToField2={'destinationUnlocode'}
              cloneFieldLabel="Destination"
              fieldData={{
                objectIds: [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['discharge_port'],
              }}
            />
          </div>
          <div className="grid__col-4">
            <WrapperLabel text="Loading Date"
              copyComponent={
                (props.cloneFieldValue && (
                  <div className="clone-agents-on-wrapper">
                    <OverlayTrigger overlay={<Tooltip><span>Set as ETD in all shipments</span></Tooltip>} delayShow={500} placement="left">
                      <button
                        className="light-button clone-agents-button active-on-hover"
                        disabled={props.disabled || !etd}
                        onClick={() => {
                          props.cloneFieldValue(etd || undefined, ['originEtd'], false, false);
                          props.cloneFieldValue(etd || undefined, ['etd'], false, false);
                        }}
                      ><FAIcon name="clone" /></button>
                    </OverlayTrigger>
                  </div>)
                )
              }>
              <DateSelectEditor
                initDate={props.data?.etd && moment(props.data.etd)}
                onDateChange={(value: moment.Moment | null) => {
                  update('etd', value ? value.format('YYYY-MM-DD') : null);
                  setEtd(value ? value.format('YYYY-MM-DD') : null);
                }}
                disabled={props.disabled}
                onFocus={onFocus}
                dataTestId="etd-input"
              />
            </WrapperLabel>
            <WrapperLabel text="Discharge Date"
              copyComponent={
                (props.cloneFieldValue && (
                  <div className="clone-agents-on-wrapper">
                    <OverlayTrigger overlay={<Tooltip><span>Set as ETA in all shipments</span></Tooltip>} delayShow={500} placement="left">
                      <button
                        className="light-button clone-agents-button active-on-hover"
                        disabled={props.disabled || !eta}
                        onClick={() => {
                          props.cloneFieldValue(eta || undefined, ['destinationEta'], false, false);
                          props.cloneFieldValue(eta || undefined, ['eta'], false, false);
                        }}
                      ><FAIcon name="clone" /></button>
                    </OverlayTrigger>
                  </div>)
                )
              }>
              <DateSelectEditor
                initDate={props.data?.eta && moment(props.data.eta)}
                onDateChange={(value: moment.Moment | null) => {
                  update('eta', value ? value.format('YYYY-MM-DD') : null);
                  setEta(value ? value.format('YYYY-MM-DD') : null);
                }}
                disabled={props.disabled}
                onFocus={onFocus}
                dataTestId="eta-input"
              />
            </WrapperLabel>
          </div >
        </div >
        <div className="grid__row grid__row--with-separator">
          <div className="grid__col-4">
            <WrapperLabel text="Container Mode">
              <CustomSelect
                options={props.containerModeOptions}
                value={containerMode || undefined}
                onValueChange={(value) => {
                  setContainerMode(value);
                  update('containerMode', value?.value || null);
                }}
                renderValue={(item: OptionValue) => item.value}
                className="dropdown-wide"
                disabled={props.disabled}
                onFocus={onFocus}
                dataTestId="container-mode-input"
              />
            </WrapperLabel>
            <WrapperLabel text="Payment Terms">
              <CustomSelect
                options={props.paymentTermsOptions}
                value={paymentTerms || undefined}
                onValueChange={(value) => {
                  setPaymentTerms(value);
                  update('paymentTerms', value?.value || null);
                }}
                renderValue={(item: OptionValue) => item.value}
                disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['payment_terms'],
                }}
                dataTestId="payment-terms-input"
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-4">
            <WrapperLabel text={props.isNonCargowise ? 'Shipment Type' : 'Consol Type'}>
              <CustomSelect
                disabled={props.disabled}
                options={props.consolTypeOptions}
                value={consolType || undefined}
                onValueChange={async (value) => {
                  await update('consolType', value?.value || null);

                  if ([value?.value, consolType?.value].includes('DRT')) {
                    setConsolType(value);
                    props.reloadGroupData(true);
                  } else {
                    setConsolType(value);
                  }
                }}
                dropdownMenuRightAlignment
                renderValue={(item: OptionValue) => item.value}
                onFocus={onFocus}
                dataTestId="consol-type-input"
              />
            </WrapperLabel>
            {areCoLoaderSettingsVisible ? (
              <WrapperLabel text="Co-loader">
                <CustomSelect
                  options={coLoaderOptions}
                  value={coLoader || undefined}
                  renderNoResultsFound={(item: OptionValue, search: string) => <div className="dropdown-placeholder">Please enter the first 3 characters of the code...</div>}
                  onSearchChange={onCoLoaderSearch}
                  onValueChange={(value) => {
                    setCoLoader(value);
                    update('coLoaderCWCode', value?.label || null);
                  }}
                  createFromSearch={(options, search) => {
                    if (search.length < 3 || options.find((option) => option.label.toUpperCase() === search.toUpperCase())) {
                      // returning as any because react-selectizes simple select type definition is wrong :/
                      return null as any;
                    }
                    else {
                      return { label: search.toUpperCase(), value: search.toUpperCase() };
                    }
                  }}
                  className="co-loader-select"
                  disabled={props.disabled}
                  onFocus={onFocus}
                />
              </WrapperLabel>
            ) : (
              <WrapperLabel text=""></WrapperLabel>
            )}
          </div>
          <div className="grid__col-4">
            {areCoLoaderSettingsVisible && (
              <>
                <WrapperLabel text="Co-load MBL No.">
                  <TextInput value={coLoadMblNo} name="coLoadMblNo" disabled={props.disabled} onBlur={(name: string, value: string | null, withoutChange) => {
                    update(name, value?.toUpperCase() || null, withoutChange);
                  }} setter={(value) => setCoLoadMblNo(value.toUpperCase())} onFocus={onFocus} />
                </WrapperLabel>
                <WrapperLabel text="">
                </WrapperLabel>
              </>
            )}
          </div>
        </div>
        <div className="grid__row">
          <ContainersList
            containers={props.data?.container}
            reloadGroupData={props.reloadGroupData}
            disabled={props.disabled}
            containerTypeOptions={props.containerTypeOptions}
            billOfLadingId={props.data?.id}
            onFocus={onFocus}
          />
        </div>
        {
          props.isNonCargowise && (
            <div className="grid__row">
              <PacklinesList
                packLines={props.data?.packLines}
                reloadGroupData={props.reloadGroupData}
                disabled={props.disabled}
                packageTypeOptions={props.packageTypeOptions}
                billOfLadingId={props.data?.id}
                onFocus={onFocus}
              />
            </div>
          )
        }
      </>
    </CollapsibleContainer >
  );
};
