import { request } from './request';

export interface APIVessel {
  name: string;
  imo: number;
}

export const VesselAPI = {
  async fetchAll(): Promise<APIVessel[]> {
    return request(`/Vessels`);
  }
}
