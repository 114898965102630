import * as React from 'react';
import * as moment from 'moment';
import { TextInput } from '../../common/TextInput';
import { WrapperLabel } from '../../common/WrapperLabel';
import { OptionValue } from 'react-selectize';
import { DateSelectEditor } from '../../common/DateSelectEditor';
import { APIDocumentGroupCInvoice, APIDocumentGroupDocument } from '../../../api/documentGroup';
import { CommercialInvoiceAPI } from '../../../api/commercialInvoice';
import { LineItems } from './LineItems';
import { NumberInput } from '../../common/NumberInput';
import { FieldChangedParams, IssuerDetailMap, JobType, PostingBehaviour } from '../common';
import { CreditorSelect } from '../../common/CreditorSelect';
import { Exception } from '../../../api/validationResults';
import { sortSearchOptions, parseNumber } from '../../helpers';
import { CustomSelect } from '../../common/CustomSelect';
import { Icon } from 'pivotal-ui/react/iconography';
import { InvoiceTotalDifference } from '../../common/InvoiceTotalDifference';
import { ConfirmModalProps } from '../../common/ConfirmModal';
import { APIDocumentContainer, DocumentType } from '../../../api/documentContainer';
import { APIEmailBasic } from '../../../api/email';
import { placeholderTypes } from '../file-sorter/FileSorter';
import { OrganisationText } from './organisation-text/OrganisationText';
import { RecordValidator } from '../../common/Form';
import { CollapsibleContainer } from '../../common/CollapsibleContainer';
import { Notification } from '../../common/Notification';

import './invoice.scss';
import { APIError } from '../../common/Notification';
import { lineItemsHaveErrorsAndWarnings, checkIfRecordValidatorHasErrors, commercialInvoiceLineItemsErrors } from './ErrorHelper';
import { ShipamaxExclamationIcon } from '../../../images/Icons';

interface Props {
  className?: string;
  data: APIDocumentGroupCInvoice;
  document: APIDocumentGroupDocument<APIDocumentGroupCInvoice> | undefined;
  countryOptions: OptionValue[];
  currencyOptions: OptionValue[];
  incoTermOptions: OptionValue[];
  unitTypeOptions: OptionValue[];
  status?: number;
  groupId?: number;
  issuerDetails: IssuerDetailMap;
  fieldsChanged: (changes: FieldChangedParams[]) => void;
  exceptions?: Exception[];
  loading?: boolean;
  reloadGroupData: () => void;
  disabled: boolean;
  allowCloneAgents?: boolean;
  cloneFieldValue?: (senderRecordId: string | undefined, receiverFields: string[] | undefined, onlyInvoices: boolean, isOrganisation?: boolean, onlySameShipmentId?: number) => void;
  setConfirmModal: (props: ConfirmModalProps) => void;
  setActiveDocument: (document: APIDocumentContainer | APIEmailBasic | undefined, scroll?: boolean) => void;
  activeDocument?: APIDocumentContainer | APIEmailBasic | undefined;
  postingBehaviour?: PostingBehaviour;
  shipmentId?: number;
  checkRepeatedCinvNumber: (docId: number, value: string | undefined) => boolean;
  addToCinvNums: (docId: number, value: string) => void;
  isNonCargowise?: boolean;
  removeCollapsibleToggles?: boolean
  useOrganisations: boolean;
  setNotification: (notification: Notification) => void;
  enableTableCoordinates?: boolean;
}

export const Invoice: React.FC<Props> = (props: Props) => {
  const [supplierRecordId, setSupplierRecordId] = React.useState<string | null>(null);
  const [importerRecordId, setImporterRecordId] = React.useState<string | null>(null);
  const [invoiceNumber, setInvoiceNumber] = React.useState<string>('');
  const [supplierName, setSupplierName] = React.useState<string>('');
  const [supplierAddress, setSupplierAddress] = React.useState<string>('');
  const [importerName, setImporterName] = React.useState<string>('');
  const [importerAddress, setImporterAddress] = React.useState<string>('');
  const [invoiceDate, setInvoiceDate] = React.useState<moment.Moment | null>(null);
  const [incoTerm, setIncoTerm] = React.useState<OptionValue | null>(null);
  const [currency, setCurrency] = React.useState<OptionValue | null>(null);
  const [grossTotal, setGrossTotal] = React.useState<number | null>(null);
  const [loading, setLoading] = React.useState<boolean | null>(null);
  const [lineItemsTotal, setLineItemsTotal] = React.useState<number>(0);
  const [lineItemsAggregated, setLineItemsAggregated] = React.useState<boolean>(false);

  const supplierCgwCode = React.useMemo(() => {
    return props.issuerDetails[supplierRecordId || '']?.cgwCode || null;
  }, [props.issuerDetails, supplierRecordId]);

  const importerCgwCode = React.useMemo(() => {
    return props.issuerDetails[importerRecordId || '']?.cgwCode || null;
  }, [props.issuerDetails, importerRecordId]);

  // load document group data into invoice form
  React.useEffect(() => {

    const d = props.data;

    setImporterRecordId(d.importerRecordId);
    setSupplierRecordId(d.supplierRecordId);
    setInvoiceNumber(d.invoiceNumber || '');
    setSupplierName(d.supplierName || '');
    setSupplierAddress(d.supplierAddress || '');
    setImporterName(d.importerName || '');
    setImporterAddress(d.importerAddress || '');
    setInvoiceDate(d.invoiceDate ? moment(d.invoiceDate) : null);
    setIncoTerm(props.incoTermOptions.find((option) => option.value === d.incoTerm) || null);
    setGrossTotal(d.grossTotal);
    setCurrency({ value: d.currency, label: d.currency });
    setLineItemsTotal(d.lineItems.reduce((sum, line) => {
      return sum + parseNumber(line.lineTotal || 0);
    }, 0));
    setLineItemsAggregated(d.areLineItemsAggregated);
  }, [props.data]);

  React.useEffect(() => {
    if (props.data.runningLineItemsMatcher) checkMatchingRunningAfterLoading(false);
  }, [props.data.runningLineItemsMatcher]);

  React.useEffect(() => {
    checkMatchingRunningAfterLoading(true);
  }, []);

  React.useEffect(() => {
    props.addToCinvNums(props.data?.documentId!, invoiceNumber);
  }, [invoiceNumber]);

  const recordValidator: RecordValidator = {
    cinvNumber: {
      validators: [
        {
          errorMessage: 'Required field',
          isValid: () => !!invoiceNumber
        },
        {
          errorMessage: 'Duplicate invoice number',
          isValid: () => !props.checkRepeatedCinvNumber(props.data.documentId, invoiceNumber)
        },
      ]
    },
    supplierCW: {
      validators: [
        {
          errorMessage: 'Required field',
          isValid: () => !!supplierRecordId || !props.useOrganisations
        },
      ]
    },
    importerCW: {
      validators: [
        {
          errorMessage: 'Required field',
          isValid: () => !!importerRecordId || !props.useOrganisations
        },
      ]
    },
    grossTotal: {
      validators: [
        {
          errorMessage: 'Required field',
          isValid: () => !!grossTotal
        },
      ]
    },
  };
  const recordValidatorHasErrors = checkIfRecordValidatorHasErrors(recordValidator);

  const waitForMatchingToComplete = async (invoiceId: number, resolve: (value?: unknown) => void, isFirstRender: boolean) => {
    const invoice = await CommercialInvoiceAPI.fetch(invoiceId);

    if (invoice) {
      if (!invoice.runningLineItemsMatcher) {
        if (!isFirstRender) {
          props.reloadGroupData();
          setLoading(false);
        }
        return resolve();
      } else {
        setLoading(true);
        window.setTimeout(() => waitForMatchingToComplete(invoiceId, resolve, false), 2000);
      }
    } else {
      props.setNotification({ ...APIError, details: { invoiceId }, reason: `Error waiting for matching on CI, request returned null` });
      return resolve();
    }
  }

  const checkMatchingRunningAfterLoading = async (isFirstRender: boolean) => {
    await new Promise((resolve) => waitForMatchingToComplete(props.data.id, resolve, isFirstRender));
  }

  const onFilterOptions = (items: OptionValue[], search: string): OptionValue[] => {
    const filteredOptions = items.filter((option) => option.label.toUpperCase().includes(search.toUpperCase()));
    return sortSearchOptions(filteredOptions, search);
  }

  const update = async (name: string, value: string | number | null, withoutChange?: boolean) => {
    if (withoutChange) {
      return;
    }

    await CommercialInvoiceAPI.update(props.data.id, { [name]: value });
    if (name === 'supplierRecordId' || name === 'importerRecordId') {
      await new Promise((resolve) => waitForMatchingToComplete(props.data.id, resolve, false));
    }

    if (['supplierRecordId', 'importerRecordId'].includes(name)) {
      props.fieldsChanged([{
        id: props.data.documentId,
        fieldName: name,
        jobType: JobType.Invoice,
        recordId: value as string | null
      }]);
    } else if (['invoiceNumber'].includes(name)) {
      props.fieldsChanged([{
        id: props.data?.documentId,
        fieldName: name,
        jobType: JobType.Consol,
        value: value as string | null
      }]);
    }
  }

  const onFocus = () => {
    if (props.document?.documentType && !placeholderTypes.includes(props.document?.documentType)) {
      props.setActiveDocument(props.document as unknown as APIDocumentContainer)
    }
  }

  const isActiveDocument = props.activeDocument?.id === props.document?.id;
  const isInvoiceCollapsed = (PostingBehaviour.GenericTMS === props.postingBehaviour && !isActiveDocument);
  const { hasWarnings, hasErrors } = lineItemsHaveErrorsAndWarnings(props.document?.commercialInvoice?.lineItems, props.isNonCargowise);

  return (
    <CollapsibleContainer
      versionTwo
      disableScroll
      disabled={props.disabled}
      isCollapsedByDefault={isInvoiceCollapsed}
      disableToggle={props.removeCollapsibleToggles}
      title="Commercial Invoice"
      className={`invoice ${isActiveDocument ? 'selected-card-div' : 'mt-25'} ${props.className}`}
      id={`card-${props.data?.documentId}`}
      preHeaderJsx={
        <div className='invoice_icons'>
          {(hasErrors || recordValidatorHasErrors) && <span className='icon-error'><ShipamaxExclamationIcon /></span>}
          {hasWarnings && <span className='icon-warning'><ShipamaxExclamationIcon /></span>}
        </div>
      }
      visibleContent={(
        <div className={`grid__row ${!(props.removeCollapsibleToggles) ? 'grid__row--with-separator' : 'mb-22'}`}>
          <div className="grid__col-4">
            <WrapperLabel text="INVOICE NUMBER">
              <TextInput
                value={invoiceNumber}
                name="invoiceNumber"
                onBlur={update}
                setter={setInvoiceNumber}
                disabled={props.disabled}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'commercial_invoice',
                  attributeNames: ['invoice_number'],
                }}
                onFocus={onFocus}
                formEntered={true}
                validators={recordValidator.cinvNumber.validators}
                dataTestId={'invoice_number_input'}
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-4">
            <WrapperLabel text="Invoice Date">
              <DateSelectEditor initDate={invoiceDate} disabled={props.disabled} onDateChange={(value: moment.Moment | null) => {
                setInvoiceDate(value);
                update('invoiceDate', value ? value.format('YYYY-MM-DD') : null);
              }}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'commercial_invoice',
                  attributeNames: ['invoice_date'],
                }}
                onFocus={onFocus} />
            </WrapperLabel>
            <WrapperLabel text=""></WrapperLabel>
          </div>
          <div className="grid__col-4"></div>
        </div>
      )}
    >
      {loading &&
        <div className="overlay active">
          <Icon style={{ fontSize: '40px', margin: '60px calc(50% - 20px)' }} src="spinner-md" />
          <div className="overlay--text">
            <div>Matching product codes with selected organisations.</div>
            <div>This may take a few moments...</div>
          </div>
        </div>
      }
      <div>
        <div className="grid__row">
          {!props.useOrganisations
            ? (<>
              <OrganisationText
                label='supplier'
                fieldName='supplier'
                tableName='commercial_invoice'
                name={supplierName}
                setName={setSupplierName}
                address={supplierAddress}
                setAddress={setSupplierAddress}
                update={update}
                disabled={props.disabled}
                id={[props.data?.id || -1]}
                onFocus={onFocus}
              />
              <OrganisationText
                label='importer'
                fieldName='importer'
                tableName='commercial_invoice'
                name={importerName}
                setName={setImporterName}
                address={importerAddress}
                setAddress={setImporterAddress}
                update={update}
                disabled={props.disabled}
                id={[props.data?.id || -1]}
                onFocus={onFocus}
              />
            </>)
            : (<>
              <div className="grid__col-4">
                <CreditorSelect
                  label="Supplier"
                  recordId={supplierRecordId}
                  onValueChange={(option) => {
                    setSupplierRecordId(option?.value);
                    update('supplierRecordId', option?.value || null);
                  }}
                  creditorDetailsMap={props.issuerDetails}
                  showAddress={true}
                  disabled={props.disabled}
                  fieldData={{
                    objectIds: [props.data?.id || -1],
                    tableName: 'commercial_invoice',
                    attributeNames: ['supplier'],
                  }}
                  companyCode={props.data?.emailAccount?.cwCompanyCode}
                  dataTestId="supplier-input"
                  onFocus={onFocus}
                  cloneFieldValue={props.cloneFieldValue}
                  allowCloneAgents={props.allowCloneAgents}
                  copyOnlyToInvoices={true}
                  cloneToFields={['supplierRecordId']}
                  postingBehaviour={props.postingBehaviour}
                  shipmentId={props.shipmentId}
                  formEntered={true}
                  validators={recordValidator.supplierCW.validators}
                />
              </div>
              <div className="grid__col-4">
                <CreditorSelect
                  label="Importer"
                  recordId={importerRecordId}
                  onValueChange={(option) => {
                    setImporterRecordId(option?.value);
                    update('importerRecordId', option?.value || null);
                  }}
                  creditorDetailsMap={props.issuerDetails}
                  showAddress={true}
                  disabled={props.disabled}
                  fieldData={{
                    objectIds: [props.data?.id || -1],
                    tableName: 'commercial_invoice',
                    attributeNames: ['importer'],
                  }}
                  companyCode={props.data?.emailAccount?.cwCompanyCode}
                  dataTestId="importer-input"
                  onFocus={onFocus}
                  cloneFieldValue={props.cloneFieldValue}
                  allowCloneAgents={props.allowCloneAgents}
                  copyOnlyToInvoices={true}
                  cloneToFields={['importerRecordId']}
                  postingBehaviour={props.postingBehaviour}
                  shipmentId={props.shipmentId}
                  formEntered={true}
                  validators={recordValidator.importerCW.validators}
                />
              </div>
            </>)
          }
          <div className="grid__col-4">
            <WrapperLabel text="Incoterm">
              <CustomSelect
                options={props.incoTermOptions}
                value={incoTerm || undefined}
                filterOptions={onFilterOptions}
                onValueChange={(value) => {
                  setIncoTerm(value);
                  update('incoTerm', value?.value || false);
                }}
                dropdownMenuSize={'wide'}
                dropdownMenuRightAlignment
                renderValue={(item: OptionValue) => item.value}
                disabled={props.disabled}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'commercial_invoice',
                  attributeNames: ['incoterm_text']
                }}
                onFocus={onFocus}
              />
            </WrapperLabel>
            <WrapperLabel text=""></WrapperLabel>
          </div>
        </div>
      </div>
      <LineItems
        isAggregated={lineItemsAggregated}
        countryOptions={props.countryOptions}
        unitTypeOptions={props.unitTypeOptions}
        lineItemRows={props.data?.lineItems}
        disabled={props.disabled}
        parentId={props.data?.id!}
        parentDocumentType={DocumentType.CommercialInvoice}
        lineItemsTotalChanged={setLineItemsTotal}
        supplierCgwCode={supplierCgwCode}
        importerCgwCode={importerCgwCode}
        setConfirmModal={props.setConfirmModal}
        onFocus={onFocus}
        useOrganisations={props.useOrganisations}
        supplierName={supplierName}
        importerName={importerName}
        isNonCargowise={props.isNonCargowise}
        enableTableCoordinates={props.enableTableCoordinates}
      />
      <div className="total-wrapper grid__row">
        <div className='grid__col-8'></div>
        <div className='grid__col-4 grid__col--flow-column'>
          <InvoiceTotalDifference
            isCommercialInvoice={true}
            failingToolTip={'Lines Total does not match Gross Total'}
            calculatedTotal={lineItemsTotal}
            invoiceTotal={grossTotal || 0}
            totalVat={0}
            label={'LINE TOTAL SUM'}
            isResubmitDisabled={false}
            setIsResubmitDisabled={() => { }}
          />
          <div className='grid__col-12'>
            <WrapperLabel text="Currency">
              <CustomSelect
                options={props.currencyOptions}
                value={currency || undefined}
                filterOptions={onFilterOptions}
                onValueChange={async (option) => {
                  setCurrency(option);
                  await update('currency', option?.label || null);
                }}
                disabled={props.disabled}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'commercial_invoice',
                  attributeNames: ['currency'],
                }}
                onFocus={onFocus}
              />
            </WrapperLabel>
            <WrapperLabel text="INV GROSS TOTAL">
              <NumberInput
                value={grossTotal}
                name="grossTotal"
                setter={setGrossTotal}
                onBlur={update}
                precision={2}
                disabled={props.disabled}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'commercial_invoice',
                  attributeNames: ['gross_total'],
                }}
                formEntered={true}
                validators={recordValidator.grossTotal.validators}
              />
            </WrapperLabel>
          </div>
        </div>
      </div>
    </CollapsibleContainer>
  );
};
