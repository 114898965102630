import * as React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { SectionModule } from './common';
import { Explore } from './Explore';
import { Notification } from '../common/Notification';
import { TopNavigation } from '../common/TopNavigation';
import { EnterpriseDashboard } from './EnterpriseDashboard';
import { DropdownList } from '../common/DropdownList';
import { PermissionLevel } from '../../api/authentication';
import { UserSettingsContext } from '../main/Main';
import { defaultInsightsFilterSettings } from '../../api/userSettings';
import { APIPermissionProfile } from '../../api/permissionProfiles';
import { CustomDashboard } from './custom-dashboards';
import { CustomDashboardAPI } from '../../api/customDashboard';

import './insights-section.scss';

interface Props {
  setNotification: (notification: Notification | null) => void;
  permissionLevel: PermissionLevel;
  permissionProfile: APIPermissionProfile | null;
}

const sectionOptions = [
  { label: 'AP Reconciliation', value: SectionModule.APInvoice },
  { label: 'Forwarding & Clearance', value: SectionModule.ForwardAndClearance },
];


export const InsightsSection: React.FC<Props> = (props) => {
  const [section, setSection] = React.useState<SectionModule>(SectionModule.APInvoice);
  const [customDashboards, setCustomDashboards] = React.useState<any[]>([]);

  const userSettings = React.useContext(UserSettingsContext);
  const history = useHistory();

  React.useEffect(() => {
    fetchCustomDashboards();
  }, [section]);

  React.useEffect(() => {
    if (userSettings.insightsSettings?.defaultSection) {
      setSection(userSettings.insightsSettings.defaultSection);
    }
  }, []);

  const fetchCustomDashboards = () => {
    CustomDashboardAPI.fetchAll(section).then((customDashboards) => {
      setCustomDashboards(customDashboards);
    });
  }

  const changeSection = (section: SectionModule) => {
    setSection(section);
    history.push('/apInsights/explore');

    userSettings.update && userSettings.update({
      ...userSettings,
      insightsSettings: {
        ...defaultInsightsFilterSettings[section],
        ...userSettings.insightsSettings,
        ...{ defaultSection: section }
      }
    });
  }

  const links = {
    [SectionModule.APInvoice]: [
      {
        path: '/apInsights',
        label: 'Enterprise dashboard',
        component: () => (
          <EnterpriseDashboard permissionLevel={props.permissionLevel} />
        )
      },
      {
        path: '/apInsights/explore',
        label: 'Explore',
        component: () => (
          <Explore
            setNotification={props.setNotification}
            section={SectionModule.APInvoice}
            permissionProfile={props.permissionProfile}
            permissionLevel={props.permissionLevel}
            customDashboards={customDashboards}
          />
        )
      },
    ],
    [SectionModule.ForwardAndClearance]: [
      {
        path: '/apInsights/explore',
        label: 'Explore',
        component: () => (
          <Explore
            setNotification={props.setNotification}
            section={SectionModule.ForwardAndClearance}
            permissionProfile={props.permissionProfile}
            permissionLevel={props.permissionLevel}
            customDashboards={customDashboards}
          />
        )
      },
    ],
  };

  return (
    <section className="insights-container">
      <div className="insight-section-toggle">
        <div className="select-section">
          <DropdownList
            items={sectionOptions}
            selectedValue={section}
            onChange={(value) => changeSection(Number(value))}
            buttonClassName="dropdown-button active-on-hover"
            preventDefault
          />
        </div>
        <TopNavigation
          links={links[section]}
          permissionLevel={props.permissionLevel}
          customDashboards={customDashboards}
        />
        <a
          href="/knowledge-base/What-are-Insights---770248734328453185f89756cc38ec4f"
          className="light-button"
          target="__blank"
        >What are Insights?</a>
      </div>
      <div className="chart-container with-custom-scrollbar">
        <Switch>
          {links[section].map((link, index) => (
            <Route path={link.path} exact render={link.component} key={index} />
          ))}
          <Route path="/apInsights/custom-dashboards" render={() => (
            <CustomDashboard
              sectionModule={section}
              setNotification={props.setNotification}
              permissionProfile={props.permissionProfile}
              permissionLevel={props.permissionLevel}
              refetchCustomDashboards={fetchCustomDashboards}
              customDashboards={customDashboards}
            />
          )} />
        </Switch>
      </div>
    </section>
  )
}
