import { request, getLoginPage } from './request';
import intercom from '../intercom/intercom';

export enum PermissionLevel {
  Standard = 1,
  Admin = 3
}

let intercomIntervalId: number;

export const initialiseIntercom = () => {
  if (localStorage.intercomEnabled.toLowerCase() === 'true') {
    intercom('boot', {
      user_id: 'freight-' + localStorage.userId, // eslint-disable-line camelcase
      email: localStorage.email || localStorage.username,
      name: `${localStorage.firstName} ${localStorage.lastName}`,
      company: {
        company_id: 'freight-' + localStorage.companyId,
        name: localStorage.companyName
      },
      application: 'Exception Management Interface'
    });
    intercom('update');

    intercomIntervalId = setInterval(() => {
      intercom('update');
    }, 600000) as unknown as number;
  }
}

export const AuthAPI = {
  login(parameters: { email: string, password: string }): Promise<any> {
    const params = new URLSearchParams();
    params.set('include', 'user');
    return request(`/users/login?${params.toString()}`, 'POST', parameters, {}, true);
  },

  fetchRelatedUserToken(companyId: number): Promise<any> {
    const params = new URLSearchParams();
    params.set('companyId', String(companyId));
    return request(`/users/related-user-token?${params.toString()}`, 'GET');
  },

  fetchMe(): Promise<any> {
    return request(`/users/me?filter=${JSON.stringify({
      include: [
        'company',
        { permissionProfile: 'toleranceThresholds' },
        'parentUser',
      ],
    })}`);
  },

  async logout() {
    await request('/users/logout', 'POST')

    localStorage.clear();
    clearInterval(intercomIntervalId);
    intercom('shutdown');
    window.location.href = getLoginPage();
  },

  requestPasswordReset(email: string, isReset: boolean) {
    request('/users/request-set-password', 'POST', { email, isReset }, {}, true);
  },

  changePassword(user: { password: string, verificationToken: string }): Promise<any> {
    return request(`/users/reset-password`, 'POST', { newPassword: user.password }, {}, true, undefined, user.verificationToken);
  }
}
