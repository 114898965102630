import * as React from 'react';

import './standalone-container.scss';

interface Props {
  title: string | React.ReactNode;
  headerContent?: React.ReactNode;
  className?: string;
}

export const StandaloneContainer: React.FC<Props> = (props) => (
  <div className={`standalone-container ${props.className}`}>
    <div className="standalone-container__header">
      <div className="standalone-container__header--title">{props.title}</div>
      {props.headerContent}
    </div>
    <div className="standalone-container__body">
      {props.children}
    </div>
  </div>
);
