import * as React from 'react';
import { Refiner } from 'chrono-node';
import { OptionValue } from 'react-selectize';
import * as H from 'history';
import { Exception, ExceptionCode } from '../api/validationResults';
import { JobRef, JobRefType } from './ap-invoice/document-action/Clusters';
import { APIJobRef } from '../api/supplierInvoiceJobRef';
import { useEffect, useRef } from 'react';
import { APIDocumentGroupCInvoice, APIDocumentGroupConsol, APIDocumentGroupDocument, APIDocumentGroupPackingList, APIDocumentGroupShipment } from '../api/documentGroup';
import { APIDocumentContainer, DocumentType } from '../api/documentContainer';
import { AuthAPI } from '../api/authentication';
import { AppSection } from './nav-bar/NavBar';
import { UserSettingsAPI } from '../api/userSettings';
import html2canvas from 'html2canvas';

/**
 * WI00823022 - This function has been updated after we started to have issues for users using non-english locale
 * In multiple cases in this code this function is used with numbers on default format even though the OS/browser uses a distinct one
 */
function stringToNumber(value: string): number {
  // Remove spaces
  value = value.replace(/\s/g, '');
  if (value.includes(',') && value.includes('.')) {
    // If both , and . are present, assume comma is thousand separator and dot is decimal separator
    value = value.replace(/\./g, '').replace(',', '.');
  } else if (value.includes(',')) {
    // If only comma is present, assume it's the decimal separator
    value = value.replace(',', '.');
  }
  return parseFloat(value);
}

/**
 * Parse a human-readable number, including thousand separators or spaces, in a
 * locale-aware fashion
 */
export function parseNumber(value: string | number): number {
  if (typeof value === 'number') {
    return value;
  }
  return stringToNumber(value);
}

export const roundNumber = (value: number, fractionDigits: number): number => {
  const base = Math.pow(10, fractionDigits);
  return Math.round((value + Number.EPSILON) * base) / base;
}

const clone = (obj: any, incl = {}) => {
  return Object.setPrototypeOf({ ...obj, ...incl }, Object.getPrototypeOf(obj));
};

/**
 * Chrono Refiner Helpers Beginning
 */

export namespace DateParser {
  export interface Components {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    second: number;
    millisecond: number;
  }

  export interface Refiner {
    refine: (text: string, results: DateParser.ResultWrapper[]) => any[];
  }

  export interface Chrono {
    refiners: Refiner[];
    parse: (text: string, refDate: Date | null, opt: object) => any[];
  }

  export interface Result {
    knownValues: Partial<Components>;
    impliedValues: Partial<Components>;
    date: () => Date;
  }

  export interface ResultWrapper {
    ref: Date;
    index: number;
    text: string;
    tags: { [tag: string]: true };
    start: Result;
  }
}

const suggest = (base: any, opts: Partial<DateParser.Result>) => {
  const { knownValues, impliedValues } = opts;
  const known = clone(base.start.knownValues, knownValues);
  const implicit = clone(base.start.impliedValues, impliedValues);
  const start = clone(base.start);
  const copy = clone(base);

  copy.start = start;
  copy.start.knownValues = known;
  copy.start.impliedValues = implicit;

  return copy;
};

export const refineYear = new Refiner();
export const refineMonth = new Refiner();

refineMonth.refine = function (text: string, results: DateParser.ResultWrapper[]) {
  /**
   * We want to filter out cases where it parses the month as 0
   * (e.g. 05/0) and reads it as december previous year.
   */
  const suggestions: any = [];
  const filtered = results.filter((parsed) => {
    const { month } = parsed.start.knownValues;

    if (month === 0) return false;
    else return true;
  });

  return [...filtered, ...suggestions];
};

refineYear.refine = function (text: string, results: DateParser.ResultWrapper[]) {
  /**
   * Sometimes chrono will gives us back years that don't
   * make sense for our use case.
   *
   * Some of these we can filter out entirely (e.g. 400AD),
   * others we can make more sensible suggestions out of
   * (e.g. 201 -> [2018, 2019]).
   */
  const current = (new Date()).getFullYear();
  const max = current + 5;
  const min = 1970;
  const pivot = current % 10;
  const suggestions: any = [];
  const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  const filtered = results.filter((parsed) => {
    const { year } = parsed.start.knownValues;

    const notSet = !year;
    const inRange = year ? year > min && year < max : false;
    const inDecade = year === Math.floor(current / 10);
    const inDecadeRange = year ? year > (min / 10) && year < (max / 10) : false;
    const higherDecade = year ? (year * 10) > current : false;
    const hasReasonableYear = text.match(/(?:2[0-2][0-9]{2}|19[7-9][0-9])/);

    switch (true) {
      case notSet && !hasReasonableYear:
        return true;
      case notSet && Boolean(hasReasonableYear):
        if (hasReasonableYear) {
          suggestions.push(suggest(parsed, { impliedValues: { year: Number(hasReasonableYear[0]) } }));
          suggestions.push(parsed); // put the original below in the dropdown
        }
        return false;
      case inRange:
        return true;
      case inDecade:
        if (year) suggestions.push(...digits.slice(pivot, pivot + 3).map((digit) => {
          return suggest(parsed, { knownValues: { year: (year * 10) + digit } });
        }));
        return false;
      case inDecadeRange:
        if (year) suggestions.push(
          ...digits.slice(
            higherDecade ? 0 : -2,
            higherDecade ? 2 : undefined
          ).map((digit) => {
            return suggest(parsed, { knownValues: { year: (year * 10) + digit } });
          })
        );
        return false;
      default:
        return false;
    }
  });

  return [...filtered, ...suggestions];
};

/**
 * Chrono Refiner Helpers End
 */

export enum FocusDirection {
  UP,
  DOWN
}

export const createRecordId = (creditorCwMapId: number, creditorAliasId: number | undefined, creditorAddressId: number | undefined): string => {
  if (!creditorAliasId || !creditorAddressId) {
    return creditorCwMapId + '';
  }

  return `${creditorCwMapId}-${creditorAliasId}-${creditorAddressId}`;
}

const getIdFromRecordId = (recordId: string | null, idIndex: number): number | undefined => {
  if (!recordId) {
    return;
  }

  const ids = recordId.split('-');

  if (ids.length > idIndex) {
    return parseInt(ids[idIndex]);
  }
}

export const getCreditorCWMapId = (recordId: string | null): number | undefined => {
  return getIdFromRecordId(recordId, 0);
}

export const getCreditorAliasId = (recordId: string | null): number | undefined => {
  return getIdFromRecordId(recordId, 1);
}

export const getCreditorAddressId = (recordId: string | null): number | undefined => {
  return getIdFromRecordId(recordId, 2);
}

export const sortSearchOptions = (options: OptionValue[], search: string): OptionValue[] => {
  // sort the options so that the ones starting with the search keyword will appear first before the others
  const optionsSorted: OptionValue[] = [];
  options.forEach((option) => {
    if (option.label.toUpperCase().startsWith(search.toUpperCase())) optionsSorted.push(option);
  });
  options.forEach((option) => {
    if (!option.label.toUpperCase().startsWith(search.toUpperCase())) optionsSorted.push(option);
  });

  return optionsSorted
}

export const prioritizeOptions = (options: OptionValue[], prioritizedValue: (number | string)): OptionValue[] => {
  return [...options].sort((option1, option2) => {
    if (prioritizedValue === option1.value) {
      return -1;
    }

    if (prioritizedValue === option2.value) {
      return 1;
    }

    return option1.label.localeCompare(option2.label);
  })
}

// This returns the array of exceptions, but keeps only the first instance of any codes provided
export const filterCodes = (exceptions: Exception[], codes: ExceptionCode[]): Exception[] => {
  const filteredExceptions: Exception[] = [];

  exceptions.forEach((exception) => {
    if (codes.includes(exception.code)) {
      let found = false;

      filteredExceptions.forEach(filteredException => {
        if (exception.code === filteredException.code) {
          found = true;
        }
      });
      if (!found) filteredExceptions.push(exception);
    } else {
      filteredExceptions.push(exception);
    }
  });

  return filteredExceptions;
}

export const filterEDocWarnings = (exceptions: Exception[]): Exception[] => {
  if (exceptions.some((exception) => exception.code === ExceptionCode.CargowiseEDocPostingError)) {
    const filteredExceptions: Exception[] = [];
    let exceptionText = `Posted successfully, but the following files could not be saved to eDocs:`;
    let found = false;
    exceptions.forEach((exception) => {
      if (exception.code === ExceptionCode.CargowiseEDocPostingError) {
        if (!found) filteredExceptions.push(exception);
        if (exception.fileName) exceptionText += `${!found ? '' : ','} ${exception.fileName}`;
        found = true;
      } else {
        filteredExceptions.push(exception);
      }
    });

    filteredExceptions.forEach((exception) => {
      if (exception.code === ExceptionCode.CargowiseEDocPostingError) exception.description = exceptionText;
    });

    return filteredExceptions;
  }
  return exceptions;
};

export const filterExceptions = (exceptions: Exception[]): Exception[] => {
  let filteredExceptions = filterCodes(
    exceptions, [ExceptionCode.CommercialInvoiceProductCodeNotAssociated, ExceptionCode.CommercialInvoiceProductCodeNotFound]);

  filteredExceptions = filterCodes(filteredExceptions, [ExceptionCode.CargoWiseFileTooBig]);

  return filteredExceptions;
}

export const getJobRefValue = (jobRef: JobRef): string | null => {
  switch (jobRef.type) {
    case JobRefType.BL:
      return jobRef.bolNum;
    case JobRefType.CN:
      return jobRef.containerNum;
    case JobRefType.PO:
      return jobRef.purchaseOrder;
  }

  return jobRef.jobRef;
}

export const getJobRefType = (jobRef: APIJobRef): JobRefType => {
  if (jobRef.bolNum) {
    return JobRefType.BL;
  }

  if (jobRef.containerNum) {
    return JobRefType.CN;
  }

  if (jobRef.purchaseOrder) {
    return JobRefType.PO;
  }

  return JobRefType.Ref;
}

export const getJobRefAttributeName = (jobRef: JobRef): string => {
  if (jobRef.bolNum) {
    return 'bol_num';
  }

  if (jobRef.containerNum) {
    return 'container_num';
  }

  if (jobRef.purchaseOrder) {
    return 'purchase_order';
  }

  return 'job_ref';
}

export const useKeyPress = (key: string, action: () => void, dependencies: any[] = []) => {
  useEffect(() => {
    function onKeyup(e: KeyboardEvent) {
      if (e.key === key) {
        e.stopPropagation();
        e.preventDefault();
        action();
      }
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, dependencies);
}

export const useInterval = (callback: () => void, delay: number, watchedProps: any[]) => {
  const savedCallback = useRef<() => void>();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback, ...watchedProps]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback && savedCallback.current && savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export interface SearchProperty {
  key: PropertyKey,
  values: any[],
}

export const findInChildren = (children: any[], searchProperty: SearchProperty): any[] => {
  children = Array.isArray(children) ? children : [children];
  const found: any[] = [];
  const ids: number[] = [];
  for (let child of children) {
    if (child) {
      if (new Object(child).hasOwnProperty(searchProperty.key)) {
        if (searchProperty.values.includes((child as any)[searchProperty.key])) {
          if (!ids.includes(child.id)) {
            found.push(child as any);
            ids.push(child.id);
          }
        }
      }

      if (child.children) {
        const [result] = findInChildren(child.children, searchProperty);
        if (result && result.length) {
          result.forEach((r: any) => {
            if (!ids.includes(r.id)) found.push(r as any);
          })
        }
      }
    }
  }
  return found;
}

export const removeInChildren = (children: any[], searchProperty: SearchProperty) => {
  children = Array.isArray(children) ? children : [children];
  children.forEach((child, i) => {
    if (child) {
      if (new Object(child).hasOwnProperty(searchProperty.key)) {
        if (searchProperty.values.includes((child as any)[searchProperty.key])) {
          delete children[i];
        }
      }

      if (children[i]?.children) {
        removeInChildren(children[i].children, searchProperty);
      }
    }
  });
}


export const placeInChildren = (children: any[], searchProperty: SearchProperty, toPlace: APIDocumentContainer) => {
  toPlace.children = [];
  children = Array.isArray(children) ? children : [children];
  children.forEach((child, i) => {
    if (child) {
      if (new Object(child).hasOwnProperty(searchProperty.key)) {
        if (searchProperty.values.includes((child as any)[searchProperty.key])) {
          if (children[i]?.children) {
            children[i].children.push(toPlace);
          } else {
            children[i].children = [toPlace];
          }
        }
      }
    }
  });
}


export const findConsolInChildren = (documents: any[], multipleMbls?: boolean): APIDocumentGroupDocument<APIDocumentGroupConsol> | APIDocumentGroupDocument<APIDocumentGroupConsol>[] => {
  const mbls = findInChildren(documents, { key: 'documentType', values: [DocumentType.MasterBillOfLading] });
  return multipleMbls ? mbls : mbls[0];
}

export const findShipmentsInChildren = (documents: any[]): APIDocumentGroupDocument<APIDocumentGroupShipment>[] => {
  return findInChildren(documents, { key: 'documentType', values: [DocumentType.HouseBillOfLading, DocumentType.PlaceholderShipment] });
}

export const findInvoicesInChildren = (documents: any[]): APIDocumentGroupDocument<APIDocumentGroupCInvoice>[] => {
  return findInChildren(documents, { key: 'documentType', values: [DocumentType.CommercialInvoice] }).filter((d) => !!d.commercialInvoice);
}

export const findPackingListsInChildren = (documents: any[]): APIDocumentGroupDocument<APIDocumentGroupPackingList>[] => {
  return findInChildren(documents, { key: 'documentType', values: [DocumentType.PackingList] }).filter((d) => !!d.packingList);;
}

// export class Map2<P, T> extends Map<P, T> {
//   public keysArray(): P[] {
//     return Array.from(this.keys());
//   }
// }

export class Map2<P, T> {
  private map: Map<P, T>;

  public get size(): number {
    return this.map.size;
  }

  constructor(values: [P, T][]) {
    this.map = new Map(values);
  }

  public keysArray(): P[] {
    return Array.from(this.map.keys());
  }

  public keys(): IterableIterator<P> {
    return this.map.keys();
  }

  public get(key: P): T | undefined {
    return this.map.get(key);
  }

  public set(key: P, value: T) {
    return this.map.set(key, value);
  }
}

export const hasOverlap = <T>(array1: T[], array2: T[]): boolean => {
  let found = false;
  array1.forEach((item1) => {
    if (array2.includes(item1)) {
      found = true;
    }
  });

  return found;
}

export const login = async (onLoginRedirect: boolean | undefined, redirectTo?: string | null) => {
  let me = await AuthAPI.fetchMe();
  localStorage.setItem('userId', me.id);

  let user = await UserSettingsAPI.fetch();

  const settings = user?.userSettings?.settings
  const lastLoggedInCompanyId = settings?.lastLoggedInCompanyId;

  if (lastLoggedInCompanyId) {
    const relatedUserTokenData = await AuthAPI.fetchRelatedUserToken(lastLoggedInCompanyId);

    if (relatedUserTokenData?.token) {
      localStorage.setItem('token', relatedUserTokenData.token);
      me = await AuthAPI.fetchMe();
      localStorage.setItem('userId', me.id);
    }
  }

  if (!me) {
    return false;
  }

  localStorage.setItem('firstName', me.firstName);
  localStorage.setItem('lastName', me.lastName);
  localStorage.setItem('companyId', me.companyId);
  localStorage.setItem('companyName', me.company?.name);
  localStorage.setItem('username', me.username);
  localStorage.setItem('email', me.email);
  localStorage.setItem('teamId', me.teamId);
  localStorage.setItem('permissionLevel', me.permissionLevel);
  localStorage.setItem('permissionProfileId', me.permissionProfileId);
  localStorage.setItem('enableTableCoordinates', me.enableTableCoordinates);
  localStorage.setItem('intercomEnabled', me.company?.intercomEnabled);
  localStorage.setItem('parentUserId', me.parentUserId);
  if (me.parentUser?.email) localStorage.setItem('parentUserEmail', me.parentUser.email);

  if (onLoginRedirect) {
    if (!redirectTo) {
      const urlParams = new URLSearchParams(window.location.search);
      redirectTo = urlParams.get('redirectTo');
    }
    const whiteListedURL = Object.values(AppSection).find((path) => redirectTo?.toLowerCase().startsWith(`/${path}`));
    window.location.href = redirectTo && whiteListedURL ? redirectTo : '/';
  }

  return true;
}

export const shouldDoHardRefresh = (): boolean => {
  const currentAppVersion = localStorage.getItem('em-version-current');
  const latestAppVersion = localStorage.getItem('em-version-latest');

  return currentAppVersion !== latestAppVersion;
}

export const navigateToUrl = (url: string, history: H.History) => {
  const currentAppVersion = localStorage.getItem('em-version-current');
  const latestAppVersion = localStorage.getItem('em-version-latest') || '';

  if (currentAppVersion !== latestAppVersion) {
    localStorage.setItem('em-version-current', latestAppVersion);
    window.location.href = addSearchParamToUrl(url, `timestamp=${Date.now().toString()}`);
  } else {
    history.push(url);
  }
}

export const addSearchParamToUrl = (url: string, param: string): string => {
  return url.includes('?') ? `${url}&${param}` : `${url}?${param}`;
}

export const cn = (...classNames: string[]): string => classNames.reduce((result, className) => `${result} ${className}`, '');

export const useScreenshot = (params: { type?: string, quality?: number } = {}): [
  string,
  (node: HTMLElement | null) => Promise<{ dataUrl: string, width: number, height: number } | void>, any
] => {
  const [image, setImage] = React.useState<string>('');
  const [error, setError] = React.useState(null);

  const takeScreenShot = (node: HTMLElement | null) => {
    if (!node) {
      throw new Error('You should provide correct html node.')
    }
    return html2canvas(node)
      .then((canvas) => {

        const croppedCanvas = document.createElement('canvas');
        const croppedCanvasContext = croppedCanvas.getContext('2d');

        if (!croppedCanvasContext) throw new Error('Could not get canvas context');

        // init data
        const cropPositionTop = 0
        const cropPositionLeft = 0
        const width = canvas.width
        const height = canvas.height

        croppedCanvas.width = width
        croppedCanvas.height = height

        croppedCanvasContext.drawImage(
          canvas,
          cropPositionLeft,
          cropPositionTop,
        )

        const dataUrl = croppedCanvas.toDataURL(params.type, params.quality)

        setImage(dataUrl);
        return {
          dataUrl,
          width,
          height,
        };
      })
      .catch((e) => {
        setError(e);
        console.log(e);
      });
  }

  return [
    image,
    takeScreenShot,
    { error },
  ];
}
