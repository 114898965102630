import * as React from 'react';
import { AdminHeader } from '../../AdminHeader';
import { AdminGrid, AdminGridSearchParams, AdminGridTagType, AdminGridTagsList } from '../../AdminGrid';
import { AdminLinks } from '../../Admin';
import { Column } from 'react-data-grid';
import { APIWebhook, WebhookAPI } from '../../../../api/webhook';

import './index.scss';
import { Link } from 'react-router-dom';

export const WebhookTable: React.FC = () => {
  const fetchWebhooks = (params: AdminGridSearchParams) => {
    return WebhookAPI.getWebhooks({
      ...(params?.searchTerm ? { endpoint: { ilike: `%${params.searchTerm.toLowerCase()}%` } } : {})
    });
  }

  const columns: Column<APIWebhook>[] = [
    {
      key: 'endpoint', name: 'Webhook URL', formatter: (tableProps: any) => tableProps.row.endpoint
    },
    {
      key: 'environment', name: 'Workspace', formatter: (tableProps: any) => {
        switch (tableProps.row.environment) {
          case 1:
            return 'Production';
          case 2:
            return 'Test';
          default:
            return 'N/A;'
        }
      }
    },
    {
      key: 'actions', name: 'Actions', formatter: (tableProps) => {
        return (
          <Link to={`${AdminLinks.Webhooks}/form/${tableProps.row.id}`}><button className="light-button active-on-hover">Edit</button></Link>
        );
      },
    },
  ];

  return (
    <div>
      <AdminHeader
        headline="Webhooks"
        tagText="Global"
        links={[
          {
            url: '/knowledge-base/Webhooks--c1f3e045b3ed4be9a334f210f235ebad',
            label: 'What are webhooks?'
          },
        ]}
      />
      <AdminGrid
        columns={columns}
        fetchData={fetchWebhooks}
        link={{
          url: AdminLinks.Webhooks + '/form',
          label: 'Add webhook'
        }}
      />
    </div>
  )
};
