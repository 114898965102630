import { AssignmentRule } from '../../api/userSettings';
import { SearchFilter } from '../common/document-list';
import { APIPermissionProfileToleranceThreshold, TOLERANCE_WITHOUT_THRESHOLD } from '../../api/permissionProfiles';
import { APIExchangeRate } from '../../api/supplierInvoice';
import { roundNumber } from '../helpers';
import { DiscrepancyType } from '../../api/automatedTolerances';

const checkNewTeamMatchesSelectedTeam = (currentSearchFilter: SearchFilter, newTeamId: number | null): boolean => {
  return currentSearchFilter.teamId === null || newTeamId === currentSearchFilter.teamId;
}

const checkNewUserMatchesSelectedUser = (currentSearchFilter: SearchFilter, newTeamId: number | null): boolean => {
  return !!newTeamId && (!currentSearchFilter.userIds.length || currentSearchFilter.userIds.includes(newTeamId));
}

export const checkDocumentMatchesFilter = (currentSearchFilter: SearchFilter, newAssignmentRule: AssignmentRule): boolean => {
  const userId = newAssignmentRule.userIds.length ? newAssignmentRule.userIds[0] : null;

  if (userId) {
    const newUserMatchesSelectedUser = checkNewUserMatchesSelectedUser(currentSearchFilter, userId) && checkNewTeamMatchesSelectedTeam(currentSearchFilter, newAssignmentRule.teamId || -1);
    if (!newUserMatchesSelectedUser) return false;
  } else if (newAssignmentRule.teamId) {
    const newTeamMatchesSelectedTeam = checkNewTeamMatchesSelectedTeam(currentSearchFilter, newAssignmentRule.teamId) && !currentSearchFilter.userIds.length;
    if (!newTeamMatchesSelectedTeam) return false;
  }

  return true;
}

export function getToleranceThresholdFromCurrencyAndType(
  tolerances: APIPermissionProfileToleranceThreshold[] | undefined,
  invoiceCurrency: string | undefined,
  localCurrency: string | undefined,
  exchangeRates: APIExchangeRate[],
): APIPermissionProfileToleranceThreshold | undefined {
  const invoiceInLocalCurrency = invoiceCurrency === localCurrency;
  const tolerance = tolerances?.find((t) => t.currency === localCurrency);

  if (invoiceInLocalCurrency) return tolerance;

  const exchangeRate = exchangeRates.find((rate) => [rate.sourceCurrency, rate.targetCurrency].includes(localCurrency || 'INVALID'));

  if (!exchangeRate || !tolerance) return undefined;

  return {
    ...tolerance,
    upperAbsoluteMax: tolerance.upperAbsoluteMax === TOLERANCE_WITHOUT_THRESHOLD ? TOLERANCE_WITHOUT_THRESHOLD :
      roundNumber(exchangeRate.sourceCurrency === localCurrency ? tolerance.upperAbsoluteMax * exchangeRate.rate : tolerance.upperAbsoluteMax / exchangeRate.rate, 2),
    lowerAbsoluteMax: tolerance.lowerAbsoluteMax === TOLERANCE_WITHOUT_THRESHOLD ? TOLERANCE_WITHOUT_THRESHOLD :
      roundNumber(exchangeRate.sourceCurrency === localCurrency ? tolerance.lowerAbsoluteMax * exchangeRate.rate : tolerance.lowerAbsoluteMax / exchangeRate.rate, 2),
  };
}
