import * as React from 'react'

interface Props {
  to: string;
}

export const AbsoluteRedirect: React.FC<Props> = (props) => {

  React.useEffect(() => {
    window.location.href = props.to;
  }, []);

  return null;

}
