import { request } from './request';

export interface APIEmailBasic {
  id: number;
  subject: string;
  unqId: string;
}

export const EmailAPI = {
  async fetchEmailFile(unqId: string): Promise<any> {
    return request(`/Emails/getEmailFile?unqId=${encodeURIComponent(unqId)}`);
  },
}
