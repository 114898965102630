import * as React from 'react';
import { APITeam } from '../../api/teams';
import { Notification, APIError, Merged, EventNotificationRequest } from '../common/Notification';
import { Filter } from '../../api/supplierInvoice';
import { DetailView } from './DetailView';
import { DocumentGroupAPI, APIDocumentGroupDetails, APIDocumentGroupSearchDetails } from '../../api/documentGroup';
import { Icon } from 'pivotal-ui/react/iconography';
import { IssuerAPI } from '../../api/issuer';
import { VesselAPI } from '../../api/vessel';
import { OptionValue } from 'react-selectize';
import { APICwReferenceData, CompanyAPI } from '../../api/company';
import { UserSettingsContext } from '../main/Main';
import { ActivityTracker } from '../monitoring/user-activity';
import { CurrencyAPI } from '../../api/currency';
import { DocumentContainerAPI, APIDocumentType, APIDocumentContainer, DocumentType } from '../../api/documentContainer';
import { ShipamaxCheckIcon, ShipamaxCogsIcon, ShipamaxFolderIcon, ShipamaxFolderOpenIcon, ShipamaxHourGlassIcon, ShipamaxPostIcon, ShipamaxSortDownIcon, ShipamaxSortUpIcon, ShipamaxSplitIcon, ShipamaxTrashIcon } from '../../images/Icons';
import { AppSection } from '../nav-bar/NavBar';
import { APIUser, TeamsMap, UsersMap } from '../../api/comment';
import { useInterval, useKeyPress } from '../helpers';
import { findTopFile } from './file-sorter/FileSorter';
import { FieldChangedParams, groupsSearchTags, IssuerDetailMap, JobType, PostingBehaviour, Status, StatusCode, StatusText, wtgIntegrationSearchTags } from './common';
import { SearchPanel } from '../common/document-list/SearchPanel';
import { GroupDataGrid } from './group-section/GroupDataGrid';
import { SearchFilter } from '../common/document-list';
import { useHistory } from 'react-router-dom';

import './group-section.scss';
import { Tag } from '../common/document-list/TagList';
import { ValidationType } from '../../api/emailAccount';

const statusOptions = [
  { value: Status.All, label: 'All packs', icon: <ShipamaxFolderIcon /> },
  { value: Status.Unposted, label: StatusText[Status.Unposted], icon: <ShipamaxFolderOpenIcon /> },
  { value: Status.Reopened, label: StatusText[Status.Reopened], icon: <ShipamaxSplitIcon /> },
  { value: Status.Posted, label: StatusText[Status.Posted], icon: <ShipamaxPostIcon /> },
  { value: Status.Processing, label: StatusText[Status.Processing], icon: <ShipamaxCogsIcon /> },
  { value: Status.PendingResponse, label: StatusText[Status.PendingResponse], icon: <ShipamaxHourGlassIcon /> },
  { value: Status.Discarded, label: StatusText[Status.Discarded], icon: <ShipamaxTrashIcon /> },
  { value: Status.Done, label: StatusText[Status.Done], icon: <ShipamaxCheckIcon /> },
];

const sortOrderOptions = [
  { value: 1, label: 'Newest', icon: <ShipamaxSortUpIcon /> },
  { value: 2, label: 'Oldest', icon: <ShipamaxSortDownIcon /> },
];

export enum GroupSectionMode {
  General = 0,
  WtgIntegration = 1,
}

export enum OverlayType {
  Hidden = 0,
  Default = 1,
  CloseMessage = 2,
}

interface Props {
  mode: GroupSectionMode;
  teams: APITeam[];
  users: APIUser[];
  countryOptions: OptionValue[];
  selectedGroupId?: number;
  enableTableCoordinates?: boolean;
  setNotification: (notification: Notification | null) => void;
  showNotAuthorizedPage: () => void;
  addEventsToListen: (event: EventNotificationRequest) => void;
  removeGroupNotifications: (groupId: number) => void;
}

const DEFAULT_LIST_LIMIT = 35;
const AUTO_REFRESH_MSTIME = 120000;

export const GroupSection = React.forwardRef((props: Props, ref: any) => {
  const userSettings = React.useContext(UserSettingsContext);
  const [searchFilter, setSearchFilter] = React.useState<SearchFilter>({
    status: userSettings.blSettings?.searchStatus || Number(statusOptions[1].value),
    sortOrder: userSettings.blSettings?.searchOrder || Number(sortOrderOptions[0].value),
    teamId: userSettings.blSettings?.searchTeam || null,
    userIds: userSettings.blSettings?.searchUsers || [],
    activeTags: [],
  });

  const [billsOfLading, setBillsOfLading] = React.useState<APIDocumentGroupSearchDetails[]>([]);
  const [mergeMode, setMergeMode] = React.useState<boolean>(false);
  const [showDetailsView, setShowDetailsView] = React.useState<boolean>(false);
  const [activeGroupDetails, setActiveGroupDetails] = React.useState<APIDocumentGroupDetails | null>(null);
  const [fileSorterData, setFileSorterData] = React.useState<APIDocumentGroupSearchDetails | null>(null);
  const [selectedGroups, setSelectedGroups] = React.useState<number[]>([]);
  const [issuerDetails, setIssuerDetails] = React.useState<IssuerDetailMap>({});
  const [cwReferenceData, setCwReferenceData] = React.useState<APICwReferenceData | null>(null);
  const [vesselOptions, setVesselOptions] = React.useState<OptionValue[]>([]);
  const [currencyOptions, setCurrencyOptions] = React.useState<OptionValue[]>([]);
  const [overlay, setOverlay] = React.useState<OverlayType>(OverlayType.Hidden);
  const [documentTypes, setDocumentTypes] = React.useState<APIDocumentType[]>([]);
  const [listOffset, setListOffset] = React.useState<number>(86);
  const [fetchingData, setFetchingData] = React.useState<boolean>(false); // used for the spinner
  const [lockedFetcher, setLockedFetcher] = React.useState<boolean>(false); // used to not request twice at same time
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [lastFilter, setLastFilter] = React.useState<Filter | null>(null);
  const [searchTags, setSearchTags] = React.useState<Tag[]>([]);

  const documentListRef = React.useRef<HTMLDivElement>(null);

  const usersMap: UsersMap = React.useMemo(() => props.users.reduce((map, user) => { map.set(user.id, user); return map; }, new Map<number, APIUser>()), [props.users]);
  const teamsMap: TeamsMap = React.useMemo(() => props.teams.reduce((map, team) => { map.set(team.id, team); return map; }, new Map<number, APITeam>()), [props.teams]);

  const history = useHistory();

  React.useEffect(() => {
    if (!activeGroupDetails) return;
    const tracker = new ActivityTracker(AppSection.BillOfLading, activeGroupDetails.id);

    window.addEventListener('click', tracker.activity);
    window.addEventListener('keydown', tracker.activity);
    window.addEventListener('scroll', tracker.activity);
    window.addEventListener('beforeunload', tracker.sendActivity);

    return () => {
      window.removeEventListener('click', tracker.activity);
      window.removeEventListener('keydown', tracker.activity);
      window.removeEventListener('scroll', tracker.activity);
      window.removeEventListener('beforeunload', tracker.sendActivity);
      tracker.sendActivity();
    }
  }, [activeGroupDetails]);

  useInterval(() => {
    /* Keep in mind - This refresh can cause issues of inner components, rerendering stuff which
    should not be rerendered, if that is causing any issue in your code, try solving it with useMemo */
    if (searchFilter) {
      loadBillsForTable(searchFilter);
    }
  }, 120000, [searchFilter]);

  React.useEffect(() => {
    fetchReferenceData();
    updateListOffset();
  }, []);

  React.useEffect(() => {
    if (props.mode === GroupSectionMode.WtgIntegration) {
      setSearchTags(wtgIntegrationSearchTags);
    } else {
      setSearchTags(groupsSearchTags);
    }
    searchDocumentsWithLastFilter(false);
  }, [props.mode]);

  React.useEffect(() => {
    if (activeGroupDetails) {
      setShowDetailsView(true);
    }
  }, [activeGroupDetails]);

  React.useEffect(() => {
    if (!mergeMode) setSelectedGroups([]);
  }, [mergeMode]);

  React.useEffect(() => {
    if (showDetailsView) return;
    searchDocumentsWithLastFilter(true);
  }, [showDetailsView]);

  React.useEffect(() => {
    const filtersWidth = document.getElementsByClassName('search-area__filter')[0].clientWidth;
    const searchTagsElem = document.querySelector<HTMLElement>('.search-tags');
    if (searchTagsElem) searchTagsElem.style.left = (filtersWidth + 58).toString() + 'px';
  }, [lastFilter]);

  React.useEffect(() => {
    // update search list once every 2 minutes

    // Keep in mind - This periodicUpdates below can cause issues of inner components, rerendering stuff
    // which should not be rerendered, if that is causing any issue in your code, try solving it with useMemo 
    const periodicUpdates = true;
    if (periodicUpdates) {
      const interval = window.setInterval(() => {
        searchDocumentsWithLastFilter(true);
      }, AUTO_REFRESH_MSTIME);

      return () => window.clearInterval(interval);
    }
  }, [lastFilter, props.mode]);

  React.useEffect(() => {
    showDetailsViewForGroup(props.selectedGroupId);
  }, [props.selectedGroupId]);

  const updateListOffset = () => {
    const searchArea = document.getElementsByClassName('actions')[0];
    if (searchArea && listOffset !== (searchArea as HTMLDivElement).offsetHeight) {
      setListOffset((searchArea as HTMLDivElement).offsetHeight);
    }
  }

  const searchDocumentsWithLastFilter = (isAutoRefresh: boolean): Promise<APIDocumentGroupSearchDetails[]> | undefined => {
    if (lastFilter) {
      if (lastFilter.offset) {
        lastFilter.limit = Number(lastFilter.limit) + Number(lastFilter.offset);
        delete lastFilter.offset;
      }
      return searchDocuments(lastFilter, isAutoRefresh, true);
    }
  }

  const searchDocuments = async (filter: Filter, isAutoRefresh?: boolean, clearData = true): Promise<APIDocumentGroupSearchDetails[]> => {
    setLockedFetcher(true);
    if (!isAutoRefresh) { // we don't want to show the spinner when autoRefreshing
      setFetchingData(true);
    }

    if (!filter.orderBy && !filter.orderDirection) {
      if (lastFilter && lastFilter.orderBy && lastFilter.orderDirection) {
        filter.orderBy = lastFilter.orderBy;
        filter.orderDirection = lastFilter.orderDirection;
      } else {
        filter.orderBy = 'date';
        filter.orderDirection = 'desc';
      }
    }

    if (clearData && !isAutoRefresh) clearLoadedBills();
    filter.limit = (Number(filter.limit) || DEFAULT_LIST_LIMIT);

    const result = await searchBillsOfLading(filter);
    if (result === null) {
      props.setNotification({ ...APIError, details: { filter }, reason: `Error fetching groups, resulted null` });
    } else {
      if (clearData) {
        loadBillsOfLading(result);
      } else {
        loadBillsOfLading([...billsOfLading, ...result]);
      }
    }

    setHasMore(result.length >= filter.limit);
    setFetchingData(false);
    setLockedFetcher(false);
    setLastFilter(filter);

    return result;
  }
  useKeyPress('Escape', () => {
    if (!showDetailsView && mergeMode) {
      setMergeMode(false);
    }
  }, [document.activeElement]);

  // Fetching the addresses of all creditors of a company and unlocodes can potentially take a looooong time as
  // it's a lot of data. So we only want to fetch for the bills we fetched.
  const fetchRelatedData = async (allGroups: APIDocumentGroupSearchDetails[]) => {
    let issuerRecordIds = new Set<string>();
    // let allUnlocodes = new Set<string>();
    // let seaPortUnlocodes = new Set<string>();

    allGroups.forEach((group) => {
      group?.documents?.forEach((document) => {
        if (document.billOfLading) {
          issuerRecordIds.add(document.billOfLading.consigneeCgwRecordId!);
          issuerRecordIds.add(document.billOfLading.shipperCgwRecordId!);
          issuerRecordIds.add(document.billOfLading.shippingLineCgwRecordId!);

          // if (document.documentType === DocumentType.MasterBillOfLading) {
          //   if (document.billOfLading.loadPortUnlocode) seaPortUnlocodes.add(document.billOfLading.loadPortUnlocode);
          //   if (document.billOfLading.dischargePortUnlocode) seaPortUnlocodes.add(document.billOfLading.dischargePortUnlocode);
          // }

          if (document.billOfLading.notify && document.billOfLading.notify.length > 0 && document.billOfLading.notify[0].notifyPartyRecordId) {
            issuerRecordIds.add(document.billOfLading.notify[0].notifyPartyRecordId);
          }

          // if (document.billOfLading.originUnlocode) allUnlocodes.add(document.billOfLading.originUnlocode);
          // if (document.billOfLading.destinationUnlocode) allUnlocodes.add(document.billOfLading.destinationUnlocode);
        } else if (document.commercialInvoice) {
          document.commercialInvoice.importerRecordId && issuerRecordIds.add(document.commercialInvoice.importerRecordId);
          document.commercialInvoice.supplierRecordId && issuerRecordIds.add(document.commercialInvoice.supplierRecordId);
        }
      })
    });

    // allUnlocodes = new Set(Array.from(allUnlocodes).filter((unlocode) => unlocode !== null && !allLocations[unlocode]));
    //
    // if (allUnlocodes.size) {
    //   const allLocationsData = await LocationUnlocodeAPI.fetchLocations({
    //     unlocode: Array.from(allUnlocodes)
    //   });
    //
    //   setAllLocations(allLocationsData.reduce((map, item) => ({ ...map, [item.unlocode]: item }), { ...allLocations }));
    // }
    //
    // seaPortUnlocodes = new Set(Array.from(seaPortUnlocodes).filter((unlocode) => unlocode !== null && !seaportLocations[unlocode]));
    //
    // if (seaPortUnlocodes.size) {
    //   const seaPortLocationsData = await LocationUnlocodeAPI.fetchLocations({
    //     unlocode: Array.from(seaPortUnlocodes),
    //     seaport: true,
    //   });
    //
    //   setSeaportLocations(seaPortLocationsData.reduce((map, item) => ({ ...map, [item.unlocode]: item }), { ...seaportLocations }));
    // }

    issuerRecordIds = new Set(Array.from(issuerRecordIds).filter((recordId) => recordId !== null && !issuerDetails[recordId]));

    if (issuerRecordIds.size) {
      const details = await IssuerAPI.fetchIssuerDetailsMap(Array.from(issuerRecordIds));
      setIssuerDetails((prevValue) => ({
        ...prevValue,
        ...details
      }));
    }
  }

  const clearLoadedBills = () => {
    setBillsOfLading([]);
  }

  const fetchReferenceData = async () => {
    const [types, currencies, cwReferenceData, vessels] = await Promise.all([
      DocumentContainerAPI.fetchDocumentTypes(),
      CurrencyAPI.fetchAll(),
      CompanyAPI.fetchCwReferenceData(),
      VesselAPI.fetchAll(),
    ]);

    if ([types, currencies, cwReferenceData, vessels].includes(null)) {
      props.setNotification({ ...APIError, details: {}, reason: `Error fetching reference data, some of the requests retrieved null` });
    }

    setDocumentTypes((types || []).filter((type) => !!type.cwCode && type.id < 1000));
    setCurrencyOptions((currencies || []).map((currency) => ({ value: currency.currency, label: currency.currency })));
    setCwReferenceData(cwReferenceData || []);
    setVesselOptions((vessels || []).map((vessel) => ({ value: vessel.imo, label: vessel.name })));
  }

  const loadBillsForTable = async (searchFilter: SearchFilter) => {
    let data;
    switch (props.mode) {
      case GroupSectionMode.WtgIntegration:
        data = await DocumentGroupAPI.fetchWtgIntegrationGroupsForTeam(searchFilter);
        break;
      case GroupSectionMode.General:
      default:
        data = await DocumentGroupAPI.fetchGroupsForTeam(searchFilter);
        break;
    }

    fetchRelatedData([...billsOfLading, ...data.groups]);

    return data.groups;
  }

  const mergeSelectedGroups = async () => {
    setOverlay(OverlayType.Default);

    try {
      const response = await DocumentGroupAPI.mergeGroups(selectedGroups);

      if (!response) {
        props.setNotification({ ...APIError, details: { groupIds: selectedGroups }, reason: `Error on merging group request with null response` });
      } else if ((response as any).error) {
        const message = (response as any).error.message;
        props.setNotification({ type: 'error', value: message });
      } else {
        props.setNotification(Merged);
      }

      await loadBillsForTable(searchFilter);
    } catch (error) {
      props.setNotification({ ...APIError, details: { groupIds: selectedGroups }, reason: `Error on merging group request: ${error}` });
    }

    setOverlay(OverlayType.Hidden);
    setMergeMode(false);
  }

  const showDetailsViewForGroup = async (id?: number, reloadBoards?: boolean) => {
    if (id || id === 0) {
      setActiveGroupDetails(null)

      let allReloadedBills;
      if (reloadBoards) {
        allReloadedBills = await reloadDashboard();
      }

      try {
        const details = await DocumentGroupAPI.fetchDetails(id);

        let allowOpening = false;
        if (details.status === StatusCode.Processing) {
          const filesProcessing = details.documents.filter((d: any) => d.documentStatus === 0);
          if (details.documents.some((d) => d.children && d.children?.length > 0) &&
            filesProcessing.some((d) => [DocumentType.CommercialInvoice, DocumentType.PackingList, DocumentType.HouseBillOfLading].includes(d.documentType))) {
            allowOpening = true;
          }
        }
        if (details.status && (details.emailAccount?.validationType === ValidationType.AP ||
          ([StatusCode.Processing, StatusCode.PendingResponse].includes(details.status) && !allowOpening))) {
          //Should not handle AP invoices or groups which should not be acessible
          return;
        }
        // search selected group in allReloadedBills
        let fileSorter = (allReloadedBills || []).find((b) => b.id === details.id);
        // if not found, search in groups from search
        if (!fileSorter) fileSorter = billsOfLading.find((b) => b.id === details.id);

        if (details.emailAccount?.validationType === ValidationType.WtgIntegration) {
          details.postingBehaviour = PostingBehaviour.WtgIntegration;
        }
        setActiveGroupDetails(details);
        setFileSorterData(details as APIDocumentGroupSearchDetails || null);
      } catch (error) {
        props.showNotAuthorizedPage();
        return;
      }
    }
  }

  const reloadGroupData = async () => {
    if (activeGroupDetails) {
      const details = await DocumentGroupAPI.fetchDetails(activeGroupDetails.id!);
      if (details.emailAccount?.validationType === ValidationType.WtgIntegration) {
        details.postingBehaviour = PostingBehaviour.WtgIntegration;
      }
      setActiveGroupDetails(details);
    }
  }

  const fieldsChanged = async (changes: FieldChangedParams[]) => {
    const recordIdsToFetch: string[] = [];

    changes.forEach((params) => {
      if (params.recordId && !issuerDetails[params.recordId]) {
        recordIdsToFetch.push(params.recordId);
      }
    });

    let fetchedIssuerDetailsMap: IssuerDetailMap;
    if (recordIdsToFetch) {
      fetchedIssuerDetailsMap = await IssuerAPI.fetchIssuerDetailsMap(recordIdsToFetch);

      if (fetchedIssuerDetailsMap) {
        setIssuerDetails((prevValue) => ({
          ...prevValue,
          ...fetchedIssuerDetailsMap
        }));
      }
    }

    setBillsOfLading((items) => items.map((currentValue) => updateBillAfterFieldChanged(currentValue, changes)));
    setFileSorterData((currentValue) => currentValue && updateBillAfterFieldChanged(currentValue, changes));
  }

  const updateFields = (document: APIDocumentContainer, params: FieldChangedParams) => {
    if (params.fieldName === 'jobRef' && document.billOfLading) {
      if (params.id && document.billOfLading.id === params.id) {
        if (document.billOfLading && params.id) {
          // @ts-ignore
          document.billOfLading.importerReference = params.value;
          return;
        }
      }
    } else {
      if (params.id && document.id === params.id) {
        if (params.jobType === JobType.Invoice && document.commercialInvoice) {
          // @ts-ignore
          document.commercialInvoice[params.fieldName] = params.recordId || params.value;
          return;
        } else if (params.jobType === JobType.PackingList && document.packingList) {
          // @ts-ignore
          document.packingList[params.fieldName] = params.recordId || params.value;
          return;
        } else if (document.billOfLading) {
          // @ts-ignore
          document.billOfLading[params.fieldName] = params.recordId || params.value;
          return;
        }
      }
    }
  }

  const updateDocuments = (documents: APIDocumentContainer[], params: FieldChangedParams, topDocument?: APIDocumentContainer) => {
    if (topDocument) {
      updateFields(topDocument, params);
      if (topDocument.children) {
        updateDocuments(topDocument.children, params);
      }
    } else {
      for (const document of documents) {
        updateFields(document, params);
        if (document.children) {
          updateDocuments(document.children, params);
        }
      }
    }
  }

  const updateBillAfterFieldChanged = (group: APIDocumentGroupSearchDetails, changes: FieldChangedParams[]): APIDocumentGroupSearchDetails => {
    if (group.id !== activeGroupDetails?.id) {
      return group;
    }

    const updatedData = { ...group };

    const topDocument = findTopFile(group.documents);

    changes.forEach((params) => {
      if (topDocument) updateDocuments(updatedData.documents, params, topDocument);
      else updateDocuments(updatedData.documents, params);
    });

    return updatedData;
  }

  const searchBillsOfLading = async (filter: Filter) => {
    const searchFilter: Filter = {};
    if (filter.bLNumber) searchFilter.billOfLadingNo = filter.bLNumber;
    if (filter.organisationCode) searchFilter.organisationCode = filter.organisationCode;
    if (filter.organisationName) searchFilter.organisationName = filter.organisationName;
    if (filter.invoiceNumber) searchFilter.invoiceNumber = filter.invoiceNumber;
    if (filter.jobReference) searchFilter.jobReference = filter.jobReference;
    if (filter.receivedDate) searchFilter.receivedDate = filter.receivedDate;
    if (filter.postedDate) searchFilter.postedDate = filter.postedDate;
    if (filter.status) searchFilter.status = filter.status;
    if (filter.teamId) searchFilter.validationTeamId = filter.teamId;
    if (filter.userIds) searchFilter.userIds = filter.userIds;
    if (filter.orderBy) searchFilter.orderBy = filter.orderBy;
    if (filter.orderDirection) searchFilter.orderDirection = filter.orderDirection;
    if (filter.limit) searchFilter.limit = filter.limit;
    if (filter.offset) searchFilter.offset = filter.offset;
    if (filter.documentNumber) searchFilter.documentNumber = filter.documentNumber;
    if (filter.emailSubject) searchFilter.emailSubject = filter.emailSubject;
    if (filter.packId) searchFilter.groupId = filter.packId;

    let result;
    switch (props.mode) {
      case GroupSectionMode.WtgIntegration:
        result = await DocumentGroupAPI.fetchWtgIntegrationGroups(searchFilter);
        break;
      case GroupSectionMode.General:
      default:
        result = await DocumentGroupAPI.fetchBills(searchFilter);
        break;
    }

    if (result.numberOfUnpostedGroups >= 0) {
      statusOptions[1].label = `${statusOptions[1].label.split('(')[0]} (${result.numberOfUnpostedGroups})`;
    }

    return result.groups;
  }

  const loadBillsOfLading = (bills: APIDocumentGroupSearchDetails[]) => {
    setBillsOfLading(bills);
    fetchRelatedData(bills);
  }

  const reloadDashboard = async (): Promise<APIDocumentGroupSearchDetails[]> => {
    return loadBillsForTable(searchFilter);
  }

  const hideGroup = (id: number) => {
    setBillsOfLading((prevBills) => prevBills.filter((bill) => bill?.id !== id));
  }

  const updateSearchFilter = React.useCallback((update: Partial<SearchFilter>) => {
    setSearchFilter((prevFilter) => ({ ...prevFilter, ...update }));
  }, []);

  return (
    <>
      <DetailView
        show={showDetailsView}
        setShow={setShowDetailsView}
        details={activeGroupDetails}
        fileSorterData={fileSorterData}
        teams={props.teams}
        users={props.users}
        teamsMap={teamsMap}
        usersMap={usersMap}
        setNotification={props.setNotification}
        reloadDetailsForGroup={showDetailsViewForGroup}
        issuerDetails={issuerDetails}
        cwReferenceData={cwReferenceData}
        vesselOptions={vesselOptions}
        countryOptions={props.countryOptions}
        currencyOptions={currencyOptions}
        setOverlay={setOverlay}
        reloadGroupData={reloadGroupData}
        fieldChanged={fieldsChanged}
        documentTypes={documentTypes}
        reloadDashboard={reloadDashboard}
        creditorDetailsMap={issuerDetails}
        fetchRelatedData={fetchRelatedData}
        hideGroup={hideGroup}
        addEventsToListen={props.addEventsToListen}
        removeGroupNotifications={props.removeGroupNotifications}
        enableTableCoordinates={props.enableTableCoordinates}
      />
      <div className="bill-of-lading group-section with-custom-scrollbar">
        <div className="actions">
          <>
            <SearchPanel
              searchFilter={searchFilter}
              setSearchFilter={updateSearchFilter}
              hasSort={false}
              teams={props.teams}
              users={props.users}
              updateListOffset={updateListOffset}
              searchDocuments={searchDocuments}
              sortOrderOptions={sortOrderOptions}
              statusOptions={statusOptions}
              searchTags={searchTags}
              kind="bl"
            />
          </>
          {props.mode !== GroupSectionMode.WtgIntegration &&
            (<div className="actions__buttons">
              <button
                className={mergeMode ? 'merge' : 'edit'}
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  return mergeMode ? mergeSelectedGroups() : setMergeMode(!mergeMode)
                }}
                disabled={mergeMode && selectedGroups.length < 2}
              >
                {mergeMode ? 'Merge' : 'Merge Packs'}
              </button>
              <button className="merge__cancel" disabled={!mergeMode} onClick={() => setMergeMode(false)}>Cancel</button>
            </div>)
          }
        </div>
        <div ref={documentListRef} id="scrollable-cards" className="cards with-custom-scrollbar" style={{ height: `calc(100vh - ${listOffset}px)`, backgroundColor: '#F1F1F1' }}>
          <GroupDataGrid
            mode={props.mode}
            listOffset={listOffset}
            billsOfLading={billsOfLading}
            documentTypes={documentTypes}
            issuerDetails={issuerDetails}
            // allLocations={allLocations}
            // seaportLocations={seaportLocations}
            lockedFetcher={lockedFetcher}
            lastFilter={lastFilter}
            defaultListLimit={DEFAULT_LIST_LIMIT}
            hasMore={hasMore}
            mergeMode={mergeMode}
            searchDocuments={searchDocuments}
            setSelectedGroups={setSelectedGroups}>
          </GroupDataGrid>
          {fetchingData && <Icon style={{ fontSize: '48px', margin: '20px calc(50% - 24px) 30px' }} src="spinner-md" />}
        </div>
      </div>
      <div className={`details-overlay ${overlay === OverlayType.Hidden ? '' : 'show'}`}>
        {(overlay !== OverlayType.Hidden) && <Icon style={{ fontSize: '56px', left: 'calc(50% - 28px)', top: 'calc(40% - 28px)' }} src="spinner-md" />}
        <div className={`close-on-overlay ${overlay === OverlayType.CloseMessage ? 'show' : ''}`}
          onClick={() => {
            setShowDetailsView(false);
            history.push(`/${location.pathname.split('/')[1]}`);
          }}>
          <div className={`processing-message`}>Processing...</div>
          <div className={`close-message`}>
            <p>This may take a few moments and you will be notified when completed.</p>
            <p>Click to return to the F&C home page.</p>
          </div>
        </div>
      </div>
    </>
  );
});
