import * as React from 'react';
import { Consol } from './Consol';
import { Shipment } from './Shipment';
import {
  APIDocumentGroupCInvoice,
  APIDocumentGroupConsol,
  APIDocumentGroupDetails,
  APIDocumentGroupDocument,
  APIDocumentGroupPackingList,
  APIDocumentGroupShipment
} from '../../../api/documentGroup';
import { Notification } from '../../common/Notification';
import { OptionValue } from 'react-selectize';
import { APICwReferenceData } from '../../../api/company';
import { ConfirmModalProps } from '../../common/ConfirmModal';
import { Invoice } from './Invoice';
import { Brokerage } from './Brokerage';
import { CollapsibleContainer } from '../../common/CollapsibleContainer';
import { FieldChangedParams, fwdOrFncPostingBehaviour, IssuerDetailMap, JobType, PostingBehaviour } from '../common';
import { BillOfLadingAPI } from '../../../api/billOfLading';
import { CommercialInvoiceAPI } from '../../../api/commercialInvoice';
import { APITeam } from '../../../api/teams';
import { APIUser, TeamsMap, UsersMap } from '../../../api/comment';
import { ShipmentPlaceholder } from './ShipmentPlaceholder';
import { TMSType, ValidationType } from '../../../api/emailAccount';
import { CodeSelectionModalProps } from '../../common/CodeSelectionModal';
import { filterEDocWarnings, filterExceptions, findConsolInChildren, findInChildren, findInvoicesInChildren, findPackingListsInChildren, findShipmentsInChildren } from '../../helpers';
import { APIDocumentContainer, APIDocumentType, DocumentType } from '../../../api/documentContainer';
import { APIEmailBasic } from '../../../api/email';
import { TabSwitcher } from '../../common/TabSwitcher';
import { ActivityFeed, ActivityFeedHandle } from '../../common/activity-feed/ActivityFeed';
import { ExceptionsContainer } from '../../common/ExceptionsContainer';
import { PackingList } from './PackingList';
import { MasterBill } from './bills/MasterBill';
import { HouseBill } from './bills/HouseBill';
import { GenericBill } from './bills/GenericBill';

import './group-details.scss';

export interface ItemisedGroup {
  shipmentDocs: APIDocumentGroupDocument<APIDocumentGroupShipment>[];
  consolDoc?: APIDocumentGroupDocument<APIDocumentGroupConsol>;
  consolDocs?: APIDocumentGroupDocument<APIDocumentGroupConsol>[];
  invoiceDocs: APIDocumentGroupDocument<APIDocumentGroupCInvoice>[];
  packingListDocs: APIDocumentGroupDocument<APIDocumentGroupPackingList>[];
}

enum Tab {
  DocumentData = 'Document data',
  CommentsActivity = 'Comments & Activity',
}

interface Props {
  group: APIDocumentGroupDetails | null;
  allGroupDocuments?: APIDocumentContainer[];
  teams: APITeam[];
  users: APIUser[];
  teamsMap: TeamsMap;
  usersMap: UsersMap;
  issuerDetails: IssuerDetailMap;
  cwReferenceData: APICwReferenceData | null;
  vesselOptions: OptionValue[];
  countryOptions: OptionValue[];
  currencyOptions: OptionValue[];
  show: boolean;
  activeDocument: APIDocumentContainer | APIEmailBasic | undefined;
  setActiveDocument: (document: APIDocumentContainer | APIEmailBasic | undefined, scroll?: boolean) => void;
  setConfirmModal: (props: ConfirmModalProps) => void;
  setDiscardCodesModal: (props: CodeSelectionModalProps) => void;
  setNotification: (notification: Notification) => void;
  reloadDetailsForGroup: (id: number, reloadBoards?: boolean) => Promise<void>;
  closeDetailsView: () => void;
  reloadGroupData: () => Promise<void>;
  reloadDashboard: () => void;
  fieldsChanged: (changes: FieldChangedParams[]) => void;
  documentTypes: APIDocumentType[];
  isFormDisabled: boolean;
  setShowOverlay: (show: boolean) => void;
  activityFeedRef: React.MutableRefObject<ActivityFeedHandle | null>;
  placeholderJobRef: string;
  setPlaceholderJobRef: (jobRef: string) => void;
  enableTableCoordinates?: boolean;
}

const prepareCwReferenceDataOptions = (type: keyof APICwReferenceData, referenceData: APICwReferenceData | null) => {
  if (referenceData) {
    if (['bJobShipmentType'].includes(type)) {
      return referenceData[type].map((item) => ({ value: item.code, label: item.description.toUpperCase() }));
    } else if (['consolType', 'containerMode', 'packageType', 'paymentTerms', 'incoTerm', 'unitType', 'releaseType'].includes(type)) {
      return referenceData[type].map((item) => ({ value: item.code, label: `${item.code} - ${item.description}` }));
    } else {
      return referenceData[type].map((item) => ({ value: item.code, label: item.code }));
    }
  }

  return [];
}

export const GroupDetails: React.FC<Props> = (props) => {
  const groupDetailsRef = React.useRef<HTMLDivElement | null>(null);
  const paymentTermsOptions = React.useMemo(() => prepareCwReferenceDataOptions('paymentTerms', props.cwReferenceData), [props.cwReferenceData]);
  const releaseTypeOptions = React.useMemo(() => prepareCwReferenceDataOptions('releaseType', props.cwReferenceData), [props.cwReferenceData]);
  const containerTypeOptions = React.useMemo(() => prepareCwReferenceDataOptions('containerType', props.cwReferenceData), [props.cwReferenceData]);
  const consolTypeOptions = React.useMemo(() => prepareCwReferenceDataOptions('consolType', props.cwReferenceData), [props.cwReferenceData]);
  const shipmentTypeOptions = React.useMemo(() => prepareCwReferenceDataOptions('shipmentType', props.cwReferenceData), [props.cwReferenceData]);
  const bJobShipmentTypeOptions = React.useMemo(() => prepareCwReferenceDataOptions('bJobShipmentType', props.cwReferenceData), [props.cwReferenceData]);
  const transportModeOptions = React.useMemo(() => prepareCwReferenceDataOptions('transportMode', props.cwReferenceData), [props.cwReferenceData]);
  const packageTypeOptions = React.useMemo(() => prepareCwReferenceDataOptions('packageType', props.cwReferenceData), [props.cwReferenceData]);
  const containerModeOptions = React.useMemo(() => prepareCwReferenceDataOptions('containerMode', props.cwReferenceData), [props.cwReferenceData]);
  const incoTermsOptions = React.useMemo(() => prepareCwReferenceDataOptions('incoTerm', props.cwReferenceData), [props.cwReferenceData]);
  const unitTypeOptions = React.useMemo(() => prepareCwReferenceDataOptions('unitType', props.cwReferenceData), [props.cwReferenceData]);

  const exceptionsList = React.useMemo(() => filterExceptions(filterEDocWarnings(props.group?.lastValidationResult?.details?.exceptions || [])), [props.group]);

  const [selectedTab, setSelectedTab] = React.useState<string>(Tab.DocumentData);

  const [blNums, setBlNums] = React.useState<Map<number, string>>(new Map());
  const [cinvNums, setCinvNums] = React.useState<Map<number, string>>(new Map());
  const [jobRefs, setJobRefs] = React.useState<Map<number, string>>(new Map());

  const getItemisedGroup = (
    documents: APIDocumentGroupDocument<APIDocumentGroupCInvoice | APIDocumentGroupConsol | APIDocumentGroupShipment | APIDocumentGroupPackingList>[],
    multipleMbls = false,
  ): ItemisedGroup => {
    const shipmentDocs = findShipmentsInChildren(documents).sort((a, b) => b.documentType - a.documentType);
    const invoiceDocs = findInvoicesInChildren(documents);
    const packingListDocs = findPackingListsInChildren(documents);
    const itemisedGroup: ItemisedGroup = { shipmentDocs, invoiceDocs, packingListDocs };

    const consolD = findConsolInChildren(documents, multipleMbls);
    if (multipleMbls) itemisedGroup.consolDocs = consolD as APIDocumentGroupDocument<APIDocumentGroupConsol>[];
    else itemisedGroup.consolDoc = consolD as APIDocumentGroupDocument<APIDocumentGroupConsol>;

    return itemisedGroup;
  }

  let group: ItemisedGroup = {
    shipmentDocs: [],
    invoiceDocs: [],
    packingListDocs: [],
  };

  let bills: APIDocumentGroupDocument<APIDocumentGroupConsol | APIDocumentGroupShipment>[] = [];
  let shipments: APIDocumentGroupDocument<APIDocumentGroupShipment>[] = [];
  let invoices: APIDocumentGroupCInvoice[] = [];
  let packingLists: APIDocumentGroupPackingList[] = [];
  let placeholder;
  if (props.group) {
    const multipleMbls = props.group.postingBehaviour === PostingBehaviour.GenericTMS;
    group = getItemisedGroup(props.group.documents, multipleMbls);
    if (group.shipmentDocs.length > 0) {
      for (const doc of group.shipmentDocs) {
        if (doc.billOfLading && doc.documentType !== DocumentType.PlaceholderShipment) bills.push(doc);
        if (doc.billOfLading &&
          !(doc.documentType === DocumentType.PlaceholderShipment && !group.consolDoc?.children?.some((d) => d.id === doc.id) && doc.children?.length === 0)) {
          // clause above remove some edge cases where we have left behind placeholders generated in pre-validation when parser does not recognizes BLs
          shipments.push(doc);
        }
      }
    }
    if (group.consolDoc?.billOfLading) {
      bills.push(group.consolDoc)
      if (fwdOrFncPostingBehaviour.includes(props.group.postingBehaviour) && group.consolDoc.billOfLading.consolType === 'DRT' &&
        shipments.filter((s) => s.documentType === DocumentType.PlaceholderShipment).length === shipments.length) {
        shipments.unshift(group.consolDoc);
      }
    } else if (group.consolDocs && group.consolDocs.length > 0) {
      for (const doc of group.consolDocs) {
        if (doc.billOfLading) bills.push(doc);
      }
    }

    if (group.invoiceDocs.length > 0) {
      for (const doc of group.invoiceDocs) {
        if (doc.commercialInvoice) invoices.push(doc.commercialInvoice);
      }
    }

    if (group.packingListDocs.length > 0) {
      for (const doc of group.packingListDocs) {
        if (doc.packingList) packingLists.push(doc.packingList);
      }
    }

    placeholder = findInChildren(props.group.documents,
      {
        key: 'documentType',
        values: [DocumentType.PlaceholderConsol, DocumentType.PlaceholderShipment, DocumentType.PlaceholderBJob]
      })[0] as APIDocumentGroupDocument<APIDocumentGroupConsol>;
  }

  let startX = 0;
  let startWidth = 0;

  React.useEffect(() => {
    if (!props.show) props.setShowOverlay(false);
    setSelectedTab(Tab.DocumentData);
  }, [props.show]);

  React.useEffect(() => {
    setBlNums(new Map());
    setCinvNums(new Map());
    setJobRefs(new Map());
  }, [props.group?.id]);


  const initDrag = (e: any) => {
    if (groupDetailsRef.current && document.defaultView) {
      document.documentElement.addEventListener("mousemove", doDrag, false);
      document.documentElement.addEventListener("mouseup", stopDrag, false);
      startX = e.clientX;
      startWidth = parseInt(document.defaultView.getComputedStyle(groupDetailsRef.current).width, 10);

      let draggableDivs = Array.from(document.getElementsByClassName("width-height-draggable"));
      draggableDivs.forEach((element) => {
        element.classList.add("no-select");
      });
    }
  }

  const doDrag = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (groupDetailsRef.current && document.defaultView) {
      groupDetailsRef.current.style.width = startWidth + ((startX - e.clientX)) + "px";
    }
  }

  const checkRepeatedBlNumber = (docId: number, value: string | undefined) => {
    if (!value || !docId) return false;
    const foundNum = Array.from(blNums.entries()).filter(([id, num]) => num === value);
    if (foundNum.length > 1 || (foundNum[0] && foundNum[0][0] !== docId)) {
      return true;
    }
    return false;
  };

  const addToBlNums = (docId: number, value: string) => {
    blNums.set(docId, value || '');
  }

  const checkRepeatedJobRef = (docId: number, value: string | undefined) => {
    if (!value || !docId) return false;
    const foundRef = Array.from(jobRefs.entries()).filter(([id, ref]) => ref === value);
    if (foundRef.length > 1 || (foundRef[0] && foundRef[0][0] !== docId)) {
      return true;
    }
    return false;
  };

  const addToJobRefNums = (docId: number, value: string) => {
    jobRefs.set(docId, value || '');
  }

  const checkRepeatedCinvNumber = (docId: number, value: string | undefined) => {
    if (!value || !docId) return false;
    const foundNum = Array.from(cinvNums.entries()).filter(([id, num]) => num === value);
    if (foundNum.length > 1 || (foundNum[0] && foundNum[0][0] !== docId)) {
      return true;
    }
    return false;
  };

  const addToCinvNums = (docId: number, value: string) => {
    cinvNums.set(docId, value || '');
  }

  const stopDrag = () => {
    if (groupDetailsRef.current) {
      document.documentElement.removeEventListener("mousemove", doDrag, false);
      document.documentElement.removeEventListener("mouseup", stopDrag, false);

      let draggableDivs = Array.from(document.getElementsByClassName("width-height-draggable"));
      draggableDivs.forEach((element) => {
        element.classList.remove("no-select");
      });
    }
  }

  const reloadGroupData = (forceRefresh = false) => {
    if (forceRefresh) {
      props.reloadGroupData();
    } else if (props.group) {
      const { shipmentDocs, consolDoc, invoiceDocs } = getItemisedGroup(props.group.documents);
      if (shipmentDocs.length === 1 && consolDoc &&
        consolDoc.id === shipmentDocs[0].id &&
        invoiceDocs.length === 0) { // if the consol is the same object as the shipment
        props.reloadGroupData();
      }
    }
  }

  const areAgentsDifferent = (onlyInvoices: boolean, invoices?: APIDocumentGroupCInvoice[]): boolean => {
    if (!props.group) return true;
    const shipperAgents = new Set<string>(), receiverAgents = new Set<string>();
    let group;
    if (props.group) group = getItemisedGroup(props.group.documents);
    if (group?.consolDoc?.billOfLading && !onlyInvoices) {
      shipperAgents.add(group?.consolDoc?.billOfLading.shipperCgwRecordId || '');
      receiverAgents.add(group?.consolDoc?.billOfLading.consigneeCgwRecordId || '');
    }

    !onlyInvoices && group?.shipmentDocs.forEach((doc) => {
      shipperAgents.add(doc.billOfLading?.shipperCgwRecordId || '');
      receiverAgents.add(doc.billOfLading?.consigneeCgwRecordId || '');
    });

    let invoicesToCheckForDifference = group?.invoiceDocs.map((id) => id.commercialInvoice) || [];
    if (props.group?.postingBehaviour === PostingBehaviour.ForwardingAndClearance) {
      invoicesToCheckForDifference = invoices || [];
    }
    invoicesToCheckForDifference.forEach((doc) => {
      shipperAgents.add(doc?.supplierRecordId || '');
      receiverAgents.add(doc?.importerRecordId || '');
    });

    return shipperAgents.size > 1 || receiverAgents.size > 1;
  }

  const cloneFieldValue = async (senderValue: string | undefined, receiverFields: string[] | undefined, onlyInvoices: boolean, isOrganisationUpdate = true, onlySameShipmentId?: number, includeConsol?: boolean) => {
    if (!senderValue || !props.group || !receiverFields || receiverFields.length < 1) {
      return;
    }

    props.setShowOverlay(true);

    const creditorsChanges: FieldChangedParams[] = [];
    const dbChanges: Promise<any>[] = [];

    let group: ItemisedGroup = {
      // THIS SHOULD PROBABLY BE CHANGED TO A SINGLE OBJECT NOT AN ARRAY
      shipmentDocs: [],
      invoiceDocs: [],
      packingListDocs: [],
    };
    if (props.group) group = getItemisedGroup(props.group.documents);

    if (props.group?.postingBehaviour !== PostingBehaviour.StandaloneCInv && !onlyInvoices) {

      const bolsToUpdate = [...group.shipmentDocs, ...[group.consolDoc]];

      await Promise.all(bolsToUpdate.map(async (doc) => {
        if (doc?.billOfLading?.id !== group.consolDoc?.billOfLading?.id || includeConsol) {

          receiverFields.forEach((field) => {
            type ObjectKey = keyof APIDocumentGroupShipment;
            const fieldVar = field as ObjectKey;

            const fieldValue = doc?.billOfLading![fieldVar];
            if (fieldValue !== senderValue) {
              const change: Partial<APIDocumentGroupShipment> = {
                [field]: senderValue
              };

              if (isOrganisationUpdate) {
                creditorsChanges.push({
                  recordId: senderValue,
                  fieldName: field,
                  id: doc?.id,
                  jobType: JobType.Shipment
                });
              }
              if (doc?.billOfLading) dbChanges.push(BillOfLadingAPI.update(doc?.billOfLading.id, change));
            }

          });
        }
      }));
    }

    let invoiceDocsToUpdate: APIDocumentGroupDocument<APIDocumentGroupCInvoice>[] = group.invoiceDocs;
    if (onlySameShipmentId) {
      const sameShipmentChildren =
        group.shipmentDocs.find((sd) => sd.id === onlySameShipmentId)?.children as APIDocumentGroupDocument<any>[];
      invoiceDocsToUpdate = sameShipmentChildren.filter(
        (c) => c.documentType === DocumentType.CommercialInvoice
      ) as APIDocumentGroupDocument<APIDocumentGroupCInvoice>[];
    }

    await Promise.all(invoiceDocsToUpdate.map(async (doc) => {

      receiverFields.forEach((field) => {
        type ObjectKey = keyof APIDocumentGroupCInvoice;
        const fieldVar = field as ObjectKey;

        const fieldValue = doc?.commercialInvoice![fieldVar];
        if (fieldValue !== senderValue) {
          const change: Partial<APIDocumentGroupCInvoice> = {
            [field]: senderValue
          };

          if (isOrganisationUpdate) {
            creditorsChanges.push({
              recordId: senderValue,
              fieldName: field,
              id: doc.id,
              jobType: JobType.Invoice
            });
          }

          if (doc.commercialInvoice) dbChanges.push(CommercialInvoiceAPI.update(doc.commercialInvoice.id, change));
        }
      });

    }));

    props.fieldsChanged(creditorsChanges);

    await Promise.all(dbChanges);
    await props.reloadGroupData();
    props.setShowOverlay(false);
  }

  const isNonCargowise = props.group?.emailAccount?.validationType === ValidationType.GenericTMS || (props.group?.emailAccount?.validationType === ValidationType.Forwarding && props.group.emailAccount.tmsType !== TMSType.Cargowise);
  const useOrganisations = props.group?.emailAccount ? props.group?.emailAccount?.orgMatchingEnabled : true;
  const displayInvoices = !!(invoices.length > 0 && props.group && [PostingBehaviour.WtgIntegration, PostingBehaviour.StandaloneCInv, PostingBehaviour.GenericTMS].includes(props.group.postingBehaviour));

  const civShipments = (shipment: APIDocumentGroupDocument<APIDocumentGroupShipment>) => {
    if (invoices.length <= 0) return [];
    if (!shipment.children || (shipment.children && shipment.children.length <= 0)) return [];
    if (props.group?.emailAccount?.validationType !== ValidationType.ClearanceAndForwarding) return [];

    return invoices.filter((invoiceFromAll) => shipment.children?.some((s) =>
      s.commercialInvoice ? s.commercialInvoice.id === invoiceFromAll.id : false
    ));
  };

  const pklShipments = (shipment: APIDocumentGroupDocument<APIDocumentGroupShipment>) => {
    if (packingLists.length <= 0) return [];
    if (!shipment.children || (shipment.children && shipment.children.length <= 0)) return [];
    if (props.group?.emailAccount?.validationType !== ValidationType.ClearanceAndForwarding) return [];

    return packingLists.filter((pklFromAll) => shipment.children?.some((s) =>
      s.packingList ? s.packingList.id === pklFromAll.id : false
    ));
  };

  const isCollapsedByDefault = (doc?: APIDocumentGroupDocument<APIDocumentGroupConsol>, invoice?: APIDocumentGroupCInvoice) => {
    const id = doc ? doc.id : invoice?.documentId;
    const isChildOfActiveDocument = doc?.children?.find((c) => c.id === props.activeDocument?.id);
    const isActiveDocumentOrChildOfActiveDocument = (isChildOfActiveDocument) ? true : props.activeDocument?.id === id;
    return isActiveDocumentOrChildOfActiveDocument ? false : true;
  }

  return (
    <>
      <div className="resizer-left" onMouseDown={initDrag} title="Drag to resize" />
      <div className="group-details width-height-draggable" ref={groupDetailsRef}>
        {!!exceptionsList.length && (
          <div className="exceptions-container">
            <ExceptionsContainer exceptions={exceptionsList} isFC={true} />
          </div>
        )}
        <TabSwitcher
          selectedTab={selectedTab}
          onTabClicked={setSelectedTab}
          tabs={[Tab.DocumentData, Tab.CommentsActivity]}
        />
        <div className="forms">
          <div className="forms__wrapper with-custom-scrollbar" style={{ display: selectedTab === Tab.DocumentData ? 'flex' : 'none' }}>
            {props.group && fwdOrFncPostingBehaviour.includes(props.group.postingBehaviour) && (bills.length > 0 || Boolean(placeholder?.billOfLading)) && (
              <>
                <Consol
                  data={group?.consolDoc?.billOfLading || placeholder?.billOfLading}
                  document={group?.consolDoc || placeholder}
                  isNonCargowise={isNonCargowise}
                  consolTypeOptions={consolTypeOptions}
                  paymentTermsOptions={paymentTermsOptions}
                  transModeOptions={transportModeOptions}
                  containerTypeOptions={containerTypeOptions}
                  containerModeOptions={containerModeOptions}
                  packageTypeOptions={packageTypeOptions}
                  releaseTypeOptions={releaseTypeOptions}
                  vesselOptions={props.vesselOptions}
                  groupId={props.group?.id}
                  reloadGroupData={reloadGroupData}
                  fieldsChanged={props.fieldsChanged}
                  issuerDetails={props.issuerDetails}
                  disabled={props.isFormDisabled}
                  setActiveDocument={props.setActiveDocument}
                  activeDocument={props.activeDocument}
                  allowCloneAgents={areAgentsDifferent(false)}
                  cloneFieldValue={cloneFieldValue}
                  addToBlNums={addToBlNums}
                  checkRepeatedBlNumber={checkRepeatedBlNumber}
                  useOrganisations={useOrganisations}
                  displayDraftAndFinal={props.group?.emailAccount?.validationType === ValidationType.Export}
                />
                <div className="shipments">
                  {shipments.map((shipment, i) => (
                    <Shipment
                      key={`shipment-${i}`}
                      data={shipment.billOfLading!}
                      document={group.shipmentDocs[i]}
                      isNonCargowise={isNonCargowise}
                      releaseTypeOptions={releaseTypeOptions}
                      transModeOptions={transportModeOptions}
                      shipmentTypeOptions={shipmentTypeOptions}
                      packingModeOptions={containerModeOptions}
                      packageTypeOptions={packageTypeOptions}
                      containerTypeOptions={containerTypeOptions}
                      defaultPackingMode={group?.consolDoc?.billOfLading?.containerMode}
                      reloadGroupData={reloadGroupData}
                      fieldsChanged={props.fieldsChanged}
                      issuerDetails={props.issuerDetails}
                      disabled={props.isFormDisabled}
                      setActiveDocument={props.setActiveDocument}
                      activeDocument={props.activeDocument}
                      allowCloneAgents={areAgentsDifferent(false)}
                      cloneFieldValue={cloneFieldValue}
                      shipmentsCount={shipments.length}
                      disableHighlights={shipment.billOfLading!.id === group?.consolDoc?.billOfLading!.id}
                      addToBlNums={addToBlNums}
                      checkRepeatedBlNumber={checkRepeatedBlNumber}
                      addToJobRefNums={addToJobRefNums}
                      checkRepeatedJobRef={checkRepeatedJobRef}
                      useOrganisations={useOrganisations}
                    >
                      {civShipments(shipment).map((invoice, i) => (
                        <Invoice
                          key={`invoice-${i}`}
                          data={invoice}
                          className={civShipments(shipment).length !== 0 ? 'line-civ-in-shipments' : ''}
                          document={group.invoiceDocs.find((doc) => doc.commercialInvoice.id === invoice.id)}
                          countryOptions={props.countryOptions}
                          currencyOptions={props.currencyOptions}
                          incoTermOptions={incoTermsOptions}
                          unitTypeOptions={unitTypeOptions}
                          fieldsChanged={props.fieldsChanged}
                          issuerDetails={props.issuerDetails}
                          exceptions={props.group?.lastValidationResult?.details?.exceptions}
                          reloadGroupData={() => reloadGroupData(true)}
                          disabled={props.isFormDisabled}
                          allowCloneAgents={areAgentsDifferent(true, civShipments(shipment))}
                          cloneFieldValue={cloneFieldValue}
                          setConfirmModal={props.setConfirmModal}
                          setActiveDocument={props.setActiveDocument}
                          activeDocument={props.activeDocument}
                          postingBehaviour={props.group?.postingBehaviour}
                          shipmentId={shipment.id}
                          removeCollapsibleToggles={false}
                          addToCinvNums={addToCinvNums}
                          checkRepeatedCinvNumber={checkRepeatedCinvNumber}
                          isNonCargowise={isNonCargowise}
                          useOrganisations={useOrganisations}
                          setNotification={props.setNotification}
                          enableTableCoordinates={props.enableTableCoordinates}
                        />
                      ))}
                    </Shipment>
                  ))}
                </div>
              </>
            )}
            {(props.group?.postingBehaviour === PostingBehaviour.NewBJob && (bills.length > 0 || Boolean(placeholder?.billOfLading))) && (
              <Brokerage
                data={(group.consolDoc?.billOfLading && shipments.length === 1) ? group.consolDoc : bills[0] || placeholder}
                secondaryBLData={(group.consolDoc?.billOfLading && shipments.length === 1) ? shipments[0] : null}
                transModeOptions={transportModeOptions}
                containerTypeOptions={containerTypeOptions}
                containerModeOptions={containerModeOptions}
                shipmentTypeOptions={bJobShipmentTypeOptions}
                packageTypeOptions={packageTypeOptions}
                vesselOptions={props.vesselOptions}
                groupId={props.group?.id}
                reloadGroupData={reloadGroupData}
                fieldsChanged={props.fieldsChanged}
                issuerDetails={props.issuerDetails}
                disabled={props.isFormDisabled}
                allowCloneAgents={areAgentsDifferent(false)}
                cloneFieldValue={cloneFieldValue}
                setActiveDocument={props.setActiveDocument}
                isNonCargowise={false}
                activeDocument={props.activeDocument}
                useOrganisations={useOrganisations}
              >
                {invoices.length > 0 && invoices.map((invoice, i) => (
                  <Invoice
                    key={`invoice-${i}`}
                    data={invoice}
                    document={group.invoiceDocs.find((doc) => invoice.id === doc.commercialInvoice?.id)}
                    countryOptions={props.countryOptions}
                    currencyOptions={props.currencyOptions}
                    incoTermOptions={incoTermsOptions}
                    unitTypeOptions={unitTypeOptions}
                    fieldsChanged={props.fieldsChanged}
                    issuerDetails={props.issuerDetails}
                    exceptions={props.group?.lastValidationResult?.details?.exceptions}
                    reloadGroupData={() => reloadGroupData(true)}
                    disabled={props.isFormDisabled}
                    allowCloneAgents={areAgentsDifferent(true)}
                    cloneFieldValue={cloneFieldValue}
                    setConfirmModal={props.setConfirmModal}
                    setActiveDocument={props.setActiveDocument}
                    activeDocument={props.activeDocument}
                    removeCollapsibleToggles={false}
                    addToCinvNums={addToCinvNums}
                    checkRepeatedCinvNumber={checkRepeatedCinvNumber}
                    isNonCargowise={isNonCargowise}
                    useOrganisations={useOrganisations}
                    setNotification={props.setNotification}
                    enableTableCoordinates={props.enableTableCoordinates}
                  />
                ))}
              </Brokerage>
            )}
            {props.group && placeholder?.billOfLading && [PostingBehaviour.UpdateBJob, PostingBehaviour.UpdateSJob].includes(props.group.postingBehaviour) && (
              <CollapsibleContainer
                title={props.group?.postingBehaviour === PostingBehaviour.UpdateSJob ? 'Shipment' : 'Brokerage'}
                className="brokerage__wrapper"
                versionTwo
                disableScroll
                isCollapsedByDefault={isCollapsedByDefault(placeholder)}
                disabled={props.isFormDisabled}
                visibleContent={<ShipmentPlaceholder
                  placeholder={placeholder?.billOfLading}
                  placeholderJobRef={props.placeholderJobRef}
                  fieldsChanged={props.fieldsChanged}
                  setPlaceholderJobRef={props.setPlaceholderJobRef}
                  disabled={props.isFormDisabled}
                  jobType={JobType.Shipment}
                  addToJobRefNums={addToJobRefNums}
                  checkRepeatedJobRef={checkRepeatedJobRef}
                />}
              >
                {invoices.length > 0 && invoices.map((invoice, i) => (
                  <Invoice
                    key={`invoice-${i}`}
                    data={invoice}
                    document={group.invoiceDocs.find((doc) => doc.commercialInvoice.id === invoice.id)}
                    countryOptions={props.countryOptions}
                    currencyOptions={props.currencyOptions}
                    incoTermOptions={incoTermsOptions}
                    unitTypeOptions={unitTypeOptions}
                    fieldsChanged={props.fieldsChanged}
                    issuerDetails={props.issuerDetails}
                    exceptions={props.group?.lastValidationResult?.details?.exceptions}
                    reloadGroupData={() => reloadGroupData(true)}
                    disabled={props.isFormDisabled}
                    allowCloneAgents={areAgentsDifferent(true)}
                    cloneFieldValue={cloneFieldValue}
                    setConfirmModal={props.setConfirmModal}
                    setActiveDocument={props.setActiveDocument}
                    activeDocument={props.activeDocument}
                    removeCollapsibleToggles={false}
                    addToCinvNums={addToCinvNums}
                    checkRepeatedCinvNumber={checkRepeatedCinvNumber}
                    isNonCargowise={isNonCargowise}
                    useOrganisations={useOrganisations}
                    setNotification={props.setNotification}
                    enableTableCoordinates={props.enableTableCoordinates}
                  />
                ))}
              </CollapsibleContainer>
            )}
            {PostingBehaviour.WtgIntegration === props.group?.postingBehaviour && bills.map((bl, i) => (bl.documentType === DocumentType.MasterBillOfLading ?
              <MasterBill
                data={bl.billOfLading}
                document={bl}
                consolTypeOptions={consolTypeOptions}
                paymentTermsOptions={paymentTermsOptions}
                transModeOptions={transportModeOptions}
                containerTypeOptions={containerTypeOptions}
                containerModeOptions={containerModeOptions}
                packageTypeOptions={packageTypeOptions}
                vesselOptions={props.vesselOptions}
                groupId={props.group?.id}
                reloadGroupData={reloadGroupData}
                fieldsChanged={props.fieldsChanged}
                issuerDetails={props.issuerDetails}
                disabled={props.isFormDisabled}
                setActiveDocument={props.setActiveDocument}
                activeDocument={props.activeDocument}
                addToBlNums={addToBlNums}
                checkRepeatedBlNumber={checkRepeatedBlNumber}
              />
              :
              <HouseBill
                key={`shipment-${i}`}
                data={bl.billOfLading!}
                document={group.shipmentDocs[i]}
                releaseTypeOptions={releaseTypeOptions}
                transModeOptions={transportModeOptions}
                shipmentTypeOptions={shipmentTypeOptions}
                packingModeOptions={containerModeOptions}
                packageTypeOptions={packageTypeOptions}
                containerTypeOptions={containerTypeOptions}
                defaultPackingMode={group?.consolDoc?.billOfLading?.containerMode}
                reloadGroupData={reloadGroupData}
                fieldsChanged={props.fieldsChanged}
                issuerDetails={props.issuerDetails}
                disabled={props.isFormDisabled}
                setActiveDocument={props.setActiveDocument}
                activeDocument={props.activeDocument}
                addToBlNums={addToBlNums}
                checkRepeatedBlNumber={checkRepeatedBlNumber}
              />
            ))}
            {(PostingBehaviour.GenericTMS === props.group?.postingBehaviour && bills.length > 0) &&
              bills.sort((a, b) => a.documentType - b.documentType).map((bl, i) =>
                (!bl.billOfLading) ? <></> :
                  <GenericBill
                    // Both
                    data={bl.billOfLading}
                    document={bl}
                    transModeOptions={transportModeOptions}
                    containerTypeOptions={containerTypeOptions}
                    packageTypeOptions={packageTypeOptions}
                    issuerDetails={props.issuerDetails}
                    disabled={props.isFormDisabled}
                    activeDocument={props.activeDocument}
                    containerOrPackingModeOptions={containerModeOptions}
                    disableHighlights={bl.billOfLading!.id === group?.consolDoc?.billOfLading!.id}
                    reloadGroupData={reloadGroupData}
                    fieldsChanged={props.fieldsChanged}
                    setActiveDocument={props.setActiveDocument}
                    addToBlNums={addToBlNums}
                    checkRepeatedBlNumber={checkRepeatedBlNumber}
                    // Master
                    groupId={props.group?.id}
                    vesselOptions={props.vesselOptions}
                    consolTypeOptions={consolTypeOptions}
                    paymentTermsOptions={paymentTermsOptions}
                    // House
                    releaseTypeOptions={releaseTypeOptions}
                    defaultPackingMode={group?.consolDoc?.billOfLading?.containerMode}
                    useOrganisations={useOrganisations}
                  />
              )
            }
            {displayInvoices && (
              <div className="invoices">
                {invoices.map((invoice, i) => (
                  <Invoice
                    key={`invoice-${i}`}
                    data={invoice}
                    document={group.invoiceDocs.find((doc) => doc.commercialInvoice.id === invoice.id)}
                    countryOptions={props.countryOptions}
                    currencyOptions={props.currencyOptions}
                    incoTermOptions={incoTermsOptions}
                    unitTypeOptions={unitTypeOptions}
                    fieldsChanged={props.fieldsChanged}
                    issuerDetails={props.issuerDetails}
                    exceptions={props.group?.lastValidationResult?.details?.exceptions}
                    reloadGroupData={() => reloadGroupData(true)}
                    disabled={props.isFormDisabled}
                    allowCloneAgents={areAgentsDifferent(true)}
                    cloneFieldValue={cloneFieldValue}
                    setConfirmModal={props.setConfirmModal}
                    setActiveDocument={props.setActiveDocument}
                    activeDocument={props.activeDocument}
                    postingBehaviour={props.group?.postingBehaviour}
                    addToCinvNums={addToCinvNums}
                    checkRepeatedCinvNumber={checkRepeatedCinvNumber}
                    isNonCargowise={isNonCargowise}
                    useOrganisations={useOrganisations}
                    setNotification={props.setNotification}
                    enableTableCoordinates={props.enableTableCoordinates}
                    removeCollapsibleToggles={false}
                  />
                ))}
              </div>
            )}
            {props.group && [PostingBehaviour.GenericTMS, PostingBehaviour.WtgIntegration].includes(props.group?.postingBehaviour) && packingLists.map((packingList, i) => (
              <PackingList
                key={`packingList-${i}`}
                data={packingList}
                document={group.packingListDocs.find((doc) => doc.packingList?.id === packingList.id)}
                unitTypeOptions={unitTypeOptions}
                packageTypeOptions={packageTypeOptions}
                fieldsChanged={props.fieldsChanged}
                issuerDetails={props.issuerDetails}
                exceptions={props.group?.lastValidationResult?.details?.exceptions}
                reloadGroupData={() => reloadGroupData(true)}
                disabled={props.isFormDisabled}
                allowCloneAgents={areAgentsDifferent(true)}
                cloneFieldValue={cloneFieldValue}
                setConfirmModal={props.setConfirmModal}
                setActiveDocument={props.setActiveDocument}
                activeDocument={props.activeDocument}
                postingBehaviour={props.group?.postingBehaviour}
                useOrganisations={useOrganisations}
                setNotification={props.setNotification}
                enableTableCoordinates={props.enableTableCoordinates}
              />
            ))}
          </div>
          <div style={{ width: '100%', display: selectedTab === Tab.CommentsActivity ? 'flex' : 'none' }}>
            <ActivityFeed
              ref={props.activityFeedRef}
              usersMap={props.usersMap}
              teamsMap={props.teamsMap}
              invoiceId={undefined}
              documentGroupId={props.group?.id}
              documentGroup={props.group}
              isNonCargowise={isNonCargowise}
              postingBehaviour={props.group?.postingBehaviour}
              setNotification={props.setNotification}
              visible={selectedTab === Tab.CommentsActivity}
              companyDocTypes={props.documentTypes.reduce((result, type) => ({ ...result, [type.id]: type.cwCode }), {})}
            />
          </div>
        </div >
      </div >
    </>
  );
}
