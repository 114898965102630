import * as React from 'react';
import { MultiSelect as ReactSelectizeMultiSelect, MultiSelectProps, OptionValue } from 'react-selectize';

interface FixedMultiSelectProps extends MultiSelectProps {
  values: OptionValue[];
  name: string;
  onChange(name: string, item: OptionValue[] | OptionValue): void;
  onValuesChange?: never;
}

// Directly taken from the Bulk repository (shipamax-web)
// This component fixes the incorrect type definitons on react-selectizes multiselect
export const MultiSelect: React.FC<FixedMultiSelectProps> = (props) => {
  const onValuesChange = (values: OptionValue[] | OptionValue) => {
    props.onChange(props.name, values);
  }

  return (
    <ReactSelectizeMultiSelect {...props} onValuesChange={onValuesChange} />
  );
}
