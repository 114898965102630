import * as React from 'react';
import './table-highlight.scss';
import { DragDropContext, Droppable, Draggable as DraggableDND, DropResult } from '@hello-pangea/dnd';
import { TooltipTrigger } from 'pivotal-ui/react/tooltip'
import { APITableDefinitions, DocumentContainerAPI } from '../../../api/documentContainer';
import { TableDefinitionsPreview } from './TableDefintionsPreview';
import { Notification, ParserError } from '../Notification';
import { ConfirmModal } from '../ConfirmModal';
import Draggable from 'react-draggable'
import { DraggableData } from 'react-draggable'
import { DocumentGroupAPI } from '../../../api/documentGroup';
import { ShipamaxDeleteTableColsIcon, ShipamaxDeleteTableIcon, ShipamaxDeleteTableRowsIcon, ShipamaxDownIcon, ShipamaxDragIcon, ShipamaxPlusIcon, ShipamaxTableIcon, ShipamaxTickIcon, ShipamaxTimesIcon, ShipamaxUpIcon } from '../../../images/Icons';

export interface tableDefinitions {
  x: {
    type: string | null,
    bounds: number[],
  }[],
  y: {
    hasData: boolean,
    bounds: number[],
  }[]
}[];

interface Props {
  documentScale: number;
  documentWidth: number;
  documentHeight: number;
  canvasWidth: number;
  canvasHeight: number;
  rotation: number;
  tableCoordinates: tableDefinitions;
  editTableDefinitions: boolean;
  pageIndex: number;
  pageRelativeIndex: number;
  cancelEditTrigger?: number;
  previewChangesTrigger?: number;
  setShowEditDefinitions?: (value: boolean) => void;
  documentId?: number;
  pdfDocument?: any;
  filename?: string | undefined;
  setNotification: (notification: Notification | null) => void;
  showSpinner?: boolean;
  filePages?: number[] | null;
  previewTableDefinitions?: boolean;
  allTableCoordinates: APITableDefinitions
  setActivePage?: (value: number) => void;
  documentType?: number;
  reloadGroupData?: () => Promise<void>;
  scrollPosition?: number;
  groupId?: number;
  specificId: number;
  pageContainerRef?: any;
  openEditView?: (pageIndex: number) => void;
  pageNavigatorShowing?: boolean;
  setShowPageNavigator?: (value: boolean) => void;
  setDefinitionsEdited?: (value: boolean) => void;
  applyChangesSubsequentTrigger?: number;
  applyChangesAllTrigger?: number;
  applyChangesCurrentTrigger?: number;
  setApplyChangesSubsequentTrigger?: (value: number) => void;
  setApplyChangesAllTrigger?: (value: number) => void;
  setApplyChangesCurrentTrigger?: (value: number) => void;
  setPreviewChangesTrigger?: (value: number) => void;
  setChangesComplete?: (value: boolean) => void;
}

interface TableBounds {
  tableTop: number;
  tableLeft: number;
  tableWidth: number;
  tableHeight: number;
  tableBorderWidth: number;
}

interface AvailableHeadings {
  columnName: string,
  removedByUser: boolean
}

export const TableHighlight: React.FC<Props> = (props) => {
  const initialTable = {
    tableTop: 0,
    tableLeft: 0,
    tableWidth: 0,
    tableHeight: 0,
    tableBorderWidth: 0
  }

  const initialCIVAvailableHeadings = [
    {
      columnName: "ItemProductCode",
      removedByUser: false
    },
    {
      columnName: "ItemDescription",
      removedByUser: false
    },
    {
      columnName: "ItemCountryOfOrigin",
      removedByUser: false
    },
    {
      columnName: "ItemNumberOfUnits",
      removedByUser: false
    },
    {
      columnName: "ItemUnitPrice",
      removedByUser: false
    },
    {
      columnName: "ItemUnitType",
      removedByUser: false
    },
    {
      columnName: "ItemNetAmount",
      removedByUser: false
    },
    {
      columnName: "ItemHsCode",
      removedByUser: false
    }
  ]

  const initialPKLAvailableHeadings = [
    {
      columnName: "LIProductCode",
      removedByUser: false
    },
    {
      columnName: "LIDescription",
      removedByUser: false
    },
    {
      columnName: "LIItemQuantity",
      removedByUser: false
    },
    {
      columnName: "LIPackageQuantity",
      removedByUser: false
    },
    {
      columnName: "LIQuantityPerPackage",
      removedByUser: false
    },
    {
      columnName: "LIGrossWeight",
      removedByUser: false
    },
    {
      columnName: "LINetWeight",
      removedByUser: false
    },
    {
      columnName: "LIVolume",
      removedByUser: false
    },
    {
      columnName: "LIHsCode",
      removedByUser: false
    }
  ]

  const [tableBounds, setTableBounds] = React.useState<TableBounds | undefined>(initialTable);
  const [unusedLabelsOpen, setUnusedLabelsOpen] = React.useState<boolean>(true);
  const [availableHeadings, setAvailableHeadings] = React.useState<AvailableHeadings[]>(props.documentType === 8 ? initialPKLAvailableHeadings : initialCIVAvailableHeadings);
  const [tableDefinitionData, setTableDefinitionData] = React.useState<tableDefinitions>(props.tableCoordinates);
  const [showPreview, setShowPreview] = React.useState<boolean>(false);
  const [reparsedData, setReparsedData] = React.useState<any>([]);
  const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false);
  const [showConfirmRemoveRowsModal, setShowConfirmRemoveRowsModal] = React.useState<boolean>(false);
  const [showConfirmRemoveColumnsModal, setShowConfirmRemoveColumnsModal] = React.useState<boolean>(false);
  const [showConfirmRemoveAllModal, setShowConfirmRemoveAllModal] = React.useState<boolean>(false);
  const [newColumnPosition, setNewColumnPosition] = React.useState<number>(0);
  const [newRowPosition, setNewRowPosition] = React.useState<number>(0);
  const [hideAddRowOptions, setHideAddRowOptions] = React.useState(true);
  const [hideAddColumnOptions, setHideAddColumnOptions] = React.useState(true);
  const [defaultDraggablePosition, setDefaultDraggablePosition] = React.useState({ x: 0, y: 0 });
  const [showDocMask, setShowDocMask] = React.useState(false);

  const documentLeft = ((window.visualViewport ? window.visualViewport.width : 0) - props.documentWidth) / 2;
  const dragBufferDistance = 5;
  const pagePreviewWidth = props.pageNavigatorShowing ? 170 : 0;

  const getTableData = () => {
    const numberOfCols = tableDefinitionData.x.length;
    const numberOfRows = tableDefinitionData.y.length;
    const tableBorderWidth = 2;

    let tableLeft = 0;
    let tableRight = 0;
    let tableWidth = 0;
    let tableTop = 0;
    let tableBottom = 0;
    let tableHeight = 0;

    if (numberOfCols) {
      tableLeft = tableDefinitionData.x[0].bounds[0] * props.documentWidth;
      tableRight = tableDefinitionData.x[numberOfCols - 1].bounds[1] * props.documentWidth;
      tableWidth = tableRight - tableLeft;
    }

    if (numberOfRows) {
      tableTop = (1 - tableDefinitionData.y[0].bounds[1]) * props.documentHeight;
      tableBottom = (1 - tableDefinitionData.y[numberOfRows - 1].bounds[0]) * props.documentHeight;
      tableHeight = tableBottom - tableTop;
    }

    if (tableWidth && tableHeight) setShowDocMask(true);

    setTableBounds({
      tableTop: tableTop,
      tableLeft: tableLeft,
      tableWidth: tableWidth,
      tableHeight: tableHeight,
      tableBorderWidth: tableBorderWidth
    })
  }

  React.useEffect(() => {
    getTableData();
  }, [props.documentWidth, tableDefinitionData])

  React.useEffect(() => {
    window.addEventListener("beforeunload", () => localStorage.removeItem("TempCoordinates"));
  }, [])

  const removeColumnName = (columnName: string, columnIndex: number) => {
    availableHeadings.push({ columnName: columnName, removedByUser: true });
    setAvailableHeadings([...availableHeadings]);

    tableDefinitionData.x[columnIndex].type = null;
    setTableDefinitionData({ ...tableDefinitionData });
    props.setDefinitionsEdited && props.setDefinitionsEdited(true);
  }

  const handleOnDragEnd = (result: DropResult) => {
    const updatedHeadings = Array.from(availableHeadings);

    if (!result.destination) return;

    const destination = result.destination?.droppableId;
    const source = result.source.droppableId

    if (destination !== "available-list" && source === destination) return;

    if (destination !== "available-list" && source !== "available-list") {
      const destinationIndex = parseInt(destination);
      const sourceIndex = parseInt(source);
      const sourceItem = tableDefinitionData.x[destinationIndex].type;
      const destinationItem = tableDefinitionData.x[sourceIndex].type;

      tableDefinitionData.x[destinationIndex].type = destinationItem;
      tableDefinitionData.x[sourceIndex].type = null;

      if (sourceItem) updatedHeadings.push({ columnName: sourceItem, removedByUser: true });
      setAvailableHeadings([...updatedHeadings]);
      setTableDefinitionData({ ...tableDefinitionData });
      props.setDefinitionsEdited && props.setDefinitionsEdited(true);
      return
    }

    if (destination === "available-list") {
      if (source === "available-list") {
        const reorderedItem = updatedHeadings.splice(result.source.index, 1);
        updatedHeadings.splice(result.destination.index, 0, reorderedItem[0]);
        setAvailableHeadings([...updatedHeadings])
        return
      }

      updatedHeadings.push({ columnName: result.draggableId, removedByUser: true });
      tableDefinitionData.x[result.source.index].type = null;
      setAvailableHeadings([...updatedHeadings]);
      setTableDefinitionData({ ...tableDefinitionData });
      props.setDefinitionsEdited && props.setDefinitionsEdited(true);
      return
    } else {
      const destinationIndex = parseInt(destination);
      const originalDestinationItem = tableDefinitionData.x[destinationIndex].type;

      updatedHeadings.splice(result.source.index, 1);

      if (originalDestinationItem) {
        updatedHeadings.push({ columnName: originalDestinationItem, removedByUser: true });
      }

      tableDefinitionData.x[destinationIndex].type = result.draggableId;
      setAvailableHeadings([...updatedHeadings]);
      setTableDefinitionData({ ...tableDefinitionData });
      props.setDefinitionsEdited && props.setDefinitionsEdited(true);
    }
  }

  React.useEffect(() => {
    if (props.cancelEditTrigger !== undefined && props.cancelEditTrigger > 0 && props.editTableDefinitions) {
      setShowCancelModal(true);
    }
  }, [props.cancelEditTrigger])

  React.useEffect(() => {
    if (props.previewChangesTrigger !== undefined && props.previewChangesTrigger > 0 && props.editTableDefinitions) {
      if (props.documentId) {
        previewDefinitions();
      }
    }
  }, [props.previewChangesTrigger]);

  const tempStoreLocally = () => {
    const activePage = props.pageIndex.toString();
    let allTableCoordinatesLocal = JSON.parse(localStorage.getItem("TempCoordinates") || "{}");

    if (!allTableCoordinatesLocal[0]) {
      allTableCoordinatesLocal = {
        [activePage]: [tableDefinitionData]
      }
    } else {
      allTableCoordinatesLocal[0][activePage] = tableDefinitionData
    }

    localStorage.setItem("TempCoordinates", JSON.stringify(allTableCoordinatesLocal))
  }

  React.useEffect(() => {
    if (props.applyChangesCurrentTrigger !== undefined && props.applyChangesCurrentTrigger > 0 && props.editTableDefinitions) {
      if (!Object.keys(props.allTableCoordinates)[0]) {
        tempStoreLocally();
      }

      props.setChangesComplete && props.setChangesComplete(true);
    }
  }, [props.applyChangesCurrentTrigger]);

  React.useEffect(() => {
    if (props.applyChangesSubsequentTrigger !== undefined && props.applyChangesSubsequentTrigger > 0 && props.editTableDefinitions) {
      if (Object.keys(props.allTableCoordinates)[0]) {
        Object.keys(props.allTableCoordinates).map((coords, index) => {
          if (index > props.pageIndex && props.allTableCoordinates[index]) {
            props.allTableCoordinates[index][0].x = tableDefinitionData.x;
          }
        });
      } else {
        tempStoreLocally();
      }

      props.setChangesComplete && props.setChangesComplete(true);
    }
  }, [props.applyChangesSubsequentTrigger]);

  React.useEffect(() => {
    if (props.applyChangesAllTrigger !== undefined && props.applyChangesAllTrigger > 0 && props.editTableDefinitions) {
      if (Object.keys(props.allTableCoordinates)[0]) {
        Object.keys(props.allTableCoordinates).map((coords, index) => {
          if (index > props.pageIndex) {
            props.allTableCoordinates[parseInt(coords)][0].x = tableDefinitionData.x;
          }
        });
      } else {
        tempStoreLocally();
      }

      props.setChangesComplete && props.setChangesComplete(true);
    }
  }, [props.applyChangesAllTrigger]);

  const handleCancel = async () => {
    if (props.editTableDefinitions) {
      const allOriginalTableCoordinates = await DocumentGroupAPI.fetchAllTableCoordinates(props.groupId || 0);
      const originalTableCoordinates = allOriginalTableCoordinates[props.documentId || 0][props.pageRelativeIndex] ? allOriginalTableCoordinates[props.documentId || 0][props.pageRelativeIndex][0] : null;

      if (originalTableCoordinates) {
        tableDefinitionData.x = originalTableCoordinates.x
        tableDefinitionData.y = originalTableCoordinates.y
      } else {
        tableDefinitionData.x = [];
        tableDefinitionData.y = [];
      }

      setTableDefinitionData({ ...tableDefinitionData });
      localStorage.removeItem("TempCoordinates");

      setShowCancelModal(false);
      props.setShowEditDefinitions && props.setShowEditDefinitions(false);
      props.reloadGroupData && props.reloadGroupData();
    }
  }

  const previewDefinitions = async () => {
    setShowPreview(true);

    if (!props.allTableCoordinates[props.pageRelativeIndex]) props.allTableCoordinates[props.pageRelativeIndex] = [];
    props.allTableCoordinates[props.pageRelativeIndex][0] = tableDefinitionData;

    const updateData = { ...props.allTableCoordinates };
    updateData[props.pageRelativeIndex][0] = tableDefinitionData;
    
    const response = await DocumentContainerAPI.updateTableCoordinates(props.documentId || 0, updateData, "PREVIEW_PARSER");

    if (response) {
      setReparsedData(response);
    } else {
      props.setNotification(ParserError);
      setShowPreview(false);
    }

    props.setApplyChangesAllTrigger && props.setApplyChangesAllTrigger(0);
    props.setApplyChangesSubsequentTrigger && props.setApplyChangesSubsequentTrigger(0);
    props.setApplyChangesCurrentTrigger && props.setApplyChangesCurrentTrigger(0);
  }

  const saveDefinitions = async () => {
    const updateData = { ...props.allTableCoordinates };
    updateData[props.pageRelativeIndex][0] = tableDefinitionData;
    const response = await DocumentContainerAPI.updateTableCoordinates(props.documentId || 0, updateData, "APPLY_PARSER");

    if (response) {
      props.setShowEditDefinitions && props.setShowEditDefinitions(false);
      props.reloadGroupData && props.reloadGroupData();
      localStorage.removeItem("TempCoordinates");
    } else {
      props.setNotification(ParserError);
      setShowPreview(false);
    }
  }

  const columnConvertColumnName = (columnName: string) => {
    switch (columnName) {
      case "ItemProductCode":
      case "LIProductCode":
        columnName = "Product Code"
        break;
      case "ItemDescription":
      case "LIDescription":
        columnName = "Description"
        break;
      case "ItemCountryOfOrigin":
        columnName = "Origin"
        break;
      case "ItemNumberOfUnits":
        columnName = "QTY"
        break;
      case "ItemUnitType":
        columnName = "Unit"
        break;
      case "ItemUnitPrice":
        columnName = "PPU"
        break;
      case "ItemNetAmount":
        columnName = "Total"
        break;
      case "ItemHsCode":
      case "LIHsCode":
        columnName = "HS Code"
        break;
      case "LIItemQuantity":
        columnName = "Item Quantity"
        break;
      case "LIPackageQuantity":
        columnName = "Package Quantity"
        break;
      case "LIQuantityPerPackage":
        columnName = "Quantity Per Package"
        break;
      case "LIGrossWeight":
        columnName = "Gross Weight"
        break;
      case "LINetWeight":
        columnName = "Net Weight"
        break;
      case "LIVolume":
        columnName = "Volume"
        break;
      case "LIQuantityType":
        columnName = "Quantity Type"
        break;
      default:
        const columnNameSplit = columnName && columnName.split(/(?=[A-Z])/);
        columnName = columnNameSplit ? columnNameSplit.join(" ") : "";
    }

    return columnName;
  }

  const handleAddColumnHover = (e: React.MouseEvent<HTMLDivElement>) => {
    const left = e.clientX;
    setNewColumnPosition(left);
  }

  const handleAddRowHover = (e: React.MouseEvent<HTMLDivElement>) => {
    const top = e.clientY;
    const pdfPageTop = props.pageContainerRef.current.offsetTop + props.pageContainerRef.current.offsetParent.offsetTop;
    setNewRowPosition(top - (tableBounds?.tableTop ? tableBounds?.tableTop : 0) + (props.scrollPosition ? props.scrollPosition : 0) - pdfPageTop);
  }

  const handleDeleteRow = (rowIndex: number, isRowTop: boolean) => {
    const rows = tableDefinitionData.y;
    const currentRow = rows[rowIndex];
    const previousRow = rows[rowIndex - 1];
    const nextRow = rows[rowIndex + 1];

    if (nextRow && previousRow) { // set next row top to be previous row bottom
      nextRow.bounds[1] = previousRow.bounds[0];
    }

    if (!previousRow && nextRow && !isRowTop) {// set next row top to be current row top
      nextRow.bounds[1] = currentRow.bounds[1];
    }

    // remove current row
    rows.splice(rowIndex, 1);
    setTableDefinitionData({ ...tableDefinitionData });
  }

  const handleDeleteColumn = (columnIndex: number, isRowRight: boolean) => {
    const columns = tableDefinitionData.x;
    const currentColumn = columns[columnIndex];
    const previousColumn = columns[columnIndex - 1];
    const currentColumnHeading = currentColumn.type || "";
    const previousColumnHeading = previousColumn ? previousColumn.type : null;

    if (previousColumn && !isRowRight) { // set prev column right to be current columns right
      previousColumn.bounds[1] = currentColumn.bounds[1];
      previousColumn.type = null;
    }

    // remove current column
    columns.splice(columnIndex, 1);

    // Update available headings
    if (currentColumnHeading) availableHeadings.push({ columnName: currentColumnHeading, removedByUser: true });
    if (previousColumnHeading) availableHeadings.push({ columnName: previousColumnHeading, removedByUser: true });

    setAvailableHeadings([...availableHeadings]);
    setTableDefinitionData({ ...tableDefinitionData });
    props.setDefinitionsEdited && props.setDefinitionsEdited(true);
  }

  const handleDeleteAllColumns = () => {
    const columns = tableDefinitionData.x;

    //Update available headings
    columns.map((column) => {
      if (column.type) availableHeadings.push({ columnName: column.type, removedByUser: true })
    });

    setAvailableHeadings([...availableHeadings]);

    const columnsLength = columns.length;
    columns.splice(0, columnsLength);
    setTableDefinitionData({ ...tableDefinitionData });
    props.setDefinitionsEdited && props.setDefinitionsEdited(true);
  }

  const handleDeleteAllRows = () => {
    const rows = tableDefinitionData.y;
    const rowsLength = rows.length;

    rows.splice(0, rowsLength);
    setTableDefinitionData({ ...tableDefinitionData });
  }

  const handleAddRow = (e: React.MouseEvent<HTMLDivElement>) => {
    let newRowBottomCoords;
    let newRowTopCoords;
    let addingBeforeTable = false;
    let addingAfterTable = false;

    const pdfPageTop = props.pageContainerRef.current.offsetTop + props.pageContainerRef.current.offsetParent.offsetTop

    const clickedCoords = 1 - ((e.clientY + props.scrollPosition! - pdfPageTop)) / props.documentHeight;
    let insertIndex = -1;

    const rows = tableDefinitionData.y;

    // handle adding first marker of very first row
    if (rows.length === 0) {
      rows.push({ hasData: true, bounds: [clickedCoords] });
      setTableDefinitionData({ ...tableDefinitionData });
      return;
    }

    // handle adding second marker of very first row
    if (rows.length === 1 && rows[0].bounds.length === 1) {
      const isColMarkerBelow = clickedCoords > rows[0].bounds[0];
      const boundsInsertIndex = isColMarkerBelow ? 1 : 0;
      rows[0].bounds.splice(boundsInsertIndex, 0, clickedCoords);
      setTableDefinitionData({ ...tableDefinitionData });
      return;
    }

    insertIndex = rows.findIndex((row) => {
      if (clickedCoords < row.bounds[1] && clickedCoords > row.bounds[0]) {
        return true;
      }
    });

    if (insertIndex === 0) {
      newRowTopCoords = rows[insertIndex].bounds[1];
      newRowBottomCoords = clickedCoords;
    } else if (insertIndex !== -1) {
      // draw bottom line, make top the bottom of previous row
      newRowBottomCoords = clickedCoords;
      newRowTopCoords = rows[insertIndex - 1].bounds[0];

      // inserting before last row, update last row top coords
      if (insertIndex + 1 === rows.length) {
        rows[insertIndex].bounds[1] = clickedCoords;
      }
    } else if (clickedCoords > rows[0].bounds[1]) {
      // clicked above the table
      // draw top line, make bottom the first row top
      newRowTopCoords = clickedCoords;
      newRowBottomCoords = rows[0].bounds[1];
      insertIndex = 0;
      addingBeforeTable = true;
    } else if (clickedCoords < rows[rows.length - 1].bounds[0]) {
      // clicked below the table
      // draw bottom line, make top the last row bottom
      insertIndex = rows.length;
      newRowTopCoords = rows[rows.length - 1].bounds[0];
      newRowBottomCoords = clickedCoords;
      addingAfterTable = true;
    } else {
      // clicked in the gap between rows coordinates, set index to closest row
      insertIndex = rows.findIndex((row, index, rows) => {
        if (clickedCoords < row.bounds[0] && clickedCoords > rows[index + 1].bounds[1]) {
          return true;
        }
      });

      newRowBottomCoords = clickedCoords;
      newRowTopCoords = rows[insertIndex - 1].bounds[1];
    }

    rows.splice(insertIndex, 0, { hasData: true, bounds: [newRowBottomCoords, newRowTopCoords] });

    if (insertIndex + 1 !== rows.length - 1 && !addingAfterTable && !addingBeforeTable) {
      rows[insertIndex + 1].bounds[1] = newRowBottomCoords;
    }

    setTableDefinitionData({ ...tableDefinitionData });
  }

  const handleAddColumn = (e: React.MouseEvent<HTMLDivElement>) => {
    let newRowLeftCoords;
    let newRowRightCoords;
    let insertIndex = -1;
    let addingAfterTable = false;

    const columns = tableDefinitionData.x;
    const pixelPosition = (e.clientX - documentLeft - pagePreviewWidth / 2);
    const clickedCoords = pixelPosition / props.documentWidth;

    props.setDefinitionsEdited && props.setDefinitionsEdited(true);

    // handle adding first marker of very first column
    if (columns.length === 0) {
      columns.push({ type: null, bounds: [clickedCoords] });
      setTableDefinitionData({ ...tableDefinitionData });
      return;
    }

    // handle adding second marker of very first column
    if (columns.length === 1 && columns[0].bounds.length === 1) {
      const isColMarkerRight = clickedCoords > columns[0].bounds[0];
      const boundsInsertIndex = isColMarkerRight ? 1 : 0;
      columns[0].bounds.splice(boundsInsertIndex, 0, clickedCoords);
      setTableDefinitionData({ ...tableDefinitionData });
      return;
    }

    insertIndex = columns.findIndex((column) => {
      if (clickedCoords < column.bounds[1] && clickedCoords > column.bounds[0]) {
        return true;
      }
    })

    if (insertIndex === 0 && columns.length === 1) {
      // only  1 column exists
      newRowLeftCoords = clickedCoords;
      newRowRightCoords = columns[insertIndex].bounds[1];
      columns[insertIndex].bounds[1] = clickedCoords;
      insertIndex = 1;
    } else if (insertIndex === 0) {
      newRowLeftCoords = columns[insertIndex].bounds[0];
      newRowRightCoords = clickedCoords;
    } else if (insertIndex !== -1) {
      newRowRightCoords = clickedCoords;
      newRowLeftCoords = columns[insertIndex - 1].bounds[1];

      // inserting before last col, update last col left coords
      if (insertIndex + 1 === columns.length) {
        columns[insertIndex].bounds[0] = clickedCoords;
      }
    } else if (clickedCoords > columns[columns.length - 1].bounds[1]) {
      // clicked to the right of table
      newRowRightCoords = clickedCoords;
      newRowLeftCoords = columns[columns.length - 1].bounds[1];
      insertIndex = columns.length;
      addingAfterTable = true;
    } else if (clickedCoords < columns[0].bounds[0]) {
      // clicked to the left of table
      newRowRightCoords = columns[0].bounds[0];
      newRowLeftCoords = clickedCoords;
      insertIndex = 0;
    } else {
      return
    }

    columns.splice(insertIndex, 0, { type: null, bounds: [newRowLeftCoords, newRowRightCoords] });

    if (insertIndex + 1 !== columns.length - 1 && !addingAfterTable) {
      columns[insertIndex + 1].bounds[0] = newRowRightCoords;
    }

    setTableDefinitionData({ ...tableDefinitionData });
  }

  const handleOnStopDragRow = (data: DraggableData, currentRowPosition: number, delimeterOffset: number, rowIndex: number, isRowTop: boolean) => {
    const droppedDistance = data.y;
    const newRowCoords = 1 - (currentRowPosition + droppedDistance + delimeterOffset) / props.documentHeight;
    const rows = tableDefinitionData.y;

    if (isRowTop) {
      rows[rowIndex].bounds[1] = newRowCoords
    } else {
      rows[rowIndex].bounds[0] = newRowCoords
      if (rows[rowIndex + 1]) rows[rowIndex + 1].bounds[1] = newRowCoords
    }

    setDefaultDraggablePosition({ x: 0, y: 0 })
    setTableDefinitionData({ ...tableDefinitionData });
  }

  const handleOnStopDragColumn = (data: DraggableData, currentColumnPosition: number, delimeterOffset: number, columnIndex: number, isColumnRight: boolean) => {
    const droppedDistance = data.x;
    const newColumnCoords = ((currentColumnPosition + droppedDistance + delimeterOffset) / props.documentWidth);
    const columns = tableDefinitionData.x;

    if (isColumnRight) {
      columns[columnIndex].bounds[1] = newColumnCoords
    } else {
      columns[columnIndex].bounds[0] = newColumnCoords
      if (columns[columnIndex - 1]) columns[columnIndex - 1].bounds[1] = newColumnCoords
    }

    setDefaultDraggablePosition({ x: 0, y: 0 })
    setTableDefinitionData({ ...tableDefinitionData });
    props.setDefinitionsEdited && props.setDefinitionsEdited(true);
  }

  const handleRowSelection = (e: React.ChangeEvent, index: number) => {
    const input = e.currentTarget as HTMLInputElement
    const isRowSelected = input.checked

    tableDefinitionData.y[index].hasData = isRowSelected;

    setTableDefinitionData({ ...tableDefinitionData });
  }

  return (
    <>
      <ConfirmModal
        show={showCancelModal}
        title="Are you sure?"
        text={`
          Any changes made will be lost.<br />
          <br />
          Do you still want to discard? <br />`
        }
        onHide={() => setShowCancelModal(false)}
        onConfirm={() => handleCancel()}
      />
      <ConfirmModal
        show={showConfirmRemoveRowsModal}
        title={`Remove all row definitions?`}
        onHide={() => setShowConfirmRemoveRowsModal(false)}
        onConfirm={() => {
          handleDeleteAllRows();
          setShowConfirmRemoveRowsModal(false);
        }}
      />
      <ConfirmModal
        show={showConfirmRemoveColumnsModal}
        title={`Remove all column definitions?`}
        onHide={() => setShowConfirmRemoveColumnsModal(false)}
        onConfirm={() => {
          handleDeleteAllColumns();
          setShowConfirmRemoveColumnsModal(false);
        }}
      />
      <ConfirmModal
        show={showConfirmRemoveAllModal}
        title={`Remove all definitions?`}
        onHide={() => setShowConfirmRemoveAllModal(false)}
        onConfirm={() => {
          handleDeleteAllRows();
          handleDeleteAllColumns();
          setShowConfirmRemoveAllModal(false);
        }}
      />
      {props.editTableDefinitions && showPreview && (
        <TableDefinitionsPreview
          tableDefinitions={tableDefinitionData}
          pdfDocument={props.pdfDocument}
          setNotification={props.setNotification}
          showSpinner={props.showSpinner}
          documentId={props.documentId}
          specificId={props.specificId}
          filePages={props.filePages}
          pageIndex={props.pageIndex}
          setShowPreview={setShowPreview}
          setShowEditDefinitions={props.setShowEditDefinitions}
          reparsedData={reparsedData}
          documentType={props.documentType || 5}
          saveDefinitions={saveDefinitions}
          setPreviewChangesTrigger={props.setPreviewChangesTrigger}
        />
      )}

      <DragDropContext onDragEnd={handleOnDragEnd} autoScrollerOptions={{ disabled: true }}>
        <div
          className={`table-highlight ${props.editTableDefinitions ? "edit-mode" : ""} ${props.previewTableDefinitions ? "preview-mode" : ""} ${hideAddRowOptions ? "add-row-prohibited" : ""} ${hideAddColumnOptions ? "add-column-prohibited" : ""} ${showDocMask ? "show-doc-mask" : ""}`}
          style={{
            top: tableBounds && tableBounds.tableTop - tableBounds.tableBorderWidth,
            left: tableBounds && tableBounds.tableLeft - tableBounds.tableBorderWidth,
            width: tableBounds && tableBounds.tableWidth,
            height: tableBounds && tableBounds.tableHeight,
            borderWidth: tableBounds && tableBounds.tableBorderWidth
          }}
          onMouseMove={(e) => {
            if (props.editTableDefinitions) {
              handleAddColumnHover(e)
              handleAddRowHover(e)
            }
          }}
          onMouseEnter={() => {
            if (props.editTableDefinitions) {
              if (hideAddColumnOptions) {
                setHideAddColumnOptions(false)
              }

              if (hideAddRowOptions) {
                setHideAddRowOptions(false)
              }
            }
          }}
        >
          {!props.editTableDefinitions && !props.previewTableDefinitions && (
            <div className='edit-button-wrap'>
              <button className='full-button' onClick={() => {
                props.openEditView && props.openEditView(props.pageRelativeIndex)
              }}>
                <ShipamaxTableIcon />
                Edit Table
              </button>
            </div>
          )}

          {props.editTableDefinitions && (
            <>
              <div
                className="column-actions-panel"
                onMouseEnter={() => {
                  setHideAddRowOptions(true);
                  setHideAddColumnOptions(false)
                }}
              >
                {tableDefinitionData.x.length === 0 && (
                  <span className="add-instruction add-col-instruction">
                    Add Column
                    <ShipamaxPlusIcon />
                  </span>
                )}
              </div>
              <div
                className="row-actions-panel"
                onMouseEnter={() => {
                  setHideAddColumnOptions(true);
                  setHideAddRowOptions(false)
                }}
              >
                {tableDefinitionData.y.length === 0 && (
                  <span className="add-instruction add-row-instruction">
                    Add Row
                    <ShipamaxPlusIcon />
                  </span>
                )}
              </div>
              <div
                className="row-selection-panel"
                onMouseEnter={() => {
                  setHideAddColumnOptions(true);
                  setHideAddRowOptions(true)
                }}
              ></div>

              <div className="new-row-marker row-marker" style={{ top: newRowPosition, left: tableBounds ? -(tableBounds.tableLeft + documentLeft) : 0 }}>
                <div
                  className="add-delimeter add-row delimeter-action"
                  onMouseEnter={() => setHideAddColumnOptions(true)}
                  onClick={(e) => handleAddRow(e)}
                >
                  <TooltipTrigger tooltip="Add Row Divider" placement="left">
                    <ShipamaxPlusIcon />
                  </TooltipTrigger>
                </div>
              </div>
            </>
          )}

          <div className='column-headings-wrapper'>
            {props.editTableDefinitions && (
              <>
                <button
                  className='light-button show-hide-page-navigator'
                  onClick={() => { props.setShowPageNavigator && props.setShowPageNavigator(!props.pageNavigatorShowing) }}
                  onMouseEnter={() => setHideAddColumnOptions(true)}
                >
                  <ShipamaxDownIcon />
                </button>
                <div className="new-column-marker column-marker" style={{ top: 0, left: newColumnPosition - pagePreviewWidth }}>
                  <div className="add-delimeter add-column delimeter-action"
                    onMouseEnter={() => setHideAddRowOptions(true)}
                    onClick={(e) => handleAddColumn(e)}
                  >
                    <TooltipTrigger tooltip="Add Column Divider">
                      <ShipamaxPlusIcon />
                    </TooltipTrigger>
                  </div>
                </div>
              </>
            )}

            {tableDefinitionData.x.map((col, index, cols) => {
              const delimeterOffset = props.editTableDefinitions ? -documentLeft + pagePreviewWidth / 2 : tableBounds!.tableLeft;
              const columnLeft = (col.bounds[0] * props.documentWidth) - delimeterOffset;
              const columnRight = (col.bounds[1] * props.documentWidth) - delimeterOffset;
              const columnName = columnConvertColumnName(col.type || "");

              const previousColumn = cols[index - 1];
              const previousColumnLeft = previousColumn ? (previousColumn.bounds[0] * props.documentWidth) - delimeterOffset : 0;

              const nextColumn = cols[index + 1]
              const nextColumnLeft = nextColumn ? (nextColumn.bounds[0] * props.documentWidth) - delimeterOffset : 0;

              const columnLeftDragConstraint = previousColumn ? -(columnLeft - previousColumnLeft - dragBufferDistance) : -(columnLeft - documentLeft);
              const columnRightDragConstraint = nextColumn ? (nextColumnLeft - columnLeft - dragBufferDistance) : (columnRight - columnLeft - dragBufferDistance);

              const rightColumnLeftDragConstraint = -(columnRight - columnLeft - dragBufferDistance)
              const rightColumnRightDragConstraint = (documentLeft + props.documentWidth) - columnRight;

              availableHeadings.map((heading, index) => {
                if (heading.columnName === col.type && !heading.removedByUser) {
                  availableHeadings.splice(index, 1);
                }
              });

              return (
                <>
                  <Droppable key={`drop-heading`} droppableId={`${index}`}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`column-heading-wrap ${snapshot.isDraggingOver ? "dragging-over" : ""}`}
                        style={{
                          left: columnLeft + 2,
                          width: (columnRight - columnLeft) - 2
                        }}
                        onMouseEnter={() => setHideAddColumnOptions(true)}
                        onMouseLeave={() => setHideAddColumnOptions(false)}
                      >
                        {columnName ? (
                          <TooltipTrigger tooltip={columnName}>
                            <DraggableDND
                              key={`heading-${index}`}
                              draggableId={col.type || ""}
                              index={index}
                              isDragDisabled={!props.editTableDefinitions}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    {...provided?.draggableProps}
                                    {...provided?.dragHandleProps}
                                    ref={provided?.innerRef}
                                    title={columnName}
                                    className={`column-heading ${snapshot.isDragging ? "dragging" : ""}`}
                                    onMouseEnter={() => setHideAddColumnOptions(true)}
                                  >
                                    {props.editTableDefinitions ? (
                                      <>
                                        <ShipamaxDragIcon />
                                        {columnName}
                                        <ShipamaxTimesIcon className="remove-column-heading" onClick={() => removeColumnName(col.type || "", index)} />
                                      </>
                                    ) : (
                                      <>
                                        {columnName}
                                      </>
                                    )}
                                  </div>
                                )
                              }}
                            </DraggableDND>
                          </TooltipTrigger>
                        ) : (
                          <>
                            {
                              props.editTableDefinitions && (
                                <div className="column-heading-placeholder"></div>
                              )
                            }
                          </>
                        )}
                      </div>
                    )}
                  </Droppable>

                  {((!props.editTableDefinitions && index !== 0) || props.editTableDefinitions) && (
                    <Draggable
                      handle=".handle"
                      defaultPosition={defaultDraggablePosition}
                      position={defaultDraggablePosition}
                      axis={"x"}
                      bounds={{ top: 0, left: columnLeftDragConstraint, right: columnRightDragConstraint, bottom: 0 }}
                      onStop={(e, data) => handleOnStopDragColumn(data, columnLeft, delimeterOffset, index, false)}
                    >
                      <div
                        className="column-marker"
                        id={`column-marker-${index}`}
                        style={{
                          left: columnLeft,
                        }}
                        onMouseEnter={() => {
                          setHideAddRowOptions(true);
                          setHideAddColumnOptions(true);
                        }}
                        onMouseLeave={() => {
                          setHideAddRowOptions(false);
                          setHideAddColumnOptions(false);
                        }}
                      >
                        {props.editTableDefinitions && (
                          <>
                            <div className="handle"></div>
                            <div
                              className="delete-column delete-delimeter delimeter-action"
                              onClick={() => handleDeleteColumn(index, false)}
                              onMouseEnter={() => {
                                setHideAddRowOptions(true);
                                setHideAddColumnOptions(true);
                              }}
                            >
                              <TooltipTrigger tooltip="Delete Column Divider">
                                <ShipamaxTimesIcon />
                              </TooltipTrigger>
                            </div>
                          </>
                        )}
                      </div>
                    </Draggable>
                  )}

                  {props.editTableDefinitions && index === tableDefinitionData.x.length - 1 && !isNaN(columnRight) && (
                    <Draggable
                      handle=".handle"
                      defaultPosition={defaultDraggablePosition}
                      position={defaultDraggablePosition}
                      axis={"x"}
                      bounds={{ top: 0, left: rightColumnLeftDragConstraint, right: rightColumnRightDragConstraint, bottom: 0 }}
                      onStop={(e, data) => handleOnStopDragColumn(data, columnRight, delimeterOffset, index, true)}
                    >
                      <div
                        className="column-marker"
                        id={`column-marker-${index}`}
                        style={{
                          left: columnRight
                        }}
                        onMouseEnter={() => {
                          setHideAddRowOptions(true);
                          setHideAddColumnOptions(true);
                        }}
                        onMouseLeave={() => {
                          setHideAddRowOptions(false);
                          setHideAddColumnOptions(false);
                        }}
                      >
                        {props.editTableDefinitions && (
                          <>
                            <div className="handle"></div>
                            <div
                              className="delete-column delete-delimeter delimeter-action"
                              onClick={() => handleDeleteColumn(index, true)}
                              onMouseEnter={() => {
                                setHideAddRowOptions(true)
                                setHideAddColumnOptions(true)
                              }}
                            > <TooltipTrigger tooltip="Delete Column Divider">
                                <ShipamaxTimesIcon />
                              </TooltipTrigger>
                            </div>
                          </>
                        )}
                      </div>
                    </Draggable >
                  )}
                </>
              )
            })}
          </div>

          {tableDefinitionData.y.map((row, index, rows) => {
            const delimeterOffset = tableBounds!.tableTop;
            const currentRowTop = ((1 - row.bounds[1]) * props.documentHeight) - delimeterOffset;
            const currentRowBottom = ((1 - row.bounds[0]) * props.documentHeight) - delimeterOffset;

            const nextRow = rows[index + 1];
            const nextRowTop = nextRow ? ((1 - nextRow.bounds[1]) * props.documentHeight) - delimeterOffset : currentRowBottom;
            const nextRowBottom = nextRow ? ((1 - nextRow.bounds[0]) * props.documentHeight) - delimeterOffset : currentRowBottom;

            const rowDiff = (nextRowTop - currentRowBottom) / 2;
            const rowPosition = (currentRowBottom + rowDiff);

            if (!props.editTableDefinitions && index === rows.length - 1) return;

            const rowDragTopConstraint = -(rowPosition - currentRowTop - dragBufferDistance);
            const rowDragBottomConstraint = nextRow ? (nextRowBottom - rowPosition - dragBufferDistance) : (props.documentHeight - rowPosition - tableBounds!.tableTop);

            const topRowDragTopConstraint = -tableBounds!.tableTop;

            return (
              <>
                {props.editTableDefinitions && index === 0 && !isNaN(currentRowTop) && (
                  <Draggable
                    handle=".handle"
                    defaultPosition={defaultDraggablePosition}
                    position={defaultDraggablePosition}
                    axis={"y"}
                    bounds={{ top: topRowDragTopConstraint, left: 0, right: 0, bottom: rowDragBottomConstraint }}
                    onStop={(e, data) => handleOnStopDragRow(data, currentRowTop, delimeterOffset, index, true)}
                  >
                    <div
                      className="row-marker"
                      id={`row-marker-${index}`}
                      style={{
                        top: currentRowTop,
                        left: !props.editTableDefinitions ? 0 : tableBounds?.tableLeft && -(tableBounds.tableLeft + documentLeft)
                      }}
                      onMouseEnter={() => {
                        setHideAddRowOptions(true);
                        setHideAddColumnOptions(true);
                      }}
                      onMouseLeave={() => {
                        setHideAddRowOptions(false);
                        setHideAddColumnOptions(false);
                      }}
                    >
                      {props.editTableDefinitions && (
                        <>
                          <div className="handle"></div>
                          <div
                            className="delete-row delete-delimeter delimeter-action"
                            onClick={() => handleDeleteRow(index, true)}
                            onMouseEnter={() => {
                              setHideAddRowOptions(true);
                              setHideAddColumnOptions(true);
                            }}
                          >
                            <TooltipTrigger tooltip="Delete Row Divider" placement="left">
                              <ShipamaxTimesIcon />
                            </TooltipTrigger>
                          </div>
                        </>
                      )}
                    </div>
                  </Draggable>
                )}
                <Draggable
                  handle=".handle"
                  defaultPosition={defaultDraggablePosition}
                  position={defaultDraggablePosition}
                  axis={"y"}
                  bounds={{ top: rowDragTopConstraint, left: 0, right: 0, bottom: rowDragBottomConstraint }}
                  onStop={(e, data) => handleOnStopDragRow(data, rowPosition, delimeterOffset, index, false)}
                >
                  <div
                    className="row-marker"
                    id={`row-marker-${index}`}
                    style={{
                      top: (index === rows.length - 1) ? currentRowBottom : rowPosition,
                      left: !props.editTableDefinitions ? 0 : tableBounds?.tableLeft && -(tableBounds.tableLeft + documentLeft)
                    }}
                    onMouseEnter={() => {
                      setHideAddRowOptions(true);
                      setHideAddColumnOptions(true);
                    }}
                    onMouseLeave={() => {
                      setHideAddRowOptions(false);
                      setHideAddColumnOptions(false);
                    }}
                  >
                    {props.editTableDefinitions && (
                      <>
                        <div className="handle"></div>
                        <div
                          className="delete-row delete-delimeter delimeter-action"
                          onClick={() => handleDeleteRow(index, false)}
                          onMouseEnter={() => {
                            setHideAddRowOptions(true);
                            setHideAddColumnOptions(true);
                          }}
                        >
                          <TooltipTrigger tooltip="Delete Row Divider" placement="left">
                            <ShipamaxTimesIcon />
                          </TooltipTrigger>
                        </div>
                      </>
                    )}
                  </div>
                </Draggable>
                {props.editTableDefinitions && (
                  <div
                    className="row-selection"
                    style={{
                      position: "absolute", top: rowPosition - ((rowPosition - currentRowTop) / 2),
                      left: -(tableBounds!.tableLeft + documentLeft - 20 - pagePreviewWidth / 2)
                    }}
                    onMouseEnter={() => {
                      setHideAddRowOptions(true);
                      setHideAddColumnOptions(true);
                    }}>
                    <input
                      id={`selectRow${index}`}
                      type="checkbox"
                      onChange={(e) => handleRowSelection(e, index)}
                      defaultChecked={row.hasData}
                    />
                    <label htmlFor={`selectRow${index}`}><ShipamaxTickIcon /></label>
                  </div>
                )}
              </>
            )
          })}
        </div>
        {(props.editTableDefinitions && !showPreview) && (
          <>
            <div className={`unused-column-labels ${unusedLabelsOpen ? "open" : ""}`}>
              <div className='unused-column-labels-header' onClick={() => setUnusedLabelsOpen(!unusedLabelsOpen)}>
                {availableHeadings.length} unused labels
                <ShipamaxUpIcon />
              </div>
              <p>Drag the labels to the correct columns</p>
              <Droppable key={`drop-available`} droppableId='available-list'>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className={`available-labels ${snapshot.isDraggingOver ? "dragging-over" : ""}`}>

                    {availableHeadings.length ? availableHeadings.map((heading, index) => {
                      const columnName = columnConvertColumnName(heading.columnName);

                      return (
                        <TooltipTrigger key={`ttavailable-${index}`} tooltip={columnName}>
                          <DraggableDND
                            key={heading.columnName}
                            draggableId={heading.columnName}
                            index={index}
                            isDragDisabled={false}
                          >
                            {(provided, snapshot) => {
                              return (
                                <div
                                  {...provided?.draggableProps}
                                  {...provided?.dragHandleProps}
                                  ref={provided?.innerRef}
                                  title={columnName}
                                  className={`column-heading ${snapshot.isDragging ? "dragging" : ""}`}
                                  data-key={tableDefinitionData.x.length + index}
                                >
                                  <ShipamaxDragIcon />
                                  {columnName}
                                </div>
                              );
                            }}
                          </DraggableDND>
                        </TooltipTrigger>
                      );
                    }) :
                      <div className="column-heading-placeholder"></div>}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className='bulk-table-actions'>
              <TooltipTrigger tooltip="Remove All Rows">
                <button
                  className='light-button'
                  onClick={() => setShowConfirmRemoveRowsModal(true)}
                >
                  <ShipamaxDeleteTableRowsIcon />
                </button>
              </TooltipTrigger>
              <TooltipTrigger tooltip="Remove All Columns">
                <button
                  className='light-button'
                  onClick={() => setShowConfirmRemoveColumnsModal(true)}
                >
                  <ShipamaxDeleteTableColsIcon />
                </button>
              </TooltipTrigger>
              <TooltipTrigger tooltip="Remove All Definitions">
                <button
                  className='light-button'
                  onClick={() => setShowConfirmRemoveAllModal(true)}
                >
                  <ShipamaxDeleteTableIcon />
                </button>
              </TooltipTrigger>
            </div>
          </>
        )}
      </DragDropContext >
    </>
  )
}
