import { APICommercialInvoiceLineItem, APIPackingListLineItem } from "../../../api/documentGroup";
import { RecordValidator } from "../../common/Form";
import { formatNumber } from "../../common/NumberInput";
import { isCILineItem } from "./LineItems";

export interface LineItemError {
  type: 'warning' | 'error';
  error: string | false,
}

export interface LineItemErrors {
  productCodeLimit?: LineItemError;
  descriptionLimit?: LineItemError;
  totalLength?: LineItemError;
  totalAmount?: LineItemError;
}

const LINE_ITEM_DESC_LENGTH_LIMIT = 525;
const LINE_ITEM_CODE_LENGTH_LIMIT = 35;
const LINE_ITEM_TOTAL_LENGTH_LIMIT = 999999999999999;

const isLineTotalInvalid = (row: APICommercialInvoiceLineItem) => {
  if (!row.description && !row.productCode && !row.matched) return false;
  if (!row.quantity || !row.unitPrice || !row.lineTotal) return true;
  return formatNumber(row.quantity * row.unitPrice, {
    precision: 2,
  }) !== formatNumber(row.lineTotal, {
    precision: 2
  });
}

export const commercialInvoiceLineItemsErrors = (
  lineItem: APICommercialInvoiceLineItem | APIPackingListLineItem,
  isNonCargowise?: boolean,
): { [key: string]: LineItemErrors } => {
  const validationMessages = {} as any;
  validationMessages[lineItem.id] = {
    productCodeLimit: {
      type: 'warning',
      error: !isNonCargowise && !lineItem.matched && lineItem.productCode && lineItem.productCode.length > LINE_ITEM_CODE_LENGTH_LIMIT ?
        `Warning: value exceeds ${LINE_ITEM_CODE_LENGTH_LIMIT} characters. It will be truncated when uploaded to Cargowise` :
        false,
    },
    descriptionLimit: {
      type: 'warning',
      error: !isNonCargowise && !lineItem.matched && lineItem.description && lineItem.description.length > LINE_ITEM_DESC_LENGTH_LIMIT ?
        `Warning: Value exceeds ${LINE_ITEM_DESC_LENGTH_LIMIT} characters. It will be truncated when uploaded to Cargowise` :
        false,
    },
    totalLength: {
      type: 'error',
      error: (!isNonCargowise && isCILineItem(lineItem) && lineItem.lineTotal && lineItem.lineTotal > LINE_ITEM_TOTAL_LENGTH_LIMIT) ?
        `Error: Value must be less than ${LINE_ITEM_TOTAL_LENGTH_LIMIT}` :
        false,
    },
    totalAmount: {
      type: 'warning',
      error: (isCILineItem(lineItem) && isLineTotalInvalid(lineItem)) ? 'Warning: Line total does not equal QTY x PPU' : false,
    },
  } as LineItemErrors;
  return validationMessages;
};

export const lineItemsHaveErrorsAndWarnings = (lineItems: APICommercialInvoiceLineItem[] | undefined,
  isNonCargowise?: boolean): { hasWarnings: boolean, hasErrors: boolean } => {
  let hasWarnings = false;
  let hasErrors = false;
  let lineItemErrs: any = {};
  if (lineItems) {
    lineItems.forEach((li: APICommercialInvoiceLineItem | APIPackingListLineItem) => {
      lineItemErrs = {
        ...lineItemErrs, ...commercialInvoiceLineItemsErrors(li, isNonCargowise)
      };
    });
    Object.keys(lineItemErrs).forEach((liKey) => {
      Object.keys(lineItemErrs[liKey]).forEach((errKey) => {
        const feError = lineItemErrs[liKey][errKey] as LineItemError;
        if (feError.error) {
          if (feError.type === 'warning') {
            hasWarnings = true;
          } else {
            hasErrors = true;
          }
        }
      });
    });
  }
  return { hasWarnings, hasErrors };
}

export const checkIfRecordValidatorHasErrors = (validator: RecordValidator): boolean => {
  let hasErrors = false;
  Object.keys(validator).forEach((key) => {
    validator[key].validators.forEach((v) => {
      if (!v.isValid('')) hasErrors = true;
    });
  });
  return hasErrors;
}
