import * as React from 'react';
import { GroupDetails } from './group-details/GroupDetails';
import { APIDocumentGroupConsol, APIDocumentGroupDetails, APIDocumentGroupDocument, APIDocumentGroupSearchDetails, DocumentGroupAPI } from '../../api/documentGroup';
import { Notification, APIError, EventNotificationRequest } from '../common/Notification';
import { ConfirmModal, ConfirmModalProps } from '../common/ConfirmModal';
import { OptionValue } from 'react-selectize';
import { useHistory, useParams } from 'react-router-dom';
import { APICwReferenceData } from '../../api/company';
import {
  APIDocCoordinate,
  APITableDefinitions,
  APIDocumentContainer,
  APIDocumentType,
  blTypes,
  CoordinatesFilter, DocumentContainerAPI, filterCoordinates
} from '../../api/documentContainer';
import { APITeam } from '../../api/teams';
import { APIUser, TeamsMap, UsersMap } from '../../api/comment';
import { FileSorterContainer } from './file-sorter/FileSorterContainer';
import { findInChildren, useKeyPress } from '../helpers';
import { IssueCodeAPI, APIIssueCode } from '../../api/issueCode';
import { ModalMode, CodeSelectionModal, CodeSelectionModalProps } from '../common/CodeSelectionModal';
import { APIEmailBasic } from '../../api/email';
import { DocumentViewerContext } from '../DocumentViewerContext';
import { FieldChangedParams, IssuerDetailMap, PostingBehaviour, StatusCode } from './common';
import { StatusBar } from './group-details/StatusBar';
import { getFlatFiles } from './file-sorter/FileSorter';
import { DocumentType } from '../../api/documentContainer';
import { APIActivityLog } from '../../api/activityLog';
import { ActivityFeedHandle } from '../common/activity-feed/ActivityFeed';
import { OverlayType } from './GroupSection';

interface Props {
  show: boolean;
  details: APIDocumentGroupDetails | null;
  fileSorterData: APIDocumentGroupSearchDetails | null;
  teams: APITeam[];
  users: APIUser[];
  teamsMap: TeamsMap;
  usersMap: UsersMap;
  issuerDetails: IssuerDetailMap;
  cwReferenceData: APICwReferenceData | null;
  vesselOptions: OptionValue[];
  countryOptions: OptionValue[];
  currencyOptions: OptionValue[];
  documentTypes: APIDocumentType[];
  creditorDetailsMap: IssuerDetailMap;
  enableTableCoordinates?: boolean;
  setShow: (value: boolean) => void;
  setNotification: (notification: Notification | null) => void;
  reloadDetailsForGroup: (id: number, reloadBoards?: boolean) => Promise<void>;
  setOverlay: (type: OverlayType) => void;
  reloadGroupData: () => Promise<void>;
  fieldChanged: (changes: FieldChangedParams[]) => void;
  reloadDashboard: () => void;
  fetchRelatedData: (allGroups: APIDocumentGroupSearchDetails[]) => void;
  hideGroup: (id: number) => void;
  addEventsToListen: (event: EventNotificationRequest) => void;
  removeGroupNotifications: (groupId: number) => void;
}

export const DetailView: React.FC<Props> = (props) => {
  const [activeDocument, setActiveDocument] = React.useState<APIDocumentContainer | APIEmailBasic | undefined>(undefined);
  const [confirmModalProps, setConfirmModalProps] = React.useState<ConfirmModalProps>({ show: false });
  const [issueCodes, setIssueCodes] = React.useState<APIIssueCode[]>([]);
  const [codeSelectionModalProps, setCodeSelectionModalProps] = React.useState<CodeSelectionModalProps>({ show: false, mode: ModalMode.Discard });
  const activityFeedRef = React.useRef<ActivityFeedHandle | null>(null);
  const [placeholderJobRef, setPlaceholderJobRef] = React.useState<string>('');
  const [modalEnabled, setModalEnabled] = React.useState<boolean>(false);

  const [focusedCoordinates, setFocusedCoordinates] = React.useState<APIDocCoordinate[]>([]);
  const [allCoordinates, setAllCoordinates] = React.useState<{ [id: string]: APIDocCoordinate[] }>({});
  const [allTableCoordinates, setAllTableCoordinates] = React.useState<{ [id: string]: APITableDefinitions }>({});
  const [tableCoordinates, setTableCoordinates] = React.useState<APITableDefinitions>([]);
  const [coordinates, setCoordinates] = React.useState<APIDocCoordinate[]>([]);

  const history = useHistory();
  const { section }: {
    idView: string | undefined; section?: string
  } = useParams(); useParams();

  history.listen(() => {
    if (history.action === "POP") closeDetailsView();
  });

  React.useEffect(() => {
    if (coordinates && coordinates.length) {
      const currentCoordinateDocId = coordinates[0].documentId;
      setCoordinates(allCoordinates[currentCoordinateDocId]);
    }
  }, [allCoordinates]);

  React.useEffect(() => {
    getIssueCodesFromAPI();
  }, []);

  React.useEffect(() => {
    fetchCoordinates();
    props.fetchRelatedData([props.details as APIDocumentGroupSearchDetails]);
    if (activeDocument) scrollToDocumentCard(activeDocument.id);
  }, [props.details]);

  React.useEffect(() => {
    displayCoordinates();
  }, [activeDocument, allCoordinates]);

  React.useEffect(() => {
    displayTableCoordinates();
  }, [activeDocument, allTableCoordinates]);

  const displayTableCoordinates = () => {
    if (activeDocument) setTableCoordinates(allTableCoordinates[activeDocument.id]);
  }

  useKeyPress('Escape', () => {
    const isWhiteListedElementFocused = document.activeElement?.closest('.react-selectize') ||
      document.activeElement?.closest('.line-items-grid');
    const isFileSplitterOpen = document.getElementsByClassName('file-splitter').length;
    const isFileActionsModalOpen = document.querySelectorAll('.detail-file-actions').length;
    const isDocumentAssignmentWindowVisible = document.querySelectorAll('.document-assignment__window').length;
    const isActivityFeedNotificationVisible = document.querySelectorAll('.activity-feed--notification').length;
    const isAnyModalOpen = confirmModalProps.show || codeSelectionModalProps.show;

    if (
      !isFileActionsModalOpen &&
      !isWhiteListedElementFocused &&
      !isFileSplitterOpen &&
      !isDocumentAssignmentWindowVisible &&
      !isActivityFeedNotificationVisible &&
      !isAnyModalOpen
    ) {
      closeDetailsView();
    }
  }, [document.activeElement, confirmModalProps]);

  const flatFiles = getFlatFiles(props.fileSorterData?.documents || []);

  const disabledNewBjob = React.useMemo(() => {
    if (flatFiles.filter((d) => DocumentType.MasterBillOfLading === d.documentType).length > 1 ||
      flatFiles.filter((d) => DocumentType.HouseBillOfLading === d.documentType).length > 1) {
      return true;
    }
    return false;
  }, [props.fileSorterData?.documents]);

  let hasMbl = false;
  let hasHbl = false;
  flatFiles.forEach(doc => {
    if (doc.documentType === DocumentType.MasterBillOfLading) {
      hasMbl = true;
    }
    if (doc.documentType === DocumentType.HouseBillOfLading) {
      hasHbl = true;
    }
  });

  const hasBothBills = hasHbl && hasMbl;

  const getIssueCodesFromAPI = async () => {
    let result = await IssueCodeAPI.fetchAll();

    if (result === null) {
      props.setNotification({ ...APIError, details: {}, reason: `Error fetching issues codes form API, resulted null` });
      result = [];
    }

    setIssueCodes(result);
  }

  const setConfirmModal = (props: ConfirmModalProps) => {
    setConfirmModalProps(props);
  }

  const setDiscardCodesModal = (props: CodeSelectionModalProps) => {
    setCodeSelectionModalProps(props);
  }

  const closeModal = () => {
    setConfirmModalProps({ show: false });
  }

  const closeDiscardModal = () => {
    setCodeSelectionModalProps({ ...codeSelectionModalProps, show: false });
  }

  const closeDetailsView = () => {
    props.setShow(false);
    history.push(`/${location.pathname.split('/')[1]}`);
  }

  const setShowOverlay = (enabled: boolean) => {
    props.setOverlay(enabled ? OverlayType.Default : OverlayType.Hidden);
  }
  const fetchCoordinates = async () => {
    if (props.details?.id) {
      const allCoordinates = await DocumentGroupAPI.fetchAllCoordinates(props.details?.id);
      setAllCoordinates(allCoordinates || {});

      if (props.enableTableCoordinates) {
        const allTableCoordinates = await DocumentGroupAPI.fetchAllTableCoordinates(props.details?.id);
        setAllTableCoordinates(allTableCoordinates || {})
      }
    }
  }

  const scrollToDocumentCard = (documentId: number) => {
    let cardDiv = document.getElementById(`card-${documentId}`);
    if (!cardDiv) cardDiv = document.querySelector(`div[secundary-id="card-${documentId}"]`);
    cardDiv?.scrollIntoView({ behavior: "smooth" });
  }

  const displayCoordinates = () => {
    if (activeDocument && allCoordinates) {
      if ('subject' in activeDocument) {
        return setCoordinates([]);
      }

      const isMasterBL = activeDocument.documentType === DocumentType.MasterBillOfLading;
      const isHouseBL = activeDocument.documentType === DocumentType.HouseBillOfLading;

      const filteredCoordinates = allCoordinates[activeDocument.id]?.filter((coordinate) => {
        const { attributeName, parentTableName } = coordinate;
        if (isMasterBL || isHouseBL) { // any bol
          if (['total_weight', 'total_volume', 'scac'].includes(attributeName)) {
            return false;
          } else if (attributeName === 'container_number' && ['bol_header', 'bol_container_seals'].includes(parentTableName)) {
            // Parser is currently sending 4 container coordinates
            // bol_header, bol_containers_seals, bol_container, bol_pack_lines
            // only the last 2 are used in the screen on different cases
            return false;
          }
        }
        switch (props.details?.postingBehaviour) {
          case PostingBehaviour.Forwarding:
          case PostingBehaviour.ForwardingAndClearance:
            if (isMasterBL && (parentTableName === 'bol_pack_lines' ||
              ['notify', 'goods_description', 'incoterm_text'].includes(attributeName))) {
              return false;
            }
            if (isHouseBL && (parentTableName === 'bol_container' ||
              ['payment_terms', 'vessel', 'voyage', 'booking_number', 'discharge_port', 'load_port',
                'seal_number', 'container_type', 'export_reference'].includes(attributeName))) {
              return false;
            }
            break;
          default:
            if (hasBothBills) {
              if (isMasterBL &&
                (['notify', 'incoterm_text', 'goods_description', 'payment_terms', 'export_reference'].includes(coordinate.attributeName) ||
                  parentTableName === 'bol_pack_lines')) {
                return false;
              }
              if (isHouseBL &&
                (['notify', 'incoterm_text', 'payment_terms', 'booking_number', 'notify', 'vessel', 'voyage', 'export_reference'].includes(coordinate.attributeName) ||
                  ['bol_container', 'bol_container_seals'].includes(parentTableName))) {
                return false;
              }
            } else {
              if (['notify', 'incoterm_text', 'payment_terms', 'export_reference'].includes(coordinate.attributeName)) {
                return false;
              }
              if (isHouseBL && ['booking_number'].includes(coordinate.attributeName)) {
                return false;
              }
            }
            break;
        }

        if (coordinate.parentTableName === 'commercial_invoice_line_item' && props.enableTableCoordinates && coordinate.attributeName === 'full_text') {
          return false;
        }

        if (coordinate.parentTableName === 'commercial_invoice_line_item' && !props.enableTableCoordinates && coordinate.attributeName !== 'full_text') {
          return false;
        }

        if (['tax', 'net_total'].includes(coordinate.attributeName)) {
          return false;
        }

        // just display coordinates where we have data being displayed
        if (props.details?.postingBehaviour && ![PostingBehaviour.WtgIntegration, PostingBehaviour.GenericTMS].includes(props.details.postingBehaviour) &&
          (coordinate.parentTableName === 'packing_list' || coordinate.parentTableName === 'packing_list_line_item')) {
          return false;
        }
        if (props.details?.postingBehaviour === PostingBehaviour.Forwarding &&
          (coordinate.parentTableName === 'commercial_invoice' || coordinate.parentTableName === 'commercial_invoice_line_item')) {
          return false;
        }

        return true;
      }) || undefined;

      setCoordinates(filteredCoordinates || []);
    }
  }

  const onSetActiveDocument = (document: APIDocumentContainer | APIEmailBasic | undefined, scroll?: boolean) => {
    setActiveDocument(document)
    if (document?.id) {
      setTimeout(() => { if (scroll) scrollToDocumentCard(document.id); }, 100);
    } else {
      setCoordinates([]);
    }
  }

  const isFormDisabled = props.details?.status ? [StatusCode.Done, StatusCode.Discarded, StatusCode.Posted, StatusCode.Processing, StatusCode.PendingResponse].includes(props.details?.status) : false;

  const onActivityLogAdded = (activityLog: Partial<APIActivityLog>) => {
    activityFeedRef.current && activityFeedRef.current.appendLog(activityLog);
  }

  const placeholder = React.useMemo(() => {
    if (!props.details) return undefined;
    return findInChildren(props.details.documents,
      {
        key: 'documentType',
        values: [DocumentType.PlaceholderConsol, DocumentType.PlaceholderShipment, DocumentType.PlaceholderBJob]
      })[0] as APIDocumentGroupDocument<APIDocumentGroupConsol>;
  }, [props.details]);

  const onRemoveCoordinates = React.useCallback((coordinatesFilter: CoordinatesFilter) => {
    if (!Object.keys(coordinatesFilter).length) {
      return;
    }

    DocumentContainerAPI.removeCoordinates(coordinatesFilter);
    setAllCoordinates(filterCoordinates(allCoordinates, coordinatesFilter));
  }, [allCoordinates]);

  return (
    <div className={`detail-view ${props.show ? 'show' : ''}`}>
      <StatusBar
        groupId={props.details?.id}
        validationResultId={props.details?.lastValidationResult?.id}
        assignmentRule={{
          teamId: props.details?.teamId || null,
          userIds: props.details?.assignedUsers.map((value) => value.userId) || []
        }}
        exceptions={props.details?.lastValidationResult?.details?.exceptions}
        status={props.details?.status}
        teams={props.teams}
        users={props.users}
        show={props.show}
        setConfirmModal={setConfirmModal}
        setDiscardCodesModal={setDiscardCodesModal}
        setNotification={props.setNotification}
        reloadDetailsForGroup={props.reloadDetailsForGroup}
        reloadDashboard={props.reloadDashboard}
        reloadGroupData={props.reloadGroupData}
        setOverlay={props.setOverlay}
        closeDetailsView={closeDetailsView}
        postingBehaviour={props.details?.postingBehaviour || PostingBehaviour.WtgIntegration}
        disabledNewBjob={disabledNewBjob}
        groupAnyBoL={getFlatFiles(props.fileSorterData?.documents || []).find((d) => blTypes.includes(d.documentType))}
        validationType={props.details?.emailAccount?.validationType}
        isTeamDropdownDisabled={isFormDisabled}
        placeholderJobRef={placeholderJobRef}
        placeholder={placeholder}
        onActivityLogAdded={onActivityLogAdded}
        addEventsToListen={props.addEventsToListen}
        removeGroupNotifications={props.removeGroupNotifications}
      />
      <div className="content" style={{ zIndex: modalEnabled ? 10 : 1 }}>
        <DocumentViewerContext.Provider value={{
          focusedCoordinates,
          setFocusedCoordinates,
          removeCoordinates: onRemoveCoordinates,
          setCoordinates,
          setAllCoordinates,
          coordinates,
          allCoordinates,
          allTableCoordinates,
          tableCoordinates
        }}>
          <FileSorterContainer
            details={props.fileSorterData}
            setNotification={props.setNotification}
            reloadDetailsForGroup={props.reloadDetailsForGroup}
            issuerDetails={props.issuerDetails}
            setShowOverlay={setShowOverlay}
            documentTypes={props.documentTypes}
            reloadGroupData={props.reloadGroupData}
            reloadDashboard={props.reloadDashboard}
            closeDetails={closeDetailsView}
            activeDocument={activeDocument}
            setActiveDocument={onSetActiveDocument}
            setModalEnabled={setModalEnabled}
            validationType={props.details?.emailAccount?.validationType}
            hideGroup={props.hideGroup}
          />
          <GroupDetails
            show={props.show}
            group={props.details}
            allGroupDocuments={props.fileSorterData?.documents}
            teams={props.teams}
            users={props.users}
            teamsMap={props.teamsMap}
            usersMap={props.usersMap}
            setConfirmModal={setConfirmModal}
            setNotification={props.setNotification}
            reloadDetailsForGroup={props.reloadDetailsForGroup}
            issuerDetails={props.issuerDetails}
            cwReferenceData={props.cwReferenceData}
            vesselOptions={props.vesselOptions}
            countryOptions={props.countryOptions}
            currencyOptions={props.currencyOptions}
            closeDetailsView={closeDetailsView}
            reloadGroupData={props.reloadGroupData}
            reloadDashboard={props.reloadDashboard}
            fieldsChanged={props.fieldChanged}
            setDiscardCodesModal={setDiscardCodesModal}
            activeDocument={activeDocument}
            setActiveDocument={onSetActiveDocument}
            documentTypes={props.documentTypes}
            isFormDisabled={isFormDisabled}
            setShowOverlay={setShowOverlay}
            activityFeedRef={activityFeedRef}
            setPlaceholderJobRef={setPlaceholderJobRef}
            placeholderJobRef={placeholderJobRef}
            enableTableCoordinates={props.enableTableCoordinates}
          />
        </DocumentViewerContext.Provider>
        <ConfirmModal
          onHide={closeModal}
          {...confirmModalProps}
          onConfirm={() => {
            confirmModalProps.onConfirm && confirmModalProps.onConfirm();
            closeModal();
          }}
        />
        <CodeSelectionModal
          hideModal={closeDiscardModal}
          codes={issueCodes}
          headerText={'Please select one or more reasons for discarding the pack'}
          {...codeSelectionModalProps}
          submit={(params) => {
            codeSelectionModalProps.submit && codeSelectionModalProps.submit(params);
            closeDiscardModal();
          }}
        />
      </div>
    </div>
  );
}
