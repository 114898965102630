import * as React from 'react';
import * as moment from 'moment';
import { TextInput } from '../../common/TextInput';
import { WrapperLabel } from '../../common/WrapperLabel';
import { OptionValue } from 'react-selectize';
import { DateSelectEditor } from '../../common/DateSelectEditor';
import { APIDocumentGroupConsol, APIDocumentGroupDocument, APIDocumentGroupShipment } from '../../../api/documentGroup';
import { APIBillOfLadingImporterReference, BillOfLadingAPI } from '../../../api/billOfLading';
import { BillOfLadingImporterReference } from '../../../api/billOfLadingImporterReference';
import { sortSearchOptions } from '../../helpers';
import { FieldChangedParams, IssuerDetailMap, JobType } from '../common';
import { CreditorSelect } from '../../common/CreditorSelect';
import { CustomSelect } from '../../common/CustomSelect';
import { PortSelect } from '../../common/PortSelect';
import { PacklinesList } from './PacklinesList';
import { ContainersList } from './ContainersList';
import { APIDocumentContainer, DocumentType } from '../../../api/documentContainer';
import { APIEmailBasic } from '../../../api/email';
import { placeholderTypes } from '../file-sorter/FileSorter';
import { CollapsibleContainer } from '../../common/CollapsibleContainer';

import './brokerage.scss';
import { RecordValidator } from '../../common/Form';
import { OrganisationText } from './organisation-text/OrganisationText';
import { checkIfRecordValidatorHasErrors } from './ErrorHelper';
import { ShipamaxExclamationIcon } from '../../../images/Icons';

interface Props {
  data: APIDocumentGroupDocument<APIDocumentGroupConsol>;
  secondaryBLData: APIDocumentGroupDocument<APIDocumentGroupShipment> | null;
  isNonCargowise: boolean;
  shipmentTypeOptions: OptionValue[];
  transModeOptions: OptionValue[];
  containerTypeOptions: OptionValue[];
  packageTypeOptions: OptionValue[];
  containerModeOptions: OptionValue[];
  vesselOptions: OptionValue[];
  groupId?: number;
  reloadGroupData: (forceRefresh?: boolean) => void;
  issuerDetails: IssuerDetailMap;
  fieldsChanged: (changes: FieldChangedParams[]) => void;
  disabled: boolean;
  allowCloneAgents: boolean;
  cloneFieldValue: (senderValue: string | undefined, receiverFields: string[] | undefined, onlyInvoices: boolean, isOrganisation?: boolean) => void;
  setActiveDocument: (document: APIDocumentContainer | APIEmailBasic | undefined, scroll?: boolean) => void;
  activeDocument?: APIDocumentContainer | APIEmailBasic | undefined;
  useOrganisations?: boolean;
}

export const Brokerage: React.FC<Props> = (props) => {

  const [mblNumber, setMblNumber] = React.useState<string>('');
  const [hblNumber, setHblNumber] = React.useState<string>('');
  const [shippingLineText, setShippingLineText] = React.useState<string>('');
  const [carriersRef, setCarriersRef] = React.useState<string>('');
  const [ownersReference, setOwnersReference] = React.useState<string>('');

  const [shipmentType, setShipmentType] = React.useState<OptionValue | null>(null);

  const [transMode, setTransMode] = React.useState<OptionValue | null>(null);
  const [voyage, setVoyage] = React.useState<string>('');
  const [vessel, setVessel] = React.useState<OptionValue | null>(null);

  const [containerMode, setContainerMode] = React.useState<OptionValue | null>(null);
  const [jobRef, setJobRef] = React.useState<string>('');
  const [goodsDesc, setGoodsDesc] = React.useState<string>('');

  const [shipperName, setShipperName] = React.useState<string>('');
  const [shipperAddress, setShipperAddress] = React.useState<string>('');
  const [consigneeName, setConsigneeName] = React.useState<string>('');
  const [consigneeAddress, setConsigneeAddress] = React.useState<string>('');

  const secondaryBlData = React.useMemo(() => props.secondaryBLData || props.data, [props.data, props.secondaryBLData]);

  // load document group data into brokerage form
  React.useEffect(() => {

    const data = props.data.billOfLading!;
    const secondaryData = props.secondaryBLData ? props.secondaryBLData.billOfLading! : data;
    setJobRef(data.importerReference?.find((ref) => ref.isConsol === false)?.jobRef || '');

    setShippingLineText(data.shippingLine || '');
    setCarriersRef(data.bookingNo || '');
    setVoyage(data.voyageNumber || '');

    setConsigneeName(data.consigneeName || '');
    setConsigneeAddress(data.consigneeAddress || '');
    setShipperName(data.shipperName || '');
    setShipperAddress(data.shipperAddress || '');

    setContainerMode(props.containerModeOptions.find((option) => option.value === data.containerMode) || null);
    setVessel(props.vesselOptions.find((option) => option.label === data.vessel) || null);

    if (!data.transportMode) updateField('transportMode', 'SEA');
    setTransMode(props.transModeOptions.find((option) => option.value === (data.transportMode || 'SEA')) || null);
    if (!data.consolType) updateField('consolType', 'AGT');

    setMblNumber(data.billOfLadingNo || ''); // if it is a HBL this field will be set but jut ignored
    setHblNumber(secondaryData.billOfLadingNo || '');

    setShipmentType(props.shipmentTypeOptions.find((option) => option.value === secondaryData.shipmentType) || null);
    setOwnersReference(secondaryData.ownersReference || '');
    setGoodsDesc(secondaryData.goodsDescription || '');
  }, [props.data]);

  const recordValidator: RecordValidator = {
    hblNumber: {
      validators: [
        {
          errorMessage: 'Duplicate bill of lading number',
          isValid: () => !props.secondaryBLData || hblNumber !== mblNumber || !mblNumber || !hblNumber
        },
      ]
    },
    mblNumber: {
      validators: [
        {
          errorMessage: 'Duplicate bill of lading number',
          isValid: () => !props.secondaryBLData || hblNumber !== mblNumber || !mblNumber || !hblNumber
        },
      ]
    },
  };
  const recordValidatorHasErrors = checkIfRecordValidatorHasErrors(recordValidator);

  const onFilterOptions = (items: OptionValue[], search: string): OptionValue[] => {
    const filteredOptions = items.filter((option) => option.label.toUpperCase().includes(search.toUpperCase()));
    return sortSearchOptions(filteredOptions, search);
  }

  const onFilterOptionsAtLeast3Characters = (items: OptionValue[], search: string): OptionValue[] => {
    if (search.length < 3) return [];

    const filteredOptions = items.filter((option) => option.label.toUpperCase().includes(search.toUpperCase()));
    return sortSearchOptions(filteredOptions, search);
  }

  const update = async (data: APIDocumentGroupDocument<APIDocumentGroupConsol | APIDocumentGroupShipment>,
    name: string, value: string | number | null, withoutChange?: boolean) => {
    if (withoutChange) {
      return;
    }

    if (name === 'jobRef') {
      const updatedImporterReference =
        await BillOfLadingImporterReference.updateJobRef(value, false, data.billOfLading!.id, data.billOfLading?.importerReference) as APIBillOfLadingImporterReference;
      let allReferences;

      if (updatedImporterReference.id &&
        data.billOfLading?.importerReference &&
        data.billOfLading?.importerReference.length > 0) {
        allReferences = data.billOfLading?.importerReference.map((ref) => {
          if (ref.id === updatedImporterReference.id) {
            return updatedImporterReference;
          } else {
            return ref;
          }
        });
      } else if (typeof updatedImporterReference == 'number' &&
        data.billOfLading?.importerReference) {
        allReferences = data.billOfLading?.importerReference.filter((ref) => ref.id !== updatedImporterReference);
      } else {
        allReferences = [updatedImporterReference];
      }
      props.fieldsChanged([{
        id: data.billOfLading?.id,
        fieldName: name,
        jobType: [DocumentType.MasterBillOfLading, DocumentType.PlaceholderBJob].includes(data.documentType) ? JobType.Consol : JobType.Shipment,
        value: allReferences,
      }]);
      setJobRef(updatedImporterReference.jobRef);
    } else {
      await BillOfLadingAPI.update(data.billOfLading!.id, { [name]: value });
      props.reloadGroupData(['consigneeCgwRecordId', 'shipperCgwRecordId'].includes(name));
    }

    if (['consigneeCgwRecordId', 'shipperCgwRecordId', 'shippingLineCgwRecordId'].includes(name)) {
      props.fieldsChanged([{
        id: data.billOfLading?.id,
        fieldName: name,
        jobType: [DocumentType.MasterBillOfLading, DocumentType.PlaceholderBJob].includes(data.documentType) ? JobType.Consol : JobType.Shipment,
        recordId: value as string | null
      }]);
    } else if (['billOfLadingNo'].includes(name)) {
      props.fieldsChanged([{
        id: data?.id,
        fieldName: name,
        jobType: JobType.Consol,
        value: value as string | null
      }]);
    }
  }

  const updateTwo = async (data: APIDocumentGroupDocument<APIDocumentGroupConsol | APIDocumentGroupShipment>,
    name: string, value: string | number | null, name2: string, value2: string | number | null) => {
    await BillOfLadingAPI.update(data.billOfLading!.id, { [name]: value, [name2]: value2 ? value2 : null });
    props.reloadGroupData();
  }

  const updateField = async (name: string, value: string | number | null, withoutChange?: boolean) => {
    update(props.data, name, value);
  }

  const updatesecondaryField = async (name: string, value: string | number | null, withoutChange?: boolean) => {
    update(secondaryBlData, name, value);
  }

  const updateTwoFields = async (name: string, value: string | number | null, name2: string, value2: string | number | null) => {
    updateTwo(props.data, name, value, name2, value2);
  }

  const updatesecondaryTwoFields = async (name: string, value: string | number | null, name2: string, value2: string | number | null) => {
    updateTwo(secondaryBlData, name, value, name2, value2);
  }

  const onFocus = () => {
    if (props.data?.documentType && !placeholderTypes.includes(props.data?.documentType)) {
      props.setActiveDocument(props.data as unknown as APIDocumentContainer)
    }
  }

  const onFocussecondary = () => {
    if (secondaryBlData?.documentType && !placeholderTypes.includes(secondaryBlData?.documentType)) {
      props.setActiveDocument(secondaryBlData as unknown as APIDocumentContainer)
    }
  }

  const isActiveDocument = [props.data.id, props.secondaryBLData?.id].includes(props.activeDocument?.id);

  return (
    <CollapsibleContainer
      versionTwo
      disableScroll
      disabled={props.disabled}
      isCollapsedByDefault={isActiveDocument ? false : true}
      title="Brokerage Job"
      className={`brokerage__wrapper ${isActiveDocument ? 'selected-card-div' : 'mt-25'}`}
      id={`card-${props.data?.id}`}
      secondaryId={props.secondaryBLData ? 'card-' + props.secondaryBLData.id : undefined}
      preHeaderJsx={
        <div className='invoice_icons'>
          {recordValidatorHasErrors && <ShipamaxExclamationIcon />}
        </div>
      }
      visibleContent={(
        <div className="grid__row grid__row--with-separator">
          <div className="grid__col-4">
            <WrapperLabel text="Job Reference">
              <TextInput
                value={jobRef}
                name="jobRef"
                onBlur={updateField}
                setter={setJobRef}
                disabled={true}
                onFocus={onFocus}
              />
            </WrapperLabel>
          </div>
          {[DocumentType.MasterBillOfLading, DocumentType.PlaceholderBJob].includes(props.data.documentType) && (
            <div className="grid__col-4">
              <WrapperLabel text="MBL Number">
                <TextInput
                  value={mblNumber}
                  name="billOfLadingNo"
                  onBlur={updateField}
                  setter={setMblNumber}
                  onFocus={onFocus}
                  fieldData={{
                    objectIds: [props.data?.billOfLading?.id || -1],
                    tableName: 'bol_header',
                    attributeNames: ['bill_of_lading_number'],
                  }}
                  formEntered={true}
                  validators={recordValidator.mblNumber.validators}
                  disabled={props.disabled}
                  dataTestId='input_mbl_number'
                />
              </WrapperLabel>
            </div>
          )}
          {(props.data.documentType === DocumentType.HouseBillOfLading || props.secondaryBLData) && (
            <div className="grid__col-4">
              <WrapperLabel text="HBL Number">
                <TextInput
                  value={hblNumber}
                  name="billOfLadingNo"
                  onBlur={updatesecondaryField}
                  setter={setHblNumber}
                  onFocus={onFocussecondary}
                  fieldData={{
                    objectIds: [secondaryBlData.billOfLading?.id || -1],
                    tableName: 'bol_header',
                    attributeNames: ['bill_of_lading_number'],
                  }}
                  formEntered={true}
                  validators={recordValidator.hblNumber.validators}
                  disabled={props.disabled}
                  dataTestId='input_hbl_number'
                />
              </WrapperLabel>
            </div>
          )}
          {!props.secondaryBLData && <div className="grid__col-4"></div>}
        </div>
      )}
    >

      <div className="grid__row grid__row--with-separator">
        {!props.useOrganisations
          ? (<>
            <OrganisationText
              label='shipper'
              fieldName='shipper'
              tableName='bol_header'
              name={shipperName}
              setName={setShipperName}
              address={shipperAddress}
              setAddress={setShipperAddress}
              update={updatesecondaryField}
              disabled={props.disabled}
              id={[props.data?.id || -1]}
              onFocus={onFocus}
            />
            <OrganisationText
              label='consignee'
              fieldName='consignee'
              tableName='bol_header'
              name={consigneeName}
              setName={setConsigneeName}
              address={consigneeAddress}
              setAddress={setConsigneeAddress}
              update={updatesecondaryField}
              disabled={props.disabled}
              id={[props.data?.id || -1]}
              onFocus={onFocus}
            />
          </>)
          :
          (<>
            <div className="grid__col-4">
              <div>
                <CreditorSelect
                  label="Shipper"
                  recordId={secondaryBlData.billOfLading?.shipperCgwRecordId || null}
                  onValueChange={(option) => {
                    updatesecondaryField('shipperCgwRecordId', option?.value || null);
                  }}
                  creditorDetailsMap={props.issuerDetails}
                  flags={{ shipper: true }}
                  dataTestId="sender-dropdown"
                  showAddress={true}
                  disabled={props.disabled}
                  companyCode={secondaryBlData.billOfLading?.emailAccount?.cwCompanyCode}
                  onFocus={onFocussecondary}
                  cloneFieldValue={props.cloneFieldValue}
                  allowCloneAgents={props.allowCloneAgents}
                  copyOnlyToInvoices={true}
                  cloneToFields={['supplierRecordId']}
                  cloneFieldLabel="Supplier"
                  fieldData={{
                    objectIds: [secondaryBlData?.billOfLading?.id || -1],
                    tableName: 'bol_header',
                    attributeNames: ['shipper'],
                  }}
                />
              </div>
            </div>
            <div className="grid__col-4">
              <CreditorSelect
                label="Consignee"
                recordId={secondaryBlData.billOfLading?.consigneeCgwRecordId || null}
                onValueChange={(option) => {
                  updatesecondaryField('consigneeCgwRecordId', option?.value || null);
                }}
                creditorDetailsMap={props.issuerDetails}
                flags={{ consignee: true }}
                dataTestId="receiver-dropdown"
                showAddress={true}
                disabled={props.disabled}
                companyCode={secondaryBlData.billOfLading?.emailAccount?.cwCompanyCode}
                onFocus={onFocussecondary}
                cloneFieldValue={props.cloneFieldValue}
                allowCloneAgents={props.allowCloneAgents}
                copyOnlyToInvoices={true}
                cloneToFields={['importerRecordId']}
                cloneFieldLabel="Importer"
                fieldData={{
                  objectIds: [secondaryBlData?.billOfLading?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['consignee'],
                }}
              />
            </div>
          </>
          )}
      </div>

      <div className="grid__row">
        <div className="grid__col-2">
          {props.useOrganisations ? (
            <CreditorSelect
              label="Carrier"
              recordId={props.data.billOfLading?.shippingLineCgwRecordId || null}
              onValueChange={(option) => {
                updateField('shippingLineCgwRecordId', option?.value || null);
              }}
              creditorDetailsMap={props.issuerDetails}
              flags={{ carrier: true }}
              dataTestId="carrier-dropdown"
              disabled={props.disabled}
              companyCode={props.data.billOfLading?.emailAccount?.cwCompanyCode}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.billOfLading?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['shipping_line'],
              }}
            />
          ) : (
            <WrapperLabel text="Carrier">
              <TextInput
                value={shippingLineText}
                name="shippingLine"
                onBlur={updateField}
                setter={setShippingLineText}
                disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.billOfLading?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['shipping_line'],
                }}
              />
            </WrapperLabel>
          )}
        </div>
        <div className="grid__col-2">
          <WrapperLabel text="Booking Number">
            <TextInput
              value={carriersRef}
              name="bookingNo"
              onBlur={updateField}
              setter={setCarriersRef}
              maxLength={20}
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.billOfLading?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['booking_number'],
              }}
              dataTestId='input_booking_no'
            />
          </WrapperLabel>
        </div>
        <div className="grid__col-2">
          <WrapperLabel text="Vessel">
            <CustomSelect
              options={props.vesselOptions}
              value={vessel || undefined}
              renderNoResultsFound={(item: OptionValue, search: string) => <div className="dropdown-placeholder">Please enter the first 3 characters of the code...</div>}
              filterOptions={onFilterOptionsAtLeast3Characters}
              onValueChange={(value) => {
                setVessel(value);
                updateTwoFields('vessel', value?.label || null, 'vesselIMO', value?.value || null);
              }}
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.billOfLading?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['vessel'],
              }}
            />
          </WrapperLabel>
        </div>
        <div className="grid__col-2">
          <WrapperLabel text="Voyage">
            <TextInput value={voyage} name="voyageNumber" onBlur={updateField} setter={setVoyage} maxLength={10} disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.billOfLading?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['voyage'],
              }}
            />
          </WrapperLabel>
        </div>
        <div className="grid__col-2">
          <WrapperLabel text="Container Mode">
            <CustomSelect
              options={props.containerModeOptions}
              value={containerMode || undefined}
              filterOptions={onFilterOptions}
              onValueChange={(value) => {
                setContainerMode(value);
                updateField('containerMode', value?.value || null);
              }}
              renderValue={(item: OptionValue) => item.value}
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.billOfLading?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['container_mode'],
              }}
            />
          </WrapperLabel>
        </div>
        <div className="grid__col-2">
          <WrapperLabel text="Trans Mode">
            <CustomSelect
              options={props.transModeOptions}
              value={transMode || undefined}
              filterOptions={onFilterOptions}
              onValueChange={(value) => {
                setTransMode(value);
                updateField('transportMode', value?.value || null);
              }}
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.billOfLading?.id || secondaryBlData.billOfLading?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['transport_mode'],
              }}
            />
          </WrapperLabel>
        </div>
      </div>
      <div className="grid__row">
        <div className="grid__col-4">
          <PortSelect
            label="Load Port"
            value={props.data.billOfLading?.loadPort}
            valueUnlocode={props.data.billOfLading?.loadPortUnlocode}
            onValueChange={(value, valueUnlocode) => {
              updateTwoFields('loadPort', value, 'loadPortUnlocode', valueUnlocode);
            }}
            onValueUnlocodeChange={(valueUnlocode) => {
              updateField('loadPortUnlocode', valueUnlocode || null);
            }}
            onlySeaPort={true}
            disabled={props.disabled}
            onFocus={onFocus}
            fieldData={{
              objectIds: [props.data?.billOfLading?.id || -1],
              tableName: 'bol_header',
              attributeNames: ['load_port'],
            }}
          />
        </div>
        <div className="grid__col-2">
          <WrapperLabel text="Loading Date" className="multiple-col-types-adjust">
            <DateSelectEditor
              initDate={props.data.billOfLading?.etd ? moment(props.data.billOfLading?.etd) : null}
              onDateChange={(value: moment.Moment | null) => {
                updateField('etd', value ? value.format('YYYY-MM-DD') : null);
              }}
              disabled={props.disabled}
              onFocus={onFocus}
            />
          </WrapperLabel>
        </div>
        <div className="grid__col-4">
          <PortSelect
            label="Discharge Port"
            value={props.data.billOfLading?.dischargePort}
            valueUnlocode={props.data.billOfLading?.dischargePortUnlocode}
            onValueChange={(value, valueUnlocode) => {
              updateTwoFields('dischargePort', value, 'dischargePortUnlocode', valueUnlocode);
            }}
            onValueUnlocodeChange={(valueUnlocode) => {
              updateField('dischargePortUnlocode', valueUnlocode || null);
            }}
            onlySeaPort={true}
            disabled={props.disabled}
            onFocus={onFocus}
            fieldData={{
              objectIds: [props.data?.billOfLading?.id || -1],
              tableName: 'bol_header',
              attributeNames: ['discharge_port'],
            }}
          />
        </div>
        <div className="grid__col-2">
          <WrapperLabel text="Discharge Date" className="multiple-col-types-adjust">
            <DateSelectEditor
              initDate={props.data.billOfLading?.eta ? moment(props.data.billOfLading?.eta) : null}
              onDateChange={(value: moment.Moment | null) => {
                updateField('eta', value ? value.format('YYYY-MM-DD') : null);
              }}
              disabled={props.disabled}
              onFocus={onFocus}
            />
          </WrapperLabel>
        </div>
      </div>
      <div className="grid__row grid__row--with-separator">
        <ContainersList
          containers={props.data.billOfLading?.container}
          reloadGroupData={props.reloadGroupData}
          disabled={props.disabled}
          containerTypeOptions={props.containerTypeOptions}
          billOfLadingId={props.data.billOfLading?.id}
          onFocus={onFocus}
        />
      </div>
      <div className="grid__row">
        <div className="grid__col-2">
          <WrapperLabel text="Shipment Type">
            <CustomSelect
              options={props.shipmentTypeOptions}
              value={shipmentType || undefined}
              filterOptions={onFilterOptions}
              onValueChange={(value) => {
                setShipmentType(value);
                updatesecondaryField('shipmentType', value?.value || null);
              }}
              disabled={props.disabled}
              onFocus={onFocussecondary}
              fieldData={{
                objectIds: [secondaryBlData?.billOfLading?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['shipment_type'],
              }}
            />
          </WrapperLabel>
        </div>
        <div className="grid__col-4">
          <WrapperLabel text="Goods Description" className="goods-description">
            <TextInput value={goodsDesc} name="goodsDescription" onBlur={updatesecondaryField} setter={setGoodsDesc} disabled={props.disabled}
              onFocus={onFocussecondary}
              fieldData={{
                objectIds: [secondaryBlData?.billOfLading?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['goods_description'],
              }}
              dataTestId='textarea_goods_desc'
            />
          </WrapperLabel>
        </div>
        <div className="grid__col-4">
          <WrapperLabel text="Owners Reference" className="multiple-col-types-adjust">
            <TextInput value={ownersReference} name="ownersReference" onBlur={updatesecondaryField} setter={setOwnersReference} maxLength={20} disabled={props.disabled}
              onFocus={onFocussecondary}
            />
          </WrapperLabel>
        </div>
        <div className="grid__col-2">
        </div>
      </div>
      <div className="grid__row">
        <div className="grid__col-4">
          <PortSelect
            label="Origin"
            value={secondaryBlData.billOfLading?.origin}
            valueUnlocode={secondaryBlData.billOfLading?.originUnlocode}
            onValueChange={(value, valueUnlocode) => {
              updatesecondaryTwoFields('origin', value, 'originUnlocode', valueUnlocode);
            }}
            onValueUnlocodeChange={(valueUnlocode) => {
              updatesecondaryField('originUnlocode', valueUnlocode || null);
            }}
            onlySeaPort={false}
            disabled={props.disabled}
            onFocus={onFocussecondary}
            fieldData={{
              objectIds: [secondaryBlData?.billOfLading?.id || -1],
              tableName: 'bol_header',
              attributeNames: ['origin'],
            }}
          />
        </div>
        <div className="grid__col-2">
          <WrapperLabel text="Departure ETD" className="multiple-col-types-adjust">
            <DateSelectEditor
              initDate={secondaryBlData.billOfLading?.originEtd ? moment(secondaryBlData.billOfLading?.originEtd) : null}
              onDateChange={(value: moment.Moment | null) => {
                updatesecondaryField('originEtd', value ? value.format('YYYY-MM-DD') : null);
              }}
              disabled={props.disabled}
              onFocus={onFocussecondary}
            />
          </WrapperLabel>
        </div>
        <div className="grid__col-4">
          <PortSelect
            label="Destination"
            value={secondaryBlData.billOfLading?.destination}
            valueUnlocode={secondaryBlData.billOfLading?.destinationUnlocode}
            onValueChange={(value, valueUnlocode) => {
              updatesecondaryTwoFields('destination', value, 'destinationUnlocode', valueUnlocode);
            }}
            onValueUnlocodeChange={(valueUnlocode) => {
              updatesecondaryField('destinationUnlocode', valueUnlocode || null);
            }}
            onlySeaPort={false}
            disabled={props.disabled}
            onFocus={onFocussecondary}
            fieldData={{
              objectIds: [secondaryBlData?.billOfLading?.id || -1],
              tableName: 'bol_header',
              attributeNames: ['destination'],
            }}
          />
        </div>
        <div className="grid__col-2">
          <WrapperLabel text="Arrival ETA" className="multiple-col-types-adjust">
            <DateSelectEditor
              initDate={secondaryBlData.billOfLading?.destinationEta ? moment(secondaryBlData.billOfLading?.destinationEta) : null}
              onDateChange={(value: moment.Moment | null) => {
                updatesecondaryField('destinationEta', value ? value.format('YYYY-MM-DD') : null);
              }}
              disabled={props.disabled}
              onFocus={onFocussecondary}
            />
          </WrapperLabel>
        </div>
      </div>
      <div className="grid__row">
        <PacklinesList
          packLines={secondaryBlData.billOfLading?.packLines}
          reloadGroupData={props.reloadGroupData}
          disabled={props.disabled}
          packageTypeOptions={props.packageTypeOptions}
          billOfLadingId={secondaryBlData.billOfLading?.id}
          onFocus={onFocussecondary}
        />
      </div>
      {props.children}

    </CollapsibleContainer>
  );
};
