import * as React from 'react';
import { OptionValue } from 'react-selectize';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { formatNumber, NumberInput } from '../../common/NumberInput';
import { APICommercialInvoiceLineItem, APIPackingListLineItem } from '../../../api/documentGroup';
import { TextInput } from '../../common/TextInput';
import { APICargowiseReferenceProductData, CommercialInvoiceLineItemAPI } from '../../../api/commercialInvoiceLineItem';
import { FAIcon } from '../../common/FAIcon';
import Select, { GroupTypeBase, OptionProps, OptionTypeBase, StylesConfig } from 'react-select';
import { parseNumber } from '../../helpers';
import { WrapperLabel } from '../../common/WrapperLabel';
import DataGrid, {
  PasteEvent,
  FillEvent,
  EditorProps,
  Column,
  FormatterProps,
  RowRendererProps, Row as GridRow, HeaderRendererProps
} from 'react-data-grid';
import { findMatchedCoordinates, APIDocCoordinate, DocumentType } from '../../../api/documentContainer';
import { DocumentViewerContext } from '../../DocumentViewerContext';
import { ShipamaxAddRowAboveIcon, ShipamaxAddRowBelowIcon, ShipamaxDownIcon, ShipamaxEllipsisIcon, ShipamaxLockIcon, ShipamaxPlusIcon, ShipamaxSettingsIcon, ShipamaxTrashIcon, ShipamaxUnlinkIcon } from '../../../images/Icons';
import { LineItemsSelector } from './LineItemsSelector';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { createPortal } from 'react-dom';
import { ConfirmModalProps } from '../../common/ConfirmModal';
import { Icon } from 'pivotal-ui/react/iconography';
import { PackingListLineItemAPI } from '../../../api/packingListLineItem';
import { PackingLineTotals } from './PackingList';
import { DraggableHeaderRenderer, reorderColumns } from '../../common/grid-components/DraggableHeaderRenderer';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { UserSettingsContext } from '../../main/Main';
import { ColumnsDetails } from '../../../api/userSettings';
import { ColumnVisibilitySelector } from '../../common/ColumnVisibilitySelector';

import './line-items.scss';
import { commercialInvoiceLineItemsErrors } from './ErrorHelper';

interface Props {
  isAggregated?: boolean;
  supplierCgwCode: string | null;
  importerCgwCode: string | null;
  countryOptions?: OptionValue[];
  unitTypeOptions: OptionValue[];
  lineItemRows?: (APICommercialInvoiceLineItem | APIPackingListLineItem)[];
  parentId: number;
  parentDocumentType: DocumentType;
  disabled: boolean;
  useOrganisations: boolean;
  supplierName: string | null;
  importerName: string | null;
  lineItemsTotalChanged?: (total: number) => void;
  packinglineItemsTotalChanged?: (totals: PackingLineTotals) => void;
  setConfirmModal: (props: ConfirmModalProps) => void;
  onFocus?: () => void;
  isNonCargowise?: boolean;
  enableTableCoordinates?: boolean;
  readonly?: boolean;
}

interface ContextMenuEventData extends RowRendererProps<APICommercialInvoiceLineItem | APIPackingListLineItem> { }

const emptyOptionArray: OptionValue[] = [
  { value: null, label: '' }
]

const matchedColumnNames: { key: string, matchedKey: string }[] = [
  { key: 'productCode', matchedKey: 'matchedProductCode' },
  { key: 'description', matchedKey: 'matchedDescription' },
  { key: 'originCountryId', matchedKey: 'matchedOriginCountryId' },
  { key: 'unitType', matchedKey: 'matchedUnitType' }
];

const copyPasteBetweenColumnsOptions: { [key: string]: string[] } = {
  'productCode': ['description'],
  'description': ['productCode'],
  'quantity': ['unitPrice', 'lineTotal'],
  'unitPrice': ['quantity', 'lineTotal'],
  'lineTotal': ['quantity', 'unitPrice'],
}

const keysToCommitChange = ['Enter', 'Tab'];

const handleKeyPressOnCell = (e: any) => !['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft'].includes(e.key);

export const isCILineItem = (li: APICommercialInvoiceLineItem | APIPackingListLineItem): li is APICommercialInvoiceLineItem => {
  return (li as APICommercialInvoiceLineItem).quantity !== undefined;
}

export const gridSelectStyle = (rowHeight: number, isDescriptionDropdown?: boolean): StylesConfig<OptionTypeBase, false, GroupTypeBase<OptionTypeBase>> => {
  return {
    indicatorSeparator: () => ({ display: 'none' }),
    control: (provided: any) => ({
      ...provided,
      height: rowHeight - 1,
      minHeight: 30,
      lineHeight: 'normal',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '0px 5px'
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      height: rowHeight - 1,
      paddingRight: 0,
      paddingTop: 11,
      paddingLeft: 0,
    }),
    menu: (provided: any) => ({
      ...provided,
      width: 'auto',
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxWidth: isDescriptionDropdown ? 700 : 190,
      minWidth: isDescriptionDropdown ? 114 : 190
    }),
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 1000,
      width: '100%',
      minWidth: 200,
      maxWidth: isDescriptionDropdown ? 700 : 190,
      marginTop: -8,
      marginLeft: -1,
    }),
    option: (provided: any, props: OptionProps<OptionTypeBase, false, GroupTypeBase<OptionTypeBase>>) => ({
      ...provided,
      minHeight: 34,
      fontSize: '12px',
      backgroundColor: (props.isSelected || props.isFocused) ? '#F1F1F1' : 'none',
      color: (props.isSelected || props.isFocused) ? '#111D26' : '#7C7C7C'
    }),
    noOptionsMessage: (provided: any) => ({
      ...provided,
      fontSize: '10px',
      lineHeight: '14px',
      color: '#111D26',
      textAlign: 'left'
    }),
  };
};

export const LineItems: React.FC<Props> = (props) => {
  const [lineItemRows, setLineItemRows] = React.useState<(APICommercialInvoiceLineItem | APIPackingListLineItem)[]>([]);
  const [selectedRows, setSelectedRows] = React.useState(() => new Set<React.Key>());
  const [selectedPosition, setSelectedPosition] = React.useState<{ rowIdx: number, idx: number } | null>(null);
  const [gridHeight, setGridHeight] = React.useState<number>(80);
  const gridRef = React.useRef<any>(null);
  const [currentRowIdx, setCurrentRowIdx] = React.useState<number | null>(null);
  const [isAggregated, setIsAggregated] = React.useState<boolean>(false);
  const [loading, setIsLoading] = React.useState<boolean>(false);
  const [columns, setColumns] = React.useState<Column<any>[]>([]);
  const userSettings = React.useContext(UserSettingsContext);
  const [colDetails, setColDetails] = React.useState<ColumnsDetails[]>([...userSettings.blSettings?.lineItemsColOrder?.find((cls) => cls.section === props.parentDocumentType)?.columns || []]);
  const [columnsVisibilitySelectorEnable, setColumnsVisibilitySelectorEnable] = React.useState<boolean>(false);
  const [updatedColumnsVisibility, setUpdatedColumnsVisibility] = React.useState<boolean>(false);
  const aggregatedRef = React.useRef<any>(null);
  const enableTableCoordinates = props.enableTableCoordinates || false;
  aggregatedRef.current = isAggregated;

  let productCodeAndDescriptionDropdownDisabled = true;
  if (!props.useOrganisations) {
    productCodeAndDescriptionDropdownDisabled = false;
  } else {
    if (props.importerCgwCode || props.supplierCgwCode) productCodeAndDescriptionDropdownDisabled = false;
  }

  const documentViewerContext = React.useContext(DocumentViewerContext);
  const api = props.parentDocumentType === DocumentType.PackingList ? PackingListLineItemAPI : CommercialInvoiceLineItemAPI;
  const tableName = props.parentDocumentType === DocumentType.PackingList ? 'packing_list_line_item' : 'commercial_invoice_line_item';

  const RowRenderer = React.useMemo(() => {
    return (rowProps: RowRendererProps<APICommercialInvoiceLineItem | APIPackingListLineItem>) => {
      const parentId = 'invoiceId' in rowProps.row ? rowProps.row.invoiceId : rowProps.row.packingListId;
      return (
        <ContextMenuTrigger id={`grid-context-menu-${parentId}`} collect={() => (rowProps)}>
          <GridRow
            {...rowProps}
            onMouseDown={(event) => {
              if (event.button === 2) {
                setCurrentRowIdx(rowProps.rowIdx);
              } else {
                setCurrentRowIdx(null);
              }
            }}
          />
        </ContextMenuTrigger>
      );
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (gridRef.current && (!gridRef.current.element.contains(event.target as HTMLElement) || !(event.target as any).closest('.react-contextmenu'))) {
      setCurrentRowIdx(null);
    }
  }

  React.useEffect(() => {
    setIsAggregated(props.isAggregated || false);
  }, [props.isAggregated]);

  React.useEffect(() => {
    if (props.lineItemRows) {
      setLineItemRows(props.lineItemRows.map((a) => {
        return { ...a };
      }));
    }
  }, [props.lineItemRows]);

  React.useEffect(() => {

    if (lineItemRows.length) {
      if (!lineItemRows.some((row) => row.id === -1)) {
        addLineItemRow();
      }
    }

    if (props.lineItemsTotalChanged && lineItemRows.length) {
      props.lineItemsTotalChanged(lineItemRows.reduce((sum: number, line) => {
        let lineTotal: undefined | number = 0;
        if ('lineTotal' in line) {
          lineTotal = line.lineTotal;
        }
        return sum + parseNumber(lineTotal || 0);
      }, 0));
    } else if (props.lineItemsTotalChanged) {
      props.lineItemsTotalChanged(0);
    }

    if (props.packinglineItemsTotalChanged && props.parentDocumentType === DocumentType.PackingList) {
      const lineItemsPackingList = lineItemRows as APIPackingListLineItem[];
      const totals = {
        netWeightTotal: lineItemsPackingList.reduce((total, li) => total + (li.netWeight || 0), 0),
        grossWeightTotal: lineItemsPackingList.reduce((total, li) => total + (li.grossWeight || 0), 0),
        volumeTotal: lineItemsPackingList.reduce((total, li) => total + (li.volume || 0), 0),
        itemQtyTotal: lineItemsPackingList.reduce((total, li) => total + (li.itemQty || 0), 0),
        packageQuantityTotal: lineItemsPackingList.reduce((total, li) => total + (li.packageQty || 0), 0),
      };
      props.packinglineItemsTotalChanged(totals);
    }

  }, [lineItemRows]);

  React.useEffect(() => {
    if (lineItemRows.length > 0) {
      setGridHeight(42 + (lineItemRows.length * 42));
    } else if (lineItemRows.length > 20) {
      setGridHeight(42 + (20 * 42));
    } else {
      setGridHeight(80);
    }
  }, [lineItemRows.length]);

  React.useEffect(() => {
    const firstFocusedCoordinate = documentViewerContext.focusedCoordinates.length ? documentViewerContext.focusedCoordinates[0] : undefined;
    if (firstFocusedCoordinate?.parentTableName === tableName) {
      let indexSelected = lineItemRows.findIndex((row) => row.originalIds?.includes(firstFocusedCoordinate?.parentTableId) || row.id === firstFocusedCoordinate?.parentTableId);
      const columnIndex = enableTableCoordinates ? columns.findIndex((column) => column.headerCellClass === firstFocusedCoordinate.attributeName) : 1;

      if (indexSelected >= 0 && (enableTableCoordinates ? columnIndex !== selectedPosition?.idx : indexSelected !== selectedPosition?.rowIdx)) {
        gridRef.current.selectCell({ idx: columnIndex, rowIdx: indexSelected });
        gridRef.current.element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [documentViewerContext.focusedCoordinates]);

  const handleFill = ({ columnKey, sourceRow, targetRows }: FillEvent<any>): any[] => {
    let rowsToUpdate = targetRows.map((row) => ({ ...row }));
    const sourceColumnKey = sourceRow.matched ? getSourceColumnName(columnKey) : columnKey;

    rowsToUpdate.forEach((row) => {
      if (!row.matched && row.id !== -1) {
        updateLineItem(row.id, columnKey, sourceRow[sourceColumnKey]);
      }
    });

    if (columnKey === 'productCode' && sourceRow.matched) {
      CommercialInvoiceLineItemAPI.searchProducts(columnKey, sourceRow.matchedProductCode, props.supplierCgwCode, props.importerCgwCode, true).then((products) => {
        if (products.length) {
          matchRows(rowsToUpdate, products[0]);
        }
      });
    }

    return rowsToUpdate.map((row) => {
      if ((row.matched && matchedColumnNames.some((column) => column.key === columnKey)) || row.id === -1) return { ...row };
      return { ...row, [columnKey as keyof any]: sourceRow[sourceColumnKey as keyof any] }
    });
  }

  const handlePaste = ({ sourceColumnKey, sourceRow, targetColumnKey, targetRow }: PasteEvent<any>): any => {
    const incompatibleColumns = ['linked'];
    if (incompatibleColumns.includes(sourceColumnKey) || incompatibleColumns.includes(targetColumnKey)) {
      return targetRow;
    }

    if (sourceColumnKey !== targetColumnKey) {
      if (!copyPasteBetweenColumnsOptions[sourceColumnKey]?.includes(targetColumnKey)) {
        return targetRow;
      }
    }

    if (targetRow.matched && matchedColumnNames.some((column) => column.key === targetColumnKey)) {
      return targetRow;
    }
    const finalSourceColumnKey = sourceRow.matched ? getSourceColumnName(sourceColumnKey) : sourceColumnKey;
    const pastedValue = sourceRow[finalSourceColumnKey];

    let rowToUpdate = { ...targetRow };
    onLineItemCellBlur(targetRow, targetColumnKey, pastedValue).then(async () => {
      if (targetColumnKey === 'productCode') {
        const products = await CommercialInvoiceLineItemAPI.searchProducts(targetColumnKey, pastedValue, props.supplierCgwCode, props.importerCgwCode, true);
        if (products[0]) {
          return matchRows([targetRow], products[0]);
        }
      }
    });

    return { ...rowToUpdate, [targetColumnKey]: sourceRow[finalSourceColumnKey as keyof any] };
  }

  const updateLineItem = async (id: number, name: string, value: string | number | null | boolean, withoutChange?: boolean) => {
    if (id && !withoutChange) {
      return await api.update(id, { [name]: (value !== undefined ? value : null) }, aggregatedRef.current);
    }
  }

  const checkRowEmpty = (row: APICommercialInvoiceLineItem | APIPackingListLineItem) => {
    if (isCILineItem(row)) {
      return !row.description && !row.hsCode && !row.productCode && !row.originCountryId && !row.unitType && !row.quantity && !row.unitPrice && !row.lineTotal;
    } else {
      return !row.description && !row.hsCode && !row.productCode && !row.grossWeight && !row.netWeight && !row.volume && !row.packageQty && !row.itemQty;
    }
  }

  const onLineItemCellBlur = async (row: APICommercialInvoiceLineItem | APIPackingListLineItem, field: string, value: string | number | null | boolean, withoutChange?: boolean): Promise<APICommercialInvoiceLineItem | APIPackingListLineItem | undefined> => {
    if (row.id > 0) {
      if (!value && checkRowEmpty(row) && row.orderIndex === lineItemRows.length - 2) {
        deleteLineItemRow(row.id);
      } else {
        return await updateLineItem(row.id, field, value, withoutChange);
      }
    } else if (value || props.useOrganisations) {
      const createdRow = await api.create({ ...row, id: undefined, [field]: (value !== undefined ? value : null) }, aggregatedRef.current);
      updateLineItemRows([createdRow.orderIndex], { id: createdRow.id });
      return createdRow;
    }
  }

  const addLineItemRow = async (targetIndex?: number) => {
    let lineItem: APICommercialInvoiceLineItem | APIPackingListLineItem;
    if (targetIndex === undefined) {
      // if adding to the end of the queue, add it as "ghost" line item which
      // will be commited only if the user add some data, otherwise will be ignored
      targetIndex = lineItemRows.length;
      if (lineItemRows.some((li) => !!li.orderIndex)) {
        // if orderIndexes are defined let's get the biggest number as reference
        // because delete actions don't update orders
        targetIndex = lineItemRows.length ? Math.max(...lineItemRows.map(o => o.orderIndex), 0) + 1 : 0;
      }
      lineItem = {
        ...(props.parentDocumentType === DocumentType.CommercialInvoice ? { invoiceId: props.parentId } : { packingListId: props.parentId }),
        orderIndex: targetIndex,
        id: -1
      };
    } else {
      lineItem = await api.create({
        ...(props.parentDocumentType === DocumentType.CommercialInvoice ? { invoiceId: props.parentId } : { packingListId: props.parentId }),
        orderIndex: targetIndex
      }, aggregatedRef.current);
    }

    const result: (APICommercialInvoiceLineItem | APIPackingListLineItem)[] = [];
    let iteratorIndex = 0;
    const promises: Promise<any>[] = [];

    if (!props.readonly) {

      lineItemRows.forEach((row, index) => {
        if (targetIndex === index) {
          result.push({ ...lineItem, orderIndex: iteratorIndex });
          iteratorIndex++;
        }

        if (row.id !== -1 && row.orderIndex !== iteratorIndex) {
          promises.push(api.update(row.id, { orderIndex: iteratorIndex }, aggregatedRef.current));
        }

        result.push({ ...row, orderIndex: iteratorIndex });

        iteratorIndex++;
      });

      if (targetIndex === lineItemRows.length) {
        result.push({ ...lineItem, orderIndex: targetIndex });
      }

      Promise.all(promises);

      setLineItemRows(result);
    }
  }

  const deleteLineItemRow = async (id: number, originalIds?: number[]) => {
    await api.remove(id, aggregatedRef.current);

    documentViewerContext.removeCoordinates({
      parentTableIds: originalIds || [id],
      parentTableName: tableName,
    });

    const rows = lineItemRows.filter((row) => row.id !== id);
    setLineItemRows(rows);
  }

  const onClickRemoveAllLines = async () => {
    props.setConfirmModal({
      show: true,
      title: 'Remove all lines',
      text: 'This will delete all line items and any changes made to them. The deleted lines cannot be restored.<br/><br/><b>Are you sure you want to remove all lines?</b>',
      confirmText: "Remove lines",
      cancelText: "Cancel",
      onConfirm: onRemoveAllLines,
    });
  }

  const onClickRemoveLinesAggregation = async () => {
    props.setConfirmModal({
      show: true,
      title: 'Remove Line Aggregation',
      text: 'This will remove the aggregation. Any change made to the line items while in aggregation mode will be lost.</br></br><b>Are you sure you want to remove the aggregation?</b>',
      confirmText: "Remove Aggregation",
      cancelText: "Cancel",
      onConfirm: onRemoveLineItemsAggregation,
    });
  }

  const onRemoveAllLines = async () => {
    await api.removeAllLinesFromCinv(props.parentId);
    lineItemRows.forEach((line) => {
      const idsToRemove = line.originalIds || [line.id];
      documentViewerContext.removeCoordinates({
        parentTableIds: idsToRemove,
        parentTableName: tableName,
      });
    });
    // refresh for list with only a single ghost line item
    setLineItemRows([{
      ...(props.parentDocumentType === DocumentType.CommercialInvoice ? { invoiceId: props.parentId } : { packingListId: props.parentId }),
      orderIndex: 0,
      id: -1
    }]);
  }

  const onAggregateLines = async () => {
    setIsLoading(true);
    let aggregatedLines = await CommercialInvoiceLineItemAPI.aggregateLinesItems(props.parentId);
    aggregatedLines = aggregatedLines.sort((a, b) => {
      if (a.orderIndex > b.orderIndex) return 1;
      if (a.orderIndex < b.orderIndex) return -1;
      return 0;
    });

    // refresh for list with only a single ghost line item
    setLineItemRows([...aggregatedLines, { invoiceId: props.parentId, orderIndex: aggregatedLines.length, id: -1 }]);
    setIsAggregated(true);
    setIsLoading(false);
  }

  const onRemoveLineItemsAggregation = async () => {
    setIsLoading(true);
    let normalLineItems = await CommercialInvoiceLineItemAPI.removeLineItemsFromAggregation(props.parentId);
    normalLineItems = normalLineItems.sort((a, b) => {
      if (a.orderIndex > b.orderIndex) return 1;
      if (a.orderIndex < b.orderIndex) return -1;
      return 0;
    });

    setLineItemRows([...normalLineItems, { invoiceId: props.parentId, orderIndex: normalLineItems.length, id: -1 }]);
    setIsAggregated(false);
    setIsLoading(false);
  }

  const updateLineItemRows = (indexes: number[], update: Partial<APICommercialInvoiceLineItem | APIPackingListLineItem>) => {
    setLineItemRows((rows) => rows.map((row) => indexes.includes(row.orderIndex) ? { ...row, ...update } : { ...row }));
  }

  const unMatchRow = async (id: number) => {
    const updatedRows = lineItemRows.map((row) => {
      if (row.id !== id) {
        return { ...row };
      } else {
        updateLineItem(row.id, 'matched', false)
        return { ...row, matched: false, matchedOriginCountry: undefined, matchedOriginCountryId: undefined }
      }
    });
    setLineItemRows(updatedRows);
  }

  const getCountryCode = (country?: OptionValue) => {
    if (country && country.label) return country.label.split('-')[0].trim();
    return '';
  }

  const getCountryName = (country?: OptionValue) => {
    if (country && country.label) return country.label.split('-')[1].trim();
    return '';
  }

  const getSourceColumnName = (columnName: string) => {
    const foundMatchedKey = matchedColumnNames.find((key) => key.key === columnName);
    if (foundMatchedKey) {
      return foundMatchedKey.matchedKey;
    }
    return columnName;
  }

  const onSelectCell = (position: { rowIdx: number, idx: number }) => {
    if (props.onFocus) props.onFocus();
    setSelectedPosition(position);
    const attributeName = enableTableCoordinates ? [columns[position.idx].headerCellClass || ""] : ["full_text"];

    onFocusCoordinate(findMatchedCoordinates({
      objectIds: lineItemRows[position.rowIdx].originalIds || [lineItemRows[position.rowIdx].id],
      tableName: tableName,
      attributeNames: attributeName,
    }, documentViewerContext.coordinates) || null);
  }

  const onFocusCoordinate = (matchedCoordinates: APIDocCoordinate[]) => {
    documentViewerContext.setFocusedCoordinates(matchedCoordinates);
  }

  const productCodeFormatter = (row: APICommercialInvoiceLineItem | APIPackingListLineItem, productCodeDropdownDisabled: boolean) => {
    const code = (row.matched ? row.matchedProductCode : row.productCode) || '';
    let content = <span className={`table-text-detail full-text ${row.matched ? 'disabled' : ''}`}>{code}</span>;

    if (row.id === -1 && !props.readonly) {
      content = <span className={`table-text-detail disabled`}>Type to add line</span>;
    } else if (commercialInvoiceLineItemsErrors(row, props.isNonCargowise)[row.id]?.productCodeLimit?.error) {
      content = (
        <OverlayTrigger overlay={<Tooltip>
          <div className="text-left">{commercialInvoiceLineItemsErrors(row, props.isNonCargowise)[row.id]?.productCodeLimit?.error}</div>
        </Tooltip >} delayShow={500} placement="right" >
          {content}
        </OverlayTrigger >
      );
    }
    if (!productCodeDropdownDisabled) return content;
    return (
      <div className="line-item__product-code--locked" title="To edit the product code, make sure the supplier and/or importer are set">
        {content}
        <ShipamaxLockIcon />
      </div>
    );
  }

  const descriptionFormatter = (row: APICommercialInvoiceLineItem | APIPackingListLineItem, dropdownDisabled = false) => {
    const description = (row.matched ? row.matchedDescription : row.description?.replace(/\n/g, ' ')) || '';
    let content = <span className={`table-text-detail full-text ${row.matched ? 'disabled' : ''}`}>{description}</span>;
    if (commercialInvoiceLineItemsErrors(row, props.isNonCargowise)[row.id]?.descriptionLimit?.error) {
      content = (
        <OverlayTrigger overlay={<Tooltip>
          <div className="text-left">{commercialInvoiceLineItemsErrors(row, props.isNonCargowise)[row.id]?.descriptionLimit?.error}</div>
        </Tooltip >} delayShow={500} placement="left" >
          {content}
        </OverlayTrigger >
      );
    }
    if (!dropdownDisabled) return content;
    return (
      <div className="line-item__product-code--locked" title="To edit the product description, make sure the supplier and/or importer are set">
        {content}
        <ShipamaxLockIcon />
      </div>
    );
  }

  const hsCodeFormatter = (row: APICommercialInvoiceLineItem | APIPackingListLineItem, productCodeDropdownDisabled = false) => {
    const content = <span className={`table-text-detail full-text ${row.matched ? 'disabled' : ''}`}>{row.matched ? row.matchedHsCode : row.hsCode}</span>;

    if (!productCodeDropdownDisabled) return content;
    return (
      <div className="line-item__product-code--locked">
        {content}
        <ShipamaxLockIcon />
      </div>
    );
  }

  const countryFormatter = (row: APICommercialInvoiceLineItem, rowIdx: number) => {
    const selectedOption = props.countryOptions?.find((option: OptionValue) => option.value === (row.originCountryId ? row.originCountryId : row.matchedOriginCountryId));
    return <>
      <span className={`table-text-detail select-detail`}>{getCountryCode(selectedOption)}</span>
      {!props.disabled && (
        <ShipamaxDownIcon
          className={`selector-icon`}
          onClick={(event) => {
            gridRef.current.selectCell({ idx: 4, rowIdx }, true);
            event.preventDefault();
            event.stopPropagation();
          }}
        />
      )}
    </>;
  }

  const unitTypeFormatter = (row: APICommercialInvoiceLineItem, rowIdx: number) => {
    return <>
      <span className={`table-text-detail select-detail`}>{row.matchedUnitType ? row.matchedUnitType : row.unitType}</span>
      {!props.disabled && (
        <ShipamaxDownIcon
          className={`selector-icon`}
          onClick={(event) => {
            gridRef.current.selectCell({ idx: 5, rowIdx }, true);
            event.preventDefault();
            event.stopPropagation();
          }}
        />
      )}
    </>;
  }

  const matchRows = async (rows: Readonly<APICommercialInvoiceLineItem | APIPackingListLineItem>[], productData: APICargowiseReferenceProductData) => {
    let update: Partial<APICommercialInvoiceLineItem | APIPackingListLineItem> = {};
    if (props.parentDocumentType === DocumentType.CommercialInvoice) {
      const countryOption = props.countryOptions?.find((countryOption) => countryOption.label.split(' - ')[0] === productData.origin);
      update = {
        matched: true,
        matchedDescription: productData.description,
        matchedOriginCountry: productData.origin,
        matchedOriginCountryId: countryOption?.value,
        matchedProductCode: productData.productCode,
        matchedUnitType: productData.unitType,
        matchedHsCode: productData.hsCode,
        matchedClassification: productData.tariffLookup,
        isPartial: false
      };
    } else if (props.parentDocumentType === DocumentType.PackingList) {
      update = {
        matched: true,
        matchedDescription: productData.description,
        matchedProductCode: productData.productCode,
        matchedHsCode: productData.hsCode,
        isPartial: false
      };
    }
    const rowsIds = rows.map((row) => row.id);
    const rowsIndexes = rows.map((row) => row.orderIndex);
    await api.bulkUpdate(rowsIds, props.parentId, update, aggregatedRef.current);
    updateLineItemRows(rowsIndexes, update);
  }

  const genericTextInput = (editorProps: EditorProps<APICommercialInvoiceLineItem | APIPackingListLineItem>, propName: string) => {
    const key = propName as keyof typeof editorProps.row;
    return <TextInput
      value={editorProps.row[key] as string || null}
      name={propName}
      autoFocus={true}
      autoSelect={true}
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (keysToCommitChange.includes(event.key)) (event.target as HTMLElement).blur();
      }}
      onChange={(value, field) => {
        editorProps.onRowChange({ ...editorProps.row, [field]: value }, false);
      }}
      onBlur={(field, value, withoutChange) => {
        editorProps.onClose(!withoutChange);
        onLineItemCellBlur(editorProps.row, field, value, withoutChange);
      }} />;
  }

  const draggableColumns = React.useMemo(() => {
    const HeaderRenderer = (props: HeaderRendererProps<APICommercialInvoiceLineItem | APIPackingListLineItem>) => {
      return <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder} />;
    };

    const handleColumnsReorder = (sourceKey: string, targetKey: string) => {
      const reorderedColumns = reorderColumns([...columns], [...colDetails]);
      const sourceColumnIndex = reorderedColumns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = reorderedColumns.findIndex((c) => c.key === targetKey);

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      let cols = [...colDetails];
      if (cols) {
        cols = reorderedColumns.map((c) => {
          return {
            key: c.key,
            width: null,
            hidden: false,
          }
        });
        setColDetails(cols);
      }
      setColumns(reorderedColumns);
    };

    let columnsOrdered = reorderColumns([...columns], [...colDetails]);

    if (colDetails && colDetails.length <= 1 || (columns.length !== colDetails.length)) {
      // in case there's not colDetails defined or the number of columns in the table has changed, let's define it from scratch
      const newColsDetails = columns.map((c) => {
        return {
          key: c.key,
          width: null,
          hidden: false,
        };
      });
      setColDetails(newColsDetails);
    }

    return columnsOrdered.filter((c) => {
      // Remove hidden columns
      if (colDetails && colDetails.some((detail) => detail.key === c.key && detail.hidden)) {
        return false;
      }
      return true;
    }).map((c) => {
      if (c.key === 'id') return c;
      return { ...c, headerRenderer: HeaderRenderer };
    });
  }, [columns, updatedColumnsVisibility]);

  const updateUserColHiddenSettings = (colKey: string, hidden: boolean) => {
    const colDetailsCopy = [...colDetails];
    const checkedCol = colDetailsCopy.find((c) => c.key === colKey);
    if (checkedCol) checkedCol.hidden = hidden;
    setColDetails(colDetailsCopy);
    setUpdatedColumnsVisibility(!updatedColumnsVisibility);
  }

  const columnMenuCI: Column<APICommercialInvoiceLineItem> = React.useMemo(() => {
    return {
      key: 'linked',
      name: '',
      width: 60,
      minWidth: 60,
      maxWidth: 60,
      editable: () => { return !props.disabled; },
      formatter(rowProps: FormatterProps<APICommercialInvoiceLineItem>) {
        const isPartialMatch = rowProps.row.matched && rowProps.row.isPartial;
        return (
          <div>
            {rowProps.row.id !== -1 && !props.readonly && (
              <ContextMenuTrigger id={`grid-context-menu-${rowProps.row.invoiceId}`} collect={() => (rowProps)} mouseButton={0}>
                <OverlayTrigger overlay={<Tooltip><span><b>Open</b> line's menu</span></Tooltip>} delayShow={500} placement="right">
                  <span>
                    <ShipamaxEllipsisIcon
                      onClick={() => setCurrentRowIdx(rowProps.rowIdx)}
                      className="line-item__context-menu-button"
                    />
                  </span>
                </OverlayTrigger>
              </ContextMenuTrigger>
            )}
            {isPartialMatch && (
              <OverlayTrigger overlay={<Tooltip>Product code set based on partial match.</Tooltip>} delayShow={500} placement="right" >
                <span>
                  <FAIcon
                    name="exclamation-triangle"
                    solid
                    className={`line-item__product-code__error line-item__product-code__error--visible ' ${(props.disabled) ? 'disabled' : ''}`}
                  />
                </span>
              </OverlayTrigger >
            )}
            {
              rowProps.row.matched && (
                <FAIcon
                  name="link"
                  solid
                  className="line-item__product-code__icons--linked"
                />
              )
            }
          </div >
        );
      },
    };
  }, [props.disabled, props.supplierCgwCode, props.importerCgwCode]);

  const columnMenuPL: Column<APIPackingListLineItem> = React.useMemo(() => {
    return {
      key: 'linked',
      name: '',
      width: 60,
      minWidth: 60,
      maxWidth: 60,
      editable: () => { return !props.disabled; },
      formatter(rowProps: FormatterProps<APIPackingListLineItem>) {
        const isPartialMatch = rowProps.row.matched && rowProps.row.isPartial;
        return (
          <div>
            {rowProps.row.id !== -1 && (
              <ContextMenuTrigger id={`grid-context-menu-${rowProps.row.packingListId}`} collect={() => (rowProps)} mouseButton={0}>
                <OverlayTrigger overlay={<Tooltip><span><b>Open</b> line's menu</span></Tooltip>} delayShow={500} placement="right">
                  <span>
                    <ShipamaxEllipsisIcon 
                      onClick={() => setCurrentRowIdx(rowProps.rowIdx)}
                      className="line-item__context-menu-button"
                    />
                  </span>
                </OverlayTrigger>
              </ContextMenuTrigger>
            )}
            {isPartialMatch && (
              <OverlayTrigger overlay={<Tooltip><div>
                {`${isPartialMatch ? 'Product code set based on partial match .' : ''}`}
              </div></Tooltip>} delayShow={500} placement="right">
                <span>
                  <FAIcon
                    name="exclamation-triangle"
                    solid
                    className={`line-item__product-code__error line-item__product-code__error--visible ' ${(props.disabled) ? 'disabled' : ''}`}
                  />
                </span>
              </OverlayTrigger>
            )}
            {rowProps.row.matched && (
              <FAIcon
                name="link"
                solid
                className="line-item__product-code__icons--linked"
              />
            )}
          </div>
        );
      },
    };
  }, [props.disabled]);

  const columnProductCode: Column<APICommercialInvoiceLineItem | APIPackingListLineItem> = React.useMemo(() => {
    return {
      key: 'productCode',
      name: 'Product Code',
      headerCellClass: "product_code",
      minWidth: 86,
      width: 115,
      maxWidth: 750,
      resizable: true,
      editorOptions: {
        editOnClick: false,
        onNavigation: handleKeyPressOnCell,
      },
      formatter(rowProps: FormatterProps<APICommercialInvoiceLineItem | APIPackingListLineItem>) {
        return productCodeFormatter(rowProps.row, rowProps.isCellSelected && productCodeAndDescriptionDropdownDisabled);
      },
      editable: () => { return !props.disabled && !productCodeAndDescriptionDropdownDisabled; },
      editor: (editorProps: EditorProps<APICommercialInvoiceLineItem | APIPackingListLineItem>) => {
        if (editorProps.row.matched) return productCodeFormatter(editorProps.row, false);
        if (productCodeAndDescriptionDropdownDisabled) return productCodeFormatter(editorProps.row, productCodeAndDescriptionDropdownDisabled);
        return props.useOrganisations
          ? <LineItemsSelector
            property={'productCode'}
            matchedProperty={'matchedProductCode'}
            propertyText={'code'}
            isDisabled={productCodeAndDescriptionDropdownDisabled}
            editorProps={editorProps}
            supplierCgwCode={props.supplierCgwCode}
            importerCgwCode={props.importerCgwCode}
            updateLineItemRows={updateLineItemRows}
            updateLineItemsDB={onLineItemCellBlur}
            matchRows={matchRows}
            gridSelectStyle={gridSelectStyle}
          /> :
          genericTextInput(editorProps, 'productCode');
      },
    };
  }, [props.disabled, props.supplierCgwCode, props.importerCgwCode, lineItemRows]);

  const columnHsCode: Column<APICommercialInvoiceLineItem | APIPackingListLineItem> = React.useMemo(() => {
    return {
      key: 'hsCode',
      name: 'HS Code',
      headerCellClass: "hs_code",
      width: 65,
      minWidth: 65,
      maxWidth: 100,
      resizable: true,
      editorOptions: {
        editOnClick: false,
        onNavigation: handleKeyPressOnCell,
      },
      formatter({ row, isCellSelected }: FormatterProps<APICommercialInvoiceLineItem | APIPackingListLineItem>) {
        return hsCodeFormatter(row, isCellSelected && productCodeAndDescriptionDropdownDisabled);
      },
      editable: () => { return !props.disabled && !productCodeAndDescriptionDropdownDisabled; },
      editor: (editorProps: EditorProps<APICommercialInvoiceLineItem | APIPackingListLineItem>) => {
        if (editorProps.row.matched) return hsCodeFormatter(editorProps.row);
        return props.useOrganisations
          ? <LineItemsSelector
            property={'hsCode'}
            matchedProperty={'matchedHsCode'}
            propertyText={'hsCode'}
            isDisabled={productCodeAndDescriptionDropdownDisabled}
            editorProps={editorProps}
            supplierCgwCode={props.supplierCgwCode}
            importerCgwCode={props.importerCgwCode}
            updateLineItemRows={updateLineItemRows}
            updateLineItemsDB={onLineItemCellBlur}
            matchRows={matchRows}
            gridSelectStyle={gridSelectStyle}
          /> :
          genericTextInput(editorProps, 'hsCode');
      },
    };
  }, [props.disabled, props.supplierCgwCode, props.importerCgwCode, lineItemRows]);

  const columnDescription: Column<APICommercialInvoiceLineItem | APIPackingListLineItem> = React.useMemo(() => {
    return {
      key: 'description',
      name: 'Description',
      headerCellClass: "description",
      minWidth: 100,
      maxWidth: 1500,
      resizable: true,
      editorOptions: {
        editOnClick: false,
        onNavigation: handleKeyPressOnCell,
      },
      formatter({ row, isCellSelected }: FormatterProps<APICommercialInvoiceLineItem | APIPackingListLineItem>) {
        return descriptionFormatter(row, isCellSelected && productCodeAndDescriptionDropdownDisabled);
      },
      editable: () => { return !props.disabled && !productCodeAndDescriptionDropdownDisabled; },
      editor: (editorProps: EditorProps<APICommercialInvoiceLineItem | APIPackingListLineItem>) => {
        if (editorProps.row.matched) return descriptionFormatter(editorProps.row);
        if (productCodeAndDescriptionDropdownDisabled) return descriptionFormatter(editorProps.row, true);
        return props.useOrganisations
          ? <LineItemsSelector
            property={'description'}
            matchedProperty={'matchedDescription'}
            propertyText={'description'}
            isDisabled={productCodeAndDescriptionDropdownDisabled}
            editorProps={editorProps}
            supplierCgwCode={props.supplierCgwCode}
            importerCgwCode={props.importerCgwCode}
            updateLineItemRows={updateLineItemRows}
            updateLineItemsDB={onLineItemCellBlur}
            matchRows={matchRows}
            gridSelectStyle={gridSelectStyle}
          /> :
          genericTextInput(editorProps, 'description');
      },
    };
  }, [props.disabled, props.supplierCgwCode, props.importerCgwCode, lineItemRows]);

  const columnMarks: Column<APICommercialInvoiceLineItem | APIPackingListLineItem> = React.useMemo(() => {
    return {
      key: 'marks',
      name: 'Marks',
      headerCellClass: "marks",
      minWidth: 65,
      width: 65,
      maxWidth: 500,
      resizable: true,
      editorOptions: {
        editOnClick: false,
        onNavigation: handleKeyPressOnCell,
      },
      formatter({ row, isCellSelected }: FormatterProps<APICommercialInvoiceLineItem | APIPackingListLineItem>) {
        if (isCILineItem(row)) return <></>;
        return <span className="table-text-detail">{row.marks}</span>;
      },
      editable: () => { return !props.disabled && !productCodeAndDescriptionDropdownDisabled; },
      editor: (editorProps: EditorProps<APICommercialInvoiceLineItem | APIPackingListLineItem>) => {
        return genericTextInput(editorProps, 'marks');
      },
    };
  }, [props.disabled, lineItemRows]);

  const columnOrigin = React.useMemo(() => {
    return {
      key: 'originCountryId',
      name: 'Origin',
      headerCellClass: "origin",
      width: 52,
      minWidth: 52,
      maxWidth: 52,
      resizable: true,
      editorOptions: {
        editOnClick: false,
        onNavigation: handleKeyPressOnCell,
      },
      editable: () => { return !props.disabled; },
      formatter({ row, rowIdx }: FormatterProps<APICommercialInvoiceLineItem>) {
        return countryFormatter(row, rowIdx);
      },
      editor: ({ row, rowIdx, onRowChange, editorPortalTarget }: EditorProps<APICommercialInvoiceLineItem>) => {
        return <Select
          autoFocus
          defaultMenuIsOpen
          value={props.countryOptions?.find((option) => option.value === (row.originCountryId || row.matchedOriginCountryId)) || undefined}
          options={emptyOptionArray.concat(props.countryOptions || [])}
          className="grid-selector"
          onChange={async (option: any) => {
            let updatedRow: APICommercialInvoiceLineItem | APIPackingListLineItem | undefined = { ...row, originCountryId: option?.value, originCountry: getCountryName(option) };
            if (option.value === null) {
              updatedRow.matchedOriginCountryId = undefined;
            }
            onRowChange(updatedRow, true);
            updatedRow = await onLineItemCellBlur(updatedRow, 'originCountry', getCountryName(option) || null);
            if (updatedRow) await onLineItemCellBlur(updatedRow, 'originCountryId', option?.value);
          }}
          menuPortalTarget={editorPortalTarget as HTMLElement}
          styles={gridSelectStyle(42)}
        />;
      }
    };
  }, [props.disabled, props.supplierCgwCode, props.importerCgwCode, lineItemRows]);

  const columnUnityType: Column<APICommercialInvoiceLineItem> = React.useMemo(() => {
    return {
      key: 'unitType',
      headerCellClass: "unit_type",
      name: <span title="Unit type">Unit</span>,
      width: 52,
      minWidth: 52,
      maxWidth: 52,
      resizable: true,
      editorOptions: {
        editOnClick: false,
        onNavigation: handleKeyPressOnCell,
      },
      formatter({ row, rowIdx }: FormatterProps<APICommercialInvoiceLineItem>) {
        return unitTypeFormatter(row, rowIdx);
      },
      editable: () => { return !props.disabled; },
      editor: ({ row, rowIdx, onRowChange, editorPortalTarget }: EditorProps<APICommercialInvoiceLineItem>) => {
        return <Select
          autoFocus
          defaultMenuIsOpen
          value={props.unitTypeOptions.find((option) => option.value === (row.matchedUnitType || row.unitType)) || undefined}
          options={emptyOptionArray.concat(props.unitTypeOptions)}
          className="grid-selector"
          onChange={async (option: any) => {
            const updatedRow = {
              ...row,
              unitType: option?.value,
              ...(row.matchedUnitType ? { matchedUnitType: null } : {})
            };
            onRowChange(updatedRow, true);
            onLineItemCellBlur(updatedRow, 'unitType', option?.value);
            if (row.matchedUnitType) onLineItemCellBlur(updatedRow, 'matchedUnitType', null);
          }}
          menuPortalTarget={editorPortalTarget as HTMLElement}
          styles={gridSelectStyle(42)}
        />;
      }
    };
  }, [props.disabled, lineItemRows]);

  const columnQuantity: Column<APICommercialInvoiceLineItem> = React.useMemo(() => {
    return {
      key: 'quantity',
      headerCellClass: "quantity",
      name: <span title="Quantity">QTY</span>,
      width: 50,
      minWidth: 50,
      maxWidth: 100,
      resizable: true,
      editorOptions: {
        editOnClick: false,
        onNavigation: handleKeyPressOnCell,
      },
      formatter({ row }: any) {
        return <div className={`totals`}>{isNaN(row.quantity) ? '' : formatNumber(row.quantity, { ignorePrecision: true })}</div>;
      },
      editable: () => { return !props.disabled; },
      editor: ({ row, onRowChange, onClose }: EditorProps<APICommercialInvoiceLineItem>) => {
        return <NumberInput
          value={row.quantity || null}
          name="quantity"
          ignorePrecision={true}
          autoFocus={true}
          autoSelect={true}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (keysToCommitChange.includes(event.key)) (event.target as HTMLElement).blur();
          }}
          onChange={(value, field) => {
            onRowChange({ ...row, [field]: value }, false);
          }}
          onBlur={(field, value, withoutChange) => {
            onClose(!withoutChange);
            onLineItemCellBlur(row, field, value, withoutChange);
          }}
        />;
      }
    };
  }, [props.disabled, lineItemRows]);

  const columnUnitPrice: Column<APICommercialInvoiceLineItem> = React.useMemo(() => {
    return {
      key: 'unitPrice',
      headerCellClass: "unit_price",
      name: <span title="Price per unit">PPU</span>,
      minWidth: 50,
      width: 50,
      maxWidth: 100,
      resizable: true,
      editorOptions: {
        editOnClick: false,
        onNavigation: handleKeyPressOnCell,
      },
      formatter({ row }: any) {
        return <div className={`totals`}>{isNaN(row.unitPrice) ? '' : formatNumber(row.unitPrice, { precision: 8, minPrecision: 2 })}</div>;
      },
      editable: () => { return !props.disabled; },
      editor: ({ row, onRowChange, onClose }: EditorProps<APICommercialInvoiceLineItem>) => {
        return <NumberInput
          value={row.unitPrice || null}
          name="unitPrice"
          precision={8}
          minPrecision={2}
          autoFocus={true}
          autoSelect={true}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (keysToCommitChange.includes(event.key)) (event.target as HTMLElement).blur();
          }}
          onChange={(value, field) => {
            onRowChange({ ...row, [field]: value }, false);
          }}
          onBlur={(field, value, withoutChange) => {
            onClose(!withoutChange);
            onLineItemCellBlur(row, field, value, withoutChange);
          }}
        />;
      }
    };
  }, [props.disabled, lineItemRows]);

  const columnLineTotal: Column<APICommercialInvoiceLineItem> = React.useMemo(() => {
    return {
      key: 'lineTotal',
      headerCellClass: "line_total",
      name: <span title="Line total">Total</span>,
      width: 70,
      maxWidth: 100,
      resizable: true,
      editorOptions: {
        editOnClick: false,
        onNavigation: handleKeyPressOnCell,
      },
      formatter({ row }: any) {
        if (!commercialInvoiceLineItemsErrors(row, props.isNonCargowise)[row.id]) return <></>;
        const { totalLength, totalAmount } = commercialInvoiceLineItemsErrors(row, props.isNonCargowise)[row.id];
        const content = <div className={`totals ${(totalLength?.error || totalAmount?.error) ? 'warning-cell' : ''}`}>{isNaN(row.lineTotal) ? '' : formatNumber(row.lineTotal, { precision: 2 })}</div>;
        if (totalLength?.error || totalAmount?.error) {
          return (
            <OverlayTrigger overlay={<Tooltip><div className="text-left">
              {totalLength?.error ? <>{totalLength?.error}<br /></> : <></>}
              {totalAmount?.error ? <>{totalAmount?.error}</> : <></>}
            </div></Tooltip >} delayShow={500} placement="left" >
              {content}
            </OverlayTrigger >
          );
        }
        return content;
      },
      editable: () => { return !props.disabled; },
      editor: ({ row, onRowChange, onClose }: EditorProps<APICommercialInvoiceLineItem>) => {
        return <NumberInput
          value={row.lineTotal || null}
          name="lineTotal"
          precision={2}
          autoFocus={true}
          autoSelect={true}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (keysToCommitChange.includes(event.key)) (event.target as HTMLElement).blur();
          }}
          onChange={(value, field) => {
            onRowChange({ ...row, [field]: value }, false);
          }}
          onBlur={(field, value, withoutChange) => {
            onClose(!withoutChange);
            onLineItemCellBlur(row, field, value, withoutChange);
          }}
        />;
      },
    };
  }, [props.disabled, lineItemRows]);

  const columnNetWeight: Column<APIPackingListLineItem> = React.useMemo(() => {
    return {
      key: 'netWeight',
      name: <span title="Line total">NT. WT.</span>,
      headerCellClass: "net_weight",
      width: 75,
      minWidth: 75,
      maxWidth: 75,
      resizable: true,
      editorOptions: {
        editOnClick: false,
        onNavigation: handleKeyPressOnCell,
      },
      formatter({ row }: any) {
        return <div className={`totals`}>{isNaN(row.netWeight) ? '' : formatNumber(row.netWeight, { precision: 2 })}</div>;
      },
      editable: () => { return !props.disabled; },
      editor: ({ row, onRowChange, onClose }: EditorProps<APIPackingListLineItem>) => {
        return <NumberInput
          value={row.netWeight || null}
          name="netWeight"
          precision={2}
          autoFocus={true}
          autoSelect={true}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (keysToCommitChange.includes(event.key)) (event.target as HTMLElement).blur();
          }}
          onChange={(value, field) => {
            onRowChange({ ...row, [field]: value }, false);
          }}
          onBlur={(field, value, withoutChange) => {
            onClose(!withoutChange);
            onLineItemCellBlur(row, field, value, withoutChange);
          }}
        />;
      },
    };
  }, [props.disabled, lineItemRows]);

  const columnGrossWeight: Column<APIPackingListLineItem> = React.useMemo(() => {
    return {
      key: 'grossWeight',
      name: <span title="Line total">GR. WT.</span>,
      headerCellClass: "gross_weight",
      width: 75,
      minWidth: 75,
      maxWidth: 75,
      resizable: true,
      editorOptions: {
        editOnClick: false,
        onNavigation: handleKeyPressOnCell,
      },
      formatter({ row }: any) {
        return <div className={`totals`}>{isNaN(row.grossWeight) ? '' : formatNumber(row.grossWeight, { precision: 2 })}</div>;
      },
      editable: () => { return !props.disabled; },
      editor: ({ row, onRowChange, onClose }: EditorProps<APIPackingListLineItem>) => {
        return <NumberInput
          value={row.grossWeight || null}
          name="grossWeight"
          precision={2}
          autoFocus={true}
          autoSelect={true}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (keysToCommitChange.includes(event.key)) (event.target as HTMLElement).blur();
          }}
          onChange={(value, field) => {
            onRowChange({ ...row, [field]: value }, false);
          }}
          onBlur={(field, value, withoutChange) => {
            onClose(!withoutChange);
            onLineItemCellBlur(row, field, value, withoutChange);
          }}
        />;
      },
    };
  }, [props.disabled, lineItemRows]);

  const columnVolume: Column<APIPackingListLineItem> = React.useMemo(() => {
    return {
      key: 'volume',
      name: <span title="Line total">Volume</span>,
      headerCellClass: "volume",
      width: 75,
      minWidth: 75,
      maxWidth: 75,
      resizable: true,
      editorOptions: {
        editOnClick: false,
        onNavigation: handleKeyPressOnCell,
      },
      formatter({ row }: any) {
        return <div className={`totals`}>{isNaN(row.volume) ? '' : formatNumber(row.volume, { precision: 2 })}</div>;
      },
      editable: () => { return !props.disabled; },
      editor: ({ row, onRowChange, onClose }: EditorProps<APIPackingListLineItem>) => {
        return <NumberInput
          value={row.volume || null}
          name="volume"
          precision={2}
          autoFocus={true}
          autoSelect={true}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (keysToCommitChange.includes(event.key)) (event.target as HTMLElement).blur();
          }}
          onChange={(value, field) => {
            onRowChange({ ...row, [field]: value }, false);
          }}
          onBlur={(field, value, withoutChange) => {
            onClose(!withoutChange);
            onLineItemCellBlur(row, field, value, withoutChange);
          }}
        />;
      },
    };
  }, [props.disabled, lineItemRows]);

  const columnItemsQty: Column<APIPackingListLineItem> = React.useMemo(() => {
    return {
      key: 'itemQty',
      name: <span title="Line total">Items</span>,
      headerCellClass: "item_quantity",
      width: 55,
      minWidth: 55,
      maxWidth: 70,
      resizable: true,
      editorOptions: {
        editOnClick: false,
        onNavigation: handleKeyPressOnCell,
      },
      formatter({ row }: any) {
        return <div className={`totals`}>{isNaN(row.itemQty) ? '' : formatNumber(row.itemQty, { ignorePrecision: true })}</div>;
      },
      editable: () => { return !props.disabled; },
      editor: ({ row, onRowChange, onClose }: EditorProps<APIPackingListLineItem>) => {
        return <NumberInput
          value={row.itemQty || null}
          name="itemQty"
          ignorePrecision={true}
          autoFocus={true}
          autoSelect={true}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (keysToCommitChange.includes(event.key)) (event.target as HTMLElement).blur();
          }}
          onChange={(value, field) => {
            onRowChange({ ...row, [field]: value }, false);
          }}
          onBlur={(field, value, withoutChange) => {
            onClose(!withoutChange);
            onLineItemCellBlur(row, field, value, withoutChange);
          }}
        />;
      },
    };
  }, [props.disabled, lineItemRows]);

  const columnPacksQty: Column<APIPackingListLineItem> = React.useMemo(() => {
    return {
      key: 'packageQty',
      name: <span title="Line total">Packs</span>,
      headerCellClass: "package_quantity",
      width: 55,
      minWidth: 55,
      maxWidth: 70,
      resizable: true,
      editorOptions: {
        editOnClick: false,
        onNavigation: handleKeyPressOnCell,
      },
      formatter({ row }: any) {
        return <div className={`totals`}>{isNaN(row.packageQty) ? '' : formatNumber(row.packageQty, { ignorePrecision: true })}</div>;
      },
      editable: () => { return !props.disabled; },
      editor: ({ row, onRowChange, onClose }: EditorProps<APIPackingListLineItem>) => {
        return <NumberInput
          value={row.packageQty || null}
          name="packageQty"
          ignorePrecision={true}
          autoFocus={true}
          autoSelect={true}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (keysToCommitChange.includes(event.key)) (event.target as HTMLElement).blur();
          }}
          onChange={(value, field) => {
            onRowChange({ ...row, [field]: value }, false);
          }}
          onBlur={(field, value, withoutChange) => {
            onClose(!withoutChange);
            onLineItemCellBlur(row, field, value, withoutChange);
          }}
        />;
      },
    };
  }, [props.disabled, lineItemRows]);

  const columnVisibilitySelector: Column<APIPackingListLineItem> = React.useMemo(() => {
    return {
      key: 'columnVisibility',
      name: (<div className="columns-selector-icon" onClick={() => setColumnsVisibilitySelectorEnable(true)} >
        <ShipamaxSettingsIcon />
      </div>),
      width: 40,
      minWidth: 40,
      resizable: false,
      formatter({ row }: any) { return <></>; },
      editable: false,
    };
  }, []);

  React.useEffect(() => {
    setColDetails([...userSettings.blSettings?.lineItemsColOrder?.find((cls) => cls.section === props.parentDocumentType)?.columns || []]);
  }, [props.parentDocumentType]);

  React.useEffect(() => {
    // Commercial Invoie Line Items and Packing Line Line Items have distinct columns (some are on both)
    setColumns((props.parentDocumentType === DocumentType.CommercialInvoice) ? [
      columnMenuCI,
      columnProductCode,
      columnHsCode,
      columnDescription,
      columnOrigin,
      columnUnityType,
      columnQuantity,
      columnUnitPrice,
      columnLineTotal,
      columnVisibilitySelector,
    ] : [
      columnMenuPL,
      columnProductCode,
      columnHsCode,
      columnDescription,
      columnMarks,
      columnNetWeight,
      columnGrossWeight,
      columnVolume,
      columnItemsQty,
      columnPacksQty,
      columnVisibilitySelector,
    ]);
  }, [props.parentDocumentType, props.disabled, props.supplierCgwCode, props.importerCgwCode, lineItemRows]);

  React.useEffect(() => {
    const settings = { ...userSettings };
    let cols = settings.blSettings?.lineItemsColOrder?.find((cls) => cls.section === (props.parentDocumentType));
    if (!cols || !cols.columns) {
      cols = {
        section: props.parentDocumentType,
        columns: colDetails,
      }
      if (settings.blSettings && settings.blSettings.lineItemsColOrder && settings.blSettings.lineItemsColOrder.length > 0) {
        settings.blSettings.lineItemsColOrder.push(cols);
      } else if (settings.blSettings) {
        settings.blSettings.lineItemsColOrder = [cols];
      }
    } else {
      cols.columns = colDetails;
    }
    if (settings.update) settings.update(settings);
  }, [colDetails]);

  const currentRow = currentRowIdx !== null ? lineItemRows[currentRowIdx] : null;
  const currentRowCssSelector = `.line-items-${props.parentId} .react-contextmenu-wrapper:nth-child(${(currentRowIdx !== null ? currentRowIdx : -100) + 4}) .rdg-row`;

  const customCss = `
    ${currentRowCssSelector} {
      border: 1px solid #0085FF;
      border-radius: 5px;
      margin-top: -2px;
    }

    ${currentRowCssSelector} .rdg-cell {
      border: none;
      box-shadow: none !important;
    }

    ${currentRowCssSelector} .rdg-cell:first-child {
      padding-top: 1px;
    }

    ${currentRowCssSelector} .rdg-cell .rdg-cell-drag-handle {
      display: none;
    } `

  const enableAggregate = lineItemRows.some((l) => l.hsCode?.trim() || (l.matched && l.matchedHsCode));

  return (
    <div className={`line-items line-items-${props.parentId} ${lineItemRows.length ? '' : 'container--empty'}`}>
      <style>
        {customCss}
      </style>
      <WrapperLabel text={`LINE ITEMS ${isAggregated ? '(AGGREGATED)' : ''}`} />

      {loading &&
        <div className="overlay active">
          <Icon style={{ fontSize: '40px', margin: '60px calc(50% - 20px)' }} src="spinner-md" />
        </div>
      }
      <div className="line-items__controls-top">
        {isAggregated && (
          <OverlayTrigger overlay={<Tooltip><span>Remove line aggregation</span></Tooltip>} delayShow={500} placement="bottom">
            <button
              className="manage-row-button light-button active-on-hover"
              onClick={() => onClickRemoveLinesAggregation()}
            >Remove Aggregation</button>
          </OverlayTrigger>
        )}
        {(!isAggregated && props.parentDocumentType === DocumentType.CommercialInvoice) && !props.readonly && (
          <OverlayTrigger overlay={<Tooltip>
            {enableAggregate ? <span>Merge lines based on HS Code and Origin</span> : <span>No lines to be aggregated</span>}
          </Tooltip>} delayShow={500} placement="bottom">
            <button
              className={`manage-row-button light-button ${enableAggregate ? 'active-on-hover' : 'disabled'}`}
              onClick={() => enableAggregate && onAggregateLines()}
            >Aggregate Lines</button>
          </OverlayTrigger>
        )}
        {Boolean(lineItemRows.length) && !props.readonly && (
          <OverlayTrigger overlay={<Tooltip><span>Delete all {isAggregated ? 'aggregated' : ''} line items</span></Tooltip>} delayShow={500} placement="bottom">
            <button
              className="manage-row-button light-button active-on-hover"
              onClick={() => onClickRemoveAllLines()}
            >Delete All Lines</button>
          </OverlayTrigger>
        )}
      </div>
      <ColumnVisibilitySelector
        modalCssClass='absolute right-0 z-50 line-items-top'
        colDetails={colDetails}
        columns={columns}
        visible={columnsVisibilitySelectorEnable}
        setVisible={setColumnsVisibilitySelectorEnable}
        updateUserColHiddenSettings={updateUserColHiddenSettings}
      />
      <DndProvider backend={HTML5Backend}>
        <DataGrid
          ref={gridRef}
          columns={draggableColumns}
          rows={lineItemRows}
          className="line-items-grid rdg-light small-grid with-custom-scrollbar"
          onFill={handleFill}
          onPaste={handlePaste}
          selectedRows={selectedRows}
          onSelectedRowsChange={setSelectedRows}
          onSelectedCellChange={onSelectCell}
          onRowsChange={setLineItemRows}
          emptyRowsRenderer={() => (<div className="no-line-items">No Line Items</div>)}
          rowHeight={42}
          headerRowHeight={40}
          style={{
            height: gridHeight,
          }}
          rowRenderer={RowRenderer}
          cellNavigationMode={'CHANGE_ROW'}
        />
      </DndProvider>
      {
        !lineItemRows.length && (
          <div className="line-items__controls">
            <button
              className="manage-row-button"
              onClick={() => addLineItemRow()}
              data-test-id="add-line-item-btn"
              disabled={props.disabled}
            ><ShipamaxPlusIcon />Add a Line Item</button>
          </div>
        )
      }
      {
        createPortal(
          <>
            <ContextMenu id={`grid-context-menu-${props.parentId}`}>
              <MenuItem
                onClick={(event, data: ContextMenuEventData) => addLineItemRow(data.rowIdx)}
              ><ShipamaxAddRowAboveIcon />Add line above</MenuItem>
              <MenuItem
                disabled={currentRow ? currentRow.orderIndex >= lineItemRows.length - 2 : true}
                onClick={(event, data: ContextMenuEventData) => addLineItemRow(data.rowIdx + 1)}
              ><ShipamaxAddRowBelowIcon />Add line below</MenuItem>
              <MenuItem
                onClick={(event, data: ContextMenuEventData) => deleteLineItemRow(data.row.id, data.row.originalIds)}
              ><ShipamaxTrashIcon />Delete line</MenuItem>
              <MenuItem
                disabled={currentRow ? !currentRow.matched : true}
                onClick={(event, data: ContextMenuEventData) => unMatchRow(data.row.id)}
              ><ShipamaxUnlinkIcon />Unlink product</MenuItem>
            </ContextMenu>
          </>,
          document.querySelector('body #root > div') || document.body
        )
      }
    </div >
  );
}
