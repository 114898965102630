import * as React from 'react';
import { AdminHeader } from '../../AdminHeader';
import { APIAutomatedTolerance, DiscrepancyType, } from '../../../../api/automatedTolerances';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { isFormInvalid, RecordValidator } from '../../../common/Form';
import { APISite } from '../../../../api/sites';
import { WrapperLabel } from '../../../common/WrapperLabel';
import { NumberInput } from '../../../common/NumberInput';
import { TextInput } from '../../../common/TextInput';
import { EmailAccountAPI } from '../../../../api/emailAccount';
import { FormSubHeadline } from '../../admin-components';
import { Toggle } from '../../../common/Toggle';

interface UrlParams {
  id: string;
}

interface Props {
  data?: APIAutomatedTolerance;
}

export const AutomatedXRToleranceForm: React.FC<Props> = (props) => {
  const urlParams = new URLSearchParams(location.search);
  const environment = urlParams.get('env');
  const params = useParams<UrlParams>();
  const id = parseInt(params.id);

  const history = useHistory();
  const [record, setRecord] = React.useState<Partial<APIAutomatedTolerance>>({
    id,
    xrUpperAbsoluteMax: 0,
    xrUpperPercentageMax: 0,
    xrSetSeparateLimit: false,
    discrepancyType: DiscrepancyType.ExchangeRateDiscrepancy,
  });

  const [formEntered, setFormEntered] = React.useState<boolean>(false);

  const recordValidator: RecordValidator = {
    xrUpperAbsoluteMax: {
      validators: [{
        isValid: (xrUpperAbsoluteMax) => xrUpperAbsoluteMax >= 0 && xrUpperAbsoluteMax <= 1000000,
        errorMessage: 'Value needs to be between 0 and 1,000,000'
      }]
    },
    xrLowerAbsoluteMax: {
      validators: [{
        isValid: (xrLowerAbsoluteMax) => xrLowerAbsoluteMax >= 0 && xrLowerAbsoluteMax <= 1000000,
        errorMessage: 'Value needs to be between 0 and 1,000,000'
      }]
    },
    xrUpperPercentageMax: {
      validators: [{
        isValid: (xrUpperPercentageMax) => xrUpperPercentageMax >= 0 && xrUpperPercentageMax <= 1000000,
        errorMessage: 'Value needs to be between 0 and 1,000,000'
      }]
    },
    xrLowerPercentageMax: {
      validators: [{
        isValid: (xrLowerPercentageMax) => xrLowerPercentageMax >= 0 && xrLowerPercentageMax <= 1000000,
        errorMessage: 'Value needs to be between 0 and 1,000,000'
      }]
    },
  }

  const submitDisabled = isFormInvalid<APISite>(record, recordValidator);

  React.useEffect(() => {
    if (params.id) {
      EmailAccountAPI.fetch(parseInt(params.id)).then((mailboxData) => {
        const recordData = {
          id: mailboxData.id,
          emailAddr: mailboxData.emailAddr,
          localCurrency: mailboxData.localCurrency,
          discrepancyType: DiscrepancyType.ExchangeRateDiscrepancy,
          xrUpperAbsoluteMax: mailboxData.xrUpperAbsoluteMax,
          xrLowerAbsoluteMax: mailboxData.xrLowerAbsoluteMax,
          xrUpperPercentageMax: mailboxData.xrUpperPercentageMax,
          xrLowerPercentageMax: mailboxData.xrLowerPercentageMax,
          xrSetSeparateLimit: mailboxData.xrSetSeparateLimit,
        }
        setRecord(recordData);
      });
    }
  }, [params.id]);


  const onSave = async () => {
    setFormEntered(true);
    if (submitDisabled) return;

    if (record.id) {
      await EmailAccountAPI.update(
        record.id,
        {
          xrUpperAbsoluteMax: record.xrUpperAbsoluteMax,
          xrUpperPercentageMax: record.xrUpperPercentageMax,
          xrLowerAbsoluteMax: record.xrSetSeparateLimit ? record.xrLowerAbsoluteMax : record.xrUpperAbsoluteMax,
          xrLowerPercentageMax: record.xrSetSeparateLimit ? record.xrLowerPercentageMax : record.xrUpperPercentageMax,
          xrSetSeparateLimit: record.xrSetSeparateLimit,
        });
    }

    history.push(AdminLinks.AutomatedTolerances);
  }

  return (
    <div className="admin__form__wrapper with-custom-scrollbar permission-profile-form">
      <AdminHeader
        headline='Edit AP automated tolerance'
        tagText={environment ? 'Test workspace' : 'Production workspace'}
        links={[{
          label: 'What are automated tolerances?',
          url: '/knowledge-base/What-are-tolerances-b-b--a04664929e0045d48e1e5f6cef5f627a#8b6013cae88c42e49e4c31d92c4a36a2',
        }]}
      />
      <div className="admin__form__content">
        <div>
          <WrapperLabel text="Threshold Type">
            <TextInput
              disabled={true}
              value={"Exchange Rate Discrepancy"}
              name="thresholdType"
            />
          </WrapperLabel>
          <WrapperLabel text="Mailbox address">
            <TextInput
              disabled={true}
              value={record.emailAddr || ''}
              name="thresholdType"
            />
          </WrapperLabel>
          <WrapperLabel text="Local Currency">
            <TextInput
              disabled={true}
              value={record.localCurrency || ''}
              name="thresholdType"
            />
          </WrapperLabel>
          <FormSubHeadline headline="Thresholds for updating accrual amounts" />
          <Toggle
            id="setSeparateLimit"
            label="Set separate upper and lower thresholds"
            checked={record.xrSetSeparateLimit || false}
            onChecked={(name, value) => setRecord({ ...record, xrSetSeparateLimit: value })}
          />
          <table>
            <thead>
              <tr>
                <th>Threshold</th>
                <th>Absolute maximum</th>
                <th>Percentage maximum</th>
              </tr>
            </thead>
            {!record.xrSetSeparateLimit ? (
              <tbody>
                <tr>
                  <td>Universal Limit</td>
                  <td>
                    <NumberInput
                      value={record.xrUpperAbsoluteMax || 0}
                      onChange={(value) => setRecord({
                        ...record,
                        xrUpperAbsoluteMax: value || 0, xrLowerAbsoluteMax: value || 0
                      })}
                      name={'xrUpperAbsoluteMax'}
                      validators={recordValidator.xrUpperAbsoluteMax.validators}
                      ignorePrecision
                    />
                  </td>
                  <td>
                    <NumberInput
                      value={record.xrUpperPercentageMax || 0}
                      onChange={(value) => setRecord({
                        ...record,
                        xrUpperPercentageMax: value || 0, xrLowerPercentageMax: value || 0
                      })}
                      name={'xrUpperPercentageMax'}
                      validators={recordValidator.xrUpperPercentageMax.validators}
                      ignorePrecision
                      suffix="%"
                    />
                  </td>
                </tr>
              </tbody>
            ) :
              (
                <tbody>
                  <tr>
                    <td>Upper Limit</td>
                    <td>
                      <NumberInput
                        value={record.xrUpperAbsoluteMax || 0}
                        onChange={(value) => setRecord({
                          ...record,
                          xrUpperAbsoluteMax: value || 0
                        })}
                        name={'xrUpperAbsoluteMax'}
                        validators={recordValidator.xrUpperAbsoluteMax.validators}
                        ignorePrecision
                      />
                    </td>
                    <td>

                      <NumberInput
                        value={record.xrUpperPercentageMax || 0}
                        onChange={(value) => setRecord({
                          ...record,
                          xrUpperPercentageMax: value || 0
                        })}
                        name={'xrUpperPercentageMax'}
                        suffix="%"
                        validators={recordValidator.xrUpperPercentageMax.validators}
                        ignorePrecision
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Lower Limit</td>
                    <td>
                      <NumberInput
                        value={record.xrLowerAbsoluteMax || 0}
                        onChange={(value) => setRecord({
                          ...record,
                          xrLowerAbsoluteMax: value || 0
                        })}
                        name={'xrLowerAbsoluteMax'}
                        validators={recordValidator.xrLowerAbsoluteMax.validators}
                        ignorePrecision
                      />
                    </td>
                    <td>
                      <NumberInput
                        value={record.xrLowerPercentageMax || 0}
                        onChange={(value) => setRecord({
                          ...record,
                          xrLowerPercentageMax: value || 0
                        })}
                        name={'xrLowerPercentageMax'}
                        validators={recordValidator.xrLowerPercentageMax.validators}
                        suffix="%"
                        ignorePrecision
                      />
                    </td>
                  </tr>
                </tbody>
              )}
          </table>
        </div>
        <div>
          <Link to={AdminLinks.AutomatedTolerances}><button className="light-button">Cancel</button></Link>
          <button className="full-button" onClick={onSave}>Save</button>
        </div>
      </div>
    </div>
  )
}
