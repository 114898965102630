import * as React from 'react';
import { Notification } from '../../../common/Notification';
import { WebhookTable } from './WebhookTable';
import { Route, Switch, useHistory } from 'react-router-dom';
import { WebhookForm } from './WebhookForm';
import { AdminLinks } from '../../Admin';

import './index.scss';
import { FileError } from '../product-data-imports';

interface Props {
  setNotification: (notification: Notification | null) => void;
}

export const WebhookSection: React.FC<Props> = (props) => {
  const [fileErrors, setFileErrors] = React.useState<FileError[]>([]);
  const history = useHistory();
  history.listen(() => {
    setFileErrors([]);
  });

  return (
    <Switch>
      <Route path={AdminLinks.Webhooks} exact component={() => (
        <WebhookTable />
      )} />
      <Route path={[AdminLinks.Webhooks + '/form', AdminLinks.Webhooks + '/form/:id']} exact component={() => (
        <WebhookForm setNotification={props.setNotification} fileErrors={fileErrors} setFileErrors={setFileErrors} />
      )} />
    </Switch>
  );
};
