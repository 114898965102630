import * as React from 'react';
import { CompanyAPI } from '../../../api/company';
import { ChartColors, formatAggregationValue, SerieSettings } from '../common';
import { ChartCommonProps, ChartFilterProps, CounterType } from '../ChartWrapper';
import { GeneralChart } from '../GeneralChart';
import { Map2 } from '../../helpers';
import * as moment from 'moment';
import { removeDataForSaveToggles } from '../utils';

enum SerieId {
  ExceptionNOT = 'NOT',
  ExceptionTIN = 'TIN',
  ExceptionTWT = 'TWT',
  ExceptionSUM = 'SUM',
  ExceptionICP = 'ICP',
  ExceptionNCP = 'NCP',
  ExceptionREF = 'REF',
  ExceptionCUR = 'CUR',
  ExceptionSHO = 'SHO',
  ExceptionAPI = 'API',
  ExceptionFXR = 'FXR',
  ExceptionOTH = 'OTH',
  ExceptionEXT = 'EXT',
  ExceptionDCH = 'DCH',
  ExceptionDUP = 'DUP',
  ExceptionAUC = 'AUC',
  ExceptionEAS = 'EAS',
  ExceptionUNX = 'UNX',
  ExceptionMUL = 'MUL',
  ExceptionNON = 'NON',
  ExceptionOVE = 'OVE',
  ExceptionINC = 'INC',
  ExceptionADD = 'ADD',
  ExceptionAUT = 'AUT',
  ExceptionBPO = 'BPO',
  ExceptionBDI = 'BDI',
  ExceptionBRE = 'BRE',
  ExceptionNoCode = 'NO_CODE'
}

const seriesData = new Map2<string, SerieSettings>([
  // General codes
  [
    SerieId.ExceptionEXT,
    {
      label: 'EXT',
      color: ChartColors.Color11,
      tooltip: 'Incorrect data extraction',
      subSection: 'General',
    }
  ],
  [
    SerieId.ExceptionNCP,
    {
      label: 'NCP',
      color: ChartColors.Color4,
      tooltip: 'No cost provision in TMS',
      subSection: 'General',
    }
  ],
  [
    SerieId.ExceptionICP,
    {
      label: 'ICP',
      color: ChartColors.Color3,
      tooltip: 'Incorrect cost provision in TMS',
      subSection: 'General',
    }
  ],
  [
    SerieId.ExceptionCUR,
    {
      label: 'CUR',
      color: ChartColors.Color6,
      tooltip: 'Incorrect currency in TMS',
      subSection: 'General',
    }
  ],
  [
    SerieId.ExceptionREF,
    {
      label: 'REF',
      color: ChartColors.Color5,
      tooltip: 'No reference on invoice',
      subSection: 'General',
    }
  ],
  [
    SerieId.ExceptionSUM,
    {
      label: 'SUM',
      color: ChartColors.Color2,
      tooltip: 'Incorrect total on invoice',
      subSection: 'General',
    }
  ],
  [
    SerieId.ExceptionTWT,
    {
      label: 'TWT',
      color: ChartColors.Color1,
      tooltip: 'Tax incorrect within TMS',
      subSection: 'General',
    }
  ],
  [
    SerieId.ExceptionSHO,
    {
      label: 'SHO',
      color: ChartColors.Color7,
      tooltip: 'Short pay',
      subSection: 'General',
    }
  ],
  [
    SerieId.ExceptionAPI,
    {
      label: 'API',
      color: ChartColors.Color8,
      tooltip: 'Accrual Partially Invoiced',
      subSection: 'General',
    }
  ],
  [
    SerieId.ExceptionFXR,
    {
      label: 'FXR',
      color: ChartColors.Color9,
      tooltip: 'Incorrect exchange rate in TMS',
      subSection: 'General',
    }
  ],
  [
    SerieId.ExceptionMUL,
    {
      label: 'MUL',
      color: ChartColors.Color18,
      tooltip: 'Multiple possible accrual combinations',
      subSection: 'General',
    }
  ],
  [
    SerieId.ExceptionOTH,
    {
      label: 'OTH',
      color: ChartColors.Color10,
      tooltip: 'Other',
      subSection: 'General',
    }
  ],
  [
    SerieId.ExceptionOVE,
    {
      label: 'OVE',
      color: ChartColors.Color20,
      tooltip: 'Overhead invoice',
      subSection: 'General',
    }
  ],

  // Discard codes
  [
    SerieId.ExceptionEAS,
    {
      label: 'EAS',
      color: ChartColors.Color15,
      tooltip: 'Easier without Shipamax',
      subSection: 'Discard',
    }
  ],
  [
    SerieId.ExceptionADD,
    {
      label: 'ADD',
      color: ChartColors.Color22,
      tooltip: 'Incorrect addressee on invoice',
      subSection: 'Discard',
    }
  ],
  [
    SerieId.ExceptionAUT,
    {
      label: 'AUT',
      color: ChartColors.Color25,
      tooltip: 'Automatically discarded',
      subSection: 'Discard',
    }
  ],
  [
    SerieId.ExceptionNOT,
    {
      label: 'NOT',
      color: ChartColors.Color23,
      tooltip: 'Not an invoice',
      subSection: 'Discard',
    }
  ],
  [
    SerieId.ExceptionTIN,
    {
      label: 'TIN',
      color: ChartColors.Color24,
      tooltip: 'Incorrect Tax on invoice',
      subSection: 'Discard',
    }
  ],
  [
    SerieId.ExceptionNON,
    {
      label: 'NON',
      color: ChartColors.Color19,
      tooltip: 'Non-pay',
      subSection: 'Discard',
    }
  ],
  [
    SerieId.ExceptionDUP,
    {
      label: 'DUP',
      color: ChartColors.Color13,
      tooltip: 'Duplicate invoice',
      subSection: 'Discard',
    }
  ],

  // In Query
  [
    SerieId.ExceptionAUC,
    {
      label: 'AUC',
      color: ChartColors.Color14,
      tooltip: 'Additional unexpected charges on invoice',
      subSection: 'In Query',
    }
  ],
  [
    SerieId.ExceptionINC,
    {
      label: 'INC',
      color: ChartColors.Color21,
      tooltip: 'Incorrect reference used',
      subSection: 'In Query',
    }
  ],
  [
    SerieId.ExceptionUNX,
    {
      label: 'UNX',
      color: ChartColors.Color17,
      tooltip: 'Unexpected invoice',
      subSection: 'In Query',
    }
  ],
  [
    SerieId.ExceptionDCH,
    {
      label: 'DCH',
      color: ChartColors.Color12,
      tooltip: 'Duplicate charge',
      subSection: 'In Query',
    }
  ],
  [
    SerieId.ExceptionBPO,
    {
      label: 'BPO',
      color: ChartColors.Color28,
      tooltip: 'Bulk post',
      subSection: 'None',
    }
  ],
  [
    SerieId.ExceptionBDI,
    {
      label: 'BDI',
      color: ChartColors.Color29,
      tooltip: 'Bulk discard',
      subSection: 'None',
    }
  ],
  [
    SerieId.ExceptionBRE,
    {
      label: 'BRE',
      color: ChartColors.Color30,
      tooltip: 'Bulk reassign',
      subSection: 'None',
    }
  ],
]);

interface Props extends ChartCommonProps {
  apiFilter: ChartFilterProps;
}

export const IssueCodesChart: React.FC<Props> = (props) => {
  const fetchChartData = async () => {
    const result: any[] = await CompanyAPI.getApInsightsChartData(props.apiFilter);

    return result.map((dataPoint) => {
      let keys = dataPoint.values.reduce((map: any, item: any) => {
        return { ...map, [item.value || SerieId.ExceptionNoCode]: item.count };
      }, {});

      if (props.apiFilter.userOptions && props.removeFilteredData) removeDataForSaveToggles(keys, props.apiFilter.userOptions);

      return {
        name: dataPoint.aggregation,
        total: dataPoint.total,
        ...keys
      }
    });
  }

  return (
    <GeneralChart
      apiFilter={props.apiFilter}
      fetchChartData={fetchChartData}
      seriesData={seriesData}
      isFetching={props.isFetching}
      setIsFetching={props.setIsFetching}
      counterType={props.counterType}
      referenceData={props.referenceData}
      groupMode="grouped"
      showToggles={!props.hideToggles}
      compactToggles
      chartSettings={{
        reversedKeys: false
      }}
      pointTooltip={(target) => {
        const value = props.counterType === CounterType.InvoicePercentage ? (target.point.data.y as number).toFixed(2) + '%' : target.point.data.y;
        return (
          <p className="chart-tooltip">
            {formatAggregationValue(target.point.data.x, props.apiFilter.aggregation, false, props.referenceData)} - {target.point.serieId}: <b>{value}</b>
          </p>
        )
      }}
      toggleAll={{
        label: 'All codes'
      }}
      setNotification={props.setNotification}
      renderForCustomDashboard={props.renderForCustomDashboard}
      renderAddToButton={props.renderAddToButton}
      permissionLevel={props.permissionLevel}
      customDashboards={props.customDashboards}
      insightsSettings={props.insightsSettings}
      chartUserSettings={props.chartUserSettings}
      renderExportButton={true}
      exportFilename={`Shipamax_Issue_Codes_Report_${moment().format('DDMMYYYY')}.csv`}
      exportType='ap'
      customPageSize={props.customPageSize}
      getChartToggles={props.getChartToggles}
      productSection={props.productSection}
    />
  )
}
