import * as React from 'react';
import { Notification } from '../../../common/Notification';
import { OrganisationsImportTable } from './OrganisationsImportTable';
import { Route, Switch } from 'react-router-dom';
import { OrganisationsImportForm } from './OrganisationsImportForm';
import { AdminLinks } from '../../Admin';

interface Props {
  setNotification: (notification: Notification | null) => void;
}

export const OrganisationsImportsSection: React.FC<Props> = (props) => {
  return (
    <Switch>
      <Route path={AdminLinks.OrganisationsImports} exact component={() => (
        <OrganisationsImportTable/>
      )}/>
      <Route path={[AdminLinks.OrganisationsImports + '/form']} exact component={() => (
        <OrganisationsImportForm setNotification={props.setNotification} />
      )}/>
    </Switch>
  );
};
