import * as React from 'react';
import { useHistory } from 'react-router-dom';

import './notification.scss';
import { FrontendLogAPI } from '../../api/frontendLog';
import { ShipamaxCheckIcon, ShipamaxExclamationIcon, ShipamaxInformationIcon, ShipamaxTimesIcon } from '../../images/Icons';
export const APIError: Notification = { type: 'error', value: 'Something went wrong. Please try again or contact support if this continues.' };

// AP INV
export const ValidationError: Notification = { type: 'error', value: 'Sorry, there are still issues with this Invoice.' };
export const Validating: Notification = { type: 'error', value: 'Invoice is still being validated.' };
export const Reopened: Notification = { type: 'success', value: 'Invoice reopened.' };
export const Reassigned: Notification = { type: 'success', value: 'Invoice reassigned.' };
export const Discarded: Notification = { type: 'success', value: 'Invoice discarded.' };
export const Queried: Notification = { type: 'success', value: 'Invoice moved to In Query.' };
export const Posted: Notification = { type: 'success', value: 'Invoice successfully posted.' };
export const DocumentReclassifiedAsInvoice: Notification = { type: 'success', value: 'Document sent for data extraction as an Invoice.' };
export const DocumentReassigned: Notification = { type: 'success', value: 'Document reassigned.' };
export const DocumentDiscarded: Notification = { type: 'error', value: 'Document has been discarded.' };
export const ExportError: Notification = { type: 'error', value: 'Error generating report. Please try again later or contact support.' }
export const StatusChangeError: Notification = { type: 'error', value: 'Error moving to Posted. Please try again later or contact support.' }
export const StatusChangeSuccess: Notification = { type: 'success', value: 'Invoice successfully forced to Posted.' }

// B/L
export const Merged: Notification = { type: 'success', value: 'Your cards have been successfully merged into one.' };
export const Split: Notification = { type: 'success', value: 'Your card has been successfully split in two.' };
export const PostToCWSuccess: Notification = { type: 'success', value: 'Pack $1 posted successfully.' };
export const ValidationSuccess: Notification = { type: 'success', value: 'Validation of pack $1 completed.' };
export const ValidationIssues: Notification = { type: 'error', value: 'Validation of pack $1 failed. Click to open the pack and review the messages.' };
export const PostToCWError: Notification = { type: 'error', value: 'Pack $1 couldn’t be posted. Click to open the pack and review the messages.' };
export const BLDiscarded: Notification = { type: 'success', value: 'Your card has been discarded and therefore removed from the board.' };
export const Done: Notification = { type: 'success', value: 'Your card has been marked as done and therefore removed from the board.' };
export const BLReopened: Notification = { type: 'success', value: 'Pack marked as reopened.' };
export const BLUnposted: Notification = { type: 'success', value: 'Pack marked as unposted.' };

// GENERAL
export const Copied: Notification = { type: 'success', value: 'Text copied to clipboard.' };
export const EmailsCopied: Notification = { type: 'success', value: 'Email list copied to clipboard.' };
export const DashboardAdded: Notification = { type: 'success', value: "Chart has been added." }
export const ParserError: Notification = { type: 'error', value: 'No Response Received From Parser' };
export const MatchingError: Notification = { type: 'error', value: 'No Response Received From Matching' };

export interface EventNotificationRequest {
  id: number,
  request: () => Promise<any>,
};

export interface Notification {
  type: 'success' | 'error' | 'info';
  value: string;
  reason?: string;
  details?: object;
}

export function interpolateNotification(message: String, interpolate?: string[]) {
  if (!interpolate || interpolate.length === 0) return message;

  for (let i = 0; i <= interpolate.length; i++) {
    const sanitizedInterpolatedString = interpolate[i];
    message = message.replace(`$${i + 1}`, sanitizedInterpolatedString);
  }
  return message;
}

interface Props {
  eventsToListen: EventNotificationRequest[];
  notification: Notification | null;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  removeEventToListen: (ids: number[]) => void;
  notificationList: { notification: Notification, groupId: number }[];
  setNotificationList: (notifications: { notification: Notification, groupId: number }[]) => void;
}

export const Notification: React.FC<Props> = (props) => {
  const [show, setShow] = React.useState<boolean>(false);
  const intervalIDRef = React.useRef<any | null>(null);

  const history = useHistory();

  React.useEffect(() => {
    if (intervalIDRef) {
      clearInterval(intervalIDRef.current);
      intervalIDRef.current = null;
    }
    intervalIDRef.current = setInterval(async () => {
      const idsToRemove: number[] = [];
      await Promise.all(props.eventsToListen.map(async (e) => {
        const result = await e.request();
        if (result) {
          idsToRemove.push(e.id);
          //If there's some alert from same group in the list, remove it before inserting new one
          const prevState = props.notificationList.filter((n) => n.groupId !== e.id);
          if (result.notification) props.setNotificationList([...prevState, result])
        }
      }));
      if (idsToRemove.length) props.removeEventToListen(idsToRemove);
    }, 4000);
  }, [props.eventsToListen, props.notificationList]);

  React.useEffect(() => {
    if (props.notification) setShow(true);
    else setShow(false);
    if (props.notification?.type === 'error' && props.notification.reason) {
      FrontendLogAPI.createLog(props.notification);
    }
  }, [props.notification]);

  const removeNotificationFromList = (groupId: number) => {
    const updated = props.notificationList.filter((n) => n.groupId !== groupId);
    props.setNotificationList(updated);
  }

  const notification = props.notification || { type: '', value: '' };

  return (
    <div className="notification-container">
      {props.notificationList.map((lNotification, idx) => {
        return (<div className={`notification show ${lNotification.notification.type} ${props.className}`}>
          <a onClick={() => {
            removeNotificationFromList(lNotification.groupId);
            history.push(`/billoflading/${lNotification.groupId}`);
          }}>
            {interpolateNotification(lNotification.notification.value, [String(lNotification.groupId)])}
          </a>
          <div className='close-notification' onClick={() => { return removeNotificationFromList(lNotification.groupId) }}><ShipamaxTimesIcon /></div>
        </div>);
      })}
      <div className={`notification ${show ? 'show' : ''} ${notification.type} ${props.className}`}>
        {notification.type === "error" ? <ShipamaxExclamationIcon /> : notification.type === "success" ? <ShipamaxCheckIcon /> : <ShipamaxInformationIcon />}
        {notification.value}
        <div className='close-notification'><ShipamaxTimesIcon /></div>
      </div>
    </div>
  );
}
