import * as React from 'react';
import * as moment from "moment";
import { WrapperLabel } from '../../../common/WrapperLabel';
import { OptionValue } from 'react-selectize';
import { DateSelectEditor } from '../../../common/DateSelectEditor';
import { TextInput } from '../../../common/TextInput';
import { APIDocumentGroupDocument, APIDocumentGroupShipment } from '../../../../api/documentGroup';
import { BillOfLadingAPI } from '../../../../api/billOfLading';
import { FieldChangedParams, IssuerDetailMap, JobType } from '../../common';
import { PortSelect } from '../../../common/PortSelect';
import { BillOfLadingNotifyParty } from '../../../../api/billOfLadingNotifyParty';
import { CustomSelect } from '../../../common/CustomSelect';
import { PacklinesList } from '../PacklinesList';
import { APIDocumentContainer } from '../../../../api/documentContainer';
import { APIEmailBasic } from '../../../../api/email';
import { placeholderTypes } from '../../file-sorter/FileSorter';
import { OrganisationText } from '../organisation-text/OrganisationText';
import { RecordValidator } from '../../../common/Form';

import '../shipment.scss';
import { checkIfRecordValidatorHasErrors } from '../ErrorHelper';

interface Props {
  data: APIDocumentGroupShipment;
  document: APIDocumentGroupDocument<APIDocumentGroupShipment> | undefined
  transModeOptions: OptionValue[];
  releaseTypeOptions: OptionValue[];
  packingModeOptions: OptionValue[];
  packageTypeOptions: OptionValue[];
  shipmentTypeOptions: OptionValue[];
  containerTypeOptions: OptionValue[];
  defaultPackingMode: string | undefined;
  reloadGroupData: (forceRefresh?: boolean) => void;
  issuerDetails: IssuerDetailMap;
  fieldsChanged: (changes: FieldChangedParams[]) => void;
  disabled: boolean;
  setActiveDocument: (document: APIDocumentContainer | APIEmailBasic | undefined, scroll?: boolean) => void;
  activeDocument?: APIDocumentContainer | APIEmailBasic | undefined;
  checkRepeatedBlNumber: (docId: number, value: string | undefined) => boolean;
  addToBlNums: (docId: number, value: string) => void;
}

export const HouseBill: React.FC<Props> = (props) => {
  const [transMode, setTransMode] = React.useState<OptionValue | null>(null);
  const [releaseType, setReleaseType] = React.useState<OptionValue | null>(null);
  const [shipmentType, setShipmentType] = React.useState<OptionValue | null>(null);
  const [packingMode, setPackingMode] = React.useState<OptionValue | null>(null);
  const [hblNo, setHblNo] = React.useState<string>('');
  const [goodsDesc, setGoodsDesc] = React.useState<string>('');
  const [etd, setEtd] = React.useState<string | null>();
  const [eta, setEta] = React.useState<string | null>();

  const [shipperName, setShipperName] = React.useState<string>('');
  const [shipperAddress, setShipperAddress] = React.useState<string>('');
  const [consigneeName, setConsigneeName] = React.useState<string>('');
  const [consigneeAddress, setConsigneeAddress] = React.useState<string>('');
  const [notifyName, setNotifyName] = React.useState<string>('');
  const [notifyAddress, setNotifyAddress] = React.useState<string>('');

  // load document group into the shipment form
  React.useEffect(() => {
    if (props.data) {
      const d = props.data;

      setHblNo(d.billOfLadingNo || '');
      setGoodsDesc(d.goodsDescription || '');
      setEtd(d.etd ? d.etd : null);
      setEta(d.eta ? d.eta : null);

      setConsigneeName(d.consigneeName || '');
      setConsigneeAddress(d.consigneeAddress || '');
      setShipperName(d.shipperName || '');
      setShipperAddress(d.shipperAddress || '');
      setNotifyName((d.notify ? d.notify[0]?.notifyPartyName : '') || '');
      setNotifyAddress((d.notify ? d.notify[0]?.notifyPartyAddress : '') || '');

      if (!d.transportMode) update('transportMode', 'SEA');
      setTransMode(props.transModeOptions.find((options) => options.value === (d.transportMode || 'SEA')) || null);
      if (!d.shipmentType) update('shipmentType', 'STD');
      setShipmentType(props.shipmentTypeOptions.find((options) => options.value === (d.shipmentType || 'STD')) || null);
      if (!d.containerMode && props.defaultPackingMode) update('containerMode', props.defaultPackingMode);
      setPackingMode(props.packingModeOptions.find((options) => options.value === (d.containerMode || props.defaultPackingMode)) || null);

      setReleaseType(props.releaseTypeOptions.find((options) => options.value === d.releaseType) || null);
    }
  }, [props.data]);

  React.useEffect(() => {
    props.addToBlNums(props.data?.documentId!, hblNo);
  }, [hblNo]);

  const recordValidator: RecordValidator = {
    blNumber: {
      validators: [
        {
          errorMessage: 'Required field',
          isValid: () => !!hblNo,
        },
        {
          errorMessage: 'Duplicate bill of lading number',
          isValid: () => !props.checkRepeatedBlNumber(props.data.documentId, hblNo),
        },
      ]
    },
    shipperName: {
      validators: [
        {
          errorMessage: 'Required field',
          isValid: () => !!shipperName,
        },
      ]
    },
    consigneeName: {
      validators: [
        {
          errorMessage: 'Required field',
          isValid: () => !!consigneeName,
        },
      ]
    },
  };
  const recordValidatorHasErrors = checkIfRecordValidatorHasErrors(recordValidator);

  const update = async (name: string, value: string | number | null, withoutChange?: boolean) => {
    if (withoutChange) {
      return;
    }

    if (['notifyPartyAddress', 'notifyPartyName'].includes(name)) {
      const notifyParty = props.data.notify ? props.data.notify[0] : null;
      if (notifyParty) {
        await BillOfLadingNotifyParty.update(notifyParty.id, { [name]: String(value) });
      } else if (value) {
        await BillOfLadingNotifyParty.create({
          billOfLadingId: props.data?.id,
          [name]: value,
        });
      }
      props.reloadGroupData(true);
    } else if (props.data?.id) {
      await BillOfLadingAPI.update(props.data?.id, { [name]: value });
      props.reloadGroupData();
    }

    if (['billOfLadingNo'].includes(name)) {
      props.fieldsChanged([{
        id: props.data?.documentId,
        fieldName: name,
        jobType: JobType.Consol,
        value: value as string | null
      }]);
    }
  }

  const onFocus = () => {
    if (props.document?.documentType && !placeholderTypes.includes(props.document?.documentType)) {
      props.setActiveDocument(props.document as unknown as APIDocumentContainer)
    }
  }

  return (
    <div className={`wtg-integration ${props.activeDocument?.id === props.data?.documentId ? 'selected-card-div' : 'mt-25'}`} id={`card-${props.data?.documentId}`}>
      <div className='invoice_icons'>
        {recordValidatorHasErrors && <span className='fas fa-exclamation-triangle icon-error'></span>}
      </div>
      <div className="collapsible-container__header">
        <div className="collapsible-container__header--title">House Bill Of Lading</div>
      </div>
      <div className="collapsible-container__body">
        <div className="grid__row grid__row--with-separator">
          <div className="grid__col-4">
            <OrganisationText
              label='shipper'
              tableName='bol_header'
              name={shipperName}
              setName={setShipperName}
              address={shipperAddress}
              setAddress={setShipperAddress}
              update={update}
              disabled={props.disabled}
              id={[props.data?.id || -1]}
              onFocus={onFocus}
              validators={recordValidator.shipperName.validators}
            />
          </div>
          <div className="grid__col-4">
            <OrganisationText
              label='consignee'
              tableName='bol_header'
              name={consigneeName}
              setName={setConsigneeName}
              address={consigneeAddress}
              setAddress={setConsigneeAddress}
              update={update}
              disabled={props.disabled}
              id={[props.data?.id || -1]}
              onFocus={onFocus}
              validators={recordValidator.consigneeName.validators}
            />
          </div>
          <div className="grid__col-4">
            <OrganisationText
              label='Notify party'
              fieldName='notifyParty'
              highlightName='notify'
              tableName='bol_header'
              name={notifyName}
              setName={setNotifyName}
              address={notifyAddress}
              setAddress={setNotifyAddress}
              update={update}
              disabled={props.disabled}
              id={[props.data?.id || -1]}
              onFocus={onFocus}
            />
          </div>
        </div>
        <div className="grid__row">
          <div className="grid__col-4">
            <WrapperLabel text="BL Number">
              <TextInput value={hblNo} name="billOfLadingNo" onBlur={update} setter={setHblNo} disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['bill_of_lading_number'],
                }}
                formEntered={true}
                validators={recordValidator.blNumber.validators}
                dataTestId="bol-number-input"
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-4">
            <WrapperLabel text="Release Type">
              <CustomSelect
                options={props.releaseTypeOptions}
                value={releaseType || undefined}
                onValueChange={(value) => {
                  setReleaseType(value);
                  update('releaseType', value?.value || null);
                }}
                disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['release_type'],
                }}
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-4">
            <WrapperLabel text="Shipment Type" title={shipmentType?.value}>
              <CustomSelect
                options={props.shipmentTypeOptions}
                value={shipmentType || undefined}
                onValueChange={(value) => {
                  setShipmentType(value);
                  update('shipmentType', value?.value || null);
                }}
                disabled={props.disabled || (!!shipmentType && props.shipmentTypeOptions.length === 1)}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['shipment_type'],
                }}
              />
            </WrapperLabel>
            <WrapperLabel text="Trans Mode" title={transMode?.value}>
              <CustomSelect
                options={props.transModeOptions}
                value={transMode || undefined}
                onValueChange={(value) => {
                  setTransMode(value);
                  update('transportMode', value?.value || null);
                }}
                disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['transport_mode'],
                }}
              />
            </WrapperLabel>
          </div>
        </div>
        <div className="grid__row">
          <div className="grid__col-4">
            <PortSelect
              label={'Origin'}
              value={props.data.origin}
              valueUnlocode={props.data.originUnlocode}
              onValueChange={(value, valueUnlocode) => {
                update('origin', value);
                update('originUnlocode', valueUnlocode);
              }}
              onValueUnlocodeChange={(valueUnlocode) => {
                update('originUnlocode', valueUnlocode);
              }}
              onlySeaPort={false}
              dataTestId="origin-port"
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['origin'],
              }}
            />
          </div>
          <div className="grid__col-4">
            <PortSelect
              label={'Destination'}
              value={props.data.destination}
              valueUnlocode={props.data.destinationUnlocode}
              onValueChange={(value, valueUnlocode) => {
                update('destination', value);
                update('destinationUnlocode', valueUnlocode);
              }}
              onValueUnlocodeChange={(valueUnlocode) => {
                update('destinationUnlocode', valueUnlocode);
              }}
              onlySeaPort={false}
              dataTestId="destination-port"
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['destination'],
              }}
            />
          </div>
          <div className="grid__col-4">
            <WrapperLabel text="ETD">
              <DateSelectEditor
                initDate={props.data.etd && moment(props.data.etd)}
                onDateChange={(value: moment.Moment | null) => {
                  update('etd', value ? value.format('YYYY-MM-DD') : null);
                  setEtd(value ? value.format('YYYY-MM-DD') : null);
                }}
                disabled={props.disabled}
                onFocus={onFocus}
              />
            </WrapperLabel>
            <WrapperLabel text="ETA" >
              <DateSelectEditor
                initDate={props.data.eta && moment(props.data.eta)}
                onDateChange={(value: moment.Moment | null) => {
                  update('eta', value ? value.format('YYYY-MM-DD') : null);
                  setEta(value ? value.format('YYYY-MM-DD') : null);
                }}
                disabled={props.disabled}
                onFocus={onFocus}
              />
            </WrapperLabel>
          </div>
        </div>
        <div className="grid__row grid__row--with-separator">
          <div className="grid__col-4">
            <WrapperLabel text="Goods Description">
              <TextInput
                name="goodsDescription"
                value={goodsDesc}
                onChange={setGoodsDesc}
                onBlur={update}
                disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['goods_description'],
                }}
                dataTestId="goods-description-input"
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-4">
            <WrapperLabel text="Packing Mode" title={packingMode?.value}>
              <CustomSelect
                options={props.packingModeOptions}
                value={packingMode || undefined}
                onValueChange={(value) => {
                  setPackingMode(value);
                  update('containerMode', value?.value || null);
                }}
                renderValue={(item: OptionValue) => item.value}
                disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['packing_mode'],
                }}
              />
            </WrapperLabel>
            <WrapperLabel text=""></WrapperLabel>
          </div>
          <div className="grid__col-4" />
        </div>
        <div className="grid__row">
          <PacklinesList
            packLines={props.data.packLines}
            reloadGroupData={props.reloadGroupData}
            disabled={props.disabled}
            packageTypeOptions={props.packageTypeOptions}
            billOfLadingId={props.data.id}
            onFocus={onFocus}
          />
        </div>
      </div>
    </div>
  );
}
