import { request } from './request';
import { PermissionLevel } from './authentication';
import { APITeam, TeamAPI } from './teams';
import { AdminGridSearchParams } from '../components/admin/AdminGrid';
import { APIPermissionProfile } from './permissionProfiles';
import { APICompany } from './company';

export interface APIComment {
  id?: number;
  created: string;
  comment: string;
  userId: number;
  supplierInvoiceId: number | null;
  documentGroupId: number;
}

export enum UserStatus {
  Enabled = 0,
  Disabled = 1,
  Hidden = 2,
  Deleted = 3
}

export interface APIUser {
  id: number;
  companyId: number;
  email: string;
  verified: boolean;
  firstName: string;
  lastName: string;
  username: string;
  permissionLevel: PermissionLevel;
  enableTableCoordinates: boolean;
  status: UserStatus;
  teamId: number;
  operatorCode: string | null;
  emailVerified: boolean;
  permissionProfileId: number | null;
  permissionProfile: APIPermissionProfile | null;
  parentUserId: number;
}

export interface APIRelatedUser {
  id: number;
  email: string;
  parentUserId: number;
  company: APICompany;
}

export interface APIRelatedUsers {
  relatedUsers: APIRelatedUser[]
}

export type UsersMap = Map<number, APIUser>;
export type TeamsMap = Map<number, APITeam>;
export type TeamsObjectMap = { [teamId: number]: APITeam };

export interface APICountResponse {
  count: number;
}

export const CommentAPI = {
  async createComment(comment: Partial<APIComment>) {
    return request(`/Comments`, 'POST', comment);
  },

  async fetchAll(documentGroupId: number): Promise<APIComment[]> {
    const filter = JSON.stringify({
      where: {
        documentGroupId: documentGroupId,
      },
      order: ['created DESC'],
    });
    return request(`/Comments?filter=${encodeURIComponent(filter)}`);
  },
}

export const UserAPI = {
  async fetch(userId: number): Promise<APIUser> {
    const filter = JSON.stringify({
      where: {
        companyId: parseInt(localStorage.getItem('companyId') || ''),
        id: userId,
      },
    });
    const results = await request(`/Users?filter=${encodeURIComponent(filter)}`)
    return results[0];
  },

  async fetchById(userId: number): Promise<APIUser> {
    const filter = JSON.stringify({ where: { id: userId } });
    const results = await request(`/Users?filter=${encodeURIComponent(filter)}`)
    return results[0];
  },

  async fetchRelatedUsers(): Promise<APIRelatedUser[]> {
    const results = await request(`/Users/related-users`);
    if (!results?.relatedUsers) return [];
    return results.relatedUsers;
  },

  async fetchAllVisible(params?: AdminGridSearchParams | undefined): Promise<APIUser[]> {
    const searchByFullName = (params?.searchTerm || '').split(' ')?.length > 1;

    const teams = params?.searchTerm ? await TeamAPI.fetchAll({ searchTerm: params.searchTerm }) : [];

    const filter = JSON.stringify({
      where: {
        companyId: parseInt(localStorage.getItem('companyId') || ''),
        parentUserId: null,
        status: { inq: [UserStatus.Enabled, UserStatus.Disabled] },
        ...(params?.searchTerm ? {
          and: [
            {
              or: [
                { firstName: { ilike: `%${params.searchTerm.toLowerCase()}%` } },
                { lastName: { ilike: `%${params.searchTerm.toLowerCase()}%` } },
                { email: { ilike: `%${params.searchTerm.toLowerCase()}%` } },
                { operatorCode: { ilike: `%${params.searchTerm.toLowerCase()}%` } },
                ...(searchByFullName ? [{
                  firstName: { ilike: `%${params?.searchTerm.toLowerCase().split(' ')[0]}` },
                  lastName: { ilike: `${params?.searchTerm.toLowerCase().split(' ')[1]}%` }
                }] : []),
                { teamId: { inq: teams.map((team) => team.id) } }
              ]
            }],
        } : {}
        )
      },
      order: ['firstname', 'lastname'],
      ...(params?.limit ? { limit: params.limit } : {}),
      include: ['permissionProfile'],
    });

    return request(`/Users?filter=${encodeURIComponent(filter)}`);
  },

  async fetchAll(): Promise<APIUser[]> {
    const filter = JSON.stringify({
      where: {
        companyId: parseInt(localStorage.getItem('companyId') || ''),
      },
      order: ['firstname', 'lastname'],
    });

    return request(`/Users?filter=${encodeURIComponent(filter)}`);
  },

  async update(id: number, change: Partial<APIUser>): Promise<unknown> {
    return request(`/Users/${id}`, 'PATCH', change);
  },

  async create(user: Partial<APIUser>): Promise<unknown> {
    return request('/Users', 'POST', { ...user, password: null });
  },

  async isFieldValueTaken(field: keyof APIUser, value: string, userId: number | undefined, perCompany: boolean, onlyEnabled: boolean): Promise<boolean> {
    const companyId = parseInt(localStorage.getItem('companyId') || '');
    const filter = JSON.stringify({
      companyId,
      where: {
        ...(perCompany ? { companyId } : {}),
        ...(onlyEnabled ? { status: UserStatus.Enabled } : {}),
        [field]: value,
        ...(userId ? { id: { neq: userId } } : {}),
      },
    });

    const result = await request(`/Users?filter=${filter}`);
    return !!result.length;
  }
}
