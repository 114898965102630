import * as React from 'react';
import { ShipamaxDownIcon } from '../../images/Icons';

import './custom-dropdown.scss';

interface Props<T> {
  label: any;
  options: { label: any, value: T }[];
  onSelect: (selectedValue: T) => void;
  selectedValue?: T;
  labelActiveOnHover?: boolean;
  hideToggleIcon?: boolean;
  disabled?: boolean;
  openOnHover?: boolean;
  className?: string;
}

export const CustomDropdown = <T extends unknown>(props: Props<T>) => {
  const [showDropdownMenu, setShowDropdownMenu] = React.useState<boolean>(false);
  const dropdownMenuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutsideOfDropdownMenu);

    return () => {
      window.removeEventListener('click', handleClickOutsideOfDropdownMenu);
    }
  }, [showDropdownMenu]);

  const handleClickOutsideOfDropdownMenu = React.useCallback((event: MouseEvent) => {
    if (showDropdownMenu && dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target as HTMLElement)) {
      setShowDropdownMenu(false);
    }
  }, [showDropdownMenu, dropdownMenuRef]);

  const toggleDropdownMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowDropdownMenu(!showDropdownMenu);
  }

  return (
    <div
      className={`custom-dropdown ${showDropdownMenu ? 'custom-dropdown--open' : ''} ${props.className || ''}`}
      onMouseLeave={() => {
        if (props.openOnHover && showDropdownMenu) setShowDropdownMenu(false);
      }}
    >
      <button
        className={`light-button custom-dropdown__label ${props.labelActiveOnHover ? 'active-on-hover' : ''}`}
        onClick={(e) => !props.disabled && toggleDropdownMenu(e)}
        disabled={props.disabled}
        onMouseEnter={() => {
          if (props.openOnHover && !showDropdownMenu) setShowDropdownMenu(true);
        }}
      >{props.label} {!props.hideToggleIcon && (<ShipamaxDownIcon className="custom-dropdown__toggle" />)}</button>
      {showDropdownMenu && (
        <div
          className="custom-dropdown__menu with-custom-scrollbar"
          ref={dropdownMenuRef}
        >
          {props.options.map((option, index) => (
            <div
              key={index}
              className={`custom-dropdown__menu__item ${props.selectedValue === option.value ? 'custom-dropdown__menu__item--selected' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.onSelect(option.value);
                setShowDropdownMenu(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
