import { request } from './request';
import { Notification } from '../components/common/Notification';

export const FrontendLogAPI = {
  async createLog(notification: Notification) {
    if (localStorage.getItem('companyId') && localStorage.getItem('userId')) {
      const log = {
        companyId: Number(localStorage.getItem('companyId')),
        userId: Number(localStorage.getItem('userId')),
        email: localStorage.getItem('email') || '',
        reason: notification.reason,
        details: notification.details
      };
      return request(`/FrontendLogs`, 'POST', log);
    }
  },
}
