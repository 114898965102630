import * as React from 'react';
import { AdminHeader } from '../../AdminHeader';
import { TabSwitcher } from '../../../common/TabSwitcher';
import { EnvironmentTab } from '../../../../api/emailAccount';
import { TMSApplicationForm } from './TMSApplicationForm';
import { TMSLoginType } from '../../../../api/cwLogin';
import { Environment } from '../../../../api/cwCredential';

import './index.scss';

export enum CWLoginTab {
  ProductionWorkspace = 'Production workspace',
  TestWorkspace = 'Test workspace',
  Wisecloud = 'Wisecloud',
}

interface Props {

}

export const CargowiseUserLoginSection: React.FC<Props> = () => {
  const [selectedTab, setSelectedTab] = React.useState<string>(EnvironmentTab.ProductionWorkspace);

  return (
    <div className="cargowise-user-login-section">
      <AdminHeader
        headline="CW1 user login for Shipamax"
      />
      <TabSwitcher
        selectedTab={selectedTab}
        onTabClicked={setSelectedTab}
        tabs={[CWLoginTab.ProductionWorkspace, CWLoginTab.TestWorkspace, CWLoginTab.Wisecloud]}
      />
      <div className="admin__form__content">
        {selectedTab === CWLoginTab.ProductionWorkspace && (
          <TMSApplicationForm
            customerEnv={Environment.Production}
            tmsLoginType={TMSLoginType.Cargowise}
            label="Production environment"
          />
        )}
        {selectedTab === CWLoginTab.TestWorkspace && (
          <TMSApplicationForm
            customerEnv={Environment.Test}
            tmsLoginType={TMSLoginType.Cargowise}
            label="Testing environment"
          />
        )}
        {selectedTab === CWLoginTab.Wisecloud && (
          <TMSApplicationForm
            tmsLoginType={TMSLoginType.WiseCloud}
            customerEnv={null}
          />
        )}
      </div>
    </div>
  )
};
