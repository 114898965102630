import * as React from 'react';
import { Notification } from '../../common/Notification';
import { APIPermissionProfile } from '../../../api/permissionProfiles';
import { Switch, Route } from 'react-router-dom';
import { Dashboard } from './Dashboard';
import { PermissionLevel } from '../../../api/authentication';
import { SectionModule } from '../common';
import { APICustomDashboard } from '../../../api/customDashboard';

import './index.scss';

interface Props {
  setNotification: (notification: Notification | null) => void;
  permissionProfile: APIPermissionProfile | null;
  permissionLevel: PermissionLevel;
  sectionModule: SectionModule;
  refetchCustomDashboards: () => void;
  customDashboards: APICustomDashboard[];
}

export const CustomDashboard: React.FC<Props> = (props) => {
  return (
    <div className="custom-dashboards">
      <Switch>
        <Route path={["/apInsights/custom-dashboards/form", "/apInsights/custom-dashboards/form/:id"]} exact render={() => (
          <Dashboard
            sectionModule={props.sectionModule}
            setNotification={props.setNotification}
            permissionProfile={props.permissionProfile}
            permissionLevel={props.permissionLevel}
            refetchCustomDashboards={props.refetchCustomDashboards}
            customDashboards={props.customDashboards}
          />
        )} />
        <Route path="/apInsights/custom-dashboards/:id" render={() => (
          <Dashboard
            sectionModule={props.sectionModule}
            setNotification={props.setNotification}
            permissionProfile={props.permissionProfile}
            permissionLevel={props.permissionLevel}
            refetchCustomDashboards={props.refetchCustomDashboards}
            readOnly={true}
            customDashboards={props.customDashboards}
          />
        )} />
      </Switch>
    </div>
  )
}
