import * as React from 'react';
import { CompanyAPI } from '../../../api/company';
import { ChartColors, SerieSettings, } from '../common';
import { ChartCommonProps, ChartFilterProps, CounterType, } from '../ChartWrapper';
import { GeneralChart } from '../GeneralChart';
import { Map2 } from '../../helpers';
import { removeDataForSaveToggles } from '../utils';

enum ExceptionType {
  BusinessProcessOnly = 1,
  ExtractionOnly = 2,
  ExtractionAndBusinessProcess = 3,
  PostedStraightThrough = 5,
  Open = 6,
  Discarded = 7,
  InQuery = 8
}

const seriesData = new Map2<number, SerieSettings>([
  [
    ExceptionType.BusinessProcessOnly,
    {
      label: 'Business process only',
      color: ChartColors.Color21,
    }
  ],
  [
    ExceptionType.ExtractionAndBusinessProcess,
    {
      label: 'Extraction and business process',
      color: ChartColors.Color18,
    }
  ],
  [
    ExceptionType.ExtractionOnly,
    {
      label: 'Extraction only',
      color: ChartColors.Color10,
    }
  ],
  [
    ExceptionType.PostedStraightThrough,
    {
      label: 'Posted Straight Through',
      color: ChartColors.Color4,
    }
  ],
  [
    ExceptionType.Open,
    {
      label: 'Open (Unknown)',
      color: ChartColors.Color1,
    }
  ],
  [
    ExceptionType.Discarded,
    {
      label: 'Discarded (Unknown)',
      color: ChartColors.Color2,
    }
  ],
  [
    ExceptionType.InQuery,
    {
      label: 'In Query (Unknown)',
      color: ChartColors.Color5,
    }
  ],
]);

interface Props extends ChartCommonProps {
  apiFilter: ChartFilterProps;
}

export const ExceptionTypesChart: React.FC<Props> = (props) => {
  const fetchChartData = async () => {
    if (!props.apiFilter.dateRange) {
      return [];
    }

    const chartData = await CompanyAPI.getApInsightsChartData(props.apiFilter);

    return chartData.map((dataPoint) => {
      let keys = dataPoint.values.reduce((map: {}, item: any) => ({ ...map, [item.value]: item.count }), {})

      if (props.apiFilter.userOptions && props.removeFilteredData) removeDataForSaveToggles(keys, props.apiFilter.userOptions);

      return {
        name: dataPoint.aggregation,
        total: dataPoint.total,
        ...keys
      }
    });
  }

  return (
    <GeneralChart
      seriesData={seriesData}
      isFetching={props.isFetching}
      setIsFetching={props.setIsFetching}
      referenceData={props.referenceData}
      counterType={props.counterType}
      fetchChartData={fetchChartData}
      apiFilter={props.apiFilter}
      showToggles={!props.hideToggles}
      groupMode="stacked"
      leftAxisSettings={{
        axisLegend: props.counterType === CounterType.InvoiceCount ? 'Count of invoices with each type' : 'Percentage of invoices with each type'
      }}
      setNotification={props.setNotification}
      renderForCustomDashboard={props.renderForCustomDashboard}
      renderAddToButton={props.renderAddToButton}
      permissionLevel={props.permissionLevel}
      customDashboards={props.customDashboards}
      insightsSettings={props.insightsSettings}
      chartUserSettings={props.chartUserSettings}
      renderExportButton={false}
      exportType='ap'
      customPageSize={props.customPageSize}
      getChartToggles={props.getChartToggles}
      productSection={props.productSection}
    />
  )
}
