import { request } from './request';

export interface APIJobRef {
  id?: number;
  invoiceId: number;
  jobRef: string | null;
  clusterId?: number;
  bolNum: string | null;
  containerNum: string | null;
  serviceStartDate: string | null;
  serviceEndDate: string | null;
  purchaseOrder: string | null;
  orderIndex: number | null;
  total: number | null;
}


export const SupplierInvoiceJobRefAPI = {
  async create(jobRef: Partial<APIJobRef>) {
    return request(`/SupplierInvoiceJobRefs`, 'POST', jobRef || {});
  },

  async update(id: number, jobRef: Partial<APIJobRef>): Promise<APIJobRef> {
    return request(`/SupplierInvoiceJobRefs/${id}`, 'PATCH', jobRef);
  },

  async delete(id: number) {
    return request(`/SupplierInvoiceJobRefs/${id}`, 'DELETE');
  }
}
