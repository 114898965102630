import * as React from 'react';
import * as moment from 'moment';
import { ResponsiveLine } from '@nivo/line';
import {
  APIEnterpriseDashboardData,
  APILeaderBoardResult,
  APIRollingAverageResult,
  CompanyAPI
} from '../../api/company';
import { APIKpiTarget, KPITargetAPI } from '../../api/kpiTargets';
import { line } from 'd3-shape';
import { Link } from 'react-router-dom';
import { AdminLinks } from '../admin/Admin';
import { PermissionLevel } from '../../api/authentication';
import { Icon } from 'pivotal-ui/react/iconography';

import './enterprise-dashboard.scss';

const getTargetThreshold = (target: APIKpiTarget | undefined, selectedMetric: EnterpriseDashboardMetric): number | null => {
  let targetValue = target?.straightThroughRate || null;

  if (selectedMetric === EnterpriseDashboardMetric.ProcessExceptionFree) {
    targetValue = target?.processExceptionFree || null;
  } else if (selectedMetric === EnterpriseDashboardMetric.PostedWithinXDays) {
    targetValue = target?.withinTime || null;
  }

  return targetValue;
}

const StraightThroughIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="14" fill="#CEFFEE" />
    <path d="M17.2129 16.631V17.7987C17.2129 18.351 16.7652 18.7987 16.2129 18.7987H11.4C10.8477 18.7987 10.4 18.351 10.4 17.7987V10.1987C10.4 9.64645 10.8477 9.19873 11.4 9.19873H13.8065M13.8065 9.19873L17.2129 11.6761M13.8065 9.19873V10.6761C13.8065 11.2284 14.2542 11.6761 14.8065 11.6761H17.2129M17.2129 11.6761V13.8439M14.7355 15.0826H19.6903M19.6903 15.0826L18.4516 13.8439M19.6903 15.0826L18.4516 16.3213" stroke="#208F68" />
  </svg>
)

const ProgressIndicator = () => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7929 7H1.20711C0.761654 7 0.538571 6.46143 0.853554 6.14645L5.64645 1.35355C5.84171 1.15829 6.15829 1.15829 6.35355 1.35355L11.1464 6.14645C11.4614 6.46143 11.2383 7 10.7929 7Z" />
  </svg>
)

const PostedWithinIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="14" fill="#BFEFFF" />
    <g clipPath="url(#clip0_1828_16761)">
      <path d="M13.3783 10.4772C13.3783 10.1327 13.6555 9.85549 14 9.85549C14.3445 9.85549 14.6217 10.1327 14.6217 10.4772V13.6687L16.8314 15.14C17.1163 15.3317 17.194 15.7177 16.9816 16.0027C16.8132 16.2876 16.4272 16.3653 16.1423 16.1529L13.6555 14.495C13.4819 14.4018 13.3783 14.2075 13.3783 13.9769V10.4772ZM14 7.36865C17.6629 7.36865 20.6316 10.3373 20.6316 14.0002C20.6316 17.6631 17.6629 20.6318 14 20.6318C10.3371 20.6318 7.36841 17.6631 7.36841 14.0002C7.36841 10.3373 10.3371 7.36865 14 7.36865ZM8.61183 14.0002C8.61183 16.9767 11.0235 19.3884 14 19.3884C16.9764 19.3884 19.3881 16.9767 19.3881 14.0002C19.3881 11.0238 16.9764 8.61207 14 8.61207C11.0235 8.61207 8.61183 11.0238 8.61183 14.0002Z" fill="#007DA6" />
    </g>
    <defs>
      <clipPath id="clip0_1828_16761">
        <rect width="13.2632" height="13.2632" fill="white" transform="translate(7.36841 7.36865)" />
      </clipPath>
    </defs>
  </svg>
)

const ProcessExceptionFreeIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="14" fill="#F4F3FF"/>
    <circle r="3.5" transform="matrix(1 0 0 -1 11 10.5)" stroke="#6652EB"/>
    <path d="M13.5 13L17.5 17M16.5 21L19 18.5L18.5 18L17.5 17M17.5 17L15.5 19" stroke="#6652EB" strokeLinecap="square"/>
  </svg>
)

interface DashboardTileTargetProps {
  label: string;
  value: number | undefined;
}

const DashboardTileTarget: React.FC<DashboardTileTargetProps> = (props) => {
  return (
    <div className="enterprise-dashboard__tile__target-wrapper">
      <div className="enterprise-dashboard__tile__target-wrapper__label">{props.label}</div>
      {!!props.value && (
        <div className={`enterprise-dashboard__tile__target-wrapper__icon enterprise-dashboard__tile__target-wrapper__icon--${props.value >= 0 ? 'positive' : 'negative'}`}><ProgressIndicator /></div>
      )}
      <div className="enterprise-dashboard__tile__target-wrapper__value">{props.value !== undefined ? Math.abs(props.value) : ''}</div>
    </div>
  )
}

interface HeaderProps {
  icon: React.ReactElement;
  title: string;
  subtitle: string;
}

const Header: React.FC<HeaderProps> = (props) => (
  <div className="enterprise-dashboard__header-with-icon">
    <div className="enterprise-dashboard__header-with-icon__icon">{props.icon}</div>
    <div className="enterprise-dashboard__header-with-icon__title">
      {props.title}
      <div>{props.subtitle}</div>
    </div>
  </div>
)

interface DashboardTileProps {
  title: string;
  value?: number;
  lastPeriodDifference?: number;
  targetDifference?: number;
  icon: React.ReactElement;
  selected: boolean;
  onClick: () => void;
}

const DashboardTile: React.FC<DashboardTileProps> = (props) => (
  <div
    className={`enterprise-dashboard__tile ${props.selected ? 'enterprise-dashboard__tile--selected' : ''}`}
    onClick={props.onClick}
  >
    <Header
      icon={props.icon}
      title={props.title}
      subtitle="Last 30 days"
    />
    {props.value !== undefined && (
      <>
        <div>
          <div className="enterprise-dashboard__tile__value">
            {props.value}
            <span className="enterprise-dashboard__tile__value__suffix">%</span>
          </div>
        </div>
        <div>
          <DashboardTileTarget
            label="vs. Last"
            value={props.lastPeriodDifference}
          />
          <DashboardTileTarget
            label="vs. Target"
            value={props.targetDifference}
          />
        </div>
      </>
    )}
  </div>
)

interface EnterpriseDashboardTableProps {
  selectedMetric: EnterpriseDashboardMetric;
  enterpriseDashboardMetricLabels: any
  leaderBoardResults: APILeaderBoardResult[];
  currentTarget: APIKpiTarget | undefined;
}

const EnterpriseDashboardTable: React.FC<EnterpriseDashboardTableProps> = (props) => {
  const { icon, label, labelAbbreviation } = props.enterpriseDashboardMetricLabels[props.selectedMetric];
  const threshold = getTargetThreshold(props.currentTarget, props.selectedMetric);

  return (
    <div className="enterprise-dashboard__leaderboard">
      <Header
        icon={icon}
        title={label}
        subtitle="Last 30 days"
      />
      <div className="enterprise-dashboard__leaderboard__table">
        <div>
          <div>POSITION</div>
          <div>SITE</div>
          <div>{labelAbbreviation}</div>
        </div>
        <div className="with-custom-scrollbar">
          {props.leaderBoardResults.map((siteStat, index) => {
            let value: any = index + 1;
            if (index === 0) value = <>🥇</>
            else if (index === 1) value = <>🥈</>
            else if (index === 2) value = <>🥉</>

            let type = '';
            if (!threshold) {
              type = ''
            } else if (threshold > siteStat.value) {
              type = 'negative';
            } else {
              type = 'positive';
            }

            return (
              <div className={`enterprise-dashboard__leaderboard__table__column enterprise-dashboard__leaderboard__table__column--${type}`}>
                <div>{value}</div>
                <div>{siteStat.siteName}</div>
                <div>{siteStat.value}%</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

interface LineProps {
  data: { data: any[] }[];
  xScale: any;
  yScale: any;
}

interface CustomProps {
  x: number;
  y: number;
  target: number | null;
}

const TargetLine = (props: LineProps) => {
  const values: CustomProps[] = props.data[0].data.filter((item) => item.targetValue !== null).map((item) => ({
    x: item.x,
    y: item.y,
    target: item.targetValue
  }));

  const lineGenerator = line<CustomProps>()
    .x((item) => props.xScale(item.x))
    .y((item) => props.yScale(item.target || 0));

  const lastPoint = props.data[0].data.length ? props.data[0].data[props.data[0].data.length - 1] : null;

  return (
    <>
      <path d={lineGenerator(values) || undefined} fill="none" stroke="#FE663A" />
      {lastPoint && (
        <text
          style={{ fontSize: '10px', fill: '#FE663A' }}
          x={props.xScale(lastPoint.x) - 50}
          y={props.yScale(lastPoint.targetValue) - 5}
        >Target: {lastPoint.targetValue}%</text>
      )}
    </>
  );
};

const ProgressLine = (props: LineProps) => {
  const values: CustomProps[] = props.data[0].data.map((item) => ({
    x: item.x,
    y: item.realY,
    target: item.targetValue
  }));

  const lineGenerator = line<CustomProps>()
    .x((item) => props.xScale(item.x))
    .y((item) => props.yScale(item.y));

  return (
    <>
      <path d={lineGenerator(values) || undefined} fill="none" stroke="#A6A6A6" />
      {values.map((value, index) => (
        <circle
          key={index}
          cx={props.xScale(value.x)}
          cy={props.yScale(value.y)}
          r={2}
          fill="white"
          stroke="#A6A6A6"
          style={{ pointerEvents: "none" }}
        />
      ))}
    </>
  );
};

enum EnterpriseDashboardMetric {
  StraightThroughRate,
  PostedWithinXDays,
  ProcessExceptionFree
}

const findKpiTarget = (date: string | null, kpiTargets: APIKpiTarget[]) => {
  return kpiTargets.find((target) => moment(date).isSame(moment(target.startDate)) || moment(date).isAfter(moment(target.startDate)));
}

const getItemValue = (item: APIRollingAverageResult, selectedMetric: EnterpriseDashboardMetric) => {
  let value = item.straightThroughRate;
  if (selectedMetric === EnterpriseDashboardMetric.ProcessExceptionFree) {
    value = item.postedExceptionFreeRate;
  } else if (selectedMetric === EnterpriseDashboardMetric.PostedWithinXDays) {
    value = item.postedWithinDaysRate;
  }
  return value;
}

interface Props {
  permissionLevel: PermissionLevel;
}

export const EnterpriseDashboard: React.FC<Props> = (props) => {
  const [selectedMetric, setSelectedMetric] = React.useState<EnterpriseDashboardMetric>(EnterpriseDashboardMetric.StraightThroughRate);
  const [data, setData] = React.useState<APIEnterpriseDashboardData | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [kpiTargets, setKpiTargets] = React.useState<APIKpiTarget[]>([]);
  const currentTarget = kpiTargets.find((target) => moment().isSame(target.startDate) || moment().isAfter(target.startDate));

  const enterpriseDashboardMetricLabels = {
    [EnterpriseDashboardMetric.StraightThroughRate]: {
      label: 'Straight through rate',
      labelAxis: 'Straight through rate - last 30 days (%)',
      labelAbbreviation: 'STR',
      icon: <StraightThroughIcon />,
    },
    [EnterpriseDashboardMetric.PostedWithinXDays]: {
      label: `Posted within ${currentTarget?.timeToProcess || 'X'} days`,
      labelAxis: `Posted within ${currentTarget?.timeToProcess || 'X'} days - last 30 days (%)`,
      labelAbbreviation: '%',
      icon: <PostedWithinIcon />,
    },
    [EnterpriseDashboardMetric.ProcessExceptionFree]: {
      label: 'Straight through potential',
      labelAxis: 'Straight through potential - last 30 days (%)',
      labelAbbreviation: '%',
      icon: <ProcessExceptionFreeIcon />,
    }
  }

  React.useEffect(() => {
    setIsLoading(true);
    CompanyAPI.getApEnterpriseDashboardData().then((data) => {
      setIsLoading(false);
      setData(data);
    });

    KPITargetAPI.fetchAll().then((data) => {
      setKpiTargets(data.sort((target1, target2) => moment(target1.startDate).isBefore(moment(target2.startDate)) ? 1 : -1));
    });
  }, []);

  const positiveChartData: { x: string, y: number | null, realY: number | null, targetValue: number | null }[] = [];
  const negativeChartData: { x: string, y: number | null, realY: number | null, targetValue: number | null }[] = [];

  let areaColor;
  let maximum = 0;

  data?.rollingAverage.forEach((item, index) => {
    const target = findKpiTarget(item.date, kpiTargets);
    const targetValue = getTargetThreshold(target, selectedMetric);

    const value = getItemValue(item, selectedMetric);

    if (value > maximum) {
      maximum = value;
    }
    if (targetValue && targetValue > maximum) {
      maximum = targetValue;
    }

    areaColor = 'negative';
    if (index < data.rollingAverage.length - 1) {
      const nextItem = data.rollingAverage[index + 1];
      const nextTarget = findKpiTarget(nextItem.date, kpiTargets);
      const nextTargetValue = nextTarget ? getTargetThreshold(nextTarget, selectedMetric) : null;
      const nextValue = getItemValue(nextItem, selectedMetric);

      if (nextTargetValue && targetValue) {
        const nextValueDiff = nextValue - nextTargetValue;

        if (nextValueDiff >= 0) {
          areaColor = 'positive';
        }
      }
    }

    const date = moment(item.date).format('DD MMM').toString();

    let [valuePositive, valueNegative]: [number | null, number | null] = [null, null];

    if (targetValue === null) {
      [valuePositive, valueNegative] = [value, value];
    } else if (value >= targetValue) {
      [valuePositive, valueNegative] = [value, value];
    } else if (value < targetValue) {
      if (areaColor === 'negative') {
        [valuePositive, valueNegative] = [null, value];
      } else {
        [valuePositive, valueNegative] = [value, value];
      }
    }

    positiveChartData.push({
      x: date,
      y: valuePositive,
      realY: value,
      targetValue: targetValue
    });

    negativeChartData.push({
      x: date,
      y: valueNegative,
      realY: value,
      targetValue: targetValue
    });
  });

  const series = [
    { id: 'Positive', data: positiveChartData },
    { id: 'Negative', data: negativeChartData },
  ];

  const valuesToShow = positiveChartData.reduce((map, value, index) => {
    if (index % 7 === 0) {
      map[value.x.toString()] = true;
    }

    return map;
  }, {} as { [key: string]: boolean });

  let leaderBoardResults: APILeaderBoardResult[] | undefined = data?.leaderBoardResults?.topStraightThroughSites;

  if (selectedMetric === EnterpriseDashboardMetric.ProcessExceptionFree) {
    leaderBoardResults = data?.leaderBoardResults?.topExceptionFreeSites;
  } else if (selectedMetric === EnterpriseDashboardMetric.PostedWithinXDays) {
    leaderBoardResults = data?.leaderBoardResults?.topPostedWithinDaysSites;
  }

  return (
    <div className="enterprise-dashboard">
      {!data && (
        <div className="enterprise-dashboard__overlay">
          {isLoading ? (
            <Icon style={{ fontSize: '48px', margin: '20px calc(50% - 24px) 30px' }} src="spinner-md" />
          ) : (
            <div>
              <h1>Welcome to your Dashboard</h1>
              {props.permissionLevel === PermissionLevel.Admin ? (
                <>
                  <p>To get started, please configure your targets</p>
                  <Link to={AdminLinks.KpiTargets}><a className="light-button">Configure Targets</a></Link>
                </>
              ) : (
                <p>Please ask your site Admin to visit this page and configure your KPIs</p>
              )}
            </div>
          )}
        </div>
      )}
      <div className="enterprise-dashboard__header">
        <DashboardTile
          title={enterpriseDashboardMetricLabels[EnterpriseDashboardMetric.StraightThroughRate].label}
          value={data?.straightThroughData.straightThroughRate}
          lastPeriodDifference={data?.straightThroughData.againstLast}
          targetDifference={data?.straightThroughData.againstTarget}
          icon={enterpriseDashboardMetricLabels[EnterpriseDashboardMetric.StraightThroughRate].icon}
          selected={selectedMetric === EnterpriseDashboardMetric.StraightThroughRate}
          onClick={() => setSelectedMetric(EnterpriseDashboardMetric.StraightThroughRate)}
        />
        <DashboardTile
          title={enterpriseDashboardMetricLabels[EnterpriseDashboardMetric.PostedWithinXDays].label}
          value={data?.postedWithInData.postedWithInRate}
          lastPeriodDifference={data?.postedWithInData.againstLast}
          targetDifference={data?.postedWithInData.againstTarget}
          icon={enterpriseDashboardMetricLabels[EnterpriseDashboardMetric.PostedWithinXDays].icon}
          selected={selectedMetric === EnterpriseDashboardMetric.PostedWithinXDays}
          onClick={() => setSelectedMetric(EnterpriseDashboardMetric.PostedWithinXDays)}
        />
        <DashboardTile
          title={enterpriseDashboardMetricLabels[EnterpriseDashboardMetric.ProcessExceptionFree].label}
          value={data?.postedExceptionFree.postedExceptionFreeRate}
          lastPeriodDifference={data?.postedExceptionFree.againstLast}
          targetDifference={data?.postedExceptionFree.againstTarget}
          icon={enterpriseDashboardMetricLabels[EnterpriseDashboardMetric.ProcessExceptionFree].icon}
          selected={selectedMetric === EnterpriseDashboardMetric.ProcessExceptionFree}
          onClick={() => setSelectedMetric(EnterpriseDashboardMetric.ProcessExceptionFree)}
        />
      </div>
      <div className="enterprise-dashboard__chart-wrapper">
        <Header
          icon={enterpriseDashboardMetricLabels[selectedMetric].icon}
          title={enterpriseDashboardMetricLabels[selectedMetric].label}
          subtitle="30 day rolling total"
        />
        <div style={{ height: '500px' }}>
          <ResponsiveLine
            theme={{
              axis: {
                ticks: {
                  text: {
                    fill: '#7C7C7C'
                  }
                }
              },
            }}
            data={series}
            margin={{ top: 50, right: 60, bottom: 70, left: 100 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 0, max: maximum + 10, stacked: false, reverse: false }}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: 52,
              legendPosition: 'middle',
              renderTick: (props: any) => {
                if (!valuesToShow[props.value]) return '';
                return (
                  <g
                    transform={`translate(${props.x},${props.y})`}
                    style={{ opacity: props.opacity }}
                  >
                    <line x1="0" x2="0" y1="5" y2={10} style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: 1 }} />
                    <text
                      alignmentBaseline={props.textBaseline}
                      style={{
                        fontFamily: 'sans-serif',
                        fontSize: '11px',
                        fill: '#7C7C7C'
                      }}
                      textAnchor={props.textAnchor}
                      transform={`translate(${props.textX},${props.textY})`}
                      y={5}
                    >
                      {props.value}
                    </text>
                  </g>
                )
              }
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: enterpriseDashboardMetricLabels[selectedMetric].labelAxis,
              legendOffset: -60,
              legendPosition: 'middle',
            }}
            enableGridX={false}
            pointColor={{ from: 'color', modifiers: [] }}
            pointLabel="y"
            colors={['#D9F6E5', '#FFEAE5']}
            pointLabelYOffset={-12}
            useMesh={true}
            areaOpacity={1}
            tooltip={({ point }) => (
              <p className="chart-tooltip chart-tooltip-with-padding" style={{ padding: '5px 10px', background: 'white', boxShadow: '0 1px 4px rgba(0, 0, 0, .25)' }}>
                {point.serieId === 'Target' ? (
                  <>Target: <b>{point.data.y}%</b></>
                ) : (
                  <>
                    {point.data.x}
                    <br />
                    Last 30 days: <b>{point.data.y}%</b></>
                )}
              </p>
            )}
            layers={['grid', 'axes', 'areas', 'markers', 'crosshair', 'lines', 'points', 'mesh', ProgressLine, TargetLine]}
            enableArea
            animate={false}
          />
        </div>
      </div>
      {!!leaderBoardResults?.length && (
        <div>
          <EnterpriseDashboardTable
            enterpriseDashboardMetricLabels={enterpriseDashboardMetricLabels}
            currentTarget={currentTarget}
            selectedMetric={selectedMetric}
            leaderBoardResults={leaderBoardResults}
          />
        </div>
      )}
    </div>
  )
}
