import { ContainerRow } from './ContainerRow';
import * as React from 'react';
import { BillOfLadingContainerAPI } from '../../../api/billOfLadingContainer';
import { FocusDirection } from '../../helpers';
import { APIDocumentGroupContainer } from '../../../api/documentGroup';
import { OptionValue } from 'react-selectize';
import { ShipamaxPlusIcon } from '../../../images/Icons';

interface Props {
  containers?: APIDocumentGroupContainer[];
  reloadGroupData: () => void;
  disabled: boolean;
  containerTypeOptions: OptionValue[];
  billOfLadingId?: number;
  onFocus?: () => void;
}

export const ContainersList: React.FC<Props> = (props) => {
  const [containerRows, setContainerRows] = React.useState<APIDocumentGroupContainer[]>([]);
  const [shouldFocusIndex, setShouldFocusIndex] = React.useState<number>(-1);

  React.useEffect(() => {
    setContainerRows(props.containers || []);
  }, [props.containers])

  const addContainerRow = async () => {
    const container = await BillOfLadingContainerAPI.create({ billOfLadingId: props.billOfLadingId });

    setContainerRows([...containerRows, { ...container, seals: [] }]);
    props.reloadGroupData();
    document.getElementById('add-new-container')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  const deleteContainerRow = async (id?: number) => {
    if (id && containerRows.length > 1) {
      await BillOfLadingContainerAPI.remove(id);

      const rows = containerRows.filter((row) => row.id !== id);
      setContainerRows(rows);
      props.reloadGroupData();
    }
  }

  const onChange = (id: number, change: Partial<APIDocumentGroupContainer>) => {
    setContainerRows((items) => items.map((container) => {
      return container.id === id ? { ...container, ...change } : container;
    }));
  }

  const handleShiftFocus = (direction: FocusDirection, currentContainerIndex: number) => {
    if (direction === FocusDirection.UP && currentContainerIndex - 1 >= 0) {
      setShouldFocusIndex(currentContainerIndex - 1);
    }

    if (direction === FocusDirection.DOWN && currentContainerIndex + 1 <= containerRows.length) {
      setShouldFocusIndex(currentContainerIndex + 1);
    }
  }

  return (
    <div className={`containers-list ${containerRows.length ? '' : 'container--empty'}`} style={{ display: 'block' }}>
      <div className="containers">
        {containerRows.map((row, i) => (
          <ContainerRow
            key={`container-row-${i}`}
            data={row}
            containerTypeOptions={props.containerTypeOptions}
            deleteContainerRow={deleteContainerRow}
            onChange={(change) => onChange(row.id, change)}
            hideLabels={i !== 0}
            onContainerUpdated={props.reloadGroupData}
            containersCount={containerRows.length}
            onShiftFocus={(direction) => handleShiftFocus(direction, i)}
            shouldFocusInput={shouldFocusIndex === i}
            onFocusCompleted={() => setShouldFocusIndex(-1)}
            disabled={props.disabled}
            onFocus={props.onFocus}
          />
        ))}
      </div>
      <div className="grid__row">
        <button
          id="add-new-container"
          className="manage-row-button active-on-hover"
          onClick={addContainerRow}
          data-test-id="add-container-btn"
          disabled={props.disabled}
        ><ShipamaxPlusIcon /> Add a Container</button>
      </div>
    </div>
  )
}
