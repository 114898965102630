import * as React from 'react';
import { TextInput } from '../../../common/TextInput';
import { FAIcon } from '../../../common/FAIcon';
import { Icon } from 'pivotal-ui/react/iconography';
import {
  APICwEAdaptorCredential,
  CwCredentialAPI,
  Environment,
  ShipamaxEnvironment
} from '../../../../api/cwCredential';
import { WrapperLabel } from '../../../common/WrapperLabel';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { CustomSelect } from '../../../common/CustomSelect';

const InfoIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.5 7.5C13.5 10.8137 10.8137 13.5 7.5 13.5C4.18629 13.5 1.5 10.8137 1.5 7.5C1.5 4.18629 4.18629 1.5 7.5 1.5C10.8137 1.5 13.5 4.18629 13.5 7.5ZM15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5ZM9.67773 9.7168H8.41797V5.83789C8.41797 5.75977 8.38867 5.69922 8.33008 5.65625C8.27148 5.60938 8.1875 5.58594 8.07812 5.58594H6.31445C6.04492 5.58594 5.85547 5.63477 5.74609 5.73242C5.63672 5.83008 5.58203 5.99414 5.58203 6.22461C5.58203 6.45508 5.63867 6.62109 5.75195 6.72266C5.86523 6.82031 6.05273 6.86914 6.31445 6.86914H6.97656V9.7168H5.7168C5.44336 9.7168 5.25 9.76562 5.13672 9.86328C5.02734 9.96094 4.97266 10.123 4.97266 10.3496C4.97266 10.5801 5.0293 10.7461 5.14258 10.8477C5.25977 10.9492 5.45117 11 5.7168 11H9.67773C9.94336 11 10.1328 10.9492 10.2461 10.8477C10.3633 10.7461 10.4219 10.5801 10.4219 10.3496C10.4219 10.123 10.3652 9.96094 10.252 9.86328C10.1387 9.76562 9.94727 9.7168 9.67773 9.7168ZM8.14258 3.10156C8.04883 2.99219 7.85938 2.9375 7.57422 2.9375C7.26562 2.9375 7.06641 3.00586 6.97656 3.14258C6.88672 3.2793 6.8418 3.59766 6.8418 4.09766C6.8418 4.36719 6.88477 4.54688 6.9707 4.63672C7.06055 4.72656 7.25781 4.77148 7.5625 4.77148C7.875 4.77148 8.07227 4.72461 8.1543 4.63086C8.24023 4.5332 8.2832 4.28516 8.2832 3.88672C8.2832 3.47266 8.23633 3.21094 8.14258 3.10156Z" />
  </svg>
)

const IconError = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="7.75" stroke="#FFA48D" strokeWidth="1.5" />
    <path d="M6.17169 6.17157L11.8285 11.8284M11.8285 6.17157L6.17169 11.8284" stroke="#FFA48D" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
)

import './cw-eadaptor-form.scss';

export enum CWEAdaptorCredentialStatus {
  None,
  Success,
  Failed
}

interface Props {
  environment: Environment;
}

export const CWEAdaptorForm: React.FC<Props> = (props) => {
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [record, setRecord] = React.useState<Partial<APICwEAdaptorCredential>>({});

  const [status, setStatus] = React.useState<CWEAdaptorCredentialStatus>(CWEAdaptorCredentialStatus.None);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isApiKeyMode, setIsApiKeyMode] = React.useState<boolean>(true);

  const authModeOptions = [{ value: 1, label: 'API Key' }, { value: 2, label: 'Username and Password' }];

  React.useEffect(() => {
    setStatus(CWEAdaptorCredentialStatus.None);
    setIsLoading(false);
  }, [record, isEditMode]);

  React.useEffect(() => {
    setIsEditMode(false);
    fetchData();
  }, [props.environment]);

  const fetchData = async () => {
    CwCredentialAPI.fetchCwEAdaptorCredentials({ customerEnv: props.environment }).then((eAdaptorCredentials) => {
      if (eAdaptorCredentials.length) {
        setRecord(eAdaptorCredentials[0]);
      } else {
        setRecord({
          username: '',
          password: '',
          endpoint: '',
          active: true,
          customerEnv: props.environment,
          envId: props.environment === Environment.Test ? ShipamaxEnvironment.TestProduction : ShipamaxEnvironment.Production,
          companyId: parseInt(localStorage.getItem('companyId') || ''),
        });
      }

      if (record.username && record.password) {
        setIsApiKeyMode(false);
      } else {
        setIsApiKeyMode(true);
      }
    });
  }

  const onTestCredentials = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const status = await CwCredentialAPI.testCwEAdaptorCredential(record as APICwEAdaptorCredential);

    setIsLoading(false);
    setStatus(status);
  };

  const onDiscard = () => {
    setIsLoading(false);
    setStatus(CWEAdaptorCredentialStatus.None);
    setIsEditMode(false);
    fetchData();
  };

  const onTestAndSave = async () => {
    setIsLoading(true);
    const currentStatus = await CwCredentialAPI.testCwEAdaptorCredential(record as APICwEAdaptorCredential);
    setStatus(currentStatus);
    setIsLoading(false);

    if (currentStatus === CWEAdaptorCredentialStatus.Success) {
      setIsEditMode(false)
      setStatus(CWEAdaptorCredentialStatus.None);

      if (record.id) {
        await CwCredentialAPI.update(record.id, record);
      } else {
        const newEntry = await CwCredentialAPI.create(record);
        setRecord(newEntry);
      }
    }
  };

  const onUpdate = (change: Partial<APICwEAdaptorCredential>) => {
    setRecord((record) => ({ ...record, ...change }));
  }

  const onAuthModeChange = (value: number) => {
    value === 1 ? setIsApiKeyMode(true) : setIsApiKeyMode(false);
  }

  return (
    <>
      <div>
        <WrapperLabel text="Enterprise ID">
          <TextInput
            disabled={!isEditMode}
            value={record.enterpriseId || null}
            name="enterpriseId"
            className="enterpriseId-input"
            onChange={(value) => onUpdate({ enterpriseId: value })}
          />
        </WrapperLabel>
        <WrapperLabel text="Server ID">
          <TextInput
            disabled={!isEditMode}
            value={record.serverId || null}
            name="serverId"
            className="serverId-input"
            onChange={(value) => onUpdate({ serverId: value })}
          />
        </WrapperLabel>

        {isEditMode &&
          <WrapperLabel text="Authentication Mode">
            <CustomSelect
              disabled={!isEditMode}
              value={isApiKeyMode ? authModeOptions[0] : authModeOptions[1]}
              options={authModeOptions}
              onValueChange={(value) => { onAuthModeChange(value?.value) }} />

            {isApiKeyMode &&
              <p className='info-text'>Please include the API Key at the end of your URL, as shown in this example: https://endpoint-URL.com/APIKey=ABCDEFG</p>
            }
          </WrapperLabel>
        }

        {!isApiKeyMode && (
          <>
            <WrapperLabel text="Username">
              <TextInput
                disableAutocomplete={true}
                disabled={!isEditMode}
                value={record.username || null}
                name="username"
                className="username-input"
                onChange={(value) => onUpdate({ username: value })}
              />
            </WrapperLabel>
            <WrapperLabel text="Password">
              <TextInput
                disableAutocomplete={true}
                disabled={!isEditMode}
                value={record.password || null}
                name="password"
                type="password"
                className="password-input"
                onChange={(value) => onUpdate({ password: value })}
              />
            </WrapperLabel>
          </>
        )}

        <WrapperLabel className="cw-eAdaptor-form__label-with-icon" text={(
          <>
            <span>Endpoint URL</span>
            {isEditMode && (
              <>
                <OverlayTrigger
                  overlay={(
                    <Tooltip>
                      <p className="text-left m-[5px]">
                        Direct CargoWise eAdaptor endpoints (i.e. without
                        <br />
                        middleware) should always:
                        <br />
                        1.start with “https://” not just “http://”
                        <br />
                        2. end in “/eAdaptor” not “/eadapterstreamedservice.svc”.
                      </p>
                    </Tooltip>
                  )}
                  placement="top" delayShow={500}>
                  <span className="active-on-hover h-[15px] pl-[10px]"><InfoIcon /></span>
                </OverlayTrigger>
              </>
            )}
          </>
        )}>
          <TextInput
            disabled={!isEditMode}
            value={record.endpoint || null}
            name="endpointUrl"
            className="endpointUrl-input"
            onChange={(value) => onUpdate({ endpoint: value })}
          />
        </WrapperLabel>
      </div>
      <div>
        {isEditMode ? (
          <div>
            <div className="flex">
              <button className="light-button discard-button" onClick={onDiscard}>Discard changes</button>
              <button className="light-button light-button--with-border test-button" onClick={onTestCredentials}>
                {isLoading ? <Icon src="spinner-md" /> : <>Test credentials</>}
              </button>
              <button className="full-button save-button" onClick={onTestAndSave}>
                {isLoading ? <Icon src="spinner-md" /> : <>Test & Save</>}
              </button>
            </div>
            {status === CWEAdaptorCredentialStatus.Failed && (
              <div className="cw-eAdaptor-form__status cw-eAdaptor-form__status--error"><span><IconError /></span>Invalid credentials</div>
            )}
            {status === CWEAdaptorCredentialStatus.Success && (
              <div className="cw-eAdaptor-form__status cw-eAdaptor-form__status--success"><FAIcon name="check-circle" solid />Valid credentials</div>
            )}
          </div>
        ) : (
          <button className="full-button" onClick={() => setIsEditMode(true)}>Edit</button>
        )}
      </div>
    </>
  );
};
