const appId = 'i15uuoni';

function shim([...args]) {
  shim.c(args);
}

namespace shim {
  export const q: any = [];
  export const c = (args: any) => shim.q.push(args);
}

declare global {
  interface Window { Intercom: any; }
}

window.Intercom = shim;

if (process.env.DEPLOY_ENV !== 'development' && process.env.DEPLOY_ENV !== 'test' && process.env.BABEL_ENV !== 'test') {
  window.addEventListener('load', () => {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${appId}`;
    const x = document.getElementsByTagName('head')[0];
    if (x) x.appendChild(s);
  }, false);
}

export default function (name: string, data?: any, ...rest: any[]) {
  if (!data) {
    // eslint-disable-next-line new-cap
    return window.Intercom(name);
  }
  // eslint-disable-next-line camelcase, new-cap
  return window.Intercom(name, { app_id: appId, ...data }, ...rest);
}
