import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { PermissionProfileForm } from './PermissionProfileForm';
import { PermissionProfilesTable } from './PermissionProfilesTable';
import { APIUser, UserAPI, UserStatus } from '../../../../api/comment';

import './index.scss';

interface Props {
  refetchUserDetails: () => void;
}

export const PermissionProfilesSection: React.FC<Props> = (props) => {
  const [permissionProfilesUsersMap, setPermissionProfilesUsersMap] = React.useState<Map<number, APIUser[]>>(new Map());

  React.useEffect(() => {
    UserAPI.fetchAll().then((data) => {
      setPermissionProfilesUsersMap(data.reduce((map, user) => {
        if (![UserStatus.Enabled].includes(user.status)) return map;
        if (user.parentUserId || !user.permissionProfileId) return map;

        const usersList = map.get(user.permissionProfileId) || [];
        map.set(user.permissionProfileId, [...usersList, user]);

        return map;
      }, new Map()));
    });
  }, [location.href]);

  return (
    <Switch>
      <Route path={AdminLinks.PermissionProfiles} exact component={() => (
        <PermissionProfilesTable permissionProfilesUsersMap={permissionProfilesUsersMap} />
      )} />
      <Route path={[AdminLinks.PermissionProfiles + '/form', AdminLinks.PermissionProfiles + '/form/:id']} exact component={() => (
        <PermissionProfileForm permissionProfilesUsersMap={permissionProfilesUsersMap} refetchUserDetails={props.refetchUserDetails} />
      )} />
    </Switch>
  )
};
