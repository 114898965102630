import * as React from 'react';
import { AdminHeader } from '../../AdminHeader';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { ButtonWithSpinner } from '../../../common/ButtonWithSpinner';
import { ConfirmModal } from '../../../common/ConfirmModal';
import { APICwReferenceImportOrg, CompanyAPI, ImportStatus } from '../../../../api/company';
import { Notification } from '../../../common/Notification';
import { CustomSelect } from '../../../common/CustomSelect';
import { WrapperLabel } from '../../../common/WrapperLabel';
import { OptionValue } from 'react-selectize';
import { FileInput } from '../../../common/FileInput';
import { isFormInvalid, RecordValidator } from '../../../common/Form';

export const importModeOptions: OptionValue[] = [
  { label: 'Replace', value: true },
  { label: 'Add', value: false },
];

interface Props {
  setNotification: (notification: Notification | null) => void;
}

export const OrganisationsImportForm: React.FC<Props> = (props) => {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [isConfirming, setIsConfirming] = React.useState<boolean>(false);
  const [clearExisting, setClearExisting] = React.useState<boolean | undefined>(undefined);
  const [imports, setImports] = React.useState<APICwReferenceImportOrg[]>([]);

  const history = useHistory();

  React.useEffect(() => {
    CompanyAPI.getOrganisationDataImports().then((data) => {
      setImports(data);
    });
  }, []);

  const recordValidator: RecordValidator = {
    clearExisting: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: () => clearExisting !== undefined
        },
        {
          errorMessage: 'An import is already in progress. Please try again once completed.',
          isValid: () => !imports.find((importOrgData) => ![ImportStatus.Completed, ImportStatus.Failed].includes(importOrgData.status))
        },
      ]
    },
    file: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: () => selectedFile !== null
        },
      ],
    }
  }

  const isUploadDisabled = isFormInvalid({ clearExisting, file: selectedFile }, recordValidator);

  const onConfirm = () => {
    if (isUploadDisabled) {
      setFormEntered(true);
      return;
    }

    setFormEntered(true);
    if (submitDisabled) return;

    setIsConfirming(true);
  }

  const onUpload = async () => {
    setIsConfirming(false);
    try {
      setIsUploading(true);

      const result = await CompanyAPI.importOrganisationData(selectedFile, !!clearExisting);
      setSelectedFile(null);
      if (result && result.id) {
        props.setNotification({ type: 'success', value: 'Import added to queue' });
      } else {
        props.setNotification({ type: 'error', value: 'Import failed' });
      }
      history.push(AdminLinks.OrganisationsImports);
    } catch (e) {
      setSelectedFile(null);
      props.setNotification({ type: 'error', value: 'Import failed' });
    }
    setIsUploading(false);
  }

  const [formEntered, setFormEntered] = React.useState<boolean>(false);

  const submitDisabled = false;

  return (
    <div className="admin__form__wrapper with-custom-scrollbar">
      <AdminHeader
        headline="Organisation data imports"
        tagText="Global"
        links={[
          {
            url: 'https://s3.eu-west-1.amazonaws.com/storage.shipamax-api.com/AddressProfileReportExample-v2.xls',
            label: 'Download csv template'
          },
          {
            url: '/knowledge-base/What-are-reference-data-uploads---dddb80127fd04e38bd2796e1b80b1727',
            label: 'What are reference data uploads?'
          },
        ]}
      />
      <div className="admin__form__content">
        <div>
          <WrapperLabel text="Import mode">
            <CustomSelect
              options={importModeOptions}
              value={importModeOptions.find((option) => option.value === clearExisting)}
              onValueChange={(option) => setClearExisting(option?.value)}
              formEntered={formEntered}
              validators={recordValidator.clearExisting.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="Select file">
            <FileInput
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              setNotification={props.setNotification}
              disabled={false}
              formEntered={formEntered}
              validators={recordValidator.file.validators}
              maxSize={10}
            />
          </WrapperLabel>
        </div>
        <div>
          <div>
            <Link to={AdminLinks.OrganisationsImports}><button className="light-button">Cancel</button></Link>
            <ButtonWithSpinner
              className="full-button"
              onClick={onConfirm}
              showSpinner={isUploading}
            >Upload</ButtonWithSpinner>
          </div>
        </div>
      </div>
      <ConfirmModal
        onHide={() => setIsConfirming(false)}
        show={isConfirming}
        onConfirm={onUpload}
        title={clearExisting ? 'Replace entire database' : 'Append data'}
        text={clearExisting ?
          `Your entire organisation database will be deleted and replaced by the data in the file you have uploaded.<br />
          Once submitted, please allow up to 24 hours for the updated data to become active.
          <br />
          <br />
          <b>Would you like to continue?</b>
          ` :
          `Are you sure you want to add this file to your existing Organisation data?<br />
          Once submitted, please allow up to 24 hours for the updated data to become active.
          <br />
          <br />
          <b>Would you like to continue?</b>`
        }
      />
    </div>
  )
}
