import * as React from 'react';
// @ts-ignore
import compose from 'recompose/compose';
// @ts-ignore
import withPropsOnChange from 'recompose/withPropsOnChange';
import { BarItemProps } from '@nivo/bar';
// @ts-ignore
import pure from 'recompose/pure';
import { BasicTooltip } from '@nivo/tooltip';
import { OFFSET_VALUE } from '../common';

export const enhance = compose(
  withPropsOnChange(['data', 'color', 'onClick'], ({ data, color, onClick }: any) => ({
    onClick: (event: any) => onClick({ color, ...data }, event),
  })),
  withPropsOnChange(
    ['data', 'color', 'theme', 'tooltip', 'getTooltipLabel', 'tooltipFormat'],
    ({ data, color, theme, tooltip, getTooltipLabel, tooltipFormat }: any) => ({
      tooltip: (
        <BasicTooltip
          id={getTooltipLabel(data)}
          value={data.value}
          enableChip={true}
          color={color}
          format={tooltipFormat}
          renderContent={
            typeof tooltip === 'function'
              ? tooltip.bind(null, { color, theme, ...data })
              : null
          }
        />
      ),
    })
  ),
  pure
)

export const getCustomStackedBarItem = (maxTotal: number) => enhance((props: BarItemProps) => {
  const { data, x, y, width, height, borderRadius, color, borderWidth, borderColor,
    showTooltip, hideTooltip, onClick, onMouseEnter, onMouseLeave, tooltip } = props;

  const handleTooltip = (e: any) => showTooltip(tooltip, e);

  const handleMouseEnter = (e: React.MouseEvent<any>) => {
    onMouseEnter(data as any, e);
    showTooltip(tooltip, e);
  }
  const handleMouseLeave = (e: React.MouseEvent<any>) => {
    onMouseLeave(data as any, e);
    hideTooltip();
  }

  const pageHeightAdjusted = document.querySelector('.chart')!.clientHeight - 80;
  const totalValue = (data as any).data.total;
  const totalHeight = totalValue * pageHeightAdjusted / maxTotal;
  const adjustedHeight = (data.value - OFFSET_VALUE) * pageHeightAdjusted / maxTotal; // we need to lower the value by the offset
  const adjustedY = y - (adjustedHeight - height);

  return (
    <g transform={`translate(${x}, ${adjustedY})`}>
      <rect
        width={width}
        height={totalHeight}
        y={adjustedHeight - totalHeight}
        rx={borderRadius}
        ry={borderRadius}
        fill={'#ddd'}
        strokeWidth={borderWidth}
        stroke={borderColor}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleTooltip}
        onMouseLeave={handleMouseLeave}
        onClick={onClick as any}
      />
      {adjustedHeight > 0 && (
        <rect
          width={width}
          height={adjustedHeight}
          rx={borderRadius}
          ry={borderRadius}
          fill={color}
          strokeWidth={borderWidth}
          stroke={borderColor}
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleTooltip}
          onMouseLeave={handleMouseLeave}
          onClick={onClick as any}
        />
      )}
    </g>
  )
});
