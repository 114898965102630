import * as React from 'react';
import { Notification } from '../../../common/Notification';
import { Route, Switch } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { EmailAlertsTable } from './EmailAlertsTable';
import { EmailAlertForm } from './EmailAlertForm';
import { OptionValue } from 'react-selectize';
import { TeamAPI } from '../../../../api/teams';

import './index.scss';

export const UTCOffset = -1 * new Date().getTimezoneOffset() / 60.0;
export const UTCOffsetLabel = UTCOffset === 0 ? '' : (UTCOffset > 0 ? ' +' + UTCOffset :  ' ' + UTCOffset);

export const getMinutesFromOffset = (offset: number): string => {
  let fractionalPart = offset % 1;
  let minutes = Math.abs(fractionalPart * 60);
  return String(minutes === 0 ? '00' : minutes);
}

interface Props {
  setNotification: (notification: Notification | null) => void;
}

export const EmailAlertsSection: React.FC<Props> = (props) => {
  const [teamOptions, setTeamOptions] = React.useState<OptionValue[]>([]);

  React.useEffect(() => {
    fetchReferenceDataFromAPI();
  }, []);

  const fetchReferenceDataFromAPI = async () => {
    const [teams] = await Promise.all([TeamAPI.fetchAll()]);
    setTeamOptions(teams.filter((team) => !team.disabled).map((team) => ({ label: team.name, value: team.id })));
  }

  return (
    <Switch>
      <Route path={AdminLinks.EmailAlerts} exact component={() => (
        <EmailAlertsTable teamOptions={teamOptions} setNotification={props.setNotification} />
      )}/>
      <Route path={[AdminLinks.EmailAlerts + '/form/add/:teamId', AdminLinks.EmailAlerts + '/form/:id']} exact component={() => (
        <EmailAlertForm
          teamOptions={teamOptions}
        />
      )} />
    </Switch>
  )
}
