import * as React from 'react';
import { OptionValue, SimpleSelect, SimpleSelectProps } from 'react-selectize';
import { APIDocCoordinate, FieldData, findMatchedCoordinates } from '../../api/documentContainer';
import { DocumentViewerContext } from '../DocumentViewerContext';
import { ShipamaxDownIcon } from '../../images/Icons';
import { sortSearchOptions, hasOverlap } from '../helpers';
import { FieldValidator } from './Form';

import './custom-select.scss'

export const defaultOnFilterOptions = (items: OptionValue[], search: string): OptionValue[] => {
  const filteredOptions = items.filter((option) => option.label.includes(search.toUpperCase()));
  return sortSearchOptions(filteredOptions, search);
}

interface Props {
  filterOptions?: (options: OptionValue[], search: string) => OptionValue[];
  options: OptionValue[];
  onValueChange: (option: OptionValue | null) => void;
  onSearchChange?: (search: string) => void;
  className?: string;
  value?: OptionValue;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  renderNoResultsFound?: (item: OptionValue, search: string) => any;
  renderValue?: (option: OptionValue) => any;
  dropdownMenuSize?: 'wide' | 'extra-wide';
  dropdownMenuRightAlignment?: true;
  showResetButton?: boolean;
  renderResetButton?: () => any;
  createFromSearch?: (options: OptionValue[], search: string) => OptionValue;
  fieldData?: FieldData;
  placeholder?: string;
  defaultValue?: OptionValue;
  renderOption?: (option: OptionValue) => any;
  fillInputOnFocus?: boolean;
  required?: boolean;
  validators?: FieldValidator[];
  hideValidatorMessage?: boolean;
  formEntered?: boolean;
  groups?: any[];
  dataTestId?: string;
}

export const CustomSelect: React.FC<Props> = (props) => {
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const selectRef = React.useRef<SimpleSelect>(null);

  const documentViewerContext = React.useContext(DocumentViewerContext);

  const matchedCoordinates = findMatchedCoordinates(props.fieldData || null, documentViewerContext.coordinates) || null;

  React.useEffect(() => {
    if (selectRef.current) {
      // @ts-ignore
      const func = selectRef.current?.refs.select.handleKeydown;

      // @ts-ignore
      selectRef.current.refs.select.handleKeydown = (args, e) => {
        if (e.which !== 27 || selectRef.current?.state.open) {
          func.call(selectRef.current?.refs.select, args, e);
        }
      };
    }
  }, [selectRef.current]);

  React.useEffect(() => {
    if (props.fieldData) {
      if (hasOverlap<APIDocCoordinate>(matchedCoordinates, documentViewerContext.focusedCoordinates)) {
        selectRef.current?.setState({ open: true });
      }
    }
  }, [documentViewerContext.focusedCoordinates]);

  const onFilterOptions = (items: OptionValue[], search: string): OptionValue[] => {
    return items.filter((option) => option.label.toUpperCase().includes(search.toUpperCase()));
  }

  const onBlur = () => {
    if (hasOverlap(documentViewerContext.focusedCoordinates, matchedCoordinates)) {
      documentViewerContext.setFocusedCoordinates([]);
    }
    props.onBlur && props.onBlur();
  }

  const onFocus = () => {
    props.onFocus && props.onFocus();
    matchedCoordinates && documentViewerContext.setFocusedCoordinates(matchedCoordinates);

    if (props.fillInputOnFocus) {
      const value = props.value?.label?.split(" - ")[0];
      if (value) setSearchTerm(value);
    }
  }

  const disabledProps: Partial<SimpleSelectProps> = props.disabled ? { search: props.value?.label || '' } : { search: searchTerm };

  const triggeredValidator = props.formEntered ? props.validators?.find((validator) => {
    if (!validator.isValid(props.value?.value)) {
      return true;
    }
  }) : null;

  const classNames = [
    props.className,
    `dropdown-size-${props.dropdownMenuSize || 'normal'}`,
    `${props.dropdownMenuRightAlignment ? 'dropdown-align-right' : ''}`,
    props.disabled ? 'custom-select--disabled' : '',
    ((props.required && !props.value && props.fieldData?.objectIds.find((id) => id !== -1)) || triggeredValidator) ? 'custom-select--highlighted' : ''
  ];

  return (
    <div className="custom-select__wrapper" data-test-id={props.dataTestId}>
      <SimpleSelect
        placeholder={props.placeholder}
        ref={selectRef}
        onFocus={onFocus}
        onBlur={onBlur}
        hideResetButton={!props.showResetButton}
        renderResetButton={props.renderResetButton || undefined}
        autofocus={false}
        options={props.options}
        value={props.value}
        renderNoResultsFound={props.renderNoResultsFound ? props.renderNoResultsFound : (item: OptionValue, search: string) => <></>}
        filterOptions={props.filterOptions ? props.filterOptions : onFilterOptions}
        onValueChange={(item: OptionValue) => {
          if (props.disabled) {
            return;
          }

          documentViewerContext.setFocusedCoordinates([]);
          props.fieldData && documentViewerContext.removeCoordinates({
            parentTableName: props.fieldData.tableName,
            parentTableIds: props.fieldData.objectIds,
            attributeNames: props.fieldData.attributeNames
          });

          setSearchTerm('');
          props.onValueChange(item);
        }}
        onSearchChange={(search) => {
          setSearchTerm(search);
          if (props.onSearchChange) {
            props.onSearchChange(search);
          }
        }}
        renderToggleButton={({ open, flipped }) => <ShipamaxDownIcon className={`simple-select--toggle ${open && !props.disabled ? 'open' : ''}`} />}
        cancelKeyboardEventOnSelection={false} // Helps tab move to next input
        delimiters={[9]} // Select using tab as well as enter
        className={classNames.join(' ')}
        renderValue={(item: OptionValue) => props.renderValue ? props.renderValue(item) : item.label}
        renderOption={props.renderOption}
        firstOptionIndexToHighlight={(optionIndex, options, value, search) => {
          return (!value && optionIndex == 0 && search.length == 0) ? -1 : optionIndex
        }}
        createFromSearch={props.createFromSearch}
        defaultValue={props.defaultValue}
        groups={props.groups}
        {...disabledProps}
      />
      {(triggeredValidator?.errorMessage && !props.hideValidatorMessage) && (
        <div className="form-field__error">
          {triggeredValidator.errorMessage}
        </div>
      )}
    </div>
  )
}
