import { request } from './request';
import { AppSection, AppSectionIds } from '../components/nav-bar/NavBar';
import { DateFilter, DateFilterType, DateOriginFilterType, DateUnit } from '../components/insights/DateFilter';
import { Aggregation, AssignedToOptions, ChartType, DataSet, InvoiceStatusSegment, OrganisationType, SectionModule } from '../components/insights/common';
import { CounterType } from '../components/insights/ChartWrapper';

export interface AssignmentRule {
  teamId: number | null;
  userIds: number[];
}

export interface APInvSettings {
  searchTeam?: number | null;
  searchUsers?: number[] | null;
  searchStatus?: number;
  searchOrder?: number;
}

export interface BlSettings {
  searchClosed?: boolean;
  searchTeam?: number | null;
  searchUsers?: number[] | null;
  searchStatus?: number;
  searchOrder?: number;
  boardTeam?: number | null;
  boardUserIds?: number[] | null;
  hideDiscardModal?: boolean;
  hideDoneModal?: boolean;
  hidePostModal?: boolean;
  hideReopenModal?: boolean;
  hidePostStandaloneCINV?: boolean;
  hidePostCreateBJob?: boolean;
  hidePostUpdateBJob?: boolean;
  hidePostUpdateSJob?: boolean;
  colOrderAndWidth?: {
    section: number,
    columns: ColumnsDetails[]
  }[];
  lineItemsColOrder?: {
    section: number,
    columns: ColumnsDetails[]
  }[];
}

export interface SelectedCreditor {
  code: string;
  id: number;
}

export interface InsightsFilterSettings {
  defaultSection?: SectionModule;
  assignedType: AssignedToOptions;
  mailboxIds: number[];
  userIds: number[];
  teamIds: number[];
  creditorCodes?: string[];
  organisationType?: OrganisationType;
  creditors?: SelectedCreditor[];
  dateFilter: DateFilter;
  segments: InvoiceStatusSegment[];
  chartType: ChartType;
  dataSet: DataSet;
}

export interface ChartUserSettings {
  aggregation: Aggregation;
  counterType: CounterType;
  selectedCurrency?: string;
}

export interface ChartsUserSettings {
  [index: number]: ChartUserSettings
}

export interface ColumnsDetails {
  key: string,
  width: string | number | null,
  hidden: boolean | undefined
}

const segmentsKeys = Object.keys(InvoiceStatusSegment).filter(k => typeof InvoiceStatusSegment[k as any] === "number");
const allSegmentsValues = segmentsKeys.map(k => parseInt(InvoiceStatusSegment[k as any], 10));

export const defaultInsightsFilterSettings = {
  [SectionModule.APInvoice]: {
    assignedType: AssignedToOptions.Mailbox,
    segments: allSegmentsValues.filter((s) => ![InvoiceStatusSegment.PostedAfterCorrection].includes(s)),
    mailboxIds: [],
    teamIds: [],
    userIds: [],
    organisationType: OrganisationType.Any,
    creditors: [],
    chartType: ChartType.InvoiceCount,
    dataSet: DataSet.CommercialInvoice,
    dateFilter: {
      dateOriginType: DateOriginFilterType.Received,
      type: DateFilterType.InTheLast,
      dateUnit: DateUnit.Month,
      dateUnitsCount: 1,
      firstDate: null,
      secondDate: null
    }
  },
  [SectionModule.ForwardAndClearance]: {
    assignedType: AssignedToOptions.Mailbox,
    segments: allSegmentsValues.filter((s) => ![InvoiceStatusSegment.Queried, InvoiceStatusSegment.PostedAfterExceptions].includes(s)),
    mailboxIds: [],
    teamIds: [],
    userIds: [],
    typeOfOrganisation: OrganisationType.Any,
    creditors: [],
    chartType: ChartType.InvoiceCount,
    dataSet: DataSet.CommercialInvoice,
    dateFilter: {
      dateOriginType: DateOriginFilterType.Received,
      type: DateFilterType.InTheLast,
      dateUnit: DateUnit.Month,
      dateUnitsCount: 1,
      firstDate: null,
      secondDate: null
    },
  }
};

export interface UserSettings {
  appSection?: number;
  apInvSettings?: APInvSettings;
  blSettings?: BlSettings;
  knowledgeBasePage?: string;
  insightsSettings?: InsightsFilterSettings;
  insightsChartSettings?: ChartsUserSettings;
  lastLoggedInCompanyId?: number;
}

export interface APIUserSettings {
  id: number;
  userId: number;
  settings: UserSettings;
}

export const UserSettingsAPI = {
  fetch(): Promise<{ userSettings: APIUserSettings }> {
    const filter = { include: 'userSettings' };
    return request(`/Users/${localStorage.getItem('userId')}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
  },

  update(patch: Partial<UserSettings>): Promise<APIUserSettings> {
    return request(`/UserSettings/${localStorage.getItem('userSettingsId')}`, 'PATCH', { settings: patch });
  },

  updateParentSettings(settings: object): Promise<APIUserSettings> {
    return request('/UserSettings/parent', 'PATCH', settings);
  },

  updateParentLastLoggedInSetting(lastLoggedInCompanyId: number): Promise<APIUserSettings> {
    return this.updateParentSettings({ lastLoggedInCompanyId });
  },

  create(): Promise<APIUserSettings> {
    const payload: { settings: UserSettings } = {
      settings: {
        appSection: AppSectionIds[AppSection.APInvoice],
        apInvSettings: {
          searchTeam: null,
          searchStatus: 0,
          searchOrder: 1,
        },
        blSettings: {
          searchClosed: true,
          searchTeam: null,
          searchStatus: 1,
          searchOrder: 1,
          boardTeam: null,
        },
        knowledgeBasePage: undefined,
        insightsSettings: defaultInsightsFilterSettings[SectionModule.APInvoice],
        insightsChartSettings: {},
        lastLoggedInCompanyId: undefined,
      }
    };

    return request(`/Users/${localStorage.getItem('userId')}/userSettings`, 'POST', payload);
  }
}
