import * as React from 'react';

import './tab-switcher.scss';

interface Props<T> {
  selectedTab: T;
  onTabClicked: (index: T) => void;
  tabs: T[];
}

export const TabSwitcher = <T extends string>(props: Props<T>) => (
  <div className="tab-switcher">
    {props.tabs.map((tab, index) => (
      <button
        key={index}
        className={`light-button ${props.selectedTab === tab ? 'selected' : ''}`}
        onClick={() => props.onTabClicked(tab)}
      >{tab}</button>
    ))}
  </div>
);