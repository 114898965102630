import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { navigateToUrl } from '../helpers';

import './admin-navigation.scss';

export interface AdminNavigationLink {
  path: string;
  label: string;
  component: any;
}

interface AdminNavigationGroup {
  label: string;
  links: AdminNavigationLink[];
}

interface Props {
  groups: AdminNavigationGroup[];
}

export const AdminNavigation: React.FC<Props> = (props) => {
  const history = useHistory();

  return (
    <div className="admin-navigation with-custom-scrollbar">
      <div className="admin-navigation__title">Admin</div>
      {props.groups.map((group, groupIndex) => (
        <div key={groupIndex} className="admin-navigation__group">
          <h2>{group.label}</h2>
          {group.links.map((link, linkIndex) => (
            <div
              key={linkIndex}
              className={`admin-navigation__link active-on-hover ${window.location.pathname.includes(link.path) ? 'admin-navigation__link--selected' : ''}`}
              onClick={() => navigateToUrl(link.path, history)}
              id={link.label.toLowerCase().replace(/ /g, '-')}
            >{link.label}</div>
          ))}
        </div>
      ))}
    </div>
  )
}