import * as React from 'react';
import { APITMSApplicationCredential, CwLoginAPI, TMSLoginType } from '../../../../api/cwLogin';
import { TextInput } from '../../../common/TextInput';
import { WrapperLabel } from '../../../common/WrapperLabel';

interface Props {
  tmsLoginType: TMSLoginType;
  customerEnv: number | null;
  label?: string;
}

export const TMSApplicationForm: React.FC<Props> = (props) => {
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [record, setRecord] = React.useState<Partial<APITMSApplicationCredential>>({
    username: '',
    password: '',
    customerEnv: props.customerEnv,
    companyId: parseInt(localStorage.getItem('companyId') || ''),
    type: props.tmsLoginType
  });

  React.useEffect(() => {
    setIsEditMode(false);
    fetchData();
  }, []);

  const fetchData = () => {
    CwLoginAPI.fetchCwApplicationCredential({ type: props.tmsLoginType, customerEnv: props.customerEnv }).then((applicationCredentials) => {
      if (applicationCredentials.length) {
        setRecord(applicationCredentials[0]);
      }
    });
  }

  const onSave = async () => {
    if (record?.id) {
      await CwLoginAPI.update(record.id, record);
    } else {
      const newEntry = await CwLoginAPI.create(record);
      setRecord(newEntry);
    }
    fetchData();
    setIsEditMode(false);
  }

  const onCancel = () => {
    setIsEditMode(false);
    fetchData();
  }

  return (
    <>
      <div>
        <WrapperLabel text="Username">
          <TextInput
            disabled={!isEditMode}
            disableAutocomplete={true}
            value={record?.username || null}
            name="username"
            className="username-input"
            onChange={(value) => setRecord({ ...record, username: value })}
          />
        </WrapperLabel>
        <WrapperLabel text="Password">
          <TextInput
            disabled={!isEditMode}
            disableAutocomplete={true}
            value={record?.password || null}
            name="password"
            type="password"
            className="password-input"
            onChange={(value) => setRecord({ ...record, password: value })}
          />
        </WrapperLabel>
      </div>
      <div>
        {isEditMode ? (
          <>
            <button className="light-button" onClick={onCancel}>Cancel</button>
            <button className="full-button" onClick={onSave}>Save</button>
          </>
        ) : (
          <button className="full-button" onClick={() => setIsEditMode(true)}>Edit</button>
        )}
      </div>
    </>
  );
};