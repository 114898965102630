import { OptionValue } from 'react-selectize';
import { APILocationUnlocode, LocationUnlocodeAPI } from '../../api/locationUnlocode';
import { WrapperLabel } from './WrapperLabel';
import { CustomSelect } from './CustomSelect';
import * as React from 'react';
import { sortSearchOptions } from '../helpers';
import { APIDocumentGroupDocument, APIDocumentGroupShipment, APIDocumentGroupConsol, APIDocumentGroupCInvoice } from '../../api/documentGroup';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';

import './port-select.scss'
import { FAIcon } from './FAIcon';
import { FieldData } from '../../api/documentContainer';

export const getUnlocodeOptions = (value: OptionValue | null): OptionValue[] => {
  return value?.value.split(',').map((unlocode: string) => ({ value: unlocode, label: unlocode })) || [];
}

export const prepareLocationOptions = (locations: APILocationUnlocode[], search: string): OptionValue[] => {
  const options: OptionValue[] = [];

  let prevName = '';
  let curName = '';
  let curUnlocodes: string[] = [];
  locations.forEach((location) => {
    curName = location.name.toUpperCase().trim();
    if ((prevName !== curName) && (prevName !== '')) {
      options.push({ value: curUnlocodes.join(','), label: prevName });
      curUnlocodes = [];
    }
    prevName = curName;
    curUnlocodes.push(location.unlocode);
  });
  if (curName !== '') options.push({ value: curUnlocodes.join(','), label: curName });

  // sort the options so that the ones starting with the search keyword will appear first before the others
  const optionsSorted: OptionValue[] = [];
  options.forEach((option) => {
    if (option.label.startsWith(search)) optionsSorted.push(option);
  });
  options.forEach((option) => {
    if (!option.label.startsWith(search)) optionsSorted.push(option);
  });

  return optionsSorted;
}

export const getLocations = async (search: string, onlySeaPort: boolean = false) => {
  if (search.length < 3) return [];

  let locations = await LocationUnlocodeAPI.fetchLocations({ unlocode: search, seaport: onlySeaPort === true ? true : null });
  locations.push(...await LocationUnlocodeAPI.fetchLocations({ name: search, seaport: onlySeaPort === true ? true : null }));

  return prepareLocationOptions(locations, search.toUpperCase().trim());
}

interface Props {
  label: string;
  value?: string | null;
  valueUnlocode?: string | null;
  onValueChange: (value: string | null, valueUnlocode: string | null) => void;
  onValueUnlocodeChange: (value: string | null) => void;
  onlySeaPort: boolean;
  dataTestId?: string;
  disabled?: boolean;
  document?: APIDocumentGroupDocument<APIDocumentGroupShipment | APIDocumentGroupConsol | APIDocumentGroupCInvoice> | undefined;
  onFocus?: () => void;
  fieldData?: FieldData;
  cloneFieldValue?: (senderValue: string | undefined, receiverFields: string[] | undefined, onlyInvoices: boolean, isOrganisation: boolean) => void;
  cloneToField1?: string;
  cloneToField2?: string;
  cloneFieldLabel?: string;
}

export const PortSelect: React.FC<Props> = (props) => {
  const [options, setOptions] = React.useState<OptionValue[]>([]);
  const [unlocodeOptions, setUnlocodeOptions] = React.useState<OptionValue[]>([]);

  const [portOption, setPortOption] = React.useState<OptionValue | null>(null);
  const [portUnlocodeOption, setPortUnlocodeOption] = React.useState<OptionValue | null>(null);

  React.useEffect(() => {
    getLocations(props.valueUnlocode || '', props.onlySeaPort).then((locations) => {
      const loadPort = locations.find((location) => location.value === props.valueUnlocode) || null;
      const unlocodeOptions = loadPort ? getUnlocodeOptions(loadPort) : [];
      const loadPortUnlocode = unlocodeOptions.find((unlocodeOption) => unlocodeOption.label === props.valueUnlocode) || null;

      setOptions(locations);
      setPortOption(loadPort);

      setUnlocodeOptions(unlocodeOptions);
      setPortUnlocodeOption(loadPortUnlocode);
    });
  }, [props.value, props.valueUnlocode]);

  const updatePortLocations = async (search: string) => {
    setOptions(await getLocations(search, props.onlySeaPort));
  }

  const onFilterOptions = (items: OptionValue[], search: string): OptionValue[] => {
    const filteredOptions = items.filter((option) => option.label.toUpperCase().includes(search.toUpperCase()));
    return sortSearchOptions(filteredOptions, search);
  }

  const onFilterOptionsAtLeast3Characters = (items: OptionValue[], search: string): OptionValue[] => {
    if (search.length < 3) return [];

    const filteredOptions = items.filter((option) =>
      option.value.toUpperCase().includes(search.toUpperCase()) ||
      option.label.toUpperCase().includes(search.toUpperCase())
    );
    return sortSearchOptions(filteredOptions, search);
  }

  return (
    <div className="port-select" data-test-id={props.dataTestId}>
      <WrapperLabel text={props.label}>
        <CustomSelect
          dataTestId={`${props.dataTestId}-custom-select`}
          options={options}
          value={portOption || undefined}
          renderNoResultsFound={(item: OptionValue, search: string) => <div className="dropdown-placeholder">Please enter the first 3 characters of the name...</div>}
          filterOptions={onFilterOptionsAtLeast3Characters}
          onSearchChange={(search) => updatePortLocations(search)}
          onValueChange={(value) => {
            setPortOption(value);

            const unlocodeOptions = getUnlocodeOptions(value) || [];
            const defaultOption = unlocodeOptions[0];

            setPortUnlocodeOption(defaultOption);
            setUnlocodeOptions(unlocodeOptions);

            props.onValueChange(value?.label || null, defaultOption?.value);
          }}
          disabled={props.disabled}
          onFocus={props.onFocus}
          fieldData={props.fieldData}
        />
      </WrapperLabel>
      <WrapperLabel text=""
        copyComponent={
          (props.cloneFieldValue && (
            <div className="clone-agents-on-wrapper">
              <OverlayTrigger overlay={<Tooltip><span>Set as {props.cloneFieldLabel || props.label} in all shipments</span></Tooltip>} delayShow={500} placement="left">
                <button
                  className="light-button clone-agents-button active-on-hover"
                  disabled={props.disabled || !portOption?.label || !portUnlocodeOption?.label}
                  onClick={() => {
                    if (props.cloneFieldValue && props.cloneToField1 && props.cloneToField2) {
                      props.cloneFieldValue(portOption?.label || undefined, [props.cloneToField1], false, false);
                      props.cloneFieldValue(portUnlocodeOption?.label || undefined, [props.cloneToField2], false, false);
                    }
                  }}
                ><FAIcon name="clone" /></button>
              </OverlayTrigger>
            </div>)
          )
        }>
        <CustomSelect
          options={unlocodeOptions}
          value={portUnlocodeOption || undefined}
          filterOptions={onFilterOptions}
          onValueChange={(value) => {
            setPortUnlocodeOption(value);
            props.onValueUnlocodeChange(value?.value)
          }}
          className="unlocode-dropdown"
          disabled={unlocodeOptions.length <= 1 || props.disabled}
          onFocus={props.onFocus}
        />
      </WrapperLabel>
    </div>
  )
}
