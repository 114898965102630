import { request } from './request';
import { ChartUserSettings, InsightsFilterSettings } from './userSettings';
import { SectionModule, SerieToggleState } from '../components/insights/common';

export interface APICustomDashboardChart {
  id: number | undefined;
  name: string;
  dashboardId: number;
  dashboardRowId: number | undefined;
  orderIndex: number;
  settings: {
    insightsSettings: InsightsFilterSettings;
    chartSettings: ChartUserSettings;
    userOptions: SerieToggleState | undefined
  };
}

export interface APICustomDashboardRow {
  id: number;
  dashboardId: number;
  orderIndex: number;

  charts: APICustomDashboardChart[];
}

export interface APICustomDashboard {
  id: number | undefined;
  name: string;
  companyId: number;

  sectionModule: SectionModule;

  rows: APICustomDashboardRow[];
}

export const CustomDashboardAPI = {
  async create(customDashboard: APICustomDashboard): Promise<APICustomDashboard> {
    return request(`/CustomDashboards`, 'POST', customDashboard);
  },

  async update(id: number, change: Partial<APICustomDashboard>): Promise<APICustomDashboard> {
    return request(`/CustomDashboards/${id}`, 'PATCH', change);
  },

  async delete(id: number): Promise<unknown> {
    return request(`/CustomDashboards/${id}`, 'DELETE');
  },

  async fetchAll(sectionModule: SectionModule): Promise<APICustomDashboard[]> {
    const filter = JSON.stringify({
      where: {
        companyId: parseInt(localStorage.getItem('companyId') || ''),
        sectionModule,
      },
    });
    return request(`/CustomDashboards?filter=${encodeURIComponent(filter)}`);
  },

  async fetch(id: number): Promise<APICustomDashboard> {
    const filter = { include: { rows: 'charts' } };
    return request(`/CustomDashboards/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
  },

  async isDashboardNameTaken(value: string, sectionModule: SectionModule, dashboardId: number | undefined): Promise<boolean> {
    const companyId = parseInt(localStorage.getItem('companyId') || '');
    const filter = JSON.stringify({
      where: {
        companyId,
        sectionModule,
        name: value,
        ...(dashboardId ? { id: { neq: dashboardId } } : {}),
      },
    });

    const result = await request(`/CustomDashboards?filter=${encodeURIComponent(filter)}`);
    return !!result.length;
  },

  async createRow(chart: Partial<APICustomDashboardRow>): Promise<APICustomDashboardRow> {
    return request(`/CustomDashboardRows`, 'POST', chart);
  },

  async updateRow(id: number, change: Partial<APICustomDashboardRow>): Promise<APICustomDashboardRow> {
    return request(`/CustomDashboardRows/${id}`, 'PATCH', change);
  },

  async deleteRow(id: number): Promise<unknown> {
    return request(`/CustomDashboardRows/${id}`, 'DELETE');
  },

  async createChart(chart: Partial<APICustomDashboardChart>): Promise<APICustomDashboardChart> {
    return request(`/CustomDashboardCharts`, 'POST', chart);
  },

  async updateChart(id: number, change: Partial<APICustomDashboardChart>): Promise<APICustomDashboardChart> {
    return request(`/CustomDashboardCharts/${id}`, 'PATCH', change);
  },

  async deleteChart(id: number): Promise<unknown> {
    return request(`/CustomDashboardCharts/${id}`, 'DELETE');
  },
}
