import * as React from 'react';
import { Column } from 'react-data-grid';
import { AdminHeader } from '../../AdminHeader';
import { Link } from 'react-router-dom';
import { AdminGrid, AdminGridSearchParams, AdminGridTagsList, AdminGridTagType } from '../../AdminGrid';
import { APISite, SiteAPI } from '../../../../api/sites';
import { AdminLinks } from '../../Admin';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { Tooltip } from 'pivotal-ui/react/tooltip';

interface Props {

}

export const SitesTable: React.FC<Props> = () => {
  const fetchSites = (params?: AdminGridSearchParams) => {
    return SiteAPI.fetchAll(params);
  };

  const columns: Column<APISite>[] = [
    {
      key: 'name', name: 'Site name', width: 300,
    },
    {
      key: 'mailboxes', name: 'Mailboxes', formatter: (tableProps) => {
        const addresses = tableProps.row.mailboxes?.map((mailbox) => mailbox.emailAddr) || [];

        return (
          <OverlayTrigger placement='bottom' overlay={<Tooltip  size='md'>{addresses.join(', ')}</Tooltip>} delayShow={500}>
            <AdminGridTagsList type={AdminGridTagType.Default} items={addresses} />
          </OverlayTrigger>
        )
      }
    },
    {
      key: 'actions', name: 'Actions', width: 300, formatter: (tableProps) => {
        return <Link to={`${AdminLinks.Sites}/form/${tableProps.row.id}`}><button className="light-button active-on-hover">Edit</button></Link>;
      },
    },
  ];

  return (
    <div>
      <AdminHeader
        headline="Sites"
        tagText="Global"
        links={[{
          label: 'What are Sites?',
          url: '/knowledge-base/What-are-Sites--eb5b6a1dd1f14c83a6616a89cfa595ff'
        }]}
      />
      <AdminGrid
        searchPlaceholder="Search sites..."
        columns={columns}
        fetchData={fetchSites}
        link={{
          url: AdminLinks.Sites + '/form',
          label: 'Add a new Site'
        }}
      />
    </div>
  )
};
