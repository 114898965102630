import { APIClusterAccrual } from '../../../api/clusterAccrual';
import { Exception, ExceptionCode } from '../../../api/validationResults';
import { APIClusterTotal, Cluster, ClusterStatus } from '../../../api/jobRefCluster';
import { roundNumber } from '../../helpers';
import { UpdatedAccrual } from './AccrualsForm';
import { APIPermissionProfileToleranceThreshold, TOLERANCE_WITHOUT_THRESHOLD } from '../../../api/permissionProfiles';
import { APIDocCoordinate } from '../../../api/documentContainer';
import { APIJobRef } from '../../../api/supplierInvoiceJobRef';
import { APIEmailAccount, CNValidationType } from '../../../api/emailAccount';
import { AggregatedTMSRef } from './AggregatedJobReference';

export const isAccrualWithoutChanges = (accrual: APIClusterAccrual): boolean => {
  if (accrual.osCostAmount !== accrual.originalOsCostAmount) return false;
  if (accrual.localCostAmount !== accrual.originalLocalCostAmount) return false;
  if (accrual.vatAmount !== accrual.originalVatAmount) return false;
  if (accrual.osCurrencyCode !== accrual.originalOsCurrencyCode) return false;
  if (accrual.selected || accrual.isSplit) return false;

  return true;
}

const checkIsMultiCurrency = (accruals: APIClusterAccrual[], invoiceCurrency: string, mailboxCurrencyCode: string) => {
  if (!mailboxCurrencyCode || (mailboxCurrencyCode !== invoiceCurrency)) return false;

  for (const accrual of accruals) {
    if (accrual.selected && accrual.osCurrencyCode !== invoiceCurrency) return true;
  }

  return false;
}

const checkExceedsSplitAllowances = (
  accruals: UpdatedAccrual[],
): boolean => !!accruals.find((accrual: UpdatedAccrual) => checkExceedsSplitAllowancesSingleAccrual(accrual))

export const checkExceedsSplitAllowancesSingleAccrual = (accrual: UpdatedAccrual): boolean => {
  if (!accrual.isSplit) {
    return false;
  }

  if (Math.sign(accrual.osCostAmount) !== Math.sign(accrual.originalOsCostAmount)) {
    return true;
  }

  if (accrual.osCostAmount === accrual.originalOsCostAmount && accrual.isApportioned) {
    return false;
  }

  return (Math.abs(accrual.osCostAmount) - Math.abs(accrual.originalOsCostAmount) >= 0);
}

export const getAccrualsExceptionType = (exceptions: Exception[], clusterId: number, clusterAccruals: APIClusterAccrual[]): [boolean, boolean] => {
  let hasMultipleAccrualsException = false;
  let allowAccrualsUpdate = false;

  clusterAccruals.length && exceptions.forEach((exception) => {
    if ([
      ExceptionCode.SupplierInvoiceAccrualInvalidSplit,
      ExceptionCode.CargoWiseClusterTotalMismatch
    ].includes(exception.code) && exception.failedClusters?.includes(clusterId)) {
      allowAccrualsUpdate = true;
    }

    if (exception.code === ExceptionCode.CargoWiseTotalMismatch) {
      allowAccrualsUpdate = true
    }

    if (exception.code === ExceptionCode.TimeoutTryingToMatchAccruals) {
      if (exception.failedClusters?.length) {
        allowAccrualsUpdate = exception.failedClusters.includes(clusterId);
      } else {
        allowAccrualsUpdate = true;
      }
    }

    if (exception.code === ExceptionCode.CargoWiseClusterAmbiguousCosts && exception.failedClusters?.includes(clusterId)) {
      hasMultipleAccrualsException = true;
    }

    if (exception.code === ExceptionCode.CargoWiseAmbiguousCosts) {
      hasMultipleAccrualsException = true
    }
  });

  return [hasMultipleAccrualsException, allowAccrualsUpdate];
}

interface GroupedAccrualsByShipment {
  [shipmentRef: string]: APIClusterAccrual[];
}

const checkExceedsToleranceThreshold = (
  accruals: APIClusterAccrual[],
  toleranceThreshold: APIPermissionProfileToleranceThreshold,
  invoiceCurrency: string,
  mailboxCurrencyCode: string
): boolean => {
  let result = false;
  const useLocal = Boolean(mailboxCurrencyCode && mailboxCurrencyCode === invoiceCurrency);

  const groupedAccrualsByShipment: GroupedAccrualsByShipment = accruals.reduce((result, accrual) => {
    if (!result[accrual.shipmentRef]) {
      result[accrual.shipmentRef] = [];
    }

    result[accrual.shipmentRef].push(accrual);

    return result;
  }, {} as GroupedAccrualsByShipment);

  Object.values(groupedAccrualsByShipment).forEach((shipmentAccruals) => {
    const isCostModified = Boolean((shipmentAccruals.filter((a) => a.osCostAmount !== a.originalOsCostAmount)).length !== 0);
    if (isCostModified) {
      let totalDifference = 0;
      let totalCostAmount = 0;
      shipmentAccruals.forEach((accrual) => {
        if (accrual?.selected) {
          let rate = accrual.osCostAmount / accrual.localCostAmount;
          if (accrual.exchangeRate) {
            rate = accrual.exchangeRate.isFlipped ? (1 / accrual.exchangeRate.rate) : accrual.exchangeRate.rate;
          }
          totalCostAmount += useLocal
            ? Math.abs(accrual.originalLocalCostAmount)
            : roundNumber((Math.abs(accrual.originalLocalCostAmount) * rate), 3);

          if (accrual?.localCostAmount !== accrual.originalLocalCostAmount) {
            const difference = (accrual?.localCostAmount || 0) - accrual.originalLocalCostAmount;

            if (!accrual.isSplit) {
              totalDifference += useLocal ? difference : roundNumber((difference * rate), 3);
            }
          }
        }
      }, 0);
      let absoluteMax = toleranceThreshold.upperAbsoluteMax;
      let percentageMax = toleranceThreshold.upperPercentageMax;
      if (totalDifference < 0) {
        absoluteMax = toleranceThreshold.lowerAbsoluteMax;
        percentageMax = toleranceThreshold.lowerPercentageMax;
      }
      if (absoluteMax !== TOLERANCE_WITHOUT_THRESHOLD && absoluteMax < Math.abs(totalDifference)) {
        result = true;
      }

      if (percentageMax !== TOLERANCE_WITHOUT_THRESHOLD && percentageMax < (100.0 * Math.abs(totalDifference) / totalCostAmount)) {
        result = true;
      }
    }
  });

  return result;
}

const checkExceedsExchangeRateThreshold = (
  accruals: APIClusterAccrual[],
  emailAccount: APIEmailAccount,
  invoiceCurrency: string
): boolean => {
  let result = false;
  /* Return false if this is not multicurrency invoice  */
  const useLocal = Boolean(emailAccount.localCurrency && emailAccount.localCurrency === invoiceCurrency);
  if (!useLocal) return result;

  const groupedAccrualsByShipment: GroupedAccrualsByShipment = accruals.reduce((result, accrual) => {
    if (!result[accrual.shipmentRef]) {
      result[accrual.shipmentRef] = [];
    }

    result[accrual.shipmentRef].push(accrual);

    return result;
  }, {} as GroupedAccrualsByShipment);

  Object.values(groupedAccrualsByShipment).forEach((shipmentAccruals) => {
    const onlyThroughExchangeRate = Boolean((accruals.filter((a) => a.osCostAmount !== a.originalOsCostAmount)).length === 0);
    if (onlyThroughExchangeRate) {
      let totalDifference = 0;
      let totalLocalCostAmount = 0;
      shipmentAccruals.forEach((accrual) => {
        /* Consider costs changed only by exchange rate */
        if (accrual) {
          let rate = accrual.osCostAmount / accrual.localCostAmount;
          if (accrual.exchangeRate) {
            rate = accrual.exchangeRate.isFlipped ? (1 / accrual.exchangeRate.rate) : accrual.exchangeRate.rate;
          }
          totalLocalCostAmount += Math.abs(accrual.originalLocalCostAmount);
          if (accrual.localCostAmount !== accrual.originalLocalCostAmount) {
            let localAmountBeforeRounding = accrual.localCostAmount;
            if (Math.abs(accrual.fxRoundingApplied)) {
              localAmountBeforeRounding = accrual.localCostAmount + accrual.fxRoundingApplied;
            }
            totalDifference += (localAmountBeforeRounding || 0) - accrual.originalLocalCostAmount;
          }
        }
      }, 0);
      let absoluteMax = emailAccount.xrUpperAbsoluteMax;
      let percentageMax = emailAccount.xrUpperPercentageMax;
      if (totalDifference < 0) {
        absoluteMax = emailAccount.xrLowerAbsoluteMax;
        percentageMax = emailAccount.xrLowerPercentageMax;
      }
      if (absoluteMax && (absoluteMax < Math.abs(totalDifference))) {
        result = true;
      }

      if (percentageMax && (percentageMax < (100.0 * Math.abs(totalDifference) / totalLocalCostAmount))) {
        result = true;
      }
    }
  });

  return result;
}

const calculateAccrualsNetTotal = (
  accruals: UpdatedAccrual[],
  invoiceCurrencyCode: string,
  clusterId: number,
): number => {
  return accruals.reduce((sum, accrual) => {
    if (!accrual?.selected || accrual.clusterId !== clusterId) {
      return sum;
    }
    const cost = invoiceCurrencyCode === accrual.osCurrencyCode ? accrual.osCostAmount : accrual.localCostAmount;

    return sum + (cost || 0);
  }, 0);
}

export const getClusterStatus = (params: {
  exceptions: Exception[],
  cluster: Cluster,
  toleranceThreshold: APIPermissionProfileToleranceThreshold | undefined,
  invoiceCurrencyCode: string;
  invoiceAccruals: APIClusterAccrual[];
  invoiceNetTotal: number;
  exchangeTolerance: number;
  emailAccount: APIEmailAccount | undefined;
}) => {
  const mailboxCurrencyCode = params.emailAccount?.localCurrency || 'UNKNOWN';

  let costDifference = 0;
  const clusterAccruals = params.invoiceAccruals.filter((accrual) => accrual.clusterId === params.cluster.id);
  const netTotal = params.cluster.isHeader ? params.invoiceNetTotal : params.cluster.total || 0;
  const accrualsNetTotal = calculateAccrualsNetTotal(params.invoiceAccruals, params.invoiceCurrencyCode, params.cluster.id);
  const [hasMultipleAccrualsException, allowAccrualsUpdate] = getAccrualsExceptionType(params.exceptions, params.cluster.id, clusterAccruals);
  const exceedsSplitAllowances = params.toleranceThreshold ? checkExceedsSplitAllowances(params.invoiceAccruals) : false;
  const exceedsToleranceThreshold = params.toleranceThreshold ? checkExceedsToleranceThreshold(params.invoiceAccruals, params.toleranceThreshold, params.invoiceCurrencyCode, mailboxCurrencyCode) : false;
  const exceedsExchangeRateThreshold = params.emailAccount ? checkExceedsExchangeRateThreshold(params.invoiceAccruals, params.emailAccount, params.invoiceCurrencyCode,) : false;
  const isMultiCurrency = checkIsMultiCurrency(params.invoiceAccruals, params.invoiceCurrencyCode, mailboxCurrencyCode);

  let clusterStatus: ClusterStatus | undefined;
  let usingExchangeRoundingTolerance = false;

  const selectedAccrualsInCluster = clusterAccruals.filter((accrual) => accrual.selected);

  if (hasMultipleAccrualsException || allowAccrualsUpdate) {
    if (selectedAccrualsInCluster.length) {
      const parsedAmount = parseFloat(netTotal.toString());
      const accrualAmount = accrualsNetTotal;
      const difference = roundNumber(accrualAmount - parsedAmount, 2);

      if (accrualAmount.toFixed(2) == parsedAmount.toFixed(2)) {
        clusterStatus = ClusterStatus.Matched;
      } else if (isMultiCurrency && Math.abs(difference) <= params.exchangeTolerance) {
        clusterStatus = ClusterStatus.Matched;
        usingExchangeRoundingTolerance = true;
        costDifference += difference;
      } else {
        clusterStatus = ClusterStatus.Failed;
      }

      if (exceedsToleranceThreshold || exceedsSplitAllowances || exceedsExchangeRateThreshold) {
        clusterStatus = ClusterStatus.Failed;
      }
    } else {
      clusterStatus = ClusterStatus.Failed;
    }
  } else {
    clusterStatus = params.cluster.status;
  }

  return { clusterStatus, usingExchangeRoundingTolerance, exceedsToleranceThreshold, exceedsExchangeRateThreshold, accrualsNetTotal, costDifference, netTotal };
}

export const filterApCoordinates = (coordinates: APIDocCoordinate[], clusters: APIClusterTotal[], lineItems: APIJobRef[], cnValidation: CNValidationType): APIDocCoordinate[] => {
  return coordinates.filter((coordinate) => {
    if (coordinate.attributeName === 'net_total') {
      return false;
    }

    if (coordinate.parentTableName === 'supplier_invoice_job_ref_cluster') {
      if (clusters.length === 1) {
        if (['total', 'vat_total', 'tax_code'].includes(coordinate.attributeName)) {
          return false;
        }
      } else {
        const cluster = clusters.find((cluster) => cluster.id === coordinate.parentTableId);

        if (cluster?.isHeader) {
          return false;
        }
      }
    } else if (coordinate.parentTableName === 'supplier_invoice_job_ref') {
      const jobRef = lineItems.find((lineItem) => lineItem.id === coordinate.parentTableId);
      if (!jobRef) return false;

      const cluster = clusters.find((cluster) => cluster.id === jobRef?.clusterId);

      if (coordinate.attributeName === 'container_num' && !cnValidation) {
        return false;
      }

      if (cluster?.isHeader && clusters.length > 1) {
        return false;
      }
    }

    return true;
  });
}

export const aggregateRefsByConsol = (aggregatedTMSRefs: AggregatedTMSRef[], accruals: APIClusterAccrual[]): AggregatedTMSRef[] => {
  const shipmentConsolMap = accruals.reduce((map, accrual) => ({ ...map, [accrual.shipmentRef]: accrual.consolRef }), {} as any);

  const aggregatedTMSRefsMap = new Map<string | null, AggregatedTMSRef>();
  aggregatedTMSRefs.forEach((aggregatedTMSRef) => {
    const parentConsolRef = aggregatedTMSRef.jobRef && shipmentConsolMap[aggregatedTMSRef.jobRef];
    const parentConsolRefParsed = parentConsolRef && aggregatedTMSRefs.find((aggregatedRef) => aggregatedRef.jobRef === parentConsolRef);

    const keyRef = parentConsolRefParsed ? parentConsolRef : aggregatedTMSRef.jobRef;
    const existingRecord = keyRef && aggregatedTMSRefsMap.get(keyRef);

    const aggregatedDocumentRefs = parentConsolRefParsed ? aggregatedTMSRef.aggregatedDocumentRefs.map((aggregatedDocumentRef) => {
      return { ...aggregatedDocumentRef, refs: aggregatedDocumentRef.refs.map((ref) => ({ ...ref, aggregatedByConsol: true })) };
    }) : aggregatedTMSRef.aggregatedDocumentRefs;

    aggregatedTMSRefsMap.set(keyRef, {
      jobRef: keyRef,
      aggregatedDocumentRefs: [
        ...aggregatedDocumentRefs,
        ...(existingRecord ? existingRecord.aggregatedDocumentRefs : []),
      ]
    });
  });

  return [...aggregatedTMSRefsMap.values()];
}
