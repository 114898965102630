import * as React from 'react';
import { AdminHeader } from '../../AdminHeader';
import { TextInput } from '../../../common/TextInput';
import { WrapperLabel } from '../../../common/WrapperLabel';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { APITeam, TeamAPI } from '../../../../api/teams';
import { AdminLinks } from '../../Admin';
import { isFormInvalid, RecordValidator } from '../../../common/Form';

interface UrlParams {
  id?: string;
}

interface Props {
  data?: APITeam;
}

export const TeamForm: React.FC<Props> = (props) => {
  const [record, setRecord] = React.useState<Partial<APITeam>>({
    companyId: parseInt(localStorage.getItem('companyId') || ''),
  });

  const [isNameTaken, setIsNameTaken] = React.useState<boolean>(false);
  const [formEntered, setFormEntered] = React.useState<boolean>(false);

  const recordValidator: RecordValidator = {
    name: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: (name) => !!name
        },
        {
          errorMessage: 'A Team already exists with this name',
          isValid: () => !isNameTaken
        }
      ]
    },
  }

  const submitDisabled = isFormInvalid(record, recordValidator);

  const params = useParams<UrlParams>();
  const recordId = params.id;
  const history = useHistory();

  React.useEffect(() => {
    if (recordId) {
      TeamAPI.fetch(parseInt(recordId)).then((data) => {
        setRecord(data);
      });
    }
  }, [params.id]);

  React.useEffect(() => {
    if (!record.name) {
      setIsNameTaken(false);
      return;
    }

    TeamAPI.isFieldValueTaken('name', record.name, record.id).then((result) => {
      setIsNameTaken(result);
    });
  }, [record.name]);

  const onUpdate = (change: Partial<APITeam>) => {
    setRecord((record) => ({ ...record, ...change }));
  }

  const onSave = async () => {
    setFormEntered(true);
    if (submitDisabled) return;

    if (record.id) {
      await TeamAPI.update(record.id, record);
    } else {
      await TeamAPI.create(record);
    }

    history.push(AdminLinks.Teams);
  }

  return (
    <div className="admin__form__wrapper with-custom-scrollbar">
      <AdminHeader
        headline={params.id ? 'Edit Team' : 'Add a new Team'}
        tagText="Global"
        links={[{
          label: 'What are Teams?',
          url: '/knowledge-base/What-are-Teams--ed0776779aad4658923c380a7767e62c',
        }]}
      />
      <div className="admin__form__content">
        <div>
          <WrapperLabel text="Team name*">
            <TextInput
              name="name"
              value={record.name || null}
              setter={(value) => onUpdate({ name: value })}
              formEntered={formEntered}
              validators={recordValidator.name.validators}
            />
          </WrapperLabel>
        </div>
        <div>
          <Link to={AdminLinks.Teams}><button className="light-button">Cancel</button></Link>
          <button className="full-button" onClick={onSave}>Save</button>
        </div>
      </div>
    </div>
  )
}