import * as React from 'react';

import './wrapper-label.scss';

interface Props {
  text: string | React.ReactNode;
  title?: string;
  dataTestId?: string;
  className?: string;
  copyComponent?: React.ReactNode;
}

export const WrapperLabel: React.FC<Props> = (props) => {
  return (
    <div className={`wrapper-label ${props.className || ''}`} title={props.title} data-test-id={props.dataTestId}>
      <label>{props.text}{props.copyComponent}</label>
      {props.children}
    </div>
  )
}
