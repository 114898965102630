import * as React from 'react';

import './collapsible-container.scss';
import { ShipamaxDownIcon, ShipamaxUpIcon } from '../../images/Icons';

const angleDownIcon = require('../../images/angle-down.svg');

interface Props {
  title: React.ReactElement | string;
  height?: number | string;
  maxHeight?: number | string;
  disableScroll?: boolean;
  overlay?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  secondaryId?: string;
  visibleContent?: React.ReactElement;
  isCollapsedByDefault?: boolean;
  versionTwo?: boolean;
  disableToggle?: boolean;
  forceHideInternalData?: boolean;
  preHeaderJsx?: React.ReactElement;
}

export const CollapsibleContainer: React.FC<Props> = (props) => {
  const [collapsed, setCollapsed] = React.useState(props.isCollapsedByDefault);

  React.useEffect(() => {
    if (!props.isCollapsedByDefault) setCollapsed(props.isCollapsedByDefault);
  }, [props.isCollapsedByDefault]);

  const toggleCollapsedState = () => {
    if (!!props.children) {
      setCollapsed(!collapsed);
    }
  }

  const cssClasses = [
    'collapsible-container',
    (props.versionTwo && !!props.children) ? 'collapsible-container--with-footer' : '',
    props.disableScroll && !collapsed ? 'no-scroll' : '',
    collapsed ? 'collapsed' : '',
    props.disabled ? 'disabled' : '',
    props.className
  ];

  return (
    <div className={cssClasses.join(' ')} id={props.id} secundary-id={props.secondaryId}>
      {props.preHeaderJsx}
      <div className={`collapsible-container__header ${collapsed ? 'collapsed' : ''}`} onClick={props.disableToggle ? () => { } : toggleCollapsedState}>
        <div className="collapsible-container__header--title">{props.title}</div>
        {!props.versionTwo && !props.disableToggle && (
          <img src={angleDownIcon} className={`collapsible-container__header--toggle`} />
        )}
      </div>
      {props.versionTwo && (
        <div style={{ maxHeight: props.maxHeight, height: props.height || 'unset' }} className={`collapsible-container__body ${props.disableScroll ? 'no-scroll' : ''} ${props.overlay ? 'overlay' : ''}`}>
          {props.visibleContent}
        </div>
      )}
      {(props.disableToggle && props.isCollapsedByDefault) ? null : (
        <div style={{ maxHeight: props.maxHeight, height: props.height || 'unset' }} className={`collapsible-container__body ${collapsed ? 'collapsed' : ''} ${props.disableScroll ? 'no-scroll' : ''} ${props.overlay ? 'overlay' : ''}`}>
          {props.children}
        </div>
      )}
      {(props.versionTwo && !!props.children) && !props.disableToggle && (
        <div className={`collapsible-container__footer active-on-hover`} onClick={toggleCollapsedState}>
          <div>{collapsed ? 'Show' : 'Hide'}&nbsp;{props.title}&nbsp;details {collapsed ? <ShipamaxDownIcon /> : <ShipamaxUpIcon />}</div>
        </div>
      )}
    </div>
  );
}
