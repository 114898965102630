import * as React from 'react';
import { Icon } from "pivotal-ui/react/iconography";

interface Props {
  onClick: () => void;
  showSpinner: boolean;
  className?: string;
  disabled?: boolean;
}

export const ButtonWithSpinner: React.FC<Props> = (props) => (
  <button
    className={`full-button ${props.className}`}
    disabled={props.showSpinner || props.disabled}
    onClick={props.onClick}
  >{props.showSpinner ? <Icon style={{ fontSize: '12px', verticalAlign: 'middle' }} src="spinner-md" /> : props.children}</button>
)