import * as React from 'react';
import { DocumentViewer, PagesRenderingProgress } from './DocumentViewer';
import { Notification } from '../Notification';
import {
  APIDocumentContainer,
  APISplitDetails,
  blTypes,
  DocumentContainerAPI,
  DocumentType
} from '../../../api/documentContainer';
import { OptionValue } from 'react-selectize';
import { ConfirmModal } from '../ConfirmModal';
import { useKeyPress } from '../../helpers';
import { move } from '../../dnd-helpers';
import { FileSplitterColumnWrapper } from './FileSplitterColumnWrapper';
import { DocumentGroupAPI, APIDocumentCorrectionsCount } from '../../../api/documentGroup';
import { MoveMenu } from './MoveMenu';
import { TooltipTrigger } from 'pivotal-ui/react/tooltip';
import { ShipamaxMergeIcon, ShipamaxMinusCircleIcon, ShipamaxPagesIcon, ShipamaxPlusCircleIcon, ShipamaxPlusIcon, ShipamaxSelectIcon, ShipamaxSelectedIcon } from '../../../images/Icons';

import './file-splitter.scss';

export enum LevelOfChanges {
  NoChange,
  ChangeInUnSupportedTypes,
  RequireValidation,
  RequireReParsing,
  RequireReParsingOnCorrectedInstances,
}

const getLevelOfChanges = (changes: FileSplitterColumn[], isApSection: boolean | undefined,
  correctionsOnCurrentGroup: APIDocumentCorrectionsCount[], hasDeletedColumnWithCorrections: boolean): LevelOfChanges => {
  let levelOfChanges: LevelOfChanges = LevelOfChanges.NoChange;

  changes.forEach((change) => {
    if (change.documentType === null || change.documentType === undefined) {
      return;
    }

    const relevantTypes = isApSection ? [
      DocumentType.SupplierInvoice,
      DocumentType.OverheadInvoice
    ] : [
      DocumentType.HouseBillOfLading,
      DocumentType.MasterBillOfLading,
      DocumentType.CommercialInvoice,
      DocumentType.PackingList,
      DocumentType.BillOfLading
    ];

    const apTypeChange = change.document && ([DocumentType.SupplierInvoice, DocumentType.OverheadInvoice].includes(change.document.documentType));
    const changeToRelevantType = relevantTypes.includes(change.documentType);
    const documentTypeChanged = change.documentType !== change.document?.documentType;
    const revalidateChange = change.document && blTypes.includes(change.document.documentType);
    const pageChanges = changes.length === 1 ? Boolean(change.document?.filePages) : (change.document?.filePages?.toString() !== change.pages.toString());
    const aliasChanged = change.documentAlias !== change.document?.documentAlias;

    if (aliasChanged) {
      levelOfChanges = LevelOfChanges.RequireValidation;
    }

    if (levelOfChanges === LevelOfChanges.NoChange && (pageChanges || documentTypeChanged)) {
      levelOfChanges = LevelOfChanges.ChangeInUnSupportedTypes;
    }

    if (documentTypeChanged && revalidateChange && levelOfChanges === LevelOfChanges.ChangeInUnSupportedTypes) { // if we don't have already requirement for reparsing
      levelOfChanges = LevelOfChanges.RequireValidation;
    } else if (changeToRelevantType && levelOfChanges !== LevelOfChanges.RequireReParsingOnCorrectedInstances) {
      const docHasCorrections = correctionsOnCurrentGroup.some((c) => c.documentId === change.document?.id && c.corrections > 0);
      if (documentTypeChanged) {
        // for ap invoice section the change AP <-> Overheads doesn't require validation or reparsing
        if (!isApSection || (isApSection && !apTypeChange)) {
          levelOfChanges = docHasCorrections ? LevelOfChanges.RequireReParsingOnCorrectedInstances : LevelOfChanges.RequireReParsing;
        }
      } else if (pageChanges) {
        levelOfChanges = docHasCorrections ? LevelOfChanges.RequireReParsingOnCorrectedInstances : LevelOfChanges.RequireReParsing;
      }
    }
  });

  // @ts-ignore
  if (hasDeletedColumnWithCorrections && levelOfChanges === LevelOfChanges.RequireReParsing) {
    levelOfChanges = LevelOfChanges.RequireReParsingOnCorrectedInstances;
  }

  return levelOfChanges;
};

export const hasColumnTypeError = (column: FileSplitterColumn): boolean => column.documentType === undefined;
export const hasColumnPageError = (column: FileSplitterColumn): boolean => !column.pages.length;

export type FileSplitterColumn = {
  document?: APIDocumentContainer;
  documentType?: DocumentType;
  documentAlias?: string;
  pages: number[];
}

export interface FileSplitterSettings {
  hideFileSplitterButton: boolean;
  isApSection?: boolean;
}

enum FileSplitterPopup {
  Discard,
  Save,
  SaveDiscardingCorrections,
}

interface Props {
  document: APIDocumentContainer;
  onClose: () => void;
  pdfDocument: any;
  documentUrl?: string;
  setNotification: (notification: Notification | null) => void;
  filename: string | undefined;
  showSpinner: boolean;
  documentTypesOptions: OptionValue[];
  fileSplitterSettings: FileSplitterSettings;
  onFileSplitFinished: (levelOfChanges: LevelOfChanges, affectedDocument: APIDocumentContainer) => void;
  unqIdLoaded: string | undefined;
  defaultPageIndex?: number;
}

export const FileSplitter: React.FC<Props> = (props) => {
  const [columns, setColumns] = React.useState<FileSplitterColumn[]>([]);
  const [correctionsOnCurrentGroup, setCorrectionsOnCurrentGroup] = React.useState<APIDocumentCorrectionsCount[]>([]);
  const [hasDeletedColumnWithCorrections, setHasDeletedColumnWithCorrections] = React.useState<boolean>(false);
  const [activePageIndex, setActivePageIndex] = React.useState<number>(0);
  const [numPages, setNumPages] = React.useState<number>(0);
  const [displayedPopup, setDisplayedPopup] = React.useState<FileSplitterPopup | undefined>(undefined);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [showPreview, setShowPreview] = React.useState<boolean>(false);
  const [activeDocumentIndex, setActiveDocumentIndex] = React.useState<number>(0);
  const [allPanelsCollapsed, setAllPanelsCollapsed] = React.useState<string>("allOpen");
  const [selectedPages, setSelectedPages] = React.useState<{ pageIndex: number, columnIndex: number }[]>([]);
  const [selectedDocuments, setSelectedDocuments] = React.useState<number[]>([]);
  const [onlyUseColumn, setOnlyUseColumn] = React.useState<number | undefined>();
  const listScroll = React.useRef<HTMLDivElement>(null);

  const levelOfChanges = getLevelOfChanges(columns, props.fileSplitterSettings.isApSection, correctionsOnCurrentGroup, hasDeletedColumnWithCorrections);
  const placeholderColumnIndex = columns.length;

  useKeyPress('Escape', () => {
    const isDropdownOpen = document.activeElement?.closest('.react-selectize');
    const isConfirmModalOpen = document.getElementsByClassName('pui-dialog-show').length;

    if (showPreview) {
      setShowPreview(false);
      return;
    }

    if (!isDropdownOpen && !isConfirmModalOpen) {
      setTimeout(onCancel, 20);
    }
  }, [levelOfChanges, showPreview]);

  React.useEffect(() => {
    props.defaultPageIndex && setActivePageIndex(props.defaultPageIndex);
  }, [props.defaultPageIndex]);

  React.useEffect(() => {
    DocumentGroupAPI.getDocumentsCorrections(props.document.groupId).then((result) => {
      setCorrectionsOnCurrentGroup(result);
    });
  }, []);

  React.useEffect(() => {
    if (!numPages) return;

    if (props.document.parentId) {
      DocumentContainerAPI.search({
        where: {
          parentId: props.document.parentId,
          companyId: parseInt(localStorage.getItem('companyId') || ''),
          ...(props.fileSplitterSettings.isApSection ? {} : { groupId: props.document.groupId })
        },
        include: [
          'documentGroup'
        ]
      }).then((documents) => {
        setColumns(documents.map((document: APIDocumentContainer) => {
          return {
            document: {
              ...document
            },
            pages: document.filePages || [],
            documentType: document.documentType,
            documentAlias: document.documentAlias
          }
        }));
      });
    } else {
      /*
       this is needed for the situation where we changed the document type of an active document.
       The activeDocument state in File sorter isn't updated
       */
      DocumentContainerAPI.search({
        where: {
          id: props.document.id,
          companyId: parseInt(localStorage.getItem('companyId') || ''),
        },
        include: [
          'documentGroup'
        ]
      }).then((documents) => {
        const document = documents[0];

        setColumns([{
          document: document,
          pages: Array.from(Array(numPages).keys()),
          documentType: document.documentType === DocumentType.MultiDoc ? undefined : document.documentType
        }]);
      });
    }

  }, [numPages]);

  const addColumn = () => {
    setColumns([
      ...columns,
      { pages: [] }
    ]);
  }

  const removeColumn = (index: number) => {
    setColumns((columns) => columns.filter((c, i) => i !== index));
  }

  const updateDocumentType = (value: DocumentType, columnIndex: number) => {
    setColumns((columns) => columns.map((column, index): FileSplitterColumn => {
      return index === columnIndex ? { ...column, documentType: Number(value) } : column;
    }));
  }

  const onSaveClick = () => {
    if (levelOfChanges === LevelOfChanges.RequireReParsing) {
      setDisplayedPopup(FileSplitterPopup.Save);
    } else if (levelOfChanges === LevelOfChanges.RequireReParsingOnCorrectedInstances) {
      setDisplayedPopup(FileSplitterPopup.SaveDiscardingCorrections);
    } else {
      onSave();
    }
  }

  const onSave = async () => {
    if (isSaving) return;

    setIsSaving(true);
    const data: APISplitDetails[] = columns.filter((column) => {
      return column.pages.length;
    }).map((column): APISplitDetails => ({
      id: column.document?.parentId ? column.document.id : undefined,
      type: column.documentType!,
      alias: column.document && column.document.documentAlias,
      pages: column.pages,
      unqId: props.unqIdLoaded,
    }));

    const promise = () => DocumentContainerAPI.splitAndUpdateDocument(props.document.parentId || props.document.id, data);

    if (props.fileSplitterSettings.isApSection ||
      ![LevelOfChanges.RequireReParsing, LevelOfChanges.RequireReParsingOnCorrectedInstances].includes(levelOfChanges)) {
      await promise();
    } else {
      promise();
    }

    setIsSaving(false);
    props.onClose();
    props.onFileSplitFinished(levelOfChanges, props.document);
  }

  const onCancel = () => {
    if (levelOfChanges === LevelOfChanges.NoChange) {
      props.onClose();
    } else {
      setDisplayedPopup(FileSplitterPopup.Discard);
    }
  }

  const handleShowPreview = (show: boolean) => {
    setShowPreview(show);
  }

  const clearSelectedPages = () => {
    setSelectedPages([]);
  }

  const handleSelectedPages = (pageIndex: number, columnIndex: number, clear: boolean = false) => {
    setOnlyUseColumn(columnIndex)
    const matchingPage = selectedPages.filter((data) => {
      return data.pageIndex === pageIndex && data.columnIndex === columnIndex;
    })

    if (matchingPage.length === 0) {
      selectedPages.push({ pageIndex: pageIndex, columnIndex: columnIndex });
      setSelectedPages([...selectedPages]);
    } else {
      const newSelectedPages = selectedPages.filter((data) => {
        // return the pages that are not the same pageindex and those that are but are in a different column
        if (data.pageIndex !== pageIndex || (data.pageIndex == pageIndex && data.columnIndex !== columnIndex)) {
          return data;
        }
      })
      setSelectedPages([...newSelectedPages])
    }
  }

  React.useEffect(() => {
    !selectedPages.length && setOnlyUseColumn(undefined)
  }, [selectedPages])

  React.useEffect(() => {
    getLevelOfChanges(columns, props.fileSplitterSettings.isApSection, correctionsOnCurrentGroup, hasDeletedColumnWithCorrections);
  }, [columns])

  const handleSelectedDocs = (docIndex: number) => {
    const currentDocIndex = selectedDocuments.indexOf(docIndex);

    if (currentDocIndex === -1) {
      selectedDocuments.push(docIndex);
    } else {
      selectedDocuments.splice(currentDocIndex, 1);
    }

    setSelectedDocuments([...selectedDocuments]);
  }

  const mergeDocuments = () => {
    const mergedPages: number[] = [];
    let firstDocIndex: number | undefined;

    selectedDocuments.map((docIndex) => {
      const documentPages = columns[docIndex].pages;

      if (!firstDocIndex && firstDocIndex !== 0) {
        firstDocIndex = docIndex
      }

      documentPages.map((page) => {
        mergedPages.push(page);
      })
    })

    selectedDocuments.slice(0).reverse().map((docIndex) => {
      columns.splice(docIndex, 1)
    })

    columns.splice(firstDocIndex || 0, 0, { pages: mergedPages })

    setColumns([...columns])
    setSelectedDocuments([]);
    setActiveDocumentIndex(firstDocIndex || 0)
    document.querySelectorAll(".file-splitter__column")[firstDocIndex || 0].scrollIntoView();
  }

  const handleOnListDrop = (e: React.DragEvent<HTMLDivElement>, docIndex: number) => {
    const target = e.target as HTMLDivElement;

    const selectedPageIndex = parseInt(e.dataTransfer.getData("selectedPageIndex"));
    const sourceDocumentIndex = e.dataTransfer.getData("sourceDocumentIndex");

    const result = move<number>(
      columns[parseInt(sourceDocumentIndex)].pages,
      columns[docIndex].pages,
      { droppableId: sourceDocumentIndex, index: selectedPageIndex },
      { droppableId: docIndex.toString(), index: columns[docIndex].pages.length }
    );

    const newColumns: FileSplitterColumn[] = [];

    columns.forEach((column, index) => {
      if (result[index]) {
        if (result[index].length) {
          newColumns.push({ ...column, pages: result[index] });
        } else {
          //column will be removed, so checking if there was any correction
          if (correctionsOnCurrentGroup.some((c) => c.documentId === column.document?.id && c.corrections > 0)) {
            setHasDeletedColumnWithCorrections(true);
          }
        }
      } else {
        newColumns.push(column);
      }
    });

    setColumns(newColumns);

    target.classList.remove("drag-enter");
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target as HTMLDivElement;
    target.classList.add('drag-enter');
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const target = e.target as HTMLDivElement;
    target.classList.remove('drag-enter');
  }

  const enableScrollOnDrag = (event: { clientY: number; }) => {
    const scrollThreshold = 150;
    const scrollSpeed = 5;
    const scrollEl = listScroll.current;
    const scrollElHeight = scrollEl?.clientHeight || 0;
    
    if (scrollEl) {
      if (event.clientY < scrollThreshold) {
        scrollEl.scrollBy(0, -scrollSpeed);
      } else if (event.clientY > scrollElHeight - scrollThreshold) {
        scrollEl.scrollBy(0, scrollSpeed);
      }
    }
  }

  const isSaveButtonDisabled = (levelOfChanges === LevelOfChanges.NoChange) || columns.some((column: FileSplitterColumn) => hasColumnTypeError(column) || hasColumnPageError(column));

  return (
    <DocumentViewer
      documentUrl={props.documentUrl}
      pdfDocument={props.pdfDocument}
      setNotification={props.setNotification}
      filename={props.filename}
      hideControls={true}
      hideOverlay={true}
      parentDocumentId={null}
      setNumPages={setNumPages}
      showSpinner={props.showSpinner}
      isDownloadDisabled={true}
      documentId={props.document.id}
      specificId={props.document.commercialInvoice?.id || props.document.packingList?.id || props.document.billOfLading?.id || props.document.supplierInvoice?.id || 0}
    >
      {({ pages, hasNotRenderedAllPages, maxNumPagesRendered, allPagesCount }) => (
        <div className="file-splitter">
          <div className="file-splitter__main-wrapper">
            <div className="document-overview with-custom-scrollbar">
              {columns.map((column: FileSplitterColumn, docIndex) => {
                const documentType = props.documentTypesOptions.filter((item) => {
                  return item.value === column.documentType;
                });

                return (
                  selectedDocuments &&
                  <div
                    key={docIndex}
                    className={`document-overview-item ${activeDocumentIndex === docIndex ? "active" : ""} ${selectedDocuments.indexOf(docIndex) !== -1 ? "selected" : ""}`}
                    draggable={false}
                    onDrop={(e) => { handleOnListDrop(e, docIndex) }}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                  >
                    <TooltipTrigger placement="right" tooltip="Select Documents To Merge">
                        <span
                          className="select-document"
                          onClick={() => handleSelectedDocs(docIndex)}
                        >
                          {selectedDocuments.indexOf(docIndex) !== -1 ? (<ShipamaxSelectedIcon />) : (<ShipamaxSelectIcon />)}
                        </span>
                    </TooltipTrigger>
                    <TooltipTrigger placement="bottom" tooltip="Scroll To Document">
                      <a
                        draggable={false}
                        onClick={() => { setActiveDocumentIndex(docIndex); }}
                      >
                        <div className="document-overview-item-details">
                          <span>{documentType[0] ? documentType[0].label : "New Document"}</span>
                          <span>{column.document && column.document.documentAlias ? column.document.documentAlias : `Document ${docIndex + 1}`}</span>
                        </div>
                        <div className="page-count">
                            <ShipamaxPagesIcon />
                          {column.pages.length}
                        </div>
                      </a>
                    </TooltipTrigger>
                  </div>
                );
              })}
            </div>
            <div 
              ref={listScroll} 
              className="with-custom-scrollbar" 
              style={{ flex: 1, height: '100%', overflow: 'auto', }}
            >
              <div className="file-splitter__columns-wrapper">
                {columns.map((column: FileSplitterColumn, columnIndex) => (
                  <FileSplitterColumnWrapper
                    key={columnIndex}
                    pages={pages}
                    column={column}
                    index={columnIndex}
                    activePageIndex={activePageIndex}
                    setActivePageIndex={setActivePageIndex}
                    removeColumn={removeColumn}
                    documentTypesOptions={props.documentTypesOptions}
                    updateDocumentType={updateDocumentType}
                    handleShowPreview={handleShowPreview}
                    allPanelsCollapsed={allPanelsCollapsed}
                    setAllPanelsCollapsed={setAllPanelsCollapsed}
                    columns={columns}
                    setColumns={setColumns}
                    handleSelectedPages={handleSelectedPages}
                    selectedPages={selectedPages}
                    disabled={onlyUseColumn !== columnIndex && onlyUseColumn !== undefined}
                    correctionsOnCurrentGroup={correctionsOnCurrentGroup}
                    setHasDeletedColumnWithCorrections={setHasDeletedColumnWithCorrections}
                    clearSelectedPages={clearSelectedPages}
                    showPreview={showPreview}
                    activeDocumentIndex={activeDocumentIndex || 0}
                    enableScrollOnDrag={enableScrollOnDrag}
                  />
                ))}
                <FileSplitterColumnWrapper
                  key={placeholderColumnIndex}
                  pages={pages}
                  index={placeholderColumnIndex}
                  activePageIndex={activePageIndex}
                  setActivePageIndex={setActivePageIndex}
                  removeColumn={removeColumn}
                  documentTypesOptions={props.documentTypesOptions}
                  updateDocumentType={updateDocumentType}
                  columns={columns}
                  setColumns={setColumns}
                >
                  <span className="file-splitter__column--placeholder__label" onClick={addColumn}><ShipamaxPlusIcon />Add a document</span>
                </FileSplitterColumnWrapper>
              </div>
            </div>
            <div className={`file-splitter__document-viewer-wrapper ${showPreview ? "show-preview" : ""}`}>
              <DocumentViewer
                documentUrl={props.documentUrl}
                pdfDocument={props.pdfDocument}
                filePages={[activePageIndex]}
                setNotification={props.setNotification}
                filename={props.filename}
                hideFileSplitterButton={true}
                hideOverlay={true}
                parentDocumentId={null}
                showSpinner={props.showSpinner}
                isDownloadDisabled={true}
                documentId={props.document.id}
                specificId={props.document.commercialInvoice?.id || props.document.packingList?.id || props.document.billOfLading?.id || props.document.supplierInvoice?.id || 0}
                showClose={true}
                handleShowPreview={handleShowPreview} />
            </div>
          </div>
          <div className="file-splitter__actions">
            <div className="document-actions">
              <div className="document-list-actions">
                <TooltipTrigger tooltip={selectedDocuments.length < 2 ? "Select Multiple Documents" : "Merge Selected Documents"}>
                <button
                  disabled={selectedDocuments.length < 2}
                  className='light-button'
                  onClick={() => mergeDocuments()}
                  >
                    <ShipamaxMergeIcon />
                    Merge Documents
                  </button>
                </TooltipTrigger>
              </div>
              <button
                className='light-button'
                onClick={() => setAllPanelsCollapsed("allClosed")}
              >
                <TooltipTrigger tooltip="Collapse All Documents">
                  <ShipamaxMinusCircleIcon />
                  Collapse All
                </TooltipTrigger>
              </button>
              <button
                className='light-button'
                onClick={() => setAllPanelsCollapsed("allOpen")}
              >
                <TooltipTrigger tooltip="Expand All Documents">
                  <ShipamaxPlusCircleIcon />
                  Expand All
                </TooltipTrigger>
              </button>
              <MoveMenu
                columns={columns}
                documentTypesOptions={props.documentTypesOptions}
                activePageIndex={activePageIndex}
                moveMultiple={true}
                selectedPages={selectedPages}
                setColumns={setColumns}
                clearSelectedPages={clearSelectedPages}
              />
            </div>
            <div>
              {hasNotRenderedAllPages && (
                <PagesRenderingProgress maxNumPagesRendered={maxNumPagesRendered} allPagesCount={allPagesCount} />
              )}
            </div>
            <div>
              <button className="light-button" onClick={onCancel}>Cancel</button>
              <button
                className="full-button"
                onClick={onSaveClick}
                disabled={isSaveButtonDisabled}
              >Save</button>
            </div>
          </div>
          <ConfirmModal
            show={displayedPopup === FileSplitterPopup.Save}
            title="Documents require reprocessing"
            text="To complete your changes, some of the documents you've edited will be reprocessed.<br/><br/><b>Do you want to reprocess the documents?</b> This may take a few minutes and the pack will reappear once this is complete."
            onConfirm={onSave}
            onHide={() => setDisplayedPopup(undefined)}
            confirmText="Reprocess"
            cancelText="Cancel & Continue Editing"
            keepOpenOnConfirm />
          <ConfirmModal
            show={displayedPopup === FileSplitterPopup.SaveDiscardingCorrections}
            title="Previous changes may be lost"
            text="To complete your changes, some of the documents you've edited will be reprocessed. Any changes you previously made to the data from those documents will be lost.<br/><br/><b>Do you want to reprocess the documents?</b> This may take a few minutes and the pack will reappear once this is complete."
            onConfirm={onSave}
            onHide={() => setDisplayedPopup(undefined)}
            confirmText="Reprocess & Discard Previous Changes"
            cancelText="Cancel & Continue Editing"
            keepOpenOnConfirm />
          <ConfirmModal
            show={displayedPopup === FileSplitterPopup.Discard}
            title="Discard changes"
            text="Any changes you have made in this view will be discarded.<br/><br/><b>Are you sure you want to exit?</b>"
            onConfirm={props.onClose}
            onHide={() => setDisplayedPopup(undefined)}
            confirmText="Discard Changes & Exit"
            cancelText="Continue Editing"
            keepOpenOnConfirm />
        </div>
      )
      }
    </DocumentViewer >
  )
}
