import { request } from './request';

export enum AlertType {
  User = 10,
  Manager = 20,
}

export interface APIEmailAlertMonitoring {
  id: number;
  alertTime?: string;
  alertType: AlertType;
  companyId: number;
  teamName: string;
  teamId: number;
  userLimit?: number;
  teamLimit?: number;
  invoicesOverTeamLimit?: number;
  usersExceedingLimit?: Record<string, any>;
  unqId: string;
  numberOfCTAs: number;
}


export const EmailAlertMonitoringAPI = {

  async updateCTAs(id: number, emailAlertMonitoring: Partial<APIEmailAlertMonitoring>): Promise<APIEmailAlertMonitoring> {
    return request(`/EmailAlertMonitorings/${id}`, 'PATCH', emailAlertMonitoring);
  },

  fetch(unqId: string): Promise<APIEmailAlertMonitoring[] | undefined> {
    const filter = JSON.stringify({ where: { companyId: localStorage.getItem('companyId'), unqId } });
    return request(`/EmailAlertMonitorings?filter=${filter}`);
  },


}
