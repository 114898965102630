import * as React from 'react';
import { APIEmailAccount, EmailAccountAPI, EnvironmentTab, ValidationType } from '../../../../../api/emailAccount';
import { Column } from 'react-data-grid';
import { AdminHeader } from '../../../AdminHeader';
import { Link } from 'react-router-dom';
import { AdminGrid, AdminGridHandle, AdminGridSearchParams, booleanFormatter } from '../../../AdminGrid';
import { AdminLinks } from '../../../Admin';
import { receiverOptions, tmsTypeOptions } from './MailboxForm';
import { OptionValue } from 'react-selectize';
import { TabSwitcher } from '../../../../common/TabSwitcher';

import './index.scss';

interface Props {
  teamOptions: OptionValue[];
  siteOptions: OptionValue[];
}

export const MailboxesTable: React.FC<Props> = (props) => {
  const [selectedTab, setSelectedTab] = React.useState<string>(EnvironmentTab.ProductionWorkspace);
  const gridRef = React.useRef<AdminGridHandle>(null);

  React.useEffect(() => {
    gridRef.current?.refetchData();
  }, [selectedTab]);

  const fetchEmailAccounts = (params: AdminGridSearchParams) => {
    return EmailAccountAPI.fetchAll({ ...params, validationType: [ValidationType.AP], environmentTab: selectedTab });
  };

  const columns: Column<APIEmailAccount>[] = React.useMemo(() => [
    {
      key: 'id', name: 'Workflow ID', minWidth: 30,
    },
    {
      key: 'emailAddr', name: 'Mailbox address', minWidth: 220,
    },
    {
      key: 'tmsType', name: 'Integration', width: 80, formatter: (tableProps) => (
        <>{tmsTypeOptions.find((option) => option.value === tableProps.row.tmsType)?.label || ''}</>
      )
    },
    {
      key: 'siteId', name: 'Site', formatter: (tableProps) => (
        <>{props.siteOptions.find((option) => option.value === tableProps.row.siteId)?.label || ''}</>
      )
    },
    {
      key: 'workflowId', name: 'First receiver', width: 90, formatter: (tableProps) => {
        if (!tableProps.row.workflowTemplateId) return <></>;
        return (<>{receiverOptions.find((option) => option.value === tableProps.row.workflowTemplateId)?.label || 'Team'}</>)
      }
    },
    {
      key: 'defaultTeamId', name: 'Team', formatter: (tableProps) => {
        if (!tableProps.row.defaultTeamId) return <></>
        return (<>{props.teamOptions.find((team) => team.value === tableProps.row.defaultTeamId)?.label || 'Unknown team'}</>)
      }
    },
    {
      key: 'cwCompanyCode', name: 'Company code', width: 95,
    },
    {
      key: 'localCurrency', name: 'Local currency', width: 100,
    },
    {
      key: 'branchCountryCode', name: 'Country code', width: 90,
    },
    {
      key: 'multiDoc', name: 'Multi Doc', width: 80, formatter: (tableProps) => booleanFormatter(tableProps.row.multiDoc)
    },
    {
      key: 'actions', name: 'Actions', width: 80, formatter: (tableProps) => (
        <Link to={`${AdminLinks.ApReconciliationWorkflows}/form/${tableProps.row.id}`}><button className="light-button active-on-hover">Edit</button></Link>
      ),
    },
  ], [props.siteOptions, props.teamOptions]);

  return (
    <div className="mailboxes-section">
      <AdminHeader
        headline="AP reconciliation Workflows"
        links={[{
          label: 'What are workflows?',
          url: '/knowledge-base/What-are-workflows---73a06cd51fb047c6a40d8772b4a8ec2d',
        }]}
      />
      <TabSwitcher
        selectedTab={selectedTab}
        onTabClicked={setSelectedTab}
        tabs={[EnvironmentTab.ProductionWorkspace, EnvironmentTab.TestWorkspace]}
      />
      <AdminGrid
        ref={gridRef}
        searchPlaceholder="Search mailboxes..."
        columns={columns}
        fetchData={fetchEmailAccounts}
        link={{
          url: AdminLinks.ApReconciliationWorkflows + '/form' + (selectedTab === EnvironmentTab.TestWorkspace ? '?env=test' : ''),
          label: 'Add a new Workflow'
        }}
      />
    </div>
  )
};
