import { request } from './request';
import { APISupplierInvoice, APIEmail } from './supplierInvoice';
import { APIValidationIssueCode } from './validationIssueCodes';
import { Status } from '../components/ap-invoice/ApInvoiceSection';
import { APICwPostingLogWithException } from './cargowisePostingLog';

export interface APIValidationResult {
  id: number;
  valid: boolean;
  status: Status;
  details?: ValidationDetails;
  created: string;
  documentGroupId: number;

  supplierInvoice: APISupplierInvoice;
  emails: APIEmail[];
  validationIssueCodes: APIValidationIssueCode[];
  userId: number;
}

export interface APIValidationResultWithLog {
  validationResult: APIValidationResult;
  logs: APICwPostingLogWithException[];
}

export interface ValidationDetails {
  exceptions: Exception[];
  matchedClusters: number[];
}

export interface FailedJobRef {
  ref: string;
}

export interface Exception {
  code: number;
  description: string;
  failedClusters?: number[];
  failedJobRefs?: FailedJobRef[];
  isWarning?: boolean;
  debug?: unknown;
  fileName?: string;
  cwUploadSizeLimit?: number;

  isInformation?: boolean;
}

export enum ExceptionCode {
  SupplierInvoiceNumberMissing = 1,
  SupplierInvoiceDateMissing = 2,
  SupplierInvoiceIssuerMissing = 3,
  SupplierInvoiceTotalMissing = 4,
  SupplierInvoiceCurrencyMissing = 5,
  SupplierInvoiceNoJobRefs = 6,
  CargoWiseInvalidAddressee = 7,
  CargoWiseDuplicateInvoiceNumber = 8,
  CargoWiseTotalMismatch = 9,
  CargoWiseCurrencyMismatch = 10,
  CargoWiseVATMismatch = 11,
  CargoWisePostFailed = 12,
  CargoWiseAmbiguousCosts = 13,
  CargoWiseMissingIssuerCode = 14,
  CargoWiseApportionedToConsol = 15,
  DemoSuccess = 16,
  SupplierInvoiceFutureDate = 17,
  CargoWiseShipmentNotFound = 18,
  CargoWiseRequestError = 19,
  ValidatorError = 20,
  CargoWiseInvoiceAlreadyExists = 21,
  BillOfLadingMissingMBL = 22,
  BillOfLadingMultipleMBLs = 23,
  BillOfLadingIncorrectConsigneeForConsolType = 24,
  BillOfLadingMissingHBLs = 25,
  ManualApprovalRequired = 26,
  UnableToMatchToJob = 27,
  MultiplePossibleJobs = 28,
  BillOfLadingMissingReferences = 29,
  BillOfLadingMissingSCAC = 30,
  CargoWiseUpdateJobRefNotFound = 31,
  BillOfLadingMissingConsigneeOnMBL = 32,
  CargoWiseFileTooBig = 33,
  CargoWiseJobsDontAddUpToTotal = 34,
  CargoWiseClusterAmbiguousCosts = 35,
  CargoWiseClusterTotalMismatch = 36,
  SupplierInvoiceJobRefInNoClusters = 37,
  BillOfLadingMissingConsignorAndConsignee = 38,
  BillOfLadingMissingOrigin = 39,
  BillOfLadingMissingDestination = 40,
  BillOfLadingMissingContainerMode = 41,
  BillOfLadingMissingReleaseType = 42,
  BillOfLadingMissingPackingMode = 43,
  CargoWiseNoAccrualsFound = 44,
  PrevalidatorError = 45,
  CargoWiseValidatorError = 46,
  CommercialInvoiceStandaloneOnly = 47,
  CommercialInvoiceNumberMissing = 48,
  CommercialInvoiceGrossTotalMissing = 49,
  CargoWiseJobRefLookupFailed = 50,
  CargoWiseNoAccrualsFoundForClusters = 51,
  CommercialInvoiceSupplierMissing = 52,
  CommercialInvoiceImporterMissing = 53,
  CommercialInvoiceProductCodeNotFound = 54,
  CommercialInvoiceProductCodeNotAssociated = 55,
  CommercialInvoiceMixedMultipleMBLs = 56,
  CommercialInvoiceMixedMultipleCIs = 57,
  CommercialInvoiceMixedNoHBLs = 58,
  ContainerNumberNoReferenceFound = 59,
  ContainerNumberMultipleReferencesFound = 60,
  TimeoutTryingToMatchAccruals = 61,
  CommercialInvoiceMixedMultipleHBLs = 62,
  CommercialInvoiceMultipleMBLNotSupported = 63,
  CargoWiseAccrualsHaveChanged = 64,
  SupplierInvoiceDuplicateChargeCode = 65,
  SupplierInvoiceAccrualChangeTooLarge = 66,
  JobReferenceExtractedFromEmailSubject = 67, // no longer being used
  JobReferenceUnableToSetFromEmail = 68,
  CargowiseEDocPostingError = 69,
  NoCInvoiceFound = 70,
  JobReferenceMultipleRefsFromEmail = 71,
  CargoWiseMissingJobReference = 72,
  JobReferenceInvalidForGroup = 73,
  CargoWiseCostFetcherError = 74,
  CargoWiseInvoicePosterError = 75,
  CostValidatorError = 76,
  InvoicePosterError = 77,
  DuplicateInvoiceNumber = 78,
  PostFailed = 79,
  CostFetcherError = 80,
  CargoWiseJobsVATDontAddUpToInvoiceVAT = 81,
  SupplierInvoiceGlCodeMissing = 82, // Not in use
  SupplierInvoiceDescriptionMissing = 83, // Not in use
  SupplierInvoiceNetTotalMissing = 84,
  SupplierInvoiceTaxCodeMissing = 85,
  SupplierInvoiceTaxAmountMissing = 86,
  SupplierInvoiceTaxTotalMissing = 87,
  CommercialInvoiceLineItemTotalNotMatching = 88,
  DeclarationCurrentlyLocked = 89,
  CargowiseJobVerificationFailed = 90,
  DuplicatedBillNumberInGroup = 91,
  DuplicatedCInvNumberInGroup = 92,
  SupplierInvoiceAccrualInvalidSplit = 93,
  MissingConsolPlaceholderRef = 94,
  MissingShipmentPlaceholderRef = 95,
  CargoWisePlaceholderShipmentNotFound = 96,
  CargoWisePlaceholderConsolNotFound = 97,
  CargoWiseShipmentLinkedToOtherConsol = 98,
  DuplicateSJobRefInGroup = 99,
  GroupIncludesCJobReference = 100,
  SupplierInvoiceExchangeRateChangeTooLarge = 101,
  MismatchInVolume = 102,
  MismatchInWeight = 103,
  CouldNotCompareVolumeUnits = 104,
  CouldNotCompareWeightUnits = 105,
  CargoWisePartialConsolCost = 106,
  CommercialInvoiceMultipleHBLNotSupported = 107,
  MultipleHBLsInZipFile = 108,
  SupplierInvoiceNumberExists = 109,
  SupplierInvoiceExchangeRatesMustBeApproved = 110,
  CargowiseJobRefSetViaBlNumber = 111,
  CargowiseBlNumberNotMatchingWithBlNumber = 112,
  CargoWiseShipmentWithouJobRefLinkedToOtherConsol = 113,
  SupplierInvoiceExtractedRateThresholdExceeds = 114,
  CommercialInvoiceImporterNameMissing = 115,
  CommercialInvoiceImporterAddressMissing = 116,
  CommercialInvoiceSupplierNameMissing = 117,
  CommercialInvoiceSupplierAddressMissing = 118,
  MasterBillOfLadingSendingAgentNameMissing = 119,
  MasterBillOfLadingSendingAgentAddressMissing = 120,
  MasterBillOfLadingReceivingAgentNameMissing = 121,
  MasterBillOfLadingReceivingAgentAddressMissing = 122,
  MasterBillOfLadingCarrierMissing = 123,
  HouseBillOfLadingShipperNameMissing = 124,
  HouseBillOfLadingShipperAddressMissing = 125,
  HouseBillOfLadingConsigneeNameMissing = 126,
  HouseBillOfLadingConsigneeAddressMissing = 127,
  BillOfLadingNumberMissing = 128,
  AccrualRollupMeasuresFailed = 129,
  CommercialInvoiceLineItemTotalNotMatchPriceAndQty = 130,
  PackingListInvoiceNumberMissing = 131,
  PackingListTotalsMissing = 132,
  PackingListTotalsMismatch = 133,
  GenericFieldException = 134,
  AccrualRollupCurrencyMismatch = 135,
  AccrualRollupDuplicatesFound = 136,
  AccrualRollupInTPA = 137,
}

export const ExceptionDescriptions = {
  [ExceptionCode.GenericFieldException]: `Pack cannot be posted. Correct the fields highlighted in red before posting`,
};

export const errorsWithAuditLogs: ExceptionCode[] = [
  ExceptionCode.CargoWisePostFailed,
  ExceptionCode.CargoWiseRequestError,
  ExceptionCode.CommercialInvoiceNumberMissing,
  ExceptionCode.CargowiseEDocPostingError,
  ExceptionCode.PostFailed,
  ExceptionCode.DeclarationCurrentlyLocked,
  ExceptionCode.CargowiseJobVerificationFailed,
  ExceptionCode.CargoWiseCostFetcherError,
  ExceptionCode.CargoWiseInvoicePosterError,
  ExceptionCode.InvoicePosterError,
  ExceptionCode.CostFetcherError
];

export const initialIssueCodeNumber = 1000;

export const ValidationAPI = {
  async fetchValidationResultRow(id: number): Promise<APIValidationResult> {
    return request(`/ValidationResults/${id}`);
  },

  async fetchValidationResultData(id: number, supplierInvoiceId: number): Promise<APIValidationResult> {
    return request(`/ValidationResults/${id}/details?supplierInvoiceId=${supplierInvoiceId}`);
  },

  async update(id: number, validationResult: Partial<APIValidationResult>): Promise<APIValidationResult> {
    return request(`/ValidationResults/${id}`, 'PATCH', validationResult);
  },

  async fetchValidationResultDataLogs(documentGroupId: number): Promise<APIValidationResultWithLog[]> {
    const filter = {
      where: {
        documentGroupId,
      }
    };
    return request(`/ValidationResults/getLogs?filter=${encodeURIComponent(JSON.stringify(filter))}`);
  },
}

const overheadInvoiceExceptions = [
  ExceptionCode.SupplierInvoiceNumberMissing,
  ExceptionCode.SupplierInvoiceDateMissing,
  ExceptionCode.SupplierInvoiceIssuerMissing,
  ExceptionCode.SupplierInvoiceTotalMissing,
  ExceptionCode.SupplierInvoiceCurrencyMissing,
  ExceptionCode.CargoWiseInvalidAddressee,
  ExceptionCode.CargoWiseDuplicateInvoiceNumber,
  ExceptionCode.CargoWiseCurrencyMismatch,
  ExceptionCode.CargoWiseVATMismatch,
  ExceptionCode.CargoWisePostFailed,
  ExceptionCode.CargoWiseMissingIssuerCode,
  ExceptionCode.SupplierInvoiceFutureDate,
  ExceptionCode.CargoWiseRequestError,
  ExceptionCode.ValidatorError,
  ExceptionCode.CargoWiseInvoiceAlreadyExists,
  ExceptionCode.ManualApprovalRequired,
  ExceptionCode.CargoWiseFileTooBig,
  ExceptionCode.PrevalidatorError,
  ExceptionCode.CargoWiseValidatorError,
  ExceptionCode.CargowiseEDocPostingError,
  ExceptionCode.CargoWiseJobsDontAddUpToTotal,
  ExceptionCode.CargowiseEDocPostingError,
  ExceptionCode.CargoWiseInvoicePosterError,
  ExceptionCode.InvoicePosterError,
  ExceptionCode.DuplicateInvoiceNumber,
  ExceptionCode.PostFailed,
  ExceptionCode.CargoWiseJobsVATDontAddUpToInvoiceVAT,
  ExceptionCode.SupplierInvoiceGlCodeMissing,
  ExceptionCode.SupplierInvoiceDescriptionMissing,
  ExceptionCode.SupplierInvoiceNetTotalMissing,
  ExceptionCode.SupplierInvoiceTaxCodeMissing,
  ExceptionCode.SupplierInvoiceTaxAmountMissing,
  ExceptionCode.SupplierInvoiceTaxTotalMissing,
  ExceptionCode.SupplierInvoiceNumberExists,
];

export const filterOverheadsExceptions = (exceptions: Exception[]): Exception[] => {
  return exceptions.filter((exception) => overheadInvoiceExceptions.includes(exception.code) || exception.code > initialIssueCodeNumber);
}

export const hasAnyOfExceptions = (searchedExceptionCodes: number[], exceptions: Exception[]): boolean => {
  let found = false;

  searchedExceptionCodes.forEach((exceptionCode) => {
    const matchedException = exceptions.find((exception) => exception.code === exceptionCode);

    if (matchedException) {
      found = true;
    }
  });

  return found;
}

export const doesClusterHaveAnyOfExceptions = (clusterId: number, searchedExceptionCodes: ExceptionCode[], exceptions: Exception[]): boolean => {
  let found = false;

  exceptions.forEach((exception) => {
    if (searchedExceptionCodes.includes(exception.code) && exception.failedClusters?.includes(clusterId)) {
      found = true;
    }
  });

  return found;
}
