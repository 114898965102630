import * as React from 'react';
import { DocumentType, APIDocumentContainer } from '../../../api/documentContainer';
import { ActionModalType } from '../../common/DocumentActionModal';
import { APIEmailBasic } from '../../../api/email';
import { Draggable, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { OptionValue } from 'react-selectize';
import { CustomSelect } from '../../common/CustomSelect';
import { DocumentTypesToUpdate } from './FileSorter';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { ShipamaxArrowIcon, ShipamaxCogsIcon, ShipamaxFileIcon, ShipamaxLockIcon } from '../../../images/Icons';

import './file.scss';

interface Props {
  id: number;
  fileData?: APIDocumentContainer;
  emailData?: APIEmailBasic;
  documentId: number;
  activeDocumentId: number | undefined;
  documentType: DocumentType;
  info1?: string;
  info2?: string;
  companyDocTypes: { [k: number]: string };
  actionType: ActionModalType;
  showDetails: boolean;
  isDragDisabled: boolean;
  draggableId: number;
  draggableIndex: number;
  isDragging: boolean;
  documentTypesOptions: OptionValue[];
  docTypesToUpdate?: DocumentTypesToUpdate[];
  displaySelect: boolean;
  displayEndActions: boolean;
  selectable: boolean;
  hasPostedConsol: boolean;
  noDrag?: boolean;
  setDocTypesToUpdate?: (value: DocumentTypesToUpdate[]) => void;
  setSelected: (id: number) => void;
  setIsDragging: (isDragged: boolean) => void;
  onSelect: (id: number, selected: boolean) => void;
}

const types: { [k: number]: string } = {
  [DocumentType.MasterBillOfLading]: 'mbl',
  [DocumentType.PlaceholderConsol]: 'mbl',
  [DocumentType.PlaceholderBJob]: 'mbl',
  [DocumentType.HouseBillOfLading]: 'hbl',
  [DocumentType.PlaceholderShipment]: 'hbl',
  [DocumentType.CommercialInvoice]: 'c-inv',
  [DocumentType.SupplierInvoice]: 'default',
  [DocumentType.OverheadInvoice]: 'default',
  [DocumentType.ArrivalNotice]: 'default',
  [DocumentType.DeliveryOrder]: 'default',
  [DocumentType.PackingList]: 'pkl',
  [DocumentType.PackingDeclaration]: 'default',
  [DocumentType.CertificateOfOrigin]: 'default',
  [DocumentType.VendorQuote]: 'default',
  [DocumentType.ISFDocument]: 'default',
  [DocumentType.ProofOfDelivery]: 'default',
  [DocumentType.HealthCertificate]: 'default',
  [DocumentType.ManufacturesDeclaration]: 'default',
  [DocumentType.ForwardersCargoReceipt]: 'default',
  [DocumentType.VendorPaidOriginChargeSummary]: 'default',
  [DocumentType.AgentsInvoice]: 'default',
  [DocumentType.InterimFootwearForm]: 'default',
  [DocumentType.FirstSaleInvoice]: 'default',
  [DocumentType.GeneralConformityCertificate]: 'default',
  [DocumentType.VendorInvoiceApproval]: 'default',
  [DocumentType.MiscellaneousCustomsDocument]: 'default',
  [DocumentType.WharfGateIn]: 'default',
  [DocumentType.AirWayBill]: 'AirWayBill',
  [DocumentType.UKN]: 'unknown',
  [DocumentType.EmailBody]: 'email',
}

export const File: React.FC<Props> = (props) => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  let typeText = props.documentType in types ? props.documentType : DocumentType.UKN;
  let typeStyle = types[typeText];
  const [selectedNewType, setSelectedNewType] = React.useState<OptionValue | null>();
  const [editable, setEditable] = React.useState<boolean>();
  const [selected, setSelected] = React.useState<boolean>(false);

  React.useEffect(() => {
    setEditable(props.actionType === ActionModalType.EditMode);
  }, [props.actionType]);

  React.useEffect(() => {
    setSelectedNewType(props.documentTypesOptions.find((opt) => opt.value === props.documentType));
  }, [editable, props.documentTypesOptions]);

  function getStyle(style: any, snapshot: DraggableStateSnapshot) {
    if (!snapshot.isDragging) return {};
    if (!snapshot.isDropAnimating) {
      return style;
    }

    return {
      ...style,
      transitionDuration: `0.001s`
    };
  }

  const showLock = () => {
    return (['mbl', 'hbl'].includes(typeStyle) && props.actionType === ActionModalType.DragMode && (props.activeDocumentId === props.documentId));
  }

  const hideFile = () => {
    return false;
  }

  const onFilterOptions = (items: OptionValue[], search: string): OptionValue[] => {
    return items.filter((option) => option.label.toUpperCase().includes(search.toUpperCase()));
  }

  const displaySelectConditions = () => props.displaySelect && !props.noDrag && props.selectable && !props.hasPostedConsol;
  const displayDocumentProcessing = props.fileData && (
    (props.fileData.documentType === DocumentType.CommercialInvoice && !props.fileData.commercialInvoice) ||
    (props.fileData.documentType === DocumentType.PackingList && !props.fileData.packingList) ||
    (props.fileData.documentType === DocumentType.HouseBillOfLading && !props.fileData.billOfLading));

  const getElement = () => {
    return (
      <>
        <div className={`file-locked ${showLock() ? '' : 'hidden'}`} title={`Documents of type ${typeStyle.toUpperCase()} cannot be moved`}>
          <ShipamaxLockIcon />
        </div>
        <div className={`${typeStyle}`}>
          <ShipamaxFileIcon />
          <span>{props.companyDocTypes[props.documentType] || 'MSC'}</span>
        </div>
        <div className={`detail-${typeStyle} ${editable ? 'hide' : ''}`}>
          {props.documentType === DocumentType.EmailBody && (
            <span >Subject: {props.fileData?.email.subject}</span>
          )}
          {props.documentType !== DocumentType.EmailBody && !!props.fileData && (props.fileData.filename || props.fileData.multidocFilename) && (
            <span >{props.fileData.filename || props.fileData.multidocFilename || ''}</span>
          )}
        </div>
        <div className={`${props.documentType === DocumentType.EmailBody || editable ? 'hide' : ''}`}>
          {props.showDetails &&
            !!props.fileData &&
            <span>{props.fileData.billOfLading?.billOfLadingNo || props.fileData.commercialInvoice?.invoiceNumber}</span>
          }
        </div>
        {editable && (
          <>
            <ShipamaxArrowIcon />
            <div className='new-type'>
              <CustomSelect
                options={props.documentTypesOptions}
                value={selectedNewType || undefined}
                renderNoResultsFound={(item: OptionValue, search: string) => <div className="dropdown-placeholder" />}
                filterOptions={onFilterOptions}
                onValueChange={(selected) => {
                  if (!selected) {
                    return;
                  }
                  if (props.docTypesToUpdate && props.setDocTypesToUpdate) {
                    setSelectedNewType(selected);
                    const found = props.docTypesToUpdate.find((doc) => doc.documentId === props.documentId);
                    if (found) {
                      if (found.oldType !== selected.value) {
                        props.setDocTypesToUpdate(props.docTypesToUpdate
                          .filter((doc) => doc.documentId !== props.documentId)
                          .concat({ ...found, newType: selected.value }));
                      } else {
                        props.setDocTypesToUpdate(props.docTypesToUpdate.filter((doc) => doc.documentId !== props.documentId));
                      }
                    } else {
                      props.setDocTypesToUpdate(props.docTypesToUpdate.concat({
                        id: props.id,
                        documentId: props.documentId,
                        oldType: props.documentType,
                        newType: selected.value
                      }));
                    }
                  }
                }}
                dropdownMenuSize="wide"
                renderValue={(item: OptionValue) => <span className={`opt-${types[item.value]}`}>{item.label.split('-')[0].trim()}</span>} />
            </div>
          </>
        )}

        {props.displayEndActions && (
          <div className="items-holder">
            <div className="items">
              {displaySelectConditions() &&
                <label
                  className={`file__selector ${props.selectable && !props.hasPostedConsol ? 'show' : ''}`}
                  onClick={(event: React.MouseEvent) => event.stopPropagation()}>
                  <input
                    type="checkbox"
                    checked={selected}
                    onChange={() => { setSelected(!selected); props.onSelect(props.documentId, !selected); }}
                    disabled={!props.selectable} />
                  <span className="checkmark" />
                </label>}
              {!props.selectable &&
                <OverlayTrigger
                  overlay={<Tooltip size='md'>Document is part of a multidoc and cannot be moved to a new pack</Tooltip>}
                  placement="left"
                  delayShow={500}>
                  <label className={`file__locked`}><ShipamaxLockIcon /></label>
                </OverlayTrigger>
              }
            </div>
          </div>
        )}
        {displayDocumentProcessing &&
          <OverlayTrigger overlay={<Tooltip>
            Document is being re-processed
          </Tooltip >} delayShow={500} placement="top">
            <div className="document-processing">
              <ShipamaxCogsIcon />
            </div>
          </OverlayTrigger >

        }
      </>
    )
  }

  const getClassName = (snapshot: DraggableStateSnapshot, copy?: boolean) => {
    let className = 'file';
    if (props.activeDocumentId === props.documentId && !snapshot.isDragging) className = className.concat(' ', 'selected');
    if (snapshot.isDragging && !copy) className = className.concat(' ', 'dragging visible');
    if (isHovering) className = className.concat(' ', 'hovering');
    if (!props.selectable && props.actionType === ActionModalType.SplitMode) className = className.concat(' ', 'opacity50');
    return className;
  }

  if (props.noDrag) return <div className='file' onClick={() => props.setSelected(props.documentId)}>{getElement()}</div>;
  else return (<Draggable
    key={props.draggableId}
    draggableId={props.draggableId.toString()}
    index={props.draggableIndex}
    isDragDisabled={props.isDragDisabled}
  >
    {(provided, snapshot) => (
      <>
        {!hideFile() && (<div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={getClassName(snapshot)}
          onClick={() => props.setSelected(props.documentId)}
          style={getStyle(provided.draggableProps.style, snapshot)}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {getElement()}
        </div >)}
        {snapshot.isDragging && (
          <div className={`${getClassName(snapshot, true)} file-copy`}>
            {getElement()}
          </div >)}
      </>
    )}
  </Draggable>
  );
}
