import * as React from 'react';
import { DropdownList } from '../../common/DropdownList';
import { TextInput } from '../../common/TextInput';
import { JobRef, JobRefType } from './Clusters';
import { getJobRefAttributeName, getJobRefValue } from '../../helpers';
import { DateSelectEditor } from '../../common/DateSelectEditor';
import { SupplierInvoiceJobRefAPI } from '../../../api/supplierInvoiceJobRef';
import { APIEmailAccount, CNValidationType, TMSType } from '../../../api/emailAccount';
import { CoordinatesFilter } from '../../../api/documentContainer';
import * as moment from 'moment';
import { FAIcon } from '../../common/FAIcon';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { AggregatedDocumentRef } from './AggregatedJobReference';
import { ActivityLogAPI, ActivityType } from '../../../api/activityLog';

import './job-reference.scss';
import { Cluster } from '../../../api/jobRefCluster';
import { ShipamaxTimesIcon } from '../../../images/Icons';

export const checkJobRefRemovable = (jobRef: JobRef): boolean => {
  return !jobRef.jobRef && !jobRef.bolNum && !jobRef.containerNum && !jobRef.purchaseOrder;
}

export const getJobRefValues = (jobRef: AggregatedDocumentRef): JobRef => {
  return {
    jobRef: jobRef.refs[0].jobRef,
    type: jobRef.refs[0].type,
    serviceStartDate: jobRef.refs[0].serviceStartDate,
    bolNum: jobRef.refs[0].bolNum,
    purchaseOrder: jobRef.refs[0].purchaseOrder,
    containerNum: jobRef.refs[0].containerNum,
    serviceEndDate: jobRef.refs[0].serviceEndDate,
    clusterId: jobRef.refs[0].clusterId,
    invoiceId: jobRef.refs[0].invoiceId,
    orderIndex: jobRef.refs[0].orderIndex,
    total: jobRef.refs[0].total
  }
}

interface JobReferenceProps {
  aggregatedJobRef: AggregatedDocumentRef;
  onChange: (change: Partial<JobRef>) => void;
  emailAccount?: APIEmailAccount;
  disabled: boolean;
  disabledUpdate: boolean;
  removeCoordinates: (coordinatesFilter: CoordinatesFilter) => void;
  addJobRef: () => void;
  removeJobRef: (jobRefIds: number[]) => void;
  documentGroupId: number;
  clusters?: Cluster[];
  isAggregated?: boolean;
}

export const JobReference: React.FC<JobReferenceProps> = (props) => {
  const jobRef = React.useMemo(() => getJobRefValues(props.aggregatedJobRef), [props.aggregatedJobRef]);
  const jobRefIds = props.aggregatedJobRef.refs.map((jobRef) => jobRef.id!);
  const value = getJobRefValue(jobRef);

  const onBlurJobRef = (value: string | null, withoutChange: boolean) => {
    const change: Partial<JobRef> = {};

    if (jobRef.type === JobRefType.BL) {
      change.bolNum = value?.toUpperCase();
      change.jobRef = null;
    } else if (jobRef.type === JobRefType.CN) {
      change.containerNum = value?.toUpperCase();
      change.jobRef = null;
    } else if (jobRef.type === JobRefType.PO) {
      change.purchaseOrder = value?.toUpperCase();
      change.jobRef = null;
    } else {
      change.jobRef = value?.toUpperCase();
    }

    if (!props.disabled && !props.disabledUpdate && checkJobRefRemovable({ ...jobRef, ...change })) {
      return props.removeJobRef(jobRefIds);
    }

    if (withoutChange) {
      return;
    }

    onChange(change);
  }

  const onChange = async (change: Partial<JobRef>) => {
    const existingJobRefs = jobRefIds.filter((jobRefId) => !!jobRefId);
    if (existingJobRefs.length) {
      props.onChange(change);
      Promise.all(existingJobRefs.map((id) => SupplierInvoiceJobRefAPI.update(id, change)));
    } else {
      const newJobRef = await SupplierInvoiceJobRefAPI.create({ ...jobRef, ...change });
      props.onChange(newJobRef);
    }
  }

  const onTypeRefChanged = React.useCallback((value: string | number | null) => {
    const type = value as JobRefType || JobRefType.Ref;
    const oldType = jobRef.type;

    if (oldType !== type) {
      const update: Partial<JobRef> = { type };

      let value: string | null;

      switch (oldType) {
        case JobRefType.Ref:
          value = jobRef.jobRef;
          update.jobRef = null;
          break
        case JobRefType.BL:
          value = jobRef.bolNum;
          update.bolNum = null;
          break;
        case JobRefType.CN:
          value = jobRef.containerNum;
          update.containerNum = null;
          break;
        case JobRefType.PO:
          value = jobRef.purchaseOrder;
          update.purchaseOrder = null;
          break;
      }

      switch (type) {
        case JobRefType.BL:
          update.bolNum = value;
          break
        case JobRefType.CN:
          update.containerNum = value;
          break;
        case JobRefType.PO:
          update.purchaseOrder = value;
          break;
        case JobRefType.Ref:
          update.jobRef = value;
          break;
      }

      onChange(update);

      ActivityLogAPI.createLog({
        userId: Number(localStorage.getItem('userId')),
        type: ActivityType.JobRefTypeChange,
        documentGroupId: props.documentGroupId,
        details: {
          oldType,
          newType: type,
          value,
        }
      });

      props.removeCoordinates({
        parentTableIds: jobRefIds,
        parentTableName: 'supplier_invoice_job_ref',
      });
    }
  }, [props.aggregatedJobRef, props.onChange]);

  const cnValidationEnabled = props.emailAccount && [CNValidationType.Standard, CNValidationType.WithServiceDate].includes(props.emailAccount.cnValidation);

  const jobRefTypeOptions = React.useMemo(() => {
    const options = [
      { value: JobRefType.Ref, label: '<span class="boldRefName">REF</span>Job Reference' },
      { value: JobRefType.BL, label: '<span class="boldRefName">BL</span>BL or AWB Number' },
    ];
    if (cnValidationEnabled) options.push({ value: JobRefType.CN, label: '<span class="boldRefName">CN</span>Container Number' });
    if (props.emailAccount?.tmsType !== TMSType.Cargowise) options.push({ value: JobRefType.PO, label: '<span class="boldRefName">PO</span>Purchase Order' });

    return options;
  }, [props.emailAccount?.cnValidation, props.emailAccount?.tmsType]);

  return (
    <div className="job-ref">
      <div className={props.isAggregated ? `job-ref__wrapper is-merged` : `job-ref__wrapper`}>
        <DropdownList
          items={jobRefTypeOptions}
          selectedValue={jobRef.type ? jobRef.type : JobRefType.Ref}
          onChange={onTypeRefChanged}
          buttonClassName="dropdown-button"
          preventDefault
          loadValuesAsHtml={true}
          displayValueAsSelected={true}
          disabled={props.disabled || props.disabledUpdate}
        />
        <TextInput
          value={value?.toUpperCase() || null}
          name="ref"
          onBlur={(name, value, withoutChange) => onBlurJobRef(value, withoutChange)}
          disabled={props.disabled || props.disabledUpdate}
          fieldData={{
            tableName: 'supplier_invoice_job_ref',
            objectIds: jobRefIds,
            attributeNames: [getJobRefAttributeName(jobRef)]
          }}
        />
        {props.emailAccount?.cnValidation === CNValidationType.WithServiceDate && jobRef.type === JobRefType.CN && (
          <div className="job-ref__container-service-date">
            <DateSelectEditor
              initDate={jobRef.serviceStartDate}
              onDateChange={(value, updateInvoice) => {
                if (!updateInvoice || !value) {
                  return;
                }

                const date = value.format('YYYY-MM-DD');

                const change: Partial<JobRef> = {
                  serviceStartDate: date || null,
                  jobRef: null,
                };

                if ((!jobRef.serviceEndDate) || value?.isAfter(moment(jobRef.serviceEndDate))) {
                  change.serviceEndDate = date;

                  props.removeCoordinates({
                    parentTableIds: jobRefIds,
                    parentTableName: 'supplier_invoice_job_ref',
                    attributeName: 'service_end_date'
                  });
                }

                onChange(change);
              }}
              disabled={props.disabled || props.disabledUpdate}
              fieldData={{
                objectIds: jobRefIds,
                tableName: 'supplier_invoice_job_ref',
                attributeNames: ['service_start_date']
              }}
              placeholderText="Service date"
            />
            <DateSelectEditor
              initDate={jobRef.serviceEndDate}
              onDateChange={(value, updateInvoice) => {
                if (!updateInvoice || !value) {
                  return;
                }

                const date = value?.format('YYYY-MM-DD');

                const change: Partial<JobRef> = {
                  serviceEndDate: date || null,
                  jobRef: null,
                };

                if (!jobRef.serviceStartDate || value.isBefore(jobRef.serviceStartDate)) {
                  change.serviceStartDate = date;

                  props.removeCoordinates({
                    parentTableIds: jobRefIds,
                    parentTableName: 'supplier_invoice_job_ref',
                    attributeName: 'service_start_date'
                  });
                }

                onChange(change);
              }}
              disabled={props.disabled || props.disabledUpdate}
              fieldData={{
                objectIds: jobRefIds,
                tableName: 'supplier_invoice_job_ref',
                attributeNames: ['service_end_date']
              }}
              placeholderText="Service date"
            />
          </div>
        )}
        <div className="job-ref__suffix">
          {props.isAggregated && (
            <input
              name={'rowTotal'}
              disabled={props.disabled}
              value={jobRef.total as number}
              className='text-input'
            />
          )}
        </div>
        {props.isAggregated ? (
          <span />
        ) : (
        <OverlayTrigger overlay={<Tooltip><b>Delete</b> reference</Tooltip>} placement="left" delayShow={500}>
          <button
            className="remove-button active-on-hover"
            onClick={() => props.removeJobRef(jobRefIds)}
            disabled={props.disabled}
          >
            <ShipamaxTimesIcon />
          </button>
        </OverlayTrigger>
        )} 
      </div>
    </div>
  )
}
