import * as React from 'react';
import { Avatar } from '../Avatar';
import * as moment from 'moment';
import { PostingBehaviour, StatusCode } from '../../bill-of-lading/common';
import {
  ActivityDetailsAccrualChange,
  ActivityDetailsAssignment,
  ActivityDetailsBulkAction,
  ActivityDetailsExchangeRateCorrection,
  ActivityDetailsFieldCorrection,
  ActivityDetailsFileTypeChange, ActivityDetailsJobRefTypeChange,
  ActivityDetailsPostBehaviourChange,
  ActivityDetailsSplitMergeGroups,
  ActivityDetailsStatusChange,
  ActivityDetailsUpdateDocumentStructure,
  ActivityDetailsUpdateHierarchy,
  ActivityRollupPrevention,
  ActivityType,
} from '../../../api/activityLog';
import { APIComment, TeamsMap, UsersMap } from '../../../api/comment';
import { getStatusText } from '../../ap-invoice/ApInvoiceSection';
import { APICwPostingLogWithException } from '../../../api/cargowisePostingLog';
import { PostingLogTile } from './PostingLogTile';
import { Notification } from '../Notification';
import { ActivityLog } from './ActivityFeed';
import { ShipamaxArrowIcon } from '../../../images/Icons';
import { ClearOperationType } from '../../../api/supplierInvoice';
import { APIClusterAccrual } from '../../../api/clusterAccrual';
import { JobRefType } from '../../ap-invoice/document-action/Clusters';
import { formatNumber } from '../NumberInput';
import { formatCostNumber } from '../../ap-invoice/document-action/AccrualsRow';

import './activity-log-wrapper.scss';
import { ExceptionCode } from '../../../api/validationResults';
import { retrieveFieldCorrectionLabel } from './helpers';
import { APIDocumentGroupDetails } from '../../../api/documentGroup';
import { DocumentType } from '../../../api/documentContainer';

const postingBehaviourMap = {
  [PostingBehaviour.NewBJob]: ' as a new Brokerage Job',
  [PostingBehaviour.UpdateBJob]: ' to an existing Brokerage Job',
  [PostingBehaviour.UpdateSJob]: ' to an existing Shipment Job',
  [PostingBehaviour.StandaloneCInv]: ' as standalone Commercial Invoice(s)',
};

const ActivityTypeLabelMap = {
  [ActivityType.AddedComment]: 'left a comment',
  [ActivityType.StatusChange]: 'changed status',
  [ActivityType.RequestedValidation]: '',
  [ActivityType.BulkRequestedValidation]: '',
  [ActivityType.PostedStraightThrough]: '',
  [ActivityType.Assignment]: '',
  [ActivityType.Reclassification]: 'reclassified the invoice',
  [ActivityType.TMSErrorLog]: 'response caused an unexpected error',
  [ActivityType.TMSRequestLog]: 'made a request to the TMS',
  [ActivityType.FileTypeChange]: '',
  [ActivityType.FieldCorrection]: '',
  [ActivityType.BulkAction]: '',
  [ActivityType.AccrualChange]: '',
  [ActivityType.ExchangeRateCorrection]: 'edited the Exchange Rate',
  [ActivityType.JobRefTypeChange]: 'edited a reference',
  [ActivityType.RollupPrevention]: '',
  [ActivityType.ChangePostingBehaviour]: 'changed the posting scenario',
  [ActivityType.SplitGroup]: '',
  [ActivityType.MergeGroup]: '',
  [ActivityType.ShipmentDeleted]: 'removed a Shipment',
  [ActivityType.ShipmentCreated]: 'added a new Shipment',
  [ActivityType.UpdatedHierarchy]: 'organised documents',
  [ActivityType.UpdatedDocumentStructure]: 'edited documents',
  [ActivityType.UpdatedDocumentType]: 'edited document'
}

const ReferenceTypesMap: { [key: string]: string } = {
  'jobRef': 'Job Reference',
  [JobRefType.Ref]: 'Job Reference',
  'bolNum': 'BL or AWB Number',
  [JobRefType.BL]: 'BL or AWB Number',
  'containerNum': 'Container Number',
  [JobRefType.CN]: 'Container Number',
  'purchaseOrder': 'Purchase Order',
  [JobRefType.PO]: 'Purchase Order',
}

const PostingBehaviourText = {
  [PostingBehaviour.Undefined]: '',
  [PostingBehaviour.NewBJob]: 'Create new Brokerage Job',
  [PostingBehaviour.UpdateBJob]: 'Update Existing Brokerage',
  [PostingBehaviour.UpdateSJob]: 'Update Existing Shipment',
  [PostingBehaviour.StandaloneCInv]: 'Create standalone Invoices',
  [PostingBehaviour.Forwarding]: 'Forwarding',
  [PostingBehaviour.ForwardingAndClearance]: 'Forwarding And Clearance',
  [PostingBehaviour.GenericTMS]: 'Import',
  [PostingBehaviour.WtgIntegration]: 'WTG Integration',
}

const getNonSplitAccrualText = (accrual: APIClusterAccrual, includeArrows: boolean) => {
  return (
    <div>
      {includeArrows ? <ShipamaxArrowIcon className='with-padding' /> : ''} {accrual.chargeCode}: {accrual.osCurrencyCode} {formatCostNumber(accrual.osCostAmount)}
    </div>
  )
}
const getSplitAccrualText = (accrual: APIClusterAccrual, includeArrows: boolean) => {
  return (
    <div>
      {includeArrows ? <ShipamaxArrowIcon className='with-padding' /> : ''} {accrual.chargeCode}: {accrual.osCurrencyCode} {formatCostNumber(accrual.osCostAmount)} posted <br />
      {includeArrows ? <ShipamaxArrowIcon className='with-padding' /> : ''} {accrual.chargeCode}: {accrual.osCurrencyCode} {formatCostNumber(accrual.originalOsCostAmount - accrual.osCostAmount)} remaining
    </div>
  )
}

const printValue = (value: string | null, fieldName: string) => {
  if (['grossTotal', 'vatTotal', 'total'].includes(fieldName)) {
    return value === null ? <i>None</i> : formatNumber(parseFloat(value), { precision: 2 });
  }
  if (parseFloat(value || "-1") === 0) {
    return value;
  }

  return value || <i>None</i>;
}



interface Props {
  postingBehaviour: PostingBehaviour | undefined;
  usersMap: UsersMap;
  teamsMap: TeamsMap;
  activityLog: ActivityLog;
  setNotification: (notification: Notification) => void;
  isForApInvoice?: boolean;
  companyDocTypes: { [k: number]: string };
  documentGroup?: APIDocumentGroupDetails | null;
  isNonCargowise?: boolean;
}

export const ActivityLogWrapper: React.FC<Props> = (props) => {
  const objectLabel = props.isForApInvoice ? 'invoice' : 'pack';
  let activityTypeLabel = ActivityTypeLabelMap[props.activityLog.type];
  let text;
  let warningText;

  const getTreeDocType = (docType: DocumentType | undefined, level: string): string => {
    if (!docType) return '';
    if ([DocumentType.PlaceholderBJob, DocumentType.PlaceholderConsol, DocumentType.PlaceholderShipment].includes(docType)) {
      return `(${level})`;
    } else if ([DocumentType.MasterBillOfLading, DocumentType.HouseBillOfLading].includes(docType)) {
      return `(${level} ${props.companyDocTypes[docType]})`;
    }
    return `(${props.companyDocTypes[docType]})`;
  }

  switch (props.activityLog.type) {
    case ActivityType.StatusChange: {
      const data = props.activityLog.data as ActivityDetailsStatusChange;

      if ([StatusCode.Posted, StatusCode.PostedWithIssues].includes(data.newStatus)) {
        activityTypeLabel = 'posted the ' + objectLabel;
        if (!props.isForApInvoice) {
          // @ts-ignore
          activityTypeLabel += postingBehaviourMap[props.postingBehaviour] || '';
        }
        if (data.newStatus === StatusCode.PostedWithIssues) {
          warningText = (
            <>
              <hr className='my-4' />
              <PostingLogTile
                message={undefined}
                hideMessageBar
                exception={{
                  code: ExceptionCode.AccrualRollupInTPA,
                  description: data.message!,
                }}
                setNotification={props.setNotification}
              />
            </>
          );
        }
      } else if (data.newStatus === StatusCode.Discarded) {
        activityTypeLabel = 'discarded the ' + objectLabel;
      } else if ([StatusCode.Unposted, StatusCode.Reopened].includes(data.newStatus)) {
        activityTypeLabel = 'reopened the ' + objectLabel;
      } else if (data.newStatus === StatusCode.Queried) {
        activityTypeLabel = 'put the ' + objectLabel + ' in query';
      } else if (data.newStatus === StatusCode.Done) {
        activityTypeLabel = 'set the ' + objectLabel + ' to done.'
      }

      text = <>{getStatusText(data.oldStatus)} <ShipamaxArrowIcon className="activity-log-wrapper__icon-separator" /> {getStatusText(data.newStatus)}{warningText}</>;
    }
      break;
    case ActivityType.Assignment: {
      const reassignmentLog = props.activityLog.data as ActivityDetailsAssignment;
      const oldUser = reassignmentLog.oldUserIds?.length ? props.usersMap.get(reassignmentLog.oldUserIds[0]) : undefined;
      const oldTeam = !oldUser ? props.teamsMap.get(reassignmentLog.oldTeamId || -1) : undefined;
      const newUser = reassignmentLog.newUserIds?.length ? props.usersMap.get(reassignmentLog.newUserIds[0]) : undefined;
      const newTeam = !newUser ? props.teamsMap.get(reassignmentLog.newTeamId || -1) : undefined;

      if (!oldTeam && !oldUser) {
        activityTypeLabel = 'assigned the ' + objectLabel;
      } else {
        activityTypeLabel = 'reassigned the ' + objectLabel;
      }

      const previousAssignment = oldUser ? oldUser.firstName + ' ' + oldUser.lastName : (oldTeam ? oldTeam.name : 'Unassigned');
      const newAssignment = newUser ? newUser.firstName + ' ' + newUser.lastName : (newTeam ? newTeam.name : 'Unassigned');
      text = <>{previousAssignment} <ShipamaxArrowIcon className="activity-log-wrapper__icon-separator" /> {newAssignment}</>;
    }
      break;

    case ActivityType.PostedStraightThrough: {
      activityTypeLabel = 'posted the ' + objectLabel + ' straight through';
      text = <>Open <ShipamaxArrowIcon className="activity-log-wrapper__icon-separator" /> Posted</>;
    }
      break;

    case ActivityType.FileTypeChange: {
      const editDocTypesDetails = props.activityLog.data as ActivityDetailsFileTypeChange;
      activityTypeLabel = 'changed document types';
      text = editDocTypesDetails.changes.map((change) => (
        <div className="flex items-center">
          <div style={{ display: 'inline-block', width: '200px', overflowX: 'hidden', marginRight: '5px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>File: {change.fileName}</div> {props.companyDocTypes[change.oldFileType] || 'MSC'} <ShipamaxArrowIcon className="activity-log-wrapper__icon-separator" /> {props.companyDocTypes[change.newFileType] || 'MSC'}
        </div>
      ));
    }
      break;

    case ActivityType.Reclassification: {
      text = <>Unrecognised <ShipamaxArrowIcon className="activity-log-wrapper__icon-separator" /> Open</>;
    }
      break;

    case ActivityType.AddedComment: {
      const comment = props.activityLog.data as APIComment;
      text = comment.comment;
    }
      break;

    case ActivityType.RollupPrevention: {
      const message = props.activityLog.data as ActivityRollupPrevention;
      text = message.message;
    }
      break;

    case ActivityType.TMSErrorLog:
    case ActivityType.TMSRequestLog:
      const data = props.activityLog.data as APICwPostingLogWithException;

      let message = props.activityLog.type === ActivityType.TMSRequestLog ? data.log.request! : data.log.response;
      if (data.log.response === null) {
        activityTypeLabel = 'couldn\'t find a webhook';
        text = (
          <PostingLogTile
            message={undefined}
            hideMessageBar
            exception={data.exception}
            setNotification={props.setNotification}
          />
        );
      } else {
        text = (
          <PostingLogTile
            message={message}
            exception={data.exception}
            setNotification={props.setNotification}
          />
        );
      }
      break;

    case ActivityType.JobRefTypeChange: {
      const jobRefTypeChange = props.activityLog.data as ActivityDetailsJobRefTypeChange;
      const oldReferenceType = ReferenceTypesMap[jobRefTypeChange.oldType];
      const newReferenceType = ReferenceTypesMap[jobRefTypeChange.newType];
      text = <>{oldReferenceType}: {printValue(jobRefTypeChange.value, 'jobRef')}<ShipamaxArrowIcon className='with-padding' />{newReferenceType}: {printValue(jobRefTypeChange.value, 'jobRef')}</>;
    }
      break;

    case ActivityType.FieldCorrection: {
      const fieldCorrection = props.activityLog.data as ActivityDetailsFieldCorrection;
      let fieldLabel = fieldCorrection.field;

      fieldLabel = retrieveFieldCorrectionLabel(
        fieldCorrection,
        props.isNonCargowise,
        props.documentGroup,
        props.activityLog.documentId,
      );

      activityTypeLabel = 'edited ' + fieldLabel;

      let oldValue = fieldCorrection.oldValue;
      let newValue = fieldCorrection.newValue;

      if (['invoiceDate', 'serviceStartDate', 'serviceEndDate', 'shippedOnBoardDate'].includes(fieldCorrection.field)) {
        oldValue = oldValue ? moment(oldValue).format('DD MMM YYYY') : oldValue;
        newValue = newValue ? moment(newValue).format('DD MMM YYYY') : newValue;
      }

      if (['jobRef', 'bolNum', 'containerNum', 'purchaseOrder'].includes(fieldCorrection.field)) {
        const referenceType = ReferenceTypesMap[fieldCorrection.field];

        text = <>{referenceType}: {printValue(oldValue, fieldCorrection.field)}<ShipamaxArrowIcon className='with-padding' />{referenceType}: {printValue(newValue, fieldCorrection.field)}</>;
      } else {
        text = <>{printValue(oldValue, fieldCorrection.field)}<ShipamaxArrowIcon className='with-padding' />{printValue(newValue, fieldCorrection.field)}</>;
      }
    }
      break;

    case ActivityType.BulkAction: {
      const bulkActionDetails = props.activityLog.data as ActivityDetailsBulkAction;

      if (bulkActionDetails.clearOperationTypes.length === 2) {
        activityTypeLabel = 'cleared all data';
      } else if (bulkActionDetails.clearOperationTypes.length === 1) {
        activityTypeLabel = bulkActionDetails.clearOperationTypes[0] === ClearOperationType.Header ? 'cleared the header form' : 'deleted all subtotals';
      }
    }
      break;

    case ActivityType.ExchangeRateCorrection: {
      const exchangeRateCorrectionDetails = props.activityLog.data as ActivityDetailsExchangeRateCorrection;

      text = (
        <>
          {exchangeRateCorrectionDetails.sourceCurrency}<ShipamaxArrowIcon />{exchangeRateCorrectionDetails.targetCurrency}: {formatNumber(exchangeRateCorrectionDetails.oldRate, { precision: 8 })}<ShipamaxArrowIcon className='with-padding' />
          {exchangeRateCorrectionDetails.sourceCurrency}<ShipamaxArrowIcon />{exchangeRateCorrectionDetails.targetCurrency}: {formatNumber(exchangeRateCorrectionDetails.newRate, { precision: 8 })}
        </>
      );
    }
      break;

    case ActivityType.AccrualChange: {
      const accrualChangeDetails = props.activityLog.data as ActivityDetailsAccrualChange;
      let leftColumn, rightColumn;

      if (!accrualChangeDetails.oldAccrual.isSplit && accrualChangeDetails.newAccrual.isSplit) {
        activityTypeLabel = 'split the cost of an accrual';
        leftColumn = getNonSplitAccrualText(accrualChangeDetails.oldAccrual, false);
        rightColumn = getSplitAccrualText(accrualChangeDetails.newAccrual, true);
      } else if (accrualChangeDetails.oldAccrual.isSplit && !accrualChangeDetails.newAccrual.isSplit) {
        activityTypeLabel = 'reverted the split of an accrual';
        leftColumn = getSplitAccrualText(accrualChangeDetails.oldAccrual, false);
        rightColumn = getNonSplitAccrualText(accrualChangeDetails.newAccrual, true);
      } else if (accrualChangeDetails.oldAccrual.isSplit && accrualChangeDetails.newAccrual.isSplit) {
        activityTypeLabel = 'changed the split of an accrual';
        leftColumn = getSplitAccrualText(accrualChangeDetails.oldAccrual, false);
        rightColumn = getSplitAccrualText(accrualChangeDetails.newAccrual, true);
      } else if (accrualChangeDetails.newAccrual.originalOsCostAmount === accrualChangeDetails.newAccrual.osCostAmount) {
        activityTypeLabel = 'reverted the update of an accrual';
        leftColumn = getNonSplitAccrualText(accrualChangeDetails.oldAccrual, false);
        rightColumn = getNonSplitAccrualText(accrualChangeDetails.newAccrual, true);
      } else {
        activityTypeLabel = 'updated the cost of an accrual';
        leftColumn = getNonSplitAccrualText(accrualChangeDetails.oldAccrual, false);
        rightColumn = getNonSplitAccrualText(accrualChangeDetails.newAccrual, true);
      }

      text = (
        <div className="flex flex-row" >
          <div>
            {leftColumn}
          </div>
          <div>
            {rightColumn}
          </div>
        </div>
      )
    }
      break;
    case ActivityType.SplitGroup: {
      const splitDetails = props.activityLog.data as ActivityDetailsSplitMergeGroups;
      activityTypeLabel = `created a new pack (ID ${splitDetails.newGroups[0]}) by splitting an existing pack (ID ${splitDetails.oldGroups[0]})`;
    }
      break;
    case ActivityType.MergeGroup: {
      const mergeDetails = props.activityLog.data as ActivityDetailsSplitMergeGroups;
      activityTypeLabel = `updated pack (ID ${mergeDetails.newGroups[0]}) by merging packs (IDs ${mergeDetails.oldGroups})`;
    }
      break;
    case ActivityType.UpdatedDocumentStructure: {
      const updateTypeDetails = props.activityLog.data as ActivityDetailsUpdateDocumentStructure;
      const detailsPrev: string[] = [];
      const detailsPost: string[] = [];
      updateTypeDetails.oldStructure.forEach((oldD) => {
        detailsPrev.push(`Document ID ${oldD.id} (${props.companyDocTypes[oldD.type]}) with ${oldD.pages?.length || 'all'} page${oldD.pages?.length !== 1 ? 's' : ''}`);
      });
      updateTypeDetails.newStructure.forEach((newD) => {
        detailsPost.push(`Document ID ${newD.id} (${props.companyDocTypes[newD.type]}) with ${newD.pages?.length || 'all'} page${newD.pages?.length !== 1 ? 's' : ''}`);
      });
      text = (<>
        <div className='old'>
          {detailsPrev.map((l) => {
            return <>{l}<br /></>;
          })}
        </div>
        <ShipamaxArrowIcon className="activity-log-wrapper__icon-separator" />
        <div className='new'>
          {detailsPost.map((l) => {
            return <>{l}<br /></>;
          })}
        </div></>);
    }
      break;
    case ActivityType.UpdatedHierarchy: {
      const updateTypeDetails = props.activityLog.data as ActivityDetailsUpdateHierarchy;
      const detailsPrev: JSX.Element[] = [];
      const detailsPost: JSX.Element[] = [];
      console.log(updateTypeDetails.oldHierarchy);
      console.log(updateTypeDetails.newHierarchy);
      updateTypeDetails.oldHierarchy.forEach((mainH) => {
        detailsPrev.push(<>-&ensp;Document ID {mainH.id} {getTreeDocType(mainH.documentType, 'Consol')}</>);
        mainH.children?.forEach((h) => {
          detailsPrev.push(<><span className="icon-tree"></span> Document ID {h.id} {getTreeDocType(h.documentType, 'Shipment')}</>);
          h.children?.forEach((hc) => {
            detailsPrev.push(<>&emsp;&ensp;<span className="icon-tree"></span> Document ID {hc.id} {getTreeDocType(hc.documentType, 'Shipment')}</>);
          });
        });
      });
      updateTypeDetails.newHierarchy.forEach((mainH) => {
        detailsPost.push(<>-&ensp;Document ID {mainH.id} {getTreeDocType(mainH.documentType, 'Consol')}</>);
        mainH.children?.forEach((h) => {
          detailsPost.push(<><span className="icon-tree"></span> Document ID {h.id} {getTreeDocType(h.documentType, 'Shipment')}</>);
          h.children?.forEach((hc) => {
            detailsPost.push(<>&emsp;&ensp;<span className="icon-tree"></span> Document ID {hc.id} {getTreeDocType(hc.documentType, 'Shipment')}</>);
          });
        });
      });
      console.log(detailsPrev);
      text = (<>
        <div className='old'>
          {detailsPrev.map((l) => {
            return <>{l}<br /></>;
          })}
        </div>
        <ShipamaxArrowIcon className="activity-log-wrapper__icon-separator" />
        <div className='new'>
          {detailsPost.map((l) => {
            return <>{l}<br /></>;
          })}
        </div></>);
    }
      break;
    case ActivityType.ChangePostingBehaviour: {
      const mergeDetails = props.activityLog.data as ActivityDetailsPostBehaviourChange;
      text = <>{PostingBehaviourText[mergeDetails.oldBehaviour]} <ShipamaxArrowIcon className="activity-log-wrapper__icon-separator" /> {PostingBehaviourText[mergeDetails.newBehaviour]}</>;
    }
      break;
    case ActivityType.UpdatedDocumentType: {
      const updateTypeDeatails = props.activityLog.data as ActivityDetailsUpdateDocumentStructure;
      text = <>Document ID {updateTypeDeatails.oldStructure[0]?.id} ({props.companyDocTypes[updateTypeDeatails.oldStructure[0]?.type]}) Type changed to ({props.companyDocTypes[updateTypeDeatails.newStructure[0]?.type]})</>;
    }
      break;
  }

  const userData = props.activityLog.userId ? props.usersMap.get(props.activityLog.userId) : undefined;
  const [defaultUser, defaultUserInitials] = [
    ActivityType.TMSErrorLog
  ].includes(props.activityLog.type) ? ['TMS', 'TMS'] : ['Shipamax', 'SM'];

  const [
    userName,
    initials
  ] = userData ? [
    userData.firstName + ' ' + userData.lastName,
    userData.firstName.charAt(0) + userData.lastName.charAt(0)
  ] : [
      defaultUser,
      defaultUserInitials
    ];

  return (
    <div className="activity-log-wrapper">
      <div>
        <Avatar initials={initials} />
      </div>
      <div className="activity-log-wrapper__body">
        <div className="activity-log-wrapper__body__header">
          <span>{userName}</span>
          <span>{activityTypeLabel}</span>
          <span>{moment(props.activityLog.timestamp).format('HH:mm DD MMM YYYY')}</span>
        </div>
        <div className="activity-log-wrapper__body__content">
          {text}
        </div>
      </div>
    </div>
  )
}
