import * as React from 'react';
import { Theme } from '@nivo/core';
import { Bar, TooltipProp, BarItemProps } from '@nivo/bar';
import { ColorsMapType } from '../utils';
import { AxisSettings, ChartColors } from '../common';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';

interface Props {
  colorsMap: ColorsMapType;
  data: any[];
  keys: string[];
  bottomAxisSettings: AxisSettings;
  leftAxisSettings: AxisSettings;
  indexBy: string;
  groupMode: 'grouped' | 'stacked';
  tooltip?: TooltipProp;
  barComponent?: React.FC<BarItemProps> | undefined;
  maxValue?: 'auto' | number | undefined;
  innerPadding?: number | undefined;
  animate?: boolean | undefined;
  theme?: Theme;
}

export const BarChart: React.FC<Props> = (props) => (
  <AutoSizer style={{ width: '100%' }}>
    {(size: Size) => (
      <Bar
        width={size.width}
        height={size.height}
        theme={props.theme}
        data={props.data}
        keys={props.keys}
        indexBy={props.indexBy}
        margin={{ top: 10, right: 10, bottom: 70, left: 70 }}
        padding={0.2}
        innerPadding={props.innerPadding}
        colors={(bar) => props.colorsMap[bar.id] || ChartColors.Color1}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: props.bottomAxisSettings.tickRotation || 0,
          legend: props.bottomAxisSettings.axisLegend,
          legendPosition: 'middle',
          legendOffset: 64,
          format: props.bottomAxisSettings.axisFormat,
          renderTick: props.bottomAxisSettings.renderTick
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.leftAxisSettings.axisLegend,
          legendPosition: 'middle',
          legendOffset: -60,
          format: props.leftAxisSettings.axisFormat
        }}
        enableLabel={false}
        animate={props.animate !== undefined ? props.animate : true}
        motionStiffness={90}
        motionDamping={15}
        groupMode={props.groupMode}
        tooltip={props.tooltip}
        barComponent={props.barComponent}
        maxValue={props.maxValue}
      />
    )}
  </AutoSizer>
)
