import { request } from './request';
import { APIDocumentGroupContainer } from './documentGroup';

export const BillOfLadingContainerAPI = {
  async update(id: number, change: Partial<APIDocumentGroupContainer>): Promise<unknown> {
    return request(`/BillOfLadingContainers/${id}`, 'PATCH', change);
  },

  async create(container: Partial<APIDocumentGroupContainer>): Promise<APIDocumentGroupContainer> {
    return request('/BillOfLadingContainers', 'POST', container)
  },

  async remove(id: number): Promise<unknown> {
    return request(`/BillOfLadingContainers/${id}`, 'DELETE');
  }
}
