import * as React from 'react';
import { ConfirmModal } from '../../common/ConfirmModal';
import { DocumentGroupAPI } from '../../../api/documentGroup';
import { IssueCode } from '../../../api/issueCode';

interface Props {
  documentGroupIds: number[];
  onCancel: () => void;
  onFinished: () => void;
}
export const DiscardBulkAction: React.FC<Props> = (props) => {
  const onSubmit = async () => {
    await DocumentGroupAPI.bulkDiscard(props.documentGroupIds, [{ id: IssueCode.BulkDiscard, description: 'Bulk discard' }]);
    props.onFinished();
  }

  const invoicesLabel = props.documentGroupIds.length === 1 ? 'invoice' : 'invoices';

  return (
    <ConfirmModal
      show={true}
      onConfirm={onSubmit}
      onHide={props.onCancel}
      keepOpenOnConfirm
      title={`Discard selected ${invoicesLabel}`}
      text={`
        ${props.documentGroupIds.length === 1 ? 'The selected invoice' : 'All selected invoices'} will be discarded.
        <br />
        <br />
        <b>Would you like to discard ${props.documentGroupIds.length} selected ${invoicesLabel}?</b>
      `}
    />
  );
}