import * as React from 'react';
import { AdminHeader } from '../../AdminHeader';
import { TextInput } from '../../../common/TextInput';
import { WrapperLabel } from '../../../common/WrapperLabel';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { APISite, SiteAPI } from '../../../../api/sites';
import { AdminLinks } from '../../Admin';
import { isFormInvalid, RecordValidator } from '../../../common/Form';

interface UrlParams {
  id?: string;
}

interface Props {
  data?: APISite;
}

export const SiteForm: React.FC<Props> = (props) => {
  const [record, setRecord] = React.useState<Partial<APISite>>({
    companyId: parseInt(localStorage.getItem('companyId') || ''),
  });
  const [isNameTaken, setIsNameTaken] = React.useState<boolean>(false);
  const [formEntered, setFormEntered] = React.useState<boolean>(false);

  const recordValidator: RecordValidator = {
    name: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: (name) => !!name
        },
        {
          errorMessage: 'A Site already exists with this name',
          isValid: () => !isNameTaken
        }
      ]
    },
  }

  const submitDisabled = isFormInvalid<APISite>(record, recordValidator);

  const params = useParams<UrlParams>();
  const history = useHistory();

  React.useEffect(() => {
    if (params.id) {
      SiteAPI.fetch(parseInt(params.id)).then((data) => {
        setRecord(data);
      });
    }
  }, [params.id]);

  React.useEffect(() => {
    if (!record.name) {
      setIsNameTaken(false);
      return;
    }

    SiteAPI.isFieldValueTaken('name', record.name, record.id).then((result) => {
      setIsNameTaken(result);
    });
  }, [record.name]);

  const onSave = async () => {
    setFormEntered(true);
    if (submitDisabled) return;

    if (record.id) {
      await SiteAPI.update(record.id, record);
    } else {
      await SiteAPI.create(record);
    }

    history.push(AdminLinks.Sites);
  }

  return (
    <div className="admin__form__wrapper with-custom-scrollbar">
      <AdminHeader
        headline={params.id ? 'Edit Site' : 'Add a Site'}
        tagText="Global"
        links={[{
          label: 'What are Sites?',
          url: '/knowledge-base/What-are-Sites--eb5b6a1dd1f14c83a6616a89cfa595ff'
        }]}
      />
      <div className="admin__form__content">
        <div>
          <WrapperLabel text="Site name*">
            <TextInput
              name="name"
              value={record.name || null}
              setter={(value) => setRecord({ ...record, name: value })}
              formEntered={formEntered}
              validators={recordValidator.name.validators}
            />
          </WrapperLabel>
        </div>
        <div>
          <Link to={AdminLinks.Sites}><button className="light-button">Cancel</button></Link>
          <button className="full-button" onClick={onSave}>Save</button>
        </div>
      </div>
    </div>
  )
}