import { request } from './request';
import { Environment } from './cwCredential';

export enum TMSLoginType {
  Cargowise = 'cargowise',
  WiseCloud = 'wisecloud'
}

export interface APITMSApplicationCredential {
  id?: number;
  username: string;
  password: string;
  customerEnv: Environment | null;
  companyId: number;
  type: TMSLoginType;
}

export const CwLoginAPI = {
  fetchCwApplicationCredential(args: Partial<APITMSApplicationCredential>): Promise<APITMSApplicationCredential[]> {
    const filter = JSON.stringify({
      where: {
        companyId: parseInt(localStorage.getItem('companyId') + ''),
        ...args
      }
    });
    return request(`/CargowiseLogins?filter=${encodeURIComponent(filter)}`);
  },

  async update(id: number, cwApplicationCredential: Partial<APITMSApplicationCredential>): Promise<boolean> {
    return request(`/CargowiseLogins/${id}`, 'PATCH', { ...cwApplicationCredential, companyId: parseInt(localStorage.getItem('companyId') + '') });
  },

  async create(data: Partial<APITMSApplicationCredential>): Promise<APITMSApplicationCredential> {
    return request('/CargowiseLogins', 'POST', { ...data, companyId: parseInt(localStorage.getItem('companyId') + '') });
  },
}