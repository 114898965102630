import * as React from 'react';
import { FAIcon } from '../../common/FAIcon';
import { DocumentAssignment } from '../../common/DocumentAssignment';
import { BulkActionType } from '../ApInvoiceSection';
import { ReassignBulkAction } from './ReassignBulkAction';
import { APITeam } from '../../../api/teams';
import { APIUser } from '../../../api/comment';
import { DiscardBulkAction } from './DiscardBulkAction';
import { AssignmentRule } from '../../../api/userSettings';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { RevalidateBulkAction } from './RevalidateBulkAction';
import { PermissionLevel } from '../../../api/authentication';
import { APIPermissionProfile } from '../../../api/permissionProfiles';

interface Props {
  teams: APITeam[];
  users: APIUser[];
  selectedGroupIds: number[];
  onFinished: () => void;
  disabled: boolean;
  permissionLevel: PermissionLevel;
  checkPermissionProfileAccess: (value: keyof APIPermissionProfile) => boolean;
}

export const BulkActionsButtons: React.FC<Props> = (props) => {
  const [bulkActionType, setBulkActionType] = React.useState<BulkActionType | undefined>();
  const [assignmentRule, setAssignmentRule] = React.useState<AssignmentRule | undefined>();

  const selectedUser = props.users.find((user) => assignmentRule?.userIds.includes(user.id));
  const selectedTeam = props.teams.find((team) => team.id === assignmentRule?.teamId);

  const bulkDiscard = () => {
    setBulkActionType(BulkActionType.Discard);
  }

  const bulkReassign = () => {
    setBulkActionType(BulkActionType.Reassign);
  }

  const bulkRevalidate = () => {
    setBulkActionType(BulkActionType.Revalidate);
  }

  return (
    <>
    { props.checkPermissionProfileAccess('discardAP') &&
      <OverlayTrigger overlay={<Tooltip>Discard</Tooltip>} placement="bottom" delayShow={500}>
        <button
          className="light-button active-on-hover"
          onClick={() => bulkDiscard()}
          disabled={props.disabled}
        ><FAIcon name="trash-alt" /></button>
      </OverlayTrigger> }
      <DocumentAssignment
        teams={props.teams}
        users={props.users}
        assignmentRule={{ teamId: null, userIds: [] }}
        setAssignmentRule={(value) => {
          setAssignmentRule(value);
          bulkReassign();
        }}
        disabled={props.disabled}
        showOnlyIcon={true}
      />
      {(props.permissionLevel === PermissionLevel.Admin || props.checkPermissionProfileAccess('postBulk')) && (
        <OverlayTrigger overlay={<Tooltip>Post</Tooltip>} placement="bottom" delayShow={500}>
          <button
            className="light-button active-on-hover"
            onClick={() => bulkRevalidate()}
            disabled={props.disabled}
          ><FAIcon name="paper-plane" /></button>
        </OverlayTrigger>
      )}
      {bulkActionType && (
        <>
          {bulkActionType === BulkActionType.Reassign && (
            <ReassignBulkAction
              documentGroupIds={props.selectedGroupIds}
              onCancel={() => setBulkActionType(undefined)}
              onFinished={props.onFinished}
              selectedUser={selectedUser}
              selectedTeam={selectedTeam}
            />
          )}
          {bulkActionType === BulkActionType.Discard && (
            <DiscardBulkAction
              documentGroupIds={props.selectedGroupIds}
              onCancel={() => setBulkActionType(undefined)}
              onFinished={props.onFinished}
            />
          )}
          {bulkActionType === BulkActionType.Revalidate && (
            <RevalidateBulkAction
              documentGroupIds={props.selectedGroupIds}
              onCancel={() => setBulkActionType(undefined)}
              onFinished={props.onFinished}
            />
          )}
        </>
      )}
    </>
  )
}
