import * as React from 'react';
import { Column } from 'react-data-grid';
import { AdminHeader } from '../../AdminHeader';
import { AdminGrid, AdminGridSearchParams, AdminGridTagsList, AdminGridTagType } from '../../AdminGrid';
import { AdminLinks } from '../../Admin';
import { APIPermissionProfile, PermissionProfileAPI } from '../../../../api/permissionProfiles';
import { Link } from 'react-router-dom';
import { APIUser } from '../../../../api/comment';

import './index.scss';

interface Props {
  permissionProfilesUsersMap: Map<number, APIUser[]>;
}

export const PermissionProfilesTable: React.FC<Props> = (props) => {

  const fetchSites = (params?: AdminGridSearchParams) => {
    return PermissionProfileAPI.fetchAll(params);
  };

  const columns: Column<APIPermissionProfile>[] = [
    {
      key: 'name', name: 'Profile name',
    },
    {
      key: 'description', name: 'Description'
    },
    {
      key: 'solution', name: 'Solution', formatter: (tableProps) => {
        const items = [];
        if (tableProps.row.accessAP) items.push('AP');
        if (tableProps.row.accessForwarding) items.push('F&C');
        if (tableProps.row.accessWtgIntegration) items.push('WTGIntegration');
        if (tableProps.row.accessInsights) items.push('Insights');

        return <AdminGridTagsList items={items} type={AdminGridTagType.Default} />;
      }
    },
    {
      key: 'workspace', name: 'Workspace', formatter: (tableProps) => {
        const items = [];
        if (tableProps.row.workspaceProduction) items.push('Production');
        if (tableProps.row.workspaceTest) items.push('Test');

        return <AdminGridTagsList items={items} type={AdminGridTagType.Default} />;
      }
    },
    {
      key: 'numberOfUsers', name: 'Number of users', width: 115, formatter: (tableProps) => {
        return <>{props.permissionProfilesUsersMap.get(tableProps.row.id)?.length || 0}</>;
      }
    },
    {
      key: 'actions', name: 'Actions', width: 100, formatter: (tableProps) => {
        return <Link to={`${AdminLinks.PermissionProfiles}/form/${tableProps.row.id}`}><button className="light-button active-on-hover">Edit</button></Link>;
      },
    },
  ];

  return (
    <div>
      <AdminHeader
        headline="Permission profiles"
        tagText="Global"
        links={[{
          label: 'What are Permission profiles?',
          url: '/knowledge-base/Permission-Profiles-and-User-Roles--1df0af2d35e640e6805fb7a7d7f28641'
        }]}
      />
      <AdminGrid
        searchPlaceholder="Search profiles..."
        columns={columns}
        fetchData={fetchSites}
        link={{
          url: AdminLinks.PermissionProfiles + '/form',
          label: 'Create a new Profile'
        }}
      />
    </div>
  )
};
