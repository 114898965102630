import { request } from './request';
import { PostingBehaviour, StatusCode } from '../components/bill-of-lading/common';
import { APIComment } from './comment';
import { APISplitDetails, DocumentType, MinimalHierarchy } from './documentContainer';
import { ClearOperationType } from './supplierInvoice';
import { APIClusterAccrual } from './clusterAccrual';
import { JobRefType } from '../components/ap-invoice/document-action/Clusters';

export enum ActivityType {
  AddedComment = 'comment',
  StatusChange = 'status_change',
  RequestedValidation = 'requested_validation',
  BulkRequestedValidation = 'bulk_requested_validation',
  PostedStraightThrough = 'posted_straight_through',
  Assignment = 'assignment',
  Reclassification = 'reclassification',
  TMSErrorLog = 'tms_error_log',
  TMSRequestLog = 'tms_request_log',
  FileTypeChange = 'file_type_change',
  FieldCorrection = 'field_correction',
  BulkAction = 'bulk_action',
  AccrualChange = 'accrual_change',
  ExchangeRateCorrection = 'exchange_rate_correction',
  JobRefTypeChange = 'job_ref_type_change',
  RollupPrevention = 'rollup_prevention',
  ChangePostingBehaviour = 'posting_behaviour_change',
  SplitGroup = 'split_group',
  MergeGroup = 'merge_group',
  ShipmentDeleted = 'shipment_deleted',
  ShipmentCreated = 'shipment_created',
  UpdatedHierarchy = 'updated_hierarchy',
  UpdatedDocumentStructure = 'updated_document_structure',
  UpdatedDocumentType = 'updated_document_type',
}

export interface BaseActivityDetails {
  codes?: { id: number, code: string, description: string }[];
}

export interface ActivityDetailsStatusChange extends BaseActivityDetails {
  oldStatus: StatusCode,
  newStatus: StatusCode,
  message?: string,
}

export interface ActivityDetailsAssignment extends BaseActivityDetails {
  oldTeamId?: number | null,
  newTeamId?: number | null,
  oldUserIds?: number[],
  newUserIds?: number[],
}

export interface ActivityDetailsFileTypeChange extends BaseActivityDetails {
  changes: {
    documentId: number,
    oldFileType: DocumentType,
    newFileType: DocumentType,
    fileName: string,
  }[];
}

export interface ActivityDetailsFileTypeChange extends BaseActivityDetails {
  changes: {
    documentId: number,
    oldFileType: DocumentType,
    newFileType: DocumentType,
    fileName: string,
  }[];
}

export interface ActivityDetailsRequestedValidation extends BaseActivityDetails {
  withoutPosting?: boolean;
}

export interface ActivityDetailsBulkAction extends BaseActivityDetails {
  clearOperationTypes: ClearOperationType[];
}

export interface ActivityDetailsAccrualChange extends BaseActivityDetails {
  oldAccrual: APIClusterAccrual;
  newAccrual: APIClusterAccrual;
}

export interface ActivityDetailsExchangeRateCorrection extends BaseActivityDetails {
  sourceCurrency: string;
  targetCurrency: string;
  oldRate: number;
  newRate: number;
}

export interface ActivityDetailsJobRefTypeChange extends BaseActivityDetails {
  oldType: JobRefType;
  newType: JobRefType;
  value: string | null;
}

export interface ActivityDetailsFieldCorrection extends BaseActivityDetails {
  field: string;
  model: string;
  newValue: any;
  oldValue: any;
}

export interface ActivityRollupPrevention extends BaseActivityDetails {
  message: string;
}

export interface ActivityDetailsPostBehaviourChange extends BaseActivityDetails {
  newBehaviour: PostingBehaviour;
  oldBehaviour: PostingBehaviour;
}

export interface ActivityDetailsSplitMergeGroups extends BaseActivityDetails {
  oldGroups: number[];
  newGroups: number[];
}

export interface ActivityDetailsUpdateDocumentStructure extends BaseActivityDetails {
  oldStructure: APISplitDetails[],
  newStructure: APISplitDetails[],
}

export interface ActivityDetailsUpdateHierarchy extends BaseActivityDetails {
  oldHierarchy: MinimalHierarchy[],
  newHierarchy: MinimalHierarchy[],
}

export type ActivityDetailsType =
  ActivityDetailsStatusChange |
  ActivityDetailsAssignment |
  ActivityDetailsFileTypeChange |
  ActivityDetailsRequestedValidation |
  ActivityDetailsBulkAction |
  ActivityDetailsAccrualChange |
  ActivityDetailsExchangeRateCorrection |
  ActivityDetailsJobRefTypeChange |
  ActivityRollupPrevention |
  ActivityDetailsFieldCorrection |
  ActivityDetailsPostBehaviourChange |
  undefined;

export interface APIActivityLog {
  id: number;
  timestamp: string;
  userId: number;
  documentId: number;
  documentGroupId: number;
  type: ActivityType;
  details: ActivityDetailsType;
}

export const ActivityLogAPI = {
  async createLog(activityLog: Partial<APIActivityLog>) {
    return request(`/ActivityLogs`, 'POST', activityLog);
  },

  async fetchAll(documentGroupId: number): Promise<APIComment[]> {
    const filter = JSON.stringify({
      where: {
        documentGroupId,
      },
      order: ['timestamp DESC'],
    });
    return request(`/ActivityLogs?filter=${encodeURIComponent(filter)}`);
  },
}
