import * as React from 'react';
import * as moment from 'moment';
import { TextInput } from '../../common/TextInput';
import { DateSelectEditor } from '../../common/DateSelectEditor';
import { NumberInput } from '../../common/NumberInput';
import { APIValidationResult, Exception, ExceptionCode } from '../../../api/validationResults';
import {
  APIExchangeRate,
  APIJobOwnersMap,
  SupplierInvoiceAPI,
} from '../../../api/supplierInvoice';
import { Notification, APIError } from '../../common/Notification';
import {
  CoordinatesFilter,
  DocumentType,
  FieldData
} from '../../../api/documentContainer';
import { OptionValue } from 'react-selectize';
import { FAIcon } from '../../common/FAIcon';
import { Clusters } from './Clusters';
import { Cluster, JobRefClusterAPI } from '../../../api/jobRefCluster';
import { CreditorSelect } from '../../common/CreditorSelect';
import { CreditorChangedParams } from '../ApInvoiceSection';
import { parseNumber, roundNumber, sortSearchOptions } from '../../helpers';
import { InvoiceTotalDifference } from '../../common/InvoiceTotalDifference';
import { CustomSelect } from '../../common/CustomSelect';
import { APIClusterAccrual, ClusterAccrualAPI } from '../../../api/clusterAccrual';
import { WrapperLabel } from '../../common/WrapperLabel';
import { CompanyAPI } from '../../../api/company';
import { APICgwCurrencyExchangeRates } from '../../../api/currency';
import { getCurrencyExchangeRate } from './AccrualsRow';
import { ShipamaxExchangeIcon } from '../../../images/Icons';
import { IssuerDetailMap } from '../../bill-of-lading/common';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { isAccrualWithoutChanges } from './helpers';
import { APIPermissionProfile, APIPermissionProfileToleranceThreshold } from '../../../api/permissionProfiles';

const arrowSmallRight = require('../../../images/arrow-small-right.svg');

interface TaxDiscrepancyProps {
  taxTotal: number | null;
  selectedAccrualsVAT: number;
  taxTolerance: number | null;
}

const TaxDiscrepancy: React.FC<TaxDiscrepancyProps> = (props) => {
  const difference = props.selectedAccrualsVAT - (props.taxTotal || 0);
  const hasError = Math.abs(difference) > (props.taxTolerance || 0);

  return (
    <WrapperLabel text="Accrued tax" className="form__tax-discrepancy">
      <div className="form__tax-discrepancy__wrapper">
        <NumberInput
          value={props.selectedAccrualsVAT}
          name="vatTotal"
          disabled={true}
          className="form__vat"
          hasErrors={hasError}
        />
        {hasError && (
          <span className="absolute">
            ({difference.toLocaleString(navigator.language, { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })})
          </span>
        )}
      </div>
    </WrapperLabel>
  )
}

interface Props {
  data: APIValidationResult | null;
  documentType: DocumentType;
  currencyOptions: OptionValue[];
  issuerDetails: IssuerDetailMap;
  addresseeOptions: OptionValue[];
  exceptions: Exception[];
  disabled: boolean;
  enableOnlyJobRefs?: boolean;
  setNotification: (notification: Notification | null) => void;
  creditorChanged: (params: CreditorChangedParams) => void;
  setIsResubmitDisabled: (value: boolean) => void;
  isResubmitDisabled: boolean;
  removeCoordinates: (coordinatesFilter: CoordinatesFilter) => void;
  jobOwnersMap: APIJobOwnersMap;
  permissionProfile: APIPermissionProfile | null;
  assignUser: (userId: number) => void;
  clusters: Cluster[];
  setClusters: (value: (((prevState: Cluster[]) => Cluster[]) | Cluster[])) => void;
  showCargoWiseSettings: boolean;

  totalVat: number | null;
  setTotalVat: (value: number | null) => void;
  invoiceTotal: number | null;
  invoiceNetTotal: number;
  setInvoiceTotal: (value: number | null) => void;
  invoiceAccruals: APIClusterAccrual[];
  setInvoiceAccruals: (value: (((prevState: APIClusterAccrual[]) => APIClusterAccrual[]) | APIClusterAccrual[])) => void;
  currency: OptionValue | null;
  setCurrency: (value: (((prevState: OptionValue | null) => OptionValue | null) | (OptionValue | null))) => void;
  toleranceThreshold: APIPermissionProfileToleranceThreshold | undefined;
  exchangeRates: APIExchangeRate[];
  setExchangeRates: (value: (((prevState: APIExchangeRate[]) => APIExchangeRate[]) | APIExchangeRate[])) => void;
  cargowiseExchangeRates: APICgwCurrencyExchangeRates[];
}

export const ExtractedDataForm: React.FC<Props> = React.memo((props) => {
  const [addressee, setAddressee] = React.useState<OptionValue | null>(null);
  const [invoiceNumber, setInvoiceNumber] = React.useState<string | null>(null);
  const [invoiceDate, setInvoiceDate] = React.useState<moment.Moment | null>(null);
  const [taxDate, setTaxDate] = React.useState<moment.Moment | null>(null);
  const [issuerCgwRecordId, setIssuerCgwRecordId] = React.useState<string | null>(null);
  const [issuerCgwCode, setIssuerCgwCode] = React.useState<string | null | undefined>(null);

  const [taxCode, setTaxCode] = React.useState<OptionValue | null>(null);
  const [taxCodesOptions, setTaxCodesOptions] = React.useState<OptionValue[]>([]);

  const [branch, setBranch] = React.useState<string | null>(null);
  const [departmentCode, setDepartmentCode] = React.useState<string | null>(null);

  const clustersRef = React.useRef(null);

  const showExchangeRates = React.useMemo(() => props.invoiceAccruals.some((accrual) => accrual.osCurrencyCode !== props.currency?.label), [props.invoiceAccruals]);
  const isOverheadInvoice = props.documentType === DocumentType.OverheadInvoice;
  const emailAccount = props.data?.supplierInvoice.document.emailAccount;
  const mailboxCurrency = emailAccount?.localCurrency || 'UNKNOWN';
  const isInvoiceInLocal = Boolean(mailboxCurrency !== 'UNKNOWN' && props.currency?.label === mailboxCurrency);

  React.useEffect(() => {
    if (props.data) {
      CompanyAPI.getTaxCodes(emailAccount?.branchCountryCode ?? '', emailAccount?.taxCodeGroupId ?? 1).then((data) => {
        setTaxCodesOptions(data.map((taxCode) => ({ label: taxCode.code + ' - ' + taxCode.description, value: taxCode.code })));
      });

      const invoice = props.data.supplierInvoice;

      setAddressee(invoice.addresseeCgwCode ? { value: invoice.addresseeCgwCode, label: invoice.addresseeCgwCode } : null);
      setInvoiceNumber(invoice.invoiceNumber);
      props.setCurrency(invoice.currency ? { value: invoice.currencyId, label: invoice.currency } : null);
      props.setTotalVat(invoice.vatTotal);
      props.setInvoiceTotal(invoice.grossTotal);
      setInvoiceDate(invoice.invoiceDate ? moment(invoice.invoiceDate) : null);
      setTaxDate(invoice.taxDate ? moment(invoice.taxDate) : null);
      setIssuerCgwRecordId(invoice.issuerCgwRecordId);
      setIssuerCgwCode(invoice.issuerCgwCode);
      setBranch(invoice.branch);
      setDepartmentCode(invoice.departmentCode);
    } else {
      setIssuerCgwRecordId(null);
      setIssuerCgwCode(null);
      setAddressee(null);
      setInvoiceNumber(null);
      props.setCurrency(null);
      props.setTotalVat(null);
      props.setInvoiceTotal(null);
      setInvoiceDate(null);
      setTaxDate(null);
      setBranch(null);
      setDepartmentCode(null);
    }
  }, [props.data]);

  React.useEffect(() => {
    setTaxCode(taxCodesOptions.find((taxCodeOption) => taxCodeOption.value === props.data?.supplierInvoice.taxCode) || null);
  }, [props.data, taxCodesOptions]);

  React.useEffect(() => {
    if (props.data?.supplierInvoice.id && isOverheadInvoice && !props.data?.supplierInvoice.taxCode && taxCodesOptions.length === 1) {
      SupplierInvoiceAPI.update(props.data.supplierInvoice.id, { taxCode: taxCodesOptions[0].value });
      setTaxCode(taxCodesOptions[0]);
    }
  }, [taxCodesOptions, props.data?.supplierInvoice.taxCode]);

  const onInputFieldChanged = (value: string | number, name: string) => {
    switch (name) {
      case 'invoiceNumber': setInvoiceNumber(value.toString());
        break;
      case 'vatTotal': props.setTotalVat(Number(value));
        break;
      case 'grossTotal': props.setInvoiceTotal(Number(value));
        break;
      case 'branch':
        setBranch(value.toString().toUpperCase());
        break;
      case 'departmentCode':
        setDepartmentCode(value.toString().toUpperCase());
        break;
    }
  }

  const onDateChanged = (date: moment.Moment | null, updateInvoice?: boolean, fieldData?: FieldData) => {
    const newValue = date ? date.format('YYYY-MM-DD') : null;
    if (fieldData?.attributeNames?.includes('tax_date')) {
      setTaxDate(date);
      if (updateInvoice) {
        update('taxDate', newValue);
      }
    } else {
      setInvoiceDate(date);
      if (updateInvoice) {
        update('invoiceDate', newValue);
      }
    }
  }

  const update = async (name: string, value: string | number | null, withoutChange?: boolean) => {
    if (withoutChange) {
      return;
    }

    if (props.data && !props.disabled) {
      const patch = { [name]: value !== undefined ? value : null };
      if (name === 'currencyId') {
        const currencyOption = props.currencyOptions.find((c) => c.value === value);
        patch.currency = currencyOption ? currencyOption.label : null;
      } else if (name === 'grossTotal') {
        if (props.clusters.length === 1 && props.clusters[0].isHeader) {
          JobRefClusterAPI.update(props.clusters[0].id, { total: Number(value) });
          props.clusters[0].total = Number(value);
        }
      } else if (name === 'branch') {
        if (value) {
          props.clusters.forEach((cluster) => !cluster.branch && JobRefClusterAPI.update(cluster.id, { branch: value.toString().toUpperCase() }));
          props.setClusters((clusters) => clusters.map((cluster: Cluster) => !cluster.branch ? ({ ...cluster, branch: value.toString().toUpperCase() }) : cluster));
        }
      } else if (name === 'departmentCode') {
        if (value) {
          props.clusters.forEach((cluster) => (!cluster.departmentCode || cluster.departmentCode.length < 3) && JobRefClusterAPI.update(cluster.id, { departmentCode: value.toString().toUpperCase() }));
          props.setClusters((clusters) => clusters.map((cluster: Cluster) => !cluster.departmentCode ? ({ ...cluster, departmentCode: value.toString().toUpperCase() }) : cluster));
        }
      }
      // update fields
      const result = await SupplierInvoiceAPI.update(props.data.supplierInvoice.id, patch);
      if (result === null) {
        props.setNotification({ ...APIError, details: { invoiceId: props.data.supplierInvoice.id }, reason: `Update on invoice returned null` });
      }

      if (['grossTotal', 'vatTotal'].includes(name)) {
        clustersRef.current && (clustersRef.current as any).resetAccrualsInHeaderCluster();
      }
    }
  }

  const onFilterOptions = (items: OptionValue[], search: string): OptionValue[] => {
    const filteredOptions = items.filter((option) => option.label.includes(search.toUpperCase()));
    return sortSearchOptions(filteredOptions, search);
  }

  const updateExchangeRate = (updatedExchangeRate: APIExchangeRate) => {
    props.setExchangeRates((exchangeRates) => exchangeRates.map((exchangeRate) => {
      return exchangeRate.id === updatedExchangeRate.id ? { ...updatedExchangeRate } : exchangeRate;
    }));
  }

  const flipExchangeRate = async (exchangeRate: APIExchangeRate) => {
    if (!exchangeRate.rate) return;

    const updatedExchangeRate = {
      ...exchangeRate,
      sourceCurrency: exchangeRate.targetCurrency,
      targetCurrency: exchangeRate.sourceCurrency,
      rate: roundNumber(1 / exchangeRate.rate, 8),
      originalRate: roundNumber(1 / exchangeRate.originalRate, 8),
      originalExtractedRate: exchangeRate.originalExtractedRate ? roundNumber(1 / exchangeRate.originalExtractedRate, 8) : undefined,
      extractedRate: exchangeRate.extractedRate ? roundNumber(1 / exchangeRate.extractedRate, 8) : undefined,
    };

    props.setExchangeRates((exchangeRates) => exchangeRates.map((exchangeRate) => {
      return exchangeRate.id === updatedExchangeRate.id ? updatedExchangeRate : exchangeRate;
    }));

    await SupplierInvoiceAPI.updateExchangeRate(exchangeRate.id, updatedExchangeRate);
  }

  const onBlurExchangeRate = async (id: number, rate: number, withoutChange: boolean, updatedExchangeRate: APIExchangeRate) => {
    if (withoutChange || !rate) return;

    await SupplierInvoiceAPI.updateExchangeRate(id, { rate, extractedRate: rate });
    const updatedAccruals = props.invoiceAccruals.map((accrual) => {
      const exchangeRate = getCurrencyExchangeRate(mailboxCurrency, accrual.osCurrencyCode, [updatedExchangeRate]);

      if (!exchangeRate) return accrual;

      return {
        ...accrual,
        localCostAmount: rate ? roundNumber(exchangeRate.isFlipped ? accrual.osCostAmount * rate : accrual.osCostAmount / rate, 2) : 0,
        localVatAmount: rate && accrual.vatAmount ? roundNumber(exchangeRate.isFlipped ? accrual.vatAmount * rate : accrual.vatAmount / rate, 2) : 0,
      }
    });

    await ClusterAccrualAPI.saveAccruals(updatedAccruals, props.invoiceAccruals);
    props.setInvoiceAccruals(updatedAccruals);
  }

  const onCurrencyChange = async (option: OptionValue | null) => {
    props.setCurrency(option);
    await update('currencyId', option?.value);

    if (props.data?.supplierInvoice.id) {
      props.setInvoiceAccruals((invoiceAccruals) => {
        const changedAccrualIds: number[] = [];
        const newAccruals: APIClusterAccrual[] = [];

        invoiceAccruals.forEach((accrual) => {
          if (isAccrualWithoutChanges(accrual)) {
            return newAccruals.push(accrual);
          }

          changedAccrualIds.push(accrual.id);
          newAccruals.push({
            ...accrual,
            osCostAmount: accrual.originalOsCostAmount,
            localCostAmount: accrual.originalLocalCostAmount,
            vatAmount: accrual.originalVatAmount,
            osCurrencyCode: accrual.originalOsCurrencyCode,
            updatedByUser: null,
            selected: false,
            isSplit: false,
            isUpdated: false,
          });
        });

        if (changedAccrualIds.length) {
          ClusterAccrualAPI.resetInvoiceAccruals(props.data!.supplierInvoice.id, changedAccrualIds);
        }
        return newAccruals;
      });
    }
  }

  const hasExceptionJobsDontAddUpToTotal = props.exceptions.find((ex) => ex.code === ExceptionCode.CargoWiseJobsDontAddUpToTotal);
  const hasNonHeaderClusters = props.clusters.find((cluster) => !cluster.isHeader);

  /*
    if we removed one of two last non-header clusters, we will convert the last cluster to header cluster.
    if the resubmit button is still disabled, enable it
  */
  if (!hasNonHeaderClusters && props.isResubmitDisabled) {
    props.setIsResubmitDisabled(false);
  }

  const totalsJobRefs = props.clusters.reduce((sum, cluster) => {
    if (cluster.isHeader && props.clusters.length > 1) {
      return sum;
    }

    return sum + parseNumber(cluster.total || 0);
  }, 0);

  const taxTotalsJobRefs = props.clusters.reduce((sum, cluster) => {
    return sum + parseNumber(cluster.vatTotal || 0);
  }, 0);

  const totalsDifference = totalsJobRefs + parseNumber(props.totalVat || 0) - parseNumber(props.invoiceTotal || 0);
  const taxDifference = taxTotalsJobRefs - parseNumber(props.totalVat || 0);
  const hasTotalsDifference = Math.abs(totalsDifference) > 0.001;
  const hasTaxDifference = Math.abs(taxDifference) > 0.001 && isOverheadInvoice;
  const hasDifference = hasTotalsDifference || hasTaxDifference

  if (hasDifference && hasNonHeaderClusters) {
    props.setIsResubmitDisabled(true)
  } else {
    props.setIsResubmitDisabled(false)
  }

  const hasTaxTotalDiscrepancyException = props.exceptions.find((ex) => ex.code === ExceptionCode.CargoWiseVATMismatch) && props.invoiceAccruals.find((acc) => acc.selected);
  const selectedAccrualsVAT = props.invoiceAccruals.reduce((sum, accrual) => sum + (accrual.selected ? (Math.round((isInvoiceInLocal ? accrual.localVatAmount : accrual.vatAmount) * 1000) || 0) : 0), 0) / 1000;

  return (
    <div className={`form with-custom-scrollbar ${isOverheadInvoice ? 'form--overheads' : ''}`}>
      <div className="main-form">
        <div className="main-form__title">Invoice header</div>
        <div className="grid__row">
          <div className="grid__col-6">
            <CreditorSelect
              recordId={issuerCgwRecordId}
              onValueChange={(option) => {
                const cgwCode = option?.label?.split(/ - (.+)/)[0];
                setIssuerCgwCode(cgwCode);
                setIssuerCgwRecordId(option?.value);
                update('issuerCgwRecordId', option?.value);
                if (issuerCgwCode !== cgwCode) {
                  update('issuerCgwCode', cgwCode || null);
                }
                props.creditorChanged({
                  recordId: option?.value,
                  modelId: props.data?.supplierInvoice.id,
                  fieldName: 'issuerCgwRecordId'
                });
              }}
              showAddress={true}
              flags={{ creditor: true }}
              label="Issuer"
              disabled={props.enableOnlyJobRefs || props.disabled}
              creditorDetailsMap={props.issuerDetails}
              companyCode={emailAccount?.cwCompanyCode}
              required
              dataTestId="issuer-input"
              fieldData={{
                objectIds: [props.data?.supplierInvoice.id || -1],
                tableName: 'supplier_invoice',
                attributeNames: ['issuer_cgw_record_id'],
              }}
            />
          </div>
          <div className="grid__col-6 grid__col--flow-column">
            <WrapperLabel text="Addressee">
              <CustomSelect
                options={props.addresseeOptions}
                value={addressee || undefined}
                renderNoResultsFound={(item: OptionValue, search: string) => <div className="dropdown-placeholder">Please enter the first 3 characters of the code...</div>}
                filterOptions={onFilterOptions}
                onValueChange={(option) => {
                  setAddressee(option);
                  update('addresseeCgwCode', option?.value);
                }}
                renderResetButton={() => <FAIcon name="times" solid className={`simple-select--clear ${addressee && addressee.value ? '' : 'hide'}`} />}
                showResetButton
                disabled={props.enableOnlyJobRefs || props.disabled}
                className="form__addressee"
              />
            </WrapperLabel>
            <div className="grid__sub-row grid__col-12">
              <div className="grid__row">
                <div className="grid__col-6">
                  <WrapperLabel text="Invoice date">
                    <DateSelectEditor
                      initDate={invoiceDate}
                      onDateChange={onDateChanged}
                      disabled={props.enableOnlyJobRefs || props.disabled}
                      fieldData={{
                        objectIds: [props.data?.supplierInvoice?.id || -1],
                        tableName: 'supplier_invoice',
                        attributeNames: ['invoice_date']
                      }}
                      required
                      className="form__invoice-date"
                    />
                  </WrapperLabel>
                </div>
                <div className="grid__col-6">
                  {emailAccount?.useTaxDate &&
                    <WrapperLabel text="Tax date">
                      <DateSelectEditor
                        initDate={taxDate}
                        onDateChange={onDateChanged}
                        disabled={props.enableOnlyJobRefs || props.disabled}
                        fieldData={{
                          objectIds: [props.data?.supplierInvoice?.id || -1],
                          tableName: 'supplier_invoice',
                          attributeNames: ['tax_date']
                        }}
                        className="form__invoice-date"
                      />
                    </WrapperLabel>
                  }
                </div>
              </div>
            </div>
            <div className="grid__sub-row grid__col-12">
              <div className="grid__row">
                <div className="grid__col-4">
                  <WrapperLabel text="Currency">
                    <CustomSelect
                      required
                      options={props.currencyOptions}
                      value={props.currency || undefined}
                      filterOptions={onFilterOptions}
                      onValueChange={onCurrencyChange}
                      disabled={props.enableOnlyJobRefs || props.disabled}
                      fieldData={{
                        objectIds: [props.data?.supplierInvoice.id || -1],
                        attributeNames: ['currency'],
                        tableName: 'supplier_invoice'
                      }}
                      className="form__currency"
                    />
                  </WrapperLabel>
                </div>
                <div className="grid__col-8">
                  <WrapperLabel text="Invoice number" className="form__invoice-number">
                    <TextInput
                      required
                      value={invoiceNumber}
                      name="invoiceNumber"
                      onBlur={update}
                      onChange={onInputFieldChanged}
                      disabled={props.enableOnlyJobRefs || props.disabled}
                      fieldData={{
                        objectIds: [props.data?.supplierInvoice?.id || -1],
                        tableName: 'supplier_invoice',
                        attributeNames: ['invoice_number'],
                      }}
                    />
                  </WrapperLabel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid__row">
          <div className="grid__col-6">
            {isOverheadInvoice && (
              <>
                {props.showCargoWiseSettings ? (
                  <>
                    <WrapperLabel text="Branch">
                      <TextInput
                        required={!hasNonHeaderClusters}
                        value={branch}
                        name="branch"
                        maxLength={3}
                        onBlur={update}
                        onChange={onInputFieldChanged}
                        disabled={props.enableOnlyJobRefs || props.disabled}
                        fieldData={{
                          objectIds: [props.data?.supplierInvoice.id || -1],
                          attributeNames: ['branch'],
                          tableName: 'supplier_invoice'
                        }}
                      />
                    </WrapperLabel>
                    <WrapperLabel text="Department">
                      <TextInput
                        required={!hasNonHeaderClusters}
                        value={departmentCode}
                        name="departmentCode"
                        maxLength={3}
                        onBlur={update}
                        onChange={onInputFieldChanged}
                        disabled={props.enableOnlyJobRefs || props.disabled}
                        fieldData={{
                          objectIds: [props.data?.supplierInvoice.id || -1],
                          attributeNames: ['department_code'],
                          tableName: 'supplier_invoice'
                        }}
                      />
                    </WrapperLabel>
                  </>
                ) : (
                  <>
                    <div />
                    <div />
                  </>
                )}
                <WrapperLabel text="Tax Code">
                  <CustomSelect
                    required={!hasNonHeaderClusters}
                    dropdownMenuSize={'extra-wide'}
                    options={taxCodesOptions}
                    value={taxCode || undefined}
                    filterOptions={onFilterOptions}
                    onValueChange={(option) => {
                      setTaxCode(option);
                      update('taxCode', option?.value);

                      if (option) {
                        props.clusters.forEach((cluster) => !cluster.taxCode && JobRefClusterAPI.update(cluster.id, { taxCode: option.value }));
                        props.setClusters((clusters) => clusters.map((cluster: Cluster) => !cluster.taxCode ? ({ ...cluster, taxCode: option.value }) : cluster));
                      }
                    }}
                    disabled={props.enableOnlyJobRefs || props.disabled}
                    fieldData={{
                      objectIds: [props.data?.supplierInvoice.id || -1],
                      attributeNames: ['tax_code'],
                      tableName: 'supplier_invoice'
                    }}
                    renderValue={(option) => option.value}
                  />
                </WrapperLabel>
              </>
            )}
            {!isOverheadInvoice && showExchangeRates && !!props.exchangeRates.length && (
              <div className="form__exchange-rates">
                <WrapperLabel text="Exchange rates">
                  {props.exchangeRates.map((exchangeRate) => {
                    const isLocked = props.invoiceAccruals.some((accrual) => accrual.selected && getCurrencyExchangeRate(mailboxCurrency, accrual.osCurrencyCode, [exchangeRate]));

                    return (
                      <div className={`form__exchange-rates__row ${isLocked ? 'form__exchange-rates__row--locked' : ''}`} key={'exchange-rate-' + exchangeRate.id}>
                        <div className="form__exchange-rates__label">
                          <span>{exchangeRate.sourceCurrency}<img src={arrowSmallRight} />{exchangeRate.targetCurrency}</span>
                          <OverlayTrigger overlay={<Tooltip><b>Reverse</b> the exchange rate</Tooltip>} delayShow={500} placement="bottom">
                            <div className="form__exchange-rates__row__flip">
                              <ShipamaxExchangeIcon
                                onClick={(event) => { event.preventDefault(); event.stopPropagation(); flipExchangeRate(exchangeRate); }}
                                className="active-on-hover"
                              />
                            </div>
                          </OverlayTrigger>
                        </div>
                        {isLocked && <FAIcon name="lock" className="form__exchange-rates__row__lock" solid />}
                        <NumberInput
                          disabled={props.disabled || isLocked}
                          minPrecision={6}
                          precision={8}
                          maxVisibleLength={10}
                          value={exchangeRate.rate}
                          name={`exchangeRate-${exchangeRate.id}`}
                          onChange={(rate) => updateExchangeRate({ ...exchangeRate, rate, extractedRate: rate })}
                          onBlur={(name, rate, withoutChange) => rate && onBlurExchangeRate(exchangeRate.id, rate, withoutChange, { ...exchangeRate, rate })}
                        />
                      </div>
                    )
                  })}
                </WrapperLabel>
              </div>
            )}
          </div>
          <div className="grid__col-6 !block">
            <div className="grid__row">
              <div className="grid__col-4">
                <WrapperLabel text="Tax total">
                  <NumberInput
                    required
                    value={props.totalVat !== null ? Number(props.totalVat) : null}
                    name="vatTotal"
                    onBlur={update}
                    onChange={onInputFieldChanged}
                    disabled={props.enableOnlyJobRefs || props.disabled}
                    fieldData={{
                      objectIds: [props.data?.supplierInvoice?.id || -1],
                      tableName: 'supplier_invoice',
                      attributeNames: ['vat_total']
                    }}
                    className="form__vat"
                  />
                </WrapperLabel>
              </div>
              <div className="grid__col-8">
                <WrapperLabel text="Gross total">
                  <NumberInput
                    required
                    value={props.invoiceTotal ? Number(props.invoiceTotal) : null}
                    name="grossTotal"
                    onBlur={update}
                    onChange={onInputFieldChanged}
                    disabled={props.enableOnlyJobRefs || props.disabled}
                    fieldData={{
                      objectIds: [props.data?.supplierInvoice?.id || -1],
                      tableName: 'supplier_invoice',
                      attributeNames: ['gross_total']
                    }}
                    className="form__invoice-total"
                  />
                </WrapperLabel>
              </div>
            </div>
            {hasTaxTotalDiscrepancyException && (
              <div className="grid__row grid__row">
                <div className="grid__col-4">
                  <TaxDiscrepancy
                    selectedAccrualsVAT={selectedAccrualsVAT}
                    taxTolerance={emailAccount?.taxTolerance || null}
                    taxTotal={props.totalVat}
                  />
                </div>
                <div className="grid__col-8" />
              </div>
            )}
          </div>
        </div>
        {(hasExceptionJobsDontAddUpToTotal || hasDifference) && hasNonHeaderClusters ? (
          <div className="grid__row">
            <div className="grid__col-6">
              {isOverheadInvoice && (
                <InvoiceTotalDifference
                  calculatedTotal={taxTotalsJobRefs}
                  invoiceTotal={props.invoiceTotal || 0}
                  totalVat={props.totalVat || 0}
                  label="Sum of Sub Taxes"
                  isResubmitDisabled={props.isResubmitDisabled}
                  taxMode={true}
                  hasDifference={hasTaxDifference}
                />
              )}
            </div>
            <div className="grid__col-6">
              <InvoiceTotalDifference
                calculatedTotal={totalsJobRefs}
                invoiceTotal={props.invoiceTotal || 0}
                totalVat={props.totalVat || 0}
                label="Sum of subtotals"
                isResubmitDisabled={props.isResubmitDisabled}
                hasDifference={hasTotalsDifference}
              />
            </div>
          </div>
        ) : ""}
      </div>
      <Clusters
        ref={clustersRef}
        invoiceId={props.data?.supplierInvoice.id || 0}
        clusters={props.clusters}
        updateClusters={props.setClusters}
        emailAccount={emailAccount}
        invoiceCurrencyCode={props.currency?.label || ''}
        invoiceNetTotal={props.invoiceNetTotal}
        exceptions={props.exceptions}
        disabled={props.disabled}
        isAccountsLevelUser={!props.enableOnlyJobRefs}
        toleranceThreshold={props.toleranceThreshold}
        useSeparateLimit={props.permissionProfile?.setSeparateLimit || false}
        invoiceAccruals={props.invoiceAccruals}
        setInvoiceAccruals={props.setInvoiceAccruals}
        exchangeRates={props.exchangeRates}
        document={props.data?.supplierInvoice.document}
        removeCoordinates={props.removeCoordinates}
        cargowiseExchangeRates={props.cargowiseExchangeRates}
        taxCodesOptions={taxCodesOptions}
        isOverheadInvoice={isOverheadInvoice}
        jobOwnersMap={props.jobOwnersMap}
        assignUser={props.assignUser}
        showCargoWiseSettings={props.showCargoWiseSettings}
      />
    </div>
  );
});
