import * as React from 'react';
import { OptionValue } from 'react-selectize';
import { StandaloneContainer } from '../common/StandaloneContainer';
import { ChartFilterToggles } from './ChartFilterToggles';
import { DateFilterType, DateFilterWrapper, DateOriginFilterType, DateRange, DateUnit, getDateRange } from './DateFilter';
import { CustomMultiSelect, labelSeparator } from '../common/CustomMultiSelect';
import { StatusChart } from './explore-ap-pages/StatusChart';
import { CountChart } from './explore-ap-pages/CountChart';
import { CorrectionsChart } from './explore-ap-pages/CorrectionsChart';
import { IssueCodesChart } from './explore-ap-pages/IssueCodesChart';
import {
  Aggregation,
  AggregationDataType,
  AssignedToOptions,
  ChartType,
  chartTypeOptions,
  DataSet,
  InvoiceStatusSegment,
  OptionValueMap,
  OrganisationType,
  ReferenceData,
  SectionModule,
  SerieToggleState,
  shouldAnonymizeData,
  tolerancesModifier
} from './common';
import {
  ChartsUserSettings,
  ChartUserSettings,
  InsightsFilterSettings,
  SelectedCreditor,
} from '../../api/userSettings';
import { APITeam } from '../../api/teams';
import { APIUser } from '../../api/comment';
import { CreditorsMultiselect } from './CreditorsMultiselect';
import { ExceptionTypesChart } from './explore-ap-pages/ExceptionTypesChart';
import { TolerancesChart } from './explore-ap-pages/TolerancesChart';
import { APIEmailAccount, EmailAccountAPI, ValidationType } from '../../api/emailAccount';
import { CompanyAPI } from '../../api/company';
import { Notification } from '../common/Notification';
import { CustomSelect } from '../common/CustomSelect';
import { CinvCountChart } from './explore-groups-pages/CinvCountChart';
import { GroupCorrectionsChart } from './explore-groups-pages/GroupCorrectionsChart';
import { GroupLineCorrectionsChart } from './explore-groups-pages/GroupLineCorrectionsChart';
import { StatusCountChart } from './explore-groups-pages/StatusCountChart';
import { CinvLineItemsCountChart } from './explore-groups-pages/CinvLineItemsCountChart';
import { APIPermissionProfile } from '../../api/permissionProfiles';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import * as moment from 'moment/moment';
import { ShipamaxDragIcon, ShipamaxEllipsisIcon } from '../../images/Icons';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { APICustomDashboardChart } from '../../api/customDashboard';
import { PermissionLevel } from '../../api/authentication';
import { APICustomDashboard } from '../../api/customDashboard';

import './explore.scss';

export const aggregationsData: AggregationDataType = {
  [Aggregation.Creditor]: {
    dropdownLabel: 'Creditor',
    xAxisLabel: 'Creditors',
  },
  [Aggregation.DayReceived]: {
    dropdownLabel: 'Day received',
    xAxisLabel: 'Day received',
  },
  [Aggregation.WeekReceived]: {
    dropdownLabel: 'Week received',
    xAxisLabel: 'Week received',
  },
  [Aggregation.MonthReceived]: {
    dropdownLabel: 'Month received',
    xAxisLabel: 'Month received',
  },
  [Aggregation.DayPosted]: {
    dropdownLabel: 'Day posted',
    xAxisLabel: 'Day posted',
  },
  [Aggregation.WeekPosted]: {
    dropdownLabel: 'Week posted',
    xAxisLabel: 'Week posted',
  },
  [Aggregation.MonthPosted]: {
    dropdownLabel: 'Month posted',
    xAxisLabel: 'Month posted',
  },
  [Aggregation.ExceptionCause]: {
    dropdownLabel: 'Issue Code',
    xAxisLabel: 'Issue Codes',
    enabledFor: [ChartType.InvoiceCount],
  },
  [Aggregation.Team]: {
    dropdownLabel: 'Team (assignee)',
    xAxisLabel: 'Teams',
  },
  [Aggregation.User]: {
    dropdownLabel: 'User (assignee)',
    xAxisLabel: 'Users',
  },
  [Aggregation.Mailbox]: {
    dropdownLabel: 'Mailbox',
    xAxisLabel: 'Mailboxes',
  },
  [Aggregation.TeamModifier]: {
    dropdownLabel: 'Team (modifier)',
    xAxisLabel: 'Teams',
    enabledFor: [ChartType.Tolerances],
  },
  [Aggregation.UserModifier]: {
    dropdownLabel: 'User (modifier)',
    xAxisLabel: 'Users',
    enabledFor: [ChartType.Tolerances],
  },
  [Aggregation.JobOwner]: {
    dropdownLabel: 'Job Owner',
    xAxisLabel: 'Job Owners',
    enabledFor: [ChartType.Tolerances],
  },
  [Aggregation.Importer]: {
    dropdownLabel: 'Importer',
    xAxisLabel: 'Importers',
  },
  [Aggregation.Supplier]: {
    dropdownLabel: 'Supplier',
    xAxisLabel: 'Suppliers',
  },
  [Aggregation.PostingUser]: {
    dropdownLabel: 'Posting User',
    xAxisLabel: 'User',
  },
}

export const aggregationsOptions = {
  [SectionModule.APInvoice]: [
    {
      groupId: 'received',
      items: [
        Aggregation.DayReceived,
        Aggregation.WeekReceived,
        Aggregation.MonthReceived
      ]
    },
    {
      groupId: 'posted',
      items: [
        Aggregation.DayPosted,
        Aggregation.WeekPosted,
        Aggregation.MonthPosted,
      ]
    },
    Aggregation.Creditor,
    Aggregation.Team,
    Aggregation.ExceptionCause,
    Aggregation.User,
    Aggregation.Mailbox,
    Aggregation.TeamModifier,
    Aggregation.UserModifier,
    Aggregation.JobOwner,
  ],
  [SectionModule.ForwardAndClearance]: [
    {
      groupId: 'received',
      items: [
        Aggregation.DayReceived,
        Aggregation.WeekReceived,
        Aggregation.MonthReceived
      ]
    },
    {
      groupId: 'posted',
      items: [
        Aggregation.DayPosted,
        Aggregation.WeekPosted,
        Aggregation.MonthPosted,
      ]
    },
    Aggregation.Importer,
    Aggregation.Supplier,
    Aggregation.PostingUser,
    Aggregation.Team,
    Aggregation.User,
    Aggregation.Mailbox,
    Aggregation.TeamModifier,
    Aggregation.UserModifier,
  ],
};

export const groupByGroups = [
  {
    groupId: 'received',
    title: 'Date Received'
  }, {
    groupId: 'posted',
    title: 'Date Posted'
  }, {
    groupId: '',
    title: ''
  }];

export enum CounterType {
  InvoiceCount = 1,
  InvoicePercentage = 2,
  LineCount = 3,
  LinePercentage = 4,
}

const validationTypes = {
  [SectionModule.APInvoice]: [ValidationType.AP],
  [SectionModule.ForwardAndClearance]: [ValidationType.Clearance, ValidationType.ClearanceAndForwarding, ValidationType.Export, ValidationType.Forwarding]
};

export const getCounterTypesData = (chartType: ChartType): { [key: number]: { axisLabel: string, buttonLabel: string } } => {
  return (chartType === ChartType.LineCorrections)
    ? {
      [CounterType.LineCount]: {
        buttonLabel: '#',
        axisLabel: 'Lines count'
      },
      [CounterType.LinePercentage]: {
        buttonLabel: '%',
        axisLabel: 'Lines percentage'
      },
    }
    : {
      [CounterType.InvoiceCount]: {
        buttonLabel: chartType === ChartType.Tolerances ? '$' : '#',
        axisLabel: 'Invoice count'
      },
      [CounterType.InvoicePercentage]: {
        buttonLabel: '%',
        axisLabel: 'Invoice percentage'
      },
    };
};

export const invoiceStates = {
  [SectionModule.APInvoice]: [
    {
      value: InvoiceStatusSegment.Unposted,
      label: 'Open',
    },
    {
      value: InvoiceStatusSegment.Posted,
      label: 'Posted straight through',
    },
    {
      value: InvoiceStatusSegment.PostedAfterExceptions,
      label: 'Posted after exception',
    },
    {
      value: InvoiceStatusSegment.Queried,
      label: 'In Query',
    },
    {
      value: InvoiceStatusSegment.Discarded,
      label: 'Discarded',
    },
  ],
  [SectionModule.ForwardAndClearance]: [
    {
      value: InvoiceStatusSegment.Unposted,
      label: 'Unposted',
    },
    {
      value: InvoiceStatusSegment.Posted,
      label: 'Posted without correction',
    },
    {
      value: InvoiceStatusSegment.PostedAfterCorrection,
      label: 'Posted after correction',
    },
    {
      value: InvoiceStatusSegment.Discarded,
      label: 'Discarded',
    },
  ],
};

const dropdownAssignedToOptions: OptionValue[] = [
  {
    value: AssignedToOptions.Mailbox,
    label: 'mailbox'
  },
  {
    value: AssignedToOptions.Team,
    label: 'team'
  },
  {
    value: AssignedToOptions.User,
    label: 'user'
  },
];

export const dropdownDataSetOptions: OptionValue[] = [
  {
    value: DataSet.CommercialInvoice,
    label: 'Commercial Invoices'
  },
];

const dropdownOrganisationType: OptionValue[] = [
  {
    value: OrganisationType.Any,
    label: 'Any organisation'
  },
  {
    value: OrganisationType.Importer,
    label: 'Importer'
  },
  {
    value: OrganisationType.Supplier,
    label: 'Supplier'
  },
];

export interface ChartFilterProps extends InsightsFilterSettings {
  aggregation: Aggregation;
  dateRange: DateRange | undefined;
  localCurrency?: string;
  excludeOverheadInvoices: boolean;
  userOptions?: SerieToggleState
}

export interface ChartCommonProps {
  isFetching: boolean;
  setIsFetching: (value: boolean) => void;
  counterType: CounterType;
  referenceData: ReferenceData;
  hideToggles?: boolean;
  setNotification: (notification: Notification | null) => void;
  renderForCustomDashboard?: boolean;
  customDashboards: APICustomDashboard[];
  permissionLevel: PermissionLevel;
  insightsSettings: InsightsFilterSettings;
  chartUserSettings: ChartUserSettings;
  renderAddToButton?: boolean;
  customPageSize?: number;
  getChartToggles?: (data: SerieToggleState) => void;
  removeFilteredData?: boolean;
  defaultToggles?: boolean;
  productSection?: number;
}

const createOptionsMap = (options: OptionValue[]): OptionValueMap => options.reduce((map, option) => ({ ...map, [option.value]: option }), {});
const mapValuesToOptionValues = (values: number[], optionValueMap: OptionValueMap): OptionValue[] => {
  const result: OptionValue[] = [];

  values.forEach((value) => {
    if (optionValueMap[value]) result.push(optionValueMap[value]);
  });

  return result;
}

const dateUnitLabel = {
  [DateUnit.Day]: 'day',
  [DateUnit.Week]: 'week',
  [DateUnit.Month]: 'month',
  [DateUnit.Year]: 'year',
}

const TagWrapper = (props: { children: React.ReactNode }) => (
  <span
    className="text-grey6 text-[14px] inline-block px-[1px] py-[1px] my-[1px] border-[1px] border-solid border-grey4 rounded-[4px] bg-grey2 mx-[2px] font-semibold leading-[18px]"
  >{props.children}</span>
)

const ListTagWrapper = (props: { nodes: React.ReactNode[] }) => {
  if (props.nodes.length <= 2) {
    return (
      <>
        {props.nodes.map((node, index) => <TagWrapper key={index}>{node}</TagWrapper>)}
      </>
    );
  }

  return (
    <>
      <TagWrapper>{props.nodes[0]}</TagWrapper>
      <OverlayTrigger overlay={<Tooltip>{props.nodes.slice(1).map((node, index) => <span key={index}>{node}<br /></span>)}</Tooltip>} placement="bottom" delayShow={500}>
        <div>
          <TagWrapper>+{props.nodes.length - 1} more</TagWrapper>
        </div>
      </OverlayTrigger>
    </>
  )
}

const getDateRangeLabel = (insightsSettings: InsightsFilterSettings): React.ReactNode => {
  if (insightsSettings.dateFilter.type === DateFilterType.Between) {
    return <>from <TagWrapper>{moment(insightsSettings.dateFilter.firstDate)?.format('DD MMM YYYY')}</TagWrapper> to <TagWrapper>{moment(insightsSettings.dateFilter.secondDate)?.format('DD MMM YYYY')}</TagWrapper></>
  }

  if (insightsSettings.dateFilter.type === DateFilterType.InTheLast) {
    return <>in the past <TagWrapper>{insightsSettings.dateFilter.dateUnitsCount} {dateUnitLabel[insightsSettings.dateFilter.dateUnit]}{insightsSettings.dateFilter.dateUnitsCount! > 1 ? 's' : ''}</TagWrapper></>
  }

  if (insightsSettings.dateFilter.type === DateFilterType.After) {
    return <>after <TagWrapper>{moment(insightsSettings.dateFilter.firstDate)?.format('DD MMM YYYY')}</TagWrapper></>
  }

  if (insightsSettings.dateFilter.type === DateFilterType.Before) {
    return <>before <TagWrapper>{moment(insightsSettings.dateFilter.firstDate)?.format('DD MMM YYYY')}</TagWrapper></>
  }
}

const getBelongToLabel = (
  insightsSettings: InsightsFilterSettings,
  teamsMap: OptionValueMap,
  usersMap: OptionValueMap,
  mailboxesMap: OptionValueMap,
): React.ReactNode => {
  if (insightsSettings.mailboxIds.length) {
    return <>and were sent to <ListTagWrapper nodes={insightsSettings.mailboxIds.map((id) => mailboxesMap[id]?.label)} /></>
  }

  if (insightsSettings.teamIds.length) {
    return <>and belong to team{insightsSettings.teamIds.length > 1 ? 's' : ''} <ListTagWrapper nodes={insightsSettings.teamIds.map((id) => teamsMap[id]?.label)} /></>
  }

  if (insightsSettings.userIds.length) {
    return <>and belong to user{insightsSettings.userIds.length > 1 ? 's' : ''} <ListTagWrapper nodes={insightsSettings.userIds.map((id) => usersMap[id]?.label.replace('-&-', '-'))} /></>
  }

  return '';
}

const getCreditorLabel = (creditor: SelectedCreditor, section: SectionModule) => {
  const salesCreditorPrefix = section === SectionModule.APInvoice ? 'Creditor ' : 'Organisation ';
  return shouldAnonymizeData ? salesCreditorPrefix + creditor.id : creditor.code
}

const getRelatedToLabel = (
  insightsSettings: InsightsFilterSettings,
  section: SectionModule,
): React.ReactNode => {
  const prefix = section === SectionModule.APInvoice ? 'and are issued by ' : 'and are related to ';

  if (insightsSettings.creditors?.length) {
    return <>{prefix}<ListTagWrapper nodes={insightsSettings.creditors.map((creditor) => getCreditorLabel(creditor, section))} /></>
  }

  return '';
}

interface Props {
  setNotification: (notification: Notification | null) => void;
  permissionLevel: PermissionLevel;
  section: SectionModule;
  permissionProfile: APIPermissionProfile | null;
  insightsSettings: InsightsFilterSettings;
  chartSettings: ChartUserSettings;
  updateFilterSettings: (filterSettings: Partial<InsightsFilterSettings>) => void;
  updateChartSettings: (chartSettings: ChartsUserSettings) => void;
  dashboardViewSettings?: {
    dragHandleProps: any;
    isBeingDragged: boolean;
    enableChartEditMode: (chart: APICustomDashboardChart) => void;
    onDeleteChart: (chart: APICustomDashboardChart) => void;
    chart: APICustomDashboardChart;
    customPageSize: number;
    onChartLoaded: () => void;
  }
  dashboardEditSettings?: {
    chart: APICustomDashboardChart;
    updateChartSettings: (chartSettings: ChartUserSettings) => void;
  }
  customDashboards: APICustomDashboard[];
  hideToggles?: boolean;
  renderAddToButton?: boolean;
  getChartToggles?: (data: SerieToggleState) => void;
  userOptions?: SerieToggleState;
  removeFilteredData?: boolean;
  defaultToggles?: boolean;
}

export const ChartWrapper = React.memo((props: Props) => {
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [mailboxes, setMailboxes] = React.useState<APIEmailAccount[]>([]);
  const [teams, setTeams] = React.useState<APITeam[]>([]);
  const [users, setUsers] = React.useState<APIUser[]>([]);
  const [mailboxOptions, setMailboxOptions] = React.useState<OptionValue[]>([]);

  const teamOptions = React.useMemo(() => teams.map((team) => ({ value: team.id, label: shouldAnonymizeData ? 'Team ' + team.id : team.name })), [teams]);
  const teamsMap = React.useMemo(() => createOptionsMap(teamOptions), [teamOptions]);
  const selectedTeamOptions = React.useMemo(() => mapValuesToOptionValues(props.insightsSettings.teamIds, teamsMap), [props.insightsSettings.teamIds, teamsMap]);

  const userOptions = React.useMemo(() => users.map((user) => ({
    value: user.id,
    label: shouldAnonymizeData ? 'User ' + user.id : user.firstName + ' ' + user.lastName + labelSeparator + (teamsMap[user.teamId]?.label || '')
  })), [users, teams]);
  const usersMap = React.useMemo(() => createOptionsMap(userOptions), [userOptions]);
  const selectedUserOptions = React.useMemo(() => mapValuesToOptionValues(props.insightsSettings.userIds, usersMap), [props.insightsSettings.userIds, usersMap]);

  const mailboxesMap = React.useMemo(() => createOptionsMap(mailboxOptions), [mailboxOptions]);

  const selectedMailboxOptions = React.useMemo(() => mapValuesToOptionValues(props.insightsSettings.mailboxIds, mailboxesMap), [props.insightsSettings.mailboxIds, mailboxesMap]);

  React.useEffect(() => {
    fetchReferenceData(props.insightsSettings.chartType);
  }, []);

  React.useEffect(() => {
    const options = mailboxes
      .filter((mailbox) => validationTypes[props.section].includes(mailbox.validationType))
      .map((mailbox) => ({ value: mailbox.id, label: shouldAnonymizeData ? 'Mailbox ' + mailbox.id : mailbox.accountName }));
    setMailboxOptions(options);
  }, [props.section, mailboxes])

  const setChartFetching = (value: boolean) => {
    setIsFetching(value);

    if (!value) {
      props.dashboardViewSettings?.onChartLoaded();
    }
  }

  const fetchReferenceData = (chartType: ChartType) => {
    setTeams([]);
    CompanyAPI.fetchTeamsForInsights(chartType).then((data) => {
      setTeams(data);
    });

    setUsers([]);
    CompanyAPI.fetchUsersForInsights(chartType).then((data) => {
      setUsers(data);
    });

    setMailboxes([]);
    EmailAccountAPI.fetchAllForInsights(chartType).then((data) => {
      const relevantMailboxes = data
        .filter((mailbox) => [1, null].includes(mailbox.environment));// just mailbox from prd env
      setMailboxes(relevantMailboxes);
    });
  }

  const currencies = new Set(mailboxes
    .filter((emailAccount) => emailAccount.localCurrency)
    .map((emailAccount) => emailAccount.localCurrency!.trim().toUpperCase())
  );

  const selectedCurrency = props.chartSettings.selectedCurrency?.trim().toUpperCase();
  if (selectedCurrency && !currencies.has(selectedCurrency)) {
    currencies.add(selectedCurrency)
  }

  const currenciesOptions = [...currencies].sort((c1, c2) => c1.localeCompare(c2)).map((currency) => ({ value: currency, label: currency }));
  const defaultCurrency = currenciesOptions[0]?.value;

  const chartAPIFilterProps: ChartFilterProps = React.useMemo(() => {
    return ({
      ...props.insightsSettings,
      dateRange: getDateRange(props.insightsSettings.dateFilter),
      aggregation: props.chartSettings.aggregation,
      creditorCodes: props.insightsSettings.creditors?.map((creditor) => creditor.code) || [],
      localCurrency: props.chartSettings.selectedCurrency,
      excludeOverheadInvoices: !props.permissionProfile?.canViewOverhead,
      userOptions: props.userOptions
    });
  }, [JSON.stringify(props.insightsSettings), JSON.stringify(props.chartSettings.aggregation), props.chartSettings.selectedCurrency, props.userOptions]);

  if (!chartAPIFilterProps.localCurrency && chartAPIFilterProps.chartType === ChartType.Tolerances) {
    chartAPIFilterProps.localCurrency = defaultCurrency;
  }

  const hidePercentageToggleForTolerancesModifiers = (props.insightsSettings.chartType === ChartType.Tolerances && tolerancesModifier.includes(props.chartSettings.aggregation));

  const chartCommonProps: ChartCommonProps = React.useMemo(() => ({
    isFetching,
    setIsFetching: setChartFetching,
    counterType: hidePercentageToggleForTolerancesModifiers
      ? props.insightsSettings.chartType !== ChartType.LineCorrections
        ? CounterType.InvoiceCount
        : CounterType.LineCount
      : props.chartSettings.counterType,
    referenceData: {
      mailboxesMap,
      teamsMap,
      usersMap,
    },
    hideToggles: !!props.dashboardViewSettings,
    setNotification: props.setNotification,
    renderForCustomDashboard: !!props.dashboardViewSettings,
    customDashboards: props.customDashboards,
    permissionLevel: props.permissionLevel,
    insightsSettings: props.insightsSettings,
    chartSettings: props.chartSettings,
    chartUserSettings: props.chartSettings,
    renderAddToButton: props.renderAddToButton,
    customPageSize: props.dashboardViewSettings?.customPageSize,
    getChartToggles: props.getChartToggles,
    removeFilteredData: props.removeFilteredData,
    productSection: props.section
  }), [isFetching, props.chartSettings.counterType, mailboxesMap, teamsMap, usersMap, hidePercentageToggleForTolerancesModifiers]);

  const selectedChartComponent = React.useMemo(() => {
    let selectedChartComponent;
    if (props.section === SectionModule.APInvoice) {
      switch (props.insightsSettings.chartType) {
        case ChartType.InvoiceCount:
        case ChartType.AgeOfOldest:
          selectedChartComponent = (
            <CountChart
              apiFilter={chartAPIFilterProps}
              {...chartCommonProps}
              hideExportButton={!!props.dashboardViewSettings}
            />
          );
          break;
        case ChartType.IssueCodes:
          selectedChartComponent = (
            <IssueCodesChart
              apiFilter={chartAPIFilterProps}
              {...chartCommonProps}
            />
          );
          break;

        case ChartType.Corrections: {
          selectedChartComponent = (
            <CorrectionsChart
              apiFilter={chartAPIFilterProps}
              {...chartCommonProps}
            />
          );
          break;
        }

        case ChartType.Status: {
          selectedChartComponent = (
            <StatusChart
              apiFilter={chartAPIFilterProps}
              {...chartCommonProps}
            />
          );
          break;
        }

        case ChartType.ExceptionTypes: {
          selectedChartComponent = (
            <ExceptionTypesChart
              apiFilter={chartAPIFilterProps}
              {...chartCommonProps}
            />
          );
          break;
        }

        case ChartType.Tolerances: {
          selectedChartComponent = (
            <TolerancesChart
              apiFilter={chartAPIFilterProps}
              {...chartCommonProps}
            />
          );
          break;
        }
      }
    } else {
      switch (props.insightsSettings.chartType) {
        case ChartType.InvoiceCount: {
          selectedChartComponent = (
            <CinvCountChart
              apiFilter={chartAPIFilterProps}
              {...chartCommonProps}
            />
          );
          break;
        }
        case ChartType.InvoiceLineItemCount: {
          selectedChartComponent = (
            <CinvLineItemsCountChart
              apiFilter={chartAPIFilterProps}
              {...chartCommonProps}

            />
          );
          break;
        }
        case ChartType.Corrections: {
          selectedChartComponent = (
            <GroupCorrectionsChart
              apiFilter={chartAPIFilterProps}
              {...chartCommonProps}
            />
          );
          break;
        }
        case ChartType.LineCorrections: {
          selectedChartComponent = (
            <GroupLineCorrectionsChart
              apiFilter={chartAPIFilterProps}
              {...chartCommonProps}
            />
          );
          break;
        }
        case ChartType.Status: {
          selectedChartComponent = (
            <StatusCountChart
              apiFilter={chartAPIFilterProps}
              {...chartCommonProps}
            />
          );
          break;
        }
      }
    }

    return selectedChartComponent;
  }, [chartAPIFilterProps, chartCommonProps, props.setNotification, !!props.dashboardViewSettings]);

  const selectChartType = (value: number) => {
    const selectedChartType = value as ChartType;
    let update: Partial<InsightsFilterSettings> = { chartType: selectedChartType };

    if (shouldAnonymizeData && (selectedChartType === ChartType.Tolerances || props.insightsSettings.chartType === ChartType.Tolerances)) {
      update = {
        ...update,
        teamIds: [],
        userIds: [],
        creditors: [],
        mailboxIds: []
      }
      props.updateFilterSettings(update);
      fetchReferenceData(selectedChartType);
    } else {
      props.updateFilterSettings(update);
    }
  }

  const groupByOptions: any[] = [];

  aggregationsOptions[props.section].forEach((item) => {
    if (typeof item === 'object') {
      item.items.forEach((internalItem) => {
        groupByOptions.push({ value: internalItem, label: aggregationsData[internalItem]!.dropdownLabel, groupId: item.groupId })
      });
    } else {
      if (!aggregationsData[item].enabledFor || aggregationsData[item].enabledFor?.includes(props.insightsSettings.chartType)) {
        groupByOptions.push({ value: item, label: aggregationsData[item]!.dropdownLabel, groupId: '' })
      }
    }
  });

  const showPercentageToggle = ![ChartType.InvoiceCount, ChartType.InvoiceLineItemCount, ChartType.AgeOfOldest].includes(props.insightsSettings.chartType) &&
    !hidePercentageToggleForTolerancesModifiers;
  const counterTypesData = getCounterTypesData(props.insightsSettings.chartType);

  const documentTypeLabel = props.section === SectionModule.APInvoice ? 'AP Invoices' : 'Commercial Invoices';
  let description;

  if (props.dashboardViewSettings && invoiceStates[props.section].length === props.insightsSettings.segments.length) {
    description = <>All&nbsp;<span className="font-semibold">{documentTypeLabel}</span></>;
  } else {
    const statusTags = props.insightsSettings.segments.map((status) => {
      const statusObj = props.dashboardViewSettings && invoiceStates[props.section]?.find((s) => s.value === status);
      return statusObj ? <TagWrapper>{statusObj.label}</TagWrapper> : '';
    });

    description = <><span className="font-semibold">{documentTypeLabel}</span>&nbsp;which are {statusTags}</>;
  }

  const dateRangeLabel = getDateRangeLabel(props.insightsSettings);
  const belongToLabel = getBelongToLabel(props.insightsSettings, teamsMap, usersMap, mailboxesMap);
  const relatedToLabel = getRelatedToLabel(props.insightsSettings, props.section);

  const allowChanges = props.permissionLevel === PermissionLevel.Admin;

  const descRef = React.useRef<HTMLDivElement>(null);
  const showMoreDescription = React.useMemo(() => {
    return descRef.current && descRef.current.scrollHeight > descRef.current.clientHeight;
  }, [descRef.current?.scrollHeight, descRef.current?.clientHeight]);

  const chartHeaderForCustomDashboards = props.dashboardViewSettings && (
    <div className="flex w-full">
      <div className="flex flex-1">
        {allowChanges && (
          <div className={`${props.dashboardViewSettings.isBeingDragged ? 'w-[23px]' : 'opacity-0 w-[0px]'} group-hover:opacity-100  group-hover:w-[23px] transition-all duration-300`}>
            <OverlayTrigger overlay={<Tooltip><b>Drag</b> to reorder charts</Tooltip>} placement="right" delayShow={500}>
              <button
                className="drag-handle light-button active-on-hover pl-0 !cursor-grab mr-[3px]"
                {...props.dashboardViewSettings?.dragHandleProps}
              ><ShipamaxDragIcon /></button>
            </OverlayTrigger>
          </div>
        )}
        <div className="relative group">
          <h2
            className="!text-grey6 !text-[16px] !font-[700] !mb-[4px]"
          >{props.dashboardViewSettings.chart.name}</h2>
          <div
            className="flex flex-wrap overflow-hidden max-h-[49px] group-hover:max-h-none transition-all duration-300"
            ref={descRef}
          >
            <p className="text-grey6 text-[14px] [&>div]:inline-block m-0 leading-[22px]">
              {description}&nbsp;which were {props.insightsSettings.dateFilter.dateOriginType === DateOriginFilterType.Received ? 'received' : 'posted'} {dateRangeLabel} {belongToLabel} {relatedToLabel}
            </p>
          </div>
          {showMoreDescription && (
            <div className="flex justify-center">
              <button className="light-button group-hover:hidden transition-all duration-300">more</button>
            </div>
          )}
        </div>
      </div>
      {allowChanges && (
        <div className="ml-auto">
          <ContextMenuTrigger id={`chart-context-menu-${props.dashboardViewSettings.chart.id}`} mouseButton={0}>
            <div className="cursor-pointer px-[20px] mr-[-20px]">
              <ShipamaxEllipsisIcon className='ellipsis-icon' />
            </div>
          </ContextMenuTrigger>
          <ContextMenu id={`chart-context-menu-${props.dashboardViewSettings.chart.id}`} rtl={true}>
            <MenuItem
              onClick={() => props.dashboardViewSettings?.chart && props.dashboardViewSettings.enableChartEditMode(props.dashboardViewSettings.chart)}
            ><b>Edit</b>&nbsp;Chart</MenuItem>
            <MenuItem
              onClick={() => props.dashboardViewSettings?.onDeleteChart(props.dashboardViewSettings.chart)}
            ><b>Delete</b>&nbsp;Chart</MenuItem>
          </ContextMenu>
        </div>
      )}
    </div>
  );

  return (
    <div className="insights__explore">
      {!props.dashboardViewSettings && (
        <div className="insights__explore__filters">
          <div className="insights__explore__form">
            {props.section === SectionModule.ForwardAndClearance && (
              <div className="insights__explore__form__dataset">
                <h2>Data set</h2>
                <CustomSelect
                  options={dropdownDataSetOptions}
                  value={dropdownDataSetOptions.find((option) => option.value === props.insightsSettings.dataSet)}
                  onValueChange={(option) => option && props.updateFilterSettings({ dataSet: option.value as DataSet })}
                />
              </div>
            )}
            <div className="insights__explore__form__filters mb-[15px]">
              <h2>Analysis</h2>
              <CustomSelect
                options={chartTypeOptions[props.section]}
                value={chartTypeOptions[props.section].find((option) => option.value === props.insightsSettings.chartType) || chartTypeOptions[props.section][0]}
                onValueChange={(option) => option && option.value && selectChartType(Number(option.value))}
              />
              {props.insightsSettings.chartType === ChartType.Tolerances && currenciesOptions.length > 1 && (
                <>
                  <label className="label-currency">where the local currency is</label>
                  <CustomSelect
                    options={currenciesOptions}
                    value={currenciesOptions.find((option) => option.value === props.chartSettings.selectedCurrency) || currenciesOptions.find((option) => option.value === defaultCurrency)}
                    onValueChange={(option) => {
                      if (option) {
                        if (props.dashboardEditSettings) {
                          props.dashboardEditSettings.updateChartSettings({
                            ...props.chartSettings,
                            selectedCurrency: option.value as string
                          });
                        } else {
                          props.updateChartSettings({
                            [props.insightsSettings.chartType]: {
                              ...props.chartSettings,
                              selectedCurrency: option.value as string
                            }
                          });
                        }
                      }
                    }}
                    disabled={isFetching}
                  />
                </>
              )}
            </div>
            <h2>Filters</h2>
            <label>Include docs which are</label>
            <ChartFilterToggles
              options={invoiceStates[props.section]}
              selectedOptions={props.insightsSettings.segments}
              setSelectedOptions={(values) => props.updateFilterSettings({ segments: values as InvoiceStatusSegment[] })}
            />
            <div className="insights__explore__form__filters">
              <DateFilterWrapper
                section={props.section}
                dateFilter={props.insightsSettings.dateFilter}
                setDateFilter={(dateFilter) => props.updateFilterSettings({ dateFilter })}
              />
            </div>
            <div className="insights__explore__form__filters">
              <label>and belong to</label>
              <CustomSelect
                options={dropdownAssignedToOptions}
                value={dropdownAssignedToOptions.find((option) => option.value === props.insightsSettings.assignedType)}
                onValueChange={(option) => {
                  if (option) {
                    props.updateFilterSettings({
                      assignedType: option.value as AssignedToOptions,
                      teamIds: [],
                      userIds: [],
                      mailboxIds: []
                    });
                  }
                }}
              />
              {props.insightsSettings.assignedType === AssignedToOptions.Mailbox && (
                <CustomMultiSelect
                  dropdownMenuWidth="input-width"
                  values={mailboxOptions.length ? selectedMailboxOptions : []}
                  onBlur={(selectedValues) => props.updateFilterSettings({ mailboxIds: selectedValues.map((selectedValue) => selectedValue.value) })}
                  options={mailboxOptions}
                />
              )}
              {props.insightsSettings.assignedType === AssignedToOptions.Team && (
                <CustomMultiSelect
                  dropdownMenuWidth="input-width"
                  values={teamOptions.length ? selectedTeamOptions : []}
                  onBlur={(selectedValues) => props.updateFilterSettings({ teamIds: selectedValues.map((selectedValue) => selectedValue.value) })}
                  options={teamOptions}
                />
              )}
              {props.insightsSettings.assignedType === AssignedToOptions.User && (
                <CustomMultiSelect
                  dropdownMenuWidth="input-width"
                  values={userOptions.length ? selectedUserOptions : []}
                  onBlur={(selectedValues) => props.updateFilterSettings({ userIds: selectedValues.map((selectedValue) => selectedValue.value) })}
                  options={userOptions}
                />
              )}
            </div>
            <div className="insights__explore__form__filters">
              <label>
                {props.section === SectionModule.ForwardAndClearance ? 'and related to' : 'and issued by Creditor'}
              </label>
              {props.section === SectionModule.ForwardAndClearance ? (
                <>
                  <CustomSelect
                    options={dropdownOrganisationType}
                    value={dropdownOrganisationType.find((option) => option.value === props.insightsSettings.organisationType)}
                    onValueChange={(option) => option && props.updateFilterSettings({ organisationType: option.value as OrganisationType })}
                  />
                  <CreditorsMultiselect
                    selectedValues={props.insightsSettings.creditors || []}
                    onBlur={(selectedValues) => props.updateFilterSettings({ creditors: selectedValues })}
                    chartType={props.insightsSettings.chartType}
                    section={props.section}
                  />
                </>
              ) : (
                <CreditorsMultiselect
                  selectedValues={props.insightsSettings.creditors || []}
                  onBlur={(selectedValues) => props.updateFilterSettings({ creditors: selectedValues })}
                  chartType={props.insightsSettings.chartType}
                  section={props.section}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <StandaloneContainer title={''} className={`explore-chart-container ${props.dashboardViewSettings ? 'chart-container--custom-dashboard !h-[unset]' : 'mt-[12px] pt-[13px] pr-[13px] pb-[15px] pl-[25px]'}`}>
        <div className="ap-insights__explore__chart-container">
          <div className="content">
            <div className="chart-controls">
              {props.dashboardViewSettings ? chartHeaderForCustomDashboards : (
                <>
                  <div className="chart-title">{chartTypeOptions[props.section].find((option) => option.value === props.insightsSettings.chartType)?.label}</div>
                  {showPercentageToggle && (
                    <div className="mode">
                      <div className="option-group">
                        {Object.keys(counterTypesData).map((key) => (
                          <button
                            onClick={() => {
                              if (props.dashboardEditSettings) {
                                props.dashboardEditSettings.updateChartSettings({
                                  ...props.chartSettings,
                                  counterType: parseInt(key),
                                });
                              } else {
                                props.updateChartSettings({
                                  [props.insightsSettings.chartType]: {
                                    ...props.chartSettings,
                                    counterType: parseInt(key),
                                  }
                                });
                              }
                            }}
                            className={`option ${props.chartSettings.counterType === parseInt(key) ? 'selected' : ''}`}
                          >{counterTypesData[parseInt(key)].buttonLabel}</button>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="filters">
                    <label>Group by</label>
                    <div className="aggregation-dropdown-wrapper">
                      <CustomSelect
                        className='aggregation-dropdown'
                        options={groupByOptions}
                        value={groupByOptions.find((option) => option.value === props.chartSettings.aggregation)}
                        groups={groupByGroups}
                        onValueChange={(option) => {
                          if (option) {
                            if (props.dashboardEditSettings) {
                              props.dashboardEditSettings.updateChartSettings({
                                ...props.chartSettings,
                                aggregation: option.value as number
                              });
                            } else {
                              props.updateChartSettings({
                                [props.insightsSettings.chartType]: {
                                  ...props.chartSettings,
                                  aggregation: option.value as number
                                },
                              });
                            }
                          }
                        }}
                        disabled={isFetching}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {selectedChartComponent}
          </div>
        </div>
      </StandaloneContainer>
    </div>
  )
});
