import { useDrag, useDrop } from 'react-dnd';
import { Column, SortableHeaderCell } from 'react-data-grid';
import type { HeaderRendererProps } from 'react-data-grid';
import React, { useCallback } from 'react';
import { ColumnsDetails } from '../../../api/userSettings';


export function reorderColumns(columns: Column<any>[], colDetails: ColumnsDetails[], updateWidth = false): Column<any>[] {
  let columnsOrdered = columns;
  let cols = [...colDetails];
  if (cols && cols.length > 1) {
    columnsOrdered = [];
    cols.forEach((colsOrder) => {
      const elementColumn = columns.find((c) => c.key === colsOrder.key);
      if (elementColumn) {
        if (updateWidth) elementColumn.width = colsOrder.width;
        columnsOrdered.push(elementColumn);
      }
    })
  }
  return columnsOrdered;
};

export function useCombinedRefs<T>(...refs: readonly React.Ref<T>[]) {
  return useCallback(
    (handle: T | null) => {
      for (const ref of refs) {
        if (typeof ref === 'function') {
          ref(handle);
        } else if (ref !== null) {
          // @ts-expect-error: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31065
          ref.current = handle;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    refs
  );
}

interface DraggableHeaderRendererProps<R> extends HeaderRendererProps<R> {
  onColumnsReorder: (sourceKey: string, targetKey: string) => void;
}

export function DraggableHeaderRenderer<R>({
  onColumnsReorder,
  column,
  sortDirection,
  onSort,
  priority
}: DraggableHeaderRendererProps<R>) {
  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN_DRAG',
    item: { key: column.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  });

  const [{ isOver, newPosition }, drop] = useDrop({
    accept: 'COLUMN_DRAG',
    drop({ key }: { key: string }) {
      onColumnsReorder(key, column.key);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      newPosition: monitor.getDifferenceFromInitialOffset()?.x || 0,
    })
  });

  return (
    <div
      ref={useCombinedRefs(drag, drop)}
      style={{
        opacity: isDragging ? 0.8 : 1,
        backgroundColor: isDragging ? '#00438033' : 'inherit',
        cursor: 'grab',
        borderLeftStyle: (isOver && newPosition < 0) ? 'solid' : 'unset',
        borderRightStyle: (isOver && newPosition > 0) ? 'solid' : 'unset',
        borderColor: '#0085FF',
        borderWidth: 2,
      }}
    >
      <SortableHeaderCell sortDirection={sortDirection} onSort={onSort} priority={priority}>
        {column.name}
      </SortableHeaderCell>
    </div>
  );
}
