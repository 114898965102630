import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import 'react-notion-x/src/styles.css';
import { NotionRenderer } from 'react-notion-x';
import { CompanyAPI } from '../../api/company';
import { Icon } from 'pivotal-ui/react/iconography';
import { Route, Switch } from 'react-router-dom';
import { UserSettingsContext } from '../main/Main';
import { navigateToUrl } from '../helpers';

import './knowledge-base.scss'

export const KnowledgeBaseWrapper: React.FC = () => (
  <Switch>
    <Route path="/knowledge-base/:pageId" component={KnowledgeBase}/>
    <Route path="/knowledge-base" exact component={KnowledgeBase}/>
  </Switch>
)

const HOMEPAGE_ID = '875882b876d349e8aa92f40d7bbf9f11'; // page from the knowledge base

export const KnowledgeBase: React.FC = () => {
  const [data, setData] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ pageId: string }>();
  const pageWrapper = React.useRef<HTMLDivElement>(null);

  const userSettings = React.useContext(UserSettingsContext);

  const currentPageId = params.pageId || HOMEPAGE_ID;

  React.useEffect(() => {
    userSettings.update && userSettings.update({ ...userSettings, knowledgeBasePage: location.pathname + location.hash });

  }, [location.pathname, location.hash]);

  React.useEffect(() => {
    if (location.hash) {
      const targetElement = document.getElementById(location.hash.substr(1));
      setTimeout(() => {
        targetElement && targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 300);
    } else {
      pageWrapper.current?.scrollTo(0, 0);
    }
  }, [location.hash, data]);

  React.useEffect(() => {
    setIsLoading(true);
    CompanyAPI.getDocumentationPage(currentPageId)
      .then((result) => {
        if (result) {
          setData(result);
          setIsLoading(false);
          document.addEventListener('click', onClickListener);
        } else {
          history.push('/knowledge-base');
        }
      });

    return document.removeEventListener('click', onClickListener);
  }, [currentPageId]);

  const onClickListener = (event: MouseEvent) => {
    if (event.target instanceof Element) {
      const linkElement = event.target.closest('.notion-page-link') || event.target.closest('.breadcrumb');
      const linkTableOfContent = event.target.closest('.notion-table-of-contents-item');
      if (linkElement) {
        const targetHref = linkElement.attributes.getNamedItem('href');
        if (targetHref) {
          const targetPageId = targetHref.value.substr(1); // remove the / at the beginning

          navigateToUrl(`/knowledge-base${targetPageId.includes(HOMEPAGE_ID) ? '' : '/' + targetPageId}`, history);

          event.preventDefault();
          event.stopPropagation();
          return false;
        }
      } else if (linkTableOfContent) {
        const targetHash = linkTableOfContent.attributes.getNamedItem('href');
        const pageId = targetHash && targetHash.value.substr(1);
        const targetElement = pageId ? document.getElementById(pageId) : null;

        if (targetElement && pageId) {
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          history.push({ hash: pageId });
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  }

  const mapImageUrl = (url: string, block: any): string => {
    if (data.signed_urls && data.signed_urls[block.id]) {
      const targetUrl = new URL(data.signed_urls[block.id]);
      targetUrl.searchParams.set('spaceId', block.space_id);
      return targetUrl.toString();
    }
    if (!url) {
      return '';
    }

    if (url.startsWith('data:')) {
      return url
    }

    if (url.startsWith('/images')) {
      url = `https://www.notion.so${url}`
    }

    // more recent versions of notion don't proxy unsplash images
    if (!url.startsWith('https://images.unsplash.com')) {
      url = `https://www.notion.so${
        url.startsWith('/image') ? url : `/image/${encodeURIComponent(url)}`
      }`

      const notionImageUrlV2 = new URL(url)
      let table = block.parent_table === 'space' ? 'block' : block.parent_table
      if (table === 'collection') {
        table = 'block'
      }
      notionImageUrlV2.searchParams.set('table', table)
      notionImageUrlV2.searchParams.set('id', block.id)
      notionImageUrlV2.searchParams.set('cache', 'v2')
      notionImageUrlV2.searchParams.set('spaceId', block.space_id);

      url = notionImageUrlV2.toString()
    }

    return url
  }

  const mapPageUrl = (pageId: string): string => {
    if (!pageId || !data.block[pageId]) {
      return '/';
    }

    const pageIdNormalized = pageId.replace(/-/g, '');

    let slug = '';

    if (data.block[pageId]?.value?.properties?.title) {
      // for unknown reasons the title can be either an array of strings or an array of arrays of strings
      const titles = getTitles(data.block[pageId]?.value?.properties?.title);
      slug += titles.map((title: string) => title.replace('?', '').replace(/ /g, '-')).join('-');
    }

    return `/${slug ? slug + '--' + pageIdNormalized : pageIdNormalized}`;
  }

  const getTitles = (data: any): string[] => {
    if (Array.isArray(data)) {
      return data.reduce((result, subData: any) => [...result, ...getTitles(subData)], []);
    } else {
      return [data];
    }
  }

  return (
    <div className="documentation with-custom-scrollbar" ref={pageWrapper}>
      {isLoading && (
        <div className="documentation__overlay">
          <Icon style={{ fontSize: '56px', left: 'calc(50% - 28px)', top: 'calc(50% - 28px)' }} src="spinner-md" />
        </div>
      )}
      {data && (
        <NotionRenderer recordMap={data} fullPage={true} mapImageUrl={mapImageUrl} mapPageUrl={mapPageUrl} />
      )}
    </div>
  )
}