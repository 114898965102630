import * as React from 'react';
import { FAIcon } from './FAIcon';

import './multi-email-input.scss';

export const isValidEmail = (email: string): boolean => {
  const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return regex.test(email.toLowerCase().trim());
};

interface Props {
  values: string[];
  setValues: (values: string[]) => void;
  placeholder: string;
}

export const MultiEmailInput: React.FC<Props> = (props) => {
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const ref = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [hasWarning, setHasWarning] = React.useState<boolean>(false);

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutsideOfDropdownMenu);

    return () => {
      window.removeEventListener('click', handleClickOutsideOfDropdownMenu);
    }
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === " " || e.key === 'Enter') {
      if (searchTerm) {
        if (isValidEmail(searchTerm)) {
          props.setValues([...props.values, searchTerm.trim()]);
          setSearchTerm('');
          e.preventDefault();
          return false;
        } else {
          setHasWarning(true);
          e.preventDefault();
          return false;
        }
      }
    }
    setHasWarning(false);
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const potentialEmails = event.clipboardData.getData('text').trim().split(' ');
    const filteredEmails = potentialEmails
      .map((value) => value
        .replace(/</g, '')
        .replace(/>/g, '')
        .replace(/,/g, '')
      )
      .filter((value) => isValidEmail(value));

    props.setValues([...props.values, ...filteredEmails]);
  }

  const handleClickOutsideOfDropdownMenu = React.useCallback((event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
      setSearchTerm('');
      setHasWarning(false);
    }
  }, [ref]);

  const wrapperClasses = [
    'multi-email-input__input-wrapper',
    hasWarning ? 'multi-email-input__input-wrapper--with-warning' : '',
  ];

  return (
    <div className="multi-email-input" ref={ref}>
      <div className={wrapperClasses.join(' ')} >
        {!!props.values?.length && (
          <div className="multi-email-input__menu with-custom-scrollbar">
            {props.values?.map((value, index) => (
              <div className="multi-email-input__item" title={value}>
                <span>{value}</span>
                <FAIcon name="times" solid onClick={() => props.setValues(props.values!.filter((email, i) => i !== index))} />
              </div>
            ))}
          </div>
        )}
        <input
          ref={inputRef}
          placeholder={props.placeholder}
          value={searchTerm}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {hasWarning && (
          <FAIcon name="exclamation-triangle" solid className="multi-email-input__invalid-email-warning" />
        )}
      </div>
    </div>
  )
}