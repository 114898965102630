import * as React from 'react';
import { APIComment, CommentAPI, TeamsMap, UsersMap } from '../../../api/comment';
import { Notification } from '../Notification';
import { ActivityLogWrapper } from './ActivityLogWrapper';
import { ActivityDetailsType, ActivityLogAPI, ActivityType, APIActivityLog } from '../../../api/activityLog';
import { APIValidationResultWithLog, ValidationAPI } from '../../../api/validationResults';
import { AddCommentWrapper } from './AddComment';
import { APICwPostingLogWithException } from '../../../api/cargowisePostingLog';
import { Icon } from 'pivotal-ui/react/iconography';
import { PostingBehaviour } from '../../bill-of-lading/common';
import { APIStatisticsCorrection, SupplierInvoiceAPI } from '../../../api/supplierInvoice';

import './activity-feed.scss';
import { APIDocumentGroupDetails } from '../../../api/documentGroup';
import { DropdownList, DropdownOptions } from '../DropdownList';
import { ShipamaxAssignIcon, ShipamaxChangeIcon, ShipamaxCheckIcon, ShipamaxCommentIcon, ShipamaxExclamationIcon, ShipamaxFolderOpenIcon, ShipamaxListIcon } from '../../../images/Icons';

export interface ActivityLog {
  type: ActivityType;
  data: APIComment | APIActivityLog | APICwPostingLogWithException | APIStatisticsCorrection | ActivityDetailsType;
  timestamp: string;
  userId: number | undefined;
  documentId?: number;
}

export interface ActivityFeedHandle {
  reFetchContent: () => void,
  appendLog: (newLog: Partial<APIActivityLog>) => void,
}

enum FilterType {
  All,
  Comments,
  StatusChange,
  Reassignment,
  Corrections,
  SystemErrors,
  Updates,
}

const filterItems = [
  { type: FilterType.All, label: 'All Activity' },
  { type: FilterType.StatusChange, label: 'Status change' },
  { type: FilterType.Comments, label: 'Comments' },
  { type: FilterType.Reassignment, label: 'Reassignment' },
  { type: FilterType.Updates, label: 'Document Management' },
  { type: FilterType.Corrections, label: 'Corrections' },
  { type: FilterType.SystemErrors, label: 'System errors' },
]

interface Props {
  usersMap: UsersMap;
  teamsMap: TeamsMap;
  invoiceId: number | undefined;
  documentGroupId: number | undefined;
  documentGroup?: APIDocumentGroupDetails | null;
  postingBehaviour: PostingBehaviour | undefined;
  setNotification: (notification: Notification) => void;
  isForApInvoice?: boolean;
  visible: boolean;
  companyDocTypes: { [k: number]: string };
  isNonCargowise?: boolean;
}

export const ActivityFeed = React.forwardRef((props: Props, ref: React.ForwardedRef<ActivityFeedHandle>) => {
  const [activityItems, setActivityItems] = React.useState<ActivityLog[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filterType, setFilterType] = React.useState<FilterType>(FilterType.All);
  const [filterDropdownOptions, setFilterDropdownOptions] = React.useState<DropdownOptions>([])

  const getFilterDropdownOptions = () => {
    const optionsArray: DropdownOptions | { label: string; value: FilterType; }[] = [];

    filterItems.map((item) => {
      let icon = <ShipamaxListIcon />;

      switch (item.type) {
        case FilterType.StatusChange:
          icon = <ShipamaxChangeIcon />
          break;
        case FilterType.Comments:
          icon = <ShipamaxCommentIcon />
          break;
        case FilterType.Reassignment:
          icon = <ShipamaxAssignIcon />
          break;
        case FilterType.Updates:
          icon = <ShipamaxFolderOpenIcon />
          break;
        case FilterType.Corrections:
          icon = <ShipamaxCheckIcon />
          break;
        case FilterType.SystemErrors:
          icon = <ShipamaxExclamationIcon />
          break;
      }

      optionsArray.push({ label: item.label, value: item.type, icon: icon })
    })

    setFilterDropdownOptions(optionsArray);
  };

  React.useEffect(() => {
    getFilterDropdownOptions()
  }, []);

  React.useEffect(() => {
    if (!props.visible || activityItems.length) return;
    fetchContent();
    setFilterType(FilterType.All);
  }, [props.documentGroupId, props.visible]);

  React.useEffect(() => {
    setActivityItems([]);
  }, [props.documentGroupId]);

  React.useImperativeHandle(ref, () => ({
    reFetchContent() {
      fetchContent();
    },
    appendLog(newLog: Partial<APIActivityLog>) {
      setActivityItems([{ data: newLog as APIActivityLog, type: newLog.type!, timestamp: new Date().toUTCString(), userId: newLog.userId }, ...activityItems]);
    }
  }));

  const fetchContent = async () => {
    setLoading(true);

    if (!props.documentGroupId) return;
    const promises: Promise<(APIComment | APIActivityLog | APIValidationResultWithLog | APIStatisticsCorrection)[]>[] = [];
    promises.push(CommentAPI.fetchAll(props.documentGroupId));
    promises.push(ActivityLogAPI.fetchAll(props.documentGroupId));
    promises.push(ValidationAPI.fetchValidationResultDataLogs(props.documentGroupId));
    if (props.invoiceId) {
      promises.push(SupplierInvoiceAPI.getCorrections(props.invoiceId));
    }

    const data = await Promise.all(promises)
    const result: ActivityLog[] = [];

    (data[0] as APIComment[]).forEach((comment) => {
      result.push({
        type: ActivityType.AddedComment,
        data: comment,
        timestamp: comment.created,
        userId: comment.userId,
      });
    });

    (data[1] as APIActivityLog[]).forEach((activityLog) => {
      if ([
        ActivityType.RequestedValidation,
        ActivityType.BulkRequestedValidation
      ].includes(activityLog.type)) return; // do not display these activity logs

      result.push({
        type: activityLog.type,
        data: activityLog.details,
        timestamp: activityLog.timestamp,
        userId: activityLog.userId,
        documentId: activityLog?.documentId,
      });
    });

    (data[2] as APIValidationResultWithLog[]).forEach((validationWithLogs) => {
      for (const current of validationWithLogs.logs) {
        if (current.log.success) return;

        if (current.log.response) {
          result.push({
            type: ActivityType.TMSErrorLog,
            data: current,
            timestamp: current.log.endTime,
            userId: undefined,
          });
        }

        if (current.log.request) {
          result.push({
            type: ActivityType.TMSRequestLog,
            data: current,
            timestamp: current.log.startTime,
            userId: undefined,
          });
        }
      }
    });

    if (props.invoiceId) {
      (data[3] as APIStatisticsCorrection[]).forEach((statisticsCorrection) => {

        result.push({
          type: ActivityType.FieldCorrection,
          data: statisticsCorrection,
          timestamp: statisticsCorrection.timestamp,
          userId: statisticsCorrection.userId,
        });
      });
    }

    setActivityItems(result.sort((item1, item2) => new Date(item2.timestamp).getTime() - new Date(item1.timestamp).getTime()));
    setLoading(false);
  }

  const filteredItems = React.useMemo(() => {
    switch (filterType) {
      case FilterType.Comments: return activityItems.filter((activityItem) => activityItem.type === ActivityType.AddedComment);
      case FilterType.Corrections: return activityItems.filter((activityItem) => [
        ActivityType.FieldCorrection,
        ActivityType.BulkAction,
        ActivityType.AccrualChange,
        ActivityType.ExchangeRateCorrection,
        ActivityType.JobRefTypeChange,
      ].includes(activityItem.type));
      case FilterType.SystemErrors: return activityItems.filter((activityItem) => [ActivityType.TMSRequestLog, ActivityType.TMSErrorLog].includes(activityItem.type));
      case FilterType.Reassignment: return activityItems.filter((activityItem) => activityItem.type === ActivityType.Assignment);
      case FilterType.StatusChange: return activityItems.filter((activityItem) => [
        ActivityType.StatusChange,
        ActivityType.RequestedValidation,
        ActivityType.PostedStraightThrough,
        ActivityType.FileTypeChange,
        ActivityType.Reclassification,
      ].includes(activityItem.type));
      case FilterType.Updates: return activityItems.filter((activityItem) => [
        ActivityType.SplitGroup,
        ActivityType.MergeGroup,
        ActivityType.ShipmentDeleted,
        ActivityType.ShipmentCreated,
        ActivityType.UpdatedHierarchy,
        ActivityType.UpdatedDocumentStructure,
        ActivityType.UpdatedDocumentType,
      ].includes(activityItem.type));
      case FilterType.All:
      default:
        return activityItems;
    }
  }, [activityItems, filterType]);

  return (
    <div className="activity-feed">
      {!!props.documentGroupId && (
        <AddCommentWrapper
          onCommentAdded={(newComment) => setActivityItems([{ type: ActivityType.AddedComment, data: newComment, timestamp: newComment.created, userId: newComment.userId }, ...activityItems])}
          setNotification={props.setNotification}
          invoiceId={props.invoiceId}
          documentGroupId={props.documentGroupId}
        />
      )}
      <DropdownList
        items={filterDropdownOptions}
        selectedValue={filterType || FilterType.All}
        onChange={(value) => {
          const selectedFilter = filterItems.find((item) => item.type === value)
          setFilterType(selectedFilter ? selectedFilter.type : FilterType.All)
        }}
        buttonClassName="dropdown-button active-on-hover"
        preventDefault
      />
      {loading && (
        <Icon style={{ fontSize: '40px', margin: '20% calc(50% - 20px)' }} src="spinner-md" />
      )}
      <div className="activity-feed__logs with-custom-scrollbar">
        {filteredItems.length ? (
          filteredItems.map((activityLog, index) => {
            return (
              <ActivityLogWrapper
                key={index}
                activityLog={activityLog}
                usersMap={props.usersMap}
                teamsMap={props.teamsMap}
                setNotification={props.setNotification}
                isForApInvoice={props.isForApInvoice}
                companyDocTypes={props.companyDocTypes}
                postingBehaviour={props.postingBehaviour}
                documentGroup={props.documentGroup}
                isNonCargowise={props.isNonCargowise}
              />
            );
          })
        ) : (
          <div className="no-activity">No activity for the selected filter</div>
        )}
      </div>
    </div>
  );
});
