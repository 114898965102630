import { request, addEnvironmentToReqParams } from './request';
import { Filter } from './supplierInvoice';
import { APIValidationResult } from './validationResults';
import { APIBillOfLading } from './billOfLading';
import { APIDocCoordinate, APIDocumentContainer, APITableDefinitions, MinimalHierarchy, orderCoordinatesByAreaSize } from './documentContainer';
import { PostingBehaviour, StatusCode } from '../components/bill-of-lading/common';
import { APIEmailBasic } from './email';
import { APIEmailAccount } from './emailAccount';
import { APIUser } from './comment';
import { SearchFilter } from '../components/common/document-list';

export interface APIDocumentGroupShipment extends Partial<APIBillOfLading> {
  documentId: number;
  id: number;
  container?: APIDocumentGroupContainer[];
  packLines?: APIDocumentGroupPackline[];
  children: APIDocumentGroupDocument<APIDocumentGroupCInvoice | APIDocumentGroupConsol | APIDocumentGroupShipment>[];
  other: APIDocumentContainer[];
}

export interface APIDocumentGroupConsol extends APIDocumentGroupShipment { }

export interface APIDocumentGroupSeal {
  billOfLadingId?: number;
  containerNo?: string;
  containerId?: number;
  sealNo: string;
  id?: number;
}

export interface APIDocumentGroupContainer {
  billOfLadingId?: number;
  containerNo?: string;
  containerType?: string;
  seals: APIDocumentGroupSeal[];
  id: number;
}

export interface APIDocumentGroupPackline {
  billOfLadingId?: number;
  goodsDescription?: string;
  containerNo?: string;
  hsCode?: string;
  isGoodsSegment: boolean;
  marksAndNumbers: string;
  numberPieces?: number;
  pieceType?: string;
  weight?: number;
  weightUnit?: string;
  volume?: number;
  volumeUnit?: string;
  id: number;
}

export interface APICommercialInvoiceLineItem {
  id: number;
  invoiceId: number;
  description?: string;
  matchedDescription?: string;
  quantity?: number;
  unitPrice?: number;
  lineTotal?: number;
  unitType?: string;
  matchedUnitType?: string | null;
  productCode?: string;
  matchedProductCode?: string;
  originCountry?: string;
  matchedOriginCountry?: string;
  originCountryId?: number;
  matchedOriginCountryId?: number;
  hsCode?: string;
  matchedHsCode?: string;
  matched?: boolean;
  isPartial?: boolean;
  orderIndex: number;
  originalIds?: number[];
  matchedClassification?: string;
}

export interface APIDocumentGroupCInvoice {
  id: number;
  documentId: number;
  documentType: number;
  supplier: string;
  supplierRecordId: string | null;
  importer: string;
  importerRecordId: string | null;
  invoiceNumber: string;
  invoiceDate: string;
  grossTotal: number;
  netTotal: number;
  currency: string;
  currencyId: string;
  incoTerm: string;
  lineItems: APICommercialInvoiceLineItem[];
  children: APIDocumentGroupDocument<APIDocumentGroupCInvoice | APIDocumentGroupConsol | APIDocumentGroupShipment | APIDocumentGroupPackingList>[];
  areLineItemsAggregated: boolean;
  runningLineItemsMatcher: boolean;
  supplierName: string | null;
  supplierAddress: string | null;
  importerName: string | null;
  importerAddress: string | null;

  emailAccount: APIEmailAccount;
}

export interface APIPackingListLineItem {
  id: number;
  packingListId: number;
  orderIndex: number;
  description?: string;
  marks?: string;
  itemQty?: number;
  packageQty?: number;
  netWeight?: number;
  grossWeight?: number;
  volume?: number;
  productCode?: string;
  hsCode?: string;
  matchedDescription?: string;
  matchedProductCode?: string;
  matchedHsCode?: string;
  matched?: boolean;
  isPartial?: boolean;
  // property below added for compatibility on components
  originalIds?: number[];
}

export interface APIDocumentGroupPackingList {
  id: number;
  documentId: number;
  documentType: number;
  supplier: string;
  supplierRecordId: string | null;
  importer: string;
  importerRecordId: string | null;
  supplierName: string | null;
  supplierAddress: string | null;
  importerName: string | null;
  importerAddress: string | null;
  invoiceNumber: string;
  packingListNumber: string;
  invoiceDate: string;
  containerNumber: string;
  sealNumber: string;
  grossWeightTotal: number;
  netWeightTotal: number;
  volumeTotal: number;
  weightUnit: string;
  volumeUnit: string;
  packageUnit: string;
  packageQuantityTotal: number;
  itemUnit: string;
  itemQtyTotal: number;
  lineItems: APIPackingListLineItem[];
  runningLineItemsMatcher: boolean;
  emailAccount: APIEmailAccount;
}

export interface APIAssignedUser {
  groupId: number;
  userId: number;
}

export type APIDocumentGroupDocument<T> = {
  id: number;
  documentType: number;
  billOfLading?: T;
  commercialInvoice: T;
  packingList?: T;
  children?: APIDocumentGroupDocument<T>[];
};

export interface APIDocumentGroupDetails {
  id?: number;
  created?: Date;
  documents: APIDocumentGroupDocument<APIDocumentGroupCInvoice | APIDocumentGroupConsol | APIDocumentGroupShipment | APIDocumentGroupPackingList>[];
  emails?: APIEmailBasic[];
  lastValidationResult?: APIValidationResult
  status?: number;
  tray?: number;
  teamId?: number | null;
  previousTeamId?: number | null;
  emailAccount?: APIEmailAccount;
  postingBehaviour: PostingBehaviour;

  assignedUsers: APIAssignedUser[];
}

export interface APIDocumentGroupSearchDetails {
  id?: number;
  created?: Date;
  documents: APIDocumentContainer[]
  lastValidationResult?: APIValidationResult
  // postingDetails?: PostingDetails;
  status?: number;
  tray?: number;
  teamId?: number | null;
  previousTeamId?: number | null;
  emailAccount?: APIEmailAccount;
  user?: APIUser;
  postingBehaviour: PostingBehaviour;
  lastPosted?: string,
  lastPostedBy?: string,
  lastDiscarded?: string,
  lastDiscardedBy?: string,
  assignedUser?: string,
  assignedTeam?: string,

  assignedUsers: APIAssignedUser[];
}

export interface APIDocumentGroup {
  id: number;
  created: Date;
  validationResultId: number | null;
  status: StatusCode;
  approved: boolean;
  approvedBy?: number;
  tray: number;
  teamId: number | null;
  previousTeamId: number | null;
  reassignTime: Date | null;
  postedByUser: number | null;
  discardedByUser: number | null;
  queriedByUser: number | null;
  lastValidationResult: APIValidationResult;
  ignoreLargeFiles: boolean;
  postingBehaviour: PostingBehaviour;
  lastReassigned: Date | null;

  assignedUsers: APIAssignedUser[];
}

export interface ApiFetchDocumentGroups {
  groups: APIDocumentGroupSearchDetails[],
  numberOfUnpostedGroups: number
}

export interface APIDocumentCorrectionsCount {
  documentId: number,
  corrections: number
}

export interface APIDocumentGroupIssueCode {
  id: number;
  date: Date;
  documentGroupId: number;
  issueCodeId: number;
  description: string;
  userId: number | null;
}

const fetchGroups = (filter: Filter): Promise<ApiFetchDocumentGroups> => {
  const params: string[] = [];
  addEnvironmentToReqParams(params);
  return request(`/DocumentGroups/search?filter=${encodeURIComponent(JSON.stringify(filter))}&${params.join('&')}`);
};

export const DocumentGroupAPI = {
  fetchBills(filter: Filter): Promise<ApiFetchDocumentGroups> {
    return fetchGroups(filter);
  },

  fetchGroupsForTeam(searchFilter: SearchFilter): Promise<ApiFetchDocumentGroups> {
    const filter = {
      validationTeamId: searchFilter.teamId,
      userIds: searchFilter.userIds,
      status: [StatusCode.Unposted, StatusCode.Posted, StatusCode.Reopened],
      orderDirection: 'DESC',
      limit: 250
    };
    return fetchGroups(filter);
  },

  fetchWtgIntegrationGroups(filter: Filter): Promise<ApiFetchDocumentGroups> {
    filter.validationTypes = 'wtg-integration';
    return fetchGroups(filter);
  },

  fetchWtgIntegrationGroupsForTeam(searchFilter: SearchFilter): Promise<ApiFetchDocumentGroups> {
    const filter = {
      validationTeamId: searchFilter.teamId,
      userIds: searchFilter.userIds,
      status: [StatusCode.Unposted, StatusCode.Posted, StatusCode.Reopened],
      orderDirection: 'DESC',
      limit: 150,
      validationTypes: 'wtg-integration',
    };
    return fetchGroups(filter);
  },

  async fetchDetails(groupId: number): Promise<APIDocumentGroupDetails> {
    const result = await request(`/DocumentGroups/${encodeURIComponent(groupId)}/details`);
    if (result.error?.code === 'AUTHORIZATION_REQUIRED') {
      throw Error('Not Authorized');
    }
    return result;
  },

  mergeGroups([primaryGroupId, ...other]: number[]): Promise<APIDocumentGroupDetails> {
    return request(`/DocumentGroups/${encodeURIComponent(primaryGroupId)}/merge`, 'POST', { groupIds: other }, undefined, undefined, true);
  },

  splitGroup(groupId: number, documentIds: number[]): Promise<APIDocumentGroupDetails> {
    return request(`/DocumentGroups/${encodeURIComponent(groupId)}/split`, 'POST', { documentIds });
  },

  validate(id: number, approved?: boolean): Promise<unknown> {
    let url = `/DocumentGroups/${id}/validateBillOfLading`;
    if (approved !== undefined) url += `?approved=${approved}`;
    return request(url, 'POST');
  },

  updateStatus(id: number, status: number): Promise<unknown> {
    return request(`/DocumentGroups/${id}/updateStatus`, 'POST', { newStatus: status });
  },

  update(id: number, change: Partial<APIDocumentGroup>): Promise<unknown> {
    return request(`/DocumentGroups/${id}`, 'PATCH', change);
  },

  fetchGroup(id: number): Promise<APIDocumentGroup> {
    return request(`/DocumentGroups/${id}?filter=${encodeURIComponent(JSON.stringify({ include: 'lastValidationResult' }))}`);
  },

  createBill(groupId: number): Promise<APIDocumentGroupDetails> {
    return request(`/DocumentGroups/${groupId}/createBillOfLading`, 'POST');
  },

  getDocumentsCorrections(groupId: number): Promise<APIDocumentCorrectionsCount[]> {
    return request(`/DocumentGroups/${groupId}/getDocumentsCorrections`, 'GET');
  },

  async bulkDiscard(groupIds: number[], codes: any[]) {
    return request(`/DocumentGroups/bulkDiscard`, 'PATCH', { groupIds, codes });
  },

  async bulkReassign(groupIds: number[], codes: any[], newTeamId: number | null, newUserIds: number[]) {
    return request(`/DocumentGroups/bulkReassign`, 'PATCH', { groupIds, codes, newTeamId, newUserIds });
  },

  async doesExceedBulkThreshold(groupIds: number[]) {
    return request(`/DocumentGroups/doesExceedBulkThreshold`, 'POST', { groupIds });
  },

  createGroupDiscardReason(discardReason: Partial<APIDocumentGroupIssueCode>): Promise<APIDocumentGroupIssueCode> {
    return request(`/DocumentGroupIssueCodes/`, 'POST', discardReason);
  },

  createPlaceholder(groupId: number, placeholderType: PostingBehaviour.UpdateBJob | PostingBehaviour.UpdateSJob): Promise<unknown> {
    return request(`/DocumentGroups/${groupId}/createPlaceholder?type=${placeholderType}`, 'POST');
  },

  removePlaceholder(groupId: number): Promise<unknown> {
    return request(`/DocumentGroups/${groupId}/removePlaceholder`, 'DELETE');
  },

  restoreBill(groupId: number): Promise<unknown> {
    return request(`/DocumentGroups/${groupId}/restoreBill`, 'POST');
  },

  preValidateWithoutHierarchies(groupId: number, approved = false): Promise<unknown> {
    return request(`/DocumentGroups/${groupId}/preValidateBillOfLadingWithoutHierarchy`, 'POST', { approved });
  },

  createHierarchy(groupId: number, data: MinimalHierarchy): Promise<any> {
    return request(`/DocumentGroups/${groupId}/createHierarchy`, 'POST', { data });
  },

  hasHierarchy(groupId: number): Promise<any> {
    return request(`/DocumentGroups/${groupId}/hasHierarchy`, 'GET',);
  },

  async fetchAllCoordinates(documentGroupId: number): Promise<{ [id: string]: APIDocCoordinate[] }> {
    let response: { [id: string]: APIDocCoordinate[] } = await request(`/DocumentGroups/${documentGroupId}/fetchAllCoordinates`, 'POST');

    Object.keys(response).forEach((documentId) => {
      if (Array.isArray(response[documentId])) {
        const uniqueCoordinatesMap = response[documentId].reduce((result: {}, coordinate: APIDocCoordinate) => ({
          ...result,
          [coordinate.attributeName + '-' + coordinate.parentTableId + '-' + coordinate.parentTableName]: coordinate
        }), {});

        response[documentId] = orderCoordinatesByAreaSize(Object.values(uniqueCoordinatesMap));
      }
    });
    return response;
  },

  async fetchAllTableCoordinates(documentGroupId: number): Promise<{ [id: number]: APITableDefinitions }> {
    const response: object = await request(`/DocumentGroups/${documentGroupId}/fetchAllTableCoordinates`, 'GET');

    /* During the feature development older versions had distinct version of object, this function can be used to fix it
    /* Before merging to master we should delete this code
    Object.values(response).forEach((doc: object) => {
      Object.values(doc).forEach((pag: object) => {
        Object.values(pag).forEach((coords) => {
          if (Array.isArray(coords.y[0])) {
            coords.y = coords.y.map((ybound: number[]) => {
              return {
                hasData: true,
                bounds: ybound,
              };
            })
          }
        })
      })
    });
    */
    const coordinates: { [id: number]: APITableDefinitions } = { ...response };
    return coordinates;
  },
}
