import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AdminLinks } from '../../../Admin';
import { MailboxForm } from './MailboxForm';
import { OptionValue } from 'react-selectize';
import { SiteAPI } from '../../../../../api/sites';
import { MailboxesTable } from './MailboxesTable';
import { TeamAPI } from '../../../../../api/teams';

import './index.scss';

export const ForwardingAndClearanceWorkflowSection: React.FC<{ countryOptions: OptionValue[]; }> = (props) => {
  const [teamOptions, setTeamOptions] = React.useState<OptionValue[]>([]);

  React.useEffect(() => {
    fetchReferenceDataFromAPI();
  }, []);

  const fetchReferenceDataFromAPI = async () => {
    const [teams] = await Promise.all([TeamAPI.fetchAll()]);
    setTeamOptions(teams.filter((team) => !team.disabled).map((team) => ({ label: team.name, value: team.id })));
  }

  return (
    <Switch>
      <Route path={AdminLinks.ForwardingAndClearanceWorkflows} exact component={() => (
        <MailboxesTable teamOptions={teamOptions} />
      )} />
      <Route
        path={
          [
            `${AdminLinks.ForwardingAndClearanceWorkflows}/form`,
            `${AdminLinks.ForwardingAndClearanceWorkflows}/form?env=:env`,
            `${AdminLinks.ForwardingAndClearanceWorkflows}/form/:id`,
          ]}
        exact
        component={() => (
          <MailboxForm
            teamOptions={teamOptions}
            countryCodeOptions={props.countryOptions}
          />
        )} />
    </Switch>
  )
};
