import * as React from 'react';
import * as moment from 'moment';
import { TextInput } from '../../common/TextInput';
import { WrapperLabel } from '../../common/WrapperLabel';
import { OptionValue } from 'react-selectize';
import { DateSelectEditor } from '../../common/DateSelectEditor';
import { APIDocumentGroupDocument, APIDocumentGroupPackingList } from '../../../api/documentGroup';
import { FieldChangedParams, IssuerDetailMap, JobType, PostingBehaviour } from '../common';
import { CreditorSelect } from '../../common/CreditorSelect';
import { Exception } from '../../../api/validationResults';
import { Icon } from 'pivotal-ui/react/iconography';
import { ConfirmModalProps } from '../../common/ConfirmModal';
import { APIDocumentContainer, DocumentType, findMatchedCoordinates } from '../../../api/documentContainer';
import { APIEmailBasic } from '../../../api/email';
import { placeholderTypes } from '../file-sorter/FileSorter';
import { PackingListAPI } from '../../../api/packingList';
import { LineItems, gridSelectStyle } from './LineItems';
import { formatNumber, NumberInput } from '../../common/NumberInput';
import DataGrid, { EditorProps, FormatterProps, Row as GridRow, Row } from 'react-data-grid';
import { OrganisationText } from './organisation-text/OrganisationText';
import { DocumentViewerContext } from '../../DocumentViewerContext';
import { RecordValidator } from '../../common/Form';
import { CollapsibleContainer } from '../../common/CollapsibleContainer';
import { APIError, Notification } from '../../common/Notification';
import Select from 'react-select';
import { ShipamaxDownIcon } from '../../../images/Icons';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';

import './packing-list.scss';
import { checkIfRecordValidatorHasErrors } from './ErrorHelper';

export interface PackingLineTotals {
  netWeightTotal?: number,
  grossWeightTotal?: number,
  volumeTotal?: number,
  itemQtyTotal?: number,
  packageQuantityTotal?: number,
}

export interface PackingLineTotalsRows {
  desc: string,
  linesSum: number | null,
  documentTotal: number | null;
  unit: OptionValue | null;
}

const TotalsTypes = {
  'NetWeightTotal': 'NET WEIGHT',
  'GrossWeightTotal': 'GROSS WEIGHT',
  'VolumeTotal': 'VOLUME',
  'ItemQtyTotal': 'ITEMS',
  'PackageQuantityTotal': 'PACKS',
}

const keysToCommitChange = ['Enter', 'Tab'];
const handleKeyPressOnCell = (e: any) => !['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft'].includes(e.key);
const WEIGHT_UNIT_OPTIONS = [{ value: 'kg', label: 'KG - Kilograms' }, { value: 't', label: 'T - Metric Tonnes' }, { value: 'lb', label: 'LB - Pounds' }];
const VOLUME_UNIT_OPTIONS = [{ value: 'm3', label: 'M3 - Cubic Meters' }, { value: 'cf', label: 'CF - Cubic Feets' }];
const totalFieldsIndexes = [
  'net_weight', 'gross_weight', 'volume', 'item_quantity', 'package_quantity',
];
const unitTypeFieldsIndexes = [
  'weight_unit', '-not-linked-', 'volume_unit', 'item_quantity_type', 'package_type',
];

interface Props {
  data: APIDocumentGroupPackingList;
  document: APIDocumentGroupDocument<APIDocumentGroupPackingList> | undefined;
  unitTypeOptions: OptionValue[];
  packageTypeOptions: OptionValue[];
  status?: number;
  groupId?: number;
  issuerDetails: IssuerDetailMap;
  fieldsChanged: (changes: FieldChangedParams[]) => void;
  exceptions?: Exception[];
  loading?: boolean;
  reloadGroupData: () => void;
  disabled: boolean;
  allowCloneAgents?: boolean;
  cloneFieldValue?: (senderRecordId: string | undefined, receiverFields: string[] | undefined, onlyInvoices: boolean, isOrganisation?: boolean, onlySameShipmentId?: number) => void;
  setConfirmModal: (props: ConfirmModalProps) => void;
  setActiveDocument: (document: APIDocumentContainer | APIEmailBasic | undefined, scroll?: boolean) => void;
  postingBehaviour?: PostingBehaviour;
  shipmentId?: number;
  useOrganisations: boolean;
  activeDocument?: APIDocumentContainer | APIEmailBasic | undefined;
  removeCollapsibleToggles?: boolean;
  setNotification: (notification: Notification) => void;
  enableTableCoordinates?: boolean;
}

export const PackingList: React.FC<Props> = (props: Props) => {
  const [supplierRecordId, setSupplierRecordId] = React.useState<string | null>(null);
  const [importerRecordId, setImporterRecordId] = React.useState<string | null>(null);
  const [supplierName, setSupplierName] = React.useState<string>('');
  const [supplierAddress, setSupplierAddress] = React.useState<string>('');
  const [importerName, setImporterName] = React.useState<string>('');
  const [importerAddress, setImporterAddress] = React.useState<string>('');
  const [invoiceNumber, setInvoiceNumber] = React.useState<string>('');
  const [packingListNumber, setPackingListNumber] = React.useState<string>('');
  const [invoiceDate, setInvoiceDate] = React.useState<moment.Moment | null>(null);
  const [containerNo, setContainerNo] = React.useState<string>('');
  const [sealNo, setSealNo] = React.useState<string>('');

  const gridTotalsRef = React.useRef<any>(null);
  const [loading, setLoading] = React.useState<boolean | null>(null);
  const [packingLineTotals, setPackingLineTotals] = React.useState<PackingLineTotals>();
  const [totalsRows, setTotalsRows] = React.useState<PackingLineTotalsRows[]>([]);
  const [selectedTotalsPosition, setSelectedTotalsPosition] = React.useState<{ rowIdx: number, idx: number } | null>(null);

  const supplierCgwCode = React.useMemo(() => {
    return props.issuerDetails[supplierRecordId || '']?.cgwCode || null;
  }, [props.issuerDetails, supplierRecordId]);

  const importerCgwCode = React.useMemo(() => {
    return props.issuerDetails[importerRecordId || '']?.cgwCode || null;
  }, [props.issuerDetails, importerRecordId]);

  const documentViewerContext = React.useContext(DocumentViewerContext);

  // load document group data into invoice form
  React.useEffect(() => {

    const d = props.data;

    setImporterRecordId(d.importerRecordId);
    setSupplierRecordId(d.supplierRecordId);
    setSupplierName(d.supplierName || '');
    setSupplierAddress(d.supplierAddress || '');
    setImporterName(d.importerName || '');
    setImporterAddress(d.importerAddress || '');
    setInvoiceNumber(d.invoiceNumber || '');
    setPackingListNumber(d.packingListNumber || '');
    setInvoiceDate(d.invoiceDate ? moment(d.invoiceDate) : null);
    setContainerNo(d.containerNumber);
    setSealNo(d.sealNumber);

    const totals = [
      {
        desc: TotalsTypes.NetWeightTotal,
        linesSum: d.lineItems.reduce((total, li) => total + (li.netWeight || 0), 0),
        documentTotal: props.data.netWeightTotal,
        unit: WEIGHT_UNIT_OPTIONS.find((option) => option.value === d.weightUnit) || null,
      },
      {
        desc: TotalsTypes.GrossWeightTotal,
        linesSum: d.lineItems.reduce((total, li) => total + (li.grossWeight || 0), 0),
        documentTotal: props.data.grossWeightTotal,
        unit: WEIGHT_UNIT_OPTIONS.find((option) => option.value === d.weightUnit) || null,
      },
      {
        desc: TotalsTypes.VolumeTotal,
        linesSum: d.lineItems.reduce((total, li) => total + (li.volume || 0), 0),
        documentTotal: props.data.volumeTotal,
        unit: VOLUME_UNIT_OPTIONS.find((option) => option.value === d.volumeUnit) || null,
      },
      {
        desc: TotalsTypes.ItemQtyTotal,
        linesSum: d.lineItems.reduce((total, li) => total + (li.itemQty || 0), 0),
        documentTotal: props.data.itemQtyTotal,
        unit: props.unitTypeOptions.find((option) => option.value === d.itemUnit) || null,
      },
      {
        desc: TotalsTypes.PackageQuantityTotal,
        linesSum: d.lineItems.reduce((total, li) => total + (li.packageQty || 0), 0),
        documentTotal: props.data.packageQuantityTotal,
        unit: props.packageTypeOptions.find((option) => option.value === d.packageUnit) || null,
      },
    ]
    setTotalsRows(totals);
  }, [props.data]);

  React.useEffect(() => {
    if (totalsRows.length) {
      const totalsCopy = [...totalsRows];
      const netWeightTotal = totalsCopy.find((r) => r.desc === TotalsTypes.NetWeightTotal);
      const grossWeightTotal = totalsCopy.find((r) => r.desc === TotalsTypes.GrossWeightTotal);
      const volumeTotal = totalsCopy.find((r) => r.desc === TotalsTypes.VolumeTotal);
      const itemQtyTotal = totalsCopy.find((r) => r.desc === TotalsTypes.ItemQtyTotal);
      const packageQuantityTotal = totalsCopy.find((r) => r.desc === TotalsTypes.PackageQuantityTotal);

      if (netWeightTotal) netWeightTotal.linesSum = packingLineTotals?.netWeightTotal || 0;
      if (grossWeightTotal) grossWeightTotal.linesSum = packingLineTotals?.grossWeightTotal || 0;
      if (volumeTotal) volumeTotal.linesSum = packingLineTotals?.volumeTotal || 0;
      if (itemQtyTotal) itemQtyTotal.linesSum = packingLineTotals?.itemQtyTotal || 0;
      if (packageQuantityTotal) packageQuantityTotal.linesSum = packingLineTotals?.packageQuantityTotal || 0;

      setTotalsRows(totalsCopy);
    }
  }, [packingLineTotals]);

  React.useEffect(() => {
    const firstFocusedCoordinate = documentViewerContext.focusedCoordinates.length ? documentViewerContext.focusedCoordinates[0] : undefined;
    if (firstFocusedCoordinate?.parentTableName === 'packing_list' &&
      totalFieldsIndexes.includes(firstFocusedCoordinate.attributeName) &&
      firstFocusedCoordinate.documentId === props.data.documentId) {
      const selectedAttributeIndex = totalFieldsIndexes.findIndex((f) => f === firstFocusedCoordinate.attributeName);

      if (!(selectedTotalsPosition?.rowIdx === selectedAttributeIndex &&
        selectedTotalsPosition?.idx === 2)) {
        gridTotalsRef.current.selectCell({ idx: 2, rowIdx: selectedAttributeIndex, });
        gridTotalsRef.current.element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    } else if (firstFocusedCoordinate?.parentTableName === 'packing_list' &&
      unitTypeFieldsIndexes.includes(firstFocusedCoordinate.attributeName) &&
      firstFocusedCoordinate.documentId === props.data.documentId) {
      const selectedAttributeIndex = unitTypeFieldsIndexes.findIndex((f) => f === firstFocusedCoordinate.attributeName);

      if (!(selectedTotalsPosition?.rowIdx === selectedAttributeIndex &&
        selectedTotalsPosition?.idx === 3)) {
        gridTotalsRef.current.selectCell({ idx: 3, rowIdx: selectedAttributeIndex, });
        gridTotalsRef.current.element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [documentViewerContext.focusedCoordinates]);

  const recordValidator: RecordValidator = {
    cinvNumber: {
      validators: [
        {
          errorMessage: 'Required field',
          isValid: () => !!invoiceNumber
        },
      ]
    },
  };
  const recordValidatorHasErrors = checkIfRecordValidatorHasErrors(recordValidator);

  const waitForMatchingToComplete = async (pklId: number, resolve: (value?: unknown) => void) => {
    const invoice = await PackingListAPI.fetch(pklId);

    if (invoice) {
      if (!invoice.runningLineItemsMatcher) {
        return resolve();
      } else {
        window.setTimeout(() => waitForMatchingToComplete(pklId, resolve), 2000);
      }
    } else {
      props.setNotification({ ...APIError, details: { pklId }, reason: `Error waiting for matching on PKL, request returned null` });
      return resolve();
    }
  }

  const update = async (name: string, value: string | number | null, withoutChange?: boolean) => {
    if (withoutChange) {
      return;
    }

    if ((name === 'supplierRecordId' || name === 'importerRecordId') && value) setLoading(true);

    await PackingListAPI.update(props.data.id, { [name]: value });

    if (name === 'supplierRecordId' || name === 'importerRecordId') {
      await new Promise((resolve) => waitForMatchingToComplete(props.data.id, resolve));
      props.reloadGroupData();
      setLoading(false);
    }

    if (['supplierRecordId', 'importerRecordId', 'invoiceNumber'].includes(name)) {
      props.fieldsChanged([{
        id: props.data.documentId,
        fieldName: name,
        jobType: JobType.PackingList,
        recordId: value as string | null
      }]);
    }
  }

  const onFocus = () => {
    if (props.document?.documentType && !placeholderTypes.includes(props.document?.documentType)) {
      props.setActiveDocument(props.document as unknown as APIDocumentContainer)
    }
  }

  const onSelectCell = (position: { rowIdx: number, idx: number }) => {
    if (position.rowIdx >= 0) {
      onFocus();
      setSelectedTotalsPosition(position);
      const attributeName = position.idx === 3 ? unitTypeFieldsIndexes[position.rowIdx] : totalFieldsIndexes[position.rowIdx]
      documentViewerContext.setFocusedCoordinates(findMatchedCoordinates({
        objectIds: [props.data?.id],
        tableName: 'packing_list',
        attributeNames: [attributeName],
      }, documentViewerContext.coordinates) || null);
    }
  }

  const onLineItemCellBlur = async (row: any, field: string, value: string | number | null, withoutChange?: boolean): Promise<any | undefined> => {
    let fieldToUpdate = 'weightUnit';
    switch (row.desc) {
      case TotalsTypes.NetWeightTotal:
        if (field === 'documentTotal') fieldToUpdate = 'netWeightTotal';
        break;
      case TotalsTypes.GrossWeightTotal:
        if (field === 'documentTotal') fieldToUpdate = 'grossWeightTotal';
        break;
      case TotalsTypes.VolumeTotal:
        if (field === 'documentTotal') fieldToUpdate = 'volumeTotal';
        else fieldToUpdate = 'volumeUnit'
        break;
      case TotalsTypes.ItemQtyTotal:
        if (field === 'documentTotal') fieldToUpdate = 'itemQtyTotal';
        else fieldToUpdate = 'itemUnit'
        break;
      case TotalsTypes.PackageQuantityTotal:
        if (field === 'documentTotal') fieldToUpdate = 'packageQuantityTotal';
        else fieldToUpdate = 'packageUnit'
        break;
      default:
        break;
    }
    return await update(fieldToUpdate, value, withoutChange);
  }

  const updateTotalsWeightUnit = (option: OptionValue) => {
    const totalsCopy = [...totalsRows];
    const netWeight = totalsCopy.find((r) => r.desc === TotalsTypes.NetWeightTotal);
    const grossWeight = totalsCopy.find((r) => r.desc === TotalsTypes.GrossWeightTotal);

    if (netWeight) netWeight.unit = option;
    if (grossWeight) grossWeight.unit = option;

    setTotalsRows(totalsCopy);
  }

  const columns = React.useMemo(() => {
    return [
      {
        key: 'desc',
        name: '',
        editable: false,
        formatter({ row }: FormatterProps<any>) {
          return (
            <div>
              {row.desc}
            </div>
          );
        },
      },
      {
        key: 'linesSum',
        name: 'Lines Sum',
        width: 75,
        maxWidth: 75,
        resizable: false,
        editable: false,
        editorOptions: {
          editOnClick: false,
          onNavigation: handleKeyPressOnCell,
        },
        formatter({ row }: any) {
          return <div className={`totals`}>{isNaN(row.linesSum) ? '' : formatNumber(row.linesSum, { precision: 2 })}</div>;
        },
      },
      {
        key: 'documentTotal',
        name: 'Document Totals',
        width: 100,
        maxWidth: 100,
        resizable: false,
        editable: (row: any) => { return !props.disabled },
        editorOptions: {
          editOnClick: false,
          onNavigation: handleKeyPressOnCell,
        },
        formatter({ row }: any) {
          const isAnyTotalInvalid = formatNumber(Number(row.linesSum), { precision: 2 }) !== formatNumber(Number(row.documentTotal), { precision: 2 });
          let content = <div className={`totals ${isAnyTotalInvalid ? 'warning-cell-column' : ''}`}>{isNaN(row.documentTotal) ? '' : formatNumber(row.documentTotal, { precision: 2 })}</div>;
          if (isAnyTotalInvalid) {
            content = (
              <OverlayTrigger overlay={<Tooltip>
                <div className="text-left">{`Total ${row.desc.toLowerCase()} does not match lines sum.`}</div>
              </Tooltip >} delayShow={500} placement="left" >
                {content}
              </OverlayTrigger >
            );
          }
          return content;
        },
        editor: ({ row, onRowChange, onClose }: EditorProps<any>) => {
          return <NumberInput
            value={row.documentTotal || null}
            name="documentTotal"
            precision={2}
            autoFocus={true}
            autoSelect={true}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (keysToCommitChange.includes(event.key)) (event.target as HTMLElement).blur();
            }}
            onChange={(value, field) => {
              onRowChange({ ...row, [field]: value }, false);
            }}
            onBlur={(field, value, withoutChange) => {
              onClose(!withoutChange);
              onLineItemCellBlur(row, field, value, withoutChange);
            }}
          />;
        },
      },
      {
        key: 'unit',
        name: 'Units',
        width: 52,
        minWidth: 52,
        maxWidth: 52,
        resizable: false,
        editorOptions: {
          editOnClick: false,
          onNavigation: handleKeyPressOnCell,
        },
        editable: () => { return !props.disabled; },
        formatter({ row, rowIdx }: any) {
          return (<>
            <span className={`table-text-detail`}>{row.unit?.value.toUpperCase() || ''}</span>
            <ShipamaxDownIcon
              className={`selector-icon`}
              onClick={(event: any) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            />
          </>);
        },
        editor: ({ row, rowIdx, onRowChange, editorPortalTarget }: EditorProps<PackingLineTotalsRows>) => {
          let gridStyle = gridSelectStyle(42);
          const specialGridStyle = {
            ...gridStyle,
            menuList: (provided: any) => ({
              ...provided,
              maxWidth: 190,
              minWidth: 190,
              maxHeight: 167,
            }),
            menuPortal: (provided: any) => ({
              ...provided,
              zIndex: 1000,
              width: '100%',
              minWidth: 200,
              maxWidth: 190,
              marginTop: -215,
              marginLeft: -138,
              maxHeight: 167,
            }),
          };

          let options = WEIGHT_UNIT_OPTIONS;
          switch (row.desc) {
            case TotalsTypes.VolumeTotal:
              options = VOLUME_UNIT_OPTIONS;
              break;
            case TotalsTypes.ItemQtyTotal:
              options = props.unitTypeOptions;
              gridStyle = specialGridStyle;
              break;
            case TotalsTypes.PackageQuantityTotal:
              options = props.packageTypeOptions;
              gridStyle = specialGridStyle;
              break;
            default:
              break;
          }
          return <Select
            autoFocus
            defaultMenuIsOpen
            value={options.find((option) => option.value === (row.unit?.value || null)) || undefined}
            options={options || []}
            className="grid-selector"
            onChange={async (option: any) => {
              let updatedRow = { ...row, unit: option };
              await onLineItemCellBlur(updatedRow, 'unit', option.value || null);
              onRowChange(updatedRow, true);
              if (options === WEIGHT_UNIT_OPTIONS) {
                updateTotalsWeightUnit(option);
              }
            }}
            menuPortalTarget={editorPortalTarget as HTMLElement}
            styles={gridStyle}
          />;
        }
      },
    ];
  }, [props.disabled, props.data?.lineItems, totalsRows]);

  const isActiveDocument = props.activeDocument?.id === props.document?.id;
  const isPackingListCollapsed = (PostingBehaviour.GenericTMS === props.postingBehaviour && !isActiveDocument);

  return (
    <CollapsibleContainer
      versionTwo
      disableScroll
      disabled={props.disabled}
      isCollapsedByDefault={isPackingListCollapsed}
      disableToggle={props.removeCollapsibleToggles}
      title="Packing List"
      className={`invoice packinglist ${isActiveDocument ? 'selected-card-div' : 'mt-25'}`}
      id={`card-${props.data?.documentId}`}
      preHeaderJsx={
        <div className='invoice_icons'>
          {recordValidatorHasErrors && <span className='fas fa-exclamation-triangle icon-error'></span>}
        </div>
      }
      visibleContent={(
        <div className={`grid__row ${!(props.removeCollapsibleToggles) ? 'grid__row--with-separator' : 'mb-22'}`}>
          <div className="grid__col-4">
            <WrapperLabel text="INVOICE NUMBER">
              <TextInput
                value={invoiceNumber}
                name="invoiceNumber"
                onBlur={update}
                setter={setInvoiceNumber}
                disabled={props.disabled}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'packing_list',
                  attributeNames: ['invoice_number'],
                }}
                onFocus={onFocus}
                formEntered={true}
                validators={recordValidator.cinvNumber.validators}
                dataTestId="pkl_invoice_number_input"
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-4">
            <WrapperLabel text="Packing List Number">
              <TextInput
                value={packingListNumber}
                name="packingListNumber"
                onBlur={update}
                setter={setPackingListNumber}
                disabled={props.disabled}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'packing_list',
                  attributeNames: ['packing_list_number'],
                }}
                onFocus={onFocus}
                formEntered={true}
                dataTestId="pkl_packing_list_number_input"
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-4">
            <WrapperLabel text="Invoice Date">
              <DateSelectEditor initDate={invoiceDate} disabled={props.disabled} onDateChange={(value: moment.Moment | null) => {
                setInvoiceDate(value);
                update('invoiceDate', value ? value.format('YYYY-MM-DD') : null);
              }}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'packing_list',
                  attributeNames: ['invoice_date'],
                }}
                onFocus={onFocus} />
            </WrapperLabel>
            <WrapperLabel text=""></WrapperLabel>
          </div>
        </div>
      )}
    >
      {loading &&
        <div className="overlay active">
          <Icon style={{ fontSize: '40px', margin: '60px calc(50% - 20px)' }} src="spinner-md" />
          <div className="overlay--text">
            <div>Matching product codes with selected organisations.</div>
            <div>This may take a few moments...</div>
          </div>
        </div>
      }
      <div>
        <div className="grid__row">
          {!props.useOrganisations
            ? (<>
              <OrganisationText
                label='supplier'
                fieldName='supplier'
                tableName='commercial_invoice'
                name={supplierName}
                setName={setSupplierName}
                address={supplierAddress}
                setAddress={setSupplierAddress}
                update={update}
                disabled={props.disabled}
                id={[props.data?.id || -1]}
                onFocus={onFocus}
              />
              <OrganisationText
                label='importer'
                fieldName='importer'
                tableName='commercial_invoice'
                name={importerName}
                setName={setImporterName}
                address={importerAddress}
                setAddress={setImporterAddress}
                update={update}
                disabled={props.disabled}
                id={[props.data?.id || -1]}
                onFocus={onFocus}
              />
            </>)
            :
            (<>
              <div className="grid__col-4">
                <CreditorSelect
                  label="Supplier"
                  recordId={supplierRecordId}
                  onValueChange={(option) => {
                    setSupplierRecordId(option?.value);
                    update('supplierRecordId', option?.value || null);
                  }}
                  creditorDetailsMap={props.issuerDetails}
                  showAddress={true}
                  disabled={props.disabled}
                  fieldData={{
                    objectIds: [props.data?.id || -1],
                    tableName: 'commercial_invoice',
                    attributeNames: ['supplier'],
                  }}
                  companyCode={props.data?.emailAccount?.cwCompanyCode}
                  dataTestId="supplier-input"
                  onFocus={onFocus}
                  cloneFieldValue={props.cloneFieldValue}
                  allowCloneAgents={props.allowCloneAgents}
                  copyOnlyToInvoices={true}
                  cloneToFields={['supplierRecordId']}
                  postingBehaviour={props.postingBehaviour}
                  shipmentId={props.shipmentId}
                />
              </div>
              <div className="grid__col-4">
                <CreditorSelect
                  label="Importer"
                  recordId={importerRecordId}
                  onValueChange={(option) => {
                    setImporterRecordId(option?.value);
                    update('importerRecordId', option?.value || null);
                  }}
                  creditorDetailsMap={props.issuerDetails}
                  showAddress={true}
                  disabled={props.disabled}
                  fieldData={{
                    objectIds: [props.data?.id || -1],
                    tableName: 'commercial_invoice',
                    attributeNames: ['importer'],
                  }}
                  companyCode={props.data?.emailAccount?.cwCompanyCode}
                  dataTestId="importer-input"
                  onFocus={onFocus}
                  cloneFieldValue={props.cloneFieldValue}
                  allowCloneAgents={props.allowCloneAgents}
                  copyOnlyToInvoices={true}
                  cloneToFields={['importerRecordId']}
                  postingBehaviour={props.postingBehaviour}
                  shipmentId={props.shipmentId}
                />
              </div>
            </>
            )}
          <div className="grid__col-4 flex-column">
            <div className="grid__col-4">
              <WrapperLabel text="Container No.">
                <TextInput
                  name="containerNumber"
                  value={containerNo}
                  onChange={setContainerNo}
                  onBlur={update}
                  disabled={props.disabled}
                  onFocus={onFocus}
                  fieldData={{
                    objectIds: [props.data?.id || -1],
                    tableName: 'packing_list',
                    attributeNames: ['container_number'],
                  }}
                />
              </WrapperLabel>
              <WrapperLabel text="Seal No.">
                <TextInput
                  name="sealNumber"
                  value={sealNo}
                  onChange={setSealNo}
                  onBlur={update}
                  disabled={props.disabled}
                  onFocus={onFocus}
                  fieldData={{
                    objectIds: [props.data?.id || -1],
                    tableName: 'packing_list',
                    attributeNames: ['seal_number'],
                  }}
                />
              </WrapperLabel>
            </div>
            <div className="grid__col-4" style={{ flex: 0.55 }}></div>
          </div>
        </div>
        <LineItems
          unitTypeOptions={props.unitTypeOptions}
          lineItemRows={props.data?.lineItems}
          disabled={props.disabled}
          parentId={props.data?.id!}
          parentDocumentType={DocumentType.PackingList}
          packinglineItemsTotalChanged={setPackingLineTotals}
          supplierCgwCode={supplierCgwCode}
          importerCgwCode={importerCgwCode}
          setConfirmModal={props.setConfirmModal}
          onFocus={onFocus}
          supplierName={null}
          importerName={null}
          useOrganisations={props.useOrganisations}
          enableTableCoordinates={props.enableTableCoordinates}
        />
        <div className='line-items totals-table'>
          <WrapperLabel text="TOTALS" />
          <DataGrid
            ref={gridTotalsRef}
            columns={columns}
            rows={totalsRows}
            onRowsChange={setTotalsRows}
            className="line-items-grid rdg-light small-grid with-custom-scrollbar"
            onSelectedCellChange={onSelectCell}
            rowHeight={42}
            headerRowHeight={40}
            style={{
              height: 250,
              width: 380,
              float: 'right',
              clear: 'both',
            }}
            cellNavigationMode={'CHANGE_ROW'}
          />
        </div>
      </div>
    </CollapsibleContainer>
  );
};
