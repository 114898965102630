import * as React from 'react';
import * as moment from "moment";
import DataGrid, { Column, FormatterProps, HeaderRendererProps, Row, RowRendererProps, SortColumn } from 'react-data-grid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { Filter } from '../../../api/supplierInvoice';
import { ValidationType } from '../../../api/emailAccount';
import { trimRecordId } from '../../../api/issuer';
import { APIDocumentContainer, DocumentType, APIDocumentType } from '../../../api/documentContainer';
import { APIDocumentGroupSearchDetails } from '../../../api/documentGroup';
import { UserSettingsContext } from '../../main/Main';
import { statusText, IssuerDetailMap, StatusCode, PostingBehaviour } from '../common';
import { ShipamaxCheckIcon, ShipamaxCogsIcon, ShipamaxFolderOpenIcon, ShipamaxHourGlassIcon, ShipamaxPauseIcon, ShipamaxPostIcon, ShipamaxSettingsIcon, ShipamaxSplitIcon, ShipamaxTrashIcon, ShipamaxUpIcon } from '../../../images/Icons';
import { GroupSectionMode } from '../GroupSection';
import { useHistory, useParams } from 'react-router-dom';
import { DraggableHeaderRenderer, reorderColumns } from '../../common/grid-components/DraggableHeaderRenderer';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ColumnsDetails } from '../../../api/userSettings';
import { ColumnVisibilitySelector } from '../../common/ColumnVisibilitySelector';
import { TooltipTrigger } from 'pivotal-ui/react/tooltip';

interface RowType {
  id: number,
  selected: boolean,
  status: StatusCode,
  received: Date | undefined,
  mailbox: string,
  subject: string,
  job_references: any,
  doc_numbers: any,
  documents: any,
  consignee_importer: any,
  consignor_supplier_name: any,
  consignee_importer_name: any,
  pack_type: any,
  lastPosted: string,
  lastPostedBy: string,
  lastDiscarded: string,
  lastDiscardedBy: string,
  assignedUser: string,
  assignedTeam: string,
}

interface Props {
  mode: GroupSectionMode;
  listOffset: number;
  billsOfLading: APIDocumentGroupSearchDetails[];
  documentTypes: APIDocumentType[];
  issuerDetails: IssuerDetailMap;
  // allLocations: LocationsMap;
  // seaportLocations: LocationsMap;
  lockedFetcher: boolean;
  lastFilter: Filter | null;
  defaultListLimit: number;
  hasMore: boolean;
  mergeMode: boolean;
  searchDocuments: (filter: Filter, isAutoRefresh?: boolean, clearData?: boolean) => void;
  setSelectedGroups: (groups: number[]) => void;
}

export const GroupDataGrid: React.FC<Props> = (props) => {
  const [lines, setLines] = React.useState<RowType[]>([]);
  const [selectedInTable, setSelectedInTable] = React.useState<number[]>([]);
  const [sortColumns, setSortColumns] = React.useState<readonly SortColumn[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<number>();
  const userSettings = React.useContext(UserSettingsContext);
  const [columns, setColumns] = React.useState<Column<RowType>[]>([]);
  const [columnsVisibilitySelectorEnable, setColumnsVisibilitySelectorEnable] = React.useState<boolean>(false);
  const [colDetails, setColDetails] = React.useState<ColumnsDetails[]>([...userSettings.blSettings?.colOrderAndWidth?.find((cls) => cls.section === (props.mode || GroupSectionMode.General))?.columns || []]);
  const [updatedColumnsVisibility, setUpdatedColumnsVisibility] = React.useState<boolean>(false);
  const [showBackToTop, setShowBackToTop] = React.useState<boolean>(false);
  const onSortColumnsChange = React.useCallback((sortColumns: SortColumn[]) => {
    if (sortColumns.length === 0) setSortColumns([]);
    // column flag sortable does not work, this filter ensures only the defined columns go through
    if (['received', 'status', 'pack_type', 'lastDiscarded', 'lastPosted', 'mailbox'].includes(sortColumns[0].columnKey)) {
      setSortColumns([...sortColumns]);
    }
  }, []);

  const history = useHistory();
  const { section }: {
    idView: string | undefined; section?: string
  } = useParams(); useParams();

  const HEADER_ROW_HEIGHT = 40;
  const ROW_HEIGHT = 35;
  const TABLE_HEIGHT = (lines.length * ROW_HEIGHT) + HEADER_ROW_HEIGHT + 17;

  const labelColors: any = { MBL: 'purple', HBL: 'orange', CIV: 'yellow', PKL: 'green' };
  const mapPackTypes: any = {
    ClearanceAndForwarding: 'Forwarding & Clearance',
    GenericTMS: 'Import (API)',
    Export: 'Export (API)'
  };

  const isProcessing = (tableProps: React.PropsWithChildren<FormatterProps<RowType, unknown>>) => {
    return [StatusCode.Processing, StatusCode.PendingResponse].includes(tableProps.row.status) ? 'disabled' : '';
  };

  const statusIcon = (status: StatusCode) => {
    switch (status) {
      case StatusCode.Reopened:
        return <ShipamaxSplitIcon />;
      case StatusCode.Processing:
        return <ShipamaxCogsIcon />;
      case StatusCode.PendingResponse:
        return <ShipamaxHourGlassIcon />;
      case StatusCode.Posted:
        return <ShipamaxPostIcon />;
      case StatusCode.Discarded:
        return <ShipamaxTrashIcon />;
      case StatusCode.Done:
        return <ShipamaxCheckIcon />;
      case StatusCode.Queried:
        return <ShipamaxPauseIcon />;
      default: // unposted
        return <ShipamaxFolderOpenIcon />;
    }
  }

  React.useEffect(() => {
    setColDetails([...userSettings.blSettings?.colOrderAndWidth?.find((cls) => cls.section === (props.mode || GroupSectionMode.General))?.columns || []]);
    setColumns([
      {
        key: 'status', name: 'Status', width: 50, maxWidth: 50, resizable: false, sortable: true, formatter(tableProps) {
          return <div className={`text-center ${isProcessing(tableProps)}`}>
            <label
              className={`${props.mergeMode ? 'mr-16' : 'hide'}`}
              onClick={(event: React.MouseEvent) => event.stopPropagation()}>
              <input
                type="checkbox"
                checked={tableProps.row.selected}
                onChange={() => onChangeSelect(tableProps.row.id)} />
              <span className="checkmark" />
            </label>
            <OverlayTrigger
              overlay={<Tooltip>{statusText[tableProps.row.status].toUpperCase()}</Tooltip>}
              placement="right"
              delayShow={500}
            >
              <span className='status-icon'>{statusIcon(tableProps.row.status)}</span>
            </OverlayTrigger>
          </div >
        }
      },
      {
        key: 'received', name: 'Received Date', width: 140, resizable: false, sortable: true, formatter(tableProps) {
          return <div className={isProcessing(tableProps)}>
            {moment(tableProps.row.received).format('HH:mm:ss, DD MMM YYYY')}
          </div>;
        }
      },
      {
        key: 'lastDiscarded', name: 'Discarded Date', sortable: true, width: 140, resizable: false, formatter(tableProps: any) {
          return <div className={isProcessing(tableProps)}>
            {moment(tableProps.row.lastDiscarded).format('HH:mm:ss, DD MMM YYYY')}
          </div>;
        }
      },
      {
        key: 'lastDiscardedBy', name: 'Discarded by', width: 110, resizable: true, formatter(tableProps: any) {
          return <div className={`text-center ${isProcessing(tableProps)}`}>{tableProps.row.lastDiscardedBy}</div>
        }
      },
      {
        key: 'lastPosted', name: 'Posted Date', sortable: true, width: 140, resizable: false, formatter(tableProps: any) {
          return <div className={isProcessing(tableProps)}>
            {moment(tableProps.row.lastPosted).format('HH:mm:ss, DD MMM YYYY')}
          </div>;
        }
      },
      {
        key: 'lastPostedBy', name: 'Posted by', width: 110, resizable: true, formatter(tableProps: any) {
          return <div className={`text-center ${isProcessing(tableProps)}`}>{tableProps.row.lastPostedBy}</div>
        }
      },
      ...(props.mode === GroupSectionMode.WtgIntegration ? [] : [{
        key: 'mailbox', name: 'Mailbox', sortable: true, width: 120, formatter(tableProps: any) {
          return <div className={isProcessing(tableProps)}>{tableProps.row.mailbox}</div>;
        }
      }]),
      ...(props.mode === GroupSectionMode.WtgIntegration ? [] : [{
        key: 'subject', name: 'Email Subject', minWidth: 110, width: 110, formatter(tableProps: any) {
          return <OverlayTrigger placement='bottom' overlay={<Tooltip size='md'>{tableProps.row.subject}</Tooltip>} delayShow={500}>
            <div className={isProcessing(tableProps)}>{tableProps.row.subject}</div>
          </OverlayTrigger>;
        }
      }]),
      ...(props.mode === GroupSectionMode.WtgIntegration ? [] : [{
        key: 'job_references', name: 'Job References', minWidth: 110, width: 150, formatter(tableProps: any) {
          return <OverlayTrigger placement='bottom' overlay={<Tooltip size='md'>{tableProps.row.job_references.join(', ')}</Tooltip>} delayShow={500}>
            <div className={`label-ellipsis ${isProcessing(tableProps)}`}>
              {tableProps.row.job_references.map((jr: string, idx: number) => <span key={`${jr}-${idx}`} className='label label-grey'>{jr}</span>)}
            </div>
          </OverlayTrigger>
        }
      }]), {
        key: 'doc_numbers', name: (props.mode === GroupSectionMode.WtgIntegration ? 'Document Numbers' : 'BoL Numbers'), minWidth: 110,
        width: props.mode === GroupSectionMode.WtgIntegration ? null : 150,
        formatter(tableProps: any) {
          return <OverlayTrigger placement='bottom' overlay={<Tooltip size='md'>{tableProps.row.doc_numbers.join(', ')}</Tooltip>} delayShow={500}>
            <div className={`label-ellipsis ${isProcessing(tableProps)}`}>
              {tableProps.row.doc_numbers.map((bn: string, idx: number) => <span key={`${bn}-${idx}`} className='label label-grey'>{bn}</span>)}
            </div>
          </OverlayTrigger>
        }
      },
      {
        key: 'documents', name: 'Documents', minWidth: 90, width: 150, formatter(tableProps) {
          return <OverlayTrigger placement='bottom' overlay={<Tooltip size='md'>
            {tableProps.row.documents.map((d: any) => { return d.count > 1 ? `${d.count}:${d.type}` : d.type }).join(', ')}
          </Tooltip>} delayShow={500}>
            <div className={`label-ellipsis ${isProcessing(tableProps)}`}>
              {tableProps.row.documents.map((doc: any) => {
                return <span key={doc.type} className={`label label-${doc.color} ${doc.count > 1 ? 'pl-15' : ''}`}>
                  {doc.count > 1 && <span className='count'>{doc.count}</span>}
                  <span>{doc.type}</span>
                </span>
              })}
            </div>
          </OverlayTrigger>
        }
      },
      ...(props.mode !== GroupSectionMode.General ? [] : [{
        key: 'consignee_importer', name: 'Consignee / Importer', width: null, minWidth: 140, formatter(tableProps: any) {
          return <OverlayTrigger placement='bottom' overlay={<Tooltip size='md'>{tableProps.row.consignee_importer.join(', ')}</Tooltip>} delayShow={500}>
            <div className={`label-ellipsis ${isProcessing(tableProps)}`}>
              {tableProps.row.consignee_importer.map((ci: string, idx: number) => <span key={`${ci}-${idx}`} className='label label-grey'>{ci}</span>)}
            </div>
          </OverlayTrigger>
        }
      }]),
      ...(props.mode !== GroupSectionMode.WtgIntegration ? [] : [{
        key: 'consignor_supplier', name: 'Consignor / Supplier', width: null, minWidth: 140, formatter(tableProps: any) {
          return <OverlayTrigger placement='bottom' overlay={<Tooltip size='md'>{tableProps.row.consignor_supplier_name.join(', ')}</Tooltip>} delayShow={500}>
            <div className={`label-ellipsis ${isProcessing(tableProps)}`}>
              {tableProps.row.consignor_supplier_name.map((cs: string, idx: number) => <span key={`cs-${idx}`} className='label label-grey'>{cs}</span>)}
            </div>
          </OverlayTrigger>
        }
      }]),
      ...(props.mode !== GroupSectionMode.WtgIntegration ? [] : [{
        key: 'consignee_importer', name: 'Consignee / Importer', width: 150, minWidth: 140, formatter(tableProps: any) {
          return <OverlayTrigger placement='bottom' overlay={<Tooltip size='md'>{tableProps.row.consignee_importer_name.join(', ')}</Tooltip>} delayShow={500}>
            <div className={`label-ellipsis ${isProcessing(tableProps)}`}>
              {tableProps.row.consignee_importer_name.map((ci: string, idx: number) => <span key={`cs-${idx}`} className='label label-grey'>{ci}</span>)}
            </div>
          </OverlayTrigger>
        }
      }]),
      {
        key: 'group_id',
        name: 'Pack ID',
        width: 50,
        resizable: true,
        sortable: false,
        cellClass: 'defaultHidden',
        formatter(tableProps: any) {
          return <div className={`text-center ${isProcessing(tableProps)}`}>{tableProps.row.id}</div>
        }
      },
      {
        key: 'assignedUser',
        name: 'User',
        width: 90,
        resizable: true,
        sortable: false,
        cellClass: 'defaultHidden',
        formatter(tableProps: any) {
          return <div className={`text-center ${isProcessing(tableProps)}`}>{tableProps.row.assignedUser}</div>
        }
      },
      {
        key: 'assignedTeam',
        name: 'Team',
        width: 80,
        resizable: true,
        sortable: false,
        cellClass: 'defaultHidden',
        formatter(tableProps: any) {
          return <div className={`text-center ${isProcessing(tableProps)}`}>{tableProps.row.assignedTeam}</div>
        }
      },
      ...(props.mode === GroupSectionMode.WtgIntegration ? [] : [{
        key: 'pack_type',
        name: 'Use Case',
        width: 150,
        resizable: false,
        sortable: true,
        formatter(tableProps: any) {
          return <div className={`text-center ${isProcessing(tableProps)}`}>{tableProps.row.pack_type}</div>
        }
      }]),
      {
        key: 'columnVisibility',
        name: (<div className="columns-selector-icon" onClick={() => setColumnsVisibilitySelectorEnable(true)} >
          <ShipamaxSettingsIcon />
        </div>),
        width: 40,
        maxWidth: 40,
        resizable: false,
        formatter({ row }: any) { return <></>; },
        editable: false,
      },
    ]);
  }, [props.mode, props.mergeMode, selectedInTable]);

  const getConsigneesAndImporters = (documents: APIDocumentContainer[], postingBehaviour: PostingBehaviour) => {
    let recordsConsol: string[] = [];
    let recordsShipment: string[] = [];
    let recordsCinv: string[] = [];
    documents.forEach((d) => {
      // [SHIP-10656] Filter out consignees of group.postingBehaviour === update b or s job AND doc is a BL
      if (d.billOfLading
        && d.billOfLading.consigneeCgwRecordId
        && ![PostingBehaviour.UpdateBJob, PostingBehaviour.UpdateSJob].includes(postingBehaviour)) {
        const ccri = props.issuerDetails[trimRecordId(d.billOfLading.consigneeCgwRecordId || '')];
        if (ccri && ccri.name) {
          if ([DocumentType.PlaceholderConsol, DocumentType.MasterBillOfLading].includes(d.documentType)) {
            recordsConsol.push(ccri.name);
          } else if ([DocumentType.PlaceholderShipment, DocumentType.HouseBillOfLading].includes(d.documentType)) {
            recordsShipment.push(ccri.name);
          }
        }
      }
      if (d.commercialInvoice && d.commercialInvoice.importerRecordId) {
        const iri = props.issuerDetails[trimRecordId(d.commercialInvoice.importerRecordId || '')];
        if (iri && iri.name) recordsCinv.push(iri.name);
      }
    });

    // Order ((master)consol>(house)shipment>cinv)
    return [...new Set([...recordsConsol, ...recordsShipment, ...recordsCinv])];
  };

  const getConsignorsAndSuppliersName = (documents: APIDocumentContainer[], postingBehaviour: PostingBehaviour) => {
    let recordsBls: string[] = [];
    let recordsCinv: string[] = [];
    documents.forEach((d) => {
      if (d.billOfLading && d.billOfLading.shipperName) {
        recordsBls.push(d.billOfLading.shipperName);
      }
      if (d.commercialInvoice && d.commercialInvoice.supplierName) {
        recordsCinv.push(d.commercialInvoice.supplierName);
      }
    });

    return [...new Set([...recordsBls, ...recordsCinv])];
  };

  const getConsigneesAndImportersName = (documents: APIDocumentContainer[], postingBehaviour: PostingBehaviour) => {
    let recordsBls: string[] = [];
    let recordsCinv: string[] = [];
    documents.forEach((d) => {
      if (d.billOfLading && d.billOfLading.consigneeName) {
        recordsBls.push(d.billOfLading.consigneeName);
      }
      if (d.commercialInvoice && d.commercialInvoice.importerName) {
        recordsCinv.push(d.commercialInvoice.importerName);
      }
    });

    return [...new Set([...recordsBls, ...recordsCinv])];
  };

  const getDocumentsPerType = (documents: APIDocumentContainer[]) => {
    let docTypesObj: any = {};
    documents.map((d) => d.documentType).forEach((n) => {
      const found = props.documentTypes.find((dt) => dt.id === n);
      // Checked with PM. We should not show non-physical docs (i.e. 12, 13, 14)
      if (found && found.cwCode) {
        const cwCode = found.cwCode;
        docTypesObj[cwCode] = docTypesObj[cwCode] ? docTypesObj[cwCode] + 1 : 1;
      }
    });

    // Sort full object by value and alphabetically (by converting obj into array, sort, and back to an obj)
    docTypesObj = Object.entries(docTypesObj)
      .sort(([codeA, countA], [codeB, countB]) =>
        (countB as number) - (countA as number) || (((codeA) < (codeB)) ? -1 : 1))
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    // Sort Documents -> MBL > HBL > CIV > PKL
    let docTypesArray: Object[] = [];
    if (docTypesObj.MBL) docTypesArray.push({ MBL: docTypesObj.MBL });
    if (docTypesObj.HBL) docTypesArray.push({ HBL: docTypesObj.HBL });
    if (docTypesObj.CIV) docTypesArray.push({ CIV: docTypesObj.CIV });
    if (docTypesObj.PKL) docTypesArray.push({ PKL: docTypesObj.PKL });
    // Add all the rest (already sorted above)
    for (const [key, value] of Object.entries(docTypesObj)) {
      if (!['MBL', 'HBL', 'CIV', 'PKL', 'MSC'].includes(key)) {
        let obj: any = {};
        obj[key] = value;
        docTypesArray.push(obj);
      }
    }
    // Sort Documents -> MSC in the end
    if (docTypesObj.MSC) docTypesArray.push({ MSC: docTypesObj.MSC });

    // Prep array to render
    const docsToRender: any[] = [];
    docTypesArray.forEach((docType) => {
      const entry = Object.entries(docType)[0];
      const [key, value] = [entry[0], entry[1]];
      docsToRender.push({
        count: value,
        type: key,
        color: labelColors[key] || 'grey',
      });
    });
    return docsToRender;
  }

  const getEmailValidationType = (documents: APIDocumentContainer[]) => {
    const emailVtype = [...new Set(
      documents.map((d) => !d.email ? '' : (d.email as any).emailAccount.validationType)
    )];
    return (emailVtype.length === 1 || emailVtype[0] in ValidationType)
      ? mapPackTypes[ValidationType[emailVtype[0]]]
        ? mapPackTypes[ValidationType[emailVtype[0]]]
        : ValidationType[emailVtype[0]]
      : 'Invalid mailbox validation type.';
  }

  const getJobReferencesArray = (documents: APIDocumentContainer[]) => {
    const cRef: string[] = [];
    const sAndbRef: string[] = [];
    documents.forEach((d) => {
      if (d.billOfLading && d.billOfLading.importerReference && d.billOfLading.importerReference.length !== 0) {
        d.billOfLading.importerReference.forEach((ir) => {
          if (ir.jobRef) {
            if (ir.isConsol) {
              cRef.push(ir.jobRef);
            } else {
              sAndbRef.push(ir.jobRef);
            }
          }
        });
      }
    });
    // sort (Job reference - C-ref, S-ref, B-ref)
    return [...cRef, ...sAndbRef];
  }

  const getDocumentNumbers = (documents: APIDocumentContainer[], postingBehaviour: PostingBehaviour) => {
    const validationType = getEmailValidationType(documents);
    const numbers: string[] = [];
    documents.sort((a, b) => a.documentType - b.documentType).map((d) => {
      if (d.billOfLading && d.billOfLading.billOfLadingNo &&
        // SHIP-11081 don't show BL numbers for clearance unlesss it's a new bjob
        (validationType !== 'Clearance' || postingBehaviour === PostingBehaviour.NewBJob)) {
        numbers.push(d.billOfLading.billOfLadingNo);
      } else if (props.mode === GroupSectionMode.WtgIntegration && d.commercialInvoice && d.commercialInvoice.invoiceNumber) {
        numbers.push(d.commercialInvoice.invoiceNumber);
      } else if (props.mode === GroupSectionMode.WtgIntegration && d.packingList && d.packingList.invoiceNumber) {
        numbers.push(d.packingList.invoiceNumber);
      }
    });
    return numbers;
  }

  const onChangeSelect = (rowId: number) => {
    let copySit = Array.from(selectedInTable);
    let foundIndex = copySit.indexOf(rowId);
    (foundIndex < 0)
      ? copySit.push(rowId)
      : copySit.splice(foundIndex, 1);

    setSelectedInTable(copySit);
    props.setSelectedGroups(copySit);
  };

  React.useEffect(() => {
    if (!props.mergeMode) setSelectedInTable([]);
  }, [props.mergeMode]);

  React.useEffect(() => {
    const lines: RowType[] = props.billsOfLading.map((group) => {
      return {
        id: group.id!,
        selected: Boolean(selectedInTable.find((s) => s === group.id)),
        status: group.status!,
        received: group.created,
        mailbox: [...(new Set(group.documents.map((d) => d.email?.sender).filter((s) => s)))].join(', '),
        subject: [...(new Set(group.documents.map((d) => d.email?.subject).filter((s) => s)))].join(', '),
        job_references: getJobReferencesArray(group.documents),
        doc_numbers: getDocumentNumbers(group.documents, group.postingBehaviour),
        documents: getDocumentsPerType(group.documents),
        consignee_importer: getConsigneesAndImporters(group.documents, group.postingBehaviour),
        consignor_supplier_name: getConsignorsAndSuppliersName(group.documents, group.postingBehaviour),
        consignee_importer_name: getConsigneesAndImportersName(group.documents, group.postingBehaviour),
        pack_type: getEmailValidationType(group.documents),
        lastPosted: group.lastPosted || '',
        lastPostedBy: group.lastPostedBy || '',
        lastDiscarded: group.lastDiscarded || '',
        lastDiscardedBy: group.lastDiscardedBy || '',
        assignedTeam: group.assignedTeam || '',
        assignedUser: group.assignedUser || '',
      };
    });

    setLines(lines);
  }, [selectedInTable, props.billsOfLading, props.issuerDetails/*, props.allLocations, props.seaportLocations*/]);

  const fetchData = () => {
    if (!props.lockedFetcher) {
      const filter: Filter = { ...props.lastFilter };
      filter.limit = props.defaultListLimit;
      if (props.billsOfLading.length) {
        filter.offset = props.billsOfLading.length;
      }
      props.searchDocuments(filter, undefined, false);
    }
  };

  React.useEffect(() => {
    if (sortColumns.length === 0) return;
    const filter: Filter = { ...props.lastFilter };
    // Despite react-data-grid accepting multiple sort, our API doesn't yet.
    switch (sortColumns[0].columnKey) {
      case 'received':
        filter.orderBy = 'date';
        break;
      case 'status':
        filter.orderBy = 'status';
        break;
      case 'pack_type':
        filter.orderBy = 'validationType';
        break;
      case 'mailbox':
        filter.orderBy = 'sender';
        break;
      case 'lastPosted':
        filter.orderBy = 'lastPosted';
        break;
      case 'lastDiscarded':
        filter.orderBy = 'lastDiscarded';
        break;
    }
    filter.orderDirection = sortColumns[0].direction;
    filter.offset = null;
    props.searchDocuments(filter, false, true);
  }, [sortColumns]);

  React.useEffect(() => {
    const settings = { ...userSettings };
    let cols = settings.blSettings?.colOrderAndWidth?.find((cls) => cls.section === (props.mode || GroupSectionMode.General));
    if (!cols || !cols.columns) {
      cols = {
        section: props.mode,
        columns: colDetails,
      }
      if (settings.blSettings && settings.blSettings.colOrderAndWidth && settings.blSettings.colOrderAndWidth.length > 0) {
        settings.blSettings.colOrderAndWidth.push(cols);
      } else if (settings.blSettings) {
        settings.blSettings.colOrderAndWidth = [cols];
      }
    } else if (colDetails.length) {
      cols.columns = colDetails;
    }
    if (settings.update) settings.update(settings);
  }, [colDetails]);

  const updateUserColWidthsSettings = (colIndex: number, width: number) => {
    const cols = [...colDetails];
    cols[colIndex].width = width;
    setColDetails(cols);
  }

  const updateUserColHiddenSettings = (colKey: string, hidden: boolean) => {
    const colDetailsCopy = [...colDetails];
    const checkedCol = colDetailsCopy.find((c) => c.key === colKey);
    if (checkedCol) checkedCol.hidden = hidden;
    setColDetails(colDetailsCopy);
    setUpdatedColumnsVisibility(!updatedColumnsVisibility);
  }

  const backToTop = () => {
    const topElement = document.querySelector('.infinite-scroll-component .rdg-row-even');
    if (topElement) topElement.scrollIntoView({ behavior: "smooth" });
  }

  const RowRenderer = React.useMemo(() => {
    return (rowProps: RowRendererProps<RowType>) => {
      return (
        <Row
          {...rowProps}
          className={`${rowProps.className} ${rowProps.row.id === selectedRows ? 'row-clicked' : ''}`}
        />
      );
    }
  }, [selectedRows]);

  const draggableColumns = React.useMemo(() => {
    const HeaderRenderer = (props: HeaderRendererProps<RowType>) => {
      return <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder} />;
    }

    const handleColumnsReorder = (sourceKey: string, targetKey: string) => {
      const reorderedColumns = reorderColumns([...columns], [...colDetails]);
      const sourceColumnIndex = reorderedColumns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = reorderedColumns.findIndex((c) => c.key === targetKey);

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      let cols = [...colDetails];
      if (cols) {
        cols = reorderedColumns.map((c) => {
          return {
            key: c.key,
            width: c.width || null,
            hidden: false,
          }
        });
        setColDetails(cols);
      }

      setColumns(reorderedColumns);
    }

    let columnsOrdered = reorderColumns([...columns], [...colDetails], true);

    if (colDetails && colDetails.length <= 1 || (columns.length !== colDetails.length)) {
      // in case there's not colDetails defined or the number of columns in the table has changed, let's define it from scratch
      const newColsDetails = columns.map((c) => {
        return {
          key: c.key,
          width: c.width || null,
          hidden: c.cellClass === 'defaultHidden' ? true : false,
        };
      });
      setColDetails(newColsDetails);
    }

    const returnCols = columnsOrdered.filter((c) => {
      // Remove hidden columns
      if (colDetails && colDetails.some((detail) => detail.key === c.key && detail.hidden)) {
        return false;
      }

      // Following fields are only displayed when specific filter is selected
      const statusFilter = Number(props.lastFilter?.status);
      if (['lastPosted', 'lastPostedBy'].includes(c.key)) {
        return [StatusCode.Posted, StatusCode.Done].includes(statusFilter);
      } else if (['lastDiscarded', 'lastDiscardedBy'].includes(c.key)) {
        return statusFilter === StatusCode.Discarded;
      }

      return true;
    }).map((c) => {
      if (c.key === 'id') return c;
      return { ...c, headerRenderer: HeaderRenderer };
    });

    return returnCols;
  }, [columns, props.lastFilter, updatedColumnsVisibility]);

  const handleScroll = (e: any) => {
    const topElement = document.querySelector('.infinite-scroll-component .rdg-row-even');
    const rect = topElement?.getBoundingClientRect();

    if (rect && rect.top < 0) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  }

  return <>
    <ColumnVisibilitySelector
      modalCssClass='fixed right-0 z-50 pack-list-top'
      colDetails={colDetails}
      columns={columns}
      visible={columnsVisibilitySelectorEnable}
      setVisible={setColumnsVisibilitySelectorEnable}
      updateUserColHiddenSettings={updateUserColHiddenSettings}
      listFilter={props.lastFilter}
    />
    <DndProvider backend={HTML5Backend}>
      <InfiniteScroll
        dataLength={lines.length}
        next={fetchData}
        hasMore={props.hasMore}
        scrollThreshold={0.9}
        scrollableTarget="scrollable-cards"
        loader={<></>}
        onScroll={(e: any) => handleScroll(e)}>
        <DataGrid
          columns={draggableColumns}
          rows={lines}
          className="rdg-light"
          style={{ height: `${TABLE_HEIGHT}px` }}
          defaultColumnOptions={{ resizable: true }}
          sortColumns={sortColumns}
          onSortColumnsChange={onSortColumnsChange}
          headerRowHeight={HEADER_ROW_HEIGHT}
          rowHeight={ROW_HEIGHT}
          onColumnResize={(colIndex: number, width: number) => updateUserColWidthsSettings(colIndex, width)}
          rowRenderer={RowRenderer}
          enableVirtualization={false}
          onRowClick={(idx, row) => {
            setSelectedRows((row as any).id);
            return [StatusCode.Processing, StatusCode.PendingResponse].includes(row.status) ? () => { } : history.push(`/${section}/${(row as any).id}`);
          }}
        />
        {showBackToTop && (
          <div className='back-to-top-button' onClick={backToTop}>
            <TooltipTrigger tooltip="Back to top" placement="left">
              <ShipamaxUpIcon />
            </TooltipTrigger>
          </div>
        )}
      </InfiniteScroll>
    </DndProvider>
  </>
};
