import * as React from 'react';
import {
  Aggregation,
  ChartType,
  InvoiceStatusSegment,
  SectionModule,
  chartTypeOptions,
} from './common';
import { UserSettingsContext } from '../main/Main';
import {
  ChartsUserSettings,
  ChartUserSettings,
  defaultInsightsFilterSettings,
  InsightsFilterSettings,
} from '../../api/userSettings';
import { Notification } from '../common/Notification';
import { APIPermissionProfile } from '../../api/permissionProfiles';
import { aggregationsOptions, ChartWrapper, CounterType } from './ChartWrapper';
import { PermissionLevel } from '../../api/authentication';
import { APICustomDashboard } from '../../api/customDashboard';

import './explore.scss';

const isSelectedAggregationValid = (selectedAggregation: Aggregation | undefined, aggregationsOptions: any[]) => {
  if (!selectedAggregation) return false;
  let found = false;
  aggregationsOptions.forEach((item) => {
    if (typeof item === 'object') {
      if (item.items.includes(selectedAggregation)) found = true;
    } else {
      if (item === selectedAggregation) found = true;
    }
  });
  return found;
}

interface Props {
  setNotification: (notification: Notification | null) => void;
  section: SectionModule;
  permissionProfile: APIPermissionProfile | null;
  permissionLevel: PermissionLevel;
  customDashboards: APICustomDashboard[];
}

export const Explore: React.FC<Props> = (props) => {
  const userSettings = React.useContext(UserSettingsContext);
  const insightsSettings: InsightsFilterSettings = React.useMemo(() => {
    let userInsigthSettings: InsightsFilterSettings | undefined;
    if (userSettings.insightsSettings) {
      userInsigthSettings = { ...userSettings.insightsSettings };
      userInsigthSettings.segments = userInsigthSettings.segments?.filter((s: InvoiceStatusSegment) => {
        if (props.section === SectionModule.APInvoice) {
          return ![InvoiceStatusSegment.PostedAfterCorrection].includes(s);
        }
        return ![InvoiceStatusSegment.Queried, InvoiceStatusSegment.PostedAfterExceptions].includes(s);
      })
      if (!chartTypeOptions[props.section].some((t) => t.value === userInsigthSettings?.chartType)) {
        userInsigthSettings.chartType = defaultInsightsFilterSettings[props.section].chartType;
      }
    }
    return {
      ...defaultInsightsFilterSettings[props.section],
      ...(userInsigthSettings || userSettings.insightsSettings)
    };
  }, [props.section, defaultInsightsFilterSettings[props.section], JSON.stringify(userSettings.insightsSettings)]);

  let chartSettings: ChartUserSettings;

  if (userSettings.insightsChartSettings && userSettings.insightsChartSettings[insightsSettings.chartType] &&
    isSelectedAggregationValid(userSettings.insightsChartSettings[insightsSettings.chartType]?.aggregation, aggregationsOptions[props.section])) {

    chartSettings = userSettings.insightsChartSettings[insightsSettings.chartType];
  } else {
    chartSettings = {
      aggregation: Aggregation.WeekReceived,
      counterType: (insightsSettings.chartType === ChartType.LineCorrections) ? CounterType.LineCount : CounterType.InvoiceCount
    };
    if (props.section === SectionModule.ForwardAndClearance && insightsSettings.chartType === ChartType.Corrections)
      chartSettings.aggregation = Aggregation.Importer;
  }

  const updateFilterSettings = (update: Partial<InsightsFilterSettings>) => {
    userSettings.update && userSettings.update({
      ...userSettings,
      insightsSettings: {
        ...defaultInsightsFilterSettings[props.section],
        ...userSettings.insightsSettings,
        ...update
      }
    });
  }

  const updateChartSettings = (update: ChartsUserSettings) => {
    userSettings.update && userSettings.update({
      ...userSettings,
      insightsChartSettings: {
        ...userSettings.insightsChartSettings,
        ...update
      }
    });
  }

  return (
    <ChartWrapper
      setNotification={props.setNotification}
      section={props.section}
      permissionProfile={props.permissionProfile}
      permissionLevel={props.permissionLevel}
      insightsSettings={insightsSettings}
      chartSettings={chartSettings}
      updateFilterSettings={updateFilterSettings}
      updateChartSettings={updateChartSettings}
      customDashboards={props.customDashboards}
      renderAddToButton={true}
      defaultToggles={true}
    />
  )
}
