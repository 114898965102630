import { request } from './request';
import { JobRef, JobRefType } from '../components/ap-invoice/document-action/Clusters';
import { getJobRefType, getJobRefValue } from '../components/helpers';
import { APIJobRef } from './supplierInvoiceJobRef';
import { APIEmailAccount, CNValidationType, TMSType } from './emailAccount';
import { AggregatedTMSRef } from '../components/ap-invoice/document-action/AggregatedJobReference';

export enum ClusterStatus {
  Warning = 'warning',
  Failed = 'error',
  Matched = 'success',
}

export interface APIClusterTotal {
  id: number;
  invoiceId: number;
  total: number | null;
  isHeader: boolean;
  vatTotal: number | null;
  glCode?: string | null;
  description?: string | null;
  taxCode?: string | null;
  orderIndex: number | null;
  branch?: string | null;
  departmentCode?: string | null;
  jobRef?: JobRef[];
  isAggregated: boolean,
  parentClusterId?: number | null;
}

export interface Cluster extends APIClusterTotal {
  status?: ClusterStatus;
  aggregatedTMSRefs: AggregatedTMSRef[];
}

export const aggregate = (jobRefs: JobRef[], cnValidationType: CNValidationType): AggregatedTMSRef[] => {
  const aggregatedTMSRefsMap = new Map<string, AggregatedTMSRef>();

  jobRefs.forEach((jobRef) => {
    const ignoreServiceDates = jobRef.type !== JobRefType.CN || cnValidationType !== CNValidationType.WithServiceDate;

    const keyForDocumentRefAggregation = [
      getJobRefValue(jobRef),
      jobRef.type,
      jobRef.jobRef,
      ignoreServiceDates ? undefined : jobRef.serviceStartDate || jobRef.id, // if the service dates are empty, don't aggregate
      ignoreServiceDates ? undefined : jobRef.serviceEndDate || jobRef.id,
    ].join('-');

    const keyForTMSRefAggregation = jobRef.jobRef || keyForDocumentRefAggregation || (jobRef.id || Math.random()).toString();

    if (!aggregatedTMSRefsMap.has(keyForTMSRefAggregation)) {
      aggregatedTMSRefsMap.set(keyForTMSRefAggregation, {
        jobRef: jobRef.jobRef,
        aggregatedDocumentRefs: [],
      });
    }

    const aggregatedTMSRef = aggregatedTMSRefsMap.get(keyForTMSRefAggregation)!;
    const existingAggregatedDocumentRef = aggregatedTMSRef.aggregatedDocumentRefs.find((aggregatedDocumentRef) => aggregatedDocumentRef.key === keyForDocumentRefAggregation);
    
    if (existingAggregatedDocumentRef) {
      existingAggregatedDocumentRef.refs.push(jobRef);
    } else {
      aggregatedTMSRef.aggregatedDocumentRefs.push({ key: keyForDocumentRefAggregation, refs: [jobRef] });
    }
  });

  return [...aggregatedTMSRefsMap.values()];
}
export const mapJobRefsToClusters = (
  rawClusters: APIClusterTotal[],
  lineItems: APIJobRef[],
  failedClusters: number[] = [],
  matchedClusters: number[] = [],
  emailAccount: APIEmailAccount
): Cluster[] => {

  const refsPerCluster = lineItems.reduce((acc, ref) => {
    if (!ref.clusterId) return acc;

    // we want to hide CN refs for mailboxes without CN validation
    if (emailAccount.cnValidation === CNValidationType.None && ref.containerNum) {
      return acc;
    }

    // we want to hide PO refs for CW mailboxes
    if (emailAccount.tmsType === TMSType.Cargowise && ref.purchaseOrder) {
      return acc;
    }

    if (!acc[ref.clusterId]) {
      acc[ref.clusterId] = [];
    }

    acc[ref.clusterId].push({
      ...ref,
      id: ref.id || 0,
      type: getJobRefType(ref)
    });

    return acc;
  }, {} as Record<number, JobRef[]>);

  return rawClusters.map((cluster) => {
    const refs = refsPerCluster[cluster.id] || [];
    const sortedJobRefs = refs.length ? refs : [{ jobRef: null, clusterId: cluster.id, invoiceId: cluster.invoiceId, type: JobRefType.Ref, containerNum: null, serviceEndDate: null, serviceStartDate: null, purchaseOrder: null, bolNum: null, orderIndex: null, total: null }];
    return {
      ...cluster,
      aggregatedTMSRefs: aggregate(sortedJobRefs, emailAccount.cnValidation),
      status: failedClusters.includes(cluster.id) ? ClusterStatus.Failed : (matchedClusters.includes(cluster.id) ? ClusterStatus.Matched : undefined)
    };
  });
}

export const JobRefClusterAPI = {
  async create(invoiceId: number, isHeader: boolean): Promise<APIClusterTotal> {
    return request(`/SupplierInvoiceJobRefClusters`, 'POST', { invoiceId, isHeader, total: null });
  },

  async update(id: number, cluster: Partial<APIClusterTotal>): Promise<APIClusterTotal> {
    return request(`/SupplierInvoiceJobRefClusters/${id}`, 'PATCH', cluster);
  },

  async delete(id: number) {
    return request(`/SupplierInvoiceJobRefClusters/${id}`, 'DELETE');
  },
}
