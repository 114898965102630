import * as React from 'react';
import { Dropdown } from 'pivotal-ui/react/dropdowns';

import './dropdown-list.scss';

type DropdownOptionData = {
  value: number | null | string;
  label: any;
  icon?: any;
  disabled?: boolean
}

type DropdownOption = DropdownOptionData & {
  items?: DropdownOptionData[];
}

export type DropdownOptions = Array<DropdownOption>;

interface Props {
  items: DropdownOptions;
  buttonIcon?: any;
  menuAlign?: 'left' | 'right' | 'none';
  hideToggle?: boolean;
  iconOnlyButton?: boolean;
  itemClassName?: string;
  dropdownMenuClassName?: string;
  dropdownMenuWidth?: 'input-width'
  buttonClassName?: string;
  selectedValue: number | string | null;
  preventDefault?: boolean;
  onChange: (result: number | string | null) => void;
  loadValuesAsHtml?: boolean;
  displayValueAsSelected?: boolean;
  disabled?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => boolean;
}

export const DropdownList = React.memo(React.forwardRef((props: Props, ref) => {
  const dropdownRef = React.useRef<typeof Dropdown>(null);
  const { items, buttonIcon, iconOnlyButton, buttonClassName, dropdownMenuClassName, itemClassName, selectedValue, onChange,
    preventDefault, menuAlign, hideToggle, onKeyDown } = props;

  let selected: DropdownOption | undefined;

  items.forEach((item) => {
    if (item.value === selectedValue) {
      selected = item;
    } else {
      const selectedSubItem = item.items?.find((subItem) => subItem.value === selectedValue);
      if (selectedSubItem) selected = selectedSubItem;
    }
  });

  let title = <span>{buttonIcon}</span>;
  if (selected && props.displayValueAsSelected) {
    title = <span>{selected.value}</span>;
  } else if (selected) {
    title = <span>{iconOnlyButton ? selected.icon : <>{buttonIcon}{selected.icon}{selected.label}</>}</span>;
  }

  const onMouseDown = (event: React.MouseEvent) => {
    if (props.preventDefault) event.preventDefault();
  }

  React.useImperativeHandle(ref, () => ({
    focus() {
      if (dropdownRef.current) {
        dropdownRef.current.containerRef.current.children[0].focus();
      }
    }
  }));

  const disabled = props.disabled || (items.length <= 1 && props.selectedValue);

  return (
    <Dropdown
      ref={dropdownRef}
      title={title}
      buttonClassName={`${buttonClassName} ${disabled ? 'disabled' : ''}`}
      dropdownMenuClassName={`dropdown-menu with-custom-scrollbar ${dropdownMenuClassName} ${props.dropdownMenuWidth ? 'dropdown-menu--' + props.dropdownMenuWidth : ''}`}
      itemClassName={`dropdown-item ${itemClassName}`}
      menuAlign={menuAlign ? menuAlign : 'left'}
      showIcon={!hideToggle}
      floatMenu
      scroll
      onMouseDown={onMouseDown}
      onKeyDown={(dropdownRef.current && !dropdownRef.current.state.open) ? onKeyDown : undefined}
      disabled={disabled}
    >
      {items.map((item, itemIndex) => {
        if (item.items) {
          return (
            <div className="dropdown-item--with-submenu" key={itemIndex}>
              <div className="dropdown-item--separator">{item.label}</div>
              <ul>
                {item.items.map((subItem, subItemIndex) => (
                  <li className="dropdown-item">
                    <DropdownListItem
                      key={subItemIndex}
                      value={subItem.value}
                      label={subItem.label}
                      icon={subItem.icon}
                      disabled={item.disabled}
                      onChoose={onChange}
                      preventDefault={preventDefault}
                      loadValuesAsHtml={props.loadValuesAsHtml}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )
        } else {
          return (
            <DropdownListItem
              key={itemIndex}
              value={item.value}
              label={item.label}
              icon={item.icon}
              disabled={item.disabled}
              onChoose={onChange}
              preventDefault={preventDefault}
              loadValuesAsHtml={props.loadValuesAsHtml}
            />
          );
        }
      })}
    </Dropdown>
  );
}));

interface ItemProps {
  value: number | string | null;
  label: any;
  icon: any;
  disabled?: boolean;
  preventDefault?: boolean;
  onChoose: (results: number | string | null) => void;
  loadValuesAsHtml?: boolean;
}

const DropdownListItem: React.FC<ItemProps> = React.memo((props) => {
  const onChoose = (event: React.MouseEvent) => {
    if (!props.disabled) {
      event.preventDefault();
      props.onChoose(props.value);
    }
  }

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (props.preventDefault) event.preventDefault();
  }

  if (props.loadValuesAsHtml) return (
    <div
      className={`${props.disabled ? 'disabled' : ''}`}
      dangerouslySetInnerHTML={{ __html: props.label }}
      onClick={onChoose}
      onMouseDown={onMouseDown}
    />
  );

  return (
    <div
      className={`${props.disabled ? 'disabled' : ''}`}
      onClick={onChoose}
      onMouseDown={onMouseDown}
    >
      {props.icon && (
        <span className="dropdown-item__icon">{props.icon}</span>
      )}
      {props.label}
    </div>
  );
});
