import * as React from 'react';
import { PermissionLevel } from '../../../api/authentication';
import { ShipamaxPlusIcon } from '../../../images/Icons';
import { useHistory } from 'react-router-dom';
import { APICustomDashboard, APICustomDashboardChart, CustomDashboardAPI } from '../../../api/customDashboard';
import { DashboardAdded, Notification } from '../../common/Notification';

import './dashboards-dropdown.scss';
import { ChartUserSettings, InsightsFilterSettings } from '../../../api/userSettings';
import { NameForm } from './NameForm';
import { SerieToggleState } from '../common';

interface Props {
  disabled?: boolean;
  className?: string;
  permissionLevel: PermissionLevel;
  customDashboards: APICustomDashboard[];
  addToMode?: boolean;
  insightsSettings?: InsightsFilterSettings;
  chartSettings?: ChartUserSettings;
  setNotification?: (notification: Notification | null) => void;
  serieToggleStates?: SerieToggleState;
  productSection?: number;
}

export const DashboardsDropdown = <T extends unknown>(props: Props) => {
  const [showDropdownMenu, setShowDropdownMenu] = React.useState<boolean>(false);
  const [showBothNameForm, setShowBothNameForm] = React.useState<boolean>(false);
  const [showChartNameForm, setShowChartNameForm] = React.useState<boolean>(false);
  const [currentDashboardId, setCurrentDashboardId] = React.useState<number>(0);

  const dropdownMenuRef = React.useRef<HTMLDivElement>(null);
  const defaultChartSettings = {
    aggregation: 1,
    counterType: 1
  }
  const defaultInsightSettings = {
    assignedType: 2,
    chartType: 1,
    creditors: [],
    dataSet: 1,
    dateFilter: { type: 4, dateUnit: 3, firstDate: null, secondDate: null, dateOriginType: 1, dateUnitType: 1, dateUnitsCount: 2 },
    defaultSection: 1,
    mailboxIds: [],
    organisationType: 1,
    segments: [1, 3, 4, 2, 5],
    teamIds: [],
    typeOfOrganisation: 1,
    userIds: []
  }

  const history = useHistory();

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutsideOfDropdownMenu);

    return () => {
      window.removeEventListener('click', handleClickOutsideOfDropdownMenu);
    }
  }, [showDropdownMenu]);

  const handleClickOutsideOfDropdownMenu = React.useCallback((event: MouseEvent) => {
    if (showDropdownMenu && dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target as HTMLElement)) {
      setShowDropdownMenu(false);
    }
  }, [showDropdownMenu, dropdownMenuRef]);

  const toggleDropdownMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowDropdownMenu(!showDropdownMenu);
  }

  const onItemClick = (value: string) => {
    history.push(value);
    setShowDropdownMenu(false);
  }

  const addToExistingDashboard = async (chartName: string) => {
    const dashboard = await CustomDashboardAPI.fetch(currentDashboardId);
    const row = {
      id: undefined,
      dashboardId: dashboard.id,
      orderIndex: dashboard.rows.length + 1
    }

    const createdRow = await CustomDashboardAPI.createRow(row);
    const chart = {
      dashboardId: dashboard.id,
      id: undefined,
      name: chartName,
      orderIndex: 0,
      settings: {
        chartSettings: props.chartSettings || defaultChartSettings,
        insightsSettings: props.insightsSettings || defaultInsightSettings,
        userOptions: props.serieToggleStates
      },
      dashboardRowId: createdRow.id
    }

    const response = await CustomDashboardAPI.createChart(chart);

    if (response && props.setNotification) {
      props.setNotification(DashboardAdded);
    }
    setShowDropdownMenu(false);
    setShowChartNameForm(false);
  }

  const addToNewDashboard = async (dashboardName: string, chartName: string | undefined) => {
    console.log(props.serieToggleStates)
    let result: APICustomDashboard;

    const dashboard = {
      id: undefined,
      name: dashboardName,
      companyId: parseInt(localStorage.getItem('companyId') || ''),
      sectionModule: props.productSection || 1,
      rows: []
    }

    result = await CustomDashboardAPI.create({
      ...dashboard,
      rows: []
    });

    const row = {
      id: undefined,
      dashboardId: result.id,
      orderIndex: 0
    }

    const rowResult = await CustomDashboardAPI.createRow(row);

    const chartData: APICustomDashboardChart = {
      dashboardId: result.id || 0,
      id: undefined,
      name: chartName || '',
      orderIndex: 1,
      settings: {
        chartSettings: props.chartSettings || defaultChartSettings,
        insightsSettings: props.insightsSettings || defaultInsightSettings,
        userOptions: props.serieToggleStates
      },
      dashboardRowId: rowResult.id
    }

    await CustomDashboardAPI.createChart(chartData);
    setShowBothNameForm(false);

    if (props.setNotification) {
      props.setNotification(DashboardAdded);
    }
    props.customDashboards.push(result);
  }

  return (
    <>
      <div
        className={`dashboards-dropdown ${showDropdownMenu ? 'dashboards-dropdown--open' : ''} ${props.className || ''} ${props.addToMode ? 'add-to-mode' : ''}`}
      >
        <button
          className={`dashboards-dropdown__label`}
          onClick={(e) => !props.disabled && toggleDropdownMenu(e)}
          disabled={props.disabled}
        >{props.addToMode ? (<><ShipamaxPlusIcon /> <>Add to dashboard</></>) : "Custom dashboards"}</button>
        {showDropdownMenu && (
          <div
            className="dashboards-dropdown__menu absolute bg-white z-10 p-0"
            ref={dropdownMenuRef}
          >
            <div className="max-h-[300px] with-custom-scrollbar">
              {props.customDashboards.map((customDashboard, index) => (
                <div
                  key={index}
                  className="dashboards-dropdown__menu__item"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (props.addToMode) {
                      setCurrentDashboardId(customDashboard.id || 0);
                      setShowChartNameForm(true);
                    } else {
                      onItemClick('/apInsights/custom-dashboards/' + customDashboard.id);
                    }
                  }}
                >
                  {customDashboard.name}
                </div>
              ))}
            </div>
            {props.permissionLevel === PermissionLevel.Admin && (
              <div
                className="dashboards-dropdown__menu__item color-grey5"
                style={props.customDashboards.length ? { borderTop: '1px solid rgba(0, 0, 0, 0.15)' } : {}}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.addToMode ? setShowBothNameForm(true) : onItemClick('/apInsights/custom-dashboards/form');
                }}
              >
                <ShipamaxPlusIcon />{props.addToMode ? 'Add To New' : 'Create New Dashboard'}
              </div>
            )}
          </div>
        )}
      </div>
      {showBothNameForm && (
        <NameForm
          id={undefined}
          sectionModule={undefined}
          onCancel={(): void => setShowBothNameForm(false)}
          onSubmit={addToNewDashboard}
          type={'both'} />
      )}
      {showChartNameForm && (
        <NameForm
          id={undefined}
          sectionModule={undefined}
          onCancel={(): void => setShowChartNameForm(false)}
          onSubmit={addToExistingDashboard}
          type={'chart'} />
      )}
    </>
  )
}
