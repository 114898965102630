import * as React from 'react';
import * as moment from 'moment';
import { TextInput } from '../../../common/TextInput';
import { WrapperLabel } from '../../../common/WrapperLabel';
import { OptionValue } from 'react-selectize';
import { DateSelectEditor } from '../../../common/DateSelectEditor';
import { APIDocumentGroupConsol, APIDocumentGroupDocument } from '../../../../api/documentGroup';
import { BillOfLadingAPI } from '../../../../api/billOfLading';
import { sortSearchOptions } from '../../../helpers';
import { FieldChangedParams, IssuerDetailMap } from '../../common';
import { CreditorSelect } from '../../../common/CreditorSelect';
import { PortSelect } from '../../../common/PortSelect';
import { CustomSelect } from '../../../common/CustomSelect';
import { ContainersList } from '../ContainersList';
import { APIDocumentContainer } from '../../../../api/documentContainer';
import { APIEmailBasic } from '../../../../api/email';
import { placeholderTypes } from '../../file-sorter/FileSorter';
import { PacklinesList } from '../PacklinesList';

import '../consol.scss';
import './generic-bill.scss';
import { BillOfLadingNotifyParty } from '../../../../api/billOfLadingNotifyParty';
import { CollapsibleContainer } from '../../../common/CollapsibleContainer';
import { RecordValidator } from '../../../common/Form';
import { OrganisationText } from '../organisation-text/OrganisationText';
import { checkIfRecordValidatorHasErrors } from '../ErrorHelper';

interface Props {
  // Both
  data: APIDocumentGroupConsol;
  document: APIDocumentGroupDocument<APIDocumentGroupConsol> | undefined;
  transModeOptions: OptionValue[];
  containerTypeOptions: OptionValue[];
  packageTypeOptions: OptionValue[];
  issuerDetails: IssuerDetailMap;
  disabled: boolean;
  activeDocument?: APIDocumentContainer | APIEmailBasic | undefined;
  containerOrPackingModeOptions: OptionValue[];
  disableHighlights?: boolean;
  reloadGroupData: (forceRefresh?: boolean) => void;
  fieldsChanged: (changes: FieldChangedParams[]) => void;
  setActiveDocument: (document: APIDocumentContainer | APIEmailBasic | undefined, scroll?: boolean) => void;
  checkRepeatedBlNumber: (docId: number, value: string | undefined) => boolean;
  addToBlNums: (docId: number, value: string) => void;
  useOrganisations: boolean;
  // Master
  groupId?: number;
  vesselOptions: OptionValue[];
  consolTypeOptions: OptionValue[];
  paymentTermsOptions: OptionValue[];
  // House
  releaseTypeOptions: OptionValue[];
  defaultPackingMode: string | undefined;
}

export const GenericBill: React.FC<Props> = (props: Props) => {
  const [blNo, setBlNo] = React.useState<string>('');
  const [bookingRef, setBookingRef] = React.useState<string>('');
  const [exportersRef, setExportersRef] = React.useState<string>('');
  const [shippingLineText, setShippingLineText] = React.useState<string>('');

  const [ownersRef, setOwnersRef] = React.useState<string>('');

  const [paymentTerms, setPaymentTerms] = React.useState<OptionValue | null>(null);
  const [transMode, setTransMode] = React.useState<OptionValue | null>(null);
  const [voyage, setVoyage] = React.useState<string>('');
  const [vessel, setVessel] = React.useState<OptionValue | null>(null);
  const [containerMode, setContainerMode] = React.useState<OptionValue | null>(null);
  const [etd, setEtd] = React.useState<string | null>();
  const [eta, setEta] = React.useState<string | null>();
  const [shipperName, setShipperName] = React.useState<string>('');
  const [shipperAddress, setShipperAddress] = React.useState<string>('');
  const [consigneeName, setConsigneeName] = React.useState<string>('');
  const [consigneeAddress, setConsigneeAddress] = React.useState<string>('');
  const [notifyName, setNotifyName] = React.useState<string>('');
  const [notifyAddress, setNotifyAddress] = React.useState<string>('');

  // HBL
  const [releaseType, setReleaseType] = React.useState<OptionValue | null>(null);
  const [goodsDesc, setGoodsDesc] = React.useState<string>('');

  // load document group data into consol form
  React.useEffect(() => {
    const d = props.data || {} as APIDocumentGroupConsol;
    setBlNo(d.billOfLadingNo || '');

    setBookingRef(d.bookingNo || '');
    setExportersRef(d.exportReference || '');
    setShippingLineText(d.shippingLine || '');
    setOwnersRef(d.ownersReference || '');
    setVoyage(d.voyageNumber || '');
    setEtd(d.etd ? d.etd : null);
    setEta(d.eta ? d.eta : null);

    setConsigneeName(d.consigneeName || '');
    setConsigneeAddress(d.consigneeAddress || '');
    setShipperName(d.shipperName || '');
    setShipperAddress(d.shipperAddress || '');
    setNotifyName((d.notify ? d.notify[0]?.notifyPartyName : '') || '');
    setNotifyAddress((d.notify ? d.notify[0]?.notifyPartyAddress : '') || '');

    setContainerMode(props.containerOrPackingModeOptions.find((option) => option.value === d.containerMode) || null);

    let paymentTermsCode = d.paymentTerms;
    if (d.paymentTerms?.toLowerCase() === 'freight collect') paymentTermsCode = 'CCX';
    else if (d.paymentTerms?.toLowerCase() === 'freight prepaid') paymentTermsCode = 'PPD'
    setPaymentTerms(props.paymentTermsOptions.find((option) => option.value === paymentTermsCode) || null);

    if (!d.transportMode) update('transportMode', 'SEA');
    setTransMode(props.transModeOptions.find((option) => option.value === (d.transportMode || 'SEA')) || null);

    setVessel(props.vesselOptions.find((option) => option.label === d.vessel) || null);

    // HBL
    setReleaseType(props.releaseTypeOptions.find((options) => options.value === d.releaseType) || null);
    setGoodsDesc(d.goodsDescription || '');
  }, [props.data]);

  React.useEffect(() => {
    props.addToBlNums(props.data?.documentId!, blNo);
  }, [blNo]);

  const recordValidator: RecordValidator = {
    blNumber: {
      validators: [
        {
          errorMessage: 'Duplicate bill of lading number',
          isValid: () => !props.checkRepeatedBlNumber(props.data?.documentId!, blNo)
        },
      ]
    }
  };
  const recordValidatorHasErrors = checkIfRecordValidatorHasErrors(recordValidator);

  const onFilterOptionsAtLeast3Characters = (items: OptionValue[], search: string): OptionValue[] => {
    if (search.length < 3) return [];

    const filteredOptions = items.filter((option) => option.label.toUpperCase().includes(search.toUpperCase()));
    return sortSearchOptions(filteredOptions, search);
  }

  const update = async (name: string, value: string | number | null, withoutChange?: boolean) => {
    if (withoutChange) {
      return;
    }

    if (name === 'notifyRecordId') {
      await BillOfLadingNotifyParty.updateNotifyParty(value, props.data.id, props.data.notify);
      props.reloadGroupData(true);
    } else if (['notifyPartyAddress', 'notifyPartyName'].includes(name)) {
      const notifyParty = props.data.notify ? props.data.notify[0] : null;
      if (notifyParty) {
        await BillOfLadingNotifyParty.update(notifyParty.id, { [name]: String(value) });
      } else if (value) {
        await BillOfLadingNotifyParty.create({
          billOfLadingId: props.data?.id,
          [name]: value,
        });
      }
      props.reloadGroupData(true);
    } else if (props.data?.id) {
      await BillOfLadingAPI.update(props.data?.id, { [name]: value });
      props.reloadGroupData();
    }

    if (['consigneeCgwRecordId', 'shipperCgwRecordId', 'shippingLineCgwRecordId', 'notifyRecordId'].includes(name)) {
      props.fieldsChanged([{
        id: props.data.documentId,
        fieldName: name,
        recordId: value as string | null
      }]);
    } else if (['billOfLadingNo'].includes(name)) {
      props.fieldsChanged([{
        id: props.data?.documentId,
        fieldName: name,
        value: value as string | null
      }]);
    }
  }

  const updateTwo = async (name: string, value: string | number | null, name2: string, value2: string | number | null) => {
    if (props.data?.id) {
      await BillOfLadingAPI.update(props.data?.id, { [name]: value, [name2]: value2 });
      props.reloadGroupData();
    }
  }

  const onFocus = () => {
    if (props.document?.documentType && !placeholderTypes.includes(props.document?.documentType)) {
      props.setActiveDocument(props.document as unknown as APIDocumentContainer);
    }
  }

  const visibleContent =
    <div className="collapsible-container__header--body grid__row">
      <div className='grid__col-4'>
        <WrapperLabel text="BL Number">
          <TextInput value={blNo} name="billOfLadingNo" disabled={props.disabled} onBlur={(name: string, value: string | null, withoutChange) => {
            if (withoutChange) {
              return;
            }
            update(name, value);
          }} setter={setBlNo}
            onFocus={onFocus}
            fieldData={{
              objectIds: [props.data?.id || -1],
              tableName: 'bol_header',
              attributeNames: ['bill_of_lading_number'],
            }}
            formEntered={true}
            validators={recordValidator.blNumber.validators}
            dataTestId="bol-number-input"
          />
        </WrapperLabel>
      </div>
      <div className="grid__col-4">
        <WrapperLabel text="Exporter Reference">
          <TextInput
            value={exportersRef}
            name="exportReference"
            onBlur={update}
            setter={setExportersRef}
            disabled={props.disabled}
            onFocus={onFocus}
            fieldData={{
              objectIds: [props.data?.id || -1],
              tableName: 'bol_header',
              attributeNames: ['export_reference'],
            }}
            dataTestId="exporter-ref-input"
          />
        </WrapperLabel>
      </div>
      <div className="grid__col-4">
        <WrapperLabel text="Booking Number">
          <TextInput
            value={bookingRef}
            name="bookingNo"
            onBlur={update}
            setter={setBookingRef}
            maxLength={20}
            disabled={props.disabled}
            onFocus={onFocus}
            fieldData={{
              objectIds: [props.data?.id || -1],
              tableName: 'bol_header',
              attributeNames: ['booking_number'],
            }}
            dataTestId="booking-ref-input"
          />
        </WrapperLabel>
      </div>
    </div>;

  const isActiveDocument = props.activeDocument?.id === props.document?.id;

  return (
    <CollapsibleContainer
      title="Bill of lading"
      disableScroll
      disabled={props.disabled}
      id={`card-${props.data?.documentId}`}
      key={`generic-bill-${props.data.id}`}
      isCollapsedByDefault={isActiveDocument ? false : true}
      className={`generic-bill ${props.activeDocument?.id === props.data.documentId ? 'selected-card-div' : 'mt-25'}`}
      preHeaderJsx={
        <div className='invoice_icons'>
          {recordValidatorHasErrors && <span className='fas fa-exclamation-triangle icon-error'></span>}
        </div>
      }
      visibleContent={visibleContent}
      versionTwo
    >
      <div className='grid__row grid__row--with-separator top'></div>
      <div className="grid__row grid__row--with-separator">
        {!props.useOrganisations
          ? (<>
            <OrganisationText
              label='shipper'
              fieldName='shipper'
              tableName='bol_header'
              name={shipperName}
              setName={setShipperName}
              address={shipperAddress}
              setAddress={setShipperAddress}
              update={update}
              disabled={props.disabled}
              id={[props.data?.id || -1]}
              onFocus={onFocus}
            />
            <OrganisationText
              label='consignee'
              fieldName='consignee'
              tableName='bol_header'
              name={consigneeName}
              setName={setConsigneeName}
              address={consigneeAddress}
              setAddress={setConsigneeAddress}
              update={update}
              disabled={props.disabled}
              id={[props.data?.id || -1]}
              onFocus={onFocus}
            />
            <OrganisationText
              label='Notify Party'
              fieldName='notifyParty'
              highlightName='notify'
              tableName='bol_header'
              name={notifyName}
              setName={setNotifyName}
              address={notifyAddress}
              setAddress={setNotifyAddress}
              update={update}
              disabled={props.disabled}
              id={[props.data?.id || -1]}
              onFocus={onFocus}
            />
          </>)
          :
          (<>
            <div className="grid__col-4">
              <CreditorSelect
                disabled={props.disabled}
                label="Shipper"
                recordId={props.data?.shipperCgwRecordId || null}
                onValueChange={(option) => {
                  update('shipperCgwRecordId', option?.value || null);
                }}
                creditorDetailsMap={props.issuerDetails}
                flags={{ shipper: true }}
                dataTestId="shipper-dropdown"
                showAddress={true}
                companyCode={props.data?.emailAccount?.cwCompanyCode}
                onFocus={onFocus}
                copyOnlyToInvoices={false}
                cloneToFields={['shipperCgwRecordId']}
                cloneFieldLabel="Shipper"
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['shipper'],
                }}
              />
            </div>
            <div className="grid__col-4">
              <CreditorSelect
                disabled={props.disabled}
                label="Consignee"
                recordId={props.data?.consigneeCgwRecordId || null}
                onValueChange={(option) => {
                  update('consigneeCgwRecordId', option?.value || null);
                }}
                creditorDetailsMap={props.issuerDetails}
                flags={{ consignee: true }}
                dataTestId="consignee-dropdown"
                showAddress={true}
                companyCode={props.data?.emailAccount?.cwCompanyCode}
                onFocus={onFocus}
                copyOnlyToInvoices={false}
                cloneToFields={['consigneeCgwRecordId']}
                cloneFieldLabel="Consignee"
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['consignee'],
                }}
              />
            </div>
            <div className="grid__col-4">
              <CreditorSelect
                label="Notify party"
                recordId={props.data.notify ? props.data.notify[0]?.notifyPartyRecordId : null}
                onValueChange={(option) => {
                  update('notifyRecordId', option?.value || null);
                }}
                dataTestId="notify-party-dropdown"
                showAddress={true}
                creditorDetailsMap={props.issuerDetails}
                disabled={props.disabled}
                companyCode={props.data?.emailAccount?.cwCompanyCode}
                dropdownMenuRightAlignment
                onFocus={onFocus}
                fieldData={{
                  objectIds: props.disableHighlights ? [-1] : [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['notify'],
                }}
              />
            </div>
          </>)
        }
      </div>
      <div className="grid__row">
        <div className="grid__col-4">
          {props.useOrganisations ? (
            <CreditorSelect
              label="Carrier Code"
              recordId={props.data?.shippingLineCgwRecordId || null}
              onValueChange={(option) => {
                update('shippingLineCgwRecordId', option?.value || null);
              }}
              creditorDetailsMap={props.issuerDetails}
              flags={{ carrier: true }}
              dataTestId="carrier-dropdown"
              disabled={props.disabled}
              companyCode={props.data?.emailAccount?.cwCompanyCode}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['shipping_line'],
              }}
            />
          ) : (
            <WrapperLabel text="Carrier">
              <TextInput
                value={shippingLineText}
                name="shippingLine"
                onBlur={update}
                setter={setShippingLineText}
                disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['shipping_line'],
                }}
              />
            </WrapperLabel>
          )}
        </div>
        <div className="grid__col-4">
          <WrapperLabel text="Voyage">
            <TextInput value={voyage} name="voyageNumber" onBlur={update} setter={setVoyage} maxLength={10} disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['voyage'],
              }}
              dataTestId="voyage-input"
            />
          </WrapperLabel>
          <WrapperLabel text="Vessel">
            <CustomSelect
              options={props.vesselOptions}
              value={vessel || undefined}
              renderNoResultsFound={(item: OptionValue, search: string) => <div className="dropdown-placeholder">Please enter the first 3 characters of the code...</div>}
              filterOptions={onFilterOptionsAtLeast3Characters}
              onValueChange={(value) => {
                setVessel(value);
                updateTwo('vessel', value?.label || null, 'vesselIMO', value?.value || null);
              }}
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['vessel'],
              }}
            />
          </WrapperLabel>
        </div>
        <div className="grid__col-4">
          <WrapperLabel text="Release Type">
            <CustomSelect
              options={props.releaseTypeOptions}
              value={releaseType || undefined}
              onValueChange={(value) => {
                setReleaseType(value);
                update('releaseType', value?.value || null);
              }}
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['release_type'],
              }}
            />
          </WrapperLabel>
        </div>
      </div>
      <div className="grid__row">
        <div className="grid__col-4">
          <PortSelect
            label="Load Port"
            value={props.data?.loadPort}
            valueUnlocode={props.data?.loadPortUnlocode}
            onValueChange={(value, valueUnlocode) => {
              updateTwo('loadPort', value, 'loadPortUnlocode', valueUnlocode);
            }}
            onValueUnlocodeChange={(valueUnlocode) => {
              update('loadPortUnlocode', valueUnlocode);
            }}
            onlySeaPort={true}
            dataTestId="load-port"
            disabled={props.disabled}
            onFocus={onFocus}
            fieldData={{
              objectIds: [props.data?.id || -1],
              tableName: 'bol_header',
              attributeNames: ['load_port'],
            }}
          />
        </div>
        <div className="grid__col-4">
          <PortSelect
            label={'Discharge Port'}
            value={props.data?.dischargePort}
            valueUnlocode={props.data?.dischargePortUnlocode}
            onValueChange={(value, valueUnlocode) => {
              updateTwo('dischargePort', value, 'dischargePortUnlocode', valueUnlocode);
            }}
            onValueUnlocodeChange={(valueUnlocode) => {
              update('dischargePortUnlocode', valueUnlocode);
            }}
            onlySeaPort={true}
            dataTestId="disch-port"
            disabled={props.disabled}
            onFocus={onFocus}
            fieldData={{
              objectIds: [props.data?.id || -1],
              tableName: 'bol_header',
              attributeNames: ['discharge_port'],
            }}
          />
        </div>
        <div className="grid__col-4">
          <WrapperLabel text="ETD">
            <DateSelectEditor
              initDate={props.data?.etd && moment(props.data.etd)}
              onDateChange={(value: moment.Moment | null) => {
                update('etd', value ? value.format('YYYY-MM-DD') : null);
                setEtd(value ? value.format('YYYY-MM-DD') : null);
              }}
              disabled={props.disabled}
              onFocus={onFocus}
            />
          </WrapperLabel>
          <WrapperLabel text="ETA">
            <DateSelectEditor
              initDate={props.data?.eta && moment(props.data.eta)}
              onDateChange={(value: moment.Moment | null) => {
                update('eta', value ? value.format('YYYY-MM-DD') : null);
                setEta(value ? value.format('YYYY-MM-DD') : null);
              }}
              disabled={props.disabled}
              onFocus={onFocus}
            />
          </WrapperLabel>
        </div>
      </div>
      <div className="grid__row grid__row--with-separator">
        <div className="grid__col-4">
          <WrapperLabel text="SOB Date">
            <DateSelectEditor
              initDate={props.data?.shippedOnBoardDate && moment(props.data.shippedOnBoardDate)}
              onDateChange={(value: moment.Moment | null) => {
                update('shippedOnBoardDate', value ? value.format('YYYY-MM-DD') : null);
              }}
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['shipped_on_board_date'],
              }}
            />
          </WrapperLabel>
          <WrapperLabel text="Trans Mode">
            <CustomSelect
              disabled={props.disabled}
              options={props.transModeOptions}
              value={transMode || undefined}
              onValueChange={(value) => {
                setTransMode(value);
                update('transportMode', value?.value || null);
              }}
              onFocus={onFocus}
            />
          </WrapperLabel>
        </div>
        <div className="grid__col-4">
          <WrapperLabel text="Payment Terms" className="grid__col-6">
            <CustomSelect
              options={props.paymentTermsOptions}
              value={paymentTerms || undefined}
              onValueChange={(value) => {
                setPaymentTerms(value);
                update('paymentTerms', value?.value || null);
              }}
              renderValue={(item: OptionValue) => item.value}
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['payment_terms'],
              }} />
          </WrapperLabel>
          <WrapperLabel text="Container Mode">
            <CustomSelect
              options={props.containerOrPackingModeOptions}
              value={containerMode || undefined}
              onValueChange={(value) => {
                setContainerMode(value);
                update('containerMode', value?.value || null);
              }}
              renderValue={(item: OptionValue) => item.value}
              className="dropdown-wide"
              disabled={props.disabled}
              onFocus={onFocus}
            />
          </WrapperLabel>
        </div>
        <div className="grid__col-4">
          <WrapperLabel text="Owner Reference" className="multiple-col-types-adjust">
            <TextInput
              value={ownersRef}
              name="ownersReference"
              onBlur={update}
              setter={setOwnersRef}
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['owners_reference'],
              }}
              dataTestId="owners-ref-input"
              maxLength={20}
            />
          </WrapperLabel>
        </div>
      </div>
      <div className="grid__row grid__row--with-separator">
        <ContainersList
          containers={props.data?.container}
          reloadGroupData={props.reloadGroupData}
          disabled={props.disabled}
          containerTypeOptions={props.containerTypeOptions}
          billOfLadingId={props.data?.id}
          onFocus={onFocus}
        />
      </div>
      <div>
        <div className="grid__row">
          <div className="grid__col-4">
            <WrapperLabel text="Goods Description">
              <TextInput
                name="goodsDescription"
                value={goodsDesc}
                onChange={setGoodsDesc}
                onBlur={update}
                disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['goods_description'],
                }}
                dataTestId="goods-description-input"
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-4"></div>
          <div className="grid__col-4"></div>
        </div>
        <div className="grid__row">
          <PacklinesList
            packLines={props.data.packLines}
            reloadGroupData={props.reloadGroupData}
            disabled={props.disabled}
            packageTypeOptions={props.packageTypeOptions}
            billOfLadingId={props.data.id}
            onFocus={onFocus}
          />
        </div>
      </div>
    </CollapsibleContainer>
  );
};
