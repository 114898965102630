import * as React from 'react';
import {
  APIError,
  Discarded,
  Notification,
  Posted,
  Queried,
  Reassigned,
  Reopened,
  Validating,
  ValidationError,
  StatusChangeError,
  StatusChangeSuccess
} from '../../common/Notification';
import { ExtractedDataForm } from './ExtractedDataForm';
import {
  APIValidationResult,
  Exception,
  ExceptionCode,
  filterOverheadsExceptions,
  initialIssueCodeNumber,
  ValidationAPI
} from '../../../api/validationResults';
import { APITeam } from '../../../api/teams';
import { APIIssueCode, IssueCodeType } from '../../../api/issueCode';
import { APIExchangeRate, APIJobOwnersMap, ClearOperationType, SupplierInvoiceAPI } from '../../../api/supplierInvoice';
import { ValidationIssueCodeAPI } from '../../../api/validationIssueCodes';
import { ActivityFeed, ActivityFeedHandle } from '../../common/activity-feed/ActivityFeed';
import { CwPostingLogAPI } from '../../../api/cargowisePostingLog';
import { CreditorChangedParams, getStatusText, Status } from '../ApInvoiceSection';
import { DocumentGroupAPI } from '../../../api/documentGroup';
import { CoordinatesFilter, DocumentContainerAPI, DocumentType } from '../../../api/documentContainer';
import { StatusBarContainer } from '../../common/StatusBarContainer';
import { APIUser, CommentAPI, TeamsMap, UsersMap } from '../../../api/comment';
import { AssignmentRule } from '../../../api/userSettings';
import { checkDocumentMatchesFilter, getToleranceThresholdFromCurrencyAndType } from '../helpers';
import { PostingBehaviourButton } from '../../common/PostingBehaviourButton';
import { IssuerDetailMap, StatusCode } from '../../bill-of-lading/common';
import { CodeSelectionModal, CodeSelectionModalSubmitParams, ModalMode } from '../../common/CodeSelectionModal';
import { ValidationType } from '../../../api/emailAccount';
import { ShipamaxCheckIcon, ShipamaxEllipsisIcon, ShipamaxInboxIcon, ShipamaxPauseIcon, ShipamaxPostIcon, ShipamaxQueueIcon, ShipamaxTrashIcon } from '../../../images/Icons';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { Icon } from 'pivotal-ui/react/iconography';
import { ActivityLogAPI, ActivityType } from '../../../api/activityLog';
import { TabSwitcher } from '../../common/TabSwitcher';
import { Cluster, ClusterStatus, mapJobRefsToClusters } from '../../../api/jobRefCluster';
import { OptionValue } from 'react-selectize';
import { SearchFilter } from '../../common/document-list';
import { APIPermissionProfile, APIPermissionProfileToleranceThreshold } from '../../../api/permissionProfiles';
import { getClusterStatus } from './helpers';
import { UpdatedAccrual } from './AccrualsForm';
import { ClusterAccrualAPI } from '../../../api/clusterAccrual';
import { APICgwCurrencyExchangeRates, CurrencyAPI } from '../../../api/currency';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';

import './document-action.scss';

enum Tab {
  DocumentData = 'Document data',
  CommentsActivity = 'Comments & Activity',
}

export interface Issuer {
  id: number;
  cgwCode: string;
}

export interface Vessel {
  name: string;
  imo: number;
}

interface Props {
  data: APIValidationResult | null;
  teams: APITeam[];
  users: APIUser[];
  issuerDetails: IssuerDetailMap;
  permissionProfile: APIPermissionProfile | null;
  setNotification: (notification: Notification | null) => void;
  currentSearchFilter: SearchFilter;
  selectNextDocument: () => void;
  creditorChanged: (params: CreditorChangedParams) => void;
  setValidationResultData: (id: number, invoiceId: number) => void;
  updateValidationResultForInvoice: (id: number, validationResult: APIValidationResult) => void;
  setInvoiceResubmitted: (id: number) => void;
  removeCoordinates: (coordinatesFilter: CoordinatesFilter) => void;
  reloadData: () => Promise<void>;
  issueCodes: APIIssueCode[];
  currencyOptions: OptionValue[];
  addresseeOptions: OptionValue[];
  showCargoWiseSettings: boolean;
  checkPermissionProfileAccess: (value: keyof APIPermissionProfile) => boolean;
  changesDisabled: boolean;
}

export const DocumentAction: React.FC<Props> = React.memo((props) => {
  const [assignmentRule, setAssignmentRule] = React.useState<AssignmentRule>({ teamId: null, userIds: [] });
  const [modalTeam, setModalTeam] = React.useState<APITeam | null>(null);
  const [modalUser, setModalUser] = React.useState<APIUser | null>(null);
  const [modalMode, setModalMode] = React.useState<ModalMode | undefined>();
  const [resubmitted, setResubmitted] = React.useState<boolean>(false);
  const [isResubmitDisabled, setIsResubmitDisabled] = React.useState<boolean>(false);
  const [documentType, setDocumentType] = React.useState<DocumentType>(DocumentType.SupplierInvoice);
  const [selectedTab, setSelectedTab] = React.useState<string>(Tab.DocumentData);
  const sectionRef = React.useRef<HTMLElement | null>(null);
  const [showOverlay, setShowOverlay] = React.useState<boolean>(false);
  const activityFeedRef = React.useRef<ActivityFeedHandle | null>(null);
  const [clusters, setClusters] = React.useState<Cluster[]>([]);

  const [totalVat, setTotalVat] = React.useState<number | null>(null);
  const [invoiceTotal, setInvoiceTotal] = React.useState<number | null>(null);

  const [invoiceAccruals, setInvoiceAccruals] = React.useState<UpdatedAccrual[]>([]);

  const [currency, setCurrency] = React.useState<OptionValue | null>(null);

  const [exchangeRates, setExchangeRates] = React.useState<APIExchangeRate[]>([]);
  const [cargowiseExchangeRates, setCargowiseExchangeRates] = React.useState<APICgwCurrencyExchangeRates[]>([]);

  const invoiceNetTotal = !invoiceTotal ? 0 : invoiceTotal - (totalVat || 0);
  const groupStatus = props.data?.supplierInvoice.document.documentGroup.status || StatusCode.Unposted;
  const isResubmitted = resubmitted || [StatusCode.Processing, StatusCode.PendingResponse].includes(groupStatus);

  const [failedClusters, setFailedClusters] = React.useState<number[]>([]);

  const filteredExchangeRates = React.useMemo(() => {
    return currency ? exchangeRates.filter((exchangeRate) => [exchangeRate.sourceCurrency, exchangeRate.targetCurrency].includes(currency.label)) : [];
  }, [currency?.label, exchangeRates]);

  let toleranceThreshold: APIPermissionProfileToleranceThreshold | undefined = getToleranceThresholdFromCurrencyAndType(
    props.permissionProfile?.toleranceThresholds || [],
    currency?.label,
    props.data?.supplierInvoice.document.emailAccount?.localCurrency || undefined,
    filteredExchangeRates
  );

  React.useEffect(() => {
    if (!props.data) return;

    fetchAccruals();

    SupplierInvoiceAPI.fetchExchangeRates(props.data?.supplierInvoice.id).then((data) => {
      setExchangeRates(data.filter((exchangeRate) => exchangeRate.sourceCurrency !== exchangeRate.targetCurrency));
    });

    CurrencyAPI.fetchCargowiseCurrencyExchangeRates().then((cgwExchangeRates) => {
      setCargowiseExchangeRates(cgwExchangeRates);
    });
  }, [props.data]);

  React.useEffect(() => {
    const failedClustersSet = new Set<number>();

    props.data?.details?.exceptions.forEach((exception) => {
      /*
        this is a temporary hack for exceptions, where we don't store the ID of the header cluster
       */
      if (exception.code === ExceptionCode.CargoWiseNoAccrualsFound) {
        clusters.forEach((cluster) => failedClustersSet.add(cluster.id));
      }

      if (exception.failedClusters) {
        exception.failedClusters.forEach((clusterId) => failedClustersSet.add(clusterId));
      }
    });

    setFailedClusters([...failedClustersSet]);
  }, [props.data, clusters]);

  const validationIssueCodes = React.useMemo(() => {
    return !props.data ? [] : props.data.validationIssueCodes.map((code) => {
      const translatedCode = props.issueCodes.find((c) => c.id === code.issueCodeId);
      return {
        ...code,
        description: translatedCode ? `${translatedCode.code}: ${code.description}` : code.description,
        isInfo: translatedCode?.actionType !== IssueCodeType.Discard,
        displayOnlyOnOpenedGroup: translatedCode?.actionType === IssueCodeType.Other
      };
    });
  }, [props.data?.validationIssueCodes, props.issueCodes]);

  const exceptions: Exception[] = React.useMemo(() => {
    if (isResubmitted) return [];

    let result: Exception[];
    result = [
      ...(props.data?.details?.exceptions || []),
      ...validationIssueCodes.filter((c) => {
        if ([StatusCode.Discarded, StatusCode.Queried].includes(groupStatus)) return true;
        return c.displayOnlyOnOpenedGroup;
      }).map((c) => ({
        description: c.description, code: initialIssueCodeNumber + c.issueCodeId,
        isWarning: false,
        isInformation: c.isInfo
      })) // offset the code, so it's not the same as the code for exceptions
    ];

    if (documentType === DocumentType.OverheadInvoice) {
      result = filterOverheadsExceptions(result);
    }

    return result;
  }, [props.data?.details?.exceptions, validationIssueCodes, groupStatus, isResubmitted]);

  const usersExceptionsCount = React.useMemo(() => {
    const result: { [userId: number]: { failedClusters: number[] } } = {};

    const failedJobRefs = new Set<string>();

    clusters.forEach((cluster) => {
      const { clusterStatus } = getClusterStatus({
        exceptions: exceptions,
        toleranceThreshold: toleranceThreshold,
        invoiceCurrencyCode: currency?.label || '',
        exchangeTolerance: props.data?.supplierInvoice.document.emailAccount?.exchangeTolerance ?? 0,
        invoiceNetTotal: invoiceNetTotal,
        cluster: cluster,
        invoiceAccruals: invoiceAccruals,
        emailAccount: props.data?.supplierInvoice.document.emailAccount,
      });

      if (failedClusters.includes(cluster.id) && clusterStatus !== ClusterStatus.Matched) {
        cluster.aggregatedTMSRefs.forEach((aggregatedTMSRef) => {
          if (aggregatedTMSRef.jobRef) {
            failedJobRefs.add(aggregatedTMSRef.jobRef);
            const jobOwners = props.data?.supplierInvoice.jobOwnersData ? props.data?.supplierInvoice.jobOwnersData[aggregatedTMSRef.jobRef] : undefined;
            if (!jobOwners) return;

            jobOwners.forEach((jobOwner) => {
              if (jobOwner?.userId && !result[jobOwner.userId]?.failedClusters.includes(cluster.id)) {
                result[jobOwner.userId] = {
                  failedClusters: [...(result[jobOwner.userId]?.failedClusters || []), cluster.id],
                }
              }
            });
          }
        });
      }
    });

    return result;
  }, [failedClusters, clusters, props.data, invoiceAccruals, exceptions, toleranceThreshold, currency, invoiceNetTotal]);

  const userId = Number(localStorage.getItem('userId'));
  const usersMap: UsersMap = React.useMemo(() => props.users.reduce((map, user) => { map.set(user.id, user); return map; }, new Map<number, APIUser>()), [props.users]);
  const teamsMap: TeamsMap = React.useMemo(() => props.teams.reduce((map, team) => { map.set(team.id, team); return map; }, new Map<number, APITeam>()), [props.teams]);

  const jobOwnersMap = React.useMemo(() => {
    const result: APIJobOwnersMap = {};
    const jobOwnersData = props.data?.supplierInvoice.jobOwnersData;

    jobOwnersData && Object.keys(jobOwnersData).forEach((jobRef: string) => {
      result[jobRef] = jobOwnersData[jobRef].map((jobOwnerData) => ({
        ...jobOwnerData,
        userData: jobOwnerData.userId ? usersMap.get(jobOwnerData.userId) : undefined
      })) || [];
    });

    return result;
  }, [usersMap, props.data?.supplierInvoice.jobOwnersData]);

  React.useEffect(() => {
    if (props.data) {
      setAssignmentRule({
        teamId: props.data.supplierInvoice.document.documentGroup.teamId,
        userIds: props.data.supplierInvoice.document.documentGroup.assignedUsers.map((value) => value.userId)
      });

      if (props.data.supplierInvoice) {
        const matchedClusters = props.data?.details?.matchedClusters;
        const failedClusters = props.data?.details?.exceptions.reduce((prev: number[], curr) => ([...prev, ...(curr.failedClusters ? curr.failedClusters : [])]), []);

        const fetchedClusters = mapJobRefsToClusters(props.data.supplierInvoice.clusters, props.data.supplierInvoice.lineItems, failedClusters, matchedClusters, props.data.supplierInvoice.document.emailAccount);
        setClusters(fetchedClusters);
      }
    } else {
      setAssignmentRule({
        teamId: null,
        userIds: []
      });
      setClusters([]);
    }
    setResubmitted(false);
    // scroll this section to the top when changing invoice
    if (sectionRef.current) sectionRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    setIsResubmitDisabled(false);

    setDocumentType(props.data?.supplierInvoice.document.documentType || DocumentType.SupplierInvoice);
    setSelectedTab(Tab.DocumentData);
  }, [props.data]);

  const exceptionsList = props.data?.details?.exceptions?.filter((e) => (!e.isWarning));
  const warningsList = props.data?.details?.exceptions?.filter((e) => (e.isWarning));
  const hasOnlyWarnings = warningsList?.length && !exceptionsList?.length;

  const fetchAccruals = () => {
    if (props.data?.supplierInvoice.id) {
      ClusterAccrualAPI.getClusterAccruals(props.data.supplierInvoice.id).then((data) => {
        setInvoiceAccruals(data.map((accrual) => ({ ...accrual, isUpdated: accrual.osCostAmount !== accrual.originalOsCostAmount && accrual.selected })));
      });
    }
  }

  const hideModal = () => {
    setModalMode(undefined);
  }

  const showResubmissionModal = () => {
    setModalTeam(null);
    setModalUser(null);
    if (hasOnlyWarnings) {
      submit({ actionMode: ModalMode.Resubmit, codes: [], newTeam: null, newUser: null });
    } else {
      setModalMode(ModalMode.Resubmit);
    }
  }

  const showDiscardModal = () => {
    setModalTeam(null);
    setModalUser(null);
    setModalMode(ModalMode.Discard);
  }

  const showQueryModal = () => {
    setModalTeam(null);
    setModalUser(null);
    setModalMode(ModalMode.Query);
  }

  const showReassignModal = (value: AssignmentRule) => {
    let currentTeam = value.teamId && props.teams.find((t) => t.id === value.teamId);
    const currentUser = value.userIds.length && props.users.find((u) => u.id === value.userIds[0]);

    if (!currentTeam && currentUser) {
      currentTeam = props.teams.find((t) => t.id === currentUser.teamId);
    }

    if (hasOnlyWarnings) {
      submit({ actionMode: ModalMode.Reassign, codes: [], newTeam: currentTeam || null, newUser: currentUser || null });
      return;
    }

    setModalTeam(currentTeam || null);
    setModalUser(currentUser || null);
    setModalMode(ModalMode.Reassign);
  }

  const submit = async ({ actionMode, codes, newTeam, newUser, commentMessage }: CodeSelectionModalSubmitParams) => {
    if (!props.data) return;

    switch (actionMode) {
      case ModalMode.Reassign:
        try {
          await DocumentGroupAPI.bulkReassign([props.data.documentGroupId], codes, newTeam?.id || null, newUser ? [newUser.id] : []);

          setAssignmentRule({ teamId: newTeam?.id || null, userIds: newUser ? [newUser.id] : [] });

          const shouldSelectNextDocument = !checkDocumentMatchesFilter(props.currentSearchFilter, { teamId: newTeam?.id || newUser?.teamId || null, userIds: newUser ? [newUser.id] : [] });

          if (shouldSelectNextDocument) {
            props.selectNextDocument();
          }

          props.setNotification(Reassigned);
        } catch (e) {
          props.setNotification({ ...APIError, details: { groupId: props.data.documentGroupId, invoiceId: props.data.supplierInvoice.id }, reason: `Error on reasign submit: ${e}` });
        }

        break;
      case ModalMode.Discard: {
        const resultPromise = await DocumentGroupAPI.bulkDiscard([props.data.documentGroupId], codes);

        if (resultPromise === null) {
          props.setNotification({ ...APIError, details: { groupId: props.data.documentGroupId, invoiceId: props.data.supplierInvoice.id }, reason: `Error on discard submit, resulted null` });
        } else {
          props.setNotification(Discarded);
          props.selectNextDocument();
        }

        break;
      }
      case ModalMode.Query: {
        const promises: Promise<unknown>[] = [];
        promises.push(CommentAPI.createComment({
          userId,
          documentGroupId: props.data.documentGroupId,
          supplierInvoiceId: props.data?.supplierInvoice.id || null,
          comment: commentMessage,
        }));
        promises.push(logStatusChange(StatusCode.Queried, codes));
        promises.push(DocumentGroupAPI.update(props.data.documentGroupId, {
          status: StatusCode.Queried,
          queriedByUser: userId,
        }));

        const resultPromise = await Promise.all(promises);

        if (resultPromise.includes(null)) {
          props.setNotification({ ...APIError, details: { groupId: props.data.documentGroupId, invoiceId: props.data.supplierInvoice.id }, reason: `Error on setting inQuery resulted null` });
        } else {
          if (codes.length > 0) {
            updateValidationIssueCodes(codes, props.data);
          }
          props.setNotification(Queried);
          props.selectNextDocument();
        }
        break;
      }
      case ModalMode.Resubmit: {
        setResubmitted(true);
        const documentGroupDetails = await DocumentGroupAPI.fetchGroup(props.data.documentGroupId);
        if (documentGroupDetails.status === StatusCode.Unposted) {
          await DocumentGroupAPI.update(props.data.documentGroupId, {
            approved: true,
            approvedBy: userId,
            status: StatusCode.Processing,
          });
          const response = await SupplierInvoiceAPI.validate(props.data.supplierInvoice.id);
          if (response) { // If the call failed, response would be null
            await ActivityLogAPI.createLog({
              documentGroupId: props.data.documentGroupId,
              type: ActivityType.RequestedValidation,
              userId,
              details: {
                codes
              }
            });
            if (exceptionsList?.length) updateValidationIssueCodes(codes, props.data);
            props.setInvoiceResubmitted(props.data.supplierInvoice.id);
          } else {
            /* Set the status back to Unposted in case of an API-Error */
            await DocumentGroupAPI.update(props.data.documentGroupId, {
              status: StatusCode.Unposted,
            });
            setResubmitted(false);
            props.setNotification({ ...APIError, details: { groupId: props.data.documentGroupId, invoiceId: props.data.supplierInvoice.id }, reason: `Error on invoice posting submit, returned null` });
          }
        } else {
          props.setNotification({ ...APIError, details: { groupId: props.data.documentGroupId, invoiceId: props.data.supplierInvoice.id }, reason: `Error on submit, group was not previously set as unposted` });
        }
        break;
      }
    }
    hideModal();
  }

  const updateValidationIssueCodes = async (codes: APIIssueCode[], validationResult: APIValidationResult, refetchData?: boolean) => {
    if (!props.data) return;

    let issueCodesChanged = props.data.validationIssueCodes.length !== codes.length;

    // if we have the same amount of issue codes, check if their values changed
    if (!issueCodesChanged) {
      issueCodesChanged = !props.data.validationIssueCodes.every((old) => Boolean(codes.find((cur) => cur.id === old.issueCodeId)));
    }

    // if the selected issue codes still did not change, check if the other code is selected
    // and if it is, check if the description changed
    if (!issueCodesChanged) {
      const oldOtherCode = props.data.validationIssueCodes.find((code) => code.issueCodeId === 9);
      const newOtherCode = codes.find((code) => code.id === 9);

      if (oldOtherCode && newOtherCode && oldOtherCode.description !== newOtherCode.description) issueCodesChanged = true;
    }

    if (issueCodesChanged) {
      if (props.data.validationIssueCodes.length) {
        // delete codes first if there are existing ones
        await ValidationIssueCodeAPI.deleteCodesForDocumentGroup(validationResult.documentGroupId);
      }
      await ValidationIssueCodeAPI.createCodes(codes, validationResult.id, validationResult.documentGroupId);

      // refetch validation result data to update new exception codes
      if (refetchData) props.setValidationResultData(validationResult.id, props.data.supplierInvoice.id);
    }
  }

  const onAssigmentRuleChanged = async (value: AssignmentRule) => {
    showReassignModal(value);
  }

  const assignUser = React.useCallback((userId: number) => {
    onAssigmentRuleChanged({ userIds: [userId], teamId: null });
  }, []);

  const reopenInvoice = async () => {
    const promises: Promise<unknown>[] = [];
    promises.push(DocumentGroupAPI.update(props.data!.documentGroupId, { status: StatusCode.Unposted }));
    promises.push(ValidationAPI.update(props.data!.id, { status: Status.Open }));
    promises.push(logStatusChange(StatusCode.Unposted));

    const resultPromise = await Promise.all(promises);
    if (resultPromise.includes(null)) {
      props.setNotification({ ...APIError, details: { groupId: props.data!.documentGroupId }, reason: `Error on reopen invoice request, resulted null` });
    } else {
      props.setNotification(Reopened);
      props.selectNextDocument();
    }
  }

  const revalidateInvoice = async () => {
    if (!props.data || !props.data.supplierInvoice.id) return;
    const result = await SupplierInvoiceAPI.revalidate(props.data?.supplierInvoice.id);
    if (result === null) {
      props.setNotification(ValidationError);
    } else {
      props.setNotification(Validating);
      props.selectNextDocument();
    }
  }

  const checkStatusOfValidation = async () => {
    if (!props.data) return;

    // check validation status
    const result = await CwPostingLogAPI.fetchLogForInvoiceId(props.data.supplierInvoice.id);
    if (result === null || result.length > 1) {
      props.setNotification({ ...APIError, details: { groupId: props.data!.documentGroupId, invoiceId: props.data.supplierInvoice.id }, reason: `Error fetching status of invoice validation` });
    } else if (result.length === 0) { // invoice has not been posted to cargowise yet
      const documentGroup = await DocumentGroupAPI.fetchGroup(props.data.documentGroupId);
      if (documentGroup === null) {
        props.setNotification({ ...APIError, details: { groupId: props.data!.documentGroupId, invoiceId: props.data.supplierInvoice.id }, reason: `Error fetching status of invoice validation, group fecth result null` });
        setResubmitted(false);
      } else if (documentGroup.lastValidationResult.id === props.data.id || documentGroup.lastValidationResult.valid) { // invoice is still validating
        props.setNotification(Validating);
      } else { // validation was unsuccessful
        props.setNotification(ValidationError);
        props.setValidationResultData(documentGroup.lastValidationResult.id, props.data.supplierInvoice.id);
        props.updateValidationResultForInvoice(props.data.supplierInvoice.id, documentGroup.lastValidationResult);
        setResubmitted(false);
        fetchAccruals();
        activityFeedRef.current && activityFeedRef.current.reFetchContent();
      }
    } else if (result[0].success) { // validation was successful
      fetchAccruals();
      setResubmitted(false);
      props.setNotification(Posted);
      props.selectNextDocument();
    }
  }

  const clearData = async (clearDataTypes: ClearOperationType[]) => {
    if (!props.data?.supplierInvoice.id) return;

    setShowOverlay(true);
    await SupplierInvoiceAPI.clearData(props.data?.supplierInvoice.id, clearDataTypes);
    await props.reloadData();
    setShowOverlay(false);
  }

  const logStatusChange = (newStatus: StatusCode, codes?: APIIssueCode[]) => {
    const newActivityLog = {
      userId,
      documentGroupId: props.data!.documentGroupId,
      type: ActivityType.StatusChange,
      details: {
        oldStatus: props.data!.supplierInvoice.document.documentGroup.status,
        newStatus,
        codes: codes?.map((code) => ({ id: code.id, code: code.code, description: code.description }))
      }
    };
    activityFeedRef.current && activityFeedRef.current.appendLog(newActivityLog);
    return ActivityLogAPI.createLog(newActivityLog);
  }

  const markAsPosted = async () => {
    if (!props.data || !props.data.supplierInvoice.id || !props.data?.documentGroupId) return;

    const result = await SupplierInvoiceAPI.markAsPosted(props.data?.supplierInvoice.id, props.data?.documentGroupId);
    
    if (result === 'OK') {
      props.setNotification(StatusChangeSuccess);
      await props.reloadData();
    } else {
      props.setNotification(StatusChangeError);
    }
  }

  const changesDisabled = groupStatus !== StatusCode.Unposted || isResubmitted || props.changesDisabled;

  const statusText = getStatusText(isResubmitted ? StatusCode.Processing : props.data?.supplierInvoice.document.documentGroup.status || -1);
  const isOverheadSubmitDisabled = documentType === DocumentType.OverheadInvoice && !props.checkPermissionProfileAccess('postOverhead');
  const enableOnlyJobRefs = !props.checkPermissionProfileAccess('editAPHeader');

  let submitButtonTooltip = undefined;
  if (isOverheadSubmitDisabled) {
    submitButtonTooltip = <>You do not have<br /> permission to post<br /> Overhead invoices</>;
  } else if (!isResubmitDisabled) {
    submitButtonTooltip = <>Shipamax will check<br /> this invoice<br /> before posting</>;
  } else if (isResubmitDisabled) {
    submitButtonTooltip = <>Please check<br /> sum of subtotals</>;
  }

  const users = React.useMemo(() => {
    return documentType === DocumentType.OverheadInvoice ? props.users.filter((user) => user.permissionProfile?.canViewOverhead) : props.users;
  }, [documentType, props.users]);

  return (
    <section className="document-action" ref={sectionRef}>
      {showOverlay && (
        <div className="overlay"><Icon style={{ fontSize: '40px', margin: '50% calc(50% - 20px)' }} src="spinner-md" /></div>
      )}
      <div>
        <StatusBarContainer
          buttons={<>
            {props.data && !changesDisabled &&
              <>
                <button
                  className="light-button discard-button dispute-button"
                  onClick={showQueryModal}
              >
                <ShipamaxPauseIcon />
                Query
              </button>
                {props.checkPermissionProfileAccess('discardAP') && <button
                  className="light-button discard-button"
                  onClick={showDiscardModal}
                  data-test-id="discard-button"
              >
                <ShipamaxTrashIcon />
                Discard
              </button>}
                <PostingBehaviourButton
                  options={[
                    {
                      id: DocumentType.SupplierInvoice,
                      label: <>Operational AP Invoice</>,
                      onChange: async () => {
                        setDocumentType(DocumentType.SupplierInvoice);
                        await DocumentContainerAPI.update(props.data!.supplierInvoice.document.id, { documentType: DocumentType.SupplierInvoice });
                      },
                      onClick: showResubmissionModal
                    },
                    {
                      id: DocumentType.OverheadInvoice,
                      label: <>Overhead AP Invoice</>,
                      onChange: async () => {
                        setDocumentType(DocumentType.OverheadInvoice);
                        await DocumentContainerAPI.update(props.data!.supplierInvoice.document.id, { documentType: DocumentType.OverheadInvoice });
                      },
                      onClick: showResubmissionModal
                    }
                  ]}
                  selectedOptionId={documentType}
                  dataTestId="resubmit-button"
                  submitDisabled={(documentType === DocumentType.SupplierInvoice && isResubmitDisabled) || (isOverheadSubmitDisabled || isResubmitDisabled)}
                  submitButtonTooltip={submitButtonTooltip}
                />
              </>
            }
            {groupStatus === StatusCode.Queried && props.checkPermissionProfileAccess('discardAP') && (
              <button
                className="light-button discard-button"
                onClick={showDiscardModal}
                data-test-id="discard-button"
              >
                <ShipamaxTrashIcon />
                Discard
              </button>
            )}
            {props.data && [StatusCode.Discarded, StatusCode.Queried].includes(groupStatus) &&
              <button
                className="full-button"
                onClick={reopenInvoice}
                data-test-id="reopen-button"
              >
                <ShipamaxInboxIcon />
                Re-open
              </button>
            }
            {props.data && [StatusCode.PostedWithIssues].includes(groupStatus) &&
              <>
              <OverlayTrigger
                overlay={<Tooltip>This action will force the document into the Posted status</Tooltip>}
                delayShow={300}
                placement="top"
              >
                <button
                  className="light-button"
                  onClick={markAsPosted}
                >
                  <ShipamaxCheckIcon />
                  Mark as posted
                </button>
              </OverlayTrigger>
              <button
                className="full-button"
                onClick={revalidateInvoice}
                data-test-id="revalidate-button"
              >
                <ShipamaxPostIcon />
                Re-Validate
              </button>
              </>
            }
            {isResubmitted && (
              <>
                <ShipamaxQueueIcon />
                <span>This Invoice is in the reconciliation queue.</span>
                <button className="full-button" onClick={checkStatusOfValidation}>Check status</button>
              </>
            )}
          </>}
          className={isResubmitted ? 'status-bar-container--resubmitted' : ''}
          exceptions={exceptions}
          teams={props.teams}
          users={users}
          usersDetails={usersExceptionsCount}
          assignmentRule={assignmentRule}
          setAssignmentRule={onAssigmentRuleChanged}
          isTeamDropdownDisabled={!props.data || changesDisabled}
          status={statusText}
          groupId={props.data?.documentGroupId}
        />
      </div>
      {modalMode !== undefined && (
        <CodeSelectionModal
          invoiceId={props.data?.supplierInvoice.id}
          show={true}
          hideModal={hideModal}
          mode={modalMode}
          headerText={modalMode === ModalMode.Query ? `Please select one or more reasons for moving the pack to 'In Query'` : undefined}
          team={modalTeam}
          selectedUser={modalUser}
          submit={submit}
          codes={props.issueCodes}
          validationIssueCodes={props.data?.validationIssueCodes || []}
          apDocumentTypes={[documentType]}
          validationType={ValidationType.AP}
        />
      )}
      <div style={{ flex: '1 1 auto', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <div className="document-action__action-row">
          <TabSwitcher
            selectedTab={selectedTab}
            onTabClicked={setSelectedTab}
            tabs={[Tab.DocumentData, Tab.CommentsActivity]}
          />
          {props.data?.supplierInvoice.id && !changesDisabled && selectedTab === Tab.DocumentData && (
            <div className="document-action__action-row__context-menu__wrapper">
              <ContextMenuTrigger id={`grid-context-menu`} mouseButton={0}>
                <div className="document-action__action-row__context-menu__icon">
                  <ShipamaxEllipsisIcon />
                </div>
              </ContextMenuTrigger>
              <ContextMenu id={`grid-context-menu`}>
                {!enableOnlyJobRefs && (
                  <MenuItem
                    onClick={() => clearData([ClearOperationType.Header])}
                  ><b>Clear</b>&nbsp;header form</MenuItem>
                )}
                <MenuItem
                  onClick={() => clearData([ClearOperationType.LineItems])}
                ><b>Delete</b>&nbsp;all subtotals</MenuItem>
                {!enableOnlyJobRefs && (
                  <MenuItem
                    onClick={() => clearData([ClearOperationType.Header, ClearOperationType.LineItems])}
                  ><b>Clear</b>&nbsp;all data</MenuItem>
                )}
              </ContextMenu>
            </div>
          )}
        </div>
        <div className="document-action__tab">
          <div style={{ overflow: 'hidden', display: selectedTab === Tab.DocumentData ? 'flex' : 'none' }}>
            <ExtractedDataForm
              disabled={changesDisabled || !props.data?.supplierInvoice.id}
              exceptions={exceptions}
              data={props.data}
              documentType={documentType}
              currencyOptions={props.currencyOptions}
              issuerDetails={props.issuerDetails}
              addresseeOptions={props.addresseeOptions}
              setNotification={props.setNotification}
              creditorChanged={props.creditorChanged}
              enableOnlyJobRefs={enableOnlyJobRefs}
              setIsResubmitDisabled={setIsResubmitDisabled}
              isResubmitDisabled={isResubmitDisabled}
              removeCoordinates={props.removeCoordinates}
              jobOwnersMap={jobOwnersMap}
              permissionProfile={props.permissionProfile}
              assignUser={assignUser}
              clusters={clusters}
              setClusters={setClusters}
              showCargoWiseSettings={props.showCargoWiseSettings}
              invoiceTotal={invoiceTotal}
              setInvoiceTotal={setInvoiceTotal}
              invoiceNetTotal={invoiceNetTotal}
              totalVat={totalVat}
              setTotalVat={setTotalVat}
              invoiceAccruals={invoiceAccruals}
              setInvoiceAccruals={setInvoiceAccruals}
              toleranceThreshold={toleranceThreshold}
              exchangeRates={filteredExchangeRates}
              setExchangeRates={setExchangeRates}
              cargowiseExchangeRates={cargowiseExchangeRates}
              currency={currency}
              setCurrency={setCurrency}
            />
          </div>
          <div style={{ overflow: 'hidden', display: selectedTab === Tab.CommentsActivity ? 'flex' : 'none', flexGrow: 1 }}>
            <ActivityFeed
              ref={activityFeedRef}
              usersMap={usersMap}
              teamsMap={teamsMap}
              invoiceId={props.data?.supplierInvoice.id!}
              documentGroupId={props.data?.supplierInvoice.document.documentGroup.id}
              postingBehaviour={props.data?.supplierInvoice.document.documentGroup.postingBehaviour}
              setNotification={props.setNotification}
              isForApInvoice={true}
              visible={selectedTab === Tab.CommentsActivity}
              companyDocTypes={{}}
            />
          </div>
        </div>
      </div>
    </section >
  );
});
