import * as React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Notification, APIError } from '../Notification';
import { Icon } from 'pivotal-ui/react/iconography';
import { FAIcon } from '../FAIcon';
import { ShipamaxDownloadFileIcon, ShipamaxRotateIcon, ShipamaxScissorsIcon, ShipamaxSearchMinusIcon, ShipamaxSearchPlusIcon, ShipamaxTableIcon, ShipamaxTimesCircleIcon } from '../../../images/Icons';
import { HighlightOverlay } from './HighlightOverlay';
import ResizeObserver from 'resize-observer-polyfill';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { APIDocCoordinate, DocumentContainerAPI, DocumentType } from '../../../api/documentContainer';
import { DocumentViewerContext } from '../../DocumentViewerContext';
import { TooltipTrigger } from 'pivotal-ui/react/tooltip';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const INITIAL_MAX_PAGES_COUNT = 10;
const OFFSET_PAGES_COUNT = 20;

let resizeTimeoutId: number;

export const PagesRenderingProgress = (props: { maxNumPagesRendered: number, allPagesCount: number }) => (
  <OverlayTrigger overlay={<Tooltip>Due to the document size, some of the pages<br /> are not rendered immediately.</Tooltip>} placement="bottom" delayShow={500}>
    <div className="document-viewer__pdf--controls--max-pages-warning">Pages rendered {props.maxNumPagesRendered} / {props.allPagesCount}<Icon style={{ fontSize: '24px', paddingLeft: '10px' }} src="spinner-md" /></div>
  </OverlayTrigger>
)

interface Props {
  pdfDocument: any;
  documentUrl?: string;
  isDownloadDisabled?: boolean;
  parentDocumentId: number | null;
  filename: string | undefined;
  filePages?: number[] | null;
  multidocFilename?: string | null;
  setNotification: (notification: Notification | null) => void;
  setIsFileSplitterActive?: (value: boolean) => void;
  setNumPages?: (numPages: number) => void;
  showSpinner: boolean;
  hideFileSplitterButton?: boolean;
  hideControls?: boolean;
  hideOverlay?: boolean;
  children?: (params: { pages: any[], hasNotRenderedAllPages: boolean, maxNumPagesRendered: number, allPagesCount: number }) => React.ReactNode;
  documentId: number | undefined;
  filterCoordinates?: (coordinate: APIDocCoordinate) => boolean;
  loadDefaultByWidth?: boolean;
  setShowEditDefinitions?: (value: boolean) => void;
  useContainerRef?: boolean;
  editTableDefinitions?: boolean;
  previewTableDefinitions?: boolean;
  showTableHighlightOnly?: boolean;
  cancelEditTrigger?: number;
  previewChangesTrigger?: number;
  initialPdfScale?: number;
  setActivePage?: (value: number) => void;
  documentType?: number;
  showClose?: boolean;
  handleShowPreview?: (value: boolean) => any;
  reloadGroupData?: () => Promise<void>;
  groupId?: number;
  specificId: number;
  openEditView?: (pageIndex: number) => void;
  pageNavigatorShowing?: boolean;
  onPageClicked?: (pageNumber: number) => void;
  onPageHover?: (pageNumber: number, show: boolean) => void;
  setShowPageNavigator?: (value: boolean) => void;
  displayPageNumber?: boolean;
  activePage?: number;
  setDefinitionsEdited?: (value: boolean) => void;
  applyChangesSubsequentTrigger?: number;
  applyChangesAllTrigger?: number;
  applyChangesCurrentTrigger?: number;
  setApplyChangesSubsequentTrigger?: (value: number) => void;
  setApplyChangesAllTrigger?: (value: number) => void;
  setApplyChangesCurrentTrigger?: (value: number) => void;
  setPreviewChangesTrigger?: (value: number) => void;
  setChangesComplete?: (value: boolean) => void;
  loadOnlyPageIndex?: number;
}

export const DocumentViewer: React.FC<Props> = (props) => {
  const [rotation, setRotation] = React.useState<number>(0);
  const [pdfScale, setPdfScale] = React.useState<number>(props.initialPdfScale || 1);
  const [pdfHeight, setPdfHeight] = React.useState<number>(285);
  const [pdfWidth, setPdfWidth] = React.useState<number>(400);
  const [numPages, setNumPages] = React.useState<number>(0);
  const [maxNumPagesRendered, setMaxNumPagesRendered] = React.useState<number>(INITIAL_MAX_PAGES_COUNT);
  const [scrollPosition, setScrollPosition] = React.useState<number>(0);

  const pageIndexes: number[] = numPages ? (props.filePages || Array.from(Array(numPages).keys())) : [];
  const allPagesCount = pageIndexes.length
  const hasNotRenderedAllPages = maxNumPagesRendered < allPagesCount;

  const documentViewerRef = React.useRef<HTMLDivElement>(null);
  const scrollWindowRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function updateScrollPosition() {
      setScrollPosition(scrollWindowRef.current ? scrollWindowRef.current.scrollTop : 0)
    }

    if (scrollWindowRef && scrollWindowRef.current) {
      scrollWindowRef.current.addEventListener("scroll", updateScrollPosition, false);
      return function cleanup() {
        scrollWindowRef.current && scrollWindowRef.current.removeEventListener("scroll", updateScrollPosition, false);
      };
    }
  }, [scrollPosition]);

  const resizeObserver = React.useRef<ResizeObserver>(new ResizeObserver((entries: ResizeObserverEntry[]) => {
    // resize only if the grabbing is no longer in action (we can identify that by the class 'no-select')
    //if (draggableDivHorizontal[0] && !draggableDivHorizontal[0].classList.contains('no-select')) {
    resizePdf();
  }));

  React.useEffect(() => {
    setMaxNumPagesRendered(INITIAL_MAX_PAGES_COUNT);
  }, [props.documentId]);

  React.useEffect(() => {
    if (!numPages) return;

    const intervalRef = setInterval(() => {
      if (hasNotRenderedAllPages) {
        setMaxNumPagesRendered((currentValue) => currentValue + OFFSET_PAGES_COUNT);
      } else {
        clearInterval(intervalRef);
      }
    }, 2000);

    return () => {
      clearInterval(intervalRef);
    }
  }, [numPages, hasNotRenderedAllPages]);

  React.useEffect(() => {
    let draggableDivHorizontal = document.getElementsByClassName("group-details");
    if (draggableDivHorizontal.length > 0) resizeObserver.current.observe(draggableDivHorizontal[0]);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  React.useEffect(() => {
    if (!props.documentId) return setRotation(0);
    DocumentContainerAPI.fetchDocumentContainer(props.documentId).then((doc) => setRotation(doc.rotation));
  }, [props.pdfDocument]);

  const onDocumentLoadSuccess = (pdf: any) => {
    setNumPages(pdf.numPages);
    props.setNumPages && props.setNumPages(pdf.numPages);
    resizePdf();
  };

  const handleResize = () => {
    // only resize PDF when window resizing has stopped for 700ms
    window.clearTimeout(resizeTimeoutId);
    resizeTimeoutId = window.setTimeout(() => {
      resizePdf();
    }, 500);
  };

  const resizePdf = () => {
    if (documentViewerRef.current) {
      setPdfHeight((documentViewerRef.current as any).offsetHeight - (43 + 17));
      setPdfWidth((documentViewerRef.current as any).offsetWidth);
    }
  };

  const zoomIn = () => {
    setPdfScale(pdfScale + 0.2);
  };

  const zoomOut = () => {
    setPdfScale(pdfScale - 0.2);
  };

  const onLoadError = () => {
    props.setNotification({ ...APIError, details: { documentId: props.documentId }, reason: `Error on document viewer loading.` });
  };

  const rotate = () => {
    let tmpRotation = rotation >= 270 ? 0 : rotation + 90;
    setRotation(tmpRotation);
    if (props.documentId) DocumentContainerAPI.rotateDocument(props.documentId, tmpRotation);
  };

  const onOpenSplit = () => {
    props.setIsFileSplitterActive && props.setIsFileSplitterActive(true);
  };

  const pages: any[] = [];

  pageIndexes.forEach((pageIndex, index) => {
    if (pageIndex >= numPages) {
      console.log('Wrong page index');
    } else if (props.loadOnlyPageIndex == undefined || props.loadOnlyPageIndex === pageIndex) {
      pages.push(<PdfPage
        key={index}
        index={pageIndex}
        totalPages={allPagesCount}
        pdfHeight={props.loadDefaultByWidth ? undefined : pdfHeight}
        pdfWidth={props.loadDefaultByWidth ? pdfWidth : undefined}
        pdfScale={pdfScale}
        rotation={rotation}
        resetZoom={() => setPdfScale(1)}
        scrollableContainer={documentViewerRef.current}
        hideOverlay={props.hideOverlay}
        filterCoordinates={props.filterCoordinates}
        isBeingLoaded={index > maxNumPagesRendered}
        editTableDefinitions={props.editTableDefinitions}
        showTableHighlightOnly={props.showTableHighlightOnly || false}
        cancelEditTrigger={props.cancelEditTrigger}
        previewChangesTrigger={props.previewChangesTrigger}
        setShowEditDefinitions={props.setShowEditDefinitions}
        documentId={props.documentId}
        pdfDocument={props.pdfDocument}
        setNotification={props.setNotification}
        showSpinner={props.showSpinner}
        filePages={props.filePages}
        previewTableDefinitions={props.previewTableDefinitions}
        setActivePage={props.setActivePage}
        documentType={props.documentType}
        reloadGroupData={props.reloadGroupData}
        scrollPosition={scrollPosition}
        groupId={props.groupId}
        specificId={props.specificId}
        openEditView={props.openEditView}
        pageNavigatorShowing={props.pageNavigatorShowing}
        onPageClicked={props.onPageClicked}
        onPageHover={props.onPageHover}
        setShowPageNavigator={props.setShowPageNavigator}
        displayPageNumber={props.displayPageNumber}
        activePage={props.activePage}
        setDefinitionsEdited={props.setDefinitionsEdited}
        applyChangesSubsequentTrigger={props.applyChangesSubsequentTrigger}
        applyChangesAllTrigger={props.applyChangesAllTrigger}
        applyChangesCurrentTrigger={props.applyChangesCurrentTrigger}
        setApplyChangesSubsequentTrigger={props.setApplyChangesSubsequentTrigger}
        setApplyChangesAllTrigger={props.setApplyChangesAllTrigger}
        setApplyChangesCurrentTrigger={props.setApplyChangesCurrentTrigger}
        setPreviewChangesTrigger={props.setPreviewChangesTrigger}
        setChangesComplete={props.setChangesComplete}
      />)
    }
  });

  const documentComponent = (
    <Document
      file={props.pdfDocument}
      onLoadSuccess={onDocumentLoadSuccess}
      className="document-viewer__pdf with-custom-scrollbar"
      loading={<Icon style={{ fontSize: '48px', left: 'calc(50% - 24px)', top: '300px' }} src="spinner-md" />}
      onLoadError={onLoadError}
      error=''
      options={{
        cMapUrl: `${window.location.protocol}//${window.location.host}/cmaps/`,
        cMapPacked: true,
      }}
      inputRef={scrollWindowRef}
    >
      {props.children ? props.children({ pages, hasNotRenderedAllPages, maxNumPagesRendered, allPagesCount }) : pages}
    </Document>
  );

  if (props.hideControls) {
    if (props.useContainerRef) {
      return (
        <div ref={documentViewerRef} className="document-viewer__container">
          {documentComponent}
        </div>
      )
    }
    return documentComponent;
  }

  const documentViewerContext = React.useContext(DocumentViewerContext);

  const pageTableCoordinates = React.useMemo(() => {
    return documentViewerContext.tableCoordinates
  }, [documentViewerContext.tableCoordinates]);

  const tableDefinitionsSupportedDocTypes = [
    DocumentType.CommercialInvoice,
    DocumentType.PackingList
  ]

  return (
    <div ref={documentViewerRef} className="document-viewer__container">
      {props.showSpinner && !props.pdfDocument && <Icon style={{ fontSize: '48px', position: 'absolute', top: '300px' }} src="spinner-md" />}
      {props.pdfDocument &&
        <>
          {!props.hideControls && (
            <div className="document-viewer__pdf--controls">
              <OverlayTrigger overlay={<Tooltip>Zoom out</Tooltip>} placement="bottom" delayShow={500}>
              <button className="document-viewer__pdf--controls--zoom-in active-on-hover" disabled={pdfScale < 0.3} onClick={zoomOut}><ShipamaxSearchMinusIcon /></button>
              </OverlayTrigger>
              <OverlayTrigger overlay={<Tooltip>Zoom in</Tooltip>} placement="bottom" delayShow={500}>
              <button className="document-viewer__pdf--controls--zoom-out active-on-hover" disabled={pdfScale > 5.3} onClick={zoomIn}><ShipamaxSearchPlusIcon /></button>
              </OverlayTrigger>
              <OverlayTrigger overlay={<Tooltip>Rotate clockwise</Tooltip>} placement="bottom" delayShow={500}>
              <button className="document-viewer__pdf--controls--rotate active-on-hover" onClick={rotate}><ShipamaxRotateIcon /></button>
              </OverlayTrigger>
              {props.documentUrl && !props.isDownloadDisabled && (
                <OverlayTrigger overlay={<Tooltip>Download file</Tooltip>} placement="bottom" delayShow={500}>
                  <button className="document-viewer__pdf--controls--download active-on-hover">
                    <a href={props.documentUrl} download={props.multidocFilename || props.filename} target="_blank">
                    <ShipamaxDownloadFileIcon />
                    </a>
                  </button>
                </OverlayTrigger>
              )}
              {props.openEditView && tableDefinitionsSupportedDocTypes.includes(props.documentType || 0) && (
                <OverlayTrigger overlay={<Tooltip>Edit Table Definitions</Tooltip>} placement="bottom" delayShow={500}>
                  <button
                    className='document-viewer__pdf--controls--edit-definitions active-on-hover'
                    onClick={() => {
                      const pageNumber = pageTableCoordinates ? parseInt(Object.keys(pageTableCoordinates)[0]) : 0;
                      props.openEditView && props.openEditView(pageNumber);
                    }}
                  >
                  <ShipamaxTableIcon />
                  </button>
                </OverlayTrigger>
              )}
              {hasNotRenderedAllPages && (
                <PagesRenderingProgress maxNumPagesRendered={maxNumPagesRendered} allPagesCount={allPagesCount} />
              )}
              {!props.hideFileSplitterButton && numPages > 1 && (
                <>
                  <div />
                  {props.parentDocumentId ? (
                    <div className="document-viewer__pdf--controls--split--executed" title="This document has been split from a larger file">
                      <FAIcon name="cut" solid />
                      <span>This document has been split from a larger file</span>
                      <button onClick={onOpenSplit} className="light-button">Edit File Split</button>
                    </div>
                  ) : (
                    <button onClick={onOpenSplit} className="document-viewer__pdf--controls--split active-on-hover">
                      <ShipamaxScissorsIcon />
                      <span>Split Document</span>
                    </button>
                  )}
                </>
              )}
              {props.showClose && (
                <button
                  onClick={() => { props.handleShowPreview && props.handleShowPreview(false) }}
                  className="document-viewer__pdf--controls--close-preview active-on-hover">
                  <TooltipTrigger placement="left" tooltip="Close Preview">
                  <ShipamaxTimesCircleIcon />
                  </TooltipTrigger>
                </button>
              )}
            </div>
          )}
          {documentComponent}
        </>
      }
    </div >
  )
}

interface PdfPageProps {
  index: number;
  totalPages: number;
  pdfHeight: number | undefined;
  pdfWidth: number | undefined;
  pdfScale: number;
  rotation: number;
  resetZoom: () => void;
  scrollableContainer: HTMLDivElement | null;
  hideOverlay?: boolean;
  filterCoordinates?: (coordinate: APIDocCoordinate) => boolean;
  isBeingLoaded: boolean;
  editTableDefinitions?: boolean;
  showTableHighlightOnly?: boolean;
  cancelEditTrigger?: number;
  previewChangesTrigger?: number;
  setShowEditDefinitions?: (value: boolean) => void;
  documentId?: number;
  pdfDocument?: any;
  filename?: string | undefined;
  setNotification: (notification: Notification | null) => void;
  showSpinner?: boolean;
  filePages?: number[] | null;
  previewTableDefinitions?: boolean;
  setActivePage?: (value: number) => void;
  documentType?: number;
  reloadGroupData?: () => Promise<void>;
  scrollPosition?: number;
  groupId?: number;
  specificId: number;
  openEditView?: (pageIndex: number) => void;
  pageNavigatorShowing?: boolean;
  onPageClicked?: (pageNumber: number) => void;
  onPageHover?: (pageNumber: number, show: boolean) => void;
  setShowPageNavigator?: (value: boolean) => void;
  displayPageNumber?: boolean;
  activePage?: number;
  setDefinitionsEdited?: (value: boolean) => void;
  applyChangesSubsequentTrigger?: number;
  applyChangesAllTrigger?: number;
  applyChangesCurrentTrigger?: number;
  setApplyChangesSubsequentTrigger?: (value: number) => void;
  setApplyChangesAllTrigger?: (value: number) => void;
  setApplyChangesCurrentTrigger?: (value: number) => void;
  setPreviewChangesTrigger?: (value: number) => void;
  setChangesComplete?: (value: boolean) => void;
}

export const PdfPage: React.FC<PdfPageProps> = (props) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [rect, setRect] = React.useState<DOMRect | null>(null);
  const [offsetLeft, setOffsetLeft] = React.useState<number>(0);
  const [offsetTop, setOffsetTop] = React.useState<number>(0);
  const [initialRotation, setInitialRotation] = React.useState<number>(0);
  const [hideTextLayer, setHideTextLayer] = React.useState<boolean>(false);
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);

  const onCanvasResize = () => {
    const canvas = containerRef.current?.querySelector(`.react-pdf__Page__canvas`);

    if (canvas) {
      const rect = canvas.getBoundingClientRect();

      setRect(rect);
      setOffsetLeft((canvas as any).offsetLeft);
      setOffsetTop((canvas as any).offsetTop);
    }
  }

  const onLoadSuccess = (page: any) => {
    /*
     *  pdfjs does an initial rotation for some documents
     *  we can access that value and adjust the value of our rotation
    */
    setInitialRotation(page.rotate);
    setIsLoaded(true);
  }

  const resetZoom = () => {
    props.resetZoom();
  }

  const pageRelativeIndex = props.filePages ? props.filePages.indexOf(props.index) : props.index;

  return (
    <div
      className={`relative ${props.onPageClicked && props.activePage === pageRelativeIndex ? "page-active" : ""}`}
      ref={containerRef}
      style={{
        width: rect?.width || '100%',
        height: rect?.height || 0,
        margin: '0 auto',
      }}
      onClick={() => { props.onPageClicked && props.onPageClicked(pageRelativeIndex) }}
      onMouseEnter={() => { props.onPageHover && props.onPageHover(pageRelativeIndex, true) }}
    >
      {props.displayPageNumber && (
        <div className='page-number'>{pageRelativeIndex + 1}</div>
      )}
      {!props.hideOverlay && (
        <HighlightOverlay
          documentScale={props.pdfScale}
          canvasWidth={rect?.width || 0}
          canvasHeight={rect?.height || 0}
          canvasLeftX={offsetLeft}
          canvasTopY={offsetTop}
          rotation={props.rotation}
          resetZoom={resetZoom}
          scrollableContainer={props.scrollableContainer}
          pageIndex={props.index}
          pageRelativeIndex={pageRelativeIndex}
          filterCoordinates={props.filterCoordinates}
          isLoaded={isLoaded}
          editTableDefinitions={props.editTableDefinitions || false}
          showTableHighlightOnly={props.showTableHighlightOnly || false}
          cancelEditTrigger={props.cancelEditTrigger}
          previewChangesTrigger={props.previewChangesTrigger}
          applyChangesSubsequentTrigger={props.applyChangesSubsequentTrigger}
          applyChangesAllTrigger={props.applyChangesAllTrigger}
          applyChangesCurrentTrigger={props.applyChangesCurrentTrigger}
          setApplyChangesSubsequentTrigger={props.setApplyChangesSubsequentTrigger}
          setApplyChangesAllTrigger={props.setApplyChangesAllTrigger}
          setApplyChangesCurrentTrigger={props.setApplyChangesCurrentTrigger}
          setShowEditDefinitions={props.setShowEditDefinitions}
          documentId={props.documentId}
          pdfDocument={props.pdfDocument}
          setNotification={props.setNotification}
          showSpinner={props.showSpinner}
          filePages={props.filePages}
          previewTableDefinitions={props.previewTableDefinitions}
          documentType={props.documentType}
          reloadGroupData={props.reloadGroupData}
          scrollPosition={props.scrollPosition}
          groupId={props.groupId}
          specificId={props.specificId}
          pageContainerRef={containerRef}
          openEditView={props.openEditView}
          pageNavigatorShowing={props.pageNavigatorShowing}
          setShowPageNavigator={props.setShowPageNavigator}
          setDefinitionsEdited={props.setDefinitionsEdited}
          setPreviewChangesTrigger={props.setPreviewChangesTrigger}
          setChangesComplete={props.setChangesComplete}
        />
      )}
      {props.isBeingLoaded ? (
        <div
          className="document-viewer__pdf--empty-page"
          style={{ height: props.pdfHeight, }}
        ><Icon style={{ fontSize: '32px' }} src="spinner-md" /></div>
      ) : (
        <Page
          key={props.index}
          className="document-viewer__pdf--page"
          width={props.pdfWidth}
          height={props.pdfHeight}
          loading=""
          pageNumber={props.index + 1}
          scale={props.pdfScale}
          onLoadSuccess={onLoadSuccess}
          onRenderSuccess={onCanvasResize}
          onGetTextSuccess={(result) => {
            if (result.items.length > 600) {
              setHideTextLayer(true);
            }
          }}
          rotate={props.rotation + initialRotation}
          renderMode="canvas"
          // Disable text and annotation layer of documents that are bigger than 5 pages
          renderTextLayer={props.totalPages <= 10 && !hideTextLayer}
          renderAnnotationLayer={props.totalPages <= 10}
          renderInteractiveForms={false}
        />
      )}
    </div>
  )
}
