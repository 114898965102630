import * as React from 'react';
import { useLocation } from 'react-router';
import { AuthAPI } from '../../api/authentication';
import { getLoginPage } from '../../api/request';

import '../login/login.scss';

const logoSVG = require('../../images/logo-white.svg');

interface Props {
  isReset: boolean;
}

export const SetPassword: React.FC<Props> = (props) => {
  const [email, setEmail] = React.useState<string>('');
  const [updateAction, setUpdateAction] = React.useState<string>('');
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);
  // we support nick+company@shipamax.com
  const emailRegex = /^\w+([\.-]?[\+]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;

  React.useEffect(() => {
    if (props.isReset) {
      setUpdateAction('Reset');
    } else {
      setUpdateAction('Set');
    }
  }, []);

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setShowErrorMessage(false);

    if (email && emailRegex.test(email)) {
      setShowMessage(true);

      AuthAPI.requestPasswordReset(email, !!props.isReset);

      window.setTimeout(() => {
        location.href = getLoginPage();
      }, 4000);
    } else {
      setShowErrorMessage(true);
    }
  }

  return (
    <form className="login-wrapper" onSubmit={onSubmit}>
      <div className="login">
        <div>
          <img src={logoSVG} className="login-logo" />
        </div>
        <div>
          <input name="email" placeholder="Email" type="text" value={email} onChange={onEmailChange} />
        </div>
        <div className={`login-error ${showMessage ? 'show' : ''}`}>
          If your email is correct, password update instructions have been sent to you by email.
          <br /><br />
          You will be redirected to the login page shortly.
        </div>
        <div className={`login-error ${showErrorMessage ? 'show' : ''}`}>
          The provided value is not an email, please try again.
        </div>
        <div>
          <button type="submit" disabled={!email}>{updateAction} password</button>
        </div>
      </div >
    </form>
  );
}
