import * as React from 'react';
import { Modal } from 'pivotal-ui/react/modal';
import { Icon } from 'pivotal-ui/react/iconography';
import { FAIcon } from './FAIcon';

import './confirm-modal.scss';

export enum ConfirmModalType {
  Warning = 'warning',
  Message = 'message'
}

const IconEnvelope = () => (
  <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.15625 6.875C2.30957 6.875 0 9.18457 0 12.0312C0 13.6533 0.762695 15.1787 2.0625 16.1562L25.4375 33.6875C26.6621 34.6006 28.3379 34.6006 29.5625 33.6875L52.9375 16.1562C54.2373 15.1787 55 13.6533 55 12.0312C55 9.18457 52.6904 6.875 49.8438 6.875H5.15625ZM0 18.9062V41.25C0 45.042 3.08301 48.125 6.875 48.125H48.125C51.917 48.125 55 45.042 55 41.25V18.9062L31.625 36.4375C29.1758 38.2744 25.8242 38.2744 23.375 36.4375L0 18.9062Z" fill="#CCE7FF" />
  </svg>
)

const iconMap = {
  [ConfirmModalType.Warning]: <FAIcon name="exclamation-triangle" solid />,
  [ConfirmModalType.Message]: <IconEnvelope />,
}

type RadioOptions = {
  label: string;
  value: number;
  initialSelected?: boolean;
}[]

export interface ConfirmModalProps {
  show: boolean;
  title?: string;
  size?: number;
  text?: string;
  note?: string;
  icon?: React.ReactNode;
  keepOpenOnConfirm?: boolean;
  checkboxValue?: boolean;
  checkboxLabel?: string;
  confirmText?: string;
  cancelText?: string;
  onHide?: () => void;
  onConfirm?: (...args: any[]) => void;
  onCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  modalType?: ConfirmModalType;
  hideCancelBtn?: boolean;
  radioOptions?: RadioOptions;
  onRadioChange?: (selectedOption: number) => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
  const [selectedRadioOption, setSelectedRadioOption] = React.useState<number>(0);

  React.useEffect(() => {
    setShowSpinner(false);
  }, [props.show]);

  const onHide = () => {
    setTimeout(() => {
      if (props.onHide) props.onHide();
    }, 200);
  }

  const onConfirm = (...args: any[]) => {
    if (props.keepOpenOnConfirm) setShowSpinner(true);
    if (props.onConfirm) props.onConfirm(args);
  }

  return (
    <Modal
      title={props.title || null}
      size={`${props.size || 280}px`}
      show={props.show}
      onHide={onHide}
      dialogClassName={`confirm-modal confirm-modal--${props.modalType}`}
    >
      <div id="confirmModalWrap" className="wrapper">
        <div className="confirm-modal__icon-wrapper">
          {props.icon ? props.icon : iconMap[props.modalType || ConfirmModalType.Warning]}
        </div>
        <span dangerouslySetInnerHTML={{ __html: props.text ? props.text : '' }} />
        {props.note && (
          <div className="confirm-modal__note">
            <h3><FAIcon name="exclamation-triangle" solid />Important Note:</h3>
            <p>{props.note}</p>
          </div>
        )}
        {props.radioOptions && (
          <div className='confirm-modal__options'>
            {props.radioOptions.map((option, index) => {
              const isSelected = option.value === selectedRadioOption;

              return (
                <label key={index}>
                  <input type='radio' name='confirm-options' value={option.value} checked={isSelected} onChange={() => {
                    setSelectedRadioOption(option.value);
                    props.onRadioChange && props.onRadioChange(option.value);
                  }} />
                  <span>{option.label}</span>
                </label>
              )
            })}
          </div>
        )}
        <div className="buttons">
          {props.onConfirm && (
            <button id="confirmButton" disabled={showSpinner} className="btn-confirm full-button" onClick={onConfirm}>
              {showSpinner
                ? <Icon src="spinner-md" />
                : (props.confirmText ? props.confirmText : 'Confirm')
              }
            </button>
          )}
          {(!props.hideCancelBtn && !showSpinner) && (
            <button className="btn-cancel light-button" onClick={onHide}>{props.cancelText || 'Cancel'}</button>
          )}
          {props.onCheckboxChange && props.checkboxValue !== undefined &&
            <label className="checkbox">
              <input type="checkbox" defaultChecked={props.checkboxValue} onChange={props.onCheckboxChange} />
              {props.checkboxLabel}
            </label>
          }
        </div>
      </div>
    </Modal>
  );
}
