import * as React from 'react';
import { OptionValue } from 'react-selectize';
import { WrapperLabel } from '../../common/WrapperLabel';
import { NumberInput } from '../../common/NumberInput';
import { TextInput } from '../../common/TextInput';
import { APIDocumentGroupPackline } from '../../../api/documentGroup';
import { BillOfLadinPackLineAPI } from '../../../api/billOfLadingPackLine';
import { DropdownList } from '../../common/DropdownList';
import { FocusDirection, sortSearchOptions } from '../../helpers';
import { CustomSelect } from '../../common/CustomSelect';

import './packline.scss';

interface Props {
  data: APIDocumentGroupPackline;
  packageTypeOptions: OptionValue[];
  onChange: (change: Partial<APIDocumentGroupPackline>) => void;
  deletePackline: (id?: number) => void;
  hideLabels?: boolean;
  onPacklineUpdated: (line: APIDocumentGroupPackline, unitChange: boolean) => void;
  onShiftFocus: (direction: FocusDirection, inputIndex: number) => void;
  shouldFocusInputWithIndex: number;
  onFocusCompleted: () => void;
  disabled: boolean;
  onFocus?: () => void;
}

const WEIGHT_UNIT_OPTIONS = [{ value: 'kg', label: 'kg' }, { value: 't', label: 't' }, { value: 'lb', label: 'lb' }];
const VOLUME_UNIT_OPTIONS = [{ value: 'cbm', label: 'm³' }, { value: 'cf', label: 'ft³' }];

export const Packline: React.FC<Props> = (props) => {
  const [weightUnit, setWeightUnit] = React.useState<string>(props.data.weightUnit || 'kg');
  const [volumeUnit, setVolumeUnit] = React.useState<string>(props.data.volumeUnit || 'cbm');

  // refs for every input which could be focused from code when using arrows
  const inputRefs = [
    React.useRef<any>(null),
    React.useRef<any>(null),
    React.useRef<any>(null),
    React.useRef<any>(null),
    React.useRef<any>(null),
    React.useRef<any>(null),
  ];

  React.useEffect(() => {
    if (props.shouldFocusInputWithIndex !== -1) {
      inputRefs[props.shouldFocusInputWithIndex]?.current.focus();
      props.onFocusCompleted();
    }
  }, [props.shouldFocusInputWithIndex]);

  const update = async (name: string, value: string | number | null, withoutChange?: boolean) => {
    if (props.data?.id && !withoutChange) {
      const result = await BillOfLadinPackLineAPI.update(props.data?.id, { [name]: value });
      props.onPacklineUpdated(result as APIDocumentGroupPackline, ['weightUnit', 'volumeUnit'].includes(name));
    }
  }

  const onFilterOptions = (items: OptionValue[], search: string): OptionValue[] => {
    const filteredOptions = items.filter((option) => option.label.toUpperCase().includes(search.toUpperCase()));
    return sortSearchOptions(filteredOptions, search);
  }

  const onBlurUpdateAndCheckRemoveable = (name: string, value: string | number | null, withoutChange?: boolean) => {
    update(name, value, withoutChange);
    checkIfRemovable();
  }

  const checkIfRemovable = () => {
    // if all fields are empty, delete this container row
    if (!props.data.numberPieces && !props.data.weight && !props.data.volume && !props.data.containerNo && !packageType) {
      props.deletePackline(props.data.id);
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, inputIndex: number): boolean => {
    if (event.key === 'ArrowDown') {
      props.onShiftFocus(FocusDirection.DOWN, inputIndex);
      event.preventDefault();
      return false;
    } else if (event.key === 'ArrowUp') {
      props.onShiftFocus(FocusDirection.UP, inputIndex);
      event.preventDefault();
      return false;
    }

    return true;
  }

  const onChange = (value: string | number, name: string) => {
    props.onChange({ [name]: value });
  }

  const packageType = props.packageTypeOptions.find((option) => option.value === props.data.pieceType) || null;

  return (
    <div className={`grid__row packline ${props.hideLabels ? 'hide-labels' : ''}`}>
      <div className="grid__col-4">
        <WrapperLabel text="Package Count">
          <NumberInput
            value={props.data.numberPieces || null}
            name="numberPieces"
            onChange={onChange}
            onBlur={onBlurUpdateAndCheckRemoveable}
            ref={inputRefs[0]}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(event, 0)}
            precision={0}
            disabled={props.disabled}
            onFocus={props.onFocus}
            fieldData={{
              objectIds: [props.data?.id || -1],
              tableName: 'bol_pack_lines',
              attributeNames: ['number_pieces'],
            }}
          />
        </WrapperLabel>
        <WrapperLabel text="Package Type" title={packageType?.value}>
          <CustomSelect
            options={props.packageTypeOptions}
            value={packageType || undefined}
            filterOptions={onFilterOptions}
            onValueChange={(value) => {
              onChange(value?.value, 'pieceType');
              update('pieceType', value?.value || null);
            }}
            onBlur={checkIfRemovable}
            renderValue={(item: OptionValue) => item.value}
            dropdownMenuSize={'wide'}
            disabled={props.disabled}
            onFocus={props.onFocus}
            fieldData={{
              objectIds: [props.data?.id || -1],
              tableName: 'bol_pack_lines',
              attributeNames: ['piece_type'],
            }}
          />
        </WrapperLabel>
      </div>
      <div className="grid__col-4">
        <WrapperLabel text="Weight">
          <NumberInput
            value={props.data.weight || null}
            name="weight"
            onChange={onChange}
            onBlur={onBlurUpdateAndCheckRemoveable}
            ref={inputRefs[1]}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(event, 1)}
            precision={3}
            disabled={props.disabled}
            unit={
              <DropdownList
                ref={inputRefs[2]}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(event, 2)}
                items={WEIGHT_UNIT_OPTIONS}
                selectedValue={weightUnit}
                onChange={(value: string | number | null) => {
                  const processedValue = (value || 'kg').toString();
                  onChange(processedValue, 'weight-unit-toggle');
                  update('weightUnit', processedValue);
                  setWeightUnit(processedValue);
                }}
                buttonClassName="unit-toggle"
                disabled={props.disabled}
              />
            }
            onFocus={props.onFocus}
            fieldData={{
              objectIds: [props.data?.id || -1],
              tableName: 'bol_pack_lines',
              attributeNames: ['container_weight'],
            }}
          />
        </WrapperLabel>
        <WrapperLabel text="Volume" >
          <NumberInput
            value={props.data.volume || null}
            name="volume"
            onChange={onChange}
            onBlur={onBlurUpdateAndCheckRemoveable}
            ref={inputRefs[3]}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(event, 3)}
            precision={3}
            disabled={props.disabled}
            unit={
              <DropdownList
                ref={inputRefs[4]}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(event, 4)}
                items={VOLUME_UNIT_OPTIONS}
                selectedValue={volumeUnit}
                onChange={(value: string | number | null) => {
                  const processedValue = (value || 'cbm').toString();
                  onChange(processedValue, 'volume-unit-toggle');
                  update('volumeUnit', processedValue);
                  setVolumeUnit(processedValue);
                }}
                buttonClassName="unit-toggle"
                disabled={props.disabled}
              />
            }
            fieldData={{
              objectIds: [props.data?.id || -1],
              tableName: 'bol_pack_lines',
              attributeNames: ['container_volume'],
            }}
            onFocus={props.onFocus}
          />
        </WrapperLabel>
      </div>
      <div className="grid__col-4">
        <WrapperLabel text="Container No." >
          <TextInput
            value={props.data.containerNo || ''}
            name="containerNo"
            onChange={onChange}
            onBlur={onBlurUpdateAndCheckRemoveable}
            ref={inputRefs[5]}
            onKeyDown={(event) => handleKeyDown(event, 5)}
            maxLength={20}
            disabled={props.disabled}
            onFocus={props.onFocus}
            fieldData={{
              objectIds: [props.data?.id || -1],
              tableName: 'bol_pack_lines',
              attributeNames: ['container_number'],
            }}
          />
        </WrapperLabel>
      </div>
    </div>
  );
}
