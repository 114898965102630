import * as React from 'react';
import { CompanyAPI } from '../../../api/company';
import { ChartColors, formatAggregationValue, OFFSET_VALUE, SerieSettings } from '../common';
import { ChartCommonProps, ChartFilterProps, CounterType } from '../ChartWrapper';
import { ChartSettings, GeneralChart } from '../GeneralChart';
import { Map2 } from '../../helpers';
import { getCustomStackedBarItem } from '../charts/CustomStackedBarItem';
import { removeDataForSaveToggles } from '../utils';

enum SerieId {
  InvoiceNumber = 'invoiceNumber',
  InvoiceDate = 'invoiceDate',
  Currency = 'currency',
  InvoiceGrossTotal = 'grossTotal',
  Issuer = 'issuerCgwCode',
  Addressee = 'addresseeCgwCode',
  TotalVAT = 'vatTotal',
  JobReferences = 'jobRef',
  BLNumber = 'bolNum',
  LineNetTotal = 'cluster',
  ExchangeRate = 'exchangeRate',
}

export type SeriesDataType = {
  [key: string]: {
    label: string,
    color: string
  }
}

const seriesData = new Map2<string, SerieSettings>([
  [
    SerieId.InvoiceNumber,
    {
      label: 'Invoice number',
      color: ChartColors.Color1,
    }
  ],
  [
    SerieId.InvoiceDate,
    {
      label: 'Invoice date',
      color: ChartColors.Color2,
    }
  ],
  [
    SerieId.Currency,
    {
      label: 'Currency',
      color: ChartColors.Color3,
    }
  ],
  [
    SerieId.InvoiceGrossTotal,
    {
      label: 'Invoice gross total',
      color: ChartColors.Color4,
    }
  ],
  [
    SerieId.Issuer,
    {
      label: 'Issuer Code',
      color: ChartColors.Color5,
    }
  ],
  [
    SerieId.Addressee,
    {
      label: 'Addressee',
      color: ChartColors.Color9,
    }
  ],
  [
    SerieId.TotalVAT,
    {
      label: 'Total VAT',
      color: ChartColors.Color7,
    }
  ],
  [
    SerieId.JobReferences,
    {
      label: 'Job references',
      color: ChartColors.Color8,
    }
  ],
  [
    SerieId.BLNumber,
    {
      label: 'BL number',
      color: ChartColors.Color11,
    }
  ],
  [
    SerieId.LineNetTotal,
    {
      label: 'Line net total',
      color: ChartColors.Color12,
    }
  ],
  [
    SerieId.ExchangeRate,
    {
      label: 'Exchange Rate',
      color: ChartColors.Color15,
    }
  ]
]);

interface Props extends ChartCommonProps {
  apiFilter: ChartFilterProps;
}

export const CorrectionsChart: React.FC<Props> = (props) => {

  /*
    When rendering bars, the nivo library checks, whether the value is equal to 0 and in that case omits rendering the Bar Component.
    This means, that we are not able to render the grey Bar, which represents the number of total invoices posted.
    Workaround for this is to offset all values by some number
   */
  const offset = props.counterType === CounterType.InvoiceCount ? OFFSET_VALUE : 0;

  const fetchChartData = async () => {
    if (!props.apiFilter.dateRange) return [];

    const result = await CompanyAPI.getApInsightsChartData(props.apiFilter);

    let processedChartData: any[] = result.map((dataPoint) => {
      let keys = seriesData.keysArray().reduce((map: {}, key: any) => ({ ...map, [key]: dataPoint.values.find((item: any) => item.value === key)?.count || 0 }), {})

      if (props.apiFilter.userOptions && props.removeFilteredData) removeDataForSaveToggles(keys, props.apiFilter.userOptions);

      return {
        name: dataPoint.aggregation,
        total: dataPoint.total,
        ...keys
      };
    });

    return processedChartData || [];
  }

  const chartSettings: ChartSettings = props.counterType === CounterType.InvoiceCount ? {
    getBarComponent: getCustomStackedBarItem,
    animate: props.counterType !== CounterType.InvoiceCount,
    reversedKeys: false,
    useMaxValue: true,
  } : { reversedKeys: false };

  return (
    <GeneralChart
      referenceData={props.referenceData}
      apiFilter={props.apiFilter}
      seriesData={seriesData}
      fetchChartData={fetchChartData}
      isFetching={props.isFetching}
      setIsFetching={props.setIsFetching}
      counterType={props.counterType}
      groupMode="grouped"
      innerPadding={props.counterType === CounterType.InvoiceCount ? 1 : undefined}
      showToggles={!props.hideToggles}
      compactToggles
      tooltip={(target) => (
        <p className="chart-tooltip">
          {props.counterType === CounterType.InvoiceCount ? (
            <>
              {formatAggregationValue(target.indexValue, props.apiFilter.aggregation, false, props.referenceData)}<br />
              {seriesData.get(target.id as string)?.label}: <b>{target.value - offset}</b><br />
              Total invoices: <b>{target.data.total}</b>
            </>
          ) : (
            <>
              {formatAggregationValue(target.indexValue, props.apiFilter.aggregation, false, props.referenceData)} - {seriesData.get(target.id as string)?.label}: <b>{target.value.toFixed(2)}%</b>
            </>
          )}
        </p>
      )}
      chartSettings={chartSettings}
      setNotification={props.setNotification}
      renderForCustomDashboard={props.renderForCustomDashboard}
      renderAddToButton={props.renderAddToButton}
      permissionLevel={props.permissionLevel}
      customDashboards={props.customDashboards}
      insightsSettings={props.insightsSettings}
      chartUserSettings={props.chartUserSettings}
      renderExportButton={false}
      exportType='ap'
      customPageSize={props.customPageSize}
      getChartToggles={props.getChartToggles}
      productSection={props.productSection}
    />
  )
}
