import * as React from 'react';
import { CompanyAPI } from '../../../api/company';
import {
  ChartColors,
  SerieSettings,
  correctedStatus,
} from '../common';
import {
  ChartCommonProps,
  ChartFilterProps,
} from '../ChartWrapper';
import { GeneralChart } from '../GeneralChart';
import { StatusCode } from '../../bill-of-lading/common';
import { Map2 } from '../../helpers';
import * as moment from 'moment';
import { removeDataForSaveToggles } from '../utils';

const seriesData = new Map2<number, SerieSettings>([
  [
    StatusCode.Unposted,
    {
      label: 'Open',
      color: ChartColors.Color1,
    }
  ],
  [
    StatusCode.Discarded,
    {
      label: 'Discarded',
      color: ChartColors.Color2,
    }
  ],
  [
    StatusCode.Queried,
    {
      label: 'In Query',
      color: ChartColors.Color5,
    }
  ],
  [
    correctedStatus,
    {
      label: 'Posted after exception',
      color: ChartColors.Color3,
    }
  ],
  [
    StatusCode.Posted,
    {
      label: 'Posted straight through',
      color: ChartColors.Color4,
    }
  ],
]);

interface Props extends ChartCommonProps {
  apiFilter: ChartFilterProps;
}

export const StatusChart: React.FC<Props> = (props) => {
  const fetchChartData = async () => {
    if (!props.apiFilter.dateRange) {
      return [];
    }

    const chartData = await CompanyAPI.getApInsightsChartData(props.apiFilter);

    return chartData.map((dataPoint) => {
      let keys = dataPoint.values.reduce((map: {}, item: any) => ({ ...map, [item.value]: item.count }), {})

      if (props.apiFilter.userOptions && props.removeFilteredData) removeDataForSaveToggles(keys, props.apiFilter.userOptions);

      return {
        name: dataPoint.aggregation,
        total: dataPoint.total,
        ...keys
      }
    });
  }

  return (
    <GeneralChart
      seriesData={seriesData}
      isFetching={props.isFetching}
      setIsFetching={props.setIsFetching}
      referenceData={props.referenceData}
      counterType={props.counterType}
      fetchChartData={fetchChartData}
      apiFilter={props.apiFilter}
      showToggles={!props.hideToggles}
      groupMode="stacked"
      setNotification={props.setNotification}
      renderForCustomDashboard={props.renderForCustomDashboard}
      renderAddToButton={props.renderAddToButton}
      permissionLevel={props.permissionLevel}
      customDashboards={props.customDashboards}
      insightsSettings={props.insightsSettings}
      chartUserSettings={props.chartUserSettings}
      renderExportButton={true}
      exportFilename={`Shipamax_Status_Report_${moment().format('DDMMYYYY')}.csv`}
      exportType='ap'
      customPageSize={props.customPageSize}
      getChartToggles={props.getChartToggles}
      defaultToggles={props.defaultToggles}
      productSection={props.productSection}
    />
  )
}
