import * as React from 'react';
import { PointTooltip, Serie } from '@nivo/line';
import { AxisSettings, SerieToggleState } from '../common';
import { LineChart } from './LineChart';
import { ColorsMapType } from '../utils';

interface Props {
  bottomAxisSettings: AxisSettings;
  displayInPercentage: boolean;
  toggledSeries: SerieToggleState;
  data: any[];
  colorsMap: ColorsMapType,
  tooltip?: PointTooltip;
}

export const IssueCodesLineChartAdapter: React.FC<Props> = (props) => {
  const [processedChartData, setProcessedChartData] = React.useState<any[]>([]);

  React.useEffect(() => {
    const series: { [key: string]: Serie } = {};

    Object.keys(props.toggledSeries).forEach((key) => {
      if (props.toggledSeries[key]) {
        series[key] = { id: key, data: [] };
      }
    });

    props.data.forEach((row) => {
      Object.keys(series).forEach((key) => {
        series[key].data.push({ x: row.name || '', y: row[key] || 0 });
      });
    });

    setProcessedChartData(Object.values(series));
  }, [props.data, props.toggledSeries])

  return (
    <LineChart
      data={processedChartData}
      colorsMap={props.colorsMap}
      displayInPercentage={props.displayInPercentage}
      bottomAxisSettings={props.bottomAxisSettings}
      leftAxisLegend={props.displayInPercentage ? 'Percentage of total' : 'Invoice count'}
      tooltip={props.tooltip}
    />
  )
}
