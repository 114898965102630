import { request } from './request';

export interface APIApiKey {
  id: number;
  created: string;
  createdByUser: number;
  token: string;
  revokedDate: string;
  revokedByUser: number;
  active: boolean;
}

export const ApiKeyAPI = {
  async fetchAll(): Promise<APIApiKey[]> {
    const filter = JSON.stringify({
      where: {
        companyId: parseInt(localStorage.getItem('companyId') || ''),
      },
      order: ['created DESC'],
    });
    return request(`/ApiKeys?filter=${encodeURIComponent(filter)}`);
  },

  async fetch(id: number): Promise<APIApiKey | undefined> {
    return request(`/ApiKeys/${id}`);
  },

  async revokeApiKey(id: number): Promise<unknown> {
    return request(`/ApiKeys/${id}/revokeApiKey`);
  },

  async generateApiKey(): Promise<unknown> {
    return request(`/ApiKeys/generateApiKey`);
  },
}
