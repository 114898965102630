import * as React from 'react';
import { FAIcon } from './FAIcon';

import './toggle.scss';

interface Props {
  id: number | string;
  label?: string;
  color?: string;
  checked: boolean;
  hideToggle?: boolean;
  onChecked: (id: number | string, checked: boolean) => void;
  disabled?: boolean;
  tooltip?: string;
  isCheckbox?: boolean;
}

export const Toggle: React.FC<Props> = (props) => {
  const color = props.color || '#0085FF';

  const onChange = () => {
    !props.disabled && props.onChecked(props.id, !props.checked);
  }

  const cssClasses = [
    'toggle__wrapper',
    props.disabled ? 'toggle__wrapper--disabled' : '',
    props.hideToggle ? 'toggle__wrapper--hidden' : '',
    props.isCheckbox ? 'toggle__wrapper--checkbox' : ''
  ]

  return (
    <label className={cssClasses.join(' ')} title={props.tooltip}>
      <label className="switch">
        <input type="checkbox" onChange={onChange} checked={props.checked} />
        <span className="slider round" style={{ backgroundColor: props.checked ? color : '#A5B1B8' }}>
          <span className={`toggle`} style={{ color: color }}>
            <FAIcon solid name="check" className={`toggle-icon ${!props.checked ? 'hide' : ''}`} />
            {/* <FAIcon solid name="times" className="toggle-icon" /> */}
          </span>
        </span>
      </label>
      {props.label && (
        <span className={`text${props.checked ? '' : ' unchecked'}`}>{props.label}</span>
      )}
    </label>
  );
}
