import * as React from 'react';
import { DocumentViewerContainer } from '../../common/document-viewer/DocumentViewerContainer';
import { Notification, APIError } from '../../common/Notification';
import { APIDocumentGroupSearchDetails, DocumentGroupAPI } from '../../../api/documentGroup';
import { IssuerDetailMap, fwdOrFncPostingBehaviour, StatusCode, PostingBehaviour } from '../common';
import { APIDocumentContainer, APIDocumentType, DocumentType } from '../../../api/documentContainer';
import { OptionValue } from 'react-selectize';
import { APIEmailBasic } from '../../../api/email';
import { LevelOfChanges } from '../../common/document-viewer/FileSplitter';
import { ActionModalType } from '../../common/DocumentActionModal';
import { FileSorter, getFlatFiles } from './FileSorter';
import { DetailFileActions } from './DetailFileActions';

import './file-sorter.scss';
import { ValidationType } from '../../../api/emailAccount';

interface Props {
  details: APIDocumentGroupSearchDetails | null;
  issuerDetails: IssuerDetailMap;
  documentTypes: APIDocumentType[];
  activeDocument: APIDocumentContainer | APIEmailBasic | undefined;
  setActiveDocument: (document: APIDocumentContainer | APIEmailBasic | undefined, scroll?: boolean) => void;
  setNotification: (notification: Notification | null) => void;
  reloadDetailsForGroup: (id: number, reloadBoards?: boolean) => Promise<void>;
  setShowOverlay: (value: boolean) => void;
  reloadDashboard: () => void;
  reloadGroupData: () => Promise<void>;
  closeDetails: () => void;
  setShowActionsModal?: (value: ActionModalType) => void;
  setModalEnabled: (value: boolean) => void;
  validationType: ValidationType | undefined;
  hideGroup: (id: number) => void;
}

export interface DocumentTypesToUpdate {
  id: number;
  documentId: number;
  oldType: DocumentType;
  newType: DocumentType;
}

export const FileSorterContainer: React.FC<Props> = (props) => {
  const [showFileActionsModal, setShowFileActionsModal] = React.useState<ActionModalType>(ActionModalType.Hidden);
  const [loadedFileUnqId, setLoadedFileUnqId] = React.useState<string | undefined>(undefined);

  const flatDocuments = getFlatFiles(props.details?.documents || []);

  const waitForValidationToComplete = async (groupId: number, validationResultId: number | null, resolve: (value?: unknown) => void) => {
    const group = await DocumentGroupAPI.fetchGroup(groupId);

    if (group) {
      if (![StatusCode.Processing].includes(group.status) && group.validationResultId !== validationResultId) {
        // In this case, the validation will get no further than the first validator. Because if it is valid,
        // the group will get the exception 'Needs manual approval'.
        // So we can just resolve here.
        return resolve();
      } else {
        window.setTimeout(() => waitForValidationToComplete(groupId, validationResultId, resolve), 2000);
      }
    } else {
      props.setNotification({ ...APIError, details: { groupId }, reason: `Error fetching group details on FileSorterContainer` });
      return resolve();
    }
  }

  React.useEffect(() => {
    if (loadedFileUnqId !== props.activeDocument?.unqId) {
      props.setActiveDocument(props.activeDocument);
    }
  }, [loadedFileUnqId]);

  React.useEffect(() => {
    props.setModalEnabled(showFileActionsModal !== ActionModalType.Hidden);
  }, [showFileActionsModal]);

  const documentTypesOptions: OptionValue[] = React.useMemo(() => {
    return props.documentTypes.map((type) => {
      return {
        label: type.displayName ? type.cwCode + ' - ' + type.displayName : '',
        value: type.id,
      };
    });
  }, [props.documentTypes]);

  const revalidate = async (addToQueue = true) => {
    if (!props.details?.id) {
      return;
    }

    try {
      // Only HBL -> MBL or MBL -> HBL changes or XXX -> unsupported type
      // Validate document group because some documents were reclassified.
      // We don't need to manually trigger a validation after splitting because that is already done in the split endpoint
      const groupId = props.details.id;
      const lastValidationResultId = props.details.lastValidationResult?.id;

      if (addToQueue) {
        const response = await DocumentGroupAPI.validate(groupId);
        if (response === null) props.setNotification({ ...APIError, details: { groupId }, reason: `Error on revalidate request, response null` });
      }

      await new Promise((resolve) => waitForValidationToComplete(groupId, lastValidationResultId || null, resolve));
    } catch (error) {
      props.setNotification({ ...APIError, details: { groupId: props.details?.id }, reason: `Error on revalidate: ${error}` });
    }
  }

  const startEditMode = () => {
    setShowFileActionsModal(ActionModalType.EditMode);
  }

  const startSplitMode = () => {
    setShowFileActionsModal(ActionModalType.SplitMode);
  }

  const startDragMode = () => {
    setShowFileActionsModal(ActionModalType.DragMode);
  }

  const hideFileSplitterButton = ((props.activeDocument && 'filename' in props.activeDocument) ? props.details?.status !== StatusCode.Unposted : true);

  return (
    <>
      {showFileActionsModal != ActionModalType.Hidden &&
        <DetailFileActions
          details={props.details}
          setNotification={props.setNotification}
          reloadDetailsForGroup={props.reloadDetailsForGroup}
          issuerDetails={props.issuerDetails}
          setShowOverlay={props.setShowOverlay}
          waitForValidationToComplete={waitForValidationToComplete}
          documentTypes={props.documentTypes}
          reloadDashboard={props.reloadDashboard}
          closeDetails={props.closeDetails}
          activeDocument={props.activeDocument}
          actionType={showFileActionsModal}
          setShowActionsModal={setShowFileActionsModal}
          validationType={props.validationType}
        />
      }
      <div className="new-file-sorter__section">
        <div className="file-sorter">
          <div className="files">
            <div className="header">
              <label></label>
              {(props.details?.status === undefined || props.details.status < StatusCode.Posted || (props.details.status === StatusCode.Reopened && props.validationType === ValidationType.GenericTMS)) && (
                <button className="button-action active-on-hover" onClick={startEditMode}>Edit Doc Types</button>
              )}
              {PostingBehaviour.WtgIntegration !== props.details?.postingBehaviour && (
                <>
                  {(flatDocuments.filter((doc) => doc.unqId).length > 1) && (
                    <button className="button-action active-on-hover" onClick={startSplitMode}>Split Pack</button>
                  )}
                  {(props.details?.postingBehaviour && fwdOrFncPostingBehaviour.includes(props.details.postingBehaviour)) && (
                    <button className="button-action active-on-hover" onClick={startDragMode}>Organise Docs</button>
                  )}
                </>
              )}
            </div>
            <FileSorter
              details={props.details}
              issuerDetails={props.issuerDetails}
              documentTypes={props.documentTypes}
              activeDocument={props.activeDocument}
              actionType={ActionModalType.Hidden}
              setActiveDocument={props.setActiveDocument}
              newGroup={[]}
              isDragDisabled={true}
              hierarchyChanges={[]}
            />
          </div>
          <div className="viewer">
            <DocumentViewerContainer
              document={props.activeDocument}
              setFileUnqId={setLoadedFileUnqId}
              fileUnqId={loadedFileUnqId}
              setNotification={props.setNotification}
              documentTypesOptions={documentTypesOptions}
              fileSplitterSettings={{ hideFileSplitterButton }}
              setModalEnabled={props.setModalEnabled}
              loadDefaultByWidth={true}
              onFileSplitFinished={async (levelOfChanges) => {
                if (levelOfChanges === LevelOfChanges.ChangeInUnSupportedTypes) {
                  props.setShowOverlay(true);
                  await props.reloadDetailsForGroup(props.details?.id || -1, true);
                  props.setShowOverlay(false);
                } else if (levelOfChanges === LevelOfChanges.RequireValidation) {
                  props.setShowOverlay(true);
                  await revalidate(false);
                  props.reloadDetailsForGroup(props.details?.id || -1, true);
                  props.setShowOverlay(false);
                } else if ([LevelOfChanges.RequireReParsing, LevelOfChanges.RequireReParsingOnCorrectedInstances].includes(levelOfChanges)) {
                  props.hideGroup(props.details?.id || -1);
                  props.closeDetails();
                }
              }}
              documentTypes={props.documentTypes}
              reloadGroupData={props.reloadGroupData}
            />
          </div>
        </div>
      </div>
    </>
  )
}
