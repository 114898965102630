import * as React from 'react';
import { cn } from '../helpers';

interface Props {
  children: React.ReactNode;
  onClose: () => void;
  className?: string;
}

export const CustomModal: React.FC<Props> = (props) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    }
  }, []);

  const handleClickOutside = React.useCallback((event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
      props.onClose();
    }
  }, [ref]);

  return (
    <div
      ref={ref}
      className={cn(
      "absolute z-[10000] inset-0 flex justify-center items-center bg-grey6/70",
      )}
    >
      <div
        className={cn(
          "w-[360px] bg-white",
          "px-[54px] py-[23px] rounded-2xl",
          props.className || '',
        )}
      >
        {props.children}
      </div>
    </div>
  )
}