import * as React from 'react';
import { AuthAPI } from '../../api/authentication';
import { login } from '../helpers';

import './login.scss';

const logoSVG = require('../../images/logo-white.svg');

interface Props {
  setLoggedIn: (value: boolean) => void;
  onLoginRedirect?: boolean;
}

export const Login: React.FC<Props> = (props) => {
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [loginFailed, setLoginFailed] = React.useState<boolean>(false);

  React.useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');

    if (token) {
      requestLoginViaRedirection(token);
    }
  }, []);

  const requestLogin = async () => {
    if (!email || !password) {
      setLoginFailed(true);
      return;
    }

    const params = {
      email: email.trim(),
      password: password.trim()
    }

    setLoginFailed(false);
    const loginData = await AuthAPI.login(params);

    if (loginData?.id) {
      localStorage.setItem('token', loginData.id);
      const success = await login(true);
      if (!success) setLoginFailed(true);
    } else {
      setLoginFailed(true);
    }
  }

  const requestLoginViaRedirection = async (token: string) => {
    localStorage.setItem('token', token);
    const success = await login(true);
    if (!success) setLoginFailed(true);
  }

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    requestLogin();
  }

  return (
    <form className="login-wrapper" onSubmit={onSubmit}>
      <div className="login">
        <div>
          <img src={logoSVG} className="login-logo" />
        </div>
        <div>
          <input name="email" placeholder="Email" type="text" value={email} onChange={onEmailChange} />
        </div>
        <div>
          <input placeholder="Password" type="password" value={password} onChange={onPasswordChange} />
        </div>
        <div className={`login-error ${loginFailed ? 'show' : ''}`}>
          Incorrect username or password. Please double-check and try again.
        </div>
        <div className="button-area">
          <button className="loginBtn">Login</button>
          <div className="resetPw">
            <a href="/resetpassword-request">Forgotten Password?</a>
          </div>
        </div>
      </div >
    </form>
  );
}
