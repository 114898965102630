import * as React from 'react';
import { AdminHeader } from '../../AdminHeader';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { ButtonWithSpinner } from '../../../common/ButtonWithSpinner';
import { ConfirmModal } from '../../../common/ConfirmModal';
import { APICwReferenceImportOrg, CompanyAPI, ImportStatus } from '../../../../api/company';
import { Notification } from '../../../common/Notification';
import { CustomSelect } from '../../../common/CustomSelect';
import { WrapperLabel } from '../../../common/WrapperLabel';
import { OptionValue } from 'react-selectize';
import { FileInput } from '../../../common/FileInput';
import { isFormInvalid, RecordValidator } from '../../../common/Form';
import { FileError } from '.';

export const importModeOptions: OptionValue[] = [
  { label: 'Replace', value: true },
  { label: 'Add', value: false },
];

interface Props {
  setNotification: (notification: Notification | null) => void;
  setFileErrors: (fileErrors: FileError[]) => void;
  fileErrors: FileError[];
}

export const ProductDataImportForm: React.FC<Props> = (props) => {
  const [selectedFiles, setSelectedFiles] = React.useState<File[] | null>(null);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [isConfirming, setIsConfirming] = React.useState<boolean>(false);
  const [clearExisting, setClearExisting] = React.useState<boolean | undefined>(undefined);
  const [imports, setImports] = React.useState<APICwReferenceImportOrg[]>([]);
  const [formEntered, setFormEntered] = React.useState<boolean>(false);

  const history = useHistory();

  React.useEffect(() => {
    CompanyAPI.getOrganisationDataImports().then((data) => {
      setImports(data);
    });
  }, []);

  const recordValidator: RecordValidator = {
    clearExisting: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: () => clearExisting !== undefined
        },
        {
          errorMessage: 'There is another ongoing import at the moment',
          isValid: () => !imports.find((importOrgData) => ![ImportStatus.Completed, ImportStatus.Failed].includes(importOrgData.status))
        },
      ]
    },
    files: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: () => selectedFiles?.length ? true : false
        },
      ],
    }
  }

  const isUploadDisabled = isFormInvalid({ clearExisting, files: selectedFiles }, recordValidator);

  const onConfirm = () => {
    if (isUploadDisabled) {
      setFormEntered(true);
      return;
    }

    setFormEntered(true);
    if (submitDisabled) return;

    setIsConfirming(true);
  }

  const onUpload = async () => {
    setIsConfirming(false);
    if (!selectedFiles || !selectedFiles.length) {
      return
    }

    try {
      setIsUploading(true);

      const result = await CompanyAPI.importProductData(selectedFiles, !!clearExisting);

      setSelectedFiles(null);
      setIsUploading(false);

      if (result && result.id) {
        props.setNotification({ type: 'success', value: 'Import added to queue' });
        history.push(AdminLinks.ProductDataImports);
        return;
      } else {
        if (result.fileErrors) {
          props.setNotification({ type: 'error', value: 'Import failed: File Errors' });
          props.setFileErrors(result.fileErrors);
          return;
        }

        if (result.error) {
          props.setNotification({ type: 'error', value: `Import failed: ${result.error}` });
          return;
        }

        props.setNotification({ type: 'error', value: 'Import failed' });
      }
      history.push(AdminLinks.ProductDataImports);
    } catch (e) {
      props.setNotification({ type: 'error', value: 'Import failed' });
    }
  }

  const submitDisabled = false;

  return (
    <div className="admin__form__wrapper with-custom-scrollbar">
      <AdminHeader
        headline="Product codes data imports"
        links={[
          {
            url: 'https://s3.eu-west-1.amazonaws.com/storage.shipamax-api.com/ProductCodeImportExample.xlsx',
            label: 'Download template'
          },
          {
            url: '/knowledge-base/What-are-reference-data-uploads---dddb80127fd04e38bd2796e1b80b1727',
            label: 'What are reference data uploads?'
          },
        ]}
      />
      <div className="admin__form__content">
        <div>
          <WrapperLabel text="Import mode">
            <CustomSelect
              options={importModeOptions}
              value={importModeOptions.find((option) => option.value === clearExisting)}
              onValueChange={(option) => setClearExisting(option?.value)}
              formEntered={formEntered}
              validators={recordValidator.clearExisting.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="Select files">
            {selectedFiles && (
              <div className='selected__files'>
                {selectedFiles.map((selectedFile, key) => <li key={`selectedFile${key}`}>{selectedFile.name}</li>)}
              </div>
            )}
            <FileInput
              setNotification={props.setNotification}
              disabled={false}
              formEntered={formEntered}
              validators={recordValidator.files.validators}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              multiple
              maxSize={10}
            />
            {props.fileErrors.length ? (
              <div className="file__errors">
                <p className='file__errors__title'><b>File Errors</b></p>
                {props.fileErrors.map((fileErrs, key) => {
                  return (
                    <div className="file__errors__group" key={`fileErrsGrp${key}`}>
                      <p className="file__errors__group__title">File '<b>{fileErrs.name}</b>':</p>
                      <div className="file__errors__group__errors">
                        {fileErrs.errors.map((err: string, key) => <li key={`fileErrsErr${key}`} dangerouslySetInnerHTML={{ __html: err }} />)}
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : ''}
          </WrapperLabel>
        </div>
        <div>
          <div>
            <Link to={AdminLinks.ProductDataImports}><button className="light-button">Cancel</button></Link>
            <ButtonWithSpinner
              className="full-button"
              onClick={onConfirm}
              showSpinner={isUploading}
            >Upload</ButtonWithSpinner>
          </div>
        </div>
      </div>
      <ConfirmModal
        onHide={() => setIsConfirming(false)}
        show={isConfirming}
        onConfirm={onUpload}
        text={clearExisting ?
          'Are you sure you want to delete your existing Product codes data and replace it with this file? \
          Once submitted, please allow up to 24 hours for the updated data to become active.' :
          'Are you sure you want to add this file to your existing Product codes data? \
          Once submitted, please allow up to 24 hours for the updated data to become active.'}
      />
    </div>
  )
}
