import * as React from 'react';
import { Exception } from '../../../api/validationResults';
import { TextInput } from '../../common/TextInput';
import { WrapperLabel } from '../../common/WrapperLabel';
import { CustomSelect, defaultOnFilterOptions } from '../../common/CustomSelect';
import { OptionValue } from 'react-selectize';
import { Cluster, JobRefClusterAPI } from '../../../api/jobRefCluster';
import { FAIcon } from '../../common/FAIcon';
import { NumberInput } from '../../common/NumberInput';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { ShipamaxDragIcon, ShipamaxTimesIcon } from '../../../images/Icons';

import './gl-cluster-row.scss';

const checkClusterRemovable = (cluster: Cluster, clustersCount: number, currentTaxCode: string | null | undefined): boolean => {
  const totalCleared = (cluster.isHeader && clustersCount === 1) || (cluster.total == null && cluster.vatTotal == null && currentTaxCode == null);
  return totalCleared && (!cluster.description && !cluster.glCode);
}

interface Props {
  cluster: Cluster;
  clustersCount: number;
  exceptions: Exception[];
  disabled: boolean;
  onClusterChanged: (clusterId: number, change: Partial<Cluster>) => void;
  onClusterRemoved: (clusterId: number) => void;
  taxCodesOptions: OptionValue[];
  dragHandleProps: any;
  showCargoWiseSettings: boolean;
}

export const GLClusterRow: React.FC<Props> = (props) => {
  React.useEffect(() => {
    if (!props.cluster.taxCode && props.taxCodesOptions.length === 1) {
      const change = { taxCode: props.taxCodesOptions[0].value };
      JobRefClusterAPI.update(props.cluster.id, change);
      props.onClusterChanged(props.cluster.id, change);
    }
  }, [props.taxCodesOptions]);

  const update = async (name: string, value: string | number | null, withoutChange: boolean) => {

    if ((name === 'branch' || name === 'departmentCode') && value) {
      value = value.toString().toUpperCase();
    }

    if (!withoutChange) {
      JobRefClusterAPI.update(props.cluster.id, { [name]: value });
    }
  }

  const taxCodeOption = React.useMemo(() => {
    return props.taxCodesOptions.find((taxCodeOption) => taxCodeOption.value === props.cluster.taxCode) || null;
  }, [props.taxCodesOptions, props.cluster.taxCode]);

  return (
    <div
      className={`cluster__container`}
      key={props.cluster.id}
    >
      <div className="grid__row">
        <WrapperLabel text="GL Code">
          <div>
            <TextInput
              value={props.cluster.glCode || null}
              name="glCode"
              onChange={(value) => props.onClusterChanged(props.cluster.id, { glCode: value })}
              onBlur={update}
              disabled={props.disabled}
              fieldData={{
                objectIds: [props.cluster.id],
                tableName: 'supplier_invoice_gl_cluster',
                attributeNames: ['gl_code'],
              }}
            />
          </div>
        </WrapperLabel>
        <WrapperLabel text="Description">
          <div>
            <TextInput
              value={props.cluster.description || null}
              name="description"
              onBlur={update}
              onChange={(value) => props.onClusterChanged(props.cluster.id, { description: value })}
              disabled={props.disabled}
              fieldData={{
                objectIds: [props.cluster.id],
                tableName: 'supplier_invoice_gl_cluster',
                attributeNames: ['description'],
              }}
            />
          </div>
        </WrapperLabel>
      </div>
      {!props.cluster.isHeader && (
        <div className="grid__row">
          <div className="grid__col-6" style={{ columnGap: 5 }}>
            {props.showCargoWiseSettings ? (
              <>
                <WrapperLabel text="Branch">
                  <div>
                    <TextInput
                      value={props.cluster.branch || null}
                      name="branch"
                      onBlur={update}
                      maxLength={3}
                      onChange={(value) => props.onClusterChanged(props.cluster.id, { branch: value.toString().toUpperCase() })}
                      disabled={props.disabled}
                      fieldData={{
                        objectIds: [props.cluster.id],
                        tableName: 'supplier_invoice_gl_cluster',
                        attributeNames: ['branch'],
                      }}
                    />
                  </div>
                </WrapperLabel>
                <WrapperLabel text="Department">
                  <div>
                    <TextInput
                      value={props.cluster.departmentCode || null}
                      name="departmentCode"
                      onBlur={update}
                      maxLength={3}
                      onChange={(value) => props.onClusterChanged(props.cluster.id, { departmentCode: value.toString().toUpperCase() })}
                      disabled={props.disabled}
                      fieldData={{
                        objectIds: [props.cluster.id],
                        tableName: 'supplier_invoice_gl_cluster',
                        attributeNames: ['department_code'],
                      }}
                    />
                  </div>
                </WrapperLabel>
              </>
            ) : (
                <>
                  <div />
                  <div />
                </>
              )}
            <WrapperLabel text="Tax Code">
              <CustomSelect
                required
                dropdownMenuSize={'extra-wide'}
                options={props.taxCodesOptions}
                value={taxCodeOption || undefined}
                filterOptions={defaultOnFilterOptions}
                onValueChange={(option) => {
                  props.onClusterChanged(props.cluster.id, { taxCode: option?.value })
                  update('taxCode', option?.value || null, false);
                }}
                disabled={props.disabled}
                fieldData={{
                  objectIds: [props.cluster.id],
                  attributeNames: ['tax_code'],
                  tableName: 'supplier_invoice_gl_cluster'
                }}
                renderValue={(option) => option.value}
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-6" style={{ columnGap: 5 }}>
            <WrapperLabel text="TAX AMOUNT">
              <NumberInput
                required
                className="cluster__total"
                value={props.cluster.vatTotal}
                name="vatTotal"
                onChange={(value) => props.onClusterChanged(props.cluster.id, { vatTotal: isNaN(value) ? null : value })}
                onBlur={update}
                fieldData={{
                  objectIds: [props.cluster.id],
                  tableName: 'supplier_invoice_job_ref_cluster',
                  attributeNames: ['vat_total'],
                }}
                disabled={props.disabled}
              />
            </WrapperLabel>
            <WrapperLabel text="NET TOTAL">
              <NumberInput
                required
                value={props.cluster.total}
                name="total"
                onChange={(value) => props.onClusterChanged(props.cluster.id, { total: isNaN(value) ? null : value })}
                onBlur={update}
                fieldData={{
                  objectIds: [props.cluster.id],
                  tableName: 'supplier_invoice_job_ref_cluster',
                  attributeNames: ['total'],
                }}
                disabled={props.disabled}
                className="cluster__total"
              />
            </WrapperLabel>
          </div>
        </div>
      )}
      {!props.disabled && (
        <div className="gl__cluster__footer">
          <OverlayTrigger overlay={<Tooltip><b>Drag</b> to reorder cards</Tooltip>} placement="left" delayShow={500}>
            <button
              className="drag-handle light-button active-on-hover"
              {...props.dragHandleProps}
              disabled={props.disabled}
            ><ShipamaxDragIcon /></button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip><b>Delete</b> card</Tooltip>} placement="left" delayShow={500}>
            <button
              className="remove-button active-on-hover"
              onClick={() => props.onClusterRemoved(props.cluster.id)}
              disabled={props.disabled}
            ><ShipamaxTimesIcon /></button>
          </OverlayTrigger>
        </div>
      )}
    </div>
  )
}
