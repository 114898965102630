import * as React from 'react';

import './avatar.scss';

interface Props {
  onClick?: () => void;
  color?: string;
  initials: string;
}

export const Avatar: React.FC<Props> = (props) => (
  <div className="avatar" onClick={props.onClick} style={{ color: props.color || '' }}>
    {props.initials}
  </div>
);
