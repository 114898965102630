import * as React from 'react';
import { DocumentType } from '../../../api/documentContainer';
import { StatusCode, statusText } from '../../bill-of-lading/common';
import { CustomSelect } from '../CustomSelect';
import { FileSplitterColumn, hasColumnTypeError, hasColumnPageError } from './FileSplitter';
import { OptionValue } from 'react-selectize';
import { TooltipTrigger } from 'pivotal-ui/react/tooltip';
import { MoveMenu } from './MoveMenu';
import { TextInput } from '../TextInput';
import { move } from '../../dnd-helpers';
import { APIDocumentCorrectionsCount } from '../../../api/documentGroup';
import { ShipamaxExclamationIcon, ShipamaxEyeIcon, ShipamaxMinusCircleIcon, ShipamaxPagesIcon, ShipamaxPencilIcon, ShipamaxPlusCircleIcon, ShipamaxScissorsSplitIcon, ShipamaxSelectIcon, ShipamaxSelectedIcon, ShipamaxTrashIcon } from '../../../images/Icons';

interface Props {
  column?: FileSplitterColumn;
  columns?: FileSplitterColumn[];
  activePageIndex: number;
  setActivePageIndex: (index: number) => void;
  removeColumn: (index: number) => void;
  index: number;
  pages: any[];
  documentTypesOptions: OptionValue[];
  updateDocumentType: (value: DocumentType, columnIndex: number) => void;
  children?: any;
  handleShowPreview?: (value: boolean) => any;
  allPanelsCollapsed?: string;
  setAllPanelsCollapsed?: (value: string) => void;
  setColumns?: (value: FileSplitterColumn[]) => void;
  handleSelectedPages?: (pageIndex: number, columnIndex: number) => void;
  selectedPages?: { pageIndex: number, columnIndex: number }[];
  disabled?: boolean;
  correctionsOnCurrentGroup?: APIDocumentCorrectionsCount[];
  setHasDeletedColumnWithCorrections?: (value: boolean) => void;
  clearSelectedPages?: () => void;
  showPreview?: boolean;
  activeDocumentIndex?: number;
  enableScrollOnDrag?: (e: React.DragEvent) => void;
}

export const FileSplitterColumnWrapper: React.FC<Props> = (props) => {
  const selectedDocumentType = props.documentTypesOptions.find((option) => option.value === props.column?.documentType);
  const isDisabled = (props.column?.document && props.column.document.documentGroup.status !== StatusCode.Unposted);
  const hasTypeError = props.column ? hasColumnTypeError(props.column) : false;
  const hasPageError = props.column ? hasColumnPageError(props.column) : false;
  const [panelOpen, setPanelOpen] = React.useState<boolean>(true);
  const [dropBefore, setDropBefore] = React.useState(false);

  React.useEffect(() => {
    setPanelOpen(
      props.allPanelsCollapsed === "allOpen" ||
      (props.allPanelsCollapsed === "mixed" && panelOpen) ||
      props.allPanelsCollapsed === undefined)
  }, [props.allPanelsCollapsed])

  const firstRender = React.useRef(true);
  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    const documentContainer = document.getElementById(`document-${props.activeDocumentIndex}`);
    documentContainer?.scrollIntoView({ behavior: "smooth" });
  }, [props.activeDocumentIndex]);

  const cssClasses = [
    'file-splitter__column',
    isDisabled ? 'file-splitter__column--disabled' : '',
    hasTypeError || hasPageError ? 'highlighted' : '',
    !props.column ? 'file-splitter__column--placeholder' : '',
    panelOpen ? 'open' : 'closed',
    props.disabled ? "disabled" : "",
    props.activeDocumentIndex === props.index ? "active-document-highlight" : ""
  ];

  const splitDocument = (page: number) => {
    if (props.columns && props.setColumns) {
      const currentColIndex = props.column ? props.columns?.indexOf(props.column) : 0;
      const pages = props.columns[currentColIndex].pages;
      const pageIndex = pages.indexOf(page);

      const colPages = pages.slice(0, pageIndex + 1)
      const newColPages = pages.slice(pageIndex + 1, pages.length)
      props.columns[currentColIndex].pages = colPages;
      const newColumn = { pages: newColPages }
      props.columns.splice(currentColIndex + 1, 0, newColumn)

      props.setColumns([...props.columns]);
      props.clearSelectedPages && props.clearSelectedPages();
    }
  }

  const handleOnDragStart = (e: React.DragEvent<HTMLDivElement>, page: number, docIndex: number, pageIndex: number) => {
    e.stopPropagation();
    const target = e.target as HTMLDivElement;
    e.dataTransfer.setData("selectedPage", page.toString());
    e.dataTransfer.setData("selectedPageIndex", pageIndex.toString());
    e.dataTransfer.setData("sourceDocumentIndex", docIndex.toString());
    target.classList.add("dragging");
  }

  const handleOnDocDrop = (e: React.DragEvent<HTMLDivElement>, docIndex: number) => {
    const target = e.target as HTMLDivElement;

    if (!target.classList.contains("file-splitter__column__container")) {
      return;
    }

    const selectedPageIndex = parseInt(e.dataTransfer.getData("selectedPageIndex"));
    const sourceDocumentIndex = e.dataTransfer.getData("sourceDocumentIndex");

    if (props.columns && props.setColumns) {
      const result = move<number>(
        props.columns[parseInt(sourceDocumentIndex)].pages,
        docIndex === props.columns.length ? [] : props.columns[docIndex].pages,
        { droppableId: sourceDocumentIndex, index: selectedPageIndex },
        { droppableId: docIndex.toString(), index: docIndex === props.columns.length ? 0 : props.columns[docIndex].pages.length }
      );

      const newColumns: FileSplitterColumn[] = [];

      props.columns.forEach((column, index) => {
        if (result[index]) {
          if (result[index].length) {
            newColumns.push({ ...column, pages: result[index] });
          } else {
            //column will be removed, so checking if there was any correction
            if (props.correctionsOnCurrentGroup && props.correctionsOnCurrentGroup.some((c) => c.documentId === column.document?.id && c.corrections > 0)) {
              props.setHasDeletedColumnWithCorrections && props.setHasDeletedColumnWithCorrections(true);
            }
          }
        } else {
          newColumns.push(column);
        }
      });

      if (docIndex === props.columns.length) { // page dropped in placeholder
        newColumns.push({ pages: result[docIndex] });
      }

      props.setColumns(newColumns);
    }
    target.classList.remove("drag-enter");
  }

  const handlePageDrop = (e: React.DragEvent<HTMLDivElement>, pageIndex: number, docIndex: number) => {
    const selectedPage = parseInt(e.dataTransfer.getData("selectedPage"));
    let selectedPageIndex = parseInt(e.dataTransfer.getData("selectedPageIndex"));
    const sourceDocumentIndex = parseInt(e.dataTransfer.getData("sourceDocumentIndex"));
    const target = e.target as HTMLDivElement;

    if (dropBefore && selectedPageIndex < pageIndex) {
      pageIndex = pageIndex - 1;
    }

    if (props.columns && props.setColumns) {
      if (docIndex === sourceDocumentIndex) {
        const pages = Array.from(props.columns[sourceDocumentIndex].pages);

        pages.splice(selectedPageIndex, 1);
        pages.splice(pageIndex, 0, selectedPage);

        props.setColumns(props.columns.map((column, index) => {
          return index === sourceDocumentIndex ? { ...column, pages } : column;
        }));
      } else {
        const sourcePages = Array.from(props.columns[sourceDocumentIndex].pages);
        const destinationPages = Array.from(props.columns[docIndex].pages);

        sourcePages.splice(selectedPageIndex, 1);
        destinationPages.splice(pageIndex, 0, selectedPage);

        props.setColumns(props.columns.map((column, index) => {
          return index === sourceDocumentIndex ? { ...column, pages: [...sourcePages] } : index === docIndex ? { ...column, pages: [...destinationPages] } : column;
        }));
      }
    }
    target.classList.remove("drag-enter");
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target as HTMLDivElement;
    target.classList.add('drag-enter');

    if (target.classList.contains("page-direction-before")) {
      setDropBefore(true);
    } else {
      setDropBefore(false);
    }
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const target = e.target as HTMLDivElement;
    target.classList.remove('drag-enter');
  }

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const target = e.target as HTMLDivElement;
    target.classList.remove("dragging")
  }

  return (
    <div
      className={cssClasses.join(' ')}
      key={props.index}
      id={`document-${props.index}`}
      onDrop={(e) => handleOnDocDrop(e, props.index)}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      <div className="file-splitter__column__top">
        {isDisabled && props.column?.document && (
          <div className="file-splitter__column__status">
            {statusText[props.column.document.documentGroup.status] ? statusText[props.column.document.documentGroup.status] : 'Unrecognised'}
          </div>
        )}
        <div className="file-splitter__column__header">
          {props.column && (
            <>
              {hasTypeError && <span title="Please select document type"><ShipamaxExclamationIcon className="icon-error doc-type-error" /></span>}
              
              <CustomSelect
                disabled={isDisabled}
                placeholder="Select Doc Type"
                options={props.documentTypesOptions}
                value={selectedDocumentType}
                onValueChange={(value) => props.updateDocumentType(value?.value, props.index)}
              />
              <div className="document-alias-wrap">
                <strong>Alias:</strong>
                <TextInput
                  disabled={isDisabled}
                  value={props.column.document && props.column.document.documentAlias || ""}
                  placeholder={`Document ${props.index + 1}`}
                  name={`document-alias-${props.index}`}
                  wrapperClassName="alias-input"
                  onChange={(value) => {
                    if (props.column && props.setColumns && props.columns && props.column.document) {
                      props.column.document.documentAlias = value;
                      props.setColumns([...props.columns]);
                    }
                  }} />
                <ShipamaxPencilIcon className="edit-alias" />
              </div>

              <div className='page-count'>
                <ShipamaxPagesIcon />
                {props.column.pages.length} Page{props.column.pages.length > 1 ? "s" : ""}
              </div>
              <button className="light-button expand-collapse" onClick={() => {
                setPanelOpen(!panelOpen)
                props.setAllPanelsCollapsed && props.setAllPanelsCollapsed("mixed")
              }}>
                {panelOpen ? (
                  <TooltipTrigger tooltip="Collapse">
                    <ShipamaxMinusCircleIcon />
                  </TooltipTrigger>
                ) : (
                  <TooltipTrigger tooltip="Expand">
                      <ShipamaxPlusCircleIcon />
                  </TooltipTrigger>
                )}
              </button>
            </>
          )}
        </div>
      </div>
      <div className="with-custom-scrollbar tray-content-scroll">
        <div
          className="file-splitter__column__container tray__content"
          key={props.index}
        >
          {hasPageError && <span title="Please add a page"><ShipamaxExclamationIcon className="icon-error" /></span>}
          {props.children ? props.children : (
            <>
              {!props.column?.pages.length && (
                <div className="file-splitter__page-placeholder">
                  <div>
                    Drag and drop pages into this tray to create a separate document
                  </div>
                  <div>
                    <button className="light-button" onClick={() => props.removeColumn(props.index)} ><ShipamaxTrashIcon />Delete</button>
                  </div>
                </div>
              )}
              {props.column?.pages.map((filePageIndex, pageIndex) => {
                if (pageIndex >= props.pages.length) return;

                const selectedPage = props.selectedPages && props.selectedPages.filter((data) => {
                  return data.pageIndex === pageIndex && data.columnIndex === props.index;
                })
                return (
                  <div
                    key={`page-${pageIndex}`}
                    className={`page-container ${selectedPage && selectedPage.length ? "selected" : ""}`}
                    draggable
                    onDragStart={(e) => handleOnDragStart(e, filePageIndex, props.index, pageIndex)}
                    onDragEnd={(e) => handleDragEnd(e)}
                    onDrop={(e) => handlePageDrop(e, pageIndex, props.index)}
                    onDrag={(e) => { 
                      e.preventDefault();
                      e.stopPropagation();
                      props.enableScrollOnDrag && props.enableScrollOnDrag(e)
                    }}
                  >
                    <div className="page-direction page-direction-before"></div>
                    <div className="page-direction page-direction-after"></div>
                    <div
                      className={`file-splitter__page ${filePageIndex === props.activePageIndex ? 'file-splitter__page--active' : ''}`}
                      key={pageIndex}
                      onClick={() => props.setActivePageIndex(filePageIndex)}
                    >
                      {props.pages[filePageIndex]}
                      <span className="file-splitter__page__index">{filePageIndex + 1}</span>
                    </div>
                    <div className={`page-controls`} onClick={() => { props.setActivePageIndex(filePageIndex); }} style={{ zIndex: (10000 - (props.index + pageIndex)) }}>
                      <TooltipTrigger tooltip={!props.showPreview ? "Open Preview" : "Close Preview"}>
                      <button
                        className="light-button"
                        onClick={() => { props.handleShowPreview && props.handleShowPreview(true) }}>
                          <ShipamaxEyeIcon />
                          View
                        </button>
                      </TooltipTrigger>
                      <TooltipTrigger tooltip="Select To Move Multiple Pages">
                      <button
                        className="light-button select-page"
                        disabled={isDisabled}
                          onClick={() => props.handleSelectedPages && props.handleSelectedPages(pageIndex, props.index)}>
                          {selectedPage && selectedPage.length ? (
                            <ShipamaxSelectedIcon />
                          ) : (
                            <ShipamaxSelectIcon />
                          )}
                          Select
                        </button>
                      </TooltipTrigger>
                      <MoveMenu
                        disabled={isDisabled}
                        columns={props.columns || []}
                        column={props.column!}
                        documentTypesOptions={props.documentTypesOptions}
                        activePageIndex={props.activePageIndex}
                        setColumns={props.setColumns}
                        selectedPages={props.selectedPages}
                      />
                    </div>
                    {props.column?.pages && pageIndex < props.column?.pages.length - 1 && (
                      <TooltipTrigger onClick={() => splitDocument(filePageIndex)} tooltip="Move All Following Pages To New Document">
                        <ShipamaxScissorsSplitIcon className="split-document" />
                      </TooltipTrigger>
                    )}
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
