import * as React from 'react';
import { TextInput } from './TextInput';
import { APITeam } from '../../api/teams';
import { APIUser, UserStatus } from '../../api/comment';
import { AssignmentRule } from '../../api/userSettings';
import { useKeyPress } from '../helpers';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';

import './document-assignment.scss';
import { ShipamaxDownIcon, ShipamaxUpIcon, ShipamaxUserIcon, ShipamaxUsersIcon } from '../../images/Icons';

const ALL_TEAMS_ID = -1;

/*
 unassigned team - special option to display just documents assigned directly to the team and not to any users
 we use NEGATIVE ID for unassigned teams to distinguish them from normal teams
 */
const addUnassignedAndAllTeams = (teams: APITeam[]): APITeam[] => {
  const updatedTeams = teams.reduce((array: APITeam[], team) => {
    array.push(team);

    if (team.id !== ALL_TEAMS_ID) {
      array.push({ ...team, id: -1 * team.id, name: team.name + ' (unassigned)' });
    }

    return array;
  }, []);
  updatedTeams.unshift({ id: ALL_TEAMS_ID, name: 'All', companyId: 0, disabled: false });
  return updatedTeams;
}

export interface UsersDetails {
  [userId: number]: {
    failedClusters: number[];
  };
}

interface Props {
  teams: APITeam[];
  users: APIUser[];
  usersDetails?: UsersDetails;
  assignmentRule: AssignmentRule;
  setAssignmentRule: (value: AssignmentRule) => void;
  disabled?: boolean;
  readonly?: boolean;
  showOnlyIcon?: boolean;
}

export const DocumentAssignment: React.FC<Props> = (props) => {
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [isWindowVisible, setIsWindowVisible] = React.useState<boolean>(false);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const enabledTeams = props.teams.filter((team) => !team.disabled);
  const teamsArray = props.readonly ? addUnassignedAndAllTeams(enabledTeams) : enabledTeams;
  const usersArray = props.readonly ? props.users : props.users.filter((user) => user.status === UserStatus.Enabled);

  const teamsMap: { [id: number]: APITeam } = React.useMemo(() => {
    return teamsArray.reduce((map, team) => {
      return {
        ...map,
        [team.id]: team
      }
    }, {})
  }, [teamsArray]);

  useKeyPress('Escape', () => {
    setTimeout(() => setIsWindowVisible(false), 20);
  });

  React.useEffect(() => {
    setSearchTerm('');
  }, [isWindowVisible]);

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    }
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as HTMLElement)) {
      setIsWindowVisible(false);
    }
  }

  const sortByExceptionsCount = (user1: APIUser, user2: APIUser) => {
    const user1failedClusters = props.usersDetails && props.usersDetails[user1.id] && props.usersDetails[user1.id].failedClusters || [];
    const user2failedClusters = props.usersDetails && props.usersDetails[user2.id] && props.usersDetails[user2.id].failedClusters || [];

    if (user1failedClusters.length && !user2failedClusters.length) {
      return -1;
    }

    if (!user1failedClusters.length && user2failedClusters.length) {
      return 1;
    }

    if (user1failedClusters.length && user2failedClusters.length) {
      return user2failedClusters.length - user1failedClusters.length;
    }

    return (user1.firstName + user1.lastName).localeCompare(user2.firstName + user2.lastName);
  }

  const toggleSearchWindow = () => {
    setIsWindowVisible(!isWindowVisible);

    if (!isWindowVisible) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 100);
    }
  }

  const filteredTeams: APITeam[] = React.useMemo(() => {
    if (!searchTerm) return teamsArray;

    return teamsArray.filter((team) => team.name.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) !== -1);
  }, [searchTerm, teamsArray]);

  const filteredUsers: APIUser[] = React.useMemo(() => {
    if (!searchTerm) return usersArray.sort(sortByExceptionsCount);

    const usersWithExceptions: APIUser[] = [], usersByCode: APIUser[] = [], usersByFirstName: APIUser[] = [], usersByLastName: APIUser[] = [];

    usersArray.forEach((user) => {
      const hasUserDetails = props.usersDetails && props.usersDetails[user.id];

      if (user.operatorCode && user.operatorCode.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) !== -1) {
        hasUserDetails ? usersWithExceptions.push(user) : usersByCode.push(user);
        return;
      }

      if (user.firstName.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) !== -1) {
        hasUserDetails ? usersWithExceptions.push(user) : usersByFirstName.push(user);
        return;
      }

      if (user.lastName.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) !== -1) {
        hasUserDetails ? usersWithExceptions.push(user) : usersByLastName.push(user);
      }
    });

    return [
      ...usersWithExceptions.sort(sortByExceptionsCount),
      ...usersByCode.sort((u1, u2) => u1.operatorCode ? u1.operatorCode.localeCompare(u2.operatorCode || '') : 1),
      ...usersByFirstName.sort((u1, u2) => u1.firstName.localeCompare(u2.firstName)),
      ...usersByLastName.sort((u1, u2) => u1.lastName.localeCompare(u2.lastName))
    ];
  }, [searchTerm, usersArray, props.usersDetails]);

  const selectedTeam = React.useMemo(() => props.assignmentRule.teamId ? teamsMap[props.assignmentRule.teamId] : null, [props.assignmentRule, teamsArray]);
  const selectedUser = React.useMemo(() => props.assignmentRule.userIds.length ? props.users.find((user) => user.id === props.assignmentRule.userIds[0]) : null, [props.assignmentRule, props.users]);

  let selectedValueLabel = props.showOnlyIcon ? '' : 'All';
  if (selectedUser) {
    selectedValueLabel = selectedUser.firstName + ' ' + selectedUser.lastName;
  } else if (selectedTeam) {
    selectedValueLabel = selectedTeam.name;
  } else if (props.assignmentRule.teamId || props.assignmentRule.userIds.length) {
    selectedValueLabel = 'Unknown';
  }

  return (
    <div className="document-assignment" ref={containerRef}>
      <OverlayTrigger overlay={props.readonly ? <></> : <Tooltip>Reassign</Tooltip>} placement="bottom" delayShow={500}>
        <button
          className={`document-assignment__button ${isWindowVisible ? 'document-assignment__button--active' : ''} active-on-hover`}
          onClick={toggleSearchWindow}
          disabled={props.disabled}
        >
          {selectedUser ? <ShipamaxUserIcon /> : <ShipamaxUsersIcon className='users' />}
          <span className="document-assignment__button__value">{selectedValueLabel}</span>
          {isWindowVisible ? <ShipamaxUpIcon /> : <ShipamaxDownIcon />}
        </button>
      </OverlayTrigger>
      {isWindowVisible && (
        <div className="document-assignment__window">
          <div>
            <TextInput
              name=""
              value={searchTerm}
              setter={setSearchTerm}
              placeholder="Search Names, Codes or Teams"
              ref={searchInputRef}
            />
          </div>
          <div className="document-assignment__window__users-table">
            <h3>Select a person</h3>
            <div className="with-custom-scrollbar with-custom-scrollbar__always-visible">
              {filteredUsers.map((user) => {
                const isSelected = props.assignmentRule.userIds.includes(user.id);
                const failedClusters = props.usersDetails && props.usersDetails[user.id]?.failedClusters;

                return (
                  <div
                    className={`document-assignment__window__row ${isSelected ? 'selected' : ''}`}
                    onClick={() => {
                      if (!isSelected) {
                        props.setAssignmentRule({ teamId: props.readonly ? null : user.teamId, userIds: [user.id] });
                        setIsWindowVisible(false);
                      }
                    }}
                  >
                    <div>
                      <ShipamaxUserIcon /><div className="document-assignment__window__row__value">{user.firstName} {user.lastName}</div>
                      {failedClusters?.length && (
                        <OverlayTrigger overlay={<Tooltip>This user has {failedClusters.length} unresolved {failedClusters.length > 1 ? 'exceptions' : 'exception'} on this invoice</Tooltip>} placement="top" delayShow={500}>
                          <div className="document-assignment__window__row__exceptions-count">{failedClusters.length}</div>
                        </OverlayTrigger>
                      )}
                    </div>
                    <div>{user.operatorCode}</div>
                    <div>{teamsMap[user.teamId]?.name || 'No team'}</div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="document-assignment__window__teams-table">
            <h3>Select a team</h3>
            <div className="with-custom-scrollbar with-custom-scrollbar__always-visible">
              {filteredTeams.map((team) => {
                const isSelected = props.assignmentRule.teamId === team.id;
                const teamId = team.id === ALL_TEAMS_ID && props.readonly ? null : team.id;

                return (
                  <div
                    className={`document-assignment__window__row ${isSelected && !selectedUser ? 'selected' : ''}`}
                    onClick={() => {
                      props.setAssignmentRule({ teamId, userIds: [] })
                      setIsWindowVisible(false);
                    }}
                  >
                    <ShipamaxUsersIcon /> {team.name}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
