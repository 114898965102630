import * as React from 'react';
import { APIComment, CommentAPI } from '../../../api/comment';
import { APIError, Notification } from '../Notification';
import { Avatar } from '../Avatar';
import { FAIcon } from '../FAIcon';

import './add-comment.scss';

interface Props {
  onCommentAdded: (comment: APIComment) => void;
  setNotification: (notification: Notification) => void;
  invoiceId: number | undefined;
  documentGroupId: number;
}

interface AddCommentProps {
  commentValue: string;
  setCommentValue: (value: string) => void;
  submit: () => void;
}

export const AddComment: React.FC<AddCommentProps> = (props) => {
  const [rowsCount, setRowsCount] = React.useState<number>(1);
  const changeCommentValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textareaLineHeight = 14;
    const maxRows = 10;

    const previousRows = event.target.rows;
    event.target.rows = 1; // reset number of rows in textarea

    const currentRows = Math.floor(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    setRowsCount(currentRows < maxRows ? currentRows : maxRows)
    props.setCommentValue(event.target.value);
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // enter key
    // holding shift and enter will create a new line in the textarea,
    // so if you're pressing enter without holding shift, we trigger the onBlur effect
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      submit();
    }
  }

  const submit = () => {
    setRowsCount(1);
    props.submit();
  }

  return (
    <div className="add-comment__input">
      <Avatar initials={'' + localStorage.getItem('firstName')?.charAt(0) + localStorage.getItem('lastName')?.charAt(0)} />
      <textarea
        rows={rowsCount}
        placeholder="Leave a comment..."
        value={props.commentValue}
        onChange={changeCommentValue}
        onKeyDown={handleKeyDown}
        className="with-custom-scrollbar"
      />
    </div>
  )
}

export const AddCommentWrapper: React.FC<Props> = (props) => {
  const [commentValue, setCommentValue] = React.useState<string>('');

  const postComment = async () => {
    if (props.documentGroupId && commentValue.trim()) {
      const newComment: APIComment = {
        comment: commentValue.trim(),
        supplierInvoiceId: props.invoiceId || null,
        documentGroupId: props.documentGroupId,
        created: new Date().toISOString(),
        userId: Number(localStorage.getItem('userId'))
      };

      const result = await CommentAPI.createComment(newComment);

      if (result == null) {
        props.setNotification({ ...APIError, details: { groupId: props.documentGroupId }, reason: `Error on post comment` });
      } else {
        setCommentValue('');
        props.onCommentAdded(newComment);
      }
    }
  }

  return (
    <AddComment commentValue={commentValue} setCommentValue={setCommentValue} submit={postComment} />
  )
}
