import * as React from 'react';
import { APIJobRef, SupplierInvoiceJobRefAPI } from '../../../api/supplierInvoiceJobRef';
import { JobRefClusterAPI, Cluster } from '../../../api/jobRefCluster';
import { ClusterRow } from './ClusterRow';
import { Exception } from '../../../api/validationResults';
import { APIExchangeRate, APIJobOwnersMap } from '../../../api/supplierInvoice';
import { APIClusterAccrual } from '../../../api/clusterAccrual';
import { APIEmailAccount } from '../../../api/emailAccount';
import { APIDocumentContainer, CoordinatesFilter } from '../../../api/documentContainer';
import { APICgwCurrencyExchangeRates } from '../../../api/currency';
import { OptionValue } from 'react-selectize';
import { GLClusterRow } from './GLClusterRow';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { getItemStyle, reorder } from '../../dnd-helpers';
import { AddItemBtn } from '../../common/AddItemBtn';
import { AddClusterHelperDiagram } from '../../../images/AddClusterHelperDiagram';
import { APIPermissionProfileToleranceThreshold } from '../../../api/permissionProfiles';

import './clusters.scss';

const INITIAL_MAX_CLUSTERS_COUNT = 10;
const OFFSET_CLUSTERS_COUNT = 20;

export enum JobRefType {
  Ref = 'REF',
  BL = 'BL',
  CN = 'CN',
  PO = 'PO',
}

export interface JobRef extends APIJobRef {
  type: JobRefType;
}

interface Props {
  clusters: Cluster[];
  invoiceAccruals: APIClusterAccrual[];
  setInvoiceAccruals: (accruals: APIClusterAccrual[]) => void;
  invoiceId: number;
  updateClusters: (clusters: Cluster[]) => void;
  emailAccount?: APIEmailAccount;
  invoiceCurrencyCode: string;
  invoiceNetTotal: number;
  exceptions: Exception[];
  disabled: boolean;
  toleranceThreshold: APIPermissionProfileToleranceThreshold | undefined;
  useSeparateLimit: boolean;
  isAccountsLevelUser: boolean;
  exchangeRates: APIExchangeRate[];
  document: APIDocumentContainer | undefined;
  removeCoordinates: (coordinatesFilter: CoordinatesFilter) => void;
  cargowiseExchangeRates: APICgwCurrencyExchangeRates[];
  taxCodesOptions: OptionValue[];
  isOverheadInvoice: boolean;
  jobOwnersMap: APIJobOwnersMap;
  assignUser: (userId: number) => void;
  showCargoWiseSettings: boolean;
}

export const Clusters = React.forwardRef((props: Props, ref: any) => {
  const [clusterAccrualsUnselectedAt, setClusterAccrualsUnselectedAt] = React.useState<{ [clusterId: number]: number }>({});
  const [cargowiseExchangeRates, setCargowiseExchangeRates] = React.useState<APICgwCurrencyExchangeRates[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDragActive, setIsDragActive] = React.useState<boolean>(false);
  const [maxNumClustersRendered, setMaxNumClustersRendered] = React.useState<number>(INITIAL_MAX_CLUSTERS_COUNT);

  const hasNotRenderedAllClusters = maxNumClustersRendered < props.clusters.length;

  React.useImperativeHandle(ref, () => ({
    resetAccrualsInHeaderCluster() {
      if (props.clusters.length === 1 && props.clusters[0].isHeader) {
        unselectAccrualsInCluster(props.clusters[0].id);
      }
    }
  }));

  React.useEffect(() => {
    setMaxNumClustersRendered(INITIAL_MAX_CLUSTERS_COUNT);
  }, [props.invoiceId]);

  React.useEffect(() => {
    const intervalRef = setInterval(() => {
      if (hasNotRenderedAllClusters) {
        setMaxNumClustersRendered((currentValue) => currentValue + OFFSET_CLUSTERS_COUNT);
      } else {
        clearInterval(intervalRef);
      }
    }, 2000);

    return () => {
      clearInterval(intervalRef);
    }
  }, [hasNotRenderedAllClusters]);

  React.useEffect(() => {
    if (!props.emailAccount?.localCurrency) {
      setCargowiseExchangeRates([]);
    }
    let currencies = props.cargowiseExchangeRates;
    if (props.invoiceCurrencyCode !== props.emailAccount?.localCurrency) {
      currencies = currencies.filter((c) => c.targetCurrency === props.invoiceCurrencyCode);
    }
    if (props.emailAccount?.localCurrency && currencies.length > 0) {
      const invoiceCurrency = {
        sourceCurrency: props.emailAccount?.localCurrency,
        targetCurrency: props.emailAccount?.localCurrency,
        rate: 1,
        id: 0
      }
      currencies.push(invoiceCurrency);
    }
    setCargowiseExchangeRates(currencies);
  }, [props.invoiceCurrencyCode, props.cargowiseExchangeRates, props.invoiceAccruals]);

  const addCluster = async (targetIndex?: number) => {
    setIsLoading(true);
    const isHeader = props.clusters.length === 0;
    const convertHeaderToNonHeader = props.clusters.length === 1;

    const createdCluster = await JobRefClusterAPI.create(props.invoiceId, isHeader);
    if (createdCluster) {
      const newRef = await SupplierInvoiceJobRefAPI.create({
        clusterId: createdCluster.id,
        jobRef: undefined,
        invoiceId: props.invoiceId,
      });

      const newCluster: Cluster = {
        ...createdCluster,
        aggregatedTMSRefs: [
          {
            jobRef: newRef.jobRef,
            aggregatedDocumentRefs: [
              {
                key: newRef.jobRef,
                refs: [{
                  ...newRef,
                  type: JobRefType.Ref,
                  new: true
                }],
              }
            ]
          }
        ]
      };
      const clusters: Cluster[] = [];
      const promises: Promise<unknown>[] = [];
      let iteratorIndex = 0;

      props.clusters.forEach((cluster) => {
        if (iteratorIndex === targetIndex) {
          clusters.push({ ...newCluster, orderIndex: iteratorIndex });
          promises.push(JobRefClusterAPI.update(newCluster.id, { orderIndex: iteratorIndex }));
          iteratorIndex++;
        }

        const headerToNonHeaderClusterChange = convertHeaderToNonHeader ? { isHeader: false } : {};
        clusters.push({ ...cluster, orderIndex: iteratorIndex, ...headerToNonHeaderClusterChange });
        promises.push(JobRefClusterAPI.update(cluster.id, { orderIndex: iteratorIndex, ...headerToNonHeaderClusterChange }));
        iteratorIndex++;
      });

      if (targetIndex === undefined) {
        clusters.push(({ ...newCluster, orderIndex: iteratorIndex }));
        promises.push(JobRefClusterAPI.update(newCluster.id, { orderIndex: iteratorIndex }));
      }

      await Promise.all(promises);

      props.updateClusters(clusters);

    }
    setIsLoading(false);
  }

  const removeCluster = async (clusterId: number) => {
    const result = await JobRefClusterAPI.delete(clusterId);
    if (result !== null) {
      const otherClusters = props.clusters.filter((c) => c.id !== clusterId);

      props.removeCoordinates({
        parentTableName: 'supplier_invoice_job_ref_cluster',
        parentTableIds: [clusterId],
      });

      if (otherClusters.length === 1) {
        if (otherClusters[0].isHeader) {
          // invalid header cluster parsed (it was a hidden cluster), has to be deleted
          await JobRefClusterAPI.delete(otherClusters[0].id);
          props.updateClusters([]);
        } else {
          await JobRefClusterAPI.update(otherClusters[0].id, { isHeader: true });
          props.updateClusters([{ ...otherClusters[0], isHeader: true }]);
        }
      } else {
        props.updateClusters(otherClusters);
      }
    }
  }

  const unselectAccrualsInCluster = (clusterId: number) => {
    /*
      An ugly way how to notify the child component that the selected accruals should be clear out.
      I'll refactor this later - move all cluster related stuff inside ClusterRow, so we don't need this thing at all here
     */
    setClusterAccrualsUnselectedAt({
      ...clusterAccrualsUnselectedAt,
      [clusterId]: Date.now()
    });
  }

  const onClusterChanged = (clusterId: number, change: Partial<Cluster>, ignoreAccrualsReset?: boolean) => {
    props.updateClusters((props.clusters.map((cluster) => {
      if (cluster.id !== clusterId) {
        return cluster;
      }

      return { ...cluster, ...change };
    })));

    if (!ignoreAccrualsReset) unselectAccrualsInCluster(clusterId);
  }

  const onDragEnd = async (result: DropResult) => {
    setIsDragActive(false);
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const reorderedClusters = reorder<Cluster>(
      props.clusters,
      source.index,
      destination.index
    ).map((cluster, index) => ({ ...cluster, orderIndex: index + 1 }));

    props.updateClusters(reorderedClusters);
    const promises: Promise<unknown>[] = [];

    reorderedClusters.forEach((cluster) => {
      promises.push(JobRefClusterAPI.update(cluster.id, { orderIndex: cluster.orderIndex }));
    });

    await Promise.all(promises);
  }

  const displayAddClusterButton = !!props.invoiceId && !props.disabled && !isDragActive;

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setIsDragActive(true)}>
      <Droppable droppableId="clusters-container">
        {(provided, snapshot) => (
          <div
            className="clusters"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {props.clusters.slice(0, maxNumClustersRendered).map((cluster, index) => {
              if (cluster.isHeader && props.clusters.length > 1) return '';
              return (
                <React.Fragment key={index}>
                  <AddItemBtn
                    onClick={() => addCluster(index)}
                    tooltipText={<><b>Add</b> new subtotal</>}
                    disabled={!displayAddClusterButton}
                    disabledButVisible={isLoading}
                  />
                  <Draggable
                    draggableId={'cluster-' + index}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided?.innerRef}
                        {...provided?.draggableProps}
                        style={(provided && snapshot) ? getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        ) : {}}
                        className={`cluster ${props.isOverheadInvoice ? 'cluster--gl' : ''} ${props.disabled ? 'cluster--disabled' : ''}`}
                      >
                        {props.isOverheadInvoice ? (
                          <GLClusterRow
                            key={cluster.id}
                            cluster={cluster}
                            clustersCount={props.clusters.length}
                            exceptions={props.exceptions}
                            disabled={props.disabled}
                            onClusterChanged={onClusterChanged}
                            onClusterRemoved={removeCluster}
                            taxCodesOptions={props.taxCodesOptions}
                            dragHandleProps={provided.dragHandleProps}
                            showCargoWiseSettings={props.showCargoWiseSettings}
                          />
                        ) : (
                          <ClusterRow
                            key={cluster.id}
                            cluster={cluster}
                            invoiceAccruals={props.invoiceAccruals}
                            setInvoiceAccruals={props.setInvoiceAccruals}
                            clustersCount={props.clusters.length}
                            emailAccount={props.emailAccount}
                            invoiceCurrencyCode={props.invoiceCurrencyCode}
                            removeCluster={removeCluster}
                            onClusterChanged={onClusterChanged}
                            invoiceNetTotal={props.invoiceNetTotal}
                            exceptions={props.exceptions}
                            isAccountsLevelUser={props.isAccountsLevelUser}
                            accrualsUnselectedAt={clusterAccrualsUnselectedAt[cluster.id]}
                            disabled={props.disabled}
                            toleranceThreshold={props.toleranceThreshold}
                            useSeparateLimit={props.useSeparateLimit}
                            exchangeRates={props.exchangeRates}
                            document={props.document}
                            removeCoordinates={props.removeCoordinates}
                            cargowiseExchangeRates={cargowiseExchangeRates}
                            dragHandleProps={provided.dragHandleProps}
                            jobOwnersMap={props.jobOwnersMap}
                            assignUser={props.assignUser}
                            clusters={props.clusters}
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                </React.Fragment>
              )
            })}
            <AddItemBtn
              onClick={() => addCluster()}
              tooltipText={<><b>Add</b> {props.clusters.length ? 'subtotal' : 'a reference'}</>}
              alwaysVisible={!props.clusters.length}
              disabled={!displayAddClusterButton}
              disabledButVisible={isLoading}
            />
            {provided.placeholder}
            <div style={{ display: 'flex', justifyContent: 'center', visibility: isDragActive || props.disabled ? 'hidden' : 'visible' }}>
              <AddClusterHelperDiagram />
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
})
