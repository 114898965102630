import { request } from './request';
import { AdminGridSearchParams } from '../components/admin/AdminGrid';
import { APIEmailAccount } from './emailAccount';

export interface APISite {
  id: number;
  name: string;
  companyId: number;
  disabled: boolean;

  mailboxes?: APIEmailAccount[];
}

export const SiteAPI = {
  async fetchAll(params?: AdminGridSearchParams): Promise<APISite[]> {
    const filter = JSON.stringify({
      where: {
        companyId: parseInt(localStorage.getItem('companyId') || ''),
        ...(params?.searchTerm ? { name: { ilike: `%${params.searchTerm.toLowerCase()}%` } } : {}),
      },
      order: 'name',
      include: ['mailboxes'],
      ...(params?.limit ? { limit: params.limit } : {}),
    });
    return request(`/Sites?filter=${encodeURIComponent(filter)}`);
  },

  async fetch(id: number): Promise<APISite> {
    return request(`/Sites/${id}`);
  },

  async update(id: number, change: Partial<APISite>): Promise<unknown> {
    return request(`/Sites/${id}`, 'PATCH', change);
  },

  async create(values: Partial<APISite>): Promise<APISite> {
    return request(`/Sites`, 'POST', values);
  },

  async isFieldValueTaken(field: keyof APISite, value: string, siteId: number | undefined): Promise<boolean> {
    const companyId = parseInt(localStorage.getItem('companyId') || '');
    const filter = JSON.stringify({
      where: {
        companyId,
        disabled: false,
        [field]: value,
        ...(siteId ? { id: { neq: siteId } } : {}),
      },
    });

    const result = await request(`/Sites?filter=${filter}`);
    return !!result.length;
  }
}
