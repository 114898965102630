import * as React from 'react';
import { AuthAPI, initialiseIntercom, PermissionLevel } from '../../api/authentication';
import { AppSection, AppSectionIds, AppSectionNames, NavBar } from '../nav-bar/NavBar';
import { APIError, EventNotificationRequest, Notification } from '../common/Notification';
import { ApInvoiceSection } from '../ap-invoice/ApInvoiceSection';
import { GroupSection, GroupSectionMode } from '../bill-of-lading/GroupSection';
import { APITeam, TeamAPI } from '../../api/teams';
import { APIRelatedUser, APIUser, UserAPI } from '../../api/comment';
import { Route, useHistory, useParams } from 'react-router-dom';
import { APIUserSettings, UserSettings, UserSettingsAPI } from '../../api/userSettings';
import { InsightsSection } from '../insights/InsightsSection';
import { APIEmailAccount, EmailAccountAPI, TMSType } from '../../api/emailAccount';
import { KnowledgeBaseWrapper } from '../knowledge-base/KnowledgeBase';
import { APICompany, CompanyAPI } from '../../api/company';
import { Admin } from '../admin/Admin';
import { CurrencyAPI } from '../../api/currency';
import { OptionValue } from 'react-selectize';
import { CountryAPI } from '../../api/country';
import { APIPermissionProfile } from '../../api/permissionProfiles';
import { NotAuthorizedPage } from '../common/NotAuthorizedPage';

import './main.scss';

export interface UserSettingsProps extends UserSettings {
  update: (settings: UserSettings) => void;
}

export const UserSettingsContext: React.Context<Partial<UserSettingsProps>> = React.createContext({});

export const Main: React.FC = () => {
  const [notification, setNotification] = React.useState<Notification | null>(null);
  const [logoutCard, showLogoutCard] = React.useState<boolean>(false);
  const [selectEnvCard, showSelectEnvCard] = React.useState<boolean>(false);
  const [selectCompanyCard, showSelectCompanyCard] = React.useState<boolean>(false);
  const [teams, setTeams] = React.useState<APITeam[]>([]);
  const [users, setUsers] = React.useState<APIUser[]>([]);
  const [user, setUser] = React.useState<APIUser | null>();
  const [company, setCompany] = React.useState<APICompany | null>(null);
  const [permissionLevel, setPermissionLevel] = React.useState<number>(1);
  const [permissionProfile, setPermissionProfile] = React.useState<APIPermissionProfile | null>(null);
  const [userSettings, setUserSettings] = React.useState<APIUserSettings>();
  const [mailboxes, setMailboxes] = React.useState<APIEmailAccount[]>([]);
  const [currencyOptions, setCurrencyOptions] = React.useState<OptionValue[]>([]);
  const [countryOptions, setCountryOptions] = React.useState<OptionValue[]>([]);
  const [isChildUser, setIsChildUser] = React.useState<boolean>(false);
  const [relatedUsers, setRelatedUsers] = React.useState<APIRelatedUser[]>([]);
  const [eventsToListen, setEventsToListen] = React.useState<EventNotificationRequest[]>([]);
  const [notificationList, setNotificationList] = React.useState<{ notification: Notification, groupId: number }[]>([]);
  const [showNotAuthorized, setShowNotAuthorized] = React.useState<boolean>(false);
  // Table Coordinates corrections feature is initially enabled with flag, whenever it is enabled to everyone ideally this can be removed
  const [enableTableCoordinates, setEnableTableCoordinates] = React.useState<boolean>(false);

  const params: {
    idView: string | undefined; section?: string
  } = useParams();
  const history = useHistory();

  const colorTheme = localStorage.getItem('colorTheme');
  const colorThemeName = parseInt(colorTheme || '') ? 'dhl' : 'basic';

  const showCargoWiseSettings = mailboxes.some((emailAccount) => emailAccount.tmsType === TMSType.Cargowise);

  React.useEffect(() => {
    setShowNotAuthorized(false);
    fetchUserSettingsFromAPI();
    fetchTeamsFromAPI();
    fetchUsersFromAPI();
    fetchUserFromAPI();
    fetchMailboxesFromAPI();
    fetchCompanySettingsFromAPI();
    fetchCurrenciesFromAPI();
    fetchCountriesFromAPI();
    fetchCompanyFromAPI();
    fetchRelatedUsersFromAPI();
    // I have no idea why, but when I initialise intercom without a delay it throws an error...
    window.setTimeout(() => initialiseIntercom(), 5000);
  }, []);

  React.useEffect(() => {
    setShowNotAuthorized(false);
    if (window.location.search.includes('timestamp')) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      urlSearchParams.delete('timestamp');

      if ([...urlSearchParams.keys()].length) {
        history.push(`${window.location.pathname}?${urlSearchParams.toString()}`);
      } else {
        history.push(window.location.pathname);
      }
    }
  }, [window.location.href]);

  // set permission level when teams are loaded
  React.useEffect(() => {
    if (user) {
      setPermissionLevel(user.permissionLevel || PermissionLevel.Standard);
      setPermissionProfile(user.permissionProfile);
      setEnableTableCoordinates(user.enableTableCoordinates);
      setIsChildUser(Number(localStorage.getItem('parentUserId')) ? true : false);
    }
  }, [teams, user]);

  const logout = () => {
    AuthAPI.logout();
  }

  const clearNotification = (event: React.MouseEvent<HTMLDivElement>) => {
    setNotification(null);
    showLogoutCard(false);
    showSelectEnvCard(false);
    showSelectCompanyCard(false);
  }

  const fetchCurrenciesFromAPI = async () => {
    let currencies = await CurrencyAPI.fetchAll();
    if (currencies === null) {
      setNotification({ ...APIError, details: {}, reason: `Error loading currencies, returned null` });
      currencies = [];
    }
    setCurrencyOptions(currencies.map((currency) => ({ value: currency.id, label: currency.currency })));
  }

  const fetchCountriesFromAPI = async () => {
    let countries = await CountryAPI.fetchAll();
    if (countries === null) {
      setNotification({ ...APIError, details: {}, reason: `Error loading countries, returned null` });
      countries = [];
    }
    setCountryOptions((countries || []).map((country) => ({ value: country.id, label: `${country.code} - ${country.name}` })));
  }

  const fetchMailboxesFromAPI = async () => {
    let mailboxes = await EmailAccountAPI.fetchAll();
    if (mailboxes === null) {
      setNotification({ ...APIError, details: {}, reason: `Error loading Mailboxes, returned null` });
      mailboxes = [];
    }
    setMailboxes(mailboxes);
  }

  const fetchTeamsFromAPI = async () => {
    let teams = await TeamAPI.fetchAll();
    if (teams === null) {
      setNotification({ ...APIError, details: {}, reason: `Error loading teams, returned null` });
      teams = [];
    }
    setTeams(teams);
  }

  const fetchUsersFromAPI = async () => {
    let users = await UserAPI.fetchAllVisible();
    if (users === null) {
      setNotification({ ...APIError, details: {}, reason: `Error loading Users, returned null` });
      users = [];
    }
    setUsers(users);
  }

  const fetchRelatedUsersFromAPI = async () => {
    let relatedUsers = await UserAPI.fetchRelatedUsers();
    setRelatedUsers(relatedUsers);
  }

  const fetchUserFromAPI = async () => {
    let user = await AuthAPI.fetchMe();
    if (user === null) {
      setNotification({ ...APIError, details: {}, reason: `Error loading current user data, returned null` });
    }
    setUser(user);
  }

  const fetchCompanyFromAPI = async () => {
    let company = await CompanyAPI.fetch();
    if (company === null) {
      setNotification({ ...APIError, details: {}, reason: `Error loading current company, returned null` });
    }
    setCompany(company);
  }


  const fetchUserSettingsFromAPI = async () => {
    let settings;
    let user = await UserSettingsAPI.fetch();

    if (!user?.userSettings) {
      settings = await UserSettingsAPI.create();
      if (!settings) return;
    } else {
      settings = user.userSettings;
    }

    localStorage.setItem('userSettingsId', settings.id.toString());

    setUserSettings(settings);
  }

  const fetchCompanySettingsFromAPI = async () => {
    const companyData = await CompanyAPI.fetch();
    if (companyData === null) {
      setNotification({ ...APIError, details: {}, reason: `Error loading company settings, returned null` });
    }
    localStorage.setItem('colorTheme', companyData.colorTheme.toString());
  }

  const updateUserSettings = async (settings: UserSettings) => {
    const newSettings = await UserSettingsAPI.update(settings);
    setUserSettings(newSettings);
  }

  const checkPermissionProfileAccess = (section: keyof APIPermissionProfile): boolean => {
    if (!permissionProfile) return false;
    return permissionProfile[section] ? true : false;
  }

  const showNotAuthorizedPage = () => {
    setShowNotAuthorized(true);
  }

  const addEventsToListen = (event: EventNotificationRequest) => {
    const updatesEvents = [...eventsToListen];
    updatesEvents.push(event);
    setEventsToListen(updatesEvents);
  }

  const removeEventToListenById = (ids: number[]) => {
    const updatedEvents = eventsToListen.filter((e) => !ids.includes(e.id));
    setEventsToListen(updatedEvents);
  }

  const removeGroupNotifications = (groupId: number) => {
    const updatedNotificationList = notificationList.filter((n) => n.groupId !== groupId);
    setNotificationList(updatedNotificationList);
  }

  if (!userSettings) return null;
  const sectionId = AppSectionIds[params.section as AppSection] ?? userSettings.settings.appSection;

  if (!params.section) {
    if (sectionId === AppSectionIds[AppSection.Admin]) {
      history.push(`/${AppSection.Admin}/users`);
    } else {
      history.push(`/${AppSectionNames[sectionId]}`);
    }
  }

  const selectedGroupId = isNaN(Number(params.idView)) ? undefined : Number(params.idView);
  let activeSection;
  switch (sectionId) {
    case AppSectionIds[AppSection.BillOfLading]:
      activeSection = checkPermissionProfileAccess('accessForwarding') ? (
        <GroupSection
          setNotification={setNotification}
          teams={teams}
          users={users}
          mode={GroupSectionMode.General}
          countryOptions={countryOptions}
          selectedGroupId={selectedGroupId}
          showNotAuthorizedPage={showNotAuthorizedPage}
          addEventsToListen={addEventsToListen}
          removeGroupNotifications={removeGroupNotifications}
          enableTableCoordinates={enableTableCoordinates}
        />
      ) : (
        <NotAuthorizedPage />
      );
      break;
    case AppSectionIds[AppSection.WtgIntegration]:
      activeSection = permissionProfile?.accessWtgIntegration ? (
        <GroupSection
          setNotification={setNotification}
          teams={teams}
          users={users}
          mode={GroupSectionMode.WtgIntegration}
          countryOptions={countryOptions}
          selectedGroupId={selectedGroupId}
          showNotAuthorizedPage={showNotAuthorizedPage}
          addEventsToListen={addEventsToListen}
          removeGroupNotifications={removeGroupNotifications}
          enableTableCoordinates={enableTableCoordinates}
        />
      ) : (
        <NotAuthorizedPage />
      );
      break;
    case AppSectionIds[AppSection.ApInsights]:
      activeSection = checkPermissionProfileAccess('accessInsights') ? (
        <InsightsSection setNotification={setNotification} permissionLevel={permissionLevel} permissionProfile={permissionProfile} />
      ) : (
        <NotAuthorizedPage />
      );
      break;
    case AppSectionIds[AppSection.Admin]:
      activeSection = permissionLevel === PermissionLevel.Admin ? (
        <Admin
          setNotification={setNotification}
          showCargoWiseSettings={showCargoWiseSettings}
          currencyOptions={currencyOptions}
          countryOptions={countryOptions}
          refetchUserDetails={fetchUserFromAPI}
          users={users}
        />
      ) : (
        <NotAuthorizedPage />
      );
      break;
    case AppSectionIds[AppSection.KnowledgeBase]:
      activeSection = <KnowledgeBaseWrapper />
      break;
    default:
      // default to AP Invoice section
      activeSection = (!permissionProfile || permissionProfile.accessAP) ? (
        <Route path={["/apinvoice"]} render={() => (
          <ApInvoiceSection
            setNotification={setNotification}
            teams={teams}
            users={users}
            currencyOptions={currencyOptions}
            permissionProfile={permissionProfile}
            permissionLevel={permissionLevel}
            userSettings={userSettings}
            showCargoWiseSettings={showCargoWiseSettings}
            checkPermissionProfileAccess={checkPermissionProfileAccess}
          />
        )} />
      ) : (
        <NotAuthorizedPage />
      );
  }

  if (!isChildUser && (!permissionProfile || !user)) activeSection = <></>;

  return (
    <div className={`theme-${colorThemeName}`}>
      <UserSettingsContext.Provider value={{ ...userSettings.settings, update: updateUserSettings }}>
        <div className={`app`} onClick={clearNotification}>
          <Notification
            eventsToListen={eventsToListen}
            removeEventToListen={removeEventToListenById}
            notification={notification}
            notificationList={notificationList}
            setNotificationList={setNotificationList}
            className={sectionId === AppSectionIds[AppSection.BillOfLading] ? 'notification-padding-right' : ''}
          />
          <div className={`exception-manager ${sectionId === AppSectionIds[AppSection.APInvoice] ? 'exception-manager__ap-invoice' : ''}`}>
            <NavBar
              permissionLevel={permissionLevel}
              permissionProfile={permissionProfile}
              logout={logout}
              logoutCardVisible={logoutCard}
              showLogoutCard={showLogoutCard}
              showSelectEnvCard={showSelectEnvCard}
              selectEnvCardVisible={selectEnvCard}
              showSelectCompanyCard={showSelectCompanyCard}
              selectCompanyCardVisible={selectCompanyCard}
              activeSectionId={sectionId}
              checkPermissionProfileAccess={checkPermissionProfileAccess}
              isChildUser={isChildUser}
              company={company}
              relatedUsers={relatedUsers}
              userSettings={userSettings}
            />
            {showNotAuthorized ? <NotAuthorizedPage message="This page is not accessbile or the selected resource does not exists." /> : activeSection}
          </div>
        </div>
      </UserSettingsContext.Provider>
    </div>
  );
}
