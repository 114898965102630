import * as React from 'react';
import { TotalsInput } from './TotalsInput';
import { parseNumber } from '../helpers';
import { ClusterStatus } from '../../api/jobRefCluster';

import './invoice-total-difference.scss';

const formatValue = (value: number): string => {
  const sign = value > 0 ? '+' : '-';
  const formattedValue = Math.abs(value).toLocaleString(navigator.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 });

  return sign + ' ' + formattedValue;
}

interface Props {
  totalVat: number;
  invoiceTotal: number;
  calculatedTotal: number;
  label: string;
  isResubmitDisabled: boolean;
  setIsResubmitDisabled?: (value: boolean) => void;
  isCommercialInvoice?: boolean;
  failingToolTip?: string;
  taxMode?: boolean;
  hasDifference?: boolean;
}

export const InvoiceTotalDifference: React.FC<Props> = (props) => {
  const totalsDifference = props.taxMode ? props.calculatedTotal - parseNumber(props.totalVat) : props.calculatedTotal + parseNumber(props.totalVat) - parseNumber(props.invoiceTotal);
  const hasDifference = props.hasDifference || Math.abs(totalsDifference) > 0.001;
  const failedIcon = props.isCommercialInvoice ? ClusterStatus.Warning : ClusterStatus.Failed;

  if (hasDifference && !props.isResubmitDisabled) {
    if (props.setIsResubmitDisabled)
      props.setIsResubmitDisabled(true);
  } else if (!hasDifference && props.isResubmitDisabled) {
    if (props.setIsResubmitDisabled)
      props.setIsResubmitDisabled(false);
  }

  return (
    <div className={`form__total--difference ${props.taxMode ? "tax-mode" : ""}`}>
      {!props.isCommercialInvoice && hasDifference && (
        <span className="form__total--difference__value">({formatValue(totalsDifference)})</span>
      )}
      <TotalsInput
        value={props.calculatedTotal}
        label={props.label}
        disabled={true}
        status={hasDifference ? failedIcon : ClusterStatus.Matched}
        showIcon={props.isCommercialInvoice ? true : !hasDifference}
        differenceValue={props.isCommercialInvoice && hasDifference ? formatValue(totalsDifference) : undefined}
        iconToolTip={hasDifference ? props.failingToolTip : undefined}
        readOnly
      />
    </div>
  )
}
