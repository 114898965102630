
const API_VERSION = 'v1';

let SERVER_URL: string;
let LOGIN_URL: string;

switch (process.env.DEPLOY_ENV) {
  case 'test':
    SERVER_URL = `https://developer.ff-test.shipamax-api.com`;
    LOGIN_URL = `https://em.ff-test.shipamax.com/login`;
    break;
  case 'dev':
    SERVER_URL = `http://localhost:5000`;
    LOGIN_URL = `http://localhost:3334/login`;
    break;
  case 'staging':
    SERVER_URL = `https://developer.ff-stage.shipamax-api.com`;
    LOGIN_URL = `https://em.ff-stage.shipamax.com/login`;
    break;
  case 'staging-2':
    SERVER_URL = `https://developer-2.ff-stage.shipamax-api.com`;
    LOGIN_URL = `https://em-2.ff-stage.shipamax.com/login`;
    break;
  case 'ngrok':
    SERVER_URL = `https://shipamax-api.ngrok.io`;
    LOGIN_URL = `https://shipamax.com/login-freight`;
    break;
  case 'prod':
  default:
    SERVER_URL = `https://developer.shipamax-api.com`;
    LOGIN_URL = `https://em.shipamax.com/login`;
    break;
}

const fetchRetry = async (endpoint: string, options: RequestInit, retries = 3): Promise<Response> => {
  const retryCodes = [500, 502, 503, 504, 522, 524];
  try {
    const res = await fetch(endpoint, options);
    if (res.ok) return res;
    
    if (retries > 0 && retryCodes.includes(res.status)) {
      return fetchRetry(endpoint, options, retries - 1);
    } else {
      return res;
    }
  } catch (error) {
    if (retries > 0) {
      return fetchRetry(endpoint, options, retries - 1);
    } else {
      throw error;
    }
  }
}

export const request = async (endpoint: string, method: string = 'GET', data?: any, dataHeader?: any,
  isNotAuthenticated: boolean = false, enableErrorReturn = false, authToken?: string) => {
  if (!isNotAuthenticated && !localStorage.getItem('token')) {
    // window.location.href = getLoginPage();
    throw new Error('Not logged in');
  }

  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', authToken || localStorage.getItem('token') || '');
  if (dataHeader) headers.append('data', JSON.stringify(dataHeader));

  const response = await fetchRetry(`${SERVER_URL}/api/${API_VERSION}${endpoint}`, {
    method,
    mode: 'cors',
    headers: headers,
    body: data ? JSON.stringify(data) : undefined,
  });

  if (!response.ok && !isNotAuthenticated) {
    if (response.status === 401) {
      //await AuthAPI.logout();
      // if the user's access token is expired / invalid, remove the token from local storage (cache) and redirect to login page
      if (endpoint.includes('Users')) { // need to limit this to Users API endpoints as other operations may generate unrelated 401 responses
        localStorage.removeItem('token');
        window.location.href = getLoginPage();
      }
    } else if (!enableErrorReturn) {
      return null;
    } else {
      return response.json();
    }
  }
  if (response.status !== 204) {
    localStorage.setItem('em-version-latest', response.headers.get('em-version') || '');

    return response.json();
  } else if (method === 'DELETE' && response.status === 204) {
    return 'OK';
  }

  return null;
}

export const upload = async (endpoint: string, method: string = 'POST', data?: any) => {
  if (!localStorage.getItem('token')) {
    throw new Error('Not logged in');
  }

  const headers = new Headers();
  headers.append('Authorization', localStorage.getItem('token') || '');

  const response = await fetch(`${SERVER_URL}/api/${API_VERSION}${endpoint}`, {
    method,
    mode: 'cors',
    headers: headers,
    body: data,
  });

  if (!response.ok) {
    if (response.status === 401) {
      // if the user's access token is expired / invalid, remove the token from local storage (cache) and redirect to login page
      if (endpoint.includes('Users')) { // need to limit this to Users API endpoints as other operations may generate unrelated 401 responses
        localStorage.removeItem('token');
        window.location.href = getLoginPage();
      }
    } else {
      return null;
    }
  }
  if (response.status !== 204) {
    return response.json();
  }

  return null;
}

export const requestBeacon = async (endpoint: string, data?: any, isNotAuthenticated: boolean = false) => {
  if (!isNotAuthenticated && !localStorage.getItem('token')) {
    throw new Error('Not logged in');
  }
  const authentication = `?access_token=${localStorage.getItem('token')}`;

  let params = '';
  if (data) {
    Object.keys(data).forEach((key) => {
      params += `&${key}=${data[key]}`;
    });
  }

  const requestUri = `${SERVER_URL}/api/${API_VERSION}${endpoint}${authentication}${params}`

  navigator.sendBeacon(requestUri);
  return null;
}

export const getLoginPage = () => {
  return LOGIN_URL;
}

export const addEnvironmentToReqParams = (requestParams: string[]) => {
  const env = localStorage.getItem('selectedEnv');
  if (env) {
    requestParams.push(`environment=${env}`);
  }
}

export enum EnvironmentName {
  Test = 'test',
  Prod = 'prod',
}

export const getEnvironmentNameFromEnvironment = (environment: string | null) => {
  return environment === '2' ? EnvironmentName.Test : EnvironmentName.Prod;
}

export const getApBaseUrl = (environmentName: EnvironmentName): string => {
  return `/apinvoice?environment=${environmentName}`;
}
