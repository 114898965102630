import * as React from 'react';
import { AdminHeader } from '../../AdminHeader';
import { TextInput } from '../../../common/TextInput';
import { WrapperLabel } from '../../../common/WrapperLabel';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { isFormInvalid, RecordValidator } from '../../../common/Form';
import {
  APIPermissionProfile,
  APIPermissionProfileToleranceThreshold,
  PermissionProfileAPI, TOLERANCE_WITHOUT_THRESHOLD
} from '../../../../api/permissionProfiles';
import { Toggle } from '../../../common/Toggle';
import { FormDivider, FormSubHeadline } from '../../admin-components';
import { CustomSelect } from '../../../common/CustomSelect';
import { OptionValue } from 'react-selectize';
import { Modal } from 'pivotal-ui/react/modal';
import { APIUser } from '../../../../api/comment';
import { EmailAccountAPI } from '../../../../api/emailAccount';
import { NumberInput } from '../../../common/NumberInput';
import { APITeam, TeamAPI } from '../../../../api/teams';

interface UrlParams {
  id?: string;
}

interface Props {
  data?: APIPermissionProfile;
  permissionProfilesUsersMap: Map<number, APIUser[]>;
  refetchUserDetails: () => void;
}

export const PermissionProfileForm: React.FC<Props> = (props) => {
  const [record, setRecord] = React.useState<Partial<APIPermissionProfile>>({});

  const [isNameTaken, setIsNameTaken] = React.useState<boolean>(false);
  const [formEntered, setFormEntered] = React.useState<boolean>(false);

  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState<boolean>(false);
  const [permissionProfileOptions, setPermissionProfileOptions] = React.useState<OptionValue[]>([]);
  const [availableTeams, setAvailableTeams] = React.useState<APITeam[]>([]);
  const [targetProfileId, setTargetProfileId] = React.useState<number | null>(null);
  const [searchTeamsValue, setSearchTeamsValue] = React.useState<string>("");
  const [userEnabledDocVisibility, setUserEnabledDocVisibility] = React.useState<boolean>(false);
  const docVisRef = React.useRef<HTMLDivElement>(null);
  const listItemHeight = 30;

  const recordValidator: RecordValidator = {
    name: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: (name) => !!name
        },
        {
          errorMessage: 'A Permission profile already exists with this name',
          isValid: () => !isNameTaken
        }
      ]
    },
  }

  const submitDisabled = isFormInvalid<APIPermissionProfile>(record, recordValidator);

  const params = useParams<UrlParams>();
  const recordId = params.id ? parseInt(params.id) : null;
  const history = useHistory();

  React.useEffect(() => {
    loadData();
  }, [params.id]);

  const loadData = async () => {
    const data = recordId ? await PermissionProfileAPI.fetch(recordId) : {
      companyId: parseInt(localStorage.getItem('companyId') || ''),
      workspaceProduction: true,
      workspaceTest: true,
      canViewOverhead: true,
      toleranceThresholds: [],
      permissionProfileDocumentVisibilities: [],
    }

    const emailAccounts = await EmailAccountAPI.fetchAll();
    const uniqueCurrencies = new Set<string>();
    const toleranceThresholds: APIPermissionProfileToleranceThreshold[] = [];

    emailAccounts.forEach((emailAccount) => {
      if (emailAccount.localCurrency) uniqueCurrencies.add(emailAccount.localCurrency);
    });

    [...uniqueCurrencies.values()].sort().forEach((currency) => {
      const existingThreshold = data.toleranceThresholds?.find((threshold) => threshold.currency === currency);
      if (existingThreshold) {
        toleranceThresholds.push(existingThreshold);
      } else {
        toleranceThresholds.push({
          currency,
          upperAbsoluteMax: 0,
          lowerAbsoluteMax: 0,
          upperPercentageMax: 0,
          lowerPercentageMax: 0,
        });
      }
    });

    setRecord({
      ...data,
      toleranceThresholds
    })

    PermissionProfileAPI.fetchAll().then((data) => {
      setPermissionProfileOptions(data.filter((profile) => profile.id !== recordId).map((profile) => ({ label: profile.name, value: profile.id })));
    });

    TeamAPI.fetchAll().then((data: any) => {
      setAvailableTeams(data);
    });
  }

  React.useEffect(() => {
    if (!record.name) {
      setIsNameTaken(false);
      return;
    }

    PermissionProfileAPI.isFieldValueTaken('name', record.name, record.id).then((result) => {
      setIsNameTaken(result);
    });
  }, [record.name]);

  React.useEffect(() => {
    if (record.limitDocumentVisibility && userEnabledDocVisibility) docVisRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [record.limitDocumentVisibility])

  const onSave = async () => {
    setFormEntered(true);
    if (submitDisabled) return;

    let recordId = record.id;

    if (record.id) {
      await PermissionProfileAPI.update(record.id, record);
    } else {
      const newRecord = await PermissionProfileAPI.create(record);
      recordId = newRecord.id;
    }

    await Promise.all(record.toleranceThresholds?.map((toleranceThreshold) => {
      if (toleranceThreshold.id) {
        if (record.setSeparateLimit === false) {
          toleranceThreshold.lowerAbsoluteMax = toleranceThreshold.upperAbsoluteMax;
          toleranceThreshold.lowerPercentageMax = toleranceThreshold.upperPercentageMax;
        }
        return PermissionProfileAPI.updateThreshold(toleranceThreshold.id, { ...toleranceThreshold, permissionProfileId: recordId });
      } else {
        if (record.setSeparateLimit === false) {
          toleranceThreshold.lowerAbsoluteMax = toleranceThreshold.upperAbsoluteMax;
          toleranceThreshold.lowerPercentageMax = toleranceThreshold.upperPercentageMax;
        }
        return PermissionProfileAPI.createThreshold({ ...toleranceThreshold, permissionProfileId: recordId });
      }
    }) || []);

    await Promise.all(record.permissionProfileDocumentVisibilities?.map((documentVisibility) => {
      if (!documentVisibility.id && !documentVisibility.removedByUser) {
        return PermissionProfileAPI.createDocumentVisibility({ permissionProfileId: documentVisibility.permissionProfileId || recordId, teamId: documentVisibility.teamId });
      }

      if (documentVisibility.removedByUser && documentVisibility.id) {
        return PermissionProfileAPI.deleteDocumentVisibility(documentVisibility.id);
      }
    }) || []);


    if (record.id === parseInt(localStorage.getItem('permissionProfileId') || '')) {
      props.refetchUserDetails();
    }

    history.push(AdminLinks.PermissionProfiles);
  }

  const onUpdate = (change: Partial<APIPermissionProfile>) => {
    setRecord((record) => ({ ...record, ...change }));
  }

  const onThresholdRowUpdate = (targetIndex: number, change: Partial<APIPermissionProfileToleranceThreshold>) => {
    setRecord((record) => ({
      ...record,
      toleranceThresholds: record.toleranceThresholds?.map((threshold, index) => index === targetIndex ? { ...threshold, ...change } : threshold) || [],
    }));
  }

  const onUpdateDocVisibility = (checked: boolean, teamId: number) => {
    const documentVisibilityData = record.permissionProfileDocumentVisibilities ? Array.from(record.permissionProfileDocumentVisibilities) : [];

    if (checked) {
      const existingItem = documentVisibilityData.find((item) => {
        return item.teamId === teamId;
      });

      if (existingItem) {
        documentVisibilityData.map((visibilityData) => {
          if (visibilityData.teamId === existingItem.teamId) visibilityData.removedByUser = false;
        });
      } else {
        documentVisibilityData.push({ permissionProfileId: record.id, teamId: teamId });
      }
    } else {
      documentVisibilityData.map((visibilityData) => {
        if (visibilityData.teamId === teamId) visibilityData.removedByUser = true;
      });
    }

    setRecord((record) => ({
      ...record,
      permissionProfileDocumentVisibilities: documentVisibilityData
    }));
  }

  const onDeleteClicked = () => {
    if (!recordId) return;

    setShowDeleteConfirm(true);
  }

  const onDeleteConfirmed = async () => {
    if (!recordId) return;

    await PermissionProfileAPI.delete(recordId, targetProfileId || undefined);
    history.push(AdminLinks.PermissionProfiles);
  }

  const onDeleteCancelled = () => {
    setTargetProfileId(null);
    setShowDeleteConfirm(false);
  }

  return (
    <div className="admin__form__wrapper with-custom-scrollbar permission-profile-form">
      <AdminHeader
        headline={recordId ? 'Edit profile' : 'Add a Profile'}
        tagText="Global"
        links={[{
          label: 'What are Permission profiles?',
          url: '/knowledge-base/Permission-Profiles-and-User-Roles--1df0af2d35e640e6805fb7a7d7f28641'
        }]}
      />
      {(recordId && props.permissionProfilesUsersMap.get(recordId)) ? (
        <Modal
          title="Update users"
          size="280px"
          show={showDeleteConfirm}
          onHide={onDeleteCancelled}
          dialogClassName={`confirm-modal permission-profile-form__delete-modal`}
        >
          <p>
            The Users with this Permission Profile need to be updated with an alternative.
            Please select the new Profile for these users.
          </p>
          <input style={{ display: 'none' }} /* the modal focuses automatically the first input, which causes the dropdown to be opened */ />
          <CustomSelect
            options={permissionProfileOptions}
            onValueChange={(option) => setTargetProfileId(option?.value)}
            value={permissionProfileOptions.find((option) => option.value === targetProfileId)}
          />
          <p><b>Would you like to update users and delete?</b></p>
          <button className="full-button" onClick={onDeleteConfirmed} disabled={!targetProfileId}>Update Users & Delete</button>
          <button className="light-button" onClick={onDeleteCancelled}>Cancel</button>
        </Modal>
      ) : (
        <Modal
          title="Delete profile"
          size="280px"
          show={showDeleteConfirm}
          onHide={onDeleteCancelled}
          dialogClassName={`confirm-modal permission-profile-form__delete-modal`}
        >
          <p>Would you like to delete this Profile?</p>
          <button className="full-button" onClick={onDeleteConfirmed}>Delete</button>
          <button className="light-button" onClick={onDeleteCancelled}>Cancel</button>
        </Modal>
      )}

      <div className="admin__form__content">
        <div>
          <WrapperLabel text="Profile name">
            <TextInput
              name="name"
              value={record.name || null}
              setter={(value) => onUpdate({ name: value })}
              formEntered={formEntered}
              validators={recordValidator.name.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="Description">
            <TextInput
              enableMultiline
              name="description"
              value={record.description || null}
              setter={(value) => onUpdate({ description: value })}
            />
          </WrapperLabel>
          <FormDivider headline="Platform permissions" />
          <FormSubHeadline headline="Workspaces" />
          <Toggle
            id="accessProduction"
            label="Production"
            checked={record.workspaceProduction || false}
            onChecked={(name, value) => onUpdate({ workspaceProduction: value, workspaceTest: !value && !record.workspaceTest ? true : record.workspaceTest })}
          />
          <Toggle
            id="accessTest"
            label="Test"
            checked={record.workspaceTest || false}
            onChecked={(name, value) => onUpdate({ workspaceTest: value, workspaceProduction: !value && !record.workspaceProduction ? true : record.workspaceProduction })}
          />
          <FormSubHeadline headline="Solutions" />
          <Toggle
            id="accessAp"
            label="AP Reconciliation"
            checked={record.accessAP || false}
            onChecked={(name, value) => onUpdate({ accessAP: value })}
          />
          <Toggle
            id="accessForwarding"
            label="Forwarding and Clearance"
            checked={record.accessForwarding || false}
            onChecked={(name, value) => onUpdate({ accessForwarding: value })}
          />
          <Toggle
            id="accessWtgIntegration"
            label="WTGIntegration"
            checked={record.accessWtgIntegration || false}
            onChecked={(name, value) => onUpdate({ accessWtgIntegration: value })}
          />
          <Toggle
            id="accessInsights"
            label="Insights"
            checked={record.accessInsights || false}
            onChecked={(name, value) => onUpdate({ accessInsights: value })}
          />
          {record.accessAP && (
            <>
              <FormDivider headline="AP Reconciliation permissions" style={{ paddingBottom: '15px' }} />
              <Toggle
                id="postOverhead"
                label="Can post overhead invoices"
                checked={record.postOverhead || false}
                onChecked={(name, value) => onUpdate({ postOverhead: value })}
              />
              <Toggle
                id="viewOverhead"
                label="Can view overhead invoices"
                checked={record.canViewOverhead || false}
                onChecked={(name, value) => onUpdate({ canViewOverhead: value })}
              />
              <Toggle
                id="editApHeader"
                label="Can edit Invoice header"
                checked={record.editAPHeader || false}
                onChecked={(name, value) => onUpdate({ editAPHeader: value })}
              />
              <Toggle
                id="discardAP"
                label="Can discard invoices"
                checked={record.discardAP || false}
                onChecked={(name, value) => onUpdate({ discardAP: value })}
              />
              <Toggle
                id="postBulk"
                label="Can repost in bulk"
                checked={record.postBulk || false}
                onChecked={(name, value) => onUpdate({ postBulk: value })}
              />
              <FormSubHeadline headline="Thresholds for updating accrual amounts" />
              <Toggle
                id="setSeparateLimit"
                label="Set separate upper and lower thresholds"
                checked={record.setSeparateLimit || false}
                onChecked={(name, value) => onUpdate({ setSeparateLimit: value })}
              />
              {
                !record.setSeparateLimit ?
                  (<>
                    <table>
                      <thead>
                        <tr>
                          <th>Local currency</th>
                          <th>Absolute limit</th>
                          <th>Absolute maximum</th>
                          <th>Percentage limit</th>
                          <th>Percentage maximum</th>
                        </tr>
                      </thead>
                      <tbody>
                        {record.toleranceThresholds?.map((toleranceThreshold, index) => (
                          <tr key={index}>
                            <td>{toleranceThreshold.currency}</td>
                            <td>
                              <Toggle
                                id={'tolerance-threshold-absolute-' + index}
                                checked={toleranceThreshold.upperAbsoluteMax !== TOLERANCE_WITHOUT_THRESHOLD}
                                onChecked={(name, checked) =>
                                  onThresholdRowUpdate(index, {
                                    upperAbsoluteMax: checked ? 0 : TOLERANCE_WITHOUT_THRESHOLD,
                                    lowerAbsoluteMax: checked ? 0 : TOLERANCE_WITHOUT_THRESHOLD,
                                  })} />
                            </td>
                            <td>
                              {toleranceThreshold.upperAbsoluteMax === TOLERANCE_WITHOUT_THRESHOLD ? (
                                <span className="color-grey5">Unlimited</span>
                              ) : (
                                <NumberInput
                                  value={toleranceThreshold.upperAbsoluteMax}
                                  onChange={(value) => onThresholdRowUpdate(index, {
                                    upperAbsoluteMax: value || 0,
                                    lowerAbsoluteMax: value || 0,
                                  })}
                                  name={'absoluteMax-' + index}
                                  ignorePrecision />
                              )}
                            </td>
                            <td>
                              <Toggle
                                id={'tolerance-threshold-percentage-' + index}
                                checked={toleranceThreshold.upperPercentageMax !== TOLERANCE_WITHOUT_THRESHOLD}
                                onChecked={(name, checked) => onThresholdRowUpdate(index, {
                                  upperPercentageMax: checked ? 0 : TOLERANCE_WITHOUT_THRESHOLD,
                                  lowerPercentageMax: checked ? 0 : TOLERANCE_WITHOUT_THRESHOLD,
                                })} />
                            </td>
                            <td>
                              {toleranceThreshold.upperPercentageMax === TOLERANCE_WITHOUT_THRESHOLD ? (
                                <span className="color-grey5">Unlimited</span>
                              ) : (
                                <NumberInput
                                  value={toleranceThreshold.upperPercentageMax}
                                  onChange={(value) => onThresholdRowUpdate(index, {
                                    upperPercentageMax: value || 0,
                                    lowerPercentageMax: value || 0,
                                  })}
                                  name={'percentageMax-' + index}
                                  suffix="%"
                                  ignorePrecision />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                  )
                  :
                  (
                    <>
                      <FormSubHeadline headline="Lower Limits" />
                      <table>
                        <thead>
                          <tr>
                            <th>Local currency</th>
                            <th>Absolute limit</th>
                            <th>Absolute maximum</th>
                            <th>Percentage limit</th>
                            <th>Percentage maximum</th>
                          </tr>
                        </thead>
                        <tbody>
                          {record.toleranceThresholds?.map((toleranceThreshold, index) => (
                            <tr key={index}>
                              <td>{toleranceThreshold.currency}</td>
                              <td>
                                <Toggle
                                  id={'tolerance-threshold-absolute-' + index}
                                  checked={toleranceThreshold.lowerAbsoluteMax !== TOLERANCE_WITHOUT_THRESHOLD}
                                  onChecked={(name, checked) => onThresholdRowUpdate(index, {
                                    lowerAbsoluteMax: checked ? 0 : TOLERANCE_WITHOUT_THRESHOLD,
                                  })}
                                />
                              </td>
                              <td>
                                {toleranceThreshold.lowerAbsoluteMax === TOLERANCE_WITHOUT_THRESHOLD ? (
                                  <span className="color-grey5">Unlimited</span>
                                ) : (
                                  <NumberInput
                                    value={toleranceThreshold.lowerAbsoluteMax}
                                    onChange={(value) => onThresholdRowUpdate(index, {
                                      lowerAbsoluteMax: value || 0,
                                    })}
                                    name={'absoluteMaxi-' + index}
                                    ignorePrecision
                                  />
                                )}
                              </td>
                              <td>
                                <Toggle
                                  id={'tolerance-threshold-percentage-' + index}
                                  checked={toleranceThreshold.lowerPercentageMax !== TOLERANCE_WITHOUT_THRESHOLD}
                                  onChecked={(name, checked) => onThresholdRowUpdate(index, {
                                    lowerPercentageMax: checked ? 0 : TOLERANCE_WITHOUT_THRESHOLD,
                                  })}
                                />
                              </td>
                              <td>
                                {toleranceThreshold.lowerPercentageMax === TOLERANCE_WITHOUT_THRESHOLD ? (
                                  <span className="color-grey5">Unlimited</span>
                                ) : (
                                  <NumberInput
                                    value={toleranceThreshold.lowerPercentageMax}
                                    onChange={(value) => onThresholdRowUpdate(index, {
                                      lowerPercentageMax: value || 0,
                                    })}
                                    name={'percentageMax-' + index}
                                    suffix="%"
                                    ignorePrecision
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <FormSubHeadline headline="Upper Limits" />
                      <table>
                        <thead>
                          <tr>
                            <th>Local currency</th>
                            <th>Absolute limit</th>
                            <th>Absolute maximum</th>
                            <th>Percentage limit</th>
                            <th>Percentage maximum</th>
                          </tr>
                        </thead>
                        <tbody>
                          {record.toleranceThresholds?.map((toleranceThreshold, index) => (
                            <tr key={index}>
                              <td>{toleranceThreshold.currency}</td>
                              <td>
                                <Toggle
                                  id={'tolerance-threshold-absolute-' + index}
                                  checked={toleranceThreshold.upperAbsoluteMax !== TOLERANCE_WITHOUT_THRESHOLD}
                                  onChecked={(name, checked) => onThresholdRowUpdate(index, {
                                    upperAbsoluteMax: checked ? 0 : TOLERANCE_WITHOUT_THRESHOLD,
                                  })}
                                />
                              </td>
                              <td>
                                {toleranceThreshold.upperAbsoluteMax === TOLERANCE_WITHOUT_THRESHOLD ? (
                                  <span className="color-grey5">Unlimited</span>
                                ) : (
                                  <NumberInput
                                    value={toleranceThreshold.upperAbsoluteMax}
                                    onChange={(value) => onThresholdRowUpdate(index, {
                                      upperAbsoluteMax: value || 0,
                                    })}
                                    name={'absoluteMax-' + index}
                                    ignorePrecision
                                  />
                                )}
                              </td>
                              <td>
                                <Toggle
                                  id={'tolerance-threshold-percentage-' + index}
                                  checked={toleranceThreshold.upperPercentageMax !== TOLERANCE_WITHOUT_THRESHOLD}
                                  onChecked={(name, checked) => onThresholdRowUpdate(index, {
                                    upperPercentageMax: checked ? 0 : TOLERANCE_WITHOUT_THRESHOLD,
                                  })}
                                />
                              </td>
                              <td>
                                {toleranceThreshold.upperPercentageMax === TOLERANCE_WITHOUT_THRESHOLD ? (
                                  <span className="color-grey5">Unlimited</span>
                                ) : (
                                  <NumberInput
                                    value={toleranceThreshold.upperPercentageMax}
                                    onChange={(value) => onThresholdRowUpdate(index, {
                                      upperPercentageMax: value || 0,
                                    })}
                                    name={'percentageMax-' + index}
                                    suffix="%"
                                    ignorePrecision
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )
              }

            </>
          )}

          {(record.accessAP || record.accessForwarding) && (
            <div ref={docVisRef}>
              <FormDivider headline="Document Visibility" style={{ paddingBottom: '15px', marginTop: '15px' }} />
              <Toggle
                id="documentVisibility"
                label="Limit Document Visibility"
                checked={record.limitDocumentVisibility || false}
                onChecked={(name, checked) => {
                  if (checked) setUserEnabledDocVisibility(true);
                  onUpdate({ limitDocumentVisibility: checked });
                }} />
              {record.limitDocumentVisibility && (
                <>
                  <div className='document-visibility-teams-header'>
                    <FormSubHeadline headline="Select visible documents by team" />
                    <input
                      type='search'
                      placeholder='Search teams...'
                      className='admin__grid__search'
                      value={searchTeamsValue}
                      onChange={(e) => setSearchTeamsValue(e.currentTarget.value)}
                    />
                  </div>

                  <ul
                    className='team-list'
                    style={{ "height": availableTeams.length * listItemHeight }}
                  >
                    {availableTeams.map((team) => {
                      let docsVisible = false;
                      const teamVisibilityData = record.permissionProfileDocumentVisibilities?.find((data) => {
                        return data.teamId === team.id
                      });

                      if (teamVisibilityData && !teamVisibilityData.removedByUser) {
                        docsVisible = true;
                      }

                      return (
                        <li
                          className={team.name.toLowerCase().includes(searchTeamsValue.toLowerCase()) ? "show" : "hide"}
                          style={{ "height": listItemHeight }}
                        >
                          <Toggle
                            id={team.name + "DocumentVisibility"}
                            label={team.name}
                            checked={docsVisible}
                            onChecked={(name, checked) => {
                              onUpdateDocVisibility(checked, team.id);
                            }}
                            isCheckbox={true}
                          />
                        </li>
                      )
                    })}
                  </ul>
                </>
              )}
            </div>
          )}
        </div>
        <div>
          {recordId && (
            <button className="full-button full-button--red" onClick={onDeleteClicked}>Delete Profile</button>
          )}
          <Link to={AdminLinks.PermissionProfiles}><button className="light-button" style={{ margin: '0 20px' }}>Cancel</button></Link>
          <button className="full-button" onClick={onSave}>Save</button>
        </div>
      </div>
    </div >
  )
}
