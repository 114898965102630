import { request } from './request';
import { APIBillOfLadingNotify } from './billOfLading';

export const BillOfLadingNotifyParty = {
  async update(id: number, change: Partial<APIBillOfLadingNotify>): Promise<unknown> {
    return request(`/BillOfLadingNotifyParties/${id}`, 'PATCH', change);
  },

  async create(notify: Partial<APIBillOfLadingNotify>): Promise<APIBillOfLadingNotify> {
    return request('/BillOfLadingNotifyParties', 'POST', notify)
  },

  async remove(id: number): Promise<unknown> {
    return request(`/BillOfLadingNotifyParties/${id}`, 'DELETE');
  },

  async updateNotifyParty(value: string | number | null, billOfLadingId: number, notifyParties?: APIBillOfLadingNotify[]): Promise<void> {
    const notifyParty = notifyParties ? notifyParties[0] : null;
    if (notifyParty && !value) {
      await BillOfLadingNotifyParty.update(notifyParty.id, { notifyPartyRecordId: null });
    } else if (notifyParty && value) {
      await BillOfLadingNotifyParty.update(notifyParty.id, { notifyPartyRecordId: String(value) });
    } else if (value) {
      await BillOfLadingNotifyParty.create({
        billOfLadingId,
        notifyPartyRecordId: String(value),
      });
    }
  }
}
