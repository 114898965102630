import { AppSection } from '../nav-bar/NavBar';
import { requestBeacon } from '../../api/request';

const ONE_MINUTE = 60 * 1000;
const FIFTEEN_MINUTES = 15 * ONE_MINUTE;

export class ActivityTracker {
  private kind: AppSection;
  private docgroupId: number | undefined;
  private lastEventTime = performance.now(); // Opening the section should count as activity
  private timeout = 0;
  private unsentActivity = 0;

  constructor(kind: AppSection, docgroupId: number | undefined) {
    this.kind = kind;
    this.docgroupId = docgroupId;
    this.activity = this.activity.bind(this);
    this.sendActivity = this.sendActivity.bind(this);
  }

  activity() {
    const time = performance.now();
    const length = time - this.lastEventTime;
    this.lastEventTime = time;

    if (length > FIFTEEN_MINUTES) {
      // The gap between events is big enough that we assume the user was
      // inactive in between. Don't record a period of activity, just start a 
      // new one.
      return;
    }

    this.unsentActivity += length;

    // If we haven't already queued an event to send the activity to the server,
    // do so. We wait a minute before sending any activity, to gather up many
    // events into a small number of API calls.
    if (!this.timeout && this.unsentActivity >= 1) {
      this.timeout = setTimeout(this.sendActivity, ONE_MINUTE) as unknown as number;
    }
  }

  sendActivity() {
    const timeInSeconds = Math.floor(this.unsentActivity / 1000);

    if (timeInSeconds > 0) {
      requestBeacon('/UserActivities/updateActivity', { timeInSeconds, kind: this.kind, docgroupId: this.docgroupId });

      // If the window is closed, or the user navigates to a new section, we
      // call sendActivity directly to flush the unsent activity early, before the
      // objects are destroyed. So we should cancel any pending timeout to avoid
      // an unnecessary extra call later.
      clearTimeout(this.timeout);

      this.timeout = 0;
      this.unsentActivity -= (timeInSeconds * 1000);
    }
  }
}
