import { request } from './request';
import { ExchangeRateType } from './supplierInvoice';

export type Filter = { [k: string]: string | string[] | number | number[] | boolean | null };

export interface APIClusterAccrual {
  id: number;
  invoiceId: number
  clusterId: number;
  validationDate: string;
  consolRef: string;
  shipmentRef: string;
  chargeCode: string;
  osCostAmount: number;
  osCurrencyCode: string;
  localCostAmount: number;
  vatAmount: number;
  localVatAmount: number;
  originalOsCostAmount: number;
  originalLocalCostAmount: number;
  originalVatAmount: number;
  originalLocalVatAmount: number;
  originalOsCurrencyCode: string;
  selected: boolean;
  isSplit: boolean;
  costId?: string;
  isApportioned: boolean;
  isUpdated?: boolean;
  updatedByUser: number | null;
  exchangeRate?: ExchangeRateType;
  supplierReference?: string;
  description?: string;
  fxRoundingApplied: number;
}

export interface APIClusterAggregatedAccrual {
  accruals: APIClusterAccrual[];
  osCurrencyCode: string;
}

export const ClusterAccrualAPI = {
  async getClusterAccruals(invoiceId: number): Promise<APIClusterAccrual[]> {
    return request(`/SupplierInvoices/${invoiceId}/accruals`);
  },

  async saveAccruals(updatedAccruals: APIClusterAccrual[], currentAccruals: APIClusterAccrual[]): Promise<unknown> {
    const accrualsToUpdate: APIClusterAccrual[] = [];
    updatedAccruals.forEach((ua) => {
      const originalAccrual = currentAccruals.find((ca) => ca.id === ua.id);
      if (originalAccrual) {
        const withoutChange = originalAccrual.osCostAmount === ua.osCostAmount &&
          originalAccrual.localCostAmount === ua.localCostAmount &&
          originalAccrual.vatAmount === ua.vatAmount &&
          originalAccrual.selected === ua.selected &&
          originalAccrual.isSplit === ua.isSplit;
        if (!withoutChange) {
          accrualsToUpdate.push(ua);
        }
      }
    });
    if (accrualsToUpdate.length !== 0) {
      return request(`/ClusterAccruals/saveAccruals`, 'POST', { accruals: accrualsToUpdate });
    }
    return;
  },

  async resetInvoiceAccruals(invoiceId: number, accrualIds: number[]): Promise<unknown> {
    return request(`/ClusterAccruals/resetInvoiceAccruals`, 'POST', { invoiceId, accrualIds });
  }
}
