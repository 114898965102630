import * as React from 'react';
import { DropdownList } from './DropdownList';
import { ShipamaxPostIcon } from '../../images/Icons';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';

import './posting-behaviour-button.scss';

export type PostingBehaviourButtonOption = {
  id: number;
  label: React.ReactElement;
  disabled?: boolean;
  onClick: () => void;
  onChange: () => void;
}

interface Props {
  options: PostingBehaviourButtonOption[];
  selectedOptionId: number;
  disabled?: boolean;
  submitDisabled?: boolean;
  submitButtonTooltip?: React.ReactElement | string;
  dataTestId?: string;
}

export const PostingBehaviourButton: React.FC<Props> = (props) => {
  const selectedOption = props.options.find((option) => option.id === props.selectedOptionId);

  return (
    <div className="posting-behaviour-button">
      <DropdownList
        items={props.options.map((option) => ({ value: option.id, label: option.label, disabled: option.disabled }))}
        selectedValue={props.selectedOptionId}
        onChange={(value: string | number | null) => {
          if (value === props.selectedOptionId) {
            return;
          }
          const newOption = props.options.find((option) => option.id === value);
          newOption?.onChange();
        }}
        disabled={props.disabled}
      />
      <OverlayTrigger overlay={<Tooltip>{props.submitButtonTooltip}</Tooltip>} placement="bottom" delayShow={500}>
        <span>
          <button
            className="full-button post-button"
            onClick={selectedOption?.onClick}
            disabled={props.disabled || props.submitDisabled}
            data-test-id={props.dataTestId}
          >
            <ShipamaxPostIcon />
            Post
          </button>
        </span>
      </OverlayTrigger>
    </div>
  );
}
