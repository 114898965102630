import * as React from 'react';
import { ConfirmModal } from '../../common/ConfirmModal';
import { SupplierInvoiceAPI } from '../../../api/supplierInvoice';
import { DocumentGroupAPI } from '../../../api/documentGroup';
import { Icon } from 'pivotal-ui/react/iconography';

const THRESHOLD = 100;

enum Mode {
  Confirm,
  LimitExceeded,
}

interface Props {
  documentGroupIds: number[];
  onCancel: () => void;
  onFinished: () => void;
}
export const RevalidateBulkAction: React.FC<Props> = (props) => {
  const [mode, setMode] = React.useState<Mode | undefined>(Mode.Confirm);

  const onSubmit = async () => {
    if (props.documentGroupIds.length > THRESHOLD) {
      setMode(Mode.LimitExceeded);
      return;
    }

    setMode(undefined);
    const result = await SupplierInvoiceAPI.bulkRevalidate(props.documentGroupIds);

    if (result?.bulkLimitExceeded) {
      setMode(Mode.LimitExceeded);
      return;
    }

    props.onFinished();
  }

  const onCancel = () => {
    setMode(undefined);
    props.onCancel();
  }

  const invoicesLabel = props.documentGroupIds.length === 1 ? 'invoice' : 'invoices';

  if (mode === Mode.Confirm) return (
    <ConfirmModal
      key={1}
      show={true}
      onConfirm={onSubmit}
      onHide={onCancel}
      title={`Post selected ${invoicesLabel}`}
      text={`
        ${props.documentGroupIds.length === 1 ? 'The selected invoice' : 'All selected invoices'} will be posted.
        <br />
        <br />
        <b>Would you like to post ${props.documentGroupIds.length} selected ${invoicesLabel}?</b>
      `}
    />
  );

  const minutes = 60 - new Date().getMinutes();

  if (mode === Mode.LimitExceeded) return (
    <ConfirmModal
      key={2}
      show={true}
      size={300}
      onConfirm={onCancel}
      onHide={onCancel}
      title={`Re-post limit reached`}
      confirmText="Okay"
      hideCancelBtn={true}
      text={`
         This request exceeds the bulk re-post limit.
         <br />
         <br />
         Please make sure you have less than ${THRESHOLD}<br />
         invoices selected, or wait for ${minutes} ${minutes > 1 ? 'minutes' : 'minute'}<br />
         until the limit is reset.
      `}
    />
  )

  return (
    <div className="fixed flex justify-center z-[100] inset-0 w-full h-full bg-[#FFF7] opacity-60">
      <Icon style={{ fontSize: '48px' }} src="spinner-md" />
    </div>
  )
}