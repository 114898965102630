import * as React from 'react';
import { APIEmailAccount, EmailAccountAPI, EnvironmentTab, ValidationType } from '../../../../../api/emailAccount';
import { Column } from 'react-data-grid';
import { AdminHeader } from '../../../AdminHeader';
import { Link, useHistory } from 'react-router-dom';
import { AdminGrid, AdminGridHandle, AdminGridSearchParams, booleanFormatter } from '../../../AdminGrid';
import { AdminLinks } from '../../../Admin';
import { tmsTypeOptions } from './MailboxForm';
import { OptionValue } from 'react-selectize';
import { TabSwitcher } from '../../../../common/TabSwitcher';

import './index.scss';

interface Props {
  teamOptions: OptionValue[];
}

export const MailboxesTable: React.FC<Props> = (props) => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = React.useState<string>(EnvironmentTab.ProductionWorkspace);
  const gridRef = React.useRef<AdminGridHandle>(null);

  const isTest = new URLSearchParams(window.location.search).get('env') === 'test';
  if (isTest && selectedTab === EnvironmentTab.ProductionWorkspace) {
    setSelectedTab(EnvironmentTab.TestWorkspace)
  }

  React.useEffect(() => {
    gridRef.current?.refetchData();
  }, [selectedTab]);

  const fetchEmailAccounts = (params: AdminGridSearchParams) => {
    return EmailAccountAPI.fetchAll({
      ...params,
      validationType: [
        ValidationType.Clearance,
        ValidationType.Forwarding,
        ValidationType.ClearanceAndForwarding,
        ValidationType.GatewayForwarding,
        ValidationType.GatewayClearance,
        ValidationType.CinvCluster,
        ValidationType.WtgIntegration,
        ValidationType.Export,
        ValidationType.GenericTMS,
      ],
      environmentTab: selectedTab,
    });
  };

  const columns: Column<APIEmailAccount>[] = React.useMemo(() => [
    {
      key: 'id',
      name: 'Workflow ID',
      minWidth: 30,
    },
    {
      key: 'emailAddr',
      name: 'Mailbox address',
      minWidth: 220,
    },
    {
      key: 'tmsType', name: 'Integration',
      width: 80,
      formatter: (tableProps) => (
        <>{tmsTypeOptions.find((option) => option.value === tableProps.row.tmsType)?.label || ''}</>
      ),
    },
    {
      key: 'validationType',
      name: 'Use Case',
      width: 160,
      formatter: (tableProps) => {
        switch (tableProps.row.validationType) {
          case ValidationType.Clearance:
            return <p>Clearance</p>;
          case ValidationType.Forwarding:
            return <p>Forwarding</p>;
          case ValidationType.ClearanceAndForwarding:
            return <p>Forwarding & Clearance</p>;
          case ValidationType.CinvCluster:
            return <p>Comercial Invoice Cluster</p>;
          case ValidationType.WtgIntegration:
            return <p>WTG Integration</p>;
          case ValidationType.Export:
            return <p>Export (API)</p>;
          case ValidationType.GenericTMS:
            return <p>Import (API)</p>;
          default:
            return <p>N/A</p>;
        }
      }
    },
    {
      key: 'defaultTeamId',
      name: 'Team',
      width: 175,
      formatter: (tableProps) => {
        if (!tableProps.row.defaultTeamId) return <></>
        return (<>{props.teamOptions.find((team) => team.value === tableProps.row.defaultTeamId)?.label || 'Unknown team'}</>)
      },
    },
    {
      key: 'cwCompanyCode',
      name: 'CW company code',
      width: 115,
    },
    {
      key: 'defaultBranch',
      name: 'CW branch code',
      width: 115,
    },
    {
      key: 'defaultTransportMode',
      name: 'Def. Transport Mode',
      width: 115,
    },
    {
      key: 'multiDoc', name: 'Multi Doc', width: 60, formatter: (tableProps) => booleanFormatter(tableProps.row.multiDoc)
    },
    {
      key: 'orgMatchingEnabled', name: 'Organisation Matching', width: 135, formatter: (tableProps) => booleanFormatter(tableProps.row.orgMatchingEnabled)
    },
    {
      key: 'actions', name: 'Actions', width: 80, formatter: (tableProps) => [ValidationType.CinvCluster, ValidationType.WtgIntegration].includes(tableProps.row.validationType) ?
        (<></>) :
        (<Link to={`${AdminLinks.ForwardingAndClearanceWorkflows}/form/${tableProps.row.id}${selectedTab === EnvironmentTab.TestWorkspace ? '?env=test' : ''}`}>
          <button className="light-button active-on-hover">Edit</button>
        </Link>),
    },
  ], [props.teamOptions, selectedTab]);

  return (
    <div className="mailboxes-section">
      <AdminHeader
        headline="Forwarding & Clearance Workflows"
        links={[{
          label: 'What are workflows?',
          url: '/knowledge-base/What-are-workflows---73a06cd51fb047c6a40d8772b4a8ec2d',
        }]}
      />
      <TabSwitcher
        selectedTab={selectedTab}
        onTabClicked={(tab) => {
          if (tab === EnvironmentTab.ProductionWorkspace) {
            history.push(AdminLinks.ForwardingAndClearanceWorkflows);
          }
          setSelectedTab(tab);
        }}
        tabs={[EnvironmentTab.ProductionWorkspace, EnvironmentTab.TestWorkspace]}
      />
      <AdminGrid
        ref={gridRef}
        searchPlaceholder="Search mailboxes..."
        columns={columns}
        fetchData={fetchEmailAccounts}
        link={{
          url: `${AdminLinks.ForwardingAndClearanceWorkflows}/form${selectedTab === EnvironmentTab.TestWorkspace ? '?env=test' : ''}`,
          label: 'Add a new Workflow'
        }}
      />
    </div>
  )
};
