import * as React from 'react';
import * as moment from 'moment';
import { APITeam } from '../../api/teams';
import { DocumentList, DocumentListHandle, SearchFilter } from '../common/document-list';
import {
  APIDocumentContainer,
  DocumentContainerAPI,
  DocumentType
} from '../../api/documentContainer';
import { APIValidationResult, ValidationAPI } from '../../api/validationResults';
import { APIError, Notification } from '../common/Notification';
import { Filter, SupplierInvoiceAPI } from '../../api/supplierInvoice';
import { Card } from './Card';
import { Tag } from '../common/document-list/TagList';
import { IssuerAPI, trimRecordId } from '../../api/issuer';
import { ActivityTracker } from '../monitoring/user-activity';
import { ShipamaxCalendarIcon, ShipamaxCheckIcon, ShipamaxExclamationIcon, ShipamaxFolderIcon, ShipamaxInboxIcon, ShipamaxPauseIcon, ShipamaxPostIcon, ShipamaxQuestionIcon, ShipamaxSortDownIcon, ShipamaxSortUpIcon, ShipamaxSortValueIcon, ShipamaxTrashIcon, ShipamaxUsersIcon } from '../../images/Icons';
import { AppSection } from '../nav-bar/NavBar';
import { APIUser } from '../../api/comment';
import { APIUserSettings } from '../../api/userSettings';
import { OptionValue } from 'react-selectize';
import { IssuerDetailMap, StatusCode } from '../bill-of-lading/common';
import { AddresseeAPI } from '../../api/addressee';
import { APIIssueCode, IssueCodeAPI } from '../../api/issueCode';
import { SelectedDocumentWrapper } from './document-action/SelectedDocumentWrapper';
import { LevelOfChanges } from '../common/document-viewer/FileSplitter';
import { useHistory } from 'react-router-dom';
import { EmailAlertMonitoringAPI } from '../../api/emailAlertMonitoring';
import { APIPermissionProfile } from '../../api/permissionProfiles';
import { EnvironmentName, getApBaseUrl, getEnvironmentNameFromEnvironment } from '../../api/request';
import { navigateToUrl, shouldDoHardRefresh } from '../helpers';
import { BulkActionsButtons } from './bulk-actions/BulkActionsButtons';
import { PermissionLevel } from '../../api/authentication';

import './ap-invoice-section.scss';

const MisclassifiedOption = 4; // document was classified as something else
export const AllDocumentsOption = 10000;

enum RefreshType {
  RefreshWithDocumentLink = 'RefreshWithDocumentLink',
  RefreshWithoutDocumentLink = 'RefreshWithoutDocumentLink'
}

export const getStatusText = (statusCode: number) => {
  switch (statusCode) {
    case StatusCode.Unposted: return 'Open';
    case StatusCode.Reopened: return 'Reopened';
    case StatusCode.Discarded: return 'Discarded';
    case StatusCode.Posted: return 'Posted';
    case StatusCode.Queried: return 'In Query';
    case StatusCode.Processing: return 'Processing';
    case StatusCode.PendingResponse: return 'Pending Response';
    case StatusCode.Done: return 'Done';
    case StatusCode.PostedWithIssues: return 'Posted with Issues';
    default: return '';
  }
}

export enum BulkActionType {
  Reassign = 1,
  Discard = 2,
  Revalidate = 3,
}

export enum Status {
  Open = 0, // this result has not been processed in any way
  Discarded = 1, // this validation failure has caused a user to completely abandon the invoice
  Posted = 2, // the exceptions that caused the validation failure are now resolved
  Resubmitted = 3, // the invoice was resubmitted for validation
  Queried = 5, // the invoice was resubmitted for validation
  PostedWithIssues = 6, // the invoice is posted from our end but might be (i.e.) in TPA in CW
}

const statusOptions = [
  { value: AllDocumentsOption, label: 'All documents', icon: <ShipamaxFolderIcon /> },
  { value: Status.Open, label: getStatusText(StatusCode.Unposted), icon: <ShipamaxInboxIcon /> },
  { value: Status.Discarded, label: getStatusText(StatusCode.Discarded), icon: <ShipamaxTrashIcon /> },
  { value: Status.Queried, label: getStatusText(StatusCode.Queried), icon: <ShipamaxPauseIcon /> },
  { value: Status.Posted, label: getStatusText(StatusCode.Posted), icon: <ShipamaxCheckIcon /> },
  { value: Status.PostedWithIssues, label: getStatusText(StatusCode.PostedWithIssues), icon: <ShipamaxExclamationIcon /> },
  { value: MisclassifiedOption, label: 'Unrecognised', icon: <ShipamaxQuestionIcon /> },
];

const sortOrderOptions = [
  { value: 1, label: 'Newest received', icon: <ShipamaxSortUpIcon /> },
  { value: 2, label: 'Oldest received', icon: <ShipamaxSortDownIcon /> },
  { value: 3, label: 'Highest value', icon: <ShipamaxSortValueIcon />, onlyAPInvoice: true },
  { value: 4, label: 'Reassigned', icon: <ShipamaxUsersIcon /> },
  { value: 5, label: 'Oldest invoice date', icon: <ShipamaxCalendarIcon />, onlyAPInvoice: true },
];

type APInvoiceTag = Tag & {
  onlyAPInvoice?: boolean;
}

const DocumentIdTagId = 9;

const searchTags: APInvoiceTag[] = [
  { id: 1, value: 'EmailSubject:' },
  { id: 2, value: 'InvoiceNumber:', onlyAPInvoice: true },
  { id: 3, value: 'FileName:' },
  { id: 4, value: 'IssuerName:', onlyAPInvoice: true },
  { id: 5, value: 'IssuerCode:', onlyAPInvoice: true },
  { id: 6, value: 'JobReference:', onlyAPInvoice: true },
  { id: 7, value: 'ContainerNumber:', onlyAPInvoice: true },
  { id: 8, value: 'BLorAWBNumber:', onlyAPInvoice: true },
  { id: DocumentIdTagId, value: 'DocumentId:', onlyAPInvoice: true },
];

export interface CreditorChangedParams {
  recordId: string | null;
  modelId?: number;
  fieldName: string;
}

interface Props {
  currencyOptions: OptionValue[];
  teams: APITeam[];
  users: APIUser[];
  permissionProfile: APIPermissionProfile | null;
  permissionLevel: PermissionLevel;
  setNotification: (notification: Notification | null) => void;
  userSettings: APIUserSettings;
  showCargoWiseSettings: boolean;
  checkPermissionProfileAccess: (value: keyof APIPermissionProfile) => boolean;
}

export const ApInvoiceSection: React.FC<Props> = React.memo((props) => {
  const [documents, setDocuments] = React.useState<APIDocumentContainer[]>([]);
  const [issuerDetails, setIssuerDetails] = React.useState<IssuerDetailMap>({});
  const [selectedDocument, setSelectedDocument] = React.useState<APIDocumentContainer | undefined>();
  const [selectedValidationResult, setSelectedValidationResult] = React.useState<APIValidationResult | null>(null);
  const [cards, setCards] = React.useState<JSX.Element[]>([]);
  const [documentTypesOptions, setDocumentTypesOptions] = React.useState<OptionValue[]>([]);
  const [addresseeOptions, setAddresseeOptions] = React.useState<OptionValue[]>([]);
  const [issueCodes, setIssueCodes] = React.useState<APIIssueCode[]>([]);
  const [openGroupsIds, setOpenGroupsIds] = React.useState<number[]>([]);

  const [isBulkModeActive, setIsBulkModeActive] = React.useState<boolean>(false);

  const history = useHistory();

  let defaultSearchFilterState: SearchFilter = {
    status: props.userSettings.settings.apInvSettings?.searchStatus || Number(statusOptions[1].value),
    sortOrder: props.userSettings.settings.apInvSettings?.searchOrder || Number(sortOrderOptions[0].value),
    teamId: props.userSettings.settings.apInvSettings?.searchTeam || null,
    userIds: props.userSettings.settings.apInvSettings?.searchUsers || [],
    activeTags: [],
  };

  const urlParams = new URLSearchParams(location.search);
  const unqId = urlParams.get('unqId');
  const documentId = urlParams.get('documentId') ? parseInt(urlParams.get('documentId') || '') : undefined;
  const currentEnvironment = localStorage.getItem('selectedEnv');
  const currentEnvironmentName = getEnvironmentNameFromEnvironment(currentEnvironment);

  let baseUrl = getApBaseUrl(currentEnvironmentName);
  const urlEnvironmentName = urlParams.get('environment') ? urlParams.get('environment') as EnvironmentName : undefined;
  if (urlEnvironmentName && currentEnvironmentName !== urlEnvironmentName) {
    baseUrl = getApBaseUrl(urlEnvironmentName);
    if (urlEnvironmentName === EnvironmentName.Prod) {
      localStorage.removeItem('selectedEnv');
    } else {
      localStorage.setItem('selectedEnv', '2');
    }
  }

  const refreshType = localStorage.getItem('refresh-type');
  const refreshTime = localStorage.getItem('refresh-time') ? parseInt(localStorage.getItem('refresh-time') || '') : null;
  localStorage.removeItem('refresh-type');
  localStorage.removeItem('refresh-time');

  const wasRefreshed = refreshTime && (Date.now() - refreshTime) < 50000;

  if (documentId) {
    if (wasRefreshed && refreshType === RefreshType.RefreshWithoutDocumentLink) {
      navigateToUrl(baseUrl, history);
    } else {
      defaultSearchFilterState = {
        status: AllDocumentsOption,
        sortOrder: Number(sortOrderOptions[0].value),
        teamId: null,
        userIds: [],
        activeTags: [{ id: 9, value: 'DocumentId:' + documentId }],
      }
    }
  }

  const [searchFilter, setSearchFilter] = React.useState<SearchFilter>(defaultSearchFilterState);

  const searchRef = React.useRef<DocumentListHandle>(null);

  const showMisclassifiedDocuments = props.userSettings.settings.apInvSettings?.searchStatus === MisclassifiedOption;

  React.useEffect(() => {
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, [searchFilter]);

  const handleRefresh = () => {
    const refreshType = (searchFilter.activeTags.length === 1 && searchFilter.activeTags.find((activeTag) => activeTag.id === DocumentIdTagId)) ? RefreshType.RefreshWithDocumentLink : RefreshType.RefreshWithoutDocumentLink;
    localStorage.setItem('refresh-type', refreshType);
    localStorage.setItem('refresh-time', Date.now().toString());
  }

  React.useEffect(() => {
    if (selectedDocument && !shouldDoHardRefresh()) {
      history.push(`${baseUrl}&documentId=${selectedDocument.id}`);
    } else {
      navigateToUrl(baseUrl, history);
    }

    if (!selectedDocument || selectedDocument.documentGroup.status !== StatusCode.Unposted) return;
    const tracker = new ActivityTracker(AppSection.APInvoice, selectedDocument.documentGroup.id);

    window.addEventListener('click', tracker.activity);
    window.addEventListener('keydown', tracker.activity);
    window.addEventListener('scroll', tracker.activity);
    window.addEventListener('beforeunload', tracker.sendActivity);

    return () => {
      window.removeEventListener('click', tracker.activity);
      window.removeEventListener('keydown', tracker.activity);
      window.removeEventListener('scroll', tracker.activity);
      window.removeEventListener('beforeunload', tracker.sendActivity);
      tracker.sendActivity();
    }
  }, [selectedDocument, selectedDocument?.documentGroup?.status]);

  React.useEffect(() => {
    const types = new Map([
      [DocumentType.SupplierInvoice, 'Operational AP Invoice'],
      [DocumentType.OverheadInvoice, 'Overhead AP Invoice'],
      [DocumentType.Undefined, 'Miscellaneous'],
    ]);

    if (unqId) {
      EmailAlertMonitoringAPI.fetch(unqId).then((eam) => {
        if (eam && eam?.length !== 0) {
          const ctas = eam[0].numberOfCTAs + 1;
          EmailAlertMonitoringAPI.updateCTAs(eam[0].id, { numberOfCTAs: ctas });
        }
      });
      history.push(baseUrl);
    }

    DocumentContainerAPI.fetchDocumentTypes().then((fetchedTypes) => {
      if (fetchedTypes === null) {
        props.setNotification({ ...APIError, details: {}, reason: 'Empty fetchedTypes' });
      } else {
        fetchedTypes
          .sort((type1, type2) => type1.displayName?.localeCompare(type2?.displayName || '') || -1)
          .forEach((type) => {
            if (type.cwCode && type.displayName) {
              types.set(type.id, type.displayName);
            }
          });
      }
      setDocumentTypesOptions(Array.from(types, ([value, label]) => ({ label, value })));
    });

    getRelatedDataFromAPI();
  }, []);

  const fetchRelatedData = async (documents: APIDocumentContainer[]) => {
    const issuerRecordIds = new Set<string>();

    documents.forEach((document) => {
      if (document.supplierInvoice && document.supplierInvoice.issuerCgwRecordId && !issuerDetails[document.supplierInvoice.issuerCgwRecordId]) {
        issuerRecordIds.add(document.supplierInvoice.issuerCgwRecordId);
      }
    });

    if (issuerRecordIds.size) {
      const details = await IssuerAPI.fetchIssuerDetailsMap(Array.from(issuerRecordIds));
      setIssuerDetails({ ...issuerDetails, ...details });
    }
  }

  const getRelatedDataFromAPI = async () => {
    const [addressees, issuerCodes] = await Promise.all([
      AddresseeAPI.fetchAll(),
      IssueCodeAPI.fetchAll()
    ]);

    if ([addressees, issuerCodes].includes(null)) {
      props.setNotification({ ...APIError, details: {}, reason: 'Some of Related data from API is null' });
    }

    setAddresseeOptions((addressees || []).map((addressee) => ({ value: addressee.cwCode, label: addressee.cwCode })));
    setIssueCodes(issuerCodes || []);
  }

  React.useEffect(() => {
    loadSelectedDocument();
  }, [selectedDocument]);

  React.useEffect(() => {
    if (!documents) return;

    setCards(cards);
  }, [documents, selectedDocument, issuerDetails]);

  const loadSelectedDocument = () => {
    if (selectedDocument) {
      setSelectedValidationResult(null);

      if (selectedDocument.supplierInvoice) {
        fetchValidationResultData(selectedDocument.documentGroup.validationResultId!, selectedDocument.supplierInvoice.id);
      }
    } else {
      setSelectedValidationResult(null);
    }
  }

  const reLoadSelectedDocument = React.useCallback(async () => {
    if (!selectedDocument) return;

    if (selectedDocument.supplierInvoice) {
      return fetchValidationResultData(selectedDocument.documentGroup.validationResultId!, selectedDocument.supplierInvoice.id);
    }
  }, [selectedDocument]);

  const searchSupplierInvoices = async (filter: Filter, hasFilterChanged: boolean) => {
    const data = await SupplierInvoiceAPI.fetchInvoices({ ...filter, excludeOverheadInvoices: !props.checkPermissionProfileAccess('canViewOverhead') });

    // update status option "Open" and add number of invoices behind it
    if (data.numberOfOpenInvoices >= 0) {
      statusOptions[1].label = `${statusOptions[1].label.split('(')[0]} (${data.numberOfOpenInvoices || 0})`;
    }
    if (data.numberOfQueriedInvoices >= 0) {
      statusOptions[3].label = `${statusOptions[3].label.split('(')[0]} (${data.numberOfQueriedInvoices || 0})`;
    }

    setOpenGroupsIds(data.openGroupsIds);

    if (!documentId || (wasRefreshed && refreshType === RefreshType.RefreshWithoutDocumentLink) || hasFilterChanged) {
      setSelectedDocument(data.invoices.length ? data.invoices[0] : undefined);
      history.push(data.invoices.length ? `${baseUrl}&documentId=${data.invoices[0].id}` : baseUrl);
    }

    return data.invoices;
  }

  const fetchValidationResultData = React.useCallback(async (id: number, supplierInvoiceId: number) => {
    const validationResultData = await ValidationAPI.fetchValidationResultData(id, supplierInvoiceId);
    if (validationResultData === null) {
      props.setNotification({ ...APIError, details: { invoiceId: supplierInvoiceId, validationResultId: id }, reason: 'Request to retrieve validation result data returned null' });
    }

    if (selectedDocument?.supplierInvoice?.id === supplierInvoiceId) {
      setSelectedValidationResult(validationResultData);
    }
  }, [selectedDocument?.supplierInvoice?.id]);

  const updateValidationResultForInvoice = React.useCallback((invoiceId: number, validationResult: APIValidationResult) => {
    setDocuments((documents) => documents.map((document) => {
      if (document.supplierInvoice?.id === invoiceId) {
        return {
          ...document,
          documentGroup: {
            ...document.documentGroup,
            validationResultId: validationResult.id,
            lastValidationResult: validationResult
          }
        }
      } else return document;
    }));
  }, []);

  const setInvoiceResubmitted = React.useCallback((invoiceId: number) => {
    setDocuments((documents) => documents.map((document) => {
      if (document.supplierInvoice?.id !== invoiceId) {
        return document;
      }

      return {
        ...document,
        documentGroup: {
          ...document.documentGroup,
          lastValidationResult: {
            ...document.documentGroup.lastValidationResult,
            status: Status.Resubmitted
          }
        }
      }
    }));
  }, []);

  const creditorChanged = React.useCallback(async (params: CreditorChangedParams) => {
    if (params.recordId && !issuerDetails[params.recordId]) {
      const details = await IssuerAPI.fetchIssuerDetailsMap([params.recordId]);

      setIssuerDetails((prevValue) => ({
        ...prevValue,
        ...details
      }));
    }

    setDocuments((documents) => documents.map((document) => {
      if (!document.supplierInvoice || document.supplierInvoice?.id !== params.modelId) return document;

      return {
        ...document,
        supplierInvoice: {
          ...document.supplierInvoice,
          [params.fieldName]: params.recordId
        }
      };
    }));
  }, [issuerDetails]);

  const selectFirstDocument = React.useCallback((newInvoices?: APIDocumentContainer[]) => {
    const invoices = newInvoices || documents;
    // check if currently selected invoice is still in the search results
    const isDisplayed = invoices.some((inv) => inv.id === (documentId || selectedDocument?.id));
    if (invoices[0] && !isDisplayed) {
      setSelectedDocument(invoices[0]);
    }

    if (isDisplayed && documentId == selectedDocument?.id) {
      loadSelectedDocument();
    }

    if (invoices.length === 0) {
      setSelectedDocument(undefined);
    }
  }, [documents, selectedDocument?.id]);

  const selectNextDocument = React.useCallback(() => {
    const invoices = [...documents];
    const currentDocIndex = invoices.findIndex((doc) => doc.id === selectedDocument?.id);

    if (currentDocIndex > -1) {
      invoices.splice(currentDocIndex, 1);
      setDocuments(invoices);
    }

    if (invoices[currentDocIndex]) {
      setSelectedDocument(invoices[currentDocIndex]);
    } else if (invoices[0]) {
      setSelectedDocument(invoices[0]);
    } else {
      setSelectedDocument(undefined);
    }
  }, [selectedDocument, documents]);

  const onFileSplitFinished = React.useCallback(async (levelOfChanges, affectedDocument: APIDocumentContainer) => {
    if ([LevelOfChanges.RequireReParsing,
    LevelOfChanges.RequireValidation,
    LevelOfChanges.RequireReParsingOnCorrectedInstances,
    ].includes(levelOfChanges)) {
      const affectedDocumentId = affectedDocument.parentId || affectedDocument.id;

      if ([selectedDocument?.parentId || -1, selectedDocument?.id || -1].includes(affectedDocumentId)) {
        setSelectedDocument(documents.find((document) => ![document.parentId, document.id].includes(affectedDocumentId)));
      }

      setDocuments((documents) => documents.filter((document) => ![document.parentId, document.id].includes(affectedDocumentId)));
    } else if (levelOfChanges === LevelOfChanges.ChangeInUnSupportedTypes) {
      reloadDocumentsList()
    }
  }, [searchRef, selectFirstDocument]);

  const loadDocuments = (documents: APIDocumentContainer[]) => {
    setDocuments(documents);
    fetchRelatedData(documents);
  }

  const clearLoadedDocuments = () => {
    setDocuments([]);
  }

  const reloadDocumentsList = async () => {
    if (searchRef?.current) {
      const invoices = await searchRef.current.searchDocumentsWithLastFilter();
      selectFirstDocument(invoices as any[]);
    }
  }

  const updateSearchFilter = React.useCallback((update: Partial<SearchFilter>) => {
    setSearchFilter((prevFilter) => ({ ...prevFilter, ...update }));
  }, []);

  const [filteredSearchTags, filteredSortOrderOptions] = React.useMemo(() => {
    return showMisclassifiedDocuments ? [
      searchTags.filter((tag) => !tag.onlyAPInvoice),
      sortOrderOptions.filter((option) => !option.onlyAPInvoice)
    ] : [searchTags, sortOrderOptions];
  }, [showMisclassifiedDocuments]);

  const renderCard = React.useCallback((item) => {
    const isResubmitted = item.documentGroup.status === StatusCode.Processing;

    let tooltip = '';
    const previousTeam = props.teams.find((team) => item.documentGroup?.previousTeamId === team.id);
    if (previousTeam && item.documentGroup?.reassignTime) {
      tooltip = `From ${previousTeam.name} ${moment(item.documentGroup?.reassignTime).format('HH:mm DDMMM')}`;
    }

    return <Card
      key={item.id}
      onClick={(documentId) => {
        setSelectedDocument(item)
      }}
      id={item.id}
      title={item.supplierInvoice ? issuerDetails[trimRecordId(item.supplierInvoice.issuerCgwRecordId || '')]?.cgwCode || "UNKNOWN ISSUER" : ''}
      date={moment(item.created).format('HH:mm DDMMM')}
      name={item.filename}
      description={(item.email || { subject: 'Unknown' }).subject}
      selected={selectedDocument?.id === item.id}
      isResubmitted={isResubmitted}
      isReassigned={tooltip}
    />
  }, [props.teams, selectedDocument?.id, issuerDetails]);

  return (
    <>
      <DocumentList
        ref={searchRef}
        searchFilter={searchFilter}
        setSearchFilter={updateSearchFilter}
        items={documents}
        renderItem={renderCard}
        setNotification={props.setNotification}
        teams={props.teams}
        users={props.users}
        setResults={loadDocuments}
        clearLoadedElements={clearLoadedDocuments}
        selectFirstResult={selectFirstDocument}
        search={searchSupplierInvoices}
        statusOptions={statusOptions}
        sortOrderOptions={filteredSortOrderOptions}
        searchTags={filteredSearchTags}
        periodicUpdates
        selectedDocumentId={selectedDocument?.id}
        allowBulkMode={searchFilter.status === Status.Open}
        openGroupsIds={openGroupsIds}
        getBulkActions={(selectedGroupIds, closeBulkMode, bulkActionsDisabled) => (
          <BulkActionsButtons
            onFinished={() => {
              closeBulkMode();
              reloadDocumentsList();
            }}
            selectedGroupIds={selectedGroupIds}
            teams={props.teams}
            users={props.users}
            disabled={bulkActionsDisabled}
            permissionLevel={props.permissionLevel}
            checkPermissionProfileAccess={props.checkPermissionProfileAccess}
          />
        )}
        setIsBulkModeActive={setIsBulkModeActive}
      />
      <SelectedDocumentWrapper
        changesDisabled={isBulkModeActive}
        permissionProfile={props.permissionProfile}
        teams={props.teams}
        users={props.users}
        currencyOptions={props.currencyOptions}
        addresseeOptions={addresseeOptions}
        issueCodes={issueCodes}
        setNotification={props.setNotification}
        selectedDocument={selectedDocument}
        selectedValidationResult={selectedValidationResult}
        selectNextDocument={selectNextDocument}
        documentTypesOptions={documentTypesOptions}
        creditorChanged={creditorChanged}
        setInvoiceResubmitted={setInvoiceResubmitted}
        issuerDetails={issuerDetails}
        currentSearchFilter={searchFilter}
        showMisclassifiedDocuments={showMisclassifiedDocuments}
        reLoadSelectedDocument={reLoadSelectedDocument}
        onFileSplitFinished={onFileSplitFinished}
        fetchValidationResultData={fetchValidationResultData}
        updateValidationResultForInvoice={updateValidationResultForInvoice}
        showCargoWiseSettings={props.showCargoWiseSettings}
        checkPermissionProfileAccess={props.checkPermissionProfileAccess}
      />
    </>
  );
});
