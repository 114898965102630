import * as React from 'react';
import { Exception } from '../../api/validationResults';
import { WrapperLabel } from './WrapperLabel';
import { DocumentAssignment, UsersDetails } from './DocumentAssignment';
import { APITeam } from '../../api/teams';
import { APIUser } from '../../api/comment';
import { AssignmentRule } from '../../api/userSettings';
import { ExceptionsContainer } from './ExceptionsContainer';

import './status-bar-container.scss';

export interface ButtonSettings {
  label: string;
  onClick: () => void;
}
interface Props {
  buttons: React.ReactElement;
  exceptions: Exception[];
  teams: APITeam[];
  users: APIUser[];
  usersDetails?: UsersDetails;
  assignmentRule: AssignmentRule;
  setAssignmentRule: (value: AssignmentRule) => void;
  status: string;
  isTeamDropdownDisabled?: boolean;
  groupId?: number;
  className?: string;
}

export const StatusBarContainer: React.FC<Props> = (props) => {

  return (
    <div className={`status-bar-container ${props.className}`}>
      <div className="flex items-baseline mr-[12px] mb-[13px]">
        <div className="status-bar-container__status">
          <span className="status-bar-container__status--label">Status</span>
          <span className="status-bar-container__status--value">{props.status}</span>
        </div>
        <div className="status-bar-container__team-dropdown">
          <WrapperLabel text="Assigned to">
            <DocumentAssignment
              teams={props.teams}
              users={props.users}
              usersDetails={props.usersDetails}
              disabled={props.isTeamDropdownDisabled}
              assignmentRule={props.assignmentRule}
              setAssignmentRule={props.setAssignmentRule}
            />
          </WrapperLabel>
        </div>
      </div>
      <ExceptionsContainer exceptions={props.exceptions} />
      <div className="status-bar-container__buttons">
        {props.buttons}
      </div>
    </div >
  )
}
