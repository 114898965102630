import { request } from './request';
import { APIDocumentGroupCInvoice } from './documentGroup';
import { APIDisassociateDocumentId } from './documentContainer';

export type Filter = { [k: string]: string | string[] | number | number[] | boolean | null };

export const CommercialInvoiceAPI = {
  async update(id: number, commercialInvoice: Partial<APIDocumentGroupCInvoice> | APIDisassociateDocumentId): Promise<unknown> {
    return request(`/CommercialInvoices/${id}`, 'PATCH', commercialInvoice);
  },

  async fetch(id: number): Promise<APIDocumentGroupCInvoice> {
    return request(`/CommercialInvoices/${id}`, 'GET');
  },

  async bulkUpdate(ids: number[], change: Partial<APIDocumentGroupCInvoice>): Promise<unknown> {
    return request(`/CommercialInvoices/bulkUpdate`, 'PATCH', {
      ids,
      change
    });
  },
}
