import { request } from './request';
import { APIDisassociateDocumentId } from './documentContainer';
import { APIEmailAccount } from './emailAccount';

export interface APIBillOfLadingNotify {
  id: number;
  billOfLadingId: number;
  notifyParty: string;
  notifyPartyRecordId: string | null;
  notifyPartyName: string | null;
  notifyPartyAddress: string | null;
}

export interface APIBillOfLadingImporterReference {
  id: number;
  billOfLadingId: number;
  jobRef: string;
  isConsol: boolean;
}

export interface APIBillOfLading {
  id: number;
  documentId: number;
  billOfLadingNo: string;
  bookingNo: string;
  shipper: string;
  shipperName: string;
  shipperAddress: string;
  consignee: string;
  consigneeName: string;
  consigneeAddress: string;
  grossWeight: string;
  destinationAgent: string;
  vessel: string;
  shippingLine: string;
  grossVolume: string;
  voyageNumber: string;
  shippedOnBoardDate: string;
  paymentTerms: string;
  releaseType: string;
  exportReference: string;
  ownersReference: string;
  created: Date;
  scac: string;
  goodsDescription: string;
  coLoadMblNo: string;
  coLoaderCWCode: string;
  vesselIMO: string;
  transportMode: string;
  containerMode: string;
  shipmentType: string;
  consolType?: string;
  isRated: boolean;
  isDraft: boolean;
  notify: APIBillOfLadingNotify[];
  importerReference: APIBillOfLadingImporterReference[];
  shipperCgwRecordId: string;
  consigneeCgwRecordId: string;
  shippingLineCgwRecordId: string;

  loadPort: string;
  loadPortUnlocode: string;
  etd: string;

  dischargePort: string;
  dischargePortUnlocode: string;
  eta: string;

  origin: string;
  originUnlocode: string;
  originEtd: string;

  destination: string;
  destinationUnlocode: string;
  destinationEta: string;

  firstArrivalPort: string;
  firstArrivalPortUnlocode: string;
  firstArrivalPortEta: string;

  emailAccount: APIEmailAccount;
}

export const BillOfLadingAPI = {
  async update(id: number, change: Partial<APIBillOfLading> | APIDisassociateDocumentId): Promise<unknown> {
    return request(`/BillOfLadings/${id}`, 'PATCH', change);
  }
}
