import { request } from './request';
import { APIPackingListLineItem } from './documentGroup';

export type Filter = { [k: string]: string | string[] | number | number[] | boolean | null };

export const PackingListLineItemAPI = {
  async update(id: number, lineItem: Partial<APIPackingListLineItem>, aggregated: boolean): Promise<APIPackingListLineItem> {
    return request(`/PackingListLineItems/${id}`, 'PATCH', lineItem);
  },

  async bulkUpdate(ids: number[], invoiceId: number, change: Partial<APIPackingListLineItem>, aggregated: boolean): Promise<unknown> {
    return request(`/PackingListLineItems/bulkUpdate`, 'PATCH', {
      ids,
      invoiceId,
      change
    });
  },

  async create(lineItem: Partial<APIPackingListLineItem>, aggregated: boolean): Promise<APIPackingListLineItem> {
    return request(`/PackingListLineItems`, 'POST', lineItem)
  },

  async remove(id: number, aggregated: boolean): Promise<unknown> {
    return request(`/PackingListLineItems/${id}`, 'DELETE');
  },

  async removeAllLinesFromCinv(invoiceId: number): Promise<unknown> {
    return request(`/PackingLists/${invoiceId}/removeAllLineItems`, 'DELETE');
  },
}
