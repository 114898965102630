import { request } from './request';
import { APIDocumentGroupSeal } from './documentGroup';

export const BillOfLadingSeal = {
  async update(id: number, change: Partial<APIDocumentGroupSeal>): Promise<unknown> {
    return request(`/BillOfLadingSeals/${id}`, 'PATCH', change);
  },

  async create(container: Partial<APIDocumentGroupSeal>): Promise<APIDocumentGroupSeal> {
    return request('/BillOfLadingSeals', 'POST', container)
  },

  async remove(id: number): Promise<unknown> {
    return request(`/BillOfLadingSeals/${id}`, 'DELETE');
  }
}
