import { request } from './request';

export interface APIAddressee {
  id: number;
  cwCode: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  postCode: string;
  city: string;
  stateProvince: string;
  countryCode: string;
  unloco: string;
  landline: string;
  mobile: string;
  fax: string;
  email: string;
  companyId: number;
}

export const AddresseeAPI = {
  async fetchAll(): Promise<APIAddressee[] | null> {
    const filter = JSON.stringify({ where: { companyId: localStorage.getItem('companyId') } });
    return request(`/CreditorAddressees?filter=${filter}`);
  }
}
