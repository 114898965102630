import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { APIKeyManagementTable } from './APIKeyManagementTable';
import { APIUser } from '../../../../api/comment';
interface Props {
  users: APIUser[];
}

export const APIKeyManagementSection: React.FC<Props> = (props) => {

  return (
    <Switch>
      <Route path={AdminLinks.APIKeyManagement} exact component={() => (
        <APIKeyManagementTable users={props.users} />
      )} />
    </Switch>
  )
};
