import * as React from "react";

import './column-visibility-selector.scss';
import { ColumnsDetails } from "../../api/userSettings";
import { Column } from "react-data-grid";
import { Filter } from "../../api/supplierInvoice";
import { StatusCode } from "../bill-of-lading/common";
import { FAIcon } from "./FAIcon";

interface Props {
  modalCssClass?: string;
  columns: Column<any>[];
  colDetails: ColumnsDetails[];
  visible: boolean;
  listFilter?: Filter | null;
  setVisible: (flag: boolean) => void;
  updateUserColHiddenSettings: (colKey: string, hidden: boolean) => void;
}

export const ColumnVisibilitySelector: React.FC<Props> = (props) => {
  return (
    <>
      <div className={`background-columns-selector fixed top-0 left-0 w-full h-full z-40 ${props.visible ? 'show' : ''}`}
        onClick={() => props.setVisible(false)}>
      </div>
      <div className={`columns-selector ${props.modalCssClass} ${props.visible ? 'show' : ''}`}>
        {props.colDetails
          .filter((col) => {
            const statusFilter = Number(props.listFilter?.status);
            if (statusFilter && ['lastPosted', 'lastPostedBy'].includes(col.key)) {
              // column visible on F&C home page when filtering by posted/done
              return [StatusCode.Posted, StatusCode.Done].includes(statusFilter);
            } else if (statusFilter && ['lastDiscarded', 'lastDiscardedBy'].includes(col.key)) {
              // column visible on F&C home page when filtering by discarded
              return statusFilter === StatusCode.Discarded;
            }
            return !['status', 'columnVisibility', 'linked'].includes(col.key);
          })
          .map((col) => {
            return { key: col.key, hidden: col.hidden, name: props.columns.find((c) => c.key === col.key)?.name || '' }
          })
          .sort((a, b) => (a.name > b.name) ? 1 : -1)
          .map((col) => {
            return <label
              className={`selector`}
              onClick={(event: React.MouseEvent) => event.stopPropagation()}>
              <input
                type="checkbox"
                checked={!col.hidden}
                onChange={() => { props.updateUserColHiddenSettings(col.key, !col.hidden) }} />
              <span className="checkmark">
                <FAIcon name="check" solid />
              </span>
              {col.name}
            </label>;
          })}
      </div>
    </>
  )
}
