import { min } from 'moment';
import * as React from 'react';
import { FAIcon } from '../FAIcon';
import { Tag } from './TagList';

interface Props {
  search: string;
  activeTags: Tag[];
  selectedTag: number | null;
  tagList: Tag[];
  searchActive: boolean;
  tagsVisible: boolean;
  searchModified: boolean;
  searchTags: Tag[]
  showTags: (value: boolean) => void;
  setSearch: (search: string) => void;
  createTag: (tag: Tag) => void;
  updateActiveTags: (tag: Tag) => void;
  focusSearchInput: () => void;
  removeLastActiveTag: () => void;
  updateListOffset: () => void;
  searchDocuments: () => void;
  removeFocus: () => void;
  showErrorMessage: (show: boolean) => void;
  setSelectedTag: (value: number | null) => void;
  clearSearch: () => void;
  revertToPreviousSearch: () => void;
  setSearchModified: (value: boolean) => void;
}

const searchText = 'Search Documents...';

const dateTags = ['ReceivedDate:', 'PostedDate:'];

export const SearchInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [placeholder, setPlaceholder] = React.useState<string>(props.activeTags.length ? '' : searchText);

  React.useEffect(() => {
    window.setTimeout(() => {
      // update after a small delay because the items inside the search-input
      // move around when the placeholder changes and change the height of the element
      props.updateListOffset();
    }, 200);
  }, [placeholder]);

  React.useEffect(() => {
    revertToPreviousSearchIfNoFocus();
  }, [placeholder, props.searchModified]);

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDownEvent);
    return () => document.removeEventListener('keydown', handleKeyDownEvent);
  });

  const handleKeyDownEvent = (event: KeyboardEvent) => {
    // only do keyboard events when activeElement is within search-input
    const searchInput = document.getElementsByClassName('search-input');
    if (searchInput[0].contains(document.activeElement)) {
      props.updateListOffset();

      switch (event.keyCode) {
        case 27: // Escape
          props.removeFocus();
          break;
        case 8: // Backspace
          if (document.activeElement === document.getElementsByClassName('search-input__input')[0] && props.search === '') {
            props.removeLastActiveTag();
            props.setSearchModified(true);
          }
          break;
        case 13: // Enter
          handleEnterKey();
          break;
        case 38: // Arrow up
          handleKeyUp();
          break;
        case 40: // Arrow down
          handleKeyDown();
          break;
      }
    }
  }

  const handleEnterKey = () => {
    if (props.selectedTag) {
      const tag = props.searchTags.find((tag) => tag.id === props.selectedTag);
      if (tag) {
        props.createTag(tag);
        props.setSearch('');
      }
    } else {
      props.searchDocuments();
    }
  }

  const handleKeyUp = () => {
    if (props.tagList.length > 0 && props.selectedTag && props.tagsVisible) {
      const indexOfNewTag = props.tagList.findIndex((tag) => tag.id === props.selectedTag) - 1;
      const newSelectedTag = props.tagList[indexOfNewTag];
      props.setSelectedTag((newSelectedTag && newSelectedTag.id) || null);
    }
  }

  const handleKeyDown = () => {
    if (props.tagList.length > 0 && props.tagsVisible) {
      if (props.selectedTag) {
        const indexOfNewTag = props.tagList.findIndex((tag) => tag.id === props.selectedTag) + 1;
        const newSelectedTag = props.tagList[indexOfNewTag];
        if (newSelectedTag) props.setSelectedTag(newSelectedTag.id);
      } else {
        props.setSelectedTag(props.tagList[0].id);
      }
    }
  }

  const onSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tag = event.target.value.trim();
    const validTag = props.searchTags.find((t) => t.value.toLowerCase() === tag.toLowerCase());
    const isDuplicate = props.activeTags.some((t) => t.value.toLowerCase() === tag.toLowerCase());

    if (validTag && !isDuplicate) {
      props.createTag(validTag);
      props.setSearch('');
    } else {
      props.setSearch(tag);
    }
  }

  const onFocus = () => {
    // Set placeholder with tiny delay. This way, other components that
    // update the placeholder in their onBlur event will hapen first 
    window.setTimeout(() => setPlaceholder('Choose a search parameter'), 50);
    props.showTags(true);
  }

  const onBlur = () => {
    props.setSearch('');
    props.showTags(false);
    props.setSelectedTag(null);
    props.updateListOffset();
    const reverted = revertToPreviousSearchIfNoFocus();
    setPlaceholder(props.activeTags.length === 0 && !reverted ? searchText : '');
  }

  const revertToPreviousSearchIfNoFocus = (): boolean => {
    if ((placeholder === '' || placeholder === searchText) && props.searchModified) {
      // only revert if the current focus is outside of the search-input
      const searchInput = document.getElementsByClassName('search-input');
      if (!searchInput[0].contains(document.activeElement)) {
        // revert list after a small delay.
        props.revertToPreviousSearch();
        return true;
      }
    }
    return false;
  }

  const clearSearch = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    props.clearSearch();
  }

  return (
    <div className="search-input__container">
      <div onClick={props.focusSearchInput} className="search-input">
        {props.activeTags.map((tag) => {
          return (
            <SearchTag
              key={tag.id}
              tag={tag}
              focusSearchInput={props.focusSearchInput}
              updateActiveTags={props.updateActiveTags}
              setPlaceholder={setPlaceholder}
              nrOfActiveTags={props.activeTags.length}
              showErrorMessage={props.showErrorMessage}
              setSearchModified={props.setSearchModified}
            />
          );
        })}
        <input
          ref={ref}
          className={`search-input__input ${placeholder === '' ? 'shrink' : ''}`}
          type="text"
          value={props.search}
          onChange={onSearchChanged}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      {!!props.activeTags.length && (
        <FAIcon onClick={clearSearch} name="times-circle" solid className={`search-input__clear active`} />
      )}
    </div>
  );
})

interface SearchTagProps {
  tag: Tag;
  nrOfActiveTags: number;
  updateActiveTags: (tag: Tag) => void;
  setPlaceholder: (value: string) => void;
  focusSearchInput: () => void;
  showErrorMessage: (show: boolean) => void;
  setSearchModified: (value: boolean) => void;
}

const SearchTag: React.FC<SearchTagProps> = (props) => {
  const tagRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (props.tag.tagRef === null) {
      props.updateActiveTags({ ...props.tag, tagRef: tagRef });
    }

    return () => {
      if (props.nrOfActiveTags === 1) props.setPlaceholder(searchText);
      if (document.activeElement === tagRef.current) props.focusSearchInput();
    }
  }, [])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setSearchModified(true);
    props.updateActiveTags({ id: props.tag.id, value: event.target.value, tagRef: tagRef });
  }

  const onClick = (event: React.MouseEvent<HTMLInputElement>) => {
    // prevent triggering onClick event of parent, because it would take away this tags focus
    event.stopPropagation();
    event.preventDefault();

    // Set cursor to the end of the text input
    if (tagRef.current !== null && tagRef.current !== document.activeElement) {
      // Typescript thinks updatedTag.tagRef can also be (instance: HTMLInputElement | null) => void
      // and I haven't figured out yet why...
      const ref = tagRef.current;
      ref.selectionStart = ref.selectionEnd = props.tag.value.length;
    }
  }

  const onFocus = () => {
    props.setPlaceholder(dateTags.includes(props.tag.value) ? 'Enter a date (YYYY-MM-DD)' : 'Enter keyword');
    props.showErrorMessage(false);
  }

  const onBlur = () => {
    props.setPlaceholder('');
  }

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    props.setSearchModified(true);
    let resultedString = props.tag.value.substr(0, tagRef.current?.selectionStart || 0);
    resultedString += event.clipboardData.getData('text').trim();
    resultedString += tagRef.current?.selectionEnd ? props.tag.value.substr(tagRef.current.selectionEnd) : '';
    props.updateActiveTags({ id: props.tag.id, value: resultedString, tagRef: tagRef });
  }

  return (
    <input
      ref={tagRef}
      className="search-input__tag"
      style={{ width: `${props.tag.value.length}ch` }}
      type="text"
      value={props.tag.value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      onPaste={onPaste}
    />
  );
}
