import * as React from 'react';
import { FAIcon } from './FAIcon';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Notification } from './Notification';
import { FieldValidator } from './Form';

import './file-input.scss';

interface Props {
  selectedFile?: File | null;
  setSelectedFile?: (value: File | null) => void;
  setNotification: (notification: Notification | null) => void;
  disabled?: boolean;
  validators?: FieldValidator[];
  formEntered?: boolean;
  multiple?: boolean;
  selectedFiles?: File[] | null;
  setSelectedFiles?: (value: File[] | null) => void;
  accept?: string[],
  maxSize?: number,
}

export const FileInput: React.FC<Props> = (props) => {
  const { multiple } = props;
  const maxSize = props.maxSize ? props.maxSize : 12;

  const onDrop = React.useCallback(acceptedFiles => {
    if (multiple) {
      props.setSelectedFiles!(acceptedFiles);
      return;
    }

    props.setSelectedFile!(acceptedFiles[0]);
  }, []);

  const onDropRejected = React.useCallback((fileRejection: FileRejection[]) => {
    console.log(fileRejection);

    if (fileRejection.length && fileRejection[0].errors.length) {
      const error = fileRejection[0].errors[0];

      if (error.code === 'file-too-large') {
        props.setNotification({ type: 'error', value: `File is larger than ${maxSize}Mb` });
      } else {
        props.setNotification({ type: 'error', value: error.message });
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    disabled: props.disabled,
    multiple: multiple,
    maxSize: maxSize * Math.pow(1024, 2),
    accept: props.accept ? props.accept : ['.xls', '.xlsx', '.xlsm', '.numbers', '.ods', '.csv']
  });

  const triggeredValidator = props.formEntered ? props.validators?.find((validator) => {
    if (multiple) {
      if (!validator.isValid(props.selectedFiles)) {
        return true;
      }
    }
    if (!validator.isValid(props.selectedFile)) {
      return true;
    }
  }) : null;

  const renderBrowseBtn = () => <div className="file-input__placeholder">
    <FAIcon name="file-upload" solid />
    <p><button className="light-button">Browse</button> {`or drag and drop ${multiple ? 'files' : 'a file'} here.`}</p>
  </div>


  const renderSingle = () => {
    if (props.selectedFile) {
      return <div className="file-input__file">
        <FAIcon name="file" />
        <span>{props.selectedFile.name}</span>
        <button className="light-button active-on-hover" onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          if (multiple) {
            props.setSelectedFiles!(null);
            return;
          }
          props.setSelectedFile!(null);
        }}>
          <FAIcon name="times" solid />
        </button>
      </div>
    }

    return renderBrowseBtn();
  }

  return (
    <div {...getRootProps()} className={`file-input ${triggeredValidator ? 'file-input--highlighted' : ''}`}>
      <input {...getInputProps()} formEncType="multipart/form-data" multiple={multiple} />
      {isDragActive ? (
        <p>{`Drop the file${multiple ? 's' : ''} here...`}</p>
      ) : (
        <>
          {(multiple) ? (renderBrowseBtn()) : (renderSingle())}
        </>
      )}
      {
        triggeredValidator?.errorMessage && (
          <div className="form-field__error">
            {triggeredValidator.errorMessage}
          </div>
        )
      }
    </div >
  )
}
