import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { TeamForm } from './TeamForm';
import { TeamsTable } from './TeamsTable';

interface Props {

}

export const TeamsSection: React.FC<Props> = (props) => {
  return (
    <Switch>
      <Route path={AdminLinks.Teams} exact component={() => (
        <TeamsTable />
      )}/>
      <Route path={[AdminLinks.Teams + '/form', AdminLinks.Teams + '/form/:id']} exact component={() => (
        <TeamForm />
      )} />
    </Switch>
  )
};
