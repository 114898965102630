import * as React from 'react';
import { Cluster, ClusterStatus, JobRefClusterAPI } from '../../../api/jobRefCluster';
import { JobRef, JobRefType } from './Clusters';
import { TotalsInput } from '../../common/TotalsInput';
import { APIClusterAccrual, ClusterAccrualAPI } from '../../../api/clusterAccrual';
import { APIExchangeRate, APIJobOwnersMap } from '../../../api/supplierInvoice';
import { AccrualsForm, AccrualsFormMode, getSplitPrimaryTwinAccrual } from './AccrualsForm';
import { doesClusterHaveAnyOfExceptions, Exception, ExceptionCode } from '../../../api/validationResults';
import { SupplierInvoiceJobRefAPI } from '../../../api/supplierInvoiceJobRef';
import { getAccrualOriginalLocalCost, getCurrencyExchangeRate } from './AccrualsRow';
import { APIEmailAccount } from '../../../api/emailAccount';
import { APIDocumentContainer, CoordinatesFilter } from '../../../api/documentContainer';
import { APICgwCurrencyExchangeRates } from '../../../api/currency';
import { ShipamaxDownIcon, ShipamaxDragIcon, ShipamaxPlusIcon, ShipamaxTimesIcon } from '../../../images/Icons';
import { AggregatedJobReference, AggregatedTMSRef } from './AggregatedJobReference';
import { AccrualsTable } from './AccrualsTable';
import { checkJobRefRemovable, getJobRefValues } from './JobReference';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { MessageBar } from '../../common/MessageBar';
import { APIPermissionProfileToleranceThreshold } from '../../../api/permissionProfiles';
import { getAccrualsExceptionType, getClusterStatus } from './helpers';

import './cluster-row.scss';

const checkClusterRemovable = (cluster: Cluster, clustersCount: number): boolean => {
  const allJobRefsCleared = cluster?.aggregatedTMSRefs.every((tmsRef) => {
    return tmsRef.aggregatedDocumentRefs.every((documentRef) => {
      return checkJobRefRemovable(getJobRefValues(documentRef));
    });
  });

  const totalCleared = (cluster?.isHeader && clustersCount === 1) || cluster?.total === null;
  return totalCleared && allJobRefsCleared;
}

const checkNoAccrualsFoundForCluster = (cluster: Cluster, exceptions: Exception[]): boolean => {
  let result = false;

  exceptions.forEach((exception) => {
    if (exception.code === ExceptionCode.CargoWiseNoAccrualsFound && cluster.isHeader) {
      result = true;
    }

    if (exception.code === ExceptionCode.CargoWiseNoAccrualsFoundForClusters && exception.failedClusters?.includes(cluster.id)) {
      result = true;
    }
  });

  return result;
}

interface Props {
  cluster: Cluster;
  invoiceAccruals: APIClusterAccrual[];
  setInvoiceAccruals: (accruals: APIClusterAccrual[]) => void;
  clustersCount: number;
  emailAccount?: APIEmailAccount;
  invoiceCurrencyCode: string;
  onClusterChanged: (clusterId: number, change: Partial<Cluster>, ignoreAccrualsReset?: boolean) => void;
  invoiceNetTotal: number;
  exceptions: Exception[];
  accrualsUnselectedAt: number;
  disabled: boolean;
  toleranceThreshold: APIPermissionProfileToleranceThreshold | undefined;
  useSeparateLimit: boolean;
  exchangeRates: APIExchangeRate[];
  isAccountsLevelUser: boolean;
  document: APIDocumentContainer | undefined;
  removeCoordinates: (coordinatesFilter: CoordinatesFilter) => void;
  cargowiseExchangeRates: APICgwCurrencyExchangeRates[];
  removeCluster: (clusterId: number) => void;
  dragHandleProps: any;
  jobOwnersMap: APIJobOwnersMap;
  assignUser: (userId: number) => void;
  clusters?: Cluster[];
}

export const ClusterRow: React.FC<Props> = (props) => {
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);
  const [accrualsFormMode, setAccrualsFormMode] = React.useState<AccrualsFormMode>(AccrualsFormMode.ReadOnly);

  React.useEffect(() => { // clear selected accruals in local state, when some change was made in cluster
    if (props.accrualsUnselectedAt) {
      unselectAccruals();
    }
  }, [props.accrualsUnselectedAt]);

  const toggleFormVisibility = (accrualsFormMode: AccrualsFormMode) => {
    setAccrualsFormMode(accrualsFormMode);
  }

  const unselectAccruals = async () => {
    const unselectedAccruals: APIClusterAccrual[] = [];

    const updatedAccruals = props.invoiceAccruals.map((accrual) => {
      const useLocal = Boolean(props.emailAccount?.localCurrency && props.emailAccount.localCurrency === props.invoiceCurrencyCode);
      const exchangeRate = getCurrencyExchangeRate(props.emailAccount?.localCurrency || 'UNKNOWN', accrual.originalOsCurrencyCode, props.exchangeRates);
      const localCostAmount = useLocal ? getAccrualOriginalLocalCost(accrual, exchangeRate) : accrual.originalLocalCostAmount;

      if (accrual.isSplit && !accrual.selected) { // the accrual contains the leftover from other split accrual
        const twinSplitAccrual = getSplitPrimaryTwinAccrual(props.invoiceAccruals, accrual);

        if (!twinSplitAccrual || twinSplitAccrual.clusterId !== props.cluster.id) { // if the original split accrual isn't from this cluster, don't reset the twin
          return accrual;
        }
      } else if (accrual.clusterId !== props.cluster.id) {
        return accrual;
      }

      const unselectedAccrual = {
        ...accrual,
        selected: false,
        isUpdated: false,
        isSplit: false,
        osCostAmount: accrual.originalOsCostAmount,
        localCostAmount,
        vatAmount: accrual.originalVatAmount,
        osCurrencyCode: accrual.originalOsCurrencyCode,
      };

      unselectedAccruals.push(unselectedAccrual);

      return unselectedAccrual;
    });


    if (unselectedAccruals.length) {
      await ClusterAccrualAPI.saveAccruals(unselectedAccruals, props.invoiceAccruals); // update only the accruals, we revert the changes for
    }
    props.setInvoiceAccruals(updatedAccruals);
  }

  const addJobRef = React.useCallback(async () => {
    const newRef = await SupplierInvoiceJobRefAPI.create({ clusterId: props.cluster.id, jobRef: undefined, invoiceId: props.cluster.invoiceId, bolNum: null, containerNum: null });
    props.onClusterChanged(props.cluster.id, {
      aggregatedTMSRefs: [...props.cluster.aggregatedTMSRefs, {
        jobRef: newRef.jobRef,
        aggregatedDocumentRefs: [{ key: newRef.id.toString(), refs: [{ ...newRef, type: JobRefType.Ref }] }],
      }],
    });
  }, [props.cluster.id, props.cluster.invoiceId, props.cluster.aggregatedTMSRefs, props.onClusterChanged]);

  const removeJobRef = React.useCallback((aggregatedTMSRefIndex: number, aggregatedDocumentRefIndex: number, refIds: number[]) => {
    if (!refIds.length) return;

    Promise.all(refIds.map((refId) => SupplierInvoiceJobRefAPI.delete(refId)));

    const newAggregatedRefs: AggregatedTMSRef[] = [];

    props.cluster.aggregatedTMSRefs.forEach((aggregatedRef, index) => {
      if (index !== aggregatedTMSRefIndex) return newAggregatedRefs.push(aggregatedRef);

      const remainingJobRefs = aggregatedRef.aggregatedDocumentRefs.filter((ref, index) => aggregatedDocumentRefIndex !== index);

      if (remainingJobRefs.length) {
        newAggregatedRefs.push({
          ...aggregatedRef,
          aggregatedDocumentRefs: remainingJobRefs,
        });
      }
    });

    if (!props.disabled && checkClusterRemovable({ ...props.cluster, aggregatedTMSRefs: newAggregatedRefs }, props.clustersCount)) {
      props.removeCluster(props.cluster.id);
    } else {
      props.onClusterChanged(props.cluster.id, {
        aggregatedTMSRefs: newAggregatedRefs
      });
      unselectAccruals();
    }

    props.removeCoordinates({
      parentTableName: 'supplier_invoice_job_ref',
      parentTableIds: refIds,
    });
  }, [props.cluster, props.disabled, props.clustersCount, props.removeCluster, props.onClusterChanged, props.removeCoordinates]);

  const onJobRefChanged = React.useCallback((aggregatedTMSRefIndex: number, aggregatedDocumentRefIndex: number, change: Partial<JobRef>) => {
    props.onClusterChanged(props.cluster.id, {
      aggregatedTMSRefs: props.cluster.aggregatedTMSRefs.map((aggregatedTMSRef, index) => {
        if (index !== aggregatedTMSRefIndex) return aggregatedTMSRef;

        let jobRef: string | null = aggregatedTMSRef.jobRef;

        if (aggregatedTMSRef.aggregatedDocumentRefs.length === 1) {
          const allDirectRefs = aggregatedTMSRef.aggregatedDocumentRefs.every((aggregatedRef) => aggregatedRef.refs.every((ref) => {
            return { ...ref, ...change }.type === JobRefType.Ref;
          }));
          jobRef = allDirectRefs ? (change.jobRef || null) : null;
        }

        return {
          ...aggregatedTMSRef,
          jobRef,
          aggregatedDocumentRefs: aggregatedTMSRef.aggregatedDocumentRefs.map((aggregatedDocumentRef, index) => {
            if (index !== aggregatedDocumentRefIndex) return aggregatedDocumentRef;

            return { ...aggregatedDocumentRef, refs: aggregatedDocumentRef.refs.map((ref) => ({ ...ref, ...change })) };
          })
        };
      })
    });
  }, [props.cluster.id, props.cluster.aggregatedTMSRefs, props.onClusterChanged]);

  const onBlurTotal = (value: number | null, clusterId: number, withoutChange: boolean) => {
    if (!props.disabled && checkClusterRemovable(props.cluster, props.clustersCount)) {
      return props.removeCluster(props.cluster.id);
    }

    if (withoutChange) {
      return;
    }

    JobRefClusterAPI.update(clusterId, { total: Number(value) });
  }

  const hideContainer = props.disabled && props.cluster.isHeader && props.cluster.aggregatedTMSRefs.length === 0;
  const clusterAccruals = React.useMemo(() => props.invoiceAccruals.filter((accrual) => accrual.clusterId === props.cluster.id), [props.invoiceAccruals, props.cluster.id]);
  const hasApportionedAccruals = clusterAccruals.some((accrual) => accrual.isApportioned);
  const noAccrualsFound = checkNoAccrualsFoundForCluster(props.cluster, props.exceptions);
  const hasMultipleAccrualsWithSameChargeCodeWarning = doesClusterHaveAnyOfExceptions(props.cluster.id, [ExceptionCode.SupplierInvoiceDuplicateChargeCode], props.exceptions);
  const hasAccrualsHaveChangedWarning = doesClusterHaveAnyOfExceptions(props.cluster.id, [ExceptionCode.CargoWiseAccrualsHaveChanged], props.exceptions);
  const hasApportionedAccrualsNotPostedAtOnceException = doesClusterHaveAnyOfExceptions(props.cluster.id, [ExceptionCode.CargoWisePartialConsolCost], props.exceptions);

  const initialClusterStatus = noAccrualsFound ? ClusterStatus.Failed : ((hasMultipleAccrualsWithSameChargeCodeWarning || hasAccrualsHaveChangedWarning) ? ClusterStatus.Warning : undefined);

  const [hasMultipleAccrualsException, allowAccrualsUpdate] = getAccrualsExceptionType(props.exceptions, props.cluster.id, clusterAccruals);
  const { clusterStatus } = getClusterStatus({
    exceptions: props.exceptions,
    toleranceThreshold: props.toleranceThreshold,
    invoiceCurrencyCode: props.invoiceCurrencyCode,
    exchangeTolerance: props.emailAccount?.exchangeTolerance ?? 0,
    invoiceNetTotal: props.invoiceNetTotal,
    cluster: props.cluster,
    invoiceAccruals: props.invoiceAccruals,
    emailAccount: props.emailAccount,
  });

  const getMessageBar = () => {
    if (accrualsFormMode !== AccrualsFormMode.ReadOnly) {
      return '';
    }

    if (hasMultipleAccrualsException) {
      if (clusterStatus !== ClusterStatus.Matched) {
        return (
          <MessageBar
            text="Multiple possible accrual combinations"
            btnText="Resolve"
            onClick={() => toggleFormVisibility(AccrualsFormMode.Tolerances)}
            disabled={props.disabled}
            type={ClusterStatus.Failed}
            testId="multiple-accrual-combinations-error"
          />
        )
      }

      if (clusterStatus === ClusterStatus.Matched) {
        return (
          <MessageBar
            text="Costs have been updated"
            btnText="Edit"
            onClick={() => toggleFormVisibility(AccrualsFormMode.Tolerances)}
            disabled={props.disabled}
            type={ClusterStatus.Matched}
            testId="multiple-accrual-combinations-success"
          />
        )
      }
    }

    if (allowAccrualsUpdate) {
      if (clusterStatus !== ClusterStatus.Matched) {
        return (
          <MessageBar
            text="Failed to match a set of accruals"
            btnText="Resolve"
            onClick={() => toggleFormVisibility(AccrualsFormMode.Tolerances)}
            disabled={props.disabled || !props.isAccountsLevelUser}
            type={ClusterStatus.Failed}
            testId="total-mismatch-error"
          />
        )
      } else {
        return (
          <MessageBar
            text="Costs have been updated"
            btnText="Edit"
            onClick={() => toggleFormVisibility(AccrualsFormMode.Tolerances)}
            disabled={props.disabled || !props.isAccountsLevelUser}
            type={ClusterStatus.Matched}
            testId="total-mismatch-success"
          />
        )
      }
    }
  }

  return (
    <div
      className={`cluster__container`}
      style={hideContainer ? { display: 'none' } : {}}
      key={props.cluster.id}
    >
      {accrualsFormMode !== AccrualsFormMode.ReadOnly && (
        <AccrualsForm
          cluster={props.cluster}
          disabled={props.disabled}
          clusterAccruals={clusterAccruals}
          invoiceAccruals={props.invoiceAccruals}
          setInvoiceAccruals={props.setInvoiceAccruals}
          invoiceCurrencyCode={props.invoiceCurrencyCode}
          mailboxCurrencyCode={props.emailAccount?.localCurrency || 'UNKNOWN'}
          exchangeTolerance={props.emailAccount?.exchangeTolerance ?? 0}
          invoiceNetTotal={props.invoiceNetTotal}
          exceptions={props.exceptions}
          toleranceThreshold={props.toleranceThreshold}
          useSeparateLimit={props.useSeparateLimit}
          exchangeRates={props.exchangeRates}
          document={props.document}
          onClusterChanged={props.onClusterChanged}
          accrualsFormMode={accrualsFormMode}
          onClose={() => toggleFormVisibility(AccrualsFormMode.ReadOnly)}
          cargowiseExchangeRates={props.cargowiseExchangeRates}
          emailAccount={props.emailAccount}
        />
      )}
      <div className="cluster__refs">
        {props.cluster.aggregatedTMSRefs
          .map((aggregatedTMSRef, i) => {
            return (
              <AggregatedJobReference
                key={i}
                index={i}
                aggregatedTMSRef={aggregatedTMSRef}
                onChange={onJobRefChanged}
                emailAccount={props.emailAccount}
                disabled={props.disabled}
                exceptions={props.exceptions}
                removeCoordinates={props.removeCoordinates}
                addJobRef={addJobRef}
                removeJobRef={removeJobRef}
                jobOwnersMap={props.jobOwnersMap}
                assignUser={props.assignUser}
                documentGroupId={props.document?.groupId || -1}
                clusters={props.clusters}
                isAggregated={props.cluster.isAggregated}
              />
            )
          })}
      </div>
      {noAccrualsFound && (
        <MessageBar text="No accruals found" type={ClusterStatus.Failed} hideButton={true} />
      )}
      {hasMultipleAccrualsWithSameChargeCodeWarning && (
        <MessageBar text="Other accruals with the same charge code detected on this Job. Posting these accruals may delete information in CargoWise" type={ClusterStatus.Warning} hideButton={true} />
      )}
      {hasAccrualsHaveChangedWarning && (
        <MessageBar text="Accruals in CargoWise have changed since previous updates were made in Shipamax. Please update or select the correct costs again to post this invoice." type={ClusterStatus.Warning} hideButton={true} />
      )}
      {hasApportionedAccrualsNotPostedAtOnceException && (
        <MessageBar text="CargoWise: Consol costs must have all sub-shipment apportioned costs posted at once" type={ClusterStatus.Failed} hideButton={true} />
      )}
      {getMessageBar()}
      {!isCollapsed && (
        <AccrualsTable
          invoiceCurrencyCode={props.invoiceCurrencyCode}
          mailboxCurrencyCode={props.emailAccount?.localCurrency || 'NOT_FOUND'}
          updateAccruals={() => { }}
          visibleAccruals={clusterAccruals}
          invoiceAccruals={props.invoiceAccruals}
          accrualsFormMode={AccrualsFormMode.ReadOnly}
          showAggregatedView={false}
          hasApportionedAccruals={hasApportionedAccruals}
          isConsolReference={props.cluster.aggregatedTMSRefs.some((aggregatedTMSRef) => aggregatedTMSRef.jobRef?.charAt(0) === 'C')}
          exchangeRates={props.exchangeRates}
          cargowiseExchangeRates={props.cargowiseExchangeRates}
          toleranceThreshold={props.toleranceThreshold}
        />
      )}
      <div className="cluster__footer">
        <div>
          <OverlayTrigger overlay={<Tooltip><b>Drag</b> to reorder cards</Tooltip>} placement="left" delayShow={500}>
            <button className="drag-handle light-button active-on-hover" {...props.dragHandleProps} disabled={props.disabled} ><ShipamaxDragIcon /></button>
          </OverlayTrigger>
          {props.cluster.isAggregated ? (
            <span />
          ) : (
              <OverlayTrigger overlay={<Tooltip><b>Add</b> a reference</Tooltip>} placement="left" delayShow={500}>
        <button className="add-ref-button light-button active-on-hover" onClick={addJobRef} disabled={props.disabled}><ShipamaxPlusIcon /></button>
              </OverlayTrigger>
          )}
          {!noAccrualsFound && !!clusterAccruals.length && (
            <button
              onClick={() => setIsCollapsed(!isCollapsed)}
              className={`light-button cluster__show-accruals-button active-on-hover ${isCollapsed ? '' : 'cluster__show-accruals-button--open'}`}
            >
              <span>{isCollapsed ? 'Show' : 'Hide'} Accruals</span> <ShipamaxDownIcon className="select-toggle" />
            </button>
          )}
        </div>
        {props.cluster.isAggregated ? (
          <TotalsInput
            className="cluster__total"
            showIcon={true}
            value={props.cluster.total}
            name="total"
            hideInput={props.cluster.isHeader}
            label="NET TOTAL"
            fieldData={{
              objectIds: [props.cluster.id],
              tableName: 'supplier_invoice_job_ref_cluster',
              attributeNames: ['total'],
            }}
            disabled={props.disabled || props.cluster.isAggregated}
          />
        ) : (
          <TotalsInput
            className="cluster__total"
            status={initialClusterStatus || clusterStatus}
            showIcon={true}
            value={props.cluster.total}
            name="total"
            onChange={(value) => props.onClusterChanged(props.cluster.id, { total: isNaN(value) ? null : value })}
            onBlur={(name, value, withoutChange) => onBlurTotal(props.cluster.total, props.cluster.id, withoutChange)}
            hideInput={props.cluster.isHeader}
            label="NET TOTAL"
            fieldData={{
              objectIds: [props.cluster.id],
              tableName: 'supplier_invoice_job_ref_cluster',
              attributeNames: ['total'],
            }}
            disabled={props.disabled || props.cluster.isAggregated}
          />
        )}
        <OverlayTrigger overlay={<Tooltip><b>Delete</b> card</Tooltip>} placement="left" delayShow={500}>
          <button
            className="remove-button active-on-hover"
            onClick={() => props.removeCluster(props.cluster.id)}
            disabled={props.disabled}
          >
           <ShipamaxTimesIcon />
          </button>
        </OverlayTrigger>
      </div>
    </div>
  )
}
