import * as React from 'react';
import { NumberInput } from '../common/NumberInput';
import { FieldData } from '../../api/documentContainer';
import { ClusterStatus } from '../../api/jobRefCluster';
import { ShipamaxCheckIcon, ShipamaxExclamationIcon } from '../../images/Icons';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';

import './totals-input.scss';
import { FieldValidator } from './Form';

interface TotalsInputProps {
  value: number | null;
  onBlur?: (name: string, value: number | null, withoutChange: boolean, fieldData?: FieldData) => void;
  onChange?: (value: number) => void;
  hideInput?: boolean;
  disabled?: boolean;
  name?: string;
  label: string;
  status?: ClusterStatus;
  className?: string;
  fieldData?: FieldData;
  showIcon?: boolean;
  readOnly?: boolean;
  testId?: string;
  required?: boolean;
  differenceValue?: string;
  iconToolTip?: string;
  validators?: FieldValidator[];
  formEntered?: boolean;
}

export const TotalsInput: React.FC<TotalsInputProps> = (props) => {
  const classNames = [
    'totals-input',
    props.className,
    props.hideInput ? 'totals-input--icon-only' : '',
    props.status === ClusterStatus.Failed ? 'highlight' : '',
    props.readOnly ? 'totals-input--read-only' : '',
    props.showIcon && props.status && !props.readOnly ? 'totals-input--with-icon' : ''
  ];
  const icon = (
    <div className={`totals-input__icon totals-input__icon--${props.status}`} >
      {props.status === ClusterStatus.Failed && <ShipamaxExclamationIcon />}
      {props.status === ClusterStatus.Matched && <ShipamaxCheckIcon />}
      {props.status === ClusterStatus.Warning && <ShipamaxExclamationIcon />}
    </div>
  );

  return (
    <div className={classNames.join(' ')} data-test-id={props.testId}>
      {!props.hideInput && (
        <div className="totals-input__input-wrapper">
          <span className="totals-input__label">{props.label}</span>
          <NumberInput
            required={props.required}
            value={props.value}
            name={props.name || ''}
            onChange={props.onChange}
            onBlur={props.onBlur}
            disabled={props.disabled}
            fieldData={props.fieldData}
            tabIndex={props.readOnly ? -1 : undefined}
            validators={props.validators}
            formEntered={props.formEntered}
          />
        </div>
      )}
      {props.showIcon && props.status && !props.iconToolTip && icon}
      {props.showIcon && props.status && props.iconToolTip &&
        <OverlayTrigger overlay={<Tooltip>{props.iconToolTip}</Tooltip>} delayShow={500} placement="left">
          {icon}
        </OverlayTrigger>
      }
      {Boolean(props.differenceValue) && (
        <div className={`totals-difference`} >
          ({props.differenceValue})
        </div>
      )}
    </div>
  )
}
