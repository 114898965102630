import * as React from 'react';
import { Modal } from 'pivotal-ui/react/modal';
import { APIIssueCode, IssueCode, IssueCodeType } from '../../api/issueCode';
import { APITeam } from '../../api/teams';
import { APIValidationIssueCode } from '../../api/validationIssueCodes';
import { APIUser } from '../../api/comment';
import { SupplierInvoiceAPI } from '../../api/supplierInvoice';
import { ValidationType } from '../../api/emailAccount';
import { AddComment } from './activity-feed/AddComment';
import { DocumentType } from '../../api/documentContainer';

import './code-selection-modal.scss';

export enum ModalMode {
  Resubmit = 0,
  Reassign = 1,
  Discard = 2,
  Query = 3,
}

const modalTitles = {
  [ModalMode.Resubmit]: 'Re-submit',
  [ModalMode.Reassign]: 'Re-assign to',
  [ModalMode.Discard]: 'Discard Reason',
  [ModalMode.Query]: 'Query Reason'
}

const confirmButtonTexts = {
  [ModalMode.Resubmit]: 'Submit',
  [ModalMode.Reassign]: 'Assign',
  [ModalMode.Discard]: 'Discard',
  [ModalMode.Query]: `Move to 'In Query'`
}

export interface CodeSelectionModalSubmitParams {
  actionMode: ModalMode;
  codes: APIIssueCode[];
  newTeam: APITeam | null;
  newUser: APIUser | null;
  commentMessage?: string;
}

export interface CodeSelectionModalProps {
  invoiceId?: number | undefined;
  show: boolean;
  mode: ModalMode;
  team?: APITeam | null;
  selectedUser?: APIUser | null;
  codes?: APIIssueCode[];
  validationIssueCodes?: APIValidationIssueCode[];
  hideModal?: () => void;
  submit?: (params: CodeSelectionModalSubmitParams) => void;
  apDocumentTypes?: DocumentType[];
  validationType?: ValidationType;
  headerText?: string;
}

export const CodeSelectionModal: React.FC<CodeSelectionModalProps> = (props) => {
  const [selectedCodes, setSelectedCodes] = React.useState<number[]>([]);
  const [commentMessage, setCommentMessage] = React.useState<string | undefined>(undefined);
  const [showCommentModal, setShowCommentModal] = React.useState<boolean>(false);
  const [otherValue, setOtherValue] = React.useState<string>('');
  const [isExtCodeDisabled, setIsExtCodeDisabled] = React.useState<boolean>(false);
  const [allowSubmit, setAllowSubmit] = React.useState<boolean>(true);

  const modalTitle = React.useMemo<JSX.Element | string>(() => {
    let title;
    if (props.mode === ModalMode.Reassign) {
      title = <div>{modalTitles[props.mode]} <span>{props.selectedUser ? (props.selectedUser?.firstName + ' ' + props.selectedUser?.lastName) : props.team?.name}</span></div>;
    }
    else {
      title = modalTitles[props.mode];
    }

    return title;
  }, [props.mode, props.team, props.selectedUser]);

  React.useEffect(() => {
    setTimeout(() => {
      if (props.show && props.invoiceId) {
        SupplierInvoiceAPI.hasCorrections(props.invoiceId).then((corrections) => {
          setIsExtCodeDisabled(!corrections.length);
        });
      }
    }, 200);
  }, [props.show, props.invoiceId]);

  React.useEffect(() => {
    updateOtherValue();
  }, [props.codes]);

  React.useEffect(() => {
    if (props.show) {
      const codes: number[] = [];
      if (props.validationIssueCodes) {
        props.validationIssueCodes.forEach((code) => {
          if (props.codes) {
            const actualCode = props.codes.find((c) => c.id === code.issueCodeId);
            if (actualCode && ((props.mode === ModalMode.Discard && actualCode.actionType === IssueCodeType.Discard) ||
              (props.mode === ModalMode.Query && actualCode.actionType === IssueCodeType.Query) ||
              (![ModalMode.Discard, ModalMode.Query].includes(props.mode) && actualCode.actionType === IssueCodeType.Other))) {
              codes.push(actualCode.id);
            }
          }
        });
      }

      setSelectedCodes(codes);
      updateOtherValue();
    }
  }, [props.show]);

  const hideModal = () => {
    setTimeout(() => {
      setShowCommentModal(false);
      if (props.hideModal) props.hideModal();
    }, 200);
  }

  const updateOtherValue = () => {
    if (props.codes) {
      // issueCodeId 9 is 'OTH' (other)
      let otherCode: any = props.validationIssueCodes && props.validationIssueCodes.find((code) => code.issueCodeId === IssueCode.Other);
      if (otherCode) {
        setOtherValue(otherCode.description);
      } else {
        otherCode = props.codes.find((code) => code.id === IssueCode.Other);
        if (otherCode) {
          setOtherValue(otherCode.description);
        }
      }
    }
  }

  const updateSelectedCodes = (id: number) => {
    const codes = [...selectedCodes];
    const index = codes.findIndex((c) => c === id);

    if (index !== -1) codes.splice(index, 1);
    else codes.push(id);

    setSelectedCodes(codes);
  }

  const submit = () => {
    if (allowSubmit) {
      if (props.mode === ModalMode.Query && !showCommentModal) {
        setShowCommentModal(true);
        return;
      }

      if (props.submit && props.codes) {
        const codes: APIIssueCode[] = props.codes
          .filter((code) => selectedCodes.includes(code.id))
          .map((code) => code.code === 'OTH' ? { ...code, description: otherValue } : code);

        props.submit({ actionMode: props.mode, codes, newTeam: props.team || null, newUser: props.selectedUser || null, commentMessage });
      }
      setAllowSubmit(false)
    }
  }

  let disableConfirmButton = !selectedCodes.length;
  let confirmButtonText = confirmButtonTexts[props.mode];

  if (props.mode === ModalMode.Query) {
    if (!showCommentModal) {
      confirmButtonText = 'Next';
    } else {
      disableConfirmButton = !commentMessage;
    }
  }

  return (
    <Modal
      title={modalTitle}
      size="488px"
      show={props.show}
      onHide={hideModal}
      dialogClassName="code-selection-modal"
    >
      <div className="wrapper">
        {!showCommentModal && (
          <>
            <div className="code-selection-modal__headline">{props.headerText ? props.headerText : 'Please select one or more reasons for the exception.'}</div>
            <div className="codes">
              {!!props.codes && props.codes
                .filter((code) => props.validationType ? code.validationTypes.includes(props.validationType) : false)
                .filter((code) => {
                  switch (props.mode) {
                    case ModalMode.Discard:
                      return code.actionType === IssueCodeType.Discard;
                    case ModalMode.Query:
                      return code.actionType === IssueCodeType.Query;
                    default:
                      return code.actionType === IssueCodeType.Other;
                  }
                })
                .filter((code) => {
                  if (props.validationType !== ValidationType.AP) return true;
                  if (code.overheadInvoice && props.apDocumentTypes?.includes(DocumentType.OverheadInvoice)) return true;
                  return (code.apInvoice && props.apDocumentTypes?.includes(DocumentType.SupplierInvoice));
                })
                .map((code) => {
                  return <Card
                    key={code.id}
                    code={code}
                    selected={selectedCodes.includes(code.id)}
                    onSelect={updateSelectedCodes}
                    otherValue={otherValue}
                    setOtherValue={setOtherValue}
                    isOther={code.code === 'OTH'}
                    disabled={code.code === 'EXT' && isExtCodeDisabled}
                    tooltip={(code.code === 'EXT' && isExtCodeDisabled) ? 'There are no user corrections on this invoice' : undefined}
                  />
                })}
            </div>
          </>
        )}
        {showCommentModal && props.mode === ModalMode.Query && (
          <div>
            <div className="code-selection-modal__headline">Please provide the details of the query which needs to be raised</div>
            <AddComment
              commentValue={commentMessage || ''}
              setCommentValue={setCommentMessage}
              submit={submit}
            />
          </div>
        )}
        <div className="buttons">
          <button className="light-button light-button--with-border" onClick={hideModal}>Cancel</button>
          <button className="full-button"
            onClick={() => {
              submit()
            }}
            disabled={disableConfirmButton}>
            {confirmButtonText}
          </button>
        </div>
      </div>
    </Modal>
  );
}

interface CardProps {
  code: APIIssueCode;
  selected: boolean;
  otherValue: string;
  isOther: boolean;
  setOtherValue: (value: string) => void;
  onSelect: (id: number) => void;
  disabled?: boolean;
  tooltip?: string;
}

const Card: React.FC<CardProps> = (props) => {
  const [textareaActive, setTextareaActive] = React.useState<boolean>(false);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.setOtherValue(event.target.value);
  }

  const onBlur = () => {
    setTextareaActive(false);
    props.onSelect(props.code.id)
  }

  const onClick = () => {
    if (props.selected || !props.isOther) props.onSelect(props.code.id);
    else setTextareaActive(true);
  }

  const onFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    event.target.select();
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // enter key
    // holding shift and enter will create a new line in the textarea,
    // so if you're pressing enter without holding shift, we trigger the onBlur effect
    if (event.keyCode === 13 && !event.shiftKey) {
      onBlur();
    }
  }

  return (
    <div
      className={`card ${props.selected ? 'selected' : ''} ${props.disabled ? 'card--disabled' : ''}`}
      onClick={() => !props.disabled && onClick()}
      title={props.tooltip}
    >
      {props.code.code}
      {!textareaActive && <span>{props.isOther ? props.otherValue : props.code.description}</span>}
      {textareaActive && <textarea value={props.otherValue} onFocus={onFocus} onKeyDown={onKeyDown} onBlur={onBlur} onChange={onChange} autoFocus />}
    </div >
  );
}
