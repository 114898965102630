import * as React from 'react';
import { CompanyAPI } from '../../../api/company';
import { ChartColors, SerieSettings } from '../common';
import { ChartCommonProps, ChartFilterProps } from '../ChartWrapper';
import { GeneralChart } from '../GeneralChart';
import { Map2 } from '../../helpers';
import moment from 'moment';

const statusCodeMap: {
  unposted: number,
  posted: number,
  reopened: number,
  done: number,
  discarded: number,
  pending: number,
} = {
  unposted: 0,
  posted: 1,
  reopened: 2,
  done: 3,
  discarded: 4,
  pending: 5,
}

const settings: { leftAxisLabel: string, tooltip: string } = {
  leftAxisLabel: 'Status count',
  tooltip: 'Status',
}

interface Props extends ChartCommonProps {
  apiFilter: ChartFilterProps
}

export const StatusCountChart: React.FC<Props> = (props) => {
  const [disableCsvButton, setDisableCsvButton] = React.useState<boolean>(false);

  const seriesData = new Map2<number, SerieSettings>([
    [
      statusCodeMap.unposted,
      {
        label: 'Unposted',
        color: ChartColors.Color1,
      }
    ],
    [
      statusCodeMap.pending,
      {
        label: 'Pending Response',
        color: ChartColors.Color6,
      }
    ],
    [
      statusCodeMap.posted,
      {
        label: 'Posted',
        color: ChartColors.Color5,
      }
    ],
    [
      statusCodeMap.reopened,
      {
        label: 'Reopened',
        color: ChartColors.Color3,
      }
    ],
    [
      statusCodeMap.done,
      {
        label: 'Done',
        color: ChartColors.Color4,
      }
    ],
    [
      statusCodeMap.discarded,
      {
        label: 'Discarded',
        color: ChartColors.Color2,
      }
    ],
  ]);

  const fetchChartData = async () => {
    const result: any[] = await CompanyAPI.getGroupsInsightsChartData(props.apiFilter);
    if (result.reduce(((acc, curr) => acc + curr.total), 0) === 0) setDisableCsvButton(true);
    else setDisableCsvButton(false);

    return result.map((data) => ({
      name: data.aggregation,
      count: data.total,
      total: data.total,
      ...{
        [statusCodeMap.unposted]: data.unposted,
        [statusCodeMap.posted]: data.posted,
        [statusCodeMap.reopened]: data.reopened,
        [statusCodeMap.discarded]: data.discarded,
        [statusCodeMap.done]: data.done,
        [statusCodeMap.pending]: data.pending,
      }
    })) || [];
  }

  return (
    <GeneralChart
      showToggles={!props.hideToggles}
      apiFilter={props.apiFilter}
      seriesData={seriesData}
      fetchChartData={fetchChartData}
      referenceData={props.referenceData}
      isFetching={props.isFetching}
      setIsFetching={props.setIsFetching}
      counterType={props.counterType}
      groupMode="stacked"
      leftAxisSettings={{
        axisLegend: settings.leftAxisLabel,
        axisFormat: (e) => {
          if (Math.floor(+e) !== e) return '';
          return String(e);
        },
      }}
      setNotification={props.setNotification}
      renderForCustomDashboard={props.renderForCustomDashboard}
      renderAddToButton={props.renderAddToButton}
      permissionLevel={props.permissionLevel}
      customDashboards={props.customDashboards}
      insightsSettings={props.insightsSettings}
      chartUserSettings={props.chartUserSettings}
      renderExportButton={true}
      exportFilename={`Shipamax_Count_Report_${moment().format('DDMMYYYY')}.csv`}
      exportType='cinv'
      disableCsvExport={disableCsvButton}
      customPageSize={props.customPageSize}
      getChartToggles={props.getChartToggles}
      productSection={props.productSection}
    />
  )
}
