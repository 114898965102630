import * as React from 'react';
import { useLocation } from 'react-router';
import { AuthAPI } from '../../api/authentication';
import { getLoginPage } from '../../api/request';

import '../login/login.scss';

const logoSVG = require('../../images/logo-white.svg');

interface Props {
  isReset: boolean;
}

export const SetPasswordAction: React.FC<Props> = (props) => {
  const [token, setToken] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = React.useState<string>('');
  const [updateAction, setUpdateAction] = React.useState<string>('');
  const [showErrorMessage, setShowErrorMessage] = React.useState<JSX.Element | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);
  const query = new URLSearchParams(useLocation().search);
  const regexPassword = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{6,})/

  React.useEffect(() => {
    if (props.isReset) {
      setUpdateAction('Reset');
    } else {
      setUpdateAction('Set');
    }
    const queryToken = query.get("token");
    if (!queryToken) {
      location.href = getLoginPage();
    } else {
      setToken(queryToken);
    }
  }, []);

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  const onPasswordConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value);
  }

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!password || !passwordConfirm) {
      setShowErrorMessage(<span>Both fields must be filled</span>);
    } else if (!regexPassword.test(password)) {
      setShowErrorMessage(<span>The password must have at least 6 characters and contains numbers and letters</span>);
    } else if (password !== passwordConfirm) {
      setShowErrorMessage(<span>The password must be the same on both fields</span>);
    } else {
      setShowErrorMessage(null);
      let user = {
        password: password,
        verificationToken: token,
      };
      const res = await AuthAPI.changePassword(user);

      if (res && res.error) {
        if (res.error.statusCode === 401) {
          const url = `/${updateAction.toLowerCase()}password`;
          setShowErrorMessage(<span>This link has either expired or is invalid. <br /><br /> <a href={url}>Click here</a> to send a new link to your email address.</span >);
        } else {
          setShowErrorMessage(<span>Account update failed. Please raise an eRequest</span>);
        }
      } else {
        setShowSuccessMessage(true);
        window.setTimeout(() => {
          location.href = getLoginPage();
        }, 3000);
      }
    }
  }

  return (
    <form className="login-wrapper" onSubmit={onSubmit}>
      <div className="login">
        <div>
          <img src={logoSVG} className="login-logo" />
        </div>
        <div>
          <input placeholder="Password" type="password" value={password} onChange={onPasswordChange} />
        </div>
        <div>
          <input placeholder="Confirm Password" type="password" value={passwordConfirm} onChange={onPasswordConfirmChange} />
        </div>
        {showErrorMessage &&
          <div className={`login-error show`}>
            {showErrorMessage}
          </div>
        }
        <div className={`login-error ${showSuccessMessage ? 'show' : ''}`}>
          Password updated!
          <br /><br />
          You will be redirected to the login page shortly.
        </div>
        <div>
          <button type="submit">{updateAction} password</button>
        </div>
      </div >
    </form>
  );
}
