import { OptionValue } from 'react-selectize';
import { MultiSelect } from './MultiSelect';
import * as React from 'react';
import { ShipamaxDownIcon } from '../../images/Icons';

import './custom-multi-select.scss';

export const labelSeparator = ' -&- ';

let tempValues: OptionValue[] = [];

interface Props {
  values: OptionValue[];
  options: OptionValue[];
  onBlur: (values: OptionValue[]) => void;
  disabled?: boolean;
  dropdownMenuWidth?: 'input-width';
  onSearchChange?: (search: string) => void;
  renderNoResultsFound?: (item: OptionValue, search: string) => any;
  className?: string;
}

export const CustomMultiSelect: React.FC<Props> = (props) => {
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [selectedValues, setSelectedValues] = React.useState<OptionValue[]>([]);

  const optionsStateRef = React.useRef<OptionValue[]>([]);

  React.useEffect(() => {
    setSelectedValues(props.values);
  }, [props.values]);

  React.useEffect(() => {
    // There is an issue with accessing up to date state in the callback function that renders
    // selected values in the mailbox multiselect, which leads to multiple mailboxes being removed when
    // clicking the blue cross.
    // To solve that we keep a ref object with the value of selected mailboxes in sync.
    // Unlike state, when accessing ref.current we will always get the most recent value, instead of the value
    // assigned to it during the last render of the element calling the callback function.
    optionsStateRef.current = selectedValues;
  }, [selectedValues]);

  const classNames = [
    props.className || '',
    'dropdown-multiselect',
    props.dropdownMenuWidth ? 'dropdown-multiselect--menu-width-' + props.dropdownMenuWidth : ''
  ];

  return (
    <MultiSelect
      search={searchTerm}
      className={classNames.join(' ')}
      placeholder="All"
      name="multi-select-mail-boxes"
      hideResetButton
      autofocus={false}
      options={props.options}
      values={selectedValues}
      disabled={props.disabled}
      renderNoResultsFound={props.renderNoResultsFound || ((item: OptionValue, search: string) => <></>)}
      cancelKeyboardEventOnSelection={false} // Helps tab move to next input
      delimiters={[9]} // Select using tab as well as enter
      onFocus={() => {
        tempValues = selectedValues;
      }}
      onBlur={() => {
        // Only fetch new chart data when a new set of mailboxes has been selected
        if (JSON.stringify(tempValues) !== JSON.stringify(selectedValues)) {
          props.onBlur(optionsStateRef.current);
        }
      }}
      onChange={(name: string, values: OptionValue[]) => {
        setSelectedValues(values);
      }}
      onSearchChange={(search) => {
        setSearchTerm(search);
        props.onSearchChange && props.onSearchChange(search);
      }}
      renderToggleButton={({ open, flipped }) => <ShipamaxDownIcon className={`simple-select--toggle ${open && !props.disabled ? 'open' : ''}`} />}
      renderOption={(item) => {
        const labelParts = item.label.split(labelSeparator);

        return (
          <div className="simple-option">
            {labelParts.length > 1 ? (
              <>
                <span title={labelParts[0]}>{labelParts[0]}</span>
                <span> - </span>
                <span title={labelParts[1]}>{labelParts[1]}</span>
              </>
            ) : (
              <>{item.label}</>
            )}
          </div>
        )
      }}
      renderValue={(item) => {
        return (
          <span className="simple-value" title={item.label}>
            {item.label.split(labelSeparator)[0]}
            <span onClick={() => props.onBlur(optionsStateRef.current.filter((option) => option.value !== item.value))}>&times;</span>
          </span>
        )
      }}
    />
  )
}
