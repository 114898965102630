import * as React from 'react';
import { Icon } from 'pivotal-ui/react/iconography';
import { BarChart } from './charts/BarChart';
import { Toggle } from '../common/Toggle';
import { getChartDataForPage, getColorsMap, returnDisabledTogglesGivenChartData } from './utils';
import {
  Aggregation,
  AxisSettings,
  ChartType,
  dateAggregations,
  formatAggregationValue,
  OFFSET_VALUE,
  PAGE_SIZE,
  SerieSettings,
  SeriesSettings,
  SerieToggleState, shouldAnonymizeData,
} from './common';
import { aggregationsData, ChartCommonProps, ChartFilterProps, CounterType, getCounterTypesData, } from './ChartWrapper';
import { getLastPageIndex, Pagination } from './pagination/Pagination';
import { TooltipProp } from '@nivo/bar';
import { PointTooltip } from '@nivo/line';
import { IssueCodesLineChartAdapter } from './charts/IssueCodesLineChartAdapter';
import { TolerancesSerieId } from './explore-ap-pages/TolerancesChart';
import { Map2 } from '../helpers';
import { DashboardsDropdown } from './custom-dashboards/DashboardsDropdown';
import { ExportButton } from './ExportButton';
import { Notification } from '../common/Notification';
import { PermissionLevel } from '../../api/authentication';
import { APICustomDashboard } from '../../api/customDashboard';
import { ChartUserSettings, InsightsFilterSettings } from '../../api/userSettings';

export interface ChartSettings {
  getBarComponent?: (maxValue: number) => any;
  reversedKeys?: boolean;
  animate?: boolean;
  useMaxValue?: boolean;
}

interface Props extends ChartCommonProps {
  apiFilter: ChartFilterProps;
  counterType: CounterType;
  seriesData: SeriesSettings;
  showToggles?: boolean;
  compactToggles?: boolean;
  groupMode: 'grouped' | 'stacked';
  innerPadding?: number | undefined;
  leftAxisSettings?: AxisSettings;
  tooltip?: TooltipProp;
  pointTooltip?: PointTooltip;
  fetchChartData: () => Promise<any[]>;
  chartSettings?: ChartSettings;
  toggleAll?: {
    label: string;
  }
  setNotification: (notification: Notification | null) => void;
  renderForCustomDashboard?: boolean;
  hideExportButton?: boolean;
  renderAddToButton?: boolean;
  permissionLevel: PermissionLevel;
  customDashboards: APICustomDashboard[];
  insightsSettings: InsightsFilterSettings;
  chartUserSettings: ChartUserSettings;
  renderExportButton: boolean;
  exportFilename?: string;
  exportType?: "ap" | "cinv";
  disableCsvExport?: boolean;
}

export const GeneralChart = React.memo((props: Props) => {
  const pageSize = props.customPageSize || PAGE_SIZE;
  const [chartData, setChartData] = React.useState<any[]>([]);
  const [processedChartData, setProcessedChartData] = React.useState<any[]>([]);
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [keys, setKeys] = React.useState<string[]>([]);

  const [maxValue, setMaxValue] = React.useState<number>(0);

  const defaultValuesForToggles = props.seriesData.keysArray().reduce((result, key) => ({ ...result, [key]: true }), {});
  const [serieToggleStates, setSerieToggleStates] = React.useState<SerieToggleState>(defaultValuesForToggles);
  const [serieToggleEnabled, setSerieToggleEnabled] = React.useState<SerieToggleState>(defaultValuesForToggles);
  const [seriesTogglesMap, setSeriesTogglesMap] = React.useState<Map2<string, SeriesSettings>>(new Map2([]));

  const [timeoutRef, setTimeoutRef] = React.useState<any | undefined>(undefined);

  const colorsMap = getColorsMap(props.seriesData);
  const paginationFromEnd = dateAggregations.includes(props.apiFilter.aggregation);

  const chartSettings: ChartSettings = {
    getBarComponent: undefined,
    reversedKeys: true,
    animate: true,
    useMaxValue: false,
    ...props.chartSettings
  };

  React.useEffect(() => {
    if (!props.defaultToggles && props.apiFilter.userOptions) {
      setSerieToggleStates(props.apiFilter.userOptions);
    }

    setChartData([]);

    if (props.isFetching) {
      clearTimeout(timeoutRef);
      setTimeoutRef(undefined);
    }

    props.setIsFetching(true);
    setTimeoutRef(setTimeout(async () => {
      props.fetchChartData().then((data) => {
        setChartData(data);
        props.setIsFetching(false);
      });
    }, 0));
  }, [JSON.stringify(props.apiFilter)]);

  React.useEffect(() => {
    // reset the page index
    setPageIndex(paginationFromEnd ? getLastPageIndex(chartData.length, pageSize) : 0);
  }, [chartData]);

  React.useEffect(() => {
    if (![CounterType.InvoicePercentage, CounterType.LinePercentage].includes(props.counterType)) {
      if (![ChartType.Corrections, ChartType.LineCorrections].includes(props.apiFilter.chartType)) {
        setProcessedChartData(chartData);
        return;
      }

      setProcessedChartData(chartData.map((row) => {
        const keys = props.seriesData.keysArray().reduce((map: {}, key: any) => ({ ...map, [key]: row[key] + OFFSET_VALUE }), {});
        return { ...row, ...keys };
      }));
      return;
    }

    setProcessedChartData(chartData.map((row) => {
      const keys = props.seriesData.keysArray().reduce((map, key) => {
        const value = row[key] || 0;
        return { ...map, [key]: row.total ? (100 * value / row.total) : 0 };
      }, {});

      return { ...row, ...keys };
    }));
  }, [chartData, props.counterType]);

  // Hide series if toggled of in props.toggledSeries
  React.useEffect(() => {
    const keysWithResults: Set<string | number> = new Set();

    chartData.forEach((data) => {
      if (keysWithResults.size >= props.seriesData.size) return;

      for (let key of props.seriesData.keys()) {
        if (data[key] !== 0) {
          keysWithResults.add(key);
        }
      }
    });

    const keysToShow = keysWithResults.size > 0 ? props.seriesData.keysArray().filter((key) => keysWithResults.has(key) && serieToggleStates[key]) : props.seriesData.keysArray();

    setKeys((chartSettings.reversedKeys ? keysToShow.reverse() : keysToShow) as string[]);

    const seriesMap: Map2<string, SeriesSettings> = new Map2([]);
    props.seriesData.keysArray().forEach((serieKey) => {
      const serie = props.seriesData.get(serieKey);
      const subSection = serie?.subSection || '';
      const subSectionSerie = seriesMap.get(subSection);
      if (subSectionSerie && serie) {
        subSectionSerie.set(serieKey, serie);
      } else if (serie) {
        seriesMap.set(subSection, new Map2<string | number, SerieSettings>([
          [
            serieKey,
            serie
          ],
        ]));
      }
    })
    setSeriesTogglesMap(seriesMap);
  }, [serieToggleStates, props.seriesData, chartData, chartSettings.reversedKeys]);

  React.useEffect(() => {
    if (props.apiFilter.chartType === ChartType.Tolerances && !dateAggregations.includes(props.apiFilter.aggregation)) {
      setProcessedChartData((processedChartData) => processedChartData.sort((row1, row2) => {
        if (serieToggleStates[TolerancesSerieId.AccrualsIncreases])
          return row2['value-' + TolerancesSerieId.AccrualsIncreases] - row1['value-' + TolerancesSerieId.AccrualsIncreases];
        return Math.abs(row2['value-' + TolerancesSerieId.AccrualsDecreases]) - Math.abs(row1['value-' + TolerancesSerieId.AccrualsDecreases]);
      }));
    }
  }, [props.seriesData, chartData, props.apiFilter.chartType, props.apiFilter.aggregation, serieToggleStates]);

  React.useEffect(() => {
    disableTogglesGivenChartData(getChartDataForPage(chartData, pageSize, pageIndex, paginationFromEnd));
  }, [chartData, pageIndex, paginationFromEnd]);

  const disableTogglesGivenChartData = (chartData: object[]) => {
    const result = returnDisabledTogglesGivenChartData(chartData, serieToggleStates);

    setSerieToggleEnabled(result);

    let maxTotal = 0;

    chartData.map((row: any) => {
      const rowMaxValue = row.maxValue || row.total;

      if (rowMaxValue > maxTotal) {
        maxTotal = rowMaxValue;
      }
    })
    setMaxValue(maxTotal);
  }

  const onSerieToggleChecked = (id: number | string, checked: boolean) => {
    setSerieToggleStates({ ...serieToggleStates, [id]: checked });
    props.getChartToggles && props.getChartToggles({ ...serieToggleStates, [id]: checked });
  }

  const onAllSeriesToggleChecked = (id: number | string, checked: boolean) => {
    setSerieToggleStates(Object.keys(serieToggleStates).reduce((result, key) => ({ ...result, [key]: checked }), {}));
  }

  const isLongLabel = [
    Aggregation.Mailbox,
    Aggregation.Team,
    Aggregation.User,
    Aggregation.TeamModifier,
    Aggregation.UserModifier,
    Aggregation.JobOwner,
    Aggregation.Creditor,
    Aggregation.PostingUser,
    Aggregation.Importer,
    Aggregation.Supplier,
  ].includes(props.apiFilter.aggregation) ||
    ([
      Aggregation.Creditor,
      Aggregation.Importer,
      Aggregation.Supplier,
    ].includes(props.apiFilter.aggregation) && shouldAnonymizeData);

  const bottomAxisSettings: AxisSettings = {
    axisLegend: aggregationsData[props.apiFilter.aggregation].xAxisLabel,
    axisFormat: (value) => formatAggregationValue(value, props.apiFilter.aggregation, true, props.referenceData),
  };

  const showIssueCodesLineChart = props.apiFilter.chartType === ChartType.IssueCodes && dateAggregations.includes(props.apiFilter.aggregation);

  const defaultTooltip: TooltipProp = (target) => {
    let value;
    if ([CounterType.InvoicePercentage, CounterType.LinePercentage].includes(props.counterType)) {
      value = target.value.toFixed(2) + '% (' + Math.round(Number(target.data.total) / 100 * target.value) + ')';
    } else {
      value = target.value;
    }
    return (
      <p className="chart-tooltip">
        {formatAggregationValue(target.indexValue, props.apiFilter.aggregation, false, props.referenceData)} - {props.seriesData.get(target.id as any)?.label}: <b>{value}</b>
      </p>
    )
  }

  let barComponent = chartSettings.getBarComponent ? chartSettings.getBarComponent(maxValue) : undefined;
  const counterTypesData = getCounterTypesData(props.apiFilter.chartType);

  return (
    <>
      <div className="chart">
        <div className={`chart-overlay ${props.isFetching ? 'show' : ''}`}><Icon style={{ fontSize: '48px' }} src="spinner-md" /></div>
        {!showIssueCodesLineChart ? (
          <BarChart
            data={getChartDataForPage(processedChartData, pageSize, pageIndex, paginationFromEnd)}
            keys={keys}
            colorsMap={colorsMap}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fill: '#7C7C7C'
                  }
                }
              },
            }}
            leftAxisSettings={{
              axisLegend: counterTypesData[props.counterType].axisLabel,
              ...props.leftAxisSettings
            }}
            bottomAxisSettings={{
              ...bottomAxisSettings,
              renderTick: isLongLabel ? (props: any) => {
                const pushLower = props.tickIndex % 2;
                return (
                  <g
                    transform={`translate(${props.x},${props.y})`}
                    style={{ opacity: props.opacity }}
                  >
                    <line x1="0" x2="0" y1="5" y2={pushLower ? 18 : 10} style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: 1 }} />
                    <text
                      alignmentBaseline={props.textBaseline}
                      style={{
                        fontFamily: 'sans-serif',
                        fontSize: '11px',
                        fill: 'rgb(51, 51, 51)',
                      }}
                      textAnchor={props.textAnchor}
                      transform={`translate(${props.textX},${props.textY})`}
                      y={pushLower ? 18 : 3}
                    >
                      {props.format(props.value)}
                    </text>
                  </g>
                )
              } : undefined
            }}
            indexBy="name"
            groupMode={props.groupMode}
            tooltip={props.tooltip || defaultTooltip}
            barComponent={barComponent}
            maxValue={chartSettings.useMaxValue ? maxValue : undefined}
            innerPadding={props.innerPadding}
            animate={chartSettings.animate}
          />
        ) : (
          <IssueCodesLineChartAdapter
            toggledSeries={serieToggleStates}
            data={getChartDataForPage(processedChartData, pageSize, pageIndex, paginationFromEnd)}
            colorsMap={colorsMap}
            bottomAxisSettings={bottomAxisSettings}
            tooltip={props.pointTooltip}
            displayInPercentage={[CounterType.InvoicePercentage, CounterType.LinePercentage].includes(props.counterType)}
          />
        )}
        {processedChartData.length > pageSize && (
          <div className="pagination-controls">
            <Pagination
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              lastPageIndex={getLastPageIndex(processedChartData.length, pageSize)}
            />
          </div>
        )}
      </div>
      {props.showToggles && (
        <>
          <div className={`flex flex-wrap toggle-section toggle-section--chart-${props.apiFilter.chartType}`}>
            {seriesTogglesMap.keysArray().map((subSection, subSectionIndex) => (
              <div className="flex flex-column" key={subSectionIndex}>
                {subSection && <span className="toggle-sub-section">{subSection}</span>}
                <div className={`legend ${props.compactToggles ? 'legend--compact' : ''} legend--chart-${props.apiFilter.chartType}`}>
                  {seriesTogglesMap.get(subSection)?.keysArray().map((key) => (
                    <Toggle
                      color={props.seriesData.get(key)?.color || ''}
                      label={props.seriesData.get(key)?.label || ''}
                      id={key}
                      onChecked={onSerieToggleChecked}
                      checked={serieToggleStates[key]}
                      hideToggle={!serieToggleEnabled[key]}
                      tooltip={props.seriesData.get(key)?.tooltip || ''}
                      key={key}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
          {props.toggleAll && (
            <div className="toggle__all-wrapper">
              <Toggle
                label={props.toggleAll.label}
                id="toggle-all"
                checked={!Object.values(serieToggleStates).some((checked) => !checked)}
                onChecked={onAllSeriesToggleChecked}
              />
            </div>
          )}

        </>
      )}
      {!props.renderForCustomDashboard && (
        <div className='chart-footer'>
          {props.renderExportButton && (
            <ExportButton
              filename={props.exportFilename || ''}
              setNotification={props.setNotification}
              apiFilter={props.apiFilter}
              type={props.exportType || 'ap'}
              disabled={props.disableCsvExport || false}
            />
          )}

          {props.renderAddToButton && (
            <DashboardsDropdown
              permissionLevel={props.permissionLevel}
              customDashboards={props.customDashboards}
              addToMode={true}
              insightsSettings={props.insightsSettings}
              chartSettings={props.chartUserSettings}
              setNotification={props.setNotification}
              serieToggleStates={serieToggleStates}
              productSection={props.productSection || 1}
            />
          )}
        </div>
      )}
    </>
  )
});
