import * as React from 'react';
import { Icon } from 'pivotal-ui/react/iconography';
import { TextInput } from '../../common/TextInput';
import { isFormInvalid, RecordValidator } from '../../common/Form';
import { APICustomDashboard, CustomDashboardAPI } from '../../../api/customDashboard';
import { CustomModal } from '../../common/CustomModal';
import { SectionModule } from '../common';

interface Props {
  id: number | undefined;
  sectionModule: SectionModule | undefined;
  onCancel: () => void;
  onSubmit: (newName: string, newChartName?: string) => void;
  type: 'chart' | 'dashboard' | 'both';
}

export const NameForm: React.FC<Props> = (props) => {
  const [newName, setNewName] = React.useState<string>('');
  const [newChartName, setNewChartName] = React.useState<string>('');
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
  const [isNameTaken, setIsNameTaken] = React.useState<boolean>(false);
  const [formEntered, setFormEntered] = React.useState<boolean>(false);

  React.useEffect(() => {
    let didCancel = false;

    setFormEntered(false);
    if (!newName) {
      setIsNameTaken(false);
      return;
    }

    if (props.type === 'dashboard') {
      CustomDashboardAPI.isDashboardNameTaken(newName, props.sectionModule!, props.id).then((result) => {
        if (didCancel) return;
        setIsNameTaken(result);
      });
    }

    return () => { didCancel = true; };
  }, [newName]);

  const onSubmit = async () => {
    setFormEntered(true);
    if (submitDisabled) return;

    setShowSpinner(true);
    await props.onSubmit(newName, newChartName);
    setShowSpinner(false);
  }

  const recordValidator: RecordValidator = {
    name: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: (name) => !!name
        },
        {
          errorMessage: 'Name cannot be longer than 140 characters',
          isValid: (name) => name.length <= 140
        },
        ...(props.type === 'dashboard' ? [{
          errorMessage: 'Dashboard name already exists. Please try again',
          isValid: () => !isNameTaken
        }] : []),
      ]
    },
  }

  const submitDisabled = isFormInvalid<APICustomDashboard>({ name: newName }, recordValidator);

  return (
    <CustomModal
      onClose={props.onCancel}
      className="pt-[40px] name-form"
    >
      <h2
        className="text-blueTint1 text-[16px] font-[700] mb-[28px]"
      >Please enter your {props.type === 'chart' ? 'chart' : 'dashboard'} name</h2>
      <TextInput
        autoFocus
        value={newName || ''}
        name="name"
        onChange={(value) => setNewName(value)}
        validators={recordValidator.name.validators}
        formEntered={formEntered}
        wrapperClassName="h-[50px]"
      />

      {props.type === 'both' && (
        <>
          <h2
            className="text-blueTint1 text-[16px] font-[700] mb-[28px]"
          >Please enter your chart name</h2>
          <TextInput
            value={newChartName || ''}
            name="name"
            onChange={(value) => setNewChartName(value)}
            validators={recordValidator.name.validators}
            formEntered={formEntered}
            wrapperClassName="h-[50px]"
          />
        </>
      )}

      <div className="flex justify-end mt-[20px]">
        <button className="btn-cancel light-button" onClick={props.onCancel}>Cancel</button>
        <button disabled={showSpinner} className="btn-confirm full-button" onClick={onSubmit}>
          {showSpinner ? <Icon src="spinner-md" /> : 'Submit'}
        </button>
      </div>
    </CustomModal>
  );
}
