import * as moment from 'moment';
import { Map2 } from '../helpers';
import { labelSeparator } from '../common/CustomMultiSelect';
import { OptionValue } from 'react-selectize';

const SHIPAMAX_DEMO_COMPANY_IDS = [25, 54];
export const shouldAnonymizeData = SHIPAMAX_DEMO_COMPANY_IDS.includes(parseInt(localStorage.getItem('companyId') || ''));

export enum ChartColors {
  Color1 = '#B168A5',
  Color2 = '#E15B63',
  Color3 = '#EDC376',
  Color4 = '#B1CC6B',
  Color5 = '#5887B7',
  Color6 = '#6F77AC',
  Color7 = '#F0E658',
  Color8 = '#5FAD78',
  Color9 = '#8D78AC',
  Color10 = '#E9AF58',
  Color11 = '#619BC6',
  Color12 = '#E48E5F',
  // Color13 = '#F5CAC3',
  // Color13 = '#84A59D',
  // Color13 = '#C6DABF',
  Color13 = '#EBB967',
  Color14 = '#D1D962',
  Color15 = '#a8c5db',
  Color16 = '#647FB1',
  Color17 = '#9F70A8',
  Color18 = '#C96284',
  Color19 = '#E79E5B',
  Color20 = '#5D91BF',
  Color21 = '#EFD467',
  Color22 = '#7E77AC',
  Color23 = '#60A49F',
  Color24 = '#E37461',
  Color25 = '#88BD71',
  Color26 = '#CCE7FF',
  Color27 = '#AD961C',
  Color28 = '#62D91B',
  Color29 = '#D5A78C',
  Color30 = '#7E991C',
}

export enum ChartType {
  InvoiceCount = 1,
  Status = 2,
  IssueCodes = 3,
  Corrections = 4,
  AgeOfOldest = 5,
  ExceptionTypes = 6,
  Tolerances = 7,
  InvoiceLineItemCount = 8,
  LineCorrections = 9,
}

export enum OrganisationType {
  Any = 1,
  Importer = 2,
  Supplier = 3,
}

export enum DataSet {
  CommercialInvoice = 1,
}

export enum Aggregation {
  WeekReceived = 1,
  MonthReceived = 2,
  Creditor = 3,
  Team = 4,
  ExceptionCause = 5,
  User = 6,
  DayReceived = 7,
  Mailbox = 8,
  DayPosted = 9,
  WeekPosted = 10,
  MonthPosted = 11,
  TeamModifier = 12,
  UserModifier = 13,
  Importer = 14,
  Supplier = 15,
  PostingUser = 16,
  JobOwner = 17,
}

const postedAggregations = [Aggregation.DayPosted, Aggregation.WeekPosted, Aggregation.MonthPosted];
export const dateAggregations = [...postedAggregations, Aggregation.DayReceived, Aggregation.WeekReceived, Aggregation.MonthReceived];
export const tolerancesModifier = [Aggregation.UserModifier, Aggregation.TeamModifier];

export enum SectionModule {
  APInvoice = 1,
  ForwardAndClearance = 2,
}

export enum InvoiceStatusSegment {
  Unposted = 1,
  Queried = 2,
  Posted = 3,
  PostedAfterExceptions = 4,
  Discarded = 5,
  PostedAfterCorrection = 6,
}

export enum AssignedToOptions {
  Mailbox = 1,
  Team = 2,
  User = 3
}

export interface AggregationDataType {
  [key: string]: {
    dropdownLabel: string;
    xAxisLabel: string;
    enabledFor?: ChartType[];
    fieldName?: string;
  }
}

export const correctedStatus = 999;
export const OFFSET_VALUE = 100;

export const getDateFormat = (aggregation: Aggregation, isShort: boolean): string => {
  switch (aggregation) {
    case Aggregation.DayReceived:
    case Aggregation.DayPosted:
      return isShort ? 'Do MMM' : 'Do MMM YYYY';
    case Aggregation.WeekReceived:
    case Aggregation.WeekPosted:
      return isShort ? 'Do MMM' : 'Do MMM YYYY';
    case Aggregation.MonthReceived:
    case Aggregation.MonthPosted:
      return isShort ? 'MMM' : 'MMM YYYY';
    default:
      return ''
  }
};

export const formatAggregationValue = (value: string | number | Date, aggregation: Aggregation, isShort: boolean, referenceData: ReferenceData): string => {
  if (postedAggregations.includes(aggregation) && !value) return 'Unposted';
  if ([Aggregation.UserModifier, Aggregation.TeamModifier].includes(aggregation) && !value) return 'Shipamax';

  switch (aggregation) {
    case Aggregation.WeekReceived:
    case Aggregation.WeekPosted:
      if (typeof value === 'string') {
        const [year, week] = value.split('-');
        return moment().year(parseInt(year)).day('Monday').isoWeek(parseInt(week)).day(1).format(getDateFormat(aggregation, isShort));
      }
      return value?.toString() || 'Unknown';
    case Aggregation.DayReceived:
    case Aggregation.DayPosted:
    case Aggregation.MonthReceived:
    case Aggregation.MonthPosted:
      return moment(value).format(getDateFormat(aggregation, isShort));
    case Aggregation.Team:
    case Aggregation.TeamModifier:
      if (!value) return 'No team';
      if (shouldAnonymizeData) return 'Team ' + value;
      return referenceData.teamsMap[value as number]?.label || 'Unknown team';
    case Aggregation.User:
    case Aggregation.UserModifier:
    case Aggregation.PostingUser:
      if (!value) return 'No user';
      if (shouldAnonymizeData) return 'User ' + value;
      return referenceData.usersMap[value as number]?.label.split(labelSeparator)[0] || 'Unknown user';
    case Aggregation.Mailbox:
      if (!value) return 'No mailbox';
      if (shouldAnonymizeData) return 'Mailbox ' + value;
      return referenceData.mailboxesMap[value as number]?.label || 'Unknown mailbox';
    case Aggregation.ExceptionCause:
      return value as string || 'No code';
    case Aggregation.Creditor:
      if (!value) return 'No creditor';
      if (shouldAnonymizeData) return 'Creditor ' + value;
      break;
    case Aggregation.Importer:
      if (!value) return 'No importer';
      if (shouldAnonymizeData) return 'Importer ' + value;
      break;
    case Aggregation.Supplier:
      if (!value) return 'No supplier';
      if (shouldAnonymizeData) return 'Supplier ' + value;
      break;
  }

  return value?.toString() || 'Unknown';
}

export type SerieSettings = {
  label: string,
  color: string,
  tooltip?: string,
  subSection?: string,
}

export type SeriesSettings = Map2<string | number, SerieSettings>;

export interface SerieToggleState {
  [k: string]: boolean;
}

export const PAGE_SIZE = 12;

export interface AxisSettings {
  axisLegend?: string;
  axisFormat?: (value: string | number | Date) => string;
  tickRotation?: number;
  renderTick?: (props: any) => any;
}

export const chartTypeOptions = {
  [SectionModule.APInvoice]: [
    {
      value: ChartType.InvoiceCount,
      label: 'Invoice count',
    },
    {
      value: ChartType.Status,
      label: 'Status',
    },
    {
      value: ChartType.ExceptionTypes,
      label: 'Exception Types',
    },
    {
      value: ChartType.IssueCodes,
      label: 'Issue Codes',
    },
    {
      value: ChartType.Corrections,
      label: 'Corrections',
    },
    {
      value: ChartType.Tolerances,
      label: 'Tolerances',
    },
    {
      value: ChartType.AgeOfOldest,
      label: 'Age of Oldest',
    },
  ],
  [SectionModule.ForwardAndClearance]: [
    {
      value: ChartType.InvoiceCount,
      label: 'Commercial invoice count',
    },
    {
      value: ChartType.InvoiceLineItemCount,
      label: 'Line items count',
    },
    {
      value: ChartType.Status,
      label: 'Commercial invoice status',
    },
    {
      value: ChartType.Corrections,
      label: 'Commercial invoice header corrections',
    },
    // DON'T REMOVE COMMENT BELOW: Please refer to [SHIP-10681] comments in JIRA for more information.
    // {
    //   value: ChartType.LineCorrections,
    //   label: 'Commercial invoice line corrections',
    // },
  ],
};

export interface OptionValueMap {
  [index: number]: OptionValue
}

export interface ReferenceData {
  teamsMap: OptionValueMap;
  usersMap: OptionValueMap;
  mailboxesMap: OptionValueMap;
}
