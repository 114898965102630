import * as React from 'react';
import { Modal } from 'pivotal-ui/react/modal';
import { TextInput } from '../../../common/TextInput';
import { AuthAPI } from '../../../../api/authentication';

import './show-full-key-confirm.scss'

interface Props {
  onConfirmed: () => void;
  onCancelled: () => void;
}

export const ShowFullKeyConfirm: React.FC<Props> = (props) => {
  const [password, setPassword] = React.useState<string>('');
  const [isValid, setIsValid] = React.useState<boolean | undefined>();
  const [attemptsCount, setAttemptsCount] = React.useState<number>(0);

  React.useEffect(() => {
    setIsValid(undefined);
  }, [password]);

  const onLoginConfirm = async () => {
    const parentUserEmail = localStorage.getItem('parentUserEmail') !== 'undefined' ? localStorage.getItem('parentUserEmail') : null;
    const userEmail = localStorage.getItem('email') !== 'undefined' ? localStorage.getItem('email') : null;

    const email = parentUserEmail || userEmail || '';
    const result = await AuthAPI.login({ email, password });

    if (result.error) {
      setIsValid(false);

      if (attemptsCount >= 5) {
        await AuthAPI.logout();
        return;
      }

      setAttemptsCount((currentValue) => currentValue + 1);
      return;
    }

    props.onConfirmed();
  }

  return (
    <>
      <Modal
        show={true}
        title="Please re-enter your password"
        size="360px"
        onHide={props.onCancelled}
        dialogClassName={`confirm-modal show-full-key-confirm-modal`}
      >
        <TextInput
          value={password}
          name="password-input"
          setter={setPassword}
          type="password"
          formEntered={true}
          validators={[{
            isValid: () => isValid === true || isValid === undefined,
            errorMessage: 'Incorrect password. Please double-check and try again.'
          }]}
          disableAutocomplete
        />
        <div className="show-full-key-confirm-modal__buttons">
          <button className="light-button" onClick={props.onCancelled}>Cancel</button>
          <button className="full-button" onClick={onLoginConfirm}>Submit</button>
        </div>
      </Modal>
    </>
  )
}
