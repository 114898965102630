import * as React from 'react';
import { Exception, ExceptionCode, ExceptionDescriptions } from '../../api/validationResults';
import { ShipamaxExclamationIcon, ShipamaxInformationIcon } from '../../images/Icons';


interface GroupedException extends Exception {
  count: number;
}

interface ExceptionsMap {
  [index: string]: {
    exception: Exception,
    count: number,
    order: number
  }
}

interface Props {
  exceptions: Exception[];
  isFC?: boolean;
  hasRollupExceptions?: boolean;
}

const genericFieldExceptions = [
  ExceptionCode.CommercialInvoiceNumberMissing,
  ExceptionCode.CommercialInvoiceGrossTotalMissing,
  ExceptionCode.CommercialInvoiceSupplierMissing,
  ExceptionCode.CommercialInvoiceImporterMissing,
  ExceptionCode.DuplicatedCInvNumberInGroup,
  ExceptionCode.DuplicateSJobRefInGroup,
  ExceptionCode.DuplicatedBillNumberInGroup,
  ExceptionCode.BillOfLadingMissingReferences,
  ExceptionCode.CommercialInvoiceImporterNameMissing,
  ExceptionCode.CommercialInvoiceSupplierNameMissing,
  ExceptionCode.BillOfLadingNumberMissing,
  ExceptionCode.MasterBillOfLadingSendingAgentNameMissing,
  ExceptionCode.MasterBillOfLadingReceivingAgentNameMissing,
  ExceptionCode.MasterBillOfLadingCarrierMissing,
  ExceptionCode.HouseBillOfLadingShipperNameMissing,
  ExceptionCode.HouseBillOfLadingConsigneeNameMissing,
  ExceptionCode.PackingListInvoiceNumberMissing
];

const rollupExceptions = [
  ExceptionCode.AccrualRollupMeasuresFailed,
];

export const ExceptionsContainer: React.FC<Props> = (props) => {

  const exceptionsMap: ExceptionsMap = props.exceptions.reduce((result: ExceptionsMap, exception, index) => {
    let code = exception.code;
    let description = exception.description;

    if (rollupExceptions.includes(code)) {
      props.hasRollupExceptions = true;
    }

    if (genericFieldExceptions.includes(code)) {
      code = ExceptionCode.GenericFieldException;
      description = ExceptionDescriptions[ExceptionCode.GenericFieldException];
    }
    if (result[description]) {
      if (code !== ExceptionCode.GenericFieldException) {
        result[description].count += 1;
      }
    } else {
      result[description] = {
        count: 1,
        exception: { ...exception, code, description },
        order: index
      }
    }

    return result;
  }, {} as ExceptionsMap);

  const errors: GroupedException[] = [];
  const warnings: GroupedException[] = [];
  const infos: GroupedException[] = [];

  Object.values(exceptionsMap)
    .sort((ex1, ex2) => ex1.order - ex2.order)
    .forEach(({ exception, count }) => {
      if (exception.isWarning || exception.code === ExceptionCode.ManualApprovalRequired) {
        warnings.push({ ...exception, count });
      } else if (!exception.isInformation) {
        errors.push({ ...exception, count });
      } else {
        infos.push({ ...exception, count });
      }
    });

  const getNeedHelpLink = () => {
    if (props.isFC) {
      return "/knowledge-base/Exceptions-and-warnings--c650c70b9955443891b3dee02c6cadb3";
    } else if (props.hasRollupExceptions) {
      return "/knowledge-base/Roll-Up-Exceptions--234ae427173044c8bae0e80304b4292e";
    }
    return "/knowledge-base/Resolving-Exceptions--e0a53c175d2b42698aa5cff07200f723";
  }

  return (
    <>
      {props.exceptions.length > 0 && (
        <div className="status-bar-container__exceptions with-custom-scrollbar">
          <div className="status-bar-container__exceptions__errors">
            {errors.map((error, index) => (
              <div className="status-bar-container__exceptions__row" key={index}>
                <ShipamaxExclamationIcon />
                <span>{error.count > 1 ? `${error.count}x ` : ''}{error.description}</span>
                <a
                  href={getNeedHelpLink()}
                  className="light-button active-on-hover"
                  target="__blank"
                >Need help?</a>
              </div>
            ))}
          </div>
          <div className="status-bar-container__exceptions__warnings">
            {warnings.map((warning, index) => (
              <div className="status-bar-container__exceptions__row" key={index}>
                <ShipamaxExclamationIcon />
                <span>{warning.description}</span>
                <a
                  href={getNeedHelpLink()}
                  className="light-button active-on-hover"
                  target="__blank"
                >
                  Need help?
                </a>
              </div>
            ))}
          </div>
          <div className="status-bar-container__exceptions__infos">
            {infos.map((info, index) => (
              <div className="status-bar-container__exceptions__row" key={index}>
                <ShipamaxInformationIcon />
                <span>{info.description}</span>
              </div>
            ))}
          </div>
        </div>
      )
      }
    </>
  );
}
