import { APIError, Notification } from '../Notification';
import * as React from 'react';
import { APIEmailBasic, EmailAPI } from '../../../api/email';
import { APIDocCoordinate, APIDocumentContainer, APIDocumentType, DocumentContainerAPI, DocumentType } from '../../../api/documentContainer';
import { FileSplitter, FileSplitterSettings, LevelOfChanges } from './FileSplitter';
import { DocumentViewer } from './DocumentViewer';
import { OptionValue } from 'react-selectize';
import { ShipamaxExclamationIcon } from '../../../images/Icons';
import { TableDefinitionsEditor } from './TableDefinitionsEditor';

import './document-viewer.scss';

interface Props {
  document?: APIDocumentContainer | APIEmailBasic;
  setNotification: (notification: Notification | null) => void;
  documentTypesOptions: OptionValue[];
  fileSplitterSettings: FileSplitterSettings;
  hideOverlay?: boolean;
  onFileSplitFinished: (levelOfChanges: LevelOfChanges, affectedDocument: APIDocumentContainer) => void;
  fileUnqId?: string | undefined;
  setFileUnqId?: (unqId: string | undefined) => void;
  setModalEnabled?: (value: boolean) => void;
  filterCoordinates?: (coordinate: APIDocCoordinate) => boolean;
  loadDefaultByWidth?: boolean;
  documentTypes?: APIDocumentType[];
  reloadGroupData?: () => Promise<void>;
}

export const DocumentViewerContainer: React.FC<Props> = React.memo((props) => {
  const [pdfDocument, setPdfDocument] = React.useState<any>(null);
  const [documentUrl, setDocumentUrl] = React.useState<string | undefined>();
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
  const [isFileSplitterActive, setIsFileSplitterActive] = React.useState<boolean>(false);
  const [unsupportedFileExt, setUnsupportedFileExt] = React.useState<string>('');
  const [showEditDefinitions, setShowEditDefinitions] = React.useState<boolean>(false);
  const [activeEditPage, setActiveEditPage] = React.useState(0);
  const document = props.document as APIDocumentContainer;

  React.useEffect(() => {
    if (props.setModalEnabled) props.setModalEnabled(isFileSplitterActive);
    if (!isFileSplitterActive) reloadDoc();
  }, [isFileSplitterActive])

  React.useEffect(() => {
    if (props.setModalEnabled) props.setModalEnabled(showEditDefinitions);
    if (showEditDefinitions && document?.id) {
      DocumentContainerAPI.preLoadTableCoordinatesCache(document.id);
      DocumentContainerAPI.registerTableCoordinatesStatistics({
        documentId: document.id,
        actionType: 'opened',
      });
    }
  }, [showEditDefinitions])

  const reloadDoc = () => {
    let didCancel = false;
    setUnsupportedFileExt('');

    const getDocumentFromAPI = async () => {
      setDocumentUrl(undefined);
      setPdfDocument(null); // clear the current document while we get the new one from the api
      setShowSpinner(true);
      if (props.setFileUnqId) props.setFileUnqId(undefined);
      let result: any;
      if (document.documentType === DocumentType.EmailBody) {
        result = await EmailAPI.fetchEmailFile(document.unqId);
      } else if (document) {
        result = await DocumentContainerAPI.fetchDocument(document.parentId || document.id);

        if (didCancel) {
          console.log('Fetched document data no longer needed due to another fetch request for a different document');
          return;
        }
      }
      if (result &&
        (result.Metadata?.unqid === document?.unqId || document.documentType === DocumentType.EmailBody)) {
        setPdfDocument(result.Body);
        if (props.setFileUnqId) props.setFileUnqId(result.Metadata?.unqid);

        let subResult;
        if (document && document.parentId) {
          subResult = await DocumentContainerAPI.fetchDocument(document.id);
        }
        const blob = new Blob([new Uint8Array(subResult?.Body.data || result.Body.data)], { type: 'application/pdf' });
        setDocumentUrl(window.URL.createObjectURL(blob));
      }
      else {
        setPdfDocument(null);
        if (result && result.error && result.error === 'UnsupportedFileType') {
          setUnsupportedFileExt(result.fileType.ext);
        } else {
          props.setNotification({ ...APIError, details: { documentId: document.id, unqId: document.unqId }, reason: `Error on document viewer request to document api` });
        }
        if (result && result.Metadata?.unqid !== document?.unqId) {
          console.log('File loaded didn`t retrieve correct data');
        }
      }
      setShowSpinner(false);
    }

    setIsFileSplitterActive(false);
    if (props.document) getDocumentFromAPI();
    else setPdfDocument(null);

    return () => {
      didCancel = true;
    };
  };

  React.useEffect(() => {
    return reloadDoc();
  }, [props.document?.id]);

  const fileName = document ? (document.parent ? document.parent.filename : document.filename) : '';

  const openEditView = (pageIndex: number) => {
    setActiveEditPage(pageIndex);
    setShowEditDefinitions(true)
  }

  return (
    <section className={`document-viewer width-height-draggable `}>
      {unsupportedFileExt !== '' &&
        <div className='unsupported-file-message'>
          <div className='icon-style'><ShipamaxExclamationIcon /></div>
          <div>This file type cannot be displayed</div>
        </div>
      }
      <DocumentViewer
        documentUrl={documentUrl}
        pdfDocument={pdfDocument}
        filePages={document?.filePages || null}
        filename={fileName}
        multidocFilename={document?.multidocFilename || null}
        setNotification={props.setNotification}
        setIsFileSplitterActive={setIsFileSplitterActive}
        showSpinner={showSpinner}
        parentDocumentId={document?.parentId || null}
        hideFileSplitterButton={props.fileSplitterSettings.hideFileSplitterButton}
        hideOverlay={props.hideOverlay}
        documentId={document?.documentType === DocumentType.EmailBody ? undefined : document?.id}
        filterCoordinates={props.filterCoordinates}
        loadDefaultByWidth={props.loadDefaultByWidth}
        setShowEditDefinitions={setShowEditDefinitions}
        openEditView={openEditView}
        specificId={document?.commercialInvoice?.id || document?.packingList?.id || document?.billOfLading?.id || document?.supplierInvoice?.id || 0}
        documentType={document?.documentType}
      />
      {isFileSplitterActive && document && (
        <FileSplitter
          document={document}
          unqIdLoaded={props.fileUnqId} /* This attribute can be removed, adding to do some investigation SHIP-7874 & SHIP-8317*/
          filename={fileName}
          showSpinner={showSpinner}
          pdfDocument={pdfDocument}
          onClose={() => {
            setIsFileSplitterActive(false);
          }}
          setNotification={props.setNotification}
          documentUrl={documentUrl}
          documentTypesOptions={props.documentTypesOptions}
          fileSplitterSettings={props.fileSplitterSettings}
          onFileSplitFinished={props.onFileSplitFinished}
          defaultPageIndex={document.filePages?.length ? document.filePages[0] : undefined}
        />
      )}
      {showEditDefinitions &&
        <TableDefinitionsEditor
          setShowEditDefinitions={setShowEditDefinitions}
          pdfDocument={pdfDocument}
          filename={fileName}
          filePages={document.filePages}
          setNotification={props.setNotification}
          showSpinner={showSpinner}
          document={document}
          documentTypes={props.documentTypes}
          reloadGroupData={props.reloadGroupData}
          groupId={document?.groupId}
          activePage={activeEditPage}
        />
      }
    </section>
  );
});
