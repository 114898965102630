import * as React from 'react';
import * as moment from "moment";
import { CollapsibleContainer } from '../../common/CollapsibleContainer';
import { WrapperLabel } from '../../common/WrapperLabel';
import { OptionValue } from 'react-selectize';
import { DateSelectEditor } from '../../common/DateSelectEditor';
import { TextInput } from '../../common/TextInput';
import { APIDocumentGroupDocument, APIDocumentGroupShipment } from '../../../api/documentGroup';
import { APIBillOfLadingImporterReference, BillOfLadingAPI } from '../../../api/billOfLading';
import { BillOfLadingImporterReference } from '../../../api/billOfLadingImporterReference';
import { FieldChangedParams, IssuerDetailMap, JobType } from '../common';
import { CreditorSelect } from '../../common/CreditorSelect';
import { PortSelect } from '../../common/PortSelect';
import { BillOfLadingNotifyParty } from '../../../api/billOfLadingNotifyParty';
import { CustomSelect } from '../../common/CustomSelect';
import { PacklinesList } from './PacklinesList';
import { ContainersList } from './ContainersList';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';
import { APIDocumentContainer } from '../../../api/documentContainer';
import { APIEmailBasic } from '../../../api/email';
import { placeholderTypes } from '../file-sorter/FileSorter';
import { FAIcon } from '../../common/FAIcon';

import './shipment.scss';
import { RecordValidator } from '../../common/Form';
import { OrganisationText } from './organisation-text/OrganisationText';
import { checkIfRecordValidatorHasErrors } from './ErrorHelper';

interface Props {
  data: APIDocumentGroupShipment;
  document: APIDocumentGroupDocument<APIDocumentGroupShipment> | undefined
  isNonCargowise: boolean;
  transModeOptions: OptionValue[];
  releaseTypeOptions: OptionValue[];
  packingModeOptions: OptionValue[];
  packageTypeOptions: OptionValue[];
  shipmentTypeOptions: OptionValue[];
  containerTypeOptions: OptionValue[];
  defaultPackingMode: string | undefined;
  reloadGroupData: (forceRefresh?: boolean) => void;
  issuerDetails: IssuerDetailMap;
  fieldsChanged: (changes: FieldChangedParams[]) => void;
  disabled: boolean;
  setActiveDocument: (document: APIDocumentContainer | APIEmailBasic | undefined, scroll?: boolean) => void;
  activeDocument?: APIDocumentContainer | APIEmailBasic | undefined;
  allowCloneAgents: boolean;
  shipmentsCount: number;
  cloneFieldValue: (senderRecordId: string | undefined, receiverFields: string[] | undefined, onlyInvoices: boolean, isOrganisation?: boolean, onlySameShipmentId?: number, includeConsol?: boolean) => void;
  disableHighlights?: boolean;
  checkRepeatedJobRef: (docId: number, value: string | undefined) => boolean;
  checkRepeatedBlNumber: (docId: number, value: string | undefined) => boolean;
  addToBlNums: (docId: number, value: string) => void;
  addToJobRefNums: (docId: number, value: string) => void;
  useOrganisations: boolean;
}

export const Shipment: React.FC<Props> = (props) => {
  const [transMode, setTransMode] = React.useState<OptionValue | null>(null);
  const [releaseType, setReleaseType] = React.useState<OptionValue | null>(null);
  const [shipmentType, setShipmentType] = React.useState<OptionValue | null>(null);
  const [packingMode, setPackingMode] = React.useState<OptionValue | null>(null);
  const [hblNo, setHblNo] = React.useState<string>('');
  const [goodsDesc, setGoodsDesc] = React.useState<string>('');
  const [jobRef, setJobRef] = React.useState<string>('');
  const [etd, setEtd] = React.useState<string | null>();
  const [eta, setEta] = React.useState<string | null>();
  const [shipperName, setShipperName] = React.useState<string>('');
  const [shipperAddress, setShipperAddress] = React.useState<string>('');
  const [consigneeName, setConsigneeName] = React.useState<string>('');
  const [consigneeAddress, setConsigneeAddress] = React.useState<string>('');
  const [notifyName, setNotifyName] = React.useState<string>('');
  const [notifyAddress, setNotifyAddress] = React.useState<string>('');
  const [sob, setSob] = React.useState<string | null>();

  // load document group into the shipment form
  React.useEffect(() => {
    if (props.data) {
      const d = props.data;
      setHblNo(d.billOfLadingNo || '');
      setGoodsDesc(d.goodsDescription || '');
      setEtd(d.originEtd ? d.originEtd : null);
      setEta(d.destinationEta ? d.destinationEta : null);

      setConsigneeName(d.consigneeName || '');
      setConsigneeAddress(d.consigneeAddress || '');
      setShipperName(d.shipperName || '');
      setShipperAddress(d.shipperAddress || '');
      setNotifyName((d.notify ? d.notify[0]?.notifyPartyName : '') || '');
      setNotifyAddress((d.notify ? d.notify[0]?.notifyPartyAddress : '') || '');
      setSob(d.shippedOnBoardDate || null);

      const jobRef = d.importerReference?.find((ref) => !ref.isConsol)
      setJobRef(jobRef?.jobRef || '');

      if (!d.transportMode) update('transportMode', 'SEA');
      setTransMode(props.transModeOptions.find((options) => options.value === (d.transportMode || 'SEA')) || null);
      if (!d.shipmentType) update('shipmentType', 'STD');
      setShipmentType(props.shipmentTypeOptions.find((options) => options.value === (d.shipmentType || 'STD')) || null);
      if (!d.containerMode && props.defaultPackingMode) update('containerMode', props.defaultPackingMode);
      setPackingMode(props.packingModeOptions.find((options) => options.value === (d.containerMode || props.defaultPackingMode)) || null);

      setReleaseType(props.releaseTypeOptions.find((options) => options.value === d.releaseType) || null);
    }
  }, [props.data]);

  React.useEffect(() => {
    props.addToBlNums(props.data?.documentId!, hblNo);
  }, [hblNo]);

  React.useEffect(() => {
    props.addToJobRefNums(props.data.documentId!, jobRef);
  }, [jobRef]);

  const recordValidator: RecordValidator = {
    blNumber: {
      validators: [
        {
          errorMessage: 'Duplicate bill of lading number',
          isValid: () => !props.checkRepeatedBlNumber(props.data?.documentId, hblNo)
        },
      ]
    },
    jobRef: {
      validators: [
        {
          errorMessage: 'Duplicate Reference',
          isValid: () => !props.checkRepeatedJobRef(props.data?.documentId, jobRef)
        },
      ]
    },
  };
  const recordValidatorHasErrors = checkIfRecordValidatorHasErrors(recordValidator);

  const update = async (name: string, value: string | number | null, withoutChange?: boolean) => {
    if (withoutChange) {
      return;
    }

    if (name === 'jobRef') {
      const updatedImporterReference =
        await BillOfLadingImporterReference.updateJobRef(value, false, props.data.id, props.data.importerReference) as APIBillOfLadingImporterReference;
      let allReferences;

      if (updatedImporterReference.id &&
        props.data?.importerReference &&
        props.data?.importerReference.length > 0) {
        allReferences = props.data.importerReference.map((ref) => {
          if (ref.id === updatedImporterReference.id) {
            return updatedImporterReference;
          } else {
            return ref;
          }
        });
      } else if (typeof updatedImporterReference == 'number' &&
        props.data.importerReference) {
        allReferences = props.data.importerReference.filter((ref) => ref.id !== updatedImporterReference);
      } else {
        allReferences = [updatedImporterReference];
      }
      props.fieldsChanged([{
        id: props.data.id,
        fieldName: name,
        jobType: JobType.Shipment,
        value: allReferences,
      }]);
      setJobRef(updatedImporterReference.jobRef);
    } if (name === 'notifyRecordId') {
      await BillOfLadingNotifyParty.updateNotifyParty(value, props.data.id, props.data.notify);
      props.reloadGroupData(true);
    } else if (['notifyPartyAddress', 'notifyPartyName'].includes(name)) {
      const notifyParty = props.data.notify ? props.data.notify[0] : null;
      if (notifyParty) {
        await BillOfLadingNotifyParty.update(notifyParty.id, { [name]: String(value) });
      } else if (value) {
        await BillOfLadingNotifyParty.create({
          billOfLadingId: props.data?.id,
          [name]: value,
        });
      }
      props.reloadGroupData(true);
    } else if (name !== 'jobRef') {
      await BillOfLadingAPI.update(props.data.id, { [name]: value });
      props.reloadGroupData();
    }

    if (['consigneeCgwRecordId', 'shipperCgwRecordId', 'shippingLineCgwRecordId', 'notifyRecordId'].includes(name)) {
      props.fieldsChanged([{
        id: props.data.documentId,
        fieldName: name,
        jobType: JobType.Shipment,
        recordId: value as string | null
      }]);
    } else if (['billOfLadingNo'].includes(name)) {
      props.fieldsChanged([{
        id: props.data?.documentId,
        fieldName: name,
        jobType: JobType.Consol,
        value: value as string | null
      }]);
    }
  }

  const onFocus = () => {
    if (props.document?.documentType && !placeholderTypes.includes(props.document?.documentType)) {
      props.setActiveDocument(props.document as unknown as APIDocumentContainer)
    }
  }

  let isChild = props.document?.children?.find((c) => c.id === props.activeDocument?.id);
  let isActiveDocument = (isChild) ? true : props.activeDocument?.id === props.document?.id;

  return (
    <CollapsibleContainer
      versionTwo
      title={props.isNonCargowise ? 'House Bill of Lading' : 'Shipment'}
      disableScroll
      disabled={props.disabled}
      isCollapsedByDefault={isActiveDocument ? false : true}
      id={`card-${props.data.documentId}`}
      className={`shipment ${isActiveDocument ? 'selected-card-div' : 'mt-25'} ${isChild ? 'no-border' : ''}`}
      preHeaderJsx={
        <div className='invoice_icons'>
          {recordValidatorHasErrors && <span className='fas fa-exclamation-triangle icon-error'></span>}
        </div>
      }
      visibleContent={(
        <div className="grid__row grid__row--with-separator">
          <div className="grid__col-4">
            <WrapperLabel text={props.isNonCargowise ? 'TMS Reference' : 'Job Reference'}>
              <TextInput value={jobRef} name="jobRef" onBlur={update} setter={setJobRef} disabled={props.disabled}
                onFocus={onFocus}
                formEntered={true}
                validators={recordValidator.jobRef.validators}
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-4">
            <WrapperLabel text="HBL Number">
              <TextInput value={hblNo} name="billOfLadingNo" onBlur={update} setter={setHblNo} disabled={props.disabled}
                onFocus={onFocus}
                fieldData={{
                  objectIds: props.disableHighlights ? [-1] : [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['bill_of_lading_number'],
                }}
                formEntered={true}
                validators={recordValidator.blNumber.validators}
              />
            </WrapperLabel>
          </div>
          <div className="grid__col-4">
          </div>
        </div>
      )}
    >
      <div className="grid__row grid__row--with-separator">
        {!props.useOrganisations
          ? (<>
            <OrganisationText
              label='shipper'
              fieldName='shipper'
              tableName='bol_header'
              name={shipperName}
              setName={setShipperName}
              address={shipperAddress}
              setAddress={setShipperAddress}
              update={update}
              disabled={props.disabled}
              id={[props.data?.id || -1]}
              onFocus={onFocus}
            />
            <OrganisationText
              label='consignee'
              fieldName='consignee'
              tableName='bol_header'
              name={consigneeName}
              setName={setConsigneeName}
              address={consigneeAddress}
              setAddress={setConsigneeAddress}
              update={update}
              disabled={props.disabled}
              id={[props.data?.id || -1]}
              onFocus={onFocus}
            />
            <OrganisationText
              label='notifyParty'
              fieldName='notifyParty'
              highlightName='notify'
              tableName='bol_header'
              name={notifyName}
              setName={setNotifyName}
              address={notifyAddress}
              setAddress={setNotifyAddress}
              update={update}
              disabled={props.disabled}
              id={[props.data?.id || -1]}
              onFocus={onFocus}
            />
          </>)
          :
          (<>
            <div className="grid__col-4">
              <CreditorSelect
                label="Shipper"
                recordId={props.data.shipperCgwRecordId || null}
                onValueChange={(option) => {
                  update('shipperCgwRecordId', option?.value || null);
                }}
                flags={{ shipper: true }}
                dataTestId="consignor-dropdown"
                showAddress={true}
                creditorDetailsMap={props.issuerDetails}
                disabled={props.disabled}
                companyCode={props.data?.emailAccount?.cwCompanyCode}
                onFocus={onFocus}
                cloneFieldValue={props.cloneFieldValue}
                allowCloneAgents={props.allowCloneAgents}
                cloneToFields={['shipperCgwRecordId']}
                fieldData={{
                  objectIds: props.disableHighlights ? [-1] : [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['shipper'],
                }}
              />
            </div>
            <div className="grid__col-4">
              <CreditorSelect
                label="Consignee"
                recordId={props.data.consigneeCgwRecordId || null}
                onValueChange={(option) => {
                  update('consigneeCgwRecordId', option?.value || null);
                }}
                flags={{ consignee: true }}
                dataTestId="consignee-dropdown"
                showAddress={true}
                creditorDetailsMap={props.issuerDetails}
                disabled={props.disabled}
                companyCode={props.data?.emailAccount?.cwCompanyCode}
                onFocus={onFocus}
                cloneFieldValue={props.cloneFieldValue}
                allowCloneAgents={props.allowCloneAgents}
                cloneToFields={['consigneeCgwRecordId']}
                fieldData={{
                  objectIds: props.disableHighlights ? [-1] : [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['consignee'],
                }}
              />
            </div>
            <div className="grid__col-4">
              <CreditorSelect
                label="Notify party"
                recordId={props.data.notify ? props.data.notify[0]?.notifyPartyRecordId : null}
                onValueChange={(option) => {
                  update('notifyRecordId', option?.value || null);
                }}
                dataTestId="notify-party-dropdown"
                showAddress={true}
                creditorDetailsMap={props.issuerDetails}
                disabled={props.disabled}
                companyCode={props.data?.emailAccount?.cwCompanyCode}
                dropdownMenuRightAlignment
                onFocus={onFocus}
                fieldData={{
                  objectIds: props.disableHighlights ? [-1] : [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['notify'],
                }}
              />
            </div>
          </>)
        }
      </div>
      <div className="grid__row">
        <div className="grid__col-4">
          <WrapperLabel text="Packing Mode" title={packingMode?.value}>
            <CustomSelect
              options={props.packingModeOptions}
              value={packingMode || undefined}
              onValueChange={(value) => {
                setPackingMode(value);
                update('containerMode', value?.value || null);
              }}
              renderValue={(item: OptionValue) => item.value}
              dropdownMenuSize={'wide'}
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: props.disableHighlights ? [-1] : [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['packing_mode'],
              }}
            />
          </WrapperLabel>
          {!props.isNonCargowise ? (
            <WrapperLabel text="Shipment Type" title={shipmentType?.value}>
              <CustomSelect
                options={props.shipmentTypeOptions}
                value={shipmentType || undefined}
                onValueChange={(value) => {
                  setShipmentType(value);
                  update('shipmentType', value?.value || null);
                }}
                disabled={props.disabled || (!!shipmentType && props.shipmentTypeOptions.length === 1)}
                onFocus={onFocus}
                fieldData={{
                  objectIds: props.disableHighlights ? [-1] : [props.data?.id || -1],
                  tableName: 'bol_header',
                  attributeNames: ['shipment_type'],
                }}
              />
            </WrapperLabel>
          ) : (
            <WrapperLabel text="">
            </WrapperLabel>
          )}
        </div>
        <div className="grid__col-4">
          <WrapperLabel text="Release Type">
            <CustomSelect
              options={props.releaseTypeOptions}
              value={releaseType || undefined}
              onValueChange={(value) => {
                setReleaseType(value);
                update('releaseType', value?.value || null);
              }}
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: props.disableHighlights ? [-1] : [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['release_type'],
              }}
            />
          </WrapperLabel>
        </div>
        <div className="grid__col-4">
          <WrapperLabel text="SOB Date"
            copyComponent={
              (props.cloneFieldValue && (
                <div className="clone-agents-on-wrapper">
                  <OverlayTrigger overlay={<Tooltip><span>Set as SOB in Consol and all shipments</span></Tooltip>} delayShow={500} placement="left">
                    <button
                      className="light-button clone-agents-button active-on-hover"
                      disabled={props.disabled || !sob}
                      onClick={() => props.cloneFieldValue ? props.cloneFieldValue(sob || undefined, ['shippedOnBoardDate'], false, false, undefined, true) : {}}
                    ><FAIcon name="clone" /></button>
                  </OverlayTrigger>
                </div>)
              )
            }>
            <DateSelectEditor
              initDate={props.data?.shippedOnBoardDate && moment(props.data.shippedOnBoardDate)}
              onDateChange={(value: moment.Moment | null) => {
                update('shippedOnBoardDate', value ? value.format('YYYY-MM-DD') : null);
                setSob(value ? value.format('YYYY-MM-DD') : null);
              }}
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['shipped_on_board_date'],
              }}
              dataTestId="sob-date-input"
            />
          </WrapperLabel>
          <WrapperLabel text="Trans Mode" title={transMode?.value}>
            <CustomSelect
              options={props.transModeOptions}
              value={transMode || undefined}
              onValueChange={(value) => {
                setTransMode(value);
                update('transportMode', value?.value || null);
              }}
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: props.disableHighlights ? [-1] : [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['transport_mode'],
              }}
            />
          </WrapperLabel>
        </div>
      </div>
      <div className="grid__row grid__row--with-separator">
        <div className="grid__col-4">
          <PortSelect
            label={props.isNonCargowise ? 'Load Port' : 'Origin'}
            value={props.data.origin}
            valueUnlocode={props.data.originUnlocode}
            onValueChange={(value, valueUnlocode) => {
              update('origin', value);
              update('originUnlocode', valueUnlocode);
            }}
            onValueUnlocodeChange={(valueUnlocode) => {
              update('originUnlocode', valueUnlocode);
            }}
            onlySeaPort={false}
            dataTestId="origin-port"
            disabled={props.disabled}
            onFocus={onFocus}
            cloneFieldValue={props.cloneFieldValue}
            cloneToField1={'origin'}
            cloneToField2={'originUnlocode'}
            fieldData={{
              objectIds: props.disableHighlights ? [-1] : [props.data?.id || -1],
              tableName: 'bol_header',
              attributeNames: ['origin'],
            }}
          />
        </div>
        <div className="grid__col-4">
          <PortSelect
            label={props.isNonCargowise ? 'Discharge Port' : 'Destination'}
            value={props.data.destination}
            valueUnlocode={props.data.destinationUnlocode}
            onValueChange={(value, valueUnlocode) => {
              update('destination', value);
              update('destinationUnlocode', valueUnlocode);
            }}
            onValueUnlocodeChange={(valueUnlocode) => {
              update('destinationUnlocode', valueUnlocode);
            }}
            onlySeaPort={false}
            dataTestId="destination-port"
            disabled={props.disabled}
            onFocus={onFocus}
            cloneFieldValue={props.cloneFieldValue}
            cloneToField1={'destination'}
            cloneToField2={'destinationUnlocode'}
            fieldData={{
              objectIds: props.disableHighlights ? [-1] : [props.data?.id || -1],
              tableName: 'bol_header',
              attributeNames: ['destination'],
            }}
          />
        </div>
        <div className="grid__col-4">
          <WrapperLabel text="Departure ETD"
            copyComponent={
              (props.cloneFieldValue && (
                <div className="clone-agents-on-wrapper">
                  <OverlayTrigger overlay={<Tooltip><span>Set as Departure ETD in all shipments</span></Tooltip>} delayShow={500} placement="left">
                    <button
                      className="light-button clone-agents-button active-on-hover"
                      disabled={props.disabled || !etd || props.shipmentsCount >= 1}
                      onClick={() => props.cloneFieldValue ? props.cloneFieldValue(etd || undefined, ['originEtd'], false, false) : {}}
                    ><FAIcon name="clone" /></button>
                  </OverlayTrigger>
                </div>)
              )
            }>
            <DateSelectEditor
              initDate={props.data.originEtd && moment(props.data.originEtd)}
              onDateChange={(value: moment.Moment | null) => {
                update('originEtd', value ? value.format('YYYY-MM-DD') : null);
                setEtd(value ? value.format('YYYY-MM-DD') : null);
              }}
              disabled={props.disabled}
              onFocus={onFocus}
            />
          </WrapperLabel>
          <WrapperLabel text="Arrival ETA"
            copyComponent={
              (props.cloneFieldValue && (
                <div className="clone-agents-on-wrapper">
                  <OverlayTrigger overlay={<Tooltip><span>Set as Arrival ETA in all shipments</span></Tooltip>} delayShow={500} placement="left">
                    <button
                      className="light-button clone-agents-button active-on-hover"
                      disabled={props.disabled || !eta || props.shipmentsCount >= 1}
                      onClick={() => props.cloneFieldValue ? props.cloneFieldValue(eta || undefined, ['destinationEta'], false, false) : {}}
                    ><FAIcon name="clone" /></button>
                  </OverlayTrigger>
                </div>)
              )
            }>
            <DateSelectEditor
              initDate={props.data.destinationEta && moment(props.data.destinationEta)}
              onDateChange={(value: moment.Moment | null) => {
                update('destinationEta', value ? value.format('YYYY-MM-DD') : null);
                setEta(value ? value.format('YYYY-MM-DD') : null);
              }}
              disabled={props.disabled}
              onFocus={onFocus}
            />
          </WrapperLabel>
        </div>
      </div>
      {props.isNonCargowise && (
        <div className="grid__row">
          <ContainersList
            containers={props.data.container}
            reloadGroupData={props.reloadGroupData}
            disabled={props.disabled}
            containerTypeOptions={props.containerTypeOptions}
            billOfLadingId={props.data.id}
            onFocus={onFocus}
          />
        </div>
      )}
      <div className="grid__row">
        <div className="grid__col-4">
          <WrapperLabel text="Goods Description">
            <TextInput
              name="goodsDescription"
              value={goodsDesc}
              onChange={setGoodsDesc}
              onBlur={update}
              disabled={props.disabled}
              onFocus={onFocus}
              fieldData={{
                objectIds: props.disableHighlights ? [-1] : [props.data?.id || -1],
                tableName: 'bol_header',
                attributeNames: ['goods_description'],
              }}
            />
          </WrapperLabel>
        </div>
        <div className="grid__col-4">
        </div>
        <div className="grid__col-4">
        </div>
      </div>
      <div className="grid__row">
        <PacklinesList
          packLines={props.data.packLines}
          reloadGroupData={props.reloadGroupData}
          disabled={props.disabled}
          packageTypeOptions={props.packageTypeOptions}
          billOfLadingId={props.data.id}
          onFocus={onFocus}
        />
      </div>
      <>{props.children}</>
    </CollapsibleContainer >
  );
}
