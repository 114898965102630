import { request } from './request';
import { APICommercialInvoiceLineItem } from './documentGroup';

export type Filter = { [k: string]: string | string[] | number | number[] | boolean | null };

export interface APICargowiseReferenceProductData {
  id: number;
  productCode: string;
  owner: string;
  supplier: string;
  description: string;
  unitType: string;
  origin: string;
  hsCode: string;
  tariffLookup: string;
}

export const CommercialInvoiceLineItemAPI = {
  async update(id: number, lineItem: Partial<APICommercialInvoiceLineItem>, aggregated: boolean): Promise<APICommercialInvoiceLineItem> {
    return request(`/CommercialInvoiceLine${aggregated ? 'ItemAggregateds' : 'Items'}/${id}`, 'PATCH', lineItem);
  },

  async bulkUpdate(ids: number[], invoiceId: number, change: Partial<APICommercialInvoiceLineItem>, aggregated: boolean): Promise<unknown> {
    return request(`/CommercialInvoiceLine${aggregated ? 'ItemAggregateds' : 'Items'}/bulkUpdate`, 'PATCH', {
      ids,
      invoiceId,
      change
    });
  },

  async create(lineItem: Partial<APICommercialInvoiceLineItem>, aggregated: boolean): Promise<APICommercialInvoiceLineItem> {
    return request(`/CommercialInvoiceLine${aggregated ? 'ItemAggregateds' : 'Items'}`, 'POST', lineItem)
  },

  async remove(id: number, aggregated: boolean): Promise<unknown> {
    return request(`/CommercialInvoiceLine${aggregated ? 'ItemAggregateds' : 'Items'}/${id}`, 'DELETE');
  },

  async removeAllLinesFromCinv(invoiceId: number): Promise<unknown> {
    return request(`/CommercialInvoices/${invoiceId}/removeAllLineItems`, 'DELETE');
  },

  async aggregateLinesItems(invoiceId: number): Promise<APICommercialInvoiceLineItem[]> {
    return request(`/CommercialInvoices/${invoiceId}/aggregateLineItems`, 'POST');
  },

  async removeLineItemsFromAggregation(invoiceId: number): Promise<APICommercialInvoiceLineItem[]> {
    return request(`/CommercialInvoices/${invoiceId}/removeLineItemsAggregation`, 'POST');
  },

  async triggerLineItemsMatcher(invoiceId: number): Promise<APICommercialInvoiceLineItem[]> {
    return request(`/CommercialInvoices/${invoiceId}/triggerLineItemsMatcher`, 'POST');
  },

  async searchProducts(searchByProperty: string, searchTerm: string, supplierCgwCode: string | null, importerCgwCode: string | null, exactMatch?: boolean): Promise<APICargowiseReferenceProductData[]> {
    let searchBy = '';
    if (searchByProperty === 'productCode') searchBy = 'product_code';
    else if (searchByProperty === 'hsCode') searchBy = 'tariff';
    else searchBy = 'description';

    return await request(`/CargowiseReferenceDataProducts/search`, 'POST', {
      searchBy,
      searchTerm: `${searchTerm}`,
      supplierCgwCode,
      importerCgwCode,
      exactMatch,
      companyId: parseInt(localStorage.getItem('companyId') || '')
    });
  },
}
