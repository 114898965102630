import * as React from 'react';
import { Notification } from '../../../common/Notification';
import { ContainerDataImportTable } from './ContainerDataImportTable';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ContainerDataImportForm } from './ContainerDataImportForm';
import { AdminLinks } from '../../Admin';

import './index.scss';

interface Props {
  setNotification: (notification: Notification | null) => void;
}

export const ContainerDataSection: React.FC<Props> = (props) => {
  // TODO: Improve if we ever figure this out.
  // setFileErrors required due to set state not working in ContainerDataImportForm
  const [errors, setErrors] = React.useState<string[]>([]);
  const history = useHistory();
  history.listen(() => {
    setErrors([]);
  });

  return (
    <Switch>
      <Route path={AdminLinks.ContainerDataImports} exact component={() => (
        <ContainerDataImportTable />
      )} />
      <Route path={[AdminLinks.ContainerDataImports + '/form']} exact component={() => (
        <ContainerDataImportForm setNotification={props.setNotification} errors={errors} setErrors={setErrors} />
      )} />
    </Switch>
  );
};
