import * as React from 'react';

import './skeleton-input.scss';

interface Props {
  displayAsSkeleton: boolean;
  className?: string;
  disabled: boolean
}

// This component simply controls applying the 'skeleton-input' CSS class.
// If applied the child component should be displayed as a skeleton of what it actually is.
// Examples are the 'Add a Seal no.' or 'Add Packline' button on the BL Consol adn Shipment forms
//
// ...kind of experimental :)
export const SkeletonInput: React.FC<Props> = (props) => {
  return (
    <div className={`${props.displayAsSkeleton ? 'skeleton-input' : ''} ${props.className || ''} ${props.disabled ? 'skeleton-input--disabled' : ''}`}>
      {props.children}
    </div>
  );
}
