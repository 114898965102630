import * as React from 'react'
import { APIDocCoordinate, FieldData, findMatchedCoordinates } from '../../api/documentContainer';
import { DocumentViewerContext } from '../DocumentViewerContext';
import { hasOverlap } from '../helpers';
import { FieldValidator } from './Form';

import './text-input.scss';

interface Props {
  value: string | null;
  name: string;
  placeholder?: string;
  className?: string;
  wrapperClassName?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  onChange?: (value: string, name: string) => void;
  onBlur?: (name: string, value: string | null, withoutChange: boolean) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  setter?: (value: string) => void;
  type?: string;
  disableAutocomplete?: boolean;
  maxLength?: number;
  fieldData?: FieldData;
  enableMultiline?: boolean;
  autoFocus?: boolean;
  autoSelect?: boolean;
  required?: boolean;
  showErrorIcon?: boolean;
  suffix?: string;
  validators?: FieldValidator[];
  formEntered?: boolean;
  dataTestId?: string;
}

export const TextInput = React.forwardRef((props: Props, ref: any) => {
  const [value, setValue] = React.useState<string | null>();
  let inputRef: HTMLInputElement | HTMLTextAreaElement | null;
  const [isChanged, setIsChanged] = React.useState<boolean>(false);
  const documentViewerContext = React.useContext(DocumentViewerContext);
  const [selected, setIsSelected] = React.useState<boolean>(false);

  const matchedCoordinates = findMatchedCoordinates(props.fieldData || null, documentViewerContext.coordinates) || null;

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  React.useEffect(() => {
    if (props.fieldData) {
      if (hasOverlap<APIDocCoordinate>(matchedCoordinates, documentViewerContext.focusedCoordinates)) {
        if (inputRef) {
          inputRef.focus();
          inputRef.selectionStart = inputRef.value.length;
          inputRef.selectionEnd = inputRef.value.length;
        }
      }
    }
  }, [documentViewerContext.focusedCoordinates]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.disabled) {
      return;
    }

    setIsChanged(true);
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value, props.name);
    }

    if (props.setter) {
      props.setter(event.target.value);
    }
  }

  const onBlur = () => {
    const trimmedValue = value?.trim() || '';
    const shouldTrim = value !== trimmedValue;

    if (shouldTrim) {
      setValue(trimmedValue);
      props.onChange && props.onChange(trimmedValue, props.name)
    }
    props.onBlur && props.onBlur(props.name, trimmedValue, !(isChanged || shouldTrim));
    documentViewerContext.setFocusedCoordinates([]);
    (props.fieldData && isChanged) && documentViewerContext.removeCoordinates({
      parentTableName: props.fieldData.tableName,
      parentTableIds: props.fieldData.objectIds,
      attributeNames: props.fieldData.attributeNames
    });

    setIsChanged(false);
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.onKeyDown) props.onKeyDown(event);
  }

  React.useImperativeHandle(ref, () => ({
    focus() {
      if (inputRef) {
        inputRef.focus();
        inputRef.selectionStart = inputRef.value.length;
        inputRef.selectionEnd = inputRef.value.length;
      }
    }
  }));

  const onFocus = () => {
    props.onFocus && props.onFocus();
    matchedCoordinates && documentViewerContext.setFocusedCoordinates(matchedCoordinates);
  }

  const setRef = (input: HTMLInputElement | HTMLTextAreaElement | null) => {
    if (input) {
      if (props.autoSelect) autoSelect(input);
      inputRef = input;
    }
  }

  const autoSelect = (input: HTMLInputElement | HTMLTextAreaElement | null) => {
    if (!selected && input?.value) {
      if (input.value.length > 1) input.select();
      setIsSelected(true);
    }
  }

  const triggeredValidator = props.formEntered ? props.validators?.find((validator) => {
    if (!validator.isValid(props.value)) {
      return true;
    }
  }) : null;

  const containerClassNames = [
    'text-input__container',
    props.showErrorIcon ? 'text-input__container--with-error' : '',
    (props.required && props.fieldData?.objectIds.find((id) => id !== -1) && (value === null || value === '') || triggeredValidator) ? 'text-input__container--highlight' : '',
    props.disabled ? 'text-input__container--disabled' : '',
    props.enableMultiline ? 'text-input__container--multiline' : '',
  ];

  const classNames = [
    'text-input',
    props.className ? props.className : '',
    props.disabled ? 'text-input--disabled' : '',
  ];

  const component = props.enableMultiline ? (
      <textarea
        rows={3}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onClick={props.onClick}
        placeholder={props.placeholder}
        value={props.value || ''}
        onBlur={onBlur}
        onChange={onChange}
        ref={setRef}
        className={classNames.join(' ')}
        autoComplete={props.disableAutocomplete ? 'new-password' : ''}
        maxLength={props.maxLength}
        autoFocus={props.autoFocus}
        data-test-id={props.dataTestId}
      >{props.value}</textarea>
    ) : (
      <>
        <input
          type={props.type || 'text'}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onClick={props.onClick}
          placeholder={props.placeholder}
          value={value || ''}
          onBlur={onBlur}
          onChange={onChange}
          ref={setRef}
          className={classNames.join(' ')}
          autoComplete={props.disableAutocomplete ? 'new-password' : ''}
          maxLength={props.maxLength}
          autoFocus={props.autoFocus}
          title={value || ''}
          data-test-id={props.dataTestId}
        />
        {props.suffix && (
          <span className="text-input__suffix">{props.suffix}</span>
        )}
      </>
  );

  return (
    <div className={`text-input__wrapper ${props.wrapperClassName}`}>
      <div className={containerClassNames.join(' ')}>
        {component}
      </div>
      {triggeredValidator?.errorMessage && (
        <div className="form-field__error">
          {triggeredValidator.errorMessage}
        </div>
      )}
    </div>
  );
})
