import { APIClusterAccrual, ClusterAccrualAPI } from '../../../api/clusterAccrual';
import { Cluster, ClusterStatus } from '../../../api/jobRefCluster';
import * as React from 'react';
import { TotalsInput } from '../../common/TotalsInput';
import { APIExchangeRate } from '../../../api/supplierInvoice';
import { Exception } from '../../../api/validationResults';
import { AccrualChanges, formatCostNumber } from './AccrualsRow';
import { DocumentActionModal } from '../../common/DocumentActionModal';
import { APIDocCoordinate, APIDocumentContainer } from '../../../api/documentContainer';
import { roundNumber } from '../../helpers';
import { APIPermissionProfileToleranceThreshold, TOLERANCE_WITHOUT_THRESHOLD } from '../../../api/permissionProfiles';
import { aggregateRefsByConsol, getClusterStatus } from './helpers';
import { JobRefTolerancesWrapper } from './JobRefTolerancesWrapper';
import { APICgwCurrencyExchangeRates } from '../../../api/currency';
import { DiscrepancyType } from '../../../api/automatedTolerances';
import { APIEmailAccount } from '../../../api/emailAccount';

const getToleranceExceededErrorMessage = (toleranceThreshold: APIPermissionProfileToleranceThreshold | undefined,
  invoiceCurrencyCode: string, useSeparateLimit: boolean, discrepancyType: DiscrepancyType): string => {
  if (!toleranceThreshold) return '';

  const upperLimit: (string | undefined)[] = [];
  const lowerLimit: (string | undefined)[] = [];

  if (toleranceThreshold.upperAbsoluteMax !== TOLERANCE_WITHOUT_THRESHOLD) {
    upperLimit.push(`${toleranceThreshold.upperAbsoluteMax} ${invoiceCurrencyCode}`);
  }

  if (toleranceThreshold.upperPercentageMax !== TOLERANCE_WITHOUT_THRESHOLD) {
    upperLimit.push(`${toleranceThreshold.upperPercentageMax}%`)
  }

  if (toleranceThreshold.lowerAbsoluteMax !== TOLERANCE_WITHOUT_THRESHOLD) {
    lowerLimit.push(`${toleranceThreshold.lowerAbsoluteMax} ${invoiceCurrencyCode}`);
  }

  if (toleranceThreshold.lowerPercentageMax !== TOLERANCE_WITHOUT_THRESHOLD) {
    lowerLimit.push(`${toleranceThreshold.lowerPercentageMax}%`)
  }

  let limit: string = `${upperLimit.join(' or ')}`;
  if (useSeparateLimit) {
    limit = `upper limit ${upperLimit.join(' or ')} OR lower limit ${lowerLimit.join(' or ')}`;
  }
  let message = `Updated costs exceed the threshold of ${limit} per Job`;
  if (discrepancyType === DiscrepancyType.ExchangeRateDiscrepancy) {
    message = `Updated local costs exceed the threshold of ${limit} per Job`
  }
  return message;
}

const getExchangeRoundingToleranceMessage = (tolerance: number, invoiceCurrencyCode: string): string => {
  return `Costs within your defined FX rounding tolerance of ${formatCostNumber(tolerance)} ${invoiceCurrencyCode}`
}

const sameAccruals = (accrual1: APIClusterAccrual, accrual2: APIClusterAccrual): boolean => {
  return accrual1.id !== accrual2.id &&
    accrual1.originalOsCostAmount === accrual2.originalOsCostAmount &&
    accrual1.originalLocalCostAmount === accrual2.originalLocalCostAmount &&
    accrual1.originalVatAmount === accrual2.originalVatAmount &&
    accrual1.osCurrencyCode === accrual2.osCurrencyCode &&
    accrual1.chargeCode === accrual2.chargeCode &&
    accrual1.consolRef === accrual2.consolRef &&
    accrual1.shipmentRef === accrual2.shipmentRef &&
    accrual1.costId === accrual2.costId
}

export const getSplitPrimaryTwinAccrual = (accruals: APIClusterAccrual[], originalAccrual: APIClusterAccrual): APIClusterAccrual | undefined => {
  return accruals.find((accrual) => accrual.isSplit && accrual.selected && sameAccruals(accrual, originalAccrual));
}

export const isAccrualSelectedInAnyOtherCluster = (accruals: APIClusterAccrual[], originalAccrual: APIClusterAccrual): boolean => {
  return !!accruals.find((accrual) => sameAccruals(accrual, originalAccrual) && accrual.selected && accrual.clusterId !== originalAccrual.clusterId);
}

export interface UpdatedAccrual extends APIClusterAccrual {
  isUpdated?: boolean;
}

export interface UpdatedAccrualsMap {
  [id: number]: UpdatedAccrual | undefined;
}

export enum AccrualsFormMode {
  ReadOnly = 'read-only',
  Tolerances = 'tolerances'
}

interface Props {
  clusterAccruals: APIClusterAccrual[];
  invoiceAccruals: APIClusterAccrual[];
  setInvoiceAccruals: (accruals: APIClusterAccrual[]) => void;
  disabled: boolean;
  cluster: Cluster;
  invoiceCurrencyCode: string;
  mailboxCurrencyCode: string;
  invoiceNetTotal: number;
  exceptions: Exception[];
  cargowiseExchangeRates: APICgwCurrencyExchangeRates[];
  toleranceThreshold?: APIPermissionProfileToleranceThreshold;
  useSeparateLimit: boolean;
  exchangeRates: APIExchangeRate[];
  document: APIDocumentContainer | undefined;
  exchangeTolerance: number;
  accrualsFormMode: AccrualsFormMode;
  emailAccount?: APIEmailAccount;
  onClose: () => void;
  onClusterChanged: (clusterId: number, change: Partial<Cluster>, ignoreAccrualsReset?: boolean) => void;
}

export const AccrualsForm: React.FC<Props> = (props) => {
  const [invoiceAccrualsMap, setInvoiceAccrualsMap] = React.useState<UpdatedAccrualsMap>({});
  const [accruedCostDifference, setAccruedCostDifference] = React.useState<number>(0);

  const scrollableContainerRef = React.useRef(null);

  React.useEffect(() => {
    initialiseUpdatedAccrualsMap();
  }, [props.invoiceAccruals]);

  const initialiseUpdatedAccrualsMap = () => {
    setInvoiceAccrualsMap((props.invoiceAccruals.reduce((map, accrual) => {
      return {
        ...map,
        [accrual.id]: accrual,
      }
    }, {} as UpdatedAccrualsMap)));
  }

  const onSave = async () => {
    const invoiceAccruals = Object.values(invoiceAccrualsMap);
    const newAccruals = invoiceAccruals.map((accrual) => {
      if (accrual.isUpdated) return { ...accrual };

      const splitPrimaryTwinAccrual = getSplitPrimaryTwinAccrual(invoiceAccruals, accrual);
      if (splitPrimaryTwinAccrual) {
        return {
          ...accrual,
          isSplit: true,
          osCostAmount: splitPrimaryTwinAccrual.osCostAmount,
          localCostAmount: splitPrimaryTwinAccrual.localCostAmount,
          vatAmount: splitPrimaryTwinAccrual.vatAmount,
          localVatAmount: splitPrimaryTwinAccrual.localVatAmount,
        }
      } else {
        return { ...accrual };
      }
    });

    if (accruedCostDifference && newAccruals.length) {
      const accrualInLocalCurrency = newAccruals.find((accrual) => (accrual.osCurrencyCode !== props.invoiceCurrencyCode && accrual.selected === true));

      if (accrualInLocalCurrency) {
        accrualInLocalCurrency.localCostAmount = roundNumber(accrualInLocalCurrency.localCostAmount - accruedCostDifference, 2);
        accrualInLocalCurrency.fxRoundingApplied = roundNumber((accrualInLocalCurrency.fxRoundingApplied || 0) + accruedCostDifference, 2);
      }
    }

    await ClusterAccrualAPI.saveAccruals(newAccruals, props.invoiceAccruals);
    props.setInvoiceAccruals(newAccruals);
    props.onClose();
  }

  const onDiscard = () => {
    initialiseUpdatedAccrualsMap();
    props.onClose();
  }

  const updateAccruals = (changes: AccrualChanges) => {
    if (!Object.keys(changes).length) return;

    setInvoiceAccrualsMap((currentValue) => {
      const currentValueCopy = { ...currentValue };

      Object.keys(changes).forEach((key) => {
        const accrualId = parseInt(key);
        const accrualChange = changes[accrualId];

        if (!accrualChange) {
          delete currentValueCopy[accrualId];
        } else {
          // @ts-ignore
          currentValueCopy[accrualId] = { ...currentValueCopy[accrualId], ...accrualChange };
        }
      });

      return currentValueCopy
    });
  }

  const { clusterStatus, usingExchangeRoundingTolerance, exceedsToleranceThreshold, exceedsExchangeRateThreshold, accrualsNetTotal, costDifference, netTotal } = getClusterStatus({
    exceptions: props.exceptions,
    cluster: props.cluster,
    invoiceAccruals: Object.values(invoiceAccrualsMap),
    invoiceCurrencyCode: props.invoiceCurrencyCode,
    invoiceNetTotal: props.invoiceNetTotal,
    toleranceThreshold: props.toleranceThreshold,
    exchangeTolerance: props.exchangeTolerance,
    emailAccount: props.emailAccount,
  });

  if (costDifference !== accruedCostDifference) {
    setAccruedCostDifference(costDifference);
  }

  const filterClusterCoordinates = React.useCallback((coordinate: APIDocCoordinate): boolean => {
    if (coordinate.parentTableName === 'supplier_invoice_job_ref_cluster') {
      if (coordinate.parentTableId === props.cluster.id) return true;
    } else if (coordinate.parentTableName === 'supplier_invoice_job_ref') {
      const jobRefIds: number[] = [];
      props.cluster.aggregatedTMSRefs.forEach((aggregatedTMSRef) => {
        aggregatedTMSRef.aggregatedDocumentRefs.forEach((aggregatedDocumentRef) => {
          aggregatedDocumentRef.refs.forEach((ref) => {
            if (ref.id) jobRefIds.push(ref.id);
          });
        });
      });

      if (jobRefIds.includes(coordinate.parentTableId)) return true;
    }

    return false;
  }, []);

  const aggregatedTMSRefsByConsol = aggregateRefsByConsol(props.cluster.aggregatedTMSRefs, props.clusterAccruals);

  return (
    <DocumentActionModal
      activeDocument={props.document}
      confirmButtonText="Save"
      disabledConfirmRules={clusterStatus !== ClusterStatus.Matched}
      setNotification={() => { }}
      onConfirmAction={onSave}
      onDiscardAction={onDiscard}
      title="Select matching accruals and make necessary changes"
      description={<p>Accruals can be split in two if the full amount is not charged on this invoice. <br />The accrual amount can be adjusted within a predefined tolerance.</p>}
      filterCoordinates={filterClusterCoordinates}
      hideOverlay={false}
    >
      <div className="accruals-form">
        <div
          className="accruals-form__content with-custom-scrollbar"
          style={props.clusterAccruals.length < 5 ? { overflowY: 'unset' } : {}}
          ref={scrollableContainerRef}
        >
          <div>
            {aggregatedTMSRefsByConsol
              .filter((aggregatedTMSRef) => !!aggregatedTMSRef.jobRef)
              .map((aggregatedTMSRef, i) => (
                <JobRefTolerancesWrapper
                  key={i}
                  aggregatedTMSRef={aggregatedTMSRef}
                  clusterId={props.cluster.id}
                  accrualsMap={invoiceAccrualsMap}
                  updateAccruals={updateAccruals}
                  invoiceCurrencyCode={props.invoiceCurrencyCode}
                  accrualsFormMode={props.accrualsFormMode}
                  exchangeRates={props.exchangeRates}
                  cargowiseExchangeRates={props.cargowiseExchangeRates}
                  toleranceThreshold={props.toleranceThreshold}
                  localCurrency={props.mailboxCurrencyCode}
                  scrollableContainerRef={scrollableContainerRef}
                  invoiceAccruals={props.invoiceAccruals}
                />
              ))}
          </div>
        </div>

        <div className="accruals-form__footer">
          <div className="text-right">
            <div className="flex items-baseline">
              <div className="flex-1">
                {exceedsToleranceThreshold && (
                  <span className="tolerances__error-message">
                    {getToleranceExceededErrorMessage(props.toleranceThreshold, props.invoiceCurrencyCode, props.useSeparateLimit, DiscrepancyType.AccrualDiscrepancy)}
                  </span>
                )}
                {exceedsExchangeRateThreshold && (
                  <span className="tolerances__error-message">
                    {getToleranceExceededErrorMessage(
                      {
                        upperAbsoluteMax: props.emailAccount?.xrUpperAbsoluteMax || 0,
                        lowerAbsoluteMax: props.emailAccount?.xrLowerAbsoluteMax || 0,
                        upperPercentageMax: props.emailAccount?.xrUpperPercentageMax || 0,
                        lowerPercentageMax: props.emailAccount?.xrLowerPercentageMax || 0,
                      },
                      props.invoiceCurrencyCode,
                      props.useSeparateLimit,
                      DiscrepancyType.ExchangeRateDiscrepancy)}
                  </span>
                )}
                {usingExchangeRoundingTolerance && (
                  <span className="tolerances__error-message">
                    {getExchangeRoundingToleranceMessage(props.exchangeTolerance, props.invoiceCurrencyCode)}
                  </span>
                )}
              </div>
              <TotalsInput
                value={accrualsNetTotal}
                disabled={true}
                label="SELECTED TOTAL"
                status={clusterStatus}
                readOnly
                showIcon={(clusterStatus === ClusterStatus.Failed) || usingExchangeRoundingTolerance}
                testId="accruals-total"
              />
            </div>
            <TotalsInput
              value={netTotal}
              disabled={true}
              label="NET TOTAL"
              readOnly
            />
          </div>
        </div>
      </div>
    </DocumentActionModal>
  )
}
