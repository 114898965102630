import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { KpiTargetForm } from './KpiTargetForm';
import { KpiTargetsTable } from './KpiTargetsTable';

interface Props {

}

export const KpiTargetsSection: React.FC<Props> = () => {
  return (
    <Switch>
      <Route path={AdminLinks.KpiTargets} exact component={() => (
        <KpiTargetsTable />
      )}/>
      <Route path={[AdminLinks.KpiTargets + '/form', AdminLinks.KpiTargets + '/form/:id']} exact component={() => (
        <KpiTargetForm />
      )} />
    </Switch>
  )
};
