import { DateSelectEditor } from '../common/DateSelectEditor';
import * as React from 'react';
import { OptionValue } from 'react-selectize';
import * as moment from 'moment';
import { NumberInput } from '../common/NumberInput';
import { DurationInputArg2 } from 'moment';
import { DropdownList } from '../common/DropdownList';
import { CustomSelect } from '../common/CustomSelect';
import { SectionModule } from './common';

export enum DateOriginFilterType {
  Received = 1,
  Posted = 2,
}

export enum DateFilterType {
  After = 1,
  Before = 2,
  Between = 3,
  InTheLast = 4
}

export const dropdownDateOptions: OptionValue[] = [
  {
    value: DateFilterType.After,
    label: 'after'
  },
  {
    value: DateFilterType.Before,
    label: 'before'
  },
  {
    value: DateFilterType.Between,
    label: 'between'
  },
  {
    value: DateFilterType.InTheLast,
    label: 'in the last'
  },
];

export const dropdownDateOriginTypeOptions = {
  [SectionModule.APInvoice]: [
    {
      value: DateOriginFilterType.Received,
      label: 'received'
    },
  ],
  [SectionModule.ForwardAndClearance]: [
    {
      value: DateOriginFilterType.Received,
      label: 'received'
    },
    {
      value: DateOriginFilterType.Posted,
      label: 'posted'
    },
  ],
};

export enum DateUnit {
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4
}

export const dateUnitOptions: OptionValue[] = [
  {
    value: DateUnit.Day,
    label: 'days'
  },
  {
    value: DateUnit.Week,
    label: 'weeks'
  },
  {
    value: DateUnit.Month,
    label: 'months'
  },
  {
    value: DateUnit.Year,
    label: 'years'
  },
];

const dateUnitCountLimits = {
  [DateUnit.Day]: {
    unitsInYear: 366,
    label: 'days',
  },
  [DateUnit.Week]: {
    unitsInYear: 52,
    label: 'weeks',
  },
  [DateUnit.Month]: {
    unitsInYear: 12,
    label: 'months',
  },
  [DateUnit.Year]: {
    unitsInYear: 1,
    label: 'years',
  },
}

const getDateUnitCountError = (value: number | null, dateUnit: DateUnit): string | undefined => {
  const max = dateUnitCountLimits[dateUnit].unitsInYear * 3
  const label = dateUnitCountLimits[dateUnit].label;

  if (value === null || isNaN(value)) {
    return `Please provide a value between 1 and ${max} ${label}`;
  }

  if (value === 0) {
    return 'Value needs to be at least 1';
  }

  if (value > max) {
    return `The maximum allowed value is ${max} ${label}`;
  }

  return;
}

export type DateRange = [string | null, string | null];

export const getDateRange = (dateFilter: DateFilter): undefined | DateRange => {
  if (dateFilter.type === DateFilterType.Between) {
    if (!dateFilter.firstDate || !dateFilter.secondDate) {
      return undefined;
    }

    return [dateFilter.firstDate, dateFilter.secondDate];
  }

  if (dateFilter.type === DateFilterType.InTheLast) {
    if (dateFilter.dateUnitsCount === null) {
      return;
    }

    const unitOption = dateUnitOptions.find((dateUnit) => dateUnit.value === dateFilter.dateUnit);
    return [moment().subtract(dateFilter.dateUnitsCount, unitOption?.label as DurationInputArg2).format('YYYY-MM-DD'), null];
  }

  if (dateFilter.type === DateFilterType.After) {
    if (!dateFilter.firstDate) {
      return undefined;
    }

    return [dateFilter.firstDate, null];
  }

  if (dateFilter.type === DateFilterType.Before) {
    if (!dateFilter.firstDate) {
      return undefined;
    }

    return [null, dateFilter.firstDate];
  }

  return undefined;
}

export interface DateFilter {
  dateOriginType: DateOriginFilterType;
  type: DateFilterType;
  firstDate: string | null;
  secondDate: string | null;
  dateUnit: DateUnit;
  dateUnitsCount: number | null;
}

interface Props {
  section: SectionModule;
  dateFilter: DateFilter;
  setDateFilter: (dateFilter: DateFilter) => void;
}

export const DateFilterWrapper: React.FC<Props> = (props) => {
  const [dateUnitsCount, setDateUnitsCount] = React.useState<number | null>(props.dateFilter.dateUnitsCount);

  React.useEffect(() => {
    setDateUnitsCount(props.dateFilter.dateUnitsCount)
  }, [props.dateFilter.dateUnitsCount]);

  const dateUnitCountError = getDateUnitCountError(dateUnitsCount, props.dateFilter.dateUnit);

  return (
    <>
      <label>which were</label>
      <div>
        <CustomSelect
          options={dropdownDateOriginTypeOptions[props.section]}
          value={dropdownDateOriginTypeOptions[props.section].find((option) => option.value === props.dateFilter.dateOriginType) || dropdownDateOriginTypeOptions[props.section][0]}
          onValueChange={(option) => option && props.setDateFilter({ ...props.dateFilter, dateOriginType: option.value as DateOriginFilterType })}
        />
        <CustomSelect
          options={dropdownDateOptions}
          value={dropdownDateOptions.find((option) => option.value === props.dateFilter.type) || dropdownDateOriginTypeOptions[props.section][0]}
          onValueChange={(option) => option && props.setDateFilter({ ...props.dateFilter, type: option.value as DateFilterType })}
        />
      </div>
      {props.dateFilter.type === DateFilterType.InTheLast ? (
        <>
          <div>
            <NumberInput
              value={dateUnitsCount}
              name=""
              onChange={(value) => setDateUnitsCount(value)}
              onBlur={(name, value) => {
                if (!getDateUnitCountError(value, props.dateFilter.dateUnit) && value) {
                  props.setDateFilter({ ...props.dateFilter, dateUnitsCount: value })
                }
              }}
              precision={0}
              hasErrors={!!dateUnitCountError}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (['Enter'].includes(event.key)) (event.target as HTMLElement).blur();
              }}
            />

            <CustomSelect
              options={dateUnitOptions}
              value={dateUnitOptions.find((option) => option.value === props.dateFilter.dateUnit)}
              onValueChange={(option) => {
                if (option) {
                  props.setDateFilter({
                    ...props.dateFilter,
                    dateUnit: option.value as DateUnit,
                    dateUnitsCount: getDateUnitCountError(dateUnitsCount, option.value as DateUnit) ? null : dateUnitsCount,
                  })
                }
              }}
            />

          </div>

          {dateUnitCountError ? <span className="date-filter__error-message">{dateUnitCountError}</span> : ''}
        </>
      ) : (
        <div>
          <DateSelectEditor
            placeholderText='Date'
            initDate={props.dateFilter.firstDate}
            onDateChange={(value) => props.setDateFilter({ ...props.dateFilter, firstDate: value?.format('YYYY-MM-DD') || null })}
            minDate={'2019-01-01'}
          />
          {![DateFilterType.Before, DateFilterType.After].includes(props.dateFilter.type) && (
            <>
              <span className="between-dates">and</span>
              <DateSelectEditor
                placeholderText='Date'
                initDate={props.dateFilter.secondDate}
                onDateChange={(value) => props.setDateFilter({ ...props.dateFilter, secondDate: value?.format('YYYY-MM-DD') || null })}
              />
            </>
          )}
        </div>
      )}
    </>
  )
}
