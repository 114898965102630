import { request } from './request';
import { Filter } from './supplierInvoice';

export interface APILocationUnlocode {
  name: string;
  unlocode: string;
}

export const LocationUnlocodeAPI = {
  async fetchLocations(filter: Filter): Promise<APILocationUnlocode[]> {
    return request(`/LocationUnlocodes/search?filter=${encodeURIComponent(JSON.stringify(filter))}`);
  },
}
