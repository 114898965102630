import * as React from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { ShipamaxEllipsisIcon } from '../../../images/Icons';
import { cn, useScreenshot } from '../../helpers';
import { ChartForm } from './ChartForm';
import { Notification } from '../../common/Notification';
import { APIPermissionProfile } from '../../../api/permissionProfiles';
import { useHistory, useParams } from 'react-router-dom';
import {
  APICustomDashboard,
  APICustomDashboardChart,
  APICustomDashboardRow,
  CustomDashboardAPI
} from '../../../api/customDashboard';
import { NameForm } from './NameForm';
import { ChartView } from './ChartView';
import { Icon } from 'pivotal-ui/react/iconography';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { ConfirmModal } from '../../common/ConfirmModal';
import { Aggregation, SectionModule, SerieToggleState } from '../common';
import { CounterType } from '../ChartWrapper';
import { defaultInsightsFilterSettings } from '../../../api/userSettings';
import { PermissionLevel } from '../../../api/authentication';
import { jsPDF } from 'jspdf';
import { reorder } from '../../dnd-helpers';

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightgrey" : "",
  // width: 200
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "lightgrey" : "",

  // styles we need to apply on draggables
  ...draggableStyle
});

const rowIdentifier = (row: APICustomDashboardRow): string => `row-${row.id}`;
const rowContainerIdentifier = (rowId: number | string): string => `charts-container-${rowId}`;
const chartIdentifier = (chart: APICustomDashboardChart): string => `chart-${chart.id}`;

const customPageSizesMap: Record<number, number> = {
  1: 12,
  2: 6,
  3: 3,
}

interface Props {
  setNotification: (notification: Notification | null) => void;
  permissionProfile: APIPermissionProfile | null;
  permissionLevel: PermissionLevel;
  sectionModule: SectionModule;
  readOnly?: boolean;
  refetchCustomDashboards: () => void;
  customDashboards: APICustomDashboard[];
}

export const Dashboard: React.FC<Props> = (props) => {
  const initialDashboardData: APICustomDashboard = {
    id: undefined,
    name: 'Custom dashboard',
    rows: [],
    sectionModule: props.sectionModule,
    companyId: parseInt(localStorage.getItem('companyId') || ''),
  };

  const [dashboard, setDashboard] = React.useState<APICustomDashboard>(initialDashboardData);
  const [editableChartData, setEditableChartData] = React.useState<APICustomDashboardChart | undefined>();
  const [isDashboardNameFormVisible, setIsDashboardNameFormVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [confirmDashboardDelete, setConfirmDashboardDelete] = React.useState(false);
  const [chartIdsLoading, setChartIdsLoading] = React.useState<number[]>([]);
  const [chartToggles, setChartToggles] = React.useState<SerieToggleState>();

  const params = useParams<{ id: string }>();
  const dashboardId = params.id ? parseInt(params.id) : null;

  const history = useHistory();

  let chartsContainerRef: HTMLDivElement | null = null;

  const [image, takeScreenShot] = useScreenshot();

  const download = (pages: { dataUrl: string, width: number, height: number }[]) => {
    if (!pages.length) return;

    const baseRatioX = 10;

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'in',
      format: 'a3',
    });

    pages.forEach((page, index) => {
      if (index > 0) {
        pdf.addPage('a3');
      }

      const ratioY = Math.ceil(baseRatioX * page.height / page.width);

      pdf.addImage(page.dataUrl, 'JPEG', 0.1, 0.1, baseRatioX, ratioY, '', 'FAST');
    });

    pdf.save(`${dashboard.name.replace(/ /g, '-')}-export.pdf`);
  };

  const createScreenshot = () => {
    setTimeout(async () => {
      const ref = document.getElementById('dashboard-wrapper');
      const pagesContainers: any[] = [];
      const pages: any[] = [];

      if (!chartsContainerRef) return;

      const relevantChartRows = Array.from(chartsContainerRef.children).filter((child) => child.className.includes('dashboard-row'));

      const screenRatio = window.innerWidth / window.innerHeight;
      let chartRowsPerPage = 2;
      if (screenRatio > 1.15) chartRowsPerPage = 3;
      if (screenRatio > 2.10) chartRowsPerPage = 4;

      const pagesIndexes = Math.ceil((relevantChartRows.length || 0) / chartRowsPerPage);

      for (let pageIndex = 0; pageIndex < pagesIndexes; pageIndex++) {
        const newContainer = document.createElement('div');
        const newChartsContainer = document.createElement('div');

        newContainer.style.padding = '0 1px';

        if (pageIndex === 0) {
          const title = ref?.children[0];

          if (title) {
            newContainer.append(title.cloneNode(true));
          }
        }

        for (let i = 0; i < chartRowsPerPage; i++) {
          const chart = relevantChartRows[(pageIndex * chartRowsPerPage) + i];
          chart && newChartsContainer.append(chart.cloneNode(true));
        }

        newContainer.append(newChartsContainer);
        pagesContainers.push(newContainer);
      }

      for (let i = 0; i < pagesContainers.length; i++) {
        const pageContainer = pagesContainers[i];

        ref?.append(pageContainer);
        const page = await takeScreenShot(pageContainer);
        pages.push(page);
        ref?.removeChild(pageContainer);
      }

      await download(pages);
    }, 200);
  }

  React.useEffect(() => {
    setIsLoading(true);
    if (!dashboardId) {
      setIsDashboardNameFormVisible(true);
      setDashboard(initialDashboardData);
      return
    }

    CustomDashboardAPI.fetch(dashboardId).then((dashboard) => {
      setDashboard({
        ...dashboard,
        rows: (dashboard.rows || [])
          .sort((a, b) => a.orderIndex - b.orderIndex)
          .map((row) => ({
            ...row,
            charts: (row.charts || []).sort((a, b) => a.orderIndex - b.orderIndex),
          })),
      });
      setIsLoading(false);
      setChartIdsLoading(dashboard.rows.reduce((acc, row) => [...acc, ...row.charts.map((chart) => chart.id!)], [] as number[]));
    })
  }, [dashboardId]);

  const onAddChart = () => {
    setEditableChartData({
      id: undefined,
      name: '',
      dashboardId: dashboard.id || -1,
      orderIndex: 1,
      settings: {
        insightsSettings: defaultInsightsFilterSettings[SectionModule.APInvoice],
        chartSettings: {
          aggregation: Aggregation.WeekReceived,
          counterType: CounterType.InvoiceCount,
        },
        userOptions: undefined,
      },
      dashboardRowId: undefined,
    });
  }

  const onUpdateDashboardName = async (name: string) => {
    let result: APICustomDashboard;

    if (dashboardId) {
      result = await CustomDashboardAPI.update(dashboardId, { ...dashboard, name });
    } else {
      result = await CustomDashboardAPI.create({ ...dashboard, name });
    }

    props.refetchCustomDashboards();

    if (!dashboardId) {
      history.push('/apInsights/custom-dashboards/' + result.id);
    }
    setDashboard({
      ...dashboard,
      ...result,
    });
    setIsDashboardNameFormVisible(false);
  }

  const onCancelUpdateDashboardName = () => {
    if (!dashboardId) {
      history.push('/apInsights/explore');
    } else {
      setIsDashboardNameFormVisible(false);
    }
  }

  const getChartToggles = (data: SerieToggleState) => {
    setChartToggles(data)
  }

  const onSaveChart = async (chart: Partial<APICustomDashboardChart>) => {
    if (!dashboardId) return;

    chart.settings!.userOptions = chartToggles

    if (chart.id) {
      const result = await CustomDashboardAPI.updateChart(chart.id, chart);
      setDashboard({
        ...dashboard,
        rows: (dashboard.rows || []).map((row) => row.id === chart.dashboardRowId ? ({
          ...row,
          charts: row.charts?.map((chart) => chart.id === result.id ? result : chart) || [],
        }) : row),
      });
    } else {
      const rowResult = await CustomDashboardAPI.createRow({
        dashboardId: dashboardId,
        orderIndex: (dashboard.rows || []).length,
      });

      const result = await CustomDashboardAPI.createChart({
        ...chart,
        dashboardRowId: rowResult.id,
      });

      setDashboard({
        ...dashboard,
        rows: [
          ...(dashboard.rows || []),
          {
            ...rowResult,
            charts: [result],
          }
        ],
      });
    }

    setEditableChartData(undefined);
  }

  const deleteDashboard = async () => {
    if (!dashboardId) return;

    await CustomDashboardAPI.delete(dashboardId);
    props.refetchCustomDashboards();
    history.push('/apInsights/explore');
  }

  const deleteChart = React.useCallback(async (removedChart: APICustomDashboardChart) => {
    if (!dashboardId || !removedChart.id) return;

    await CustomDashboardAPI.deleteChart(removedChart.id);

    const row = dashboard.rows.find((row) => row.id === removedChart.dashboardRowId);
    const shouldRemoveRow = row?.charts.length === 1;

    if (shouldRemoveRow) {
      await CustomDashboardAPI.deleteRow(removedChart.dashboardRowId!);
    }

    setDashboard((d) => ({
      ...d,
      rows: shouldRemoveRow ? d.rows.filter((row) => row.id !== removedChart.dashboardRowId) : d.rows.map((row) => row.id === removedChart.dashboardRowId ? ({
        ...row,
        charts: row.charts.filter((chart) => removedChart.id !== chart.id),
      }) : row)
    }));
  }, [dashboardId]);

  const onDragEnd = async (result: DropResult) => {
    const promises: Promise<unknown>[] = [];
    let deleteEmptyRow: undefined | (() => Promise<unknown> | undefined);

    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (result.type === "droppable-row") {
      const reorderedRows = reorder(dashboard.rows, sourceIndex, destIndex);

      setDashboard({
        ...dashboard,
        rows: reorderedRows,
      });

      reorderedRows.forEach((row, index) => {
        promises.push(CustomDashboardAPI.updateRow(row.id || -1, { orderIndex: index }));
      });

    } else if (result.type === "droppable-chart") {
      const rowsMap = dashboard.rows.reduce((acc, item) => {
        acc[rowContainerIdentifier(item.id)] = item;
        return acc;
      }, {} as Record<string, APICustomDashboardRow>);

      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;

      const sourceRow = rowsMap[sourceParentId];
      const destRow = rowsMap[destParentId];

      let newRows = [...dashboard.rows];

      if (sourceParentId === destParentId && sourceRow) {
        const reorderedCharts = reorder<APICustomDashboardChart>(
          sourceRow.charts,
          sourceIndex,
          destIndex
        );
        newRows = newRows.map((row) => {
          if (row.id === sourceRow.id) {
            return { ...row, charts: reorderedCharts };
          }
          return row;
        });

        setDashboard({ ...dashboard, rows: newRows });

        reorderedCharts.forEach((chart, index) => {
          promises.push(CustomDashboardAPI.updateChart(chart.id || -1, { orderIndex: index }));
        });
      } else {
        let newSourceRowCharts = [...sourceRow.charts];
        const [draggedChart] = newSourceRowCharts.splice(sourceIndex, 1);

        let newDestRowCharts: APICustomDashboardChart[];
        if (destRow) {
          newDestRowCharts = [...destRow.charts];
          newDestRowCharts.splice(destIndex, 0, draggedChart);
        } else {
          newDestRowCharts = [draggedChart];
        }

        let finalRows: APICustomDashboardRow[] = [];

        newRows.forEach((row) => {
          if (rowContainerIdentifier(row.id) === sourceParentId) {
            row.charts = newSourceRowCharts;
            newSourceRowCharts.forEach((chart, index) => {
              promises.push(CustomDashboardAPI.updateChart(chart.id || -1, { orderIndex: index }));
            });
          } else if (rowContainerIdentifier(row.id) === destParentId) {
            row.charts = newDestRowCharts;

            newDestRowCharts.forEach((chart, index) => {
              promises.push(CustomDashboardAPI.updateChart(chart.id || -1, { orderIndex: index }));
            });
          }
          if (row.charts.length) {
            finalRows.push(row);
          } else {
            deleteEmptyRow = () => CustomDashboardAPI.deleteRow(row.id || -1);
          }
        });

        if (!destRow) { // dragged to a row level to create a new row
          const rowIndex = parseInt(destParentId.replace('charts-container-', '').replace('-placeholder', '')) ?? (dashboard.rows || []).length

          const rowResult = await CustomDashboardAPI.createRow({
            dashboardId: dashboardId!,
            orderIndex: rowIndex,
          });

          await CustomDashboardAPI.updateChart(draggedChart.id || -1, { dashboardRowId: rowResult.id });

          finalRows.splice(rowIndex, 0, { ...rowResult, charts: newDestRowCharts });

          finalRows.forEach((row, index) => {
            promises.push(CustomDashboardAPI.updateRow(row.id || -1, { orderIndex: index }));
          });
        } else {
          promises.push(CustomDashboardAPI.updateChart(draggedChart.id || -1, {
            dashboardRowId: parseInt(destParentId.replace('charts-container-', ''))
          }));
        }

        setDashboard({
          ...dashboard,
          rows: finalRows
        });
      }
    }

    await Promise.all(promises);
    deleteEmptyRow && await deleteEmptyRow();
  }

  const allowChanges = props.permissionLevel === PermissionLevel.Admin;

  return (
    <>
      <div
        className="py-[28px]"
      >
        <div
          id="dashboard-wrapper"
        >
          <div className="flex items-center justify-between pb-[15px] mx-[20px]">
            <h1
              className="text-[18px] text-grey6"
            >{dashboard.name}</h1>

            {allowChanges && (
              <>
                <ContextMenuTrigger id="grid-context-menu" mouseButton={0}>
                  <div id="dashboardOptions" className="cursor-pointer px-[20px] mr-[-20px]">
                    <ShipamaxEllipsisIcon className='ellipsis-icon' />
                  </div>
                </ContextMenuTrigger>
                <ContextMenu id="grid-context-menu">
                  <MenuItem
                    onClick={() => setIsDashboardNameFormVisible(true)}
                  ><b>Rename</b>&nbsp;Dashboard</MenuItem>
                  <MenuItem
                    onClick={() => setConfirmDashboardDelete(true)}
                  ><b>Delete</b>&nbsp;Dashboard</MenuItem>
                  <MenuItem
                    disabled={!!chartIdsLoading.length}
                    onClick={createScreenshot}
                  ><b>Export</b>&nbsp;Dashboard as PDF</MenuItem>
                </ContextMenu>
              </>
            )}
          </div>
          {isDashboardNameFormVisible && (
            <NameForm
              type="dashboard"
              id={dashboard.id}
              sectionModule={dashboard.sectionModule}
              onCancel={onCancelUpdateDashboardName}
              onSubmit={onUpdateDashboardName}
            />
          )}

          {isLoading && (
            <div className="flex items-center justify-center h-[300px]">
              <Icon style={{ fontSize: '48px' }} src="spinner-md" />
            </div>
          )}
          <div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="rows-container" type="droppable-row">
                {(rowsDroppableProvided, rowsDroppableSnapshot) => (
                  <div
                    {...rowsDroppableProvided.droppableProps}
                    ref={(ref) => {
                      rowsDroppableProvided.innerRef(ref);
                      chartsContainerRef = ref;
                    }}
                    style={getListStyle(rowsDroppableSnapshot.isDraggingOver)}
                  >
                    <Droppable droppableId={rowContainerIdentifier('0-placeholder')} type="droppable-chart" direction="horizontal">
                      {(chartsDroppableProvided, chartsDroppableSnapshot) => (
                        <div
                          {...chartsDroppableProvided.droppableProps}
                          ref={chartsDroppableProvided.innerRef}
                          className="flex"
                          style={{
                            ...getListStyle(chartsDroppableSnapshot.isDraggingOver),
                            width: '100%',
                            height: chartsDroppableSnapshot.isDraggingOver ? '400px' : '10px',
                          }}
                        >
                        </div>
                      )}
                    </Droppable>
                    {dashboard.rows?.map((row, rowIndex) => (
                      <>
                        <Draggable
                          key={rowIdentifier(row)}
                          draggableId={rowIdentifier(row)}
                          index={rowIndex}
                        >
                          {(rowDraggableProvided, rowDraggableSnapshot) => (
                            <div
                              ref={rowDraggableProvided.innerRef}
                              {...rowDraggableProvided.draggableProps}
                              style={getItemStyle(
                                rowDraggableSnapshot.isDragging,
                                rowDraggableProvided.draggableProps.style
                              )}
                              className={`dashboard-row dashboard-row-${rowIndex} mx-[20px]`}
                            >
                              <Droppable
                                droppableId={rowContainerIdentifier(row.id)}
                                type="droppable-chart"
                                direction="horizontal"
                                isDropDisabled={row.charts.length === 3}
                              >
                                {(chartsDroppableProvided, chartsDroppableSnapshot) => (
                                  <div
                                    {...chartsDroppableProvided.droppableProps}
                                    ref={chartsDroppableProvided.innerRef}
                                    className="flex gap-[20px]"
                                    style={getListStyle(chartsDroppableSnapshot.isDraggingOver)}
                                  >
                                    {row.charts.map((chart, chartIndex) => (
                                      <Draggable
                                        key={chartIdentifier(chart)}
                                        draggableId={chartIdentifier(chart)}
                                        index={chartIndex}
                                      >
                                        {(chartDraggableProvided, chartDraggableSnapshot) => {
                                          let width = (100 / row.charts.length);

                                          const draggingOverRowId = chartDraggableSnapshot.draggingOver?.replace('charts-container-', '');

                                          if (chartDraggableSnapshot.isDragging) {
                                            if (draggingOverRowId) {

                                              width = 100 / 3;
                                              const draggingOverRow = dashboard.rows?.find((row) => row.id === parseInt(draggingOverRowId));

                                              if (draggingOverRow && draggingOverRow.id !== chart.dashboardRowId) {
                                                width = (100 / (draggingOverRow.charts.length + 1));
                                              }
                                            } else {
                                              width = 100;
                                            }
                                          }

                                          let newTransform;

                                          if (!chartDraggableSnapshot.isDragging && chartsDroppableSnapshot.isDraggingOver) {
                                            width = 100 / (row.charts.length + 1);

                                            const transform = chartDraggableProvided.draggableProps.style?.transform;
                                            const [translateX, translateY] = transform?.replace('translate(', '').replace(/px/g, '').split(',').map((s) => parseFloat(s)) || [undefined, undefined];
                                            if (translateX) {
                                              newTransform = `translate(${translateX * width / 100}px, ${translateY}px)`;
                                            }
                                          }

                                          return (
                                            <div
                                              ref={chartDraggableProvided?.innerRef}
                                              {...chartDraggableProvided?.draggableProps}
                                              style={{
                                                ...(getItemStyle(
                                                  chartDraggableSnapshot.isDragging,
                                                  chartDraggableProvided.draggableProps.style
                                                )),
                                                width: width + '%',
                                                ...(newTransform ? { transform: newTransform } : {}),
                                              }}
                                              className="mb-[10px]"
                                            >
                                              <ChartView
                                                section={props.sectionModule}
                                                dragHandleProps={chartDraggableProvided.dragHandleProps}
                                                isBeingDragged={chartDraggableSnapshot.isDragging}
                                                key={chartIndex}
                                                chart={chart}
                                                permissionProfile={props.permissionProfile}
                                                permissionLevel={props.permissionLevel}
                                                enableChartEditMode={setEditableChartData}
                                                onDeleteChart={deleteChart}
                                                customDashboards={props.customDashboards}
                                                customPageSize={customPageSizesMap[row.charts.length] || 12}
                                                onChartLoaded={() => setChartIdsLoading((chartIdsLoading) => chartIdsLoading.filter((id) => id !== chart.id))}
                                                getChartToggles={getChartToggles}
                                              />
                                            </div>
                                          )
                                        }}
                                      </Draggable>
                                    ))}
                                    {chartsDroppableProvided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </div>
                          )}
                        </Draggable>
                        <Droppable droppableId={rowContainerIdentifier((rowIndex + 1) + '-placeholder')} type="droppable-chart" direction="horizontal">
                          {(chartsDroppableProvided, chartsDroppableSnapshot) => (
                            <div
                              {...chartsDroppableProvided.droppableProps}
                              ref={chartsDroppableProvided.innerRef}
                              className="flex"
                              style={{
                                ...getListStyle(chartsDroppableSnapshot.isDraggingOver),
                                width: '100%',
                                height: chartsDroppableSnapshot.isDraggingOver ? '400px' : '10px',
                              }}
                            >
                            </div>
                          )}
                        </Droppable>
                      </>
                    ))}
                    {rowsDroppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        {allowChanges && (
          <button
            className={cn(
              "flex w-[calc(100%-40px)] mx-[20px] py-[23px] items-center justify-center text-[18px] text-grey5",
              "border border-solid border-grey4 rounded-[10px] bg-transparent",
              "hover:border-blue hover:text-blue",
            )}
            onClick={onAddChart}
          >
            Add a Chart
          </button>
        )}
        {editableChartData && (
          <ChartForm
            sectionModule={props.sectionModule}
            chartData={editableChartData}
            onCancel={() => setEditableChartData(undefined)}
            onSave={onSaveChart}
            setNotification={props.setNotification}
            permissionProfile={props.permissionProfile}
            permissionLevel={props.permissionLevel}
            customDashboards={props.customDashboards}
            getChartToggles={getChartToggles}
            chartToggles={chartToggles}
          />
        )}
        {confirmDashboardDelete && (
          <ConfirmModal
            size={290}
            show={true}
            title="Delete Custom Dashboard"
            confirmText="Continue"
            onConfirm={deleteDashboard}
            onHide={() => setConfirmDashboardDelete(false)}
            text={`
              Deleting your dashboard will permanently remove it from Insights for all users.
              <br />
              <br />
  
              <b>Would you like to continue?</b>
            `}
          />
        )}
      </div>
    </>
  );
}
