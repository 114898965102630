import * as React from 'react';

import './chart-filter-toggles.scss';

export interface FilterState {
  label: string;
  value: number;
}

interface ChartFilterButtonProps {
  active: boolean;
  onClick: () => void;
}

const ChartFilterButton: React.FC<ChartFilterButtonProps> = (props) => (
  <button className={`chart-filter-toggles__button ${props.active ? 'chart-filter-toggles__button--active' : ''}`} onClick={props.onClick}>{props.children}</button>
)

export type SelectedValues = (string | number)[];

interface Props {
  options: FilterState[];
  selectedOptions: SelectedValues;
  setSelectedOptions: (values: SelectedValues) => void;
  denseMode?: boolean;
  singeOptionMode?: boolean;
  subForm?: React.ReactElement | boolean;
}

export const ChartFilterToggles: React.FC<Props> = (props) => {

  const onButtonClicked = (option: FilterState) => {
    if (props.selectedOptions.includes(option.value)) {
      if (!props.singeOptionMode && props.selectedOptions.length > 1) {
        props.setSelectedOptions(props.selectedOptions.filter((value) => option.value !== value));
      }
    } else {
      props.setSelectedOptions(props.singeOptionMode ? [option.value] : [...props.selectedOptions, option.value]);
    }
  }

  const cssClasses = [
    'chart-filter-toggles',
    props.denseMode ? 'chart-filter-toggles--dense-mode' : '',
    props.subForm ? 'chart-filter-toggles--with-sub-form' : '',
  ];

  return (
    <div className={cssClasses.join(' ')}>
      <div className="chart-filter-toggles__row chart-filter-toggles__row--with-buttons">
        {props.options.map((option) => {
          return (
            <ChartFilterButton
              active={props.selectedOptions.includes(option.value)}
              onClick={() => onButtonClicked(option)}
            >{option.label}</ChartFilterButton>
          )
        })}
      </div>
      {props.subForm && (
        <div className="chart-filter-toggles__row chart-filter-toggles__row--sub-form">{props.subForm}</div>
      )}
    </div>
  )
}
