import * as React from 'react';
import { JobRef, JobRefType } from './Clusters';
import { AccrualsTable } from './AccrualsTable';
import { APIClusterAccrual } from '../../../api/clusterAccrual';
import { AccrualsFormMode, UpdatedAccrualsMap } from './AccrualsForm';
import { AccrualChanges } from './AccrualsRow';
import { APIExchangeRate } from '../../../api/supplierInvoice';
import { APICgwCurrencyExchangeRates } from '../../../api/currency';
import { CustomDropdown } from '../../common/CustomDropdown';
import { getJobRefType, getJobRefValue, hasOverlap } from '../../helpers';
import { AggregatedTMSRef } from './AggregatedJobReference';
import { DocumentViewerContext } from '../../DocumentViewerContext';
import { APIDocCoordinate, findMatchedCoordinates } from '../../../api/documentContainer';
import { isInViewport } from '../../common/document-viewer/HighlightOverlay';
import { APIPermissionProfileToleranceThreshold } from '../../../api/permissionProfiles';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';

import './job-ref-tolerances-wrapper.scss'

const ArrowRight = () => (
  <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.81641 3.98565L7.31641 6.73565C7.07207 7.00442 6.67656 7.00399 6.43262 6.73565C6.18848 6.4671 6.18848 6.03204 6.43262 5.76349L7.86719 4.18761H0.625C0.279492 4.18761 0 3.88038 0 3.48077C0 3.08116 0.279492 2.81261 0.625 2.81261H7.86719L6.43398 1.23608C6.18984 0.967529 6.18984 0.532471 6.43398 0.263916C6.67812 -0.00463867 7.07363 -0.00463867 7.31777 0.263916L9.81777 3.01392C10.0605 3.28312 10.0605 3.7171 9.81641 3.98565Z" fill="#DBDBDB"/>
  </svg>
)

const getJobRefAccruals = (accruals: APIClusterAccrual[], jobRef: string | null, clusterId: number): APIClusterAccrual[] => {
  return accruals.filter(
    (accrual) => [accrual.consolRef?.toUpperCase(), accrual.shipmentRef?.toUpperCase()].includes(jobRef?.toUpperCase() || 'INVALID JOB REF') && clusterId === accrual.clusterId);
}

const getJobRefLabel = (jobRef: JobRef): React.ReactNode => {
  const jobRefValue = getJobRefValue(jobRef) + '';

  return ((jobRef as any).aggregatedByConsol && getJobRefType(jobRef) !== JobRefType.Ref) ? <>{jobRefValue} <ArrowRight /> {jobRef.jobRef}</> : jobRefValue;
}

interface Props {
  aggregatedTMSRef: AggregatedTMSRef;
  clusterId: number;
  accrualsMap: UpdatedAccrualsMap;
  invoiceAccruals: APIClusterAccrual[];
  updateAccruals: (changes: AccrualChanges) => void;
  invoiceCurrencyCode: string;
  accrualsFormMode: AccrualsFormMode;
  exchangeRates: APIExchangeRate[];
  cargowiseExchangeRates: APICgwCurrencyExchangeRates[];
  toleranceThreshold: APIPermissionProfileToleranceThreshold | undefined;
  localCurrency?: string;
  scrollableContainerRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const JobRefTolerancesWrapper: React.FC<Props> = (props) => {
  const jobRefAccruals = React.useMemo(() => getJobRefAccruals(Object.values(props.accrualsMap), props.aggregatedTMSRef.jobRef, props.clusterId!), [props.accrualsMap, props.aggregatedTMSRef.jobRef, props.clusterId]);
  const [showAggregatedView, setShowAggregatedView] = React.useState<boolean>(false);
  const [selectedJobRefIndex, setSelectedJobRefIndex] = React.useState<number>(0);

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const jobRefs = React.useMemo(() => {
    const result: JobRef[] = [];

    props.aggregatedTMSRef.aggregatedDocumentRefs.forEach((ref) => result.push(...ref.refs));

    return result;
  }, [props.aggregatedTMSRef]);

  const fieldData = {
    tableName: 'supplier_invoice_job_ref',
    objectIds: jobRefs.filter((jobRef) => jobRef.id).map((jobRef) => jobRef.id) as number[],
    attributeNames: ['job_ref', 'container_num', 'bol_num', 'purchase_order'],
  }

  const documentViewerContext = React.useContext(DocumentViewerContext);
  const matchedCoordinates = findMatchedCoordinates(fieldData || null, documentViewerContext.coordinates) || null;

  React.useEffect(() => {
    setSelectedJobRefIndex(0);
  }, [jobRefs]);

  const isConsolReference = props.aggregatedTMSRef.jobRef?.charAt(0) === 'C';
  const hasApportionedAccruals = jobRefAccruals.some((accrual) => accrual.isApportioned);

  React.useEffect(() => {
    if (!wrapperRef.current) return;

    if (hasOverlap<APIDocCoordinate>(matchedCoordinates, documentViewerContext.focusedCoordinates) && !isInViewport(wrapperRef.current, props.scrollableContainerRef.current)) {
      wrapperRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [documentViewerContext.focusedCoordinates, props.scrollableContainerRef]);

  React.useEffect(() => {
    setShowAggregatedView(isConsolReference);
  }, [props.aggregatedTMSRef]);

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutsideOfDropdownMenu);

    return () => {
      window.removeEventListener('click', handleClickOutsideOfDropdownMenu);
    }
  }, [documentViewerContext.focusedCoordinates]);

  const hasMultipleReferences = jobRefs.length > 1;

  const onWrapperClicked = () => {
    documentViewerContext.setFocusedCoordinates(matchedCoordinates);
  }

  const isHighlighted = hasOverlap<APIDocCoordinate>(matchedCoordinates, documentViewerContext.focusedCoordinates);

  const handleClickOutsideOfDropdownMenu = React.useCallback((event: MouseEvent) => {
    if (isHighlighted && wrapperRef.current && !wrapperRef.current.contains(event.target as HTMLElement)) {
      documentViewerContext.setFocusedCoordinates([]);
    }
  }, [isHighlighted, wrapperRef]);

  return (
    <div className="job-ref--tolerances">
      <div className="job-ref--tolerances__header">
        <div className="flex items-center mt-[5px]">
          <div style={{ width: '160px', marginRight: '12px' }}>
            <div
              className={`job-ref--tolerances__header__wrapper ${hasMultipleReferences ? 'job-ref--tolerances__header__wrapper--multiple-refs' : ''} ${isHighlighted ? 'job-ref--tolerances__header__wrapper--highlighted' : ''}`}
              onClick={onWrapperClicked}
              ref={wrapperRef}
            >
              <OverlayTrigger overlay={<Tooltip>{getJobRefLabel(jobRefs[selectedJobRefIndex])}</Tooltip>} placement="bottom" delayShow={500}>
                <span>{getJobRefLabel(jobRefs[selectedJobRefIndex])}</span>
              </OverlayTrigger>
              {hasMultipleReferences && (
                <CustomDropdown<number>
                  label={`+ ${jobRefs.length - 1} more`}
                  options={jobRefs.map((jobRef, index) => ({ label: <span className="active-on-hover">{getJobRefLabel(jobRef)}</span>, value: index }))}
                  selectedValue={selectedJobRefIndex}
                  onSelect={(index) => {
                    const selectedCoordinates = matchedCoordinates.filter((coordinate) => coordinate.parentTableId === jobRefs[index].id)
                    documentViewerContext.setFocusedCoordinates(selectedCoordinates);
                    setSelectedJobRefIndex(index);
                  }}
                  hideToggleIcon
                />
              )}
            </div>
          </div>
          <ArrowRight />
          <div className="job-ref--tolerances__header__tms-ref">{props.aggregatedTMSRef.jobRef}</div>
        </div>
        {hasApportionedAccruals && (
          <div className="job-ref--tolerances__header__tabs">
            <button
              onClick={() => setShowAggregatedView(false)}
              className={!showAggregatedView ? 'selected' : ''}
            >Shipment Accruals</button>
            <button
              onClick={() => setShowAggregatedView(true)}
              className={showAggregatedView ? 'selected' : ''}
            >Consol Accruals</button>
          </div>
        )}
      </div>
      <AccrualsTable
        invoiceCurrencyCode={props.invoiceCurrencyCode}
        mailboxCurrencyCode={props.localCurrency || 'NOT_FOUND'}
        updateAccruals={props.updateAccruals}
        visibleAccruals={jobRefAccruals}
        invoiceAccruals={props.invoiceAccruals}
        accrualsFormMode={props.accrualsFormMode}
        showAggregatedView={showAggregatedView}
        exchangeRates={props.exchangeRates}
        toleranceThreshold={props.toleranceThreshold}
        cargowiseExchangeRates={props.cargowiseExchangeRates}
        hasApportionedAccruals={hasApportionedAccruals}
        isConsolReference={isConsolReference}
      />
    </div>
  )
}