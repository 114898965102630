import * as React from 'react';
import { APIDocumentGroupSearchDetails, DocumentGroupAPI } from '../../../api/documentGroup';
import {
  APIDocumentContainer,
  APIDocumentType,
  APISplitDetails,
  blTypes,
  DocumentContainerAPI,
  DocumentType,
  MinimalHierarchy,
  unsupportedReparseTypes
} from '../../../api/documentContainer';
import { ConfirmModal } from '../../common/ConfirmModal';
import { APIError, Notification, Split } from '../../common/Notification';
import { APIEmailBasic } from '../../../api/email';
import { ActionModalType, DocumentActionModal } from '../../common/DocumentActionModal';
import { FileSorter, findTopFile, getFlatFiles, DocumentTypesToUpdate } from './FileSorter';
import { IssuerDetailMap } from '../common';
import { ValidationType } from '../../../api/emailAccount';

import './detail-file-actions.scss';

interface Props {
  details: APIDocumentGroupSearchDetails | null;
  issuerDetails: IssuerDetailMap;
  documentTypes: APIDocumentType[];
  activeDocument: APIDocumentContainer | APIEmailBasic | undefined;
  actionType: ActionModalType;
  setNotification: (notification: Notification | null) => void;
  reloadDetailsForGroup: (id: number, reloadBoards?: boolean) => Promise<void>;
  setShowOverlay: (value: boolean) => void;
  reloadDashboard: () => void;
  closeDetails: () => void;
  setShowActionsModal: (value: ActionModalType) => void;
  waitForValidationToComplete: (groupId: number, validationResultId: number | null, resolve: (value?: unknown) => void) => void;
  validationType: ValidationType | undefined;
}

export type HierarchyChange = {
  documentId: number,
  childId: number;
}

enum PopupType {
  Discard,
  Save,
}

export const DetailFileActions: React.FC<Props> = (props) => {
  const [actionDescription, setActionDescription] = React.useState<string>('');
  const [selectedFiles, setSelectedFiles] = React.useState<number[]>([]);
  const [docTypesToUpdate, setDocTypesToUpdate] = React.useState<DocumentTypesToUpdate[]>([]);
  const [newGroup, setNewGroup] = React.useState<APIDocumentContainer[]>([]);
  const [activeDocument, setActiveDocument] = React.useState<APIDocumentContainer | APIEmailBasic | undefined>();
  const [displayedPopup, setDisplayedPopup] = React.useState<PopupType | undefined>(undefined);
  const [hierarchyChanges, setHierarchyChanges] = React.useState<HierarchyChange[]>([]);
  const [details, setDetails] = React.useState<APIDocumentGroupSearchDetails | null>(props.details);
  const userId = Number(localStorage.getItem('userId'));

  const flatDocuments = getFlatFiles(props.details?.documents || []);
  const isFwdOrExport = props.validationType &&
    [ValidationType.ClearanceAndForwarding, ValidationType.Forwarding, ValidationType.GatewayForwarding, ValidationType.Export].includes(props.validationType);

  const getSaveText = (changes: DocumentTypesToUpdate[]) => {
    if (hasMblChange(changes) && isFwdOrExport)
      return "Adding and/or removing MBLs from pack may reset the existing routing. <br/><br/> <b> Are you sure you want to apply changes?</b>"
    else if (props.actionType !== ActionModalType.DragMode)
      return "To complete your changes, some of the documents you’ve edited will be reprocessed. Any changes you previously made to the data from those documents will be lost.<br/><br/> <b>Do you want to reprocess the documents?</b> This may take a few minutes and the pack will reappear once this is complete."
    else
      return "Changes made in this view will be saved. <br/>Note: Shipments that do not contain documents will be deleted.<br/><br/> <b> Are you sure you want to apply the changes?</b>"
  }

  const getCancelText = () => {
    if (props.actionType !== ActionModalType.DragMode)
      return "Any changes you’ve made in this view will be discarded.</br></br><b>Are you sure you want to exit?</b>"
    else
      return "Changes made in this view will be discarded. <br/><br/> <b> Are you sure you want to exit?</b>"
  }

  const getConfirmButtonText = (changes: DocumentTypesToUpdate[]) => {
    if (hasMblChange(changes) && isFwdOrExport) {
      return "Yes, change type";
    } else if (props.actionType !== ActionModalType.DragMode) {
      return "Reprocess & Discard Previous Change";
    } else {
      return "Save Changes";
    }
  }

  const getCancelButtonText = (changes: DocumentTypesToUpdate[]) => {
    if (hasMblChange(changes) && isFwdOrExport) {
      return "Cancel";
    } else {
      return "Cancel & Continue Editing";
    }
  }

  const getSaveTitleText = (changes: DocumentTypesToUpdate[]) => {
    if (hasMblChange(changes) && isFwdOrExport) {
      return "Reset Routing";
    } else if (props.actionType !== ActionModalType.DragMode) {
      return "Previous changes may be lost";
    } else {
      return "Confirm Changes";
    }
  }

  React.useEffect(() => {
    switch (props.actionType) {
      case ActionModalType.EditMode:
        setActiveDocument(props.activeDocument);
        setActionDescription('Edit the document types you want to change');
        break;
      case ActionModalType.SplitMode:
        setActiveDocument(props.activeDocument);
        setActionDescription('Select the documents you would like to move to a new pack');
        break;
      case ActionModalType.DragMode:
        setActiveDocument(props.activeDocument);
        setActionDescription('Drag and drop documents to link them to jobs');
        break;
      case ActionModalType.Hidden:
      default:
    }
  }, [props.actionType]);

  const closeDetailsActionView = () => {
    setDisplayedPopup(undefined);
    setNewGroup([]);
    setSelectedFiles([]);
    if (props.actionType === ActionModalType.DragMode && props.details?.id && hierarchyChanges.length !== 0)
      props.reloadDetailsForGroup(props.details?.id);
    props.setShowActionsModal(ActionModalType.Hidden);
  }

  const onSaveAction = () => {
    const hasReparsingChanges = docTypesToUpdate.some((update) => {
      return !(blTypes.includes(update.newType) && blTypes.includes(update.oldType)) && !unsupportedReparseTypes.includes(update.newType);
    });

    if ((props.actionType === ActionModalType.EditMode && hasReparsingChanges) || props.actionType === ActionModalType.DragMode || (hasMblChange(docTypesToUpdate) && isFwdOrExport)) {
      setDisplayedPopup(PopupType.Save);
    } else {
      saveChanges();
    }
  }

  const onDiscardAction = () => {
    if ((props.actionType === ActionModalType.SplitMode && newGroup.length > 0) ||
      (props.actionType === ActionModalType.EditMode && docTypesToUpdate.length > 0) ||
      (props.actionType === ActionModalType.DragMode && hierarchyChanges.length > 0)) {
      setDisplayedPopup(PopupType.Discard);
    } else {
      closeDetailsActionView();
    }
  }

  const returnHierarchyData = (documents: APIDocumentContainer[]): MinimalHierarchy => {
    const topDocument = findTopFile(documents);
    let data: MinimalHierarchy = {};
    const addedIds: number[] = [];

    if (topDocument) {
      data = {
        id: topDocument.id,
        children: []
      };

      topDocument.children.forEach((document, index) => {
        const id = document.id > 0 ? document.id : -1;
        if (id === -1 || (!data.children?.some((child) => child.id === id) && !addedIds.includes(id))) {
          data.children?.push({ id, children: [] });
          addedIds.push(id);
        }

        if (document.children) {
          let removeRepeatedDoc;
          document.children.forEach((dChild) => {
            if (data.children) {
              const id = dChild.id > 0 ? dChild.id : -1;
              removeRepeatedDoc = data.children.findIndex((f) => {
                console.log(f.id, dChild.id);
                return f.id === dChild.id;
              });
              data.children[index]?.children?.push({ id, children: [] });
              addedIds.push(id);
            }
          });
          if (removeRepeatedDoc && removeRepeatedDoc > 0 && data.children) {
            data.children.splice(removeRepeatedDoc, 1);
          }
        }
      });
    }

    return data;
  }


  const saveChanges = async () => {
    setDisplayedPopup(undefined);
    if (props.details?.id) {
      let reparseUpdates = false;
      props.setShowOverlay(true);
      if (props.actionType === ActionModalType.SplitMode) {
        const response = await DocumentGroupAPI.splitGroup(props.details.id, newGroup.map((file) => file.id));

        if (response === null) props.setNotification({ ...APIError, details: { groupId: props.details.id }, reason: `Error on split group request, returned null` });
        else props.setNotification(Split);
      } else if (props.actionType === ActionModalType.EditMode) {
        const promises: Promise<unknown>[] = [];
        props.details.documents.forEach((d) => {
          let found = docTypesToUpdate.find((dttu) => dttu.documentId === d.id);
          if (found) {
            const data: APISplitDetails[] = [{
              pages: d.filePages || [],
              type: found.newType || DocumentType.Undefined,
            }];

            promises.push(DocumentContainerAPI.splitAndUpdateDocument(d.id, data));
          }
        });
        const results = await Promise.all(promises);
        if (results.filter((r: any) => r.updatedFiles.length > 0).length > 0) {
          reparseUpdates = true;
        }
        if (results.filter((r: any) => r.groupsToReValidate.length > 0).length > 0) {
          await new Promise((resolve) => {
            if (props.details && props.details.id) props.waitForValidationToComplete(props.details.id, null, resolve);
          });
        }
      } else if (props.actionType === ActionModalType.DragMode) {
        let response = null;
        if (details) {
          const data = returnHierarchyData(details.documents);
          response = await DocumentGroupAPI.createHierarchy(props.details.id, data);
        }
        if (response === null) props.setNotification({ ...APIError, details: { groupId: props.details.id }, reason: `Error on hierarchy update/creation, request returned null` });
      }

      setNewGroup([]);
      setSelectedFiles([]);
      setDocTypesToUpdate([]);

      const hierarchyIsDeleted = !(await DocumentGroupAPI.hasHierarchy(props.details.id));
      if (reparseUpdates && hierarchyIsDeleted) {
        props.closeDetails();
      } else {
        await props.reloadDetailsForGroup(props.details.id, true);
      }
      props.setShowOverlay(false);
      props.setShowActionsModal(ActionModalType.Hidden);
    }
  }

  const splitGroup = () => {
    if (props.details) {
      const newFiles: APIDocumentContainer[] = [];
      flatDocuments.forEach((file) => {
        if (selectedFiles.includes(file.id)) {
          newFiles.push(file);
        }
      });

      setNewGroup(newFiles);
    }
  }

  const hasMblChange = (changes: DocumentTypesToUpdate[]): boolean => {
    const hasMblChange = changes.some((d) => [d.newType, d.oldType].includes(DocumentType.MasterBillOfLading));
    return hasMblChange
  }

  const saveButtonText = props.actionType === ActionModalType.SplitMode ? 'Split' : 'Save';
  const isInitialSplit = props.actionType === ActionModalType.SplitMode && !Boolean(newGroup.length);

  return (
    <>
      <DocumentActionModal
        activeDocument={activeDocument}
        setNotification={props.setNotification}
        confirmButtonText={isInitialSplit ? 'Select' : saveButtonText}
        disabledConfirmRules={(isInitialSplit && !Boolean(selectedFiles.length)) || (props.actionType === ActionModalType.EditMode && !Boolean(docTypesToUpdate.length))}
        onConfirmAction={isInitialSplit ? splitGroup : onSaveAction}
        onDiscardAction={onDiscardAction}
        title={actionDescription}
        hideOverlay={true}
        hasChanges={!!hierarchyChanges.length || !!newGroup.length || !!docTypesToUpdate.length}
      >
        <div className="detail-file-action">
          <FileSorter
            details={details}
            setDetails={setDetails}
            issuerDetails={props.issuerDetails}
            documentTypes={props.documentTypes}
            activeDocument={activeDocument}
            actionType={props.actionType}
            setActiveDocument={setActiveDocument}
            displayActions={true}
            selectedTypeUpdates={docTypesToUpdate}
            setDocTypesToUpdate={setDocTypesToUpdate}
            newGroup={newGroup}
            setSelectedFiles={setSelectedFiles}
            isDragDisabled={props.actionType !== ActionModalType.DragMode}
            setHierarchyChanges={setHierarchyChanges}
            hierarchyChanges={hierarchyChanges}
            editMode={props.actionType === ActionModalType.EditMode}
          />
        </div>
      </DocumentActionModal>
      <ConfirmModal
        show={displayedPopup === PopupType.Save}
        title={getSaveTitleText(docTypesToUpdate)}
        text={getSaveText(docTypesToUpdate)}
        onConfirm={saveChanges}
        onHide={() => setDisplayedPopup(undefined)}
        confirmText={getConfirmButtonText(docTypesToUpdate)}
        cancelText={getCancelButtonText(docTypesToUpdate)}
      />
      <ConfirmModal
        show={displayedPopup === PopupType.Discard}
        title="Discard Changes"
        text={getCancelText()}
        onConfirm={closeDetailsActionView}
        onHide={() => setDisplayedPopup(undefined)}
        confirmText="Discard Changes & Exit"
        cancelText="Continue Editing"
      />
    </>
  );
}
