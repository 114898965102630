import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Notification } from '../common/Notification';
import { APWorkflowSection } from './sections/workflows/ap-reconciliation';
import { OrganisationsImportsSection } from './sections/organisations-imports';
import { UsersSection } from './sections/users';
import { CargowiseIntegrationSection } from './sections/cargowise-integration';
import { AdminNavigation, AdminNavigationLink } from './AdminNavigation';
import { EmailAlertsSection } from './sections/email-alerts';
import { OptionValue } from 'react-selectize';
import { SitesSection } from './sections/sites';
import { TeamsSection } from './sections/teams';
import { KpiTargetsSection } from './sections/kpi-targets';
import { ProductDataSection } from './sections/product-data-imports';
import { CargowiseUserLoginSection } from './sections/cargowise-user-login';
import { PermissionProfilesSection } from './sections/permission-profiles';
import { AutomatedTolerancesSection } from './sections/automated-tolerances';
import { ContainerDataSection } from './sections/container-data-imports';
import { APIKeyManagementSection } from './sections/api-key-management';
import { WebhookSection } from './sections/webhooks';
import { APIUser } from '../../api/comment';

import './admin.scss';
import { ForwardingAndClearanceWorkflowSection } from './sections/workflows/forwarding-and-clearance';

export enum AdminLinks {
  Sites = '/admin/sites',
  Teams = '/admin/teams',
  Users = '/admin/users',
  AutomatedTolerances = '/admin/automated-tolerances',
  EmailAlerts = '/admin/email-alerts',
  KpiTargets = '/admin/kpi-targets',
  ApReconciliationWorkflows = '/admin/workflows/ap-reconciliation',
  ForwardingAndClearanceWorkflows = '/admin/workflows/forwarding-and-clearance',
  OrganisationsImports = '/admin/organisations-imports',
  ProductDataImports = '/admin/product-data-imports',
  ContainerDataImports = '/admin/container-data-imports',
  CargowiseIntegration = '/admin/cargowise-integration',
  CargowiseUserLogin = '/admin/cargowise-user-login',
  PermissionProfiles = '/admin/permission-profiles',
  APIKeyManagement = '/admin/api-key-management',
  Webhooks = '/admin/webhooks',
}

interface Props {
  currencyOptions: OptionValue[];
  countryOptions: OptionValue[];
  setNotification: (notification: Notification | null) => void;
  showCargoWiseSettings: boolean;
  refetchUserDetails: () => void;
  users: APIUser[];
}

export const Admin: React.FC<Props> = (props) => {

  const groups = React.useMemo(() => [
    {
      label: 'Users & Teams',
      links: [
        {
          path: AdminLinks.Sites,
          label: 'Sites',
          component: () => <SitesSection />
        },
        {
          path: AdminLinks.Teams,
          label: 'Teams',
          component: () => <TeamsSection />
        },
        {
          path: AdminLinks.Users,
          label: 'Users',
          component: () => <UsersSection refetchUserDetails={props.refetchUserDetails} />
        },
        {
          path: AdminLinks.PermissionProfiles,
          label: 'Permission profiles',
          component: () => <PermissionProfilesSection refetchUserDetails={props.refetchUserDetails} />
        },
      ]
    },
    {
      label: 'Workflow',
      links: [
        {
          path: AdminLinks.ApReconciliationWorkflows,
          label: 'AP reconciliation',
          component: () => <APWorkflowSection countryOptions={props.countryOptions} currencyOptions={props.currencyOptions} />
        },
        {
          path: AdminLinks.ForwardingAndClearanceWorkflows,
          label: 'Forwarding & Clearance',
          component: () => <ForwardingAndClearanceWorkflowSection countryOptions={props.countryOptions} />
        },
      ],
    },
    {
      label: 'Integration',
      links: [
        {
          path: AdminLinks.CargowiseIntegration,
          label: 'CargoWise integration',
          component: () => <CargowiseIntegrationSection />
        },
        {
          path: AdminLinks.CargowiseUserLogin,
          label: 'CargoWise user login',
          component: () => <CargowiseUserLoginSection />
        },
        {
          path: AdminLinks.APIKeyManagement,
          label: 'API Key Management',
          component: () => <APIKeyManagementSection users={props.users} />
        },
        {
          path: AdminLinks.Webhooks,
          label: 'Webhooks',
          component: () => <WebhookSection setNotification={props.setNotification} />
        },
      ]
    },
    {
      label: 'Reference Data',
      links: [
        {
          path: AdminLinks.OrganisationsImports,
          label: 'Organisations',
          component: () => <OrganisationsImportsSection setNotification={props.setNotification} />
        },
        {
          path: AdminLinks.ProductDataImports,
          label: 'Product Codes',
          component: () => <ProductDataSection setNotification={props.setNotification} />
        },
        {
          path: AdminLinks.ContainerDataImports,
          label: 'Container data',
          component: () => <ContainerDataSection setNotification={props.setNotification} />
        },
      ]
    },
    {
      label: 'Exception Management',
      links: [
        {
          path: AdminLinks.AutomatedTolerances,
          label: 'AP tolerances',
          component: () => <AutomatedTolerancesSection />
        },
        {
          path: AdminLinks.EmailAlerts,
          label: 'AP email alerts',
          component: () => <EmailAlertsSection setNotification={props.setNotification} />
        },
        {
          path: AdminLinks.KpiTargets,
          label: 'AP KPI targets',
          component: () => <KpiTargetsSection />
        }
      ]
    }
  ], [props.currencyOptions, props.countryOptions]);

  return (
    <div className="admin">
      <AdminNavigation groups={groups} />
      <div className="admin__content">
        <Switch>
          {groups.reduce((links, group) => ([...links, ...group.links]), [] as AdminNavigationLink[]).map((link, linkIndex) => (
            <Route path={link.path} component={link.component} key={linkIndex} />
          ))}
        </Switch>
      </div>
    </div>
  )
}
