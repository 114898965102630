import * as React from 'react';
import { CompanyAPI } from '../../../api/company';
import { ChartColors, ChartType, SerieSettings } from '../common';
import { ChartCommonProps, ChartFilterProps } from '../ChartWrapper';
import { GeneralChart } from '../GeneralChart';
import { Map2 } from '../../helpers';
import * as moment from 'moment';

const settings: { [key: string]: { leftAxisLabel: string, tooltip: string } } = {
  [ChartType.AgeOfOldest]: {
    leftAxisLabel: 'Age (days)',
    tooltip: 'Age of oldest (days)'
  },
  [ChartType.InvoiceCount]: {
    leftAxisLabel: 'Invoice count',
    tooltip: 'Invoices'
  },
}

interface Props extends ChartCommonProps {
  apiFilter: ChartFilterProps
}

export const CinvCountChart: React.FC<Props> = (props) => {
  const [disableCsvButton, setDisableCsvButton] = React.useState<boolean>(false);

  const seriesData = new Map2<string, SerieSettings>([
    [
      'count',
      {
        color: ChartColors.Color1,
        label: settings[props.apiFilter.chartType].tooltip
      }
    ]
  ]);

  const fetchChartData = async () => {
    const result: any[] = await CompanyAPI.getGroupsInsightsChartData(props.apiFilter);
    if (result.reduce(((acc, curr) => acc + curr.total), 0) === 0) setDisableCsvButton(true);
    else setDisableCsvButton(false);
    return result.map((data) => ({
      name: data.aggregation,
      count: props.apiFilter.chartType === ChartType.InvoiceCount ? data.total : data.values[0]?.value
    })) || [];
  }

  return (
    <GeneralChart
      apiFilter={props.apiFilter}
      seriesData={seriesData}
      fetchChartData={fetchChartData}
      referenceData={props.referenceData}
      isFetching={props.isFetching}
      setIsFetching={props.setIsFetching}
      counterType={props.counterType}
      groupMode="stacked"
      leftAxisSettings={{
        axisLegend: settings[props.apiFilter.chartType].leftAxisLabel,
        axisFormat: (e) => {
          if (Math.floor(+e) !== e) return '';
          return String(e);
        },
      }}
      setNotification={props.setNotification}
      renderForCustomDashboard={props.renderForCustomDashboard}
      renderAddToButton={props.renderAddToButton}
      permissionLevel={props.permissionLevel}
      customDashboards={props.customDashboards}
      insightsSettings={props.insightsSettings}
      chartUserSettings={props.chartUserSettings}
      renderExportButton={props.apiFilter.chartType === ChartType.InvoiceCount}
      exportFilename={`Shipamax_Count_Report_${moment().format('DDMMYYYY')}.csv`}
      exportType='cinv'
      disableCsvExport={disableCsvButton}
      customPageSize={props.customPageSize}
      productSection={props.productSection}
    />
  )
}
