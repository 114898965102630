import * as React from 'react';
import { FAIcon } from './FAIcon';

import './radio-button.scss';

interface Props {
  checked?: boolean;
  onChange: (value: boolean) => void;
  asCheckbox?: boolean;
  disabled?: boolean;
  locked?: boolean;
  label?: string;
}

export const RadioButton: React.FC<Props> = (props) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Spacebar', ' '].includes(event.key)) {
      props.onChange(!props.checked);
      event.preventDefault();
      event.stopPropagation();
    }
  }

  const classNames = [
    'custom-radio-button',
    props.checked ? 'checked' : '',
    props.asCheckbox ? 'custom-radio-button--checkbox-style' : '',
    props.disabled || props.locked ? 'custom-radio-button--disabled' : ''
  ];

  return (
    <div
      className={classNames.join(' ')}
      onClick={() => props.onChange(!props.checked)}
      onKeyPress={handleKeyPress}
      tabIndex={0}
    >
      {props.locked ? <FAIcon name="lock" solid className="custom-radio-button__lock"/> : <span className="custom-radio-button__checkbox" />}
      <input type="radio" checked={props.checked} onChange={() => props.onChange(!props.checked)} />
      {props.label && (
        <label className="active-on-hover">{props.label}</label>
      )}
    </div>
  )
}