import { request } from './request';
import { APIIssueCode } from './issueCode';

export interface APIValidationIssueCode {
  id?: number;
  validationResultId: number;
  issueCodeId: number;
  description: string;
  documentGroupId: number;
}

export const ValidationIssueCodeAPI = {

  async createCodes(codes: APIIssueCode[], id: number, documentGroupId: number) {
    const codesToInsert = codes.map((code) => { return { issueCodeId: code.id, description: code.description, validationResultId: id, documentGroupId } });

    const promises: Promise<APIValidationIssueCode>[] = [];
    codesToInsert.forEach((code) => promises.push(request(`/ValidationIssueCodes`, 'POST', code)));

    return Promise.all(promises);
  },

  async deleteCodesForDocumentGroup(id: number) {
    const filter = { documentGroupId: id };
    return request(`/ValidationIssueCodes?where=${encodeURIComponent(JSON.stringify(filter))}`, 'DELETE');
  }
}
