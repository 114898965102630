import { PAGE_SIZE, SeriesSettings, SerieToggleState } from './common';

export const returnDisabledTogglesGivenChartData = (chartData: object[], toggles: SerieToggleState, offset = 0) => {
  const keysWithResults: Set<string> = new Set();

  chartData.forEach((data: any) => {
    Object.keys(toggles)
      .forEach((key) => {
        if (data[key] !== undefined && data[key] !== (0 + offset)) {
          keysWithResults.add(key);
        }
      });
  });

  const result = { ...toggles };
  Object.keys(result).forEach((key: any) => {
    result[key] = false;
  });
  keysWithResults.forEach((key) => {
    result[key] = true;
  });

  return result;
}

export interface ColorsMapType {
  [key: string]: string
}

export const getColorsMap = (serieData: SeriesSettings): ColorsMapType => {
  return serieData.keysArray().reduce((map, key) => ({ ...map, [key]: serieData.get(key)?.color }), {});
}

export const getChartDataForPage = (chartData: any[], pageSize: number, pageIndex: number, fromEnd: boolean = false): any[] => {
  if (!fromEnd) {
    return chartData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
  }

  /*
    for pagination from end we want to display the last PAGE_SIZE results at first, we need to count them differently
    if we have 35 results, in normal pagination the results for last index will be from PAGE_SIZE + 1 to 35.
    In this pagination from end we need to display rows from (35 - PAGE_SIZE + 1) to 35
   */

  const remainder = (chartData.length % pageSize) || pageSize;
  const firstIndex = ((pageIndex - 1) * pageSize) + remainder;
  const secondIndex = (pageIndex * pageSize) + remainder;

  return chartData.slice(firstIndex >= 0 ? firstIndex : 0, secondIndex);
};

export const numFormatter = (num: number): string => {
  const rules = {
    [Math.pow(10, 3)]: 'K',
    [Math.pow(10, 6)]: 'M',
    [Math.pow(10, 9)]: 'B',
  };

  let result;

  Object.keys(rules).forEach((ruleKey) => {
    const threshold = parseInt(ruleKey);
    if (Math.abs(num) >= threshold) result = (num / threshold) + rules[threshold];
  });

  return result || num.toString();
}

export const removeDataForSaveToggles = (keys: { [k: string]: number }, options: SerieToggleState) => {
  for (const key in options) {
    if (keys[key] && options[key] === false) {
      delete keys[key];
    }
  }

  return keys;
}
