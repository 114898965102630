import { APIDocCoordinate, CoordinatesFilter, APITableDefinitions } from '../api/documentContainer';
import * as React from 'react';

interface DocumentViewerContextProps {
  focusedCoordinates: APIDocCoordinate[];
  setFocusedCoordinates: (focusedCoordinates: APIDocCoordinate[]) => void;
  removeCoordinates: (coordinatesFilter: CoordinatesFilter) => void;
  setAllCoordinates: (coordinates: { [id: string]: APIDocCoordinate[] }) => void;
  setCoordinates: (coordinates: APIDocCoordinate[]) => void;
  coordinates: APIDocCoordinate[];
  allCoordinates: { [id: string]: APIDocCoordinate[] };
  allTableCoordinates: { [id: string]: APITableDefinitions };
  tableCoordinates: APITableDefinitions;
}

export const DocumentViewerContext: React.Context<DocumentViewerContextProps> = React.createContext({
  focusedCoordinates: [] as APIDocCoordinate[],
  setFocusedCoordinates: (focusedCoordinates) => { },
  removeCoordinates: (coordinatesFilter: CoordinatesFilter) => { },
  setAllCoordinates: (coordinates) => { },
  setCoordinates: (coordinates) => { },
  coordinates: [] as APIDocCoordinate[],
  allCoordinates: {} as { [id: string]: APIDocCoordinate[] },
  allTableCoordinates: {} as { [id: string]: APITableDefinitions },
  tableCoordinates: [] as APITableDefinitions
});

export class OldContext<T> {
  private currentContext: T;
  public value: T;

  constructor(context: T) {
    this.currentContext = context
    this.value = { ...context }
  }
  update() {
    this.value = { ...this.currentContext }
  }
  isOutdated() {
    return JSON.stringify(this.value) !== JSON.stringify(this.currentContext)
  }
}
