import * as React from 'react';

interface Props {
  headline: string;
  tagText?: string;
  links?: {
    label: string;
    url: string;
  }[];
}

export const AdminHeader: React.FC<Props> = (props) => (
  <div className="admin__content__header">
    <h1>{props.headline}</h1>
    {props.tagText && <span className="admin__content__header__tag">{props.tagText}</span>}
    <div className="admin__content__header__links">
      {props.links?.map((link, index) => (
        <a key={index} href={link.url} target="__blank">{link.label}</a>
      ))}
    </div>
  </div>
)