import * as React from 'react';
import { hasOverlap, parseNumber } from '../helpers';
import { APIDocCoordinate, FieldData, findMatchedCoordinates } from '../../api/documentContainer';
import { DocumentViewerContext } from '../DocumentViewerContext';
import { FAIcon } from './FAIcon';
import { FieldValidator } from './Form';

import './number-input.scss';

const THOUSANDS_SEPARATOR = (10000).toLocaleString(navigator.language).replace(/\d/g, '');
const DECIMALS_SEPARATOR = (1.1).toLocaleString(navigator.language).replace(/\d/g, '');
const IS_DEFAULT_SEPARATOR = DECIMALS_SEPARATOR === '.';

interface PrecisionSettings {
  precision?: number;
  minPrecision?: number;
  ignorePrecision?: boolean;
  minimumIntegerDigits?: number;
}

export const formatNumber = (value: number | null, precisionSettings: PrecisionSettings): string => {
  const settings: Intl.NumberFormatOptions = precisionSettings.ignorePrecision ? {
    style: 'decimal',
    maximumFractionDigits: 4,
    minimumIntegerDigits: precisionSettings.minimumIntegerDigits,
  } : {
    style: 'decimal',
    maximumFractionDigits: precisionSettings.precision,
    minimumFractionDigits: precisionSettings.minPrecision ?? precisionSettings.precision,
    minimumIntegerDigits: precisionSettings.minimumIntegerDigits,
  };
  return (value || value === 0) ? Number(value).toLocaleString(navigator.language, settings) : '';
}

export const formatNumberToMaxVisibleLength = (value: string | undefined, maxVisibleLength: number): string => {
  if (value === undefined) {
    return '';
  }

  return value.length > maxVisibleLength ? value.substr(0, maxVisibleLength) + '...' : value;
}

interface OwnProps {
  value: number | null;
  name: string;
  precision?: number;
  minPrecision?: number;
  unit?: string | JSX.Element;
  setter?: (value: number) => void;
  onChange?: (value: number, name: string) => void;
  onBlur?: (name: string, value: number | null, withoutChange: boolean) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  ignorePrecision?: boolean;
  fieldData?: FieldData;
  tabIndex?: number;
  maxVisibleLength?: number;
  autoSelect?: boolean;
  required?: boolean;
  hasErrors?: boolean;
  onFocus?: () => void;
  minimumIntegerDigits?: number;
  showWarningIcon?: boolean;
  suffix?: string;
  validators?: FieldValidator[];
  formEntered?: boolean;
}

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value' | 'onBlur' | 'onFocus'> & OwnProps;

export const NumberInput = React.forwardRef((props: Props, ref) => {
  let inputRef: HTMLInputElement | null;
  const [prevValue, setPrevValue] = React.useState<number | null>(null);
  const [renderedValue, setRenderedValue] = React.useState<string | undefined>(undefined);
  const [isChanged, setIsChanged] = React.useState<boolean>(false);
  const [selected, setIsSelected] = React.useState<boolean>(false);

  const documentViewerContext = React.useContext(DocumentViewerContext);

  const matchedCoordinates = findMatchedCoordinates(props.fieldData || null, documentViewerContext.coordinates) || null;

  let hasException = false;

  if (props.min !== undefined && props.value !== null && props.min > props.value) hasException = true;
  if (props.max !== undefined && props.value !== null && props.max < props.value) hasException = true;
  if (props.required && props.value === undefined) hasException = true;

  React.useEffect(() => {
    if (prevValue !== props.value && !isNaN(Number(props.value)) && (document.activeElement !== inputRef || renderedValue === undefined)) {
      formatValue();
      setPrevValue(props.value);
    }
  }, [props.value]);

  React.useEffect(() => {
    if (props.fieldData) {
      if (hasOverlap<APIDocCoordinate>(matchedCoordinates, documentViewerContext.focusedCoordinates)) {
        if (inputRef) {
          inputRef.focus();
          inputRef.selectionStart = inputRef.value.length;
          inputRef.selectionEnd = inputRef.value.length;
        }
      }
    }
  }, [documentViewerContext.focusedCoordinates]);

  React.useImperativeHandle(ref, () => ({
    focus() {
      if (inputRef) {
        inputRef.focus();
        inputRef.selectionStart = inputRef.value.length;
        inputRef.selectionEnd = inputRef.value.length;
      }
    }
  }));

  const formatValue = () => {
    let formattedValue = formatNumber(props.value, {
      precision: props.precision ?? 2,
      minPrecision: props.minPrecision ?? props.precision ?? 2,
      ignorePrecision: props.ignorePrecision,
      minimumIntegerDigits: props.minimumIntegerDigits,
    });

    if (document.activeElement === inputRef) {
      formattedValue = formattedValue.replace(new RegExp(THOUSANDS_SEPARATOR, 'g'), '');
    } else if (props.maxVisibleLength) {
      formattedValue = formatNumberToMaxVisibleLength(formattedValue, props.maxVisibleLength + 1); // increase by 1 to include the decimals separator
    }

    setRenderedValue(formattedValue);
  }

  const onBlur = () => {
    // If the input field displays something different than whats coming from props
    // when leaving the field, update it so it shows the props value.
    formatValue();

    if (hasException) return;

    isChanged && props.fieldData && documentViewerContext.removeCoordinates({
      parentTableName: props.fieldData.tableName,
      parentTableIds: props.fieldData.objectIds,
      attributeNames: props.fieldData.attributeNames
    });
    documentViewerContext.setFocusedCoordinates([]);
    props.onBlur && props.onBlur(props.name, props.value, !isChanged);
    setIsChanged(false);
  }

  const onFocus = () => {
    matchedCoordinates && documentViewerContext.setFocusedCoordinates(matchedCoordinates);
    formatValue();
    if (props.onFocus) props.onFocus();
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.disabled) {
      return;
    }

    let value = event.target.value;

    if (parseNumber(value) !== props.value) {
      let num = null;
      if (value !== null) {
        if (props.ignorePrecision) {
          num = Number(parseNumber(value));
        } else {
          const precision = props.precision ?? 2;
          const minPrecision = props.minPrecision ?? precision;

          const decimalPart = value.split(DECIMALS_SEPARATOR)[1];
          if (value && decimalPart && decimalPart.length > precision) {
            num = Math.round((parseNumber(value) + Number.EPSILON) * Math.pow(10, parseNumber(precision))) / Math.pow(10, parseNumber(precision));
          } else if (value && decimalPart && (decimalPart.length < minPrecision)) {
            num = Number(parseNumber(value).toFixed(parseNumber(minPrecision)));
          } else {
            num = Number(parseNumber(value));
          }
        }
      }

      if (num !== null) {
        if (num != props.value) { // we received the value from API as string, therefore no type control
          setIsChanged(true);
        }

        if (props.onChange) {
          props.onChange(num, props.name);
        }

        if (props.setter) {
          props.setter(num);
        }
      }
    }

    if (!value) {
      value = '';
    }

    setRenderedValue(value);
  }

  const setRef = (input: HTMLInputElement) => {
    if (props.autoSelect) autoSelect(input);
    inputRef = input;
  }

  const autoSelect = (input: HTMLInputElement) => {
    if (!selected && input?.value) {
      if (input.value.length > 1) input.select();
      setIsSelected(true);
    }
  }

  const {
    className,
    setter,
    disabled,
    maxVisibleLength,
    minPrecision,
    fieldData,
    ignorePrecision,
    formEntered,
    ...rest
  } = props;

  const triggeredValidator = props.formEntered ? props.validators?.find((validator) => {
    if (!validator.isValid(props.value)) {
      return true;
    }
  }) : null;

  const containerCssClasses = [
    'number-input__container',
    className,
    (hasException && props.showWarningIcon) ? 'number-input__container--with-warning-icon' : '',
    ((props.required && props.value === null && props.fieldData?.objectIds.find((id) => id !== -1)) || props.hasErrors || triggeredValidator) ? 'number-input__container--highlight' : '',
  ];

  const inputCssClasses = [
    'number-input',
    props.disabled ? 'number-input--disabled' : '',
  ];

  return (
    <div className={`number-input__wrapper ${props.unit ? 'number-input__wrapper--with-unit' : ''}`}>
      <div className={containerCssClasses.join(' ')}>
        {hasException && props.showWarningIcon && (
          <FAIcon name="exclamation-triangle" solid />
        )}
        <input
          ref={setRef}
          className={inputCssClasses.join(' ')}
          type="text"
          {...rest}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={props.onKeyDown}
          value={renderedValue}
        />
        {props.suffix && (
          <span className="number-input__suffix">{props.suffix}</span>
        )}
      </div>
      {props.unit && <span className="unit">{props.unit}</span>}
      {triggeredValidator?.errorMessage && (
        <div className="form-field__error">
          {triggeredValidator.errorMessage}
        </div>
      )}
    </div>
  );
});
