import * as React from 'react';
import { FAIcon } from '../../common/FAIcon';
import { PAGE_SIZE } from '../common';

import './pagination.scss';

export const getLastPageIndex = (dataLength: number, pageSize: number) => Math.floor(dataLength / pageSize) - (dataLength % pageSize ? 0 : 1) // subtract by 1 if the length is PAGE_SIZE*X

interface Props {
  customPageSize?: number;
  pageIndex: number;
  setPageIndex: (index: number) => void;
  lastPageIndex: number;
}

export const Pagination: React.FC<Props> = ({ lastPageIndex, pageIndex, setPageIndex, customPageSize }) => {
  const isPrevDisabled = pageIndex === 0;
  const isNextDisabled = pageIndex === lastPageIndex;
  const pageSize = customPageSize || PAGE_SIZE;

  const handleKeyDown = (e: KeyboardEvent) => {
    const ignoreArrowKeys = document.activeElement?.tagName === 'INPUT' || document.activeElement?.closest('.dropdown');

    if (ignoreArrowKeys) {
      return;
    }

    if (e.key === 'ArrowLeft') {
      !isPrevDisabled && setPageIndex(pageIndex - 1);
    } else if (e.key === 'ArrowRight') {
      !isNextDisabled && setPageIndex(pageIndex + 1);
    }
  }

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <div className="option-group pagination-controls-left">
        <button
          className="option"
          disabled={isPrevDisabled}
          onClick={() => setPageIndex(0)}
          title={`First ${pageSize} results`}
        >
          <FAIcon name="angle-double-left" solid />
        </button>
        <button
          className="option"
          disabled={isPrevDisabled}
          onClick={() => setPageIndex(pageIndex - 1)}
          title={`Previous ${pageSize} results`}
        >
          <FAIcon name="angle-left" solid />
        </button>
      </div>
      <div className="option-group pagination-controls-right">
        <button
          className="option"
          disabled={isNextDisabled}
          onClick={() => setPageIndex(pageIndex + 1)}
          title={`Next ${pageSize} results`}
        >
          <FAIcon name="angle-right" solid />
        </button>
        <button
          className="option"
          disabled={isNextDisabled}
          onClick={() => setPageIndex(lastPageIndex)}
          title={`Last ${pageSize} results`}
        >
          <FAIcon name="angle-double-right" solid />
        </button>
      </div>
    </>
  );
}
