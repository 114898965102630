import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { AutomatedToleranceTable } from './AutomatedToleranceTable';
import { AutomatedAccrualToleranceForm } from './AutomatedAccrualToleranceForm';
import { AutomatedXRToleranceForm } from './AutomatedXRToleranceForm';
import { OptionValue } from 'react-selectize';
import { SiteAPI } from '../../../../api/sites';

import './index.scss';

interface Props {
}

export const AutomatedTolerancesSection: React.FC<Props> = (props) => {
  const [siteOptions, setSiteOptions] = React.useState<OptionValue[]>([]);

  React.useEffect(() => {
    fetchReferenceDataFromAPI();
  }, []);

  const fetchReferenceDataFromAPI = async () => {
    const [sites] = await Promise.all([SiteAPI.fetchAll()]);
    setSiteOptions(sites.map((site) => ({ label: site.name, value: site.id })));
  }

  return (
    <Switch>
      <Route path={AdminLinks.AutomatedTolerances} exact component={() => (
        <AutomatedToleranceTable siteOptions={siteOptions} />
      )} />
      <Route path={[AdminLinks.AutomatedTolerances + '/xr-tolerance-form/:id']} exact component={() => (
        <AutomatedXRToleranceForm
        />
      )} />
      <Route path={[AdminLinks.AutomatedTolerances + '/accrual-tolerance-form/:id']} exact component={() => (
        <AutomatedAccrualToleranceForm
        />
      )} />
    </Switch>
  )
}
