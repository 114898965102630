import { request } from './request';
import { AdminGridSearchParams } from '../components/admin/AdminGrid';

export interface APIPermissionProfileToleranceThreshold {
  id?: number;
  permissionProfileId?: number;
  currency?: string;
  upperAbsoluteMax: number;
  lowerAbsoluteMax: number;
  upperPercentageMax: number;
  lowerPercentageMax: number;
}

export interface APIPermissionProfileDocumentVisibilities {
  id?: number;
  permissionProfileId?: number;
  teamId?: number;
  removedByUser?: boolean;
}

export const TOLERANCE_WITHOUT_THRESHOLD = -1

export interface APIPermissionProfile {
  id: number;
  name: string;
  description: string;
  companyId: number;
  workspaceProduction: boolean;
  workspaceTest: boolean;
  accessAP: boolean;
  accessForwarding: boolean;
  accessInsights: boolean;
  accessWtgIntegration: boolean;
  postOverhead: boolean;
  postBulk: boolean;
  canViewOverhead: boolean;
  editAPHeader: boolean;
  discardAP: boolean;
  setSeparateLimit: boolean;
  toleranceThresholds: APIPermissionProfileToleranceThreshold[];
  permissionProfileDocumentVisibilities: APIPermissionProfileDocumentVisibilities[];
  limitDocumentVisibility: boolean;
}

export const PermissionProfileAPI = {
  async fetchAll(params?: AdminGridSearchParams): Promise<APIPermissionProfile[]> {
    const filter = JSON.stringify({
      where: {
        companyId: parseInt(localStorage.getItem('companyId') || ''),
        isHidden: false,
        ...(params?.searchTerm ? {
          and: [
            {
              or: [
                { name: { ilike: `%${params.searchTerm.toLowerCase()}%` } },
                { description: { ilike: `%${params.searchTerm.toLowerCase()}%` } },
              ]
            }],
        } : {}
        )
      },
      order: 'name',
      ...(params?.limit ? { limit: params.limit } : {}),
    });
    return request(`/PermissionProfiles?filter=${encodeURIComponent(filter)}`);
  },

  async fetch(id: number): Promise<APIPermissionProfile> {
    return request(`/PermissionProfiles/${id}?filter=${JSON.stringify({ include: ['toleranceThresholds', 'permissionProfileDocumentVisibilities'] })}`);
  },

  async update(id: number, change: Partial<APIPermissionProfile>): Promise<unknown> {
    return request(`/PermissionProfiles/${id}`, 'PATCH', change);
  },

  async create(values: Partial<APIPermissionProfile>): Promise<APIPermissionProfile> {
    return request(`/PermissionProfiles`, 'POST', values);
  },

  async delete(id: number, newProfileId: number | undefined): Promise<unknown> {
    return request(`/PermissionProfiles/${id}/deletePermissionProfile`, 'POST', { newProfileId });
  },

  async isFieldValueTaken(field: keyof APIPermissionProfile, value: string, siteId: number | undefined): Promise<boolean> {
    const companyId = parseInt(localStorage.getItem('companyId') || '');
    const filter = JSON.stringify({
      where: {
        companyId,
        [field]: value,
        ...(siteId ? { id: { neq: siteId } } : {}),
      },
    });

    const result = await request(`/PermissionProfiles?filter=${filter}`);
    return !!result.length;
  },

  async updateThreshold(id: number, change: Partial<APIPermissionProfileToleranceThreshold>): Promise<unknown> {
    return request(`/PermissionProfileToleranceThresholds/${id}`, 'PATCH', change);
  },

  async createThreshold(values: Partial<APIPermissionProfileToleranceThreshold>): Promise<APIPermissionProfileToleranceThreshold> {
    return request(`/PermissionProfileToleranceThresholds`, 'POST', values);
  },

  async createDocumentVisibility(values: Partial<APIPermissionProfileDocumentVisibilities>): Promise<APIPermissionProfileDocumentVisibilities> {
    return request(`/PermissionProfileDocumentVisibilities`, 'POST', values);
  },

  async deleteDocumentVisibility(id: number): Promise<unknown> {
    return request(`/PermissionProfileDocumentVisibilities/${id}`, 'DELETE');
  },
}
