import * as React from 'react';

import './admin-components.scss';

interface FormDividerProps {
  headline: string;
  style?: React.CSSProperties;
}

export const FormDivider: React.FC<FormDividerProps> = (props) => (
  <div className="admin__form__divider" style={props.style}>
    <h2>{props.headline}</h2>
  </div>
);

interface FormSubHeadlineProps {
  headline: string;
}

export const FormSubHeadline: React.FC<FormSubHeadlineProps> = (props) => (
  <div className="admin__form__sub-headline">
    <h3>{props.headline}</h3>
  </div>
);