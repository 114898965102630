import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { SiteForm } from './SiteForm';
import { SitesTable } from './SitesTable';

interface Props {

}

export const SitesSection: React.FC<Props> = () => {
  return (
    <Switch>
      <Route path={AdminLinks.Sites} exact component={() => (
        <SitesTable />
      )}/>
      <Route path={[AdminLinks.Sites + '/form', AdminLinks.Sites + '/form/:id']} exact component={() => (
        <SiteForm />
      )} />
    </Switch>
  )
};
