import * as React from 'react';
import { Notification } from './Notification';
import { DocumentViewerContainer } from './document-viewer/DocumentViewerContainer';
import { APIDocCoordinate, APIDocumentContainer } from '../../api/documentContainer';
import { APIEmailBasic } from '../../api/email';
import { useKeyPress } from '../helpers';

import './document-action-modal.scss';

interface Props {
  activeDocument: APIDocumentContainer | APIEmailBasic | undefined;
  confirmButtonText: string;
  disabledConfirmRules: boolean;
  setNotification: (notification: Notification | null) => void;
  onConfirmAction: () => void;
  onDiscardAction: () => void;
  title: string;
  description?: any;
  hideOverlay?: boolean;
  filterCoordinates?: (coordinate: APIDocCoordinate) => boolean;
  hasChanges?: boolean;
}

export enum ActionModalType {
  Hidden = 0,
  EditMode = 1,
  SplitMode = 2,
  DragMode = 3,
}

export const DocumentActionModal: React.FC<Props> = (props) => {
  useKeyPress('Escape', () => {
    const isDropdownOpen = document.activeElement?.closest('.react-selectize');

    if (!isDropdownOpen) {
      setTimeout(props.onDiscardAction, 20);
    }
  }, [props.hasChanges]);

  const [
    fileSplitterSettings,
    documentTypesOptions,
    onFileSplitFinished,
    setNotification
  ] = React.useMemo(() => [
    { hideFileSplitterButton: true },
    [],
    () => { },
    props.setNotification
  ], []);

  return (
    <div className="action-modal">
      <div className="pui-dialog-backdrop pui-dialog-show pui-modal-dialog-backdrop backdrop-actions" />
      <div className="detail-file-actions">
        <div className="viewer">
          <DocumentViewerContainer
            document={props.activeDocument}
            setNotification={setNotification}
            fileSplitterSettings={fileSplitterSettings}
            documentTypesOptions={documentTypesOptions}
            onFileSplitFinished={onFileSplitFinished}
            hideOverlay={props.hideOverlay}
            filterCoordinates={props.filterCoordinates}
          />
        </div>
        <div className="action-modal__right">
          <div className="action-modal__header">
            <div className="action-modal__title">{props.title}</div>
            {props.description && (
              <div className="action-modal__description">{props.description}</div>
            )}
          </div>
          <div className="action-modal__content">
            {props.children}
          </div>
          <div className="action-modal__footer">
            <button className="full-button btn-confirm" disabled={props.disabledConfirmRules} onClick={props.onConfirmAction}>{props.confirmButtonText}</button>
            <button className="light-button btn-cancel" onClick={props.onDiscardAction}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
}
