import * as React from 'react';
import { WrapperLabel } from '../../common/WrapperLabel';
import { TextInput } from '../../common/TextInput';
import { APIDocumentGroupConsol } from '../../../api/documentGroup';
import { BillOfLadingImporterReference } from '../../../api/billOfLadingImporterReference';
import { APIBillOfLadingImporterReference } from '../../../api/billOfLading';
import { FieldChangedParams, JobType } from '../common';

import './shipment.scss';
import { RecordValidator } from '../../common/Form';
import { checkIfRecordValidatorHasErrors } from './ErrorHelper';

interface Props {
  placeholder: APIDocumentGroupConsol;
  placeholderJobRef?: string;
  fieldsChanged: (changes: FieldChangedParams[]) => void;
  setPlaceholderJobRef: (value: string) => void;
  disabled: boolean;
  jobType: JobType;
  checkRepeatedJobRef: (docId: number, value: string | undefined) => boolean;
  addToJobRefNums: (docId: number, value: string) => void;
}

export const ShipmentPlaceholder: React.FC<Props> = (props) => {
  React.useEffect(() => {
    if (props.placeholder?.importerReference?.length) {
      props.setPlaceholderJobRef(props.placeholder.importerReference[0].jobRef);
    } else {
      props.setPlaceholderJobRef('');
    }
  }, [props.placeholder]);

  React.useEffect(() => {
    props.addToJobRefNums(props.placeholder.documentId!, props.placeholderJobRef || '');
  }, [props.placeholderJobRef]);

  const recordValidator: RecordValidator = {
    jobRef: {
      validators: [
        {
          errorMessage: 'Required field',
          isValid: () => !!props.placeholderJobRef
        },
        {
          errorMessage: 'Duplicated Shipment Job Reference',
          isValid: () => !props.checkRepeatedJobRef(props.placeholder.documentId, props.placeholderJobRef)
        },
      ]
    },
  };
  const recordValidatorHasErrors = checkIfRecordValidatorHasErrors(recordValidator);

  const update = async (name: string, value: string | null, withoutChange: boolean) => {
    if (withoutChange || !props.placeholder) {
      return;
    }

    const updatedImporterReference =
      await BillOfLadingImporterReference.updateJobRef(value, false, props.placeholder.id, props.placeholder.importerReference) as APIBillOfLadingImporterReference;
    let allReferences;

    if (updatedImporterReference.id &&
      props.placeholder?.importerReference &&
      props.placeholder?.importerReference.length > 0) {
      allReferences = props.placeholder.importerReference.map((ref) => {
        if (ref.id === updatedImporterReference.id) {
          return updatedImporterReference;
        } else {
          return ref;
        }
      });
    } else if (typeof updatedImporterReference == 'number' &&
      props.placeholder.importerReference) {
      allReferences = props.placeholder.importerReference.filter((ref) => ref.id !== updatedImporterReference);
    } else {
      allReferences = [updatedImporterReference];
    }
    props.fieldsChanged([{
      id: props.placeholder.id,
      fieldName: name,
      jobType: props.jobType,
      value: allReferences,
    }]);
  }

  return (
    <div className="shipment">
      <div className='invoice_icons'>
        {recordValidatorHasErrors && <span className='fas fa-exclamation-triangle icon-error'></span>}
      </div>
      <div className="grid__row">
        <div className="grid__col-4">
          <WrapperLabel text="Job Reference">
            <TextInput
              value={props.placeholderJobRef || null}
              name="jobRef" onBlur={update}
              setter={props.setPlaceholderJobRef}
              disabled={props.disabled}
              formEntered={true}
              validators={recordValidator.jobRef.validators} />
          </WrapperLabel>
        </div>
        <div className="grid__col-4" />
        <div className="grid__col-4" />
      </div>
    </div>
  );
}
