import * as React from 'react';
import { AdminHeader } from '../../AdminHeader';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { ButtonWithSpinner } from '../../../common/ButtonWithSpinner';
import { Notification } from '../../../common/Notification';
import { CustomSelect } from '../../../common/CustomSelect';
import { WrapperLabel } from '../../../common/WrapperLabel';
import { OptionValue } from 'react-selectize';
import { isFormInvalid, RecordValidator } from '../../../common/Form';
import { FileError } from '../product-data-imports';
import { TextInput } from '../../../common/TextInput';
import { APIWebhook, WebhookAPI } from '../../../../api/webhook';
import { Modal } from 'pivotal-ui/react/modal';

export const environmentOptions: OptionValue[] = [
  { label: 'Production', value: 1 },
  { label: 'Test', value: 2 },
];

interface Props {
  setNotification: (notification: Notification | null) => void;
  setFileErrors: (fileErrors: FileError[]) => void;
  fileErrors: FileError[];
}

export const WebhookForm: React.FC<Props> = (props) => {
  const [is, setIsSubmitting] = React.useState<boolean>(false);
  const [formEntered, setFormEntered] = React.useState<boolean>(false);
  const [webhook, setWebhook] = React.useState<Partial<APIWebhook> | undefined>(undefined);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState<boolean>(false);
  const [webhooksWithSameName, setWebhooksWithSameName] = React.useState<boolean>(false);
  const [showWebhookTest, setShowWebhookTest] = React.useState<boolean>(false);

  const history = useHistory();
  const params = useParams<{ id?: string }>();

  React.useEffect(() => {
    if (params.id) {
      WebhookAPI.getWebhookById(parseInt(params.id)).then((data) => {
        setWebhook(data);
      });
    } else {
      setWebhook({ environment: 1 });
    }
  }, [params.id]);

  React.useEffect(() => {
    if (webhook?.endpoint) {
      const debounceRequest = setTimeout(() => {
        WebhookAPI.getWebhooks({
          and: [
            { id: { neq: params.id ?? null } },
            { endpoint: webhook?.endpoint }
          ]
        }).then((webhooksWithSameName) => {
          setWebhooksWithSameName(webhooksWithSameName.length > 0);
        });
      }, 300);
      return () => clearTimeout(debounceRequest);
    }
  }, [webhook?.endpoint, 300]);

  const companyId = parseInt(localStorage.getItem('companyId') || '');

  const recordValidator: RecordValidator = {
    endpoint: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: () => webhook?.endpoint !== undefined
        },
        {
          errorMessage: 'Should begin with "https://"',
          isValid: () => webhook?.endpoint!.slice(0, 8) === 'https://'
        },
        {
          errorMessage: 'Invalid endpoint',
          isValid: () => {
            const pattern = /(https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))z?/g;
            const regex = new RegExp(pattern);
            return webhook?.endpoint!.match(regex) ? true : false;
          }
        },
        {
          errorMessage: 'This endpoint already exists',
          isValid: () => !webhooksWithSameName
        }
      ]
    },
    environment: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: () => webhook?.environment === 1 || webhook?.environment === 2
        },
      ]
    },
  }

  const onUpdate = (change: Partial<APIWebhook>) => {
    setWebhook((record) => ({ ...record!, ...change }));
  }

  const onDeleteClicked = () => {
    setShowDeleteConfirm(true);
  }

  const onDeleteCancelled = () => {
    setShowDeleteConfirm(false);
  }

  const isDisabled = isFormInvalid({
    endpoint: webhook?.endpoint,
    environment: webhook?.environment,
  }, recordValidator);

  const onSubmit = async () => {
    try {
      if (isDisabled) {
        setFormEntered(true);
        return;
      }

      setIsSubmitting(true);

      const webhookData = {
        companyId,
        endpoint: webhook?.endpoint,
        environment: webhook?.environment,
      };

      const result = params.id ?
        await WebhookAPI.updateWebhook(parseInt(params.id), webhookData) :
        await WebhookAPI.createWebhook(webhookData);

      setIsSubmitting(false);

      if (result.error) {
        props.setNotification({ type: 'error', value: `Request failed: ${result.error.message}` });
        return;
      } else {
        props.setNotification({ type: 'success', value: params.id ? 'Webhook Updated' : 'Webhook Added' });
        history.push(AdminLinks.Webhooks);
        return;
      }
    } catch (e) {
      props.setNotification({ type: 'error', value: 'Request failed' });
    }
  }

  const onDelete = async () => {
    setShowDeleteConfirm(false);

    if (params.id) {
      const result = await WebhookAPI.deleteWebhook(parseInt(params.id));
      console.log(result)
      if (result.error) {
        props.setNotification({ type: 'error', value: `Request failed: ${result.error.message}` });
      } else {
        props.setNotification({ type: 'success', value: 'Webhook Deleted' });
        history.push(AdminLinks.Webhooks);
      }
    }
  }

  const onWebhookTestClicked = async () => {
    setShowWebhookTest(true);
  }

  const onWebhookTestCancel = async () => {
    setShowWebhookTest(false);
  }

  const onWebhookTest = async () => {
    try {
      const result = await fetch(
        webhook?.endpoint!,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
          },
          body: JSON.stringify({
            kind: "#shipamax-webhook",
            eventName: "testEventName",
            payload: { fileGroupId: -1 },
            metadata: { key: "Email account metadata" }
          }),
        }
      )
      setShowWebhookTest(false);

      if (result.ok) {
        props.setNotification({ type: 'success', value: `Webhook test sent successfully` });
        return;
      }

      props.setNotification({ type: 'error', value: `Webhook test failed: status ${result.status}` });
      console.log(result, result.body, '--result--')
    } catch (error) {
      props.setNotification({ type: 'error', value: `Webhook test failed: Ensure your application allows CORS` });
      setShowWebhookTest(false);
      console.log(error, '--error--')
    }
  }

  return (
    <div className="admin__form__wrapper with-custom-scrollbar">
      <AdminHeader
        headline="Webhooks"
        links={
          [
            {
              url: '/knowledge-base/Webhooks--c1f3e045b3ed4be9a334f210f235ebad',
              label: 'What are webhooks?'
            },
          ]}
      />
      <div className="admin__form__content">
        <div>
          <WrapperLabel text="Webhook URL">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <TextInput
                value={webhook?.endpoint || ''}
                name="endpoint"
                setter={(value) => onUpdate({ endpoint: value })}
                validators={recordValidator.endpoint.validators}
                formEntered={formEntered}
              />
              {
                webhook?.endpoint && !isDisabled &&
                (<button
                  className="light-button"
                  onClick={onWebhookTestClicked}
                  style={{ margin: '0 10px', width: '110px', height: '30px' }}
                >
                  Test Endpoint
                </button>)
              }
            </div>
          </WrapperLabel>
          <WrapperLabel text="Environment">
            <CustomSelect
              options={environmentOptions}
              value={environmentOptions.find((option) => option.value === webhook?.environment)}
              onValueChange={(option) => onUpdate({ environment: option?.value })}
              validators={recordValidator.environment.validators}
              formEntered={formEntered}
            />
          </WrapperLabel>
        </div>
        <div>
          <div>
            {params.id && (
              <button className="full-button full-button--red" onClick={onDeleteClicked}>Delete Webhook</button>
            )}
            <Link to={AdminLinks.Webhooks}><button className="light-button" style={{ margin: '0 20px' }}>Cancel</button></Link>
            <ButtonWithSpinner
              className="full-button update-webhook-btn"
              onClick={onSubmit}
              showSpinner={is}
            >{params.id ? 'Update Webhook' : 'Add Webhook'}</ButtonWithSpinner>
            {params.id && (
              <Modal
                title="Delete webhook"
                size="280px"
                show={showDeleteConfirm}
                onHide={onDeleteCancelled}
                dialogClassName={`confirm-modal permission-profile-form__delete-modal`}
              >
                <p>Would you like to delete this Webhook?</p>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <button className="light-button" onClick={onDeleteCancelled}>Cancel</button>
                  <button className="full-button" onClick={onDelete} style={{ marginBottom: 0 }}>Delete Webhook</button>
                </div>
              </Modal>
            )}
            <Modal
              title="Test endpoint"
              size="480px"
              show={showWebhookTest}
              onHide={onWebhookTestCancel}
              dialogClassName={`confirm-modal permission-profile-form__delete-modal`}
            >
              <p>Would you like to send a test event to this endpoint? <br /> The following data will be sent: </p>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <p style={{ textAlign: 'left' }}>
                  <b>kind:</b> "#shipamax-webhook"<br />
                  <b>eventName:</b> "testEventName"<br />
                  <b>payload:</b> {`{ fileGroupId: -1 }`}<br />
                  <b>metadata:</b> {`{ key: "Email account metadata" }`}<br />
                  <br />
                </p>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <button className="light-button" onClick={onWebhookTestCancel}>Cancel</button>
                <button className="full-button" onClick={onWebhookTest} style={{ marginBottom: 0 }}>Send Test Event</button>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}
