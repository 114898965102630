import * as React from 'react';
import { TagList, Tag } from './TagList';
import { SearchInput } from './SearchInput';
import { DropdownList, DropdownOptions } from '../DropdownList';
import { APITeam } from '../../../api/teams';
import { APInvSettings, AssignmentRule, BlSettings } from '../../../api/userSettings';
import { UserSettingsContext } from '../../main/Main';
import { DocumentAssignment } from '../DocumentAssignment';
import { APIUser } from '../../../api/comment';
import { SearchFilter } from './index';

import './search-panel.scss';

interface Props {
  searchFilter: SearchFilter;
  setSearchFilter: (update: Partial<SearchFilter>) => void;
  sortOrderOptions: DropdownOptions;
  statusOptions: DropdownOptions;
  teams: APITeam[];
  users: APIUser[];
  searchTags: Tag[];
  kind: 'apInv' | 'bl';
  updateListOffset: () => void;
  searchDocuments: (filter: { [k: string]: string | number | null | number[] }, isAutoRefresh: boolean, clearData: boolean, hasFilterChanged: boolean) => void;
  hasSort?: boolean;
}

export const SearchPanel: React.FC<Props> = (props) => {
  const userSettings = React.useContext(UserSettingsContext);
  const [search, setSearch] = React.useState<string>('');
  const [showTags, setShowTags] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [selectedTag, setSelectedTag] = React.useState<number | null>(null);
  const [tagList, setTagList] = React.useState<Tag[]>(props.searchTags);
  const [searchActive, setSearchActive] = React.useState<boolean>(false);
  const [previousSearch, setPreviousSearch] = React.useState<Tag[]>([]);
  const [searchModified, setSearchModified] = React.useState<boolean>(false);
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    fetchDocumentsFromAPI(false);
  }, [props.searchFilter.status, props.searchFilter.sortOrder, props.searchFilter.teamId, props.searchFilter.userIds]);

  React.useEffect(() => {
    const tags = props.searchTags
      .map((tag) => tagMatchesSearchAndNoDup(tag.value) ? tag : undefined)
      .filter((tag) => tag !== undefined);

    // typescript does not recognize that we filtered 'undefined' out of the array
    setTagList(tags as Tag[]);
  }, [search, showTags, props.searchFilter.activeTags]);

  React.useEffect(() => {
    const selectedSortOrderOption = props.sortOrderOptions.find((option) => option.value === props.searchFilter.sortOrder);

    if (!selectedSortOrderOption) {
      dropdownChanged('sortOrder', props.sortOrderOptions[0].value);
    }
  }, [props.sortOrderOptions]);

  React.useEffect(() => {
    const filteredActiveTags = props.searchFilter.activeTags.filter((activeTag) => {
      const tagOption = props.searchTags.find((option) => option.id === activeTag.id);
      return !!tagOption;
    });

    props.setSearchFilter({ activeTags: filteredActiveTags });
  }, [props.searchTags]);

  const tagMatchesSearchAndNoDup = (value: string) => {
    const containsSearchTerm = value.toLowerCase().startsWith(search.toLowerCase())
    const noDuplicate = !props.searchFilter.activeTags.some((tag) => tag.value.substring(0, tag.value.indexOf(':') + 1).toLowerCase() === value.toLowerCase());
    return containsSearchTerm && noDuplicate;
  }

  const createTag = (tag: Tag) => {
    const tags = props.searchFilter.activeTags.map((t) => t.value.toLowerCase());

    if (!tags.includes(tag.value.toLowerCase())) {
      props.setSearchFilter({ activeTags: [...props.searchFilter.activeTags, { ...tag, tagRef: null }] });
      setSearchModified(true);
    }
  }

  const updateActiveTags = (updatedTag: Tag) => {
    const isValid = isValidTag(updatedTag.value);

    let tags: Tag[];
    if (isValid) {
      tags = props.searchFilter.activeTags.map((tag, i) => {
        if (tag.id === updatedTag.id) {
          // Typescript thinks updatedTag.tagRef can also be (instance: HTMLInputElement | null) => void
          // and I haven't figured out yet why...
          if (updatedTag.tagRef) (updatedTag.tagRef as any).current.focus();
          return updatedTag;
        } else {
          return tag;
        }
      })
    } else {
      tags = props.searchFilter.activeTags.filter((tag) => tag.id !== updatedTag.id);
      focusSearchInput();
    }

    props.setSearchFilter({ activeTags: tags });
  }

  const removeLastActiveTag = () => {
    props.setSearchFilter({ activeTags: props.searchFilter.activeTags.slice(props.searchFilter.activeTags.length - 1, 1) });
  }

  const isValidTag = (value: string) => {
    const tags = props.searchTags.map((tag) => tag.value.toLowerCase());
    return tags.includes(value.substring(0, value.indexOf(':') + 1).toLowerCase());
  }

  const searchDocuments = () => {
    if (isValidSearch()) {
      setSearchModified(false);
      setSearchActive(true);
      setPreviousSearch(props.searchFilter.activeTags.map((tag) => { return { ...tag } }));
      removeFocus();
      fetchDocumentsFromAPI(false);
    } else {
      showErrorMessage(true);
    }
  }

  const clearSearch = () => {
    setSearchActive(false);
    setSearchModified(false);
    props.setSearchFilter({ activeTags: [] });
    setPreviousSearch([]);
    // get documents without any search parameters. only filters / sort order
    fetchDocumentsFromAPI(true);
  }

  const revertToPreviousSearch = () => {
    props.setSearchFilter({ activeTags: previousSearch.map((tag) => { return { ...tag } }) });
  }

  const isValidSearch = (): boolean => {
    if (props.searchFilter.activeTags.length === 0) return false;
    // Check if all search terms are valid
    // We know that in activeTags all tags are valid, so we only have
    // to check if there is also a search term after the tag
    return props.searchFilter.activeTags.every((tag) => tag.value.split(':')[1].length > 0);
  }

  const showErrorMessage = (show: boolean) => {
    // If we display an error, focus the search input.
    // This will show the search-tags area in which the error is displayed.
    if (show) focusSearchInput();
    setShowError(show);
  }

  const dropdownChanged = (name: string, value: number | string | null) => {
    switch (name) {
      case 'status':
        props.setSearchFilter({ status: Number(value) });
        updateUserSettings('searchStatus', Number(value));
        break;
      case 'sortOrder':
        props.setSearchFilter({ sortOrder: Number(value) });
        updateUserSettings('searchOrder', Number(value));
        break;
    }
  }

  const setAssignmentRule = (value: AssignmentRule) => {
    props.searchFilter.teamId !== value.teamId && updateUserSettings('searchTeam', value.teamId);
    props.searchFilter.userIds !== value.userIds && updateUserSettings('searchUsers', value.userIds);

    props.setSearchFilter({ ...value });
  }

  const updateUserSettings = (name: keyof (APInvSettings | BlSettings), value: any) => {
    const settings = { ...userSettings };

    if (props.kind === 'apInv' && settings.apInvSettings) {
      if (settings.apInvSettings[name] === value) return;
      settings.apInvSettings[name] = value;
    } else if (settings.blSettings) {
      if (settings.blSettings[name] === value) return;
      settings.blSettings[name] = value;
    }

    if (settings.update) settings.update(settings);
  }

  const fetchDocumentsFromAPI = (noTags: boolean) => {
    // creating loopback filter object
    let filter: { [k: string]: string | number | null | number[] } = {};
    if (!noTags) {
      props.searchFilter.activeTags.forEach((tag) => {
        const apiTag = props.searchTags.find((searchTag) => searchTag.id === tag.id);
        if (apiTag) {
          const filterPos = apiTag.value.charAt(0).toLowerCase() + apiTag.value.slice(1, apiTag.value.length - 1);
          filter[filterPos.replace(/\s+/g, '')] = tag.value.split(':')[1].trim();
        }
      });

      props.setSearchFilter({ ...props.searchFilter, activeTags: props.searchFilter.activeTags.map((tag) => {
        const [tagName, tagValue] = tag.value.split(':').map((s) => s.trim());

        return {
          ...tag,
          value: `${tagName}:${tagValue}`
        };
      }) });
    }

    filter.status = props.searchFilter.status;
    filter.teamId = props.searchFilter.teamId;
    filter.userIds = props.searchFilter.userIds;

    switch (props.searchFilter.sortOrder) {
      case 2: // Oldest
        filter.orderBy = 'date';
        filter.orderDirection = 'asc';
        break;
      case 3: // Highest Value
        filter.orderBy = 'value';
        filter.orderDirection = 'desc';
        break;
      case 4: // Reassigned
        filter.orderBy = 'reassigned';
        filter.orderDirection = 'desc';
        break;
      case 5: // Oldest invoice date
        filter.orderBy = 'invoice_date';
        filter.orderDirection = 'asc';
        break;
      default: // Newest
        filter.orderBy = null;
        filter.orderDirection = null;
    }

    props.searchDocuments(filter, false, true, true);
  }

  const focusSearchInput = () => {
    if (searchInputRef.current) searchInputRef.current.focus();
  }

  const removeFocus = () => {
    if (document.activeElement && document.activeElement instanceof HTMLInputElement) document.activeElement.blur();
  }

  const preventDefault = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  }

  return (
    <div className={`search-panel`}>
      <div className="search-area">
        <div>
          <div className={`search-area__input ${searchActive ? 'active' : ''}`}>
            <SearchInput
              ref={searchInputRef}
              focusSearchInput={focusSearchInput}
              removeFocus={removeFocus}
              search={search}
              activeTags={props.searchFilter.activeTags}
              setSearch={setSearch}
              createTag={createTag}
              updateActiveTags={updateActiveTags}
              showTags={setShowTags}
              tagsVisible={showTags}
              removeLastActiveTag={removeLastActiveTag}
              updateListOffset={props.updateListOffset}
              searchDocuments={searchDocuments}
              showErrorMessage={showErrorMessage}
              selectedTag={selectedTag}
              setSelectedTag={setSelectedTag}
              tagList={tagList}
              searchActive={searchActive}
              clearSearch={clearSearch}
              revertToPreviousSearch={revertToPreviousSearch}
              setSearchModified={setSearchModified}
              searchModified={searchModified}
              searchTags={props.searchTags}
            />
          </div>
          {props.hasSort &&
            <div className="search-area__sort">
              <DropdownList
                items={props.sortOrderOptions}
                selectedValue={props.searchFilter.sortOrder}
                onChange={(value) => dropdownChanged('sortOrder', value)}
                buttonClassName="dropdown-button"
                preventDefault
                iconOnlyButton
                menuAlign="right"
                hideToggle
              />
            </div>}
        </div>
        <div className="search-area__filter">
          <div className="search-area__filter--status">
            <DropdownList
              items={props.statusOptions}
              selectedValue={props.searchFilter.status}
              onChange={(value) => dropdownChanged('status', value)}
              buttonClassName="dropdown-button active-on-hover"
              preventDefault
            />
          </div>
          <div className="search-area__filter--team">
            <DocumentAssignment
              teams={props.teams}
              users={props.users}
              assignmentRule={props.searchFilter}
              setAssignmentRule={setAssignmentRule}
              readonly
            />
          </div>
        </div>
      </div>
      <div onMouseDown={preventDefault} className={`search-tags ${showTags ? 'show' : ''}`}>
        <TagList
          onClick={createTag}
          search={search}
          nrOfActiveTags={props.searchFilter.activeTags.length}
          tagList={tagList}
          showError={showError}
          selectedTag={selectedTag}
          searchTags={props.searchTags}
        />
      </div>
    </div >
  );
}
