import { DraggableLocation, DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";

// a little function to help us with reordering the result
export const reorder = <T>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const move = <T>(source: T[], destination: T[], droppableSource: DraggableLocation, droppableDestination: DraggableLocation) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  if (source === destination) {
    destClone.splice(droppableSource.index, 1);
  }

  destClone.splice(droppableDestination.index, 0, removed);

  const result: { [k: string]: T[] } = {};

  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const getItemStyle = (isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle
});

export const getListStyle = (isDraggingOver: boolean) => (isDraggingOver ? { backgroundColor: "#dee2e7" } : {});
