import * as React from 'react';
import { EmailAccountAPI, EnvironmentTab, ValidationType } from '../../../../api/emailAccount';
import { APIAutomatedTolerance, DiscrepancyType } from '../../../../api/automatedTolerances';
import { AdminHeader } from '../../AdminHeader';
import { Link } from 'react-router-dom';
import { Column } from 'react-data-grid';
import { AdminLinks } from '../../Admin';
import { OptionValue } from 'react-selectize';
import { TabSwitcher } from '../../../common/TabSwitcher';
import {
  AdminGrid,
  AdminGridHandle,
  AdminGridSearchParams,
  AdminGridTagsList,
  AdminGridTagType,
} from '../../AdminGrid';
import './index.scss';

interface Props {
  siteOptions: OptionValue[];
}

export const AutomatedToleranceTable: React.FC<Props> = (props) => {
  const [selectedTab, setSelectedTab] = React.useState<string>(EnvironmentTab.ProductionWorkspace);
  const gridRef = React.useRef<AdminGridHandle>(null);

  React.useEffect(() => {
    gridRef.current?.refetchData();
  }, [selectedTab]);

  const fetchData = async (params: AdminGridSearchParams) => {
    const mailboxes = await EmailAccountAPI.fetchAll({ ...params, validationType: [ValidationType.AP], environmentTab: selectedTab });
    const result: APIAutomatedTolerance[] = [];
    mailboxes.forEach((mailbox) => {
      result.push({
        id: mailbox.id,
        emailAddr: mailbox.emailAddr,
        localCurrency: mailbox.localCurrency,
        xrUpperAbsoluteMax: mailbox.xrUpperAbsoluteMax,
        xrLowerAbsoluteMax: mailbox.xrLowerAbsoluteMax,
        xrUpperPercentageMax: mailbox.xrUpperPercentageMax,
        xrLowerPercentageMax: mailbox.xrLowerPercentageMax,
        accrualUpperAbsoluteMax: mailbox.accrualUpperAbsoluteMax,
        accrualLowerAbsoluteMax: mailbox.accrualLowerAbsoluteMax,
        accrualUpperPercentageMax: mailbox.accrualUpperPercentageMax,
        accrualLowerPercentageMax: mailbox.accrualLowerPercentageMax,
        accrualSetSeparateLimit: mailbox.accrualSetSeparateLimit,
        xrSetSeparateLimit: mailbox.xrSetSeparateLimit,
        exchangeTolerance: mailbox.exchangeTolerance,
        taxTolerance: mailbox.taxTolerance,
        site: mailbox.siteId ? props.siteOptions.find((option) => option.value === mailbox.siteId)?.label : '',
        discrepancyType: DiscrepancyType.AccrualDiscrepancy,
      });
      result.push({
        id: mailbox.id,
        emailAddr: mailbox.emailAddr,
        localCurrency: mailbox.localCurrency,
        xrUpperAbsoluteMax: mailbox.xrUpperAbsoluteMax,
        xrLowerAbsoluteMax: mailbox.xrLowerAbsoluteMax,
        xrUpperPercentageMax: mailbox.xrUpperPercentageMax,
        xrLowerPercentageMax: mailbox.xrLowerPercentageMax,
        accrualUpperAbsoluteMax: mailbox.accrualUpperAbsoluteMax,
        accrualLowerAbsoluteMax: mailbox.accrualLowerAbsoluteMax,
        accrualUpperPercentageMax: mailbox.accrualUpperPercentageMax,
        accrualLowerPercentageMax: mailbox.accrualLowerPercentageMax,
        accrualSetSeparateLimit: mailbox.accrualSetSeparateLimit,
        xrSetSeparateLimit: mailbox.xrSetSeparateLimit,
        exchangeTolerance: mailbox.exchangeTolerance,
        taxTolerance: mailbox.taxTolerance,
        site: mailbox.siteId ? props.siteOptions.find((option) => option.value === mailbox.siteId)?.label : '',
        discrepancyType: DiscrepancyType.ExchangeRateDiscrepancy,
      });
    });
    return result;
  }

  const columns: Column<APIAutomatedTolerance>[] = [
    {
      key: 'emailAddr', name: 'Mailbox address',
    },
    {
      key: 'site', name: 'Site',
    },
    {
      key: 'localCurrency', name: 'Local currency'
    },
    {
      key: 'lowerAbsoluteMax', name: 'Absolute Lower Limit', formatter: (tableProps) => {
        return <>{tableProps.row.discrepancyType === DiscrepancyType.ExchangeRateDiscrepancy ? tableProps.row.xrLowerAbsoluteMax : tableProps.row.accrualLowerAbsoluteMax}</>
      },
    },
    {
      key: 'lowerPercentageMax', name: 'Percentage Lower Limit', formatter: (tableProps) => {
        return <>{tableProps.row.discrepancyType === DiscrepancyType.ExchangeRateDiscrepancy ? tableProps.row.xrLowerPercentageMax : tableProps.row.accrualLowerPercentageMax}%</>
      },
    },
    {
      key: 'upperAbsoluteMax', name: 'Absolute Upper Limit', formatter: (tableProps) => {
        return <>{tableProps.row.discrepancyType === DiscrepancyType.ExchangeRateDiscrepancy ? tableProps.row.xrUpperAbsoluteMax : tableProps.row.accrualUpperAbsoluteMax}</>
      },
    },
    {
      key: 'upperPercentageMax', name: 'Percentage Upper Limit', formatter: (tableProps) => {
        return <>{tableProps.row.discrepancyType === DiscrepancyType.ExchangeRateDiscrepancy ? tableProps.row.xrUpperPercentageMax : tableProps.row.accrualUpperPercentageMax}%</>
      },
    },
    {
      key: 'taxTolerance', name: 'Tax rounding tolerance (automatic)', formatter: (tableProps) => {
        return <>{tableProps.row.discrepancyType === DiscrepancyType.ExchangeRateDiscrepancy ? undefined : tableProps.row.taxTolerance}</>
      },
    },
    {
      key: 'exchangeTolerance', name: 'FX rounding tolerance (manual)', formatter: (tableProps) => {
        return <>{tableProps.row.discrepancyType === DiscrepancyType.ExchangeRateDiscrepancy ? undefined : tableProps.row.exchangeTolerance}</>
      },
    },
    {
      key: 'discrepancyType', name: 'Threshold type', formatter: (tableProps) => {
        return (
          <AdminGridTagsList
            type={tableProps.row.discrepancyType === DiscrepancyType.ExchangeRateDiscrepancy ? AdminGridTagType.Yellow : AdminGridTagType.Purple}
            items={[tableProps.row.discrepancyType === DiscrepancyType.ExchangeRateDiscrepancy ? 'Exchange rate discrepancy' : 'Accrual discrepancy']}
          />
        );
      },
    },
    {
      key: 'actions', name: 'Actions', formatter: (tableProps) => {
        const formSlug = tableProps.row.discrepancyType === DiscrepancyType.ExchangeRateDiscrepancy ? 'xr-tolerance-form' : 'accrual-tolerance-form';
        const link = `${AdminLinks.AutomatedTolerances}/${formSlug}/${tableProps.row.id}`
        return <Link to={link}><button className="light-button active-on-hover">Edit</button></Link>;
      },
    },

  ];

  return (
    <div className="mailboxes-section">
      <AdminHeader
        headline="AP Tolerances"
        links={[{
          label: 'What are automated tolerances?',
          url: '/knowledge-base/What-are-tolerances-b-b--a04664929e0045d48e1e5f6cef5f627a#8b6013cae88c42e49e4c31d92c4a36a2',
        }]}
      />
      <TabSwitcher
        selectedTab={selectedTab}
        onTabClicked={setSelectedTab}
        tabs={[EnvironmentTab.ProductionWorkspace, EnvironmentTab.TestWorkspace]}
      />
      <AdminGrid
        ref={gridRef}
        searchPlaceholder="Search mailboxes..."
        columns={columns}
        fetchData={fetchData}
      />
    </div>
  )
};
