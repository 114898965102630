import * as React from 'react';
import { CSVLink } from 'react-csv';
import { CompanyAPI } from '../../api/company';
import { ExportError, Notification } from '../common/Notification';
import { ButtonWithSpinner } from '../common/ButtonWithSpinner';
import { ShipamaxDownloadIcon } from '../../images/Icons';
import { ChartFilterProps } from './ChartWrapper';

interface Props {
  filename: string;
  setNotification: (notification: Notification | null) => void;
  apiFilter: ChartFilterProps;
  type: "ap" | "cinv";
  disabled?: boolean;
}

export const ExportButton: React.FC<Props> = (props) => {
  const [csvData, setCSVData] = React.useState<object[]>([]);
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const csvInstance = React.useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  React.useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current?.link.click();
        setCSVData([]);
      });
    }
  }, [csvData]);

  const generateCsvData = async () => {
    setIsDownloading(true);

    let csvData = props.type === 'ap'
      ? await CompanyAPI.getApInsightsChartRawData(props.apiFilter)
      : await CompanyAPI.getGroupsInsightsChartData(props.apiFilter, true);

    if (!csvData) {
      props.setNotification(ExportError);
      csvData = [];
    }

    setCSVData(csvData);
    setIsDownloading(false);
  }

  return (
    <>
      <ButtonWithSpinner
        className="full-button__csv-button"
        disabled={isDownloading || props.disabled}
        onClick={async () => generateCsvData()}
        showSpinner={isDownloading}><ShipamaxDownloadIcon />Download data
      </ButtonWithSpinner>

      {csvData && !!csvData.length && (
        <>
          <CSVLink
            data={csvData}
            filename={props.filename}
            target="_self"
            ref={csvInstance}
          />
        </>
      )}
    </>
  )
}
