import * as React from 'react';
import { AdminHeader } from '../../../AdminHeader';
import { APIEmailAccount, EmailAccountAPI, TMSType, ValidationType, CNValidationType } from '../../../../../api/emailAccount';
import { TextInput } from '../../../../common/TextInput';
import { WrapperLabel } from '../../../../common/WrapperLabel';
import { CustomSelect } from '../../../../common/CustomSelect';
import { OptionValue } from 'react-selectize';
import { Toggle } from '../../../../common/Toggle';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AdminLinks } from '../../../Admin';
import { isFormInvalid, RecordValidator } from '../../../../common/Form';
import { APISite } from '../../../../../api/sites';
import { isValidEmail } from '../../../../common/MultiEmailInput';
import { ShipamaxEnvironment } from '../../../../../api/cwCredential';

const emailSuffix = '@ff.shipamax.com';

// Todo: we need to fetch this from DB filtered by validation type
enum WorkflowTemplate {
  CargoWiseAPWorkflow = 1,
  CargoWiseAPWorkflowWithUserAssignment = 2,
  NonCWAPWorkflow = 4,
}

export const tmsTypeOptions = [{ value: TMSType.Cargowise, label: 'CargoWise' }, { value: TMSType.Unknown, label: 'Other TMS' }];
export const receiverOptions = [
  { value: WorkflowTemplate.CargoWiseAPWorkflowWithUserAssignment, label: 'Job Owner' },
  { value: WorkflowTemplate.CargoWiseAPWorkflow, label: 'Team' }
];

interface UrlParams {
  id?: string;
  env?: string;
}

interface Props {
  data?: APIEmailAccount;
  teamOptions: OptionValue[];
  siteOptions: OptionValue[];
  currencyOptions: OptionValue[];
  countryCodeOptions: OptionValue[];
}

export const MailboxForm: React.FC<Props> = (props) => {
  const urlParams = new URLSearchParams(location.search);
  const environment = urlParams.get('env');

  const [record, setRecord] = React.useState<Partial<APIEmailAccount>>({
    companyId: parseInt(localStorage.getItem('companyId') || ''),
    multiDoc: true,
    triggerParser: true,
    validationType: ValidationType.AP,
    cnValidation: CNValidationType.Standard,
    environment: environment ? ShipamaxEnvironment.TestProduction : null,
    useTaxDate: false,
    useDocumentReceivedDate: false,
    defaultToOverheads: false,
  });

  const isCW = record.tmsType === TMSType.Cargowise;

  const [isEmailTaken, setIsEmailTaken] = React.useState<boolean>(false);
  const [formEntered, setFormEntered] = React.useState<boolean>(false);

  const params = useParams<UrlParams>();
  const history = useHistory();

  const recordValidator: RecordValidator = {
    emailAddr: {
      validators: [
        {
          errorMessage: 'Required',
          isValid: () => !!record.emailAddr?.replace(emailSuffix, '')
        },
        {
          errorMessage: 'This address is already in use',
          isValid: () => !isEmailTaken
        },
        {
          errorMessage: 'Not a valid email',
          isValid: () => isValidEmail(record.emailAddr || '')
        },
      ]
    },
    defaultTeamId: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => !!record.defaultTeamId
      }]
    },
    siteId: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => !!record.siteId
      }]
    },
    tmsType: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => record.tmsType != undefined // tmsType can be 0 and also null
      }]
    },
    workflowTemplateId: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => !!record.workflowTemplateId
      }]
    },
    localCurrency: {
      validators: [{
        errorMessage: 'Required',
        isValid: () => !!record.localCurrency
      }]
    },
    cwCompanyCode: {
      validators: isCW ? [{
        errorMessage: 'Required',
        isValid: () => !!record.cwCompanyCode
      }] : []
    }
  }

  const submitDisabled = isFormInvalid<APISite>(record, recordValidator);

  React.useEffect(() => {
    if (params.id) {
      EmailAccountAPI.fetch(parseInt(params.id)).then((data) => {
        setRecord(data);
      });
    }
  }, [params.id]);

  React.useEffect(() => {
    if (!record.emailAddr) {
      setIsEmailTaken(false);
      return;
    }

    EmailAccountAPI.isFieldValueTaken('emailAddr', record.emailAddr, record.id).then((result) => {
      setIsEmailTaken(result);
    });
  }, [record.emailAddr]);

  const onUpdate = (change: Partial<APIEmailAccount>) => {
    setRecord((record) => ({ ...record, ...change }));
  }

  const onSave = async () => {
    setFormEntered(true);
    if (submitDisabled) return;

    if (record.id) {
      await EmailAccountAPI.update(record.id, record);
    } else {
      await EmailAccountAPI.create(record);
    }

    history.push(AdminLinks.ApReconciliationWorkflows);
  }

  return (
    <div className="admin__form__wrapper with-custom-scrollbar">
      <AdminHeader
        headline={params.id ? 'Edit Workflow' : 'Add a new Workflow'}
        tagText={environment ? 'Test workspace' : 'Production workspace'}
        links={[{
          label: 'What are workflows?',
          url: '/knowledge-base/What-are-workflows---73a06cd51fb047c6a40d8772b4a8ec2d',
        }]}
      />
      <div className="admin__form__content">
        <div>
          <WrapperLabel text="Mailbox address*">
            <TextInput
              name="emailAddr"
              value={record.emailAddr?.replace(emailSuffix, '') || null}
              setter={(value) => onUpdate({ emailAddr: value ? value + emailSuffix : undefined })}
              suffix={emailSuffix}
              formEntered={formEntered}
              validators={recordValidator.emailAddr.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="Integration*">
            <CustomSelect
              options={tmsTypeOptions}
              onValueChange={(value) => {
                onUpdate({
                  tmsType: value?.value,
                  workflowTemplateId: value?.value === TMSType.Cargowise ? null : WorkflowTemplate.NonCWAPWorkflow,
                });
              }}
              value={tmsTypeOptions.find((option) => option.value === record.tmsType)}
              formEntered={formEntered}
              validators={recordValidator.tmsType.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="Site*">
            <CustomSelect
              options={props.siteOptions}
              onValueChange={(value) => onUpdate({ siteId: value?.value })}
              value={props.siteOptions.find((option) => option.value === record.siteId)}
              formEntered={formEntered}
              validators={recordValidator.siteId.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="First receiver*">
            <CustomSelect
              options={receiverOptions}
              onValueChange={(value) => onUpdate({ workflowTemplateId: value?.value })}
              value={receiverOptions.find((option) => option.value === (isCW ? record.workflowTemplateId : WorkflowTemplate.CargoWiseAPWorkflow))}
              formEntered={formEntered}
              validators={recordValidator.workflowTemplateId.validators}
              disabled={!isCW}
            />
          </WrapperLabel>
          <WrapperLabel text="Team*">
            <CustomSelect
              options={props.teamOptions}
              onValueChange={(value) => onUpdate({ defaultTeamId: value?.value })}
              value={props.teamOptions.find((option) => option.value === record.defaultTeamId)}
              formEntered={formEntered}
              validators={recordValidator.defaultTeamId.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="Company code*">
            <TextInput
              name="companyCode"
              value={record.cwCompanyCode || null}
              setter={(value) => onUpdate({ cwCompanyCode: value })}
              formEntered={formEntered}
              validators={recordValidator.cwCompanyCode.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="Local currency*">
            <CustomSelect
              options={props.currencyOptions}
              onValueChange={(value) => onUpdate({ localCurrency: value?.label })}
              value={props.currencyOptions.find((option) => option.label === record.localCurrency)}
              formEntered={formEntered}
              validators={recordValidator.localCurrency.validators}
            />
          </WrapperLabel>
          <WrapperLabel text="Country code">
            <CustomSelect
              options={props.countryCodeOptions}
              onValueChange={(value) => onUpdate({ branchCountryCode: value?.label.split(' ')[0] })}
              value={props.countryCodeOptions.find((option) => option.label.split(' ')[0] === record.branchCountryCode)}
            />
          </WrapperLabel>
          <WrapperLabel text="Multi Doc file splitting">
            <Toggle
              id="multiDoc"
              checked={!!record.multiDoc}
              onChecked={() => onUpdate({ multiDoc: !record.multiDoc })}
            />
          </WrapperLabel>
          <WrapperLabel text="Overhead Invoice Mailbox">
            <Toggle
              id="defaultToOverheads"
              checked={!!record.defaultToOverheads}
              onChecked={() => onUpdate({ defaultToOverheads: !record.defaultToOverheads })}
            />
          </WrapperLabel>
          {record.tmsType === TMSType.Cargowise && (
            <>
              {/*<WrapperLabel text="Include Tax Date when posting invoices">*/}
              {/*  <Toggle*/}
              {/*    id="useTaxDate"*/}
              {/*    checked={!!record.useTaxDate}*/}
              {/*    onChecked={() => onUpdate({ useTaxDate: !record.useTaxDate })}*/}
              {/*  />*/}
              {/*</WrapperLabel>*/}
              <WrapperLabel text="Include Document Received Date when posting invoices">
                <Toggle
                  id="useDocumentReceivedDate"
                  checked={!!record.useDocumentReceivedDate}
                  onChecked={() => onUpdate({ useDocumentReceivedDate: !record.useDocumentReceivedDate })}
                />
              </WrapperLabel>
            </>
          )}
        </div>
        <div>
          <Link to={AdminLinks.ApReconciliationWorkflows}><button className="light-button">Cancel</button></Link>
          <button className="full-button" onClick={onSave}>Save</button>
        </div>
      </div>
    </div>
  )
}
