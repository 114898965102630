import { APIBillOfLadingImporterReference } from "../../api/billOfLading";
import { APIIssuerAddress } from "../../api/issuer";
import { APILocationUnlocode } from "../../api/locationUnlocode";
import { Tag } from '../common/document-list/TagList';

export enum StatusCode {
  NotReady = 0,
  Unposted = 1,
  Discarded = 2,
  Posted = 3,
  Reopened = 4,
  Done = 5,
  Processing = 6,
  Queried = 7,
  PendingResponse = 8,
  Ignored = 9,
  PostedWithIssues = 10,
}

export const statusText: { [k: number]: string } = {
  [StatusCode.Unposted]: 'Unposted',
  [StatusCode.Discarded]: 'Discarded',
  [StatusCode.Posted]: 'Posted',
  [StatusCode.Reopened]: 'Reopened',
  [StatusCode.Done]: 'Done',
  [StatusCode.Processing]: 'Processing',
  [StatusCode.PendingResponse]: 'Pending Response',
  [StatusCode.Queried]: 'In Query',
};

export enum JobType {
  Shipment = 'shipment',
  Consol = 'consol',
  Invoice = 'invoice',
  PackingList = 'packingList'
}

export enum PostingBehaviour {
  Undefined = 0,
  Forwarding = 1,
  ForwardingAndClearance = 2,
  StandaloneCInv = 3,
  NewBJob = 4,
  UpdateBJob = 5,
  UpdateSJob = 6,
  WtgIntegration = 7,
  GenericTMS = 8,
}

export const fwdOrFncPostingBehaviour = [PostingBehaviour.Forwarding, PostingBehaviour.ForwardingAndClearance];

// Search status
export enum Status {
  Unposted = StatusCode.Unposted,
  Reopened = StatusCode.Reopened,
  Posted = StatusCode.Posted,
  Discarded = StatusCode.Discarded,
  Done = StatusCode.Done,
  Processing = StatusCode.Processing,
  PendingResponse = StatusCode.PendingResponse,
  All = 10000,
}

export const StatusText = {
  default: '-',
  [Status.Unposted]: 'Unposted',
  [Status.Reopened]: 'Reopened',
  [Status.Posted]: 'Posted',
  [Status.Discarded]: 'Discarded',
  [Status.Processing]: 'Processing',
  [Status.Done]: 'Done',
  [Status.PendingResponse]: 'Pending Response',
}

export const groupsSearchTags: Tag[] = [
  { id: 1, value: 'Organisation Code:' },
  { id: 2, value: 'Organisation Name:' },
  { id: 3, value: 'BL Number:' },
  { id: 4, value: 'Invoice Number:' },
  { id: 5, value: 'Job Reference:' },
  { id: 6, value: 'Received Date:' },
  { id: 7, value: 'Posted Date:' },
  { id: 8, value: 'Email Subject:' },
  { id: 9, value: 'Pack Id:' },
];

export const wtgIntegrationSearchTags: Tag[] = [
  { id: 1, value: 'Document Number:' },
  { id: 2, value: 'Received Date:' },
  { id: 3, value: 'Posted Date:' },
  { id: 4, value: 'Pack Id:' },
];


export interface IssuerDetailMap {
  [k: string]: IssuerDetails;
}

export interface IssuerDetails {
  id: number;
  name?: string;
  cgwCode: string;
  address?: APIIssuerAddress
}
export interface FieldChangedParams {
  recordId?: string | null;
  jobType?: JobType;
  id?: number;
  value?: string | APIBillOfLadingImporterReference[] | null;
  fieldName: string;
}

export interface LocationsMap {
  [unlocode: string]: APILocationUnlocode
}
