import * as React from 'react';

import './table-definitions-preview.scss';
import { tableDefinitions } from './TableHighlight';
import { DocumentViewer } from './DocumentViewer';
import { Notification, MatchingError } from '../Notification';
import { LineItems } from '../../bill-of-lading/group-details/LineItems';
import { ConfirmModal, ConfirmModalProps, ConfirmModalType } from '../ConfirmModal';
import { Icon } from 'pivotal-ui/react/iconography';
import { FAIcon } from '../FAIcon';
import { CommercialInvoiceLineItemAPI } from '../../../api/commercialInvoiceLineItem';

interface Props {
  tableDefinitions: tableDefinitions;
  documentId?: number;
  specificId: number;
  pdfDocument?: any;
  filename?: string | undefined;
  setNotification: (notification: Notification | null) => void;
  showSpinner?: boolean;
  filePages?: number[] | null;
  pageIndex?: number;
  setShowPreview: (value: boolean) => void;
  setShowEditDefinitions?: (value: boolean) => void;
  reparsedData: any;
  documentType: number;
  saveDefinitions: () => void;
  setPreviewChangesTrigger?: (value: number) => void;
  setChangesComplete?: (value: boolean) => void;
}

export const TableDefinitionsPreview: React.FC<Props> = (props) => {
  const [showProductMatchingModal, setShowProductMatchingModal] = React.useState<boolean>(false);

  return (
    <div className='table-definitions-preview-overlay'>
      <div className='table-definitions-preview'>
        {!props.reparsedData.length ? (<Icon src="spinner-md" />) : (
          <>
            <div className='document-wrap'>
              <div className="preview-header"></div>
              <DocumentViewer
                pdfDocument={props.pdfDocument}
                parentDocumentId={null}
                filename={props.filename}
                setNotification={props.setNotification}
                showSpinner={props.showSpinner || false}
                documentId={props.documentId}
                hideControls={true}
                useContainerRef={true}
                filePages={props.filePages}
                loadDefaultByWidth={true}
                editTableDefinitions={false}
                previewTableDefinitions={true}
                initialPdfScale={0.95}
                specificId={props.specificId} />
            </div>
            <div className='line-item-wrap'>
              <div className="preview-header">Preview Results</div>
              <LineItems
                supplierCgwCode={null}
                importerCgwCode={null}
                unitTypeOptions={[]}
                parentId={0}
                parentDocumentType={props.documentType}
                disabled={true}
                useOrganisations={false}
                supplierName={null}
                importerName={null}
                setConfirmModal={function (props: ConfirmModalProps): void {
                  throw new Error('Function not implemented.');
                }}
                lineItemRows={props.reparsedData}
                readonly={true}
              />

            </div>
          </>
        )}

        <div className='table-definitions-footer'>
          <button
            className='light-button discard-button'
            onClick={() => {
              props.setShowPreview(false);
              props.setPreviewChangesTrigger && props.setPreviewChangesTrigger(0);
            }}
          >
            Return to editor
          </button>
          <button
            className='full-button'
            onClick={() => {
              setShowProductMatchingModal(true);
              props.setChangesComplete && props.setChangesComplete(false)
            }}
          >
            Accept and update
          </button>
          <ConfirmModal
            show={showProductMatchingModal}
            title={"Trigger Product Matching?"}
            text={"Shipamax automatically attempts to match the data extracted for each line item with a product in your product codes database."}
            icon={<FAIcon name="question-circle" solid />}
            onHide={() => {
              props.saveDefinitions();
            }}
            onConfirm={async () => {
              const response = await CommercialInvoiceLineItemAPI.triggerLineItemsMatcher(props.specificId);
              if (response) {
                props.setShowPreview(false);
                props.setPreviewChangesTrigger && props.setPreviewChangesTrigger(0);
                props.saveDefinitions();
              } else {
                props.setNotification(MatchingError);
              }
            }}
            modalType={ConfirmModalType.Message}
            confirmText={"Proceed with matching"}
            cancelText={"Proceed without matching"}
          />
        </div>
      </div>
    </div>
  )
}
