import * as React from 'react';
import { OptionValue } from 'react-selectize';
import { PermissionLevel } from '../../../../api/authentication';
import { Route, Switch } from 'react-router-dom';
import { AdminLinks } from '../../Admin';
import { UsersTable } from './UsersTable';
import { UserForm } from './UserForm';
import { TeamAPI } from '../../../../api/teams';
import { PermissionProfileAPI } from '../../../../api/permissionProfiles';

export const permissionOptions: OptionValue[] = [
  { label: 'Standard', value: PermissionLevel.Standard },
  { label: 'Admin', value: PermissionLevel.Admin }
];

interface Props {
  refetchUserDetails: () => void;
}

export const UsersSection: React.FC<Props> = (props) => {
  const [teamOptions, setTeamOptions] = React.useState<OptionValue[]>([]);
  const [permissionProfileOptions, setPermissionProfileOptions] = React.useState<OptionValue[]>([]);

  React.useEffect(() => {
    fetchReferenceDataFromAPI();
  }, []);

  const fetchReferenceDataFromAPI = async () => {
    const [teams, permissionProfiles] = await Promise.all([TeamAPI.fetchAll(), PermissionProfileAPI.fetchAll()]);
    setTeamOptions(teams.filter((team) => !team.disabled).map((team) => ({ label: team.name, value: team.id })));
    setPermissionProfileOptions(permissionProfiles.map((permissionProfile) => ({ label: permissionProfile.name, value: permissionProfile.id })));
  }

  return (
    <Switch>
      <Route path={AdminLinks.Users} exact component={() => (
        <UsersTable teamOptions={teamOptions} permissionProfileOptions={permissionProfileOptions} />
      )} />
      <Route path={[AdminLinks.Users + '/form', AdminLinks.Users + '/form/:id']} exact component={() => (
        <UserForm teamOptions={teamOptions} permissionProfileOptions={permissionProfileOptions} refetchUserDetails={props.refetchUserDetails} />
      )} />
    </Switch>
  )
};
